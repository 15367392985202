<template>
  <div
    class="dashboard-content dashboard-booking"
    id="dashboard-content"
  >
    <v-dialog
      lazy
      v-model="editBookingIsVisible"
    >
      <v-card class="booking-edit">
        <div class="booking-edit__title">
          {{$t('practitioner_profile.booking_tab_edit_booking')}}
        </div>
        <div class="booking-edit__inners">
          <div class="booking-edit__inner">
            <div class="booking-edit__text">
              {{$t('practitioner_profile.booking_tab_date')}}
            </div>
            <div class="booking-edit__field">
              <v-menu
                v-model="bookingDateMenu"
                :close-on-content-click="false"
                full-width
                :nudge-right="40"
              >
                <v-text-field
                  slot="activator"
                  :value="formattedBookingDate"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="editedBookingDate"
                  @change="updateBookingDate()"
                  :min="currentDate()"
                  landscape
                  color="error lighten-1"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="booking-edit__inner">
            <div class="booking-edit__text">
              {{$t('practitioner_profile.booking_tab_time')}}
            </div>
            <div class="booking-edit__field">
              <VueCtkDateTimePicker
                v-model="editedBookingTime"
                formatted="HH:mm"
                format="HH:mm:ss"
                :minute-interval="15"
                only-time
                label=""
              ></VueCtkDateTimePicker>
            </div>
          </div>
        </div>
        <div class="booking-edit__actions">
          <v-btn
            color="error"
            :disabled="disabledUpdateBooking"
            @click="saveBookingEdits()"
          >
            {{$t('practitioner_profile.booking_tab_save')}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <div class="dashboard-content__title">
      <h2>
        {{$t('practitioner_profile.booking_tab_title')}}
      </h2>
      <p>
        {{$t('practitioner_profile.booking_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">
      <div v-if="showProgress">
        <v-progress-linear
          indeterminate
          color="error"
          height="3"
        ></v-progress-linear>
      </div>

      <div v-if="!showProgress">
        <div class="dashboard-booking__title">
          {{$t('practitioner_profile.booking_tab_upcoming_appointments')}}
        </div>

        <div class="dashboard-booking__desk">
          <v-data-table
            :headers="headers"
            :items="upcomingAppointments"
            :expand="expand"
            item-key="id"
            hide-actions
            class="dashboard-booking__table"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <tr
                @click="props.expanded = !props.expanded"
                class="dashboard-booking__table-header"
                :class="upcomingClassObj(props.item)"
                :ref="props.item.id"
              >
                <td class="dashboard-booking__table-td dashboard-booking__table-number">{{ props.item.id }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-name">{{ props.item.customer_name | formatName }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-treatments">{{ props.item.treatments |
                  formatTreatments }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-location">{{ props.item.location_type |
                  capitalizeFirstLetter }}
                </td>
                <td
                        v-if="!isCanceledBooking(props.item)"
                        class="dashboard-booking__table-td dashboard-booking__table-time-date"
                >{{ props.item.date | formatDate }}</td>
                <td
                        v-else
                        class="dashboard-booking__table-td dashboard-booking__table-time-date"
                >
                  <template v-if="bookingCancelBy(props.item) === 'customer'">
                    {{$t('practitioner_profile.booking_tab_customer_cancelled')}}
                  </template>
                  <template v-else-if="bookingCancelBy(props.item) === 'practitioner'">
                    {{$t('practitioner_profile.booking_tab_practitioner_cancelled')}}
                  </template>
                </td>
              </tr>
            </template>
            <template
              slot="expand"
              slot-scope="props"
            >
              <div>
                <table class="dashboard-booking__table">
                  <tr>
                    <td class="dashboard-booking__table-td dashboard-booking__table-number"></td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-name">
                      <div class="dashboard-booking__info">
                        <div>{{ props.item.customer_phone }}</div>
                        <!-- <div>{{ props.item.customer_email }}</div> -->
                      </div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-treatments">
                      <div class="dashboard-booking__prices">
                        <div
                          class="dashboard-booking__prices-item"
                          v-for="treat in getTreatmentObjectCalculated(props.item.treatments)['data']"
                          :key="treat.id"
                        >
                          <span>{{ treat.quantity }} x {{ treat.name }}</span>
                          <span>£{{ treat.total_price }}</span>
                        </div>

                        <div
                          class="dashboard-booking__prices-item"
                          v-if="props.item.location_type === 'mobile'"
                        >
                          <span>
                            {{$t('practitioner_profile.booking_tab_call_out_charge')}}
                          </span>
                          <span>£{{priceFormat(props.item.call_out_charge)}}</span>
                        </div>

                        <div class="dashboard-booking__prices-item">
                          <span>
                            {{$t('practitioner_profile.booking_tab_fee')}}
                          </span>
                          <span>£{{getFee(props.item.total_amount, props.item.discount_amount, props.item.freely_fee)}}</span>
                        </div>

                        <div
                                v-if="props.item.amount_refunded"
                                class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                        >
                          <span>
                            {{$t('practitioner_profile.booking_tab_refund_total')}}
                          </span>
                          <span>£{{ getAmountOfRefund(props.item.total_amount, props.item.discount_amount) }}</span>
                        </div>

                        <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total" v-if="!props.item.amount_refunded">
                          <span>
                            {{$t('practitioner_profile.booking_tab_sub_total')}}
                          </span>
                          <span >£{{ getTotalIncome(props.item.total_amount, props.item.discount_amount, props.item.freely_fee) }}</span>
                        </div>
                        <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total" v-else>
                          <span>
                            {{$t('practitioner_profile.booking_tab_total_income')}}
                          </span>
                          <span>£0.00</span>
                        </div>

                      </div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-location">
                      <div class="dashboard-booking__address">{{ props.item.location.address }}</div>
                      <div class="dashboard-booking__address">{{ props.item.location.city }}</div>
                      <div class="dashboard-booking__address">{{ props.item.location.postcode }}</div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-time-date">
                      <l-map
                        ref="map"
                        :zoom="zoom"
                        :center="formatMapCenter(props.item.location.home_location)"
                        :options="{ zoomControl: false }"
                        class="map"
                      >
                        <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                        <l-tile-layer
                          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                        />

                        <l-marker
                          :lat-lng="formatMapCenter(props.item.location.home_location)"
                          :interactive="false"
                        />
                      </l-map>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="dashboard-booking__actions">
                <v-btn
                  v-if="props.item.booking_status === 'confirmed'"
                  class="dashboard-booking__actions-cancel error"
                  @click="editBooking(props.item)"
                >
                  {{$t('practitioner_profile.booking_tab_edit_booking')}}
                </v-btn>

                <v-btn
                  v-if="props.item.booking_status === 'confirmed'"
                  class="dashboard-booking__actions-cancel error"
                  @click="showCancelBookingPopup(props.item)"
                >
                  {{$t('practitioner_profile.booking_tab_cancel_booking')}}
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>


        <div class="dashboard-booking__mob">

          <div class="dashboard-booking__mob-table">

            <div
              class="dashboard-booking__mob-item"
              v-for="item in upcomingAppointments"
              :key="item.id"
            >
              <div
                      class="dashboard-booking__mob-header"
                      :class="upcomingClassObj(item)"
              >
                <div class="dashboard-booking__mob-header-lside">
                  <strong>{{item.customer_name | formatName }}</strong>
                  <span>{{item.id}}</span>
                </div>
                <div
                        v-if="!isCanceledBooking(item)"
                        class="dashboard-booking__mob-header-rside"
                >
                  <strong>{{item.date | formatDate}}</strong>
                </div>
                <div
                        v-else
                        class="dashboard-booking__mob-header-rside"
                >
                  <template v-if="bookingCancelBy(item) === 'customer'">
                    <strong>
                      {{$t('practitioner_profile.booking_tab_customer_cancelled')}}
                    </strong>
                  </template>
                  <template v-else-if="bookingCancelBy(item) === 'practitioner'">
                    <strong>
                      {{$t('practitioner_profile.booking_tab_practitioner_cancelled')}}
                    </strong>
                  </template>
                </div>
              </div>
              <div class="dashboard-booking__mob-body">
                <div class="dashboard-booking__mob-body-inner">
                  <div class="dashboard-booking__prices">
                    <div
                      class="dashboard-booking__prices-item"
                      v-for="treat in getTreatmentObjectCalculated(item.treatments)['data']"
                      :key="treat.id"
                    >
                      <span>{{ treat.quantity }} x {{ treat.name }}</span>
                      <span>£{{ treat.total_price }}</span>
                    </div>

                    <div
                      class="dashboard-booking__prices-item"
                      v-if="item.location_type === 'mobile'"
                    >
                      <span>
                        {{$t('practitioner_profile.booking_tab_call_out_charge')}}
                      </span>
                      <span>£{{priceFormat(item.call_out_charge)}}</span>
                    </div>

                    <div class="dashboard-booking__prices-item">
                      <span>
                        {{$t('practitioner_profile.booking_tab_fee')}}
                      </span>
                      <span>£{{getFee(item.total_amount, item.discount_amount, item.freely_fee)}}</span>
                    </div>

                    <div
                            v-if="item.amount_refunded"
                            class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                    >
                      <span>
                        {{$t('practitioner_profile.booking_tab_refund_total')}}
                      </span>
                      <span>£{{ getAmountOfRefund(item.total_amount, item.discount_amount) }}</span>
                    </div>


                    <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total" v-if="!item.amount_refunded">
                      <span>
                        {{$t('practitioner_profile.booking_tab_sub_total')}}
                      </span>
                      <span >£{{ getTotalIncome(item.total_amount, item.discount_amount, item.freely_fee) }}</span>
                    </div>

                    <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total" v-else>
                      <span>
                        {{$t('practitioner_profile.booking_tab_total_income')}}
                      </span>
                      <span>£0.00</span>
                    </div>

                  </div>
                </div>
                <div class="dashboard-booking__mob-body-inner">
                  <span class="dashboard-booking__mob-title">
                    {{$t('practitioner_profile.booking_tab_location')}}
                  </span>

                  <div class="dashboard-booking__address-holder">
                    <div class="dashboard-booking__address">{{ item.location.address }},</div>
                    <div class="dashboard-booking__address">{{ item.location.city }},</div>
                    <div class="dashboard-booking__address">{{ item.location.postcode }}</div>
                  </div>

                  <l-map
                    ref="map"
                    :zoom="zoom"
                    :center="formatMapCenter(item.location.home_location)"
                    :options="{ zoomControl: false }"
                    class="map"
                  >
                    <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                    <l-tile-layer
                      url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                      attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                    />

                    <l-marker
                      :lat-lng="formatMapCenter(item.location.home_location)"
                      :interactive="false"
                    />
                  </l-map>
                </div>
                <div class="dashboard-booking__mob-body-inner">
                  <div class="dashboard-booking__actions">
                    <v-btn
                      v-if="item.booking_status === 'confirmed'"
                      class="dashboard-booking__actions-cancel error"
                      @click="editBooking(item)"
                    >
                      {{$t('practitioner_profile.booking_tab_edit_booking')}}
                    </v-btn>

                    <v-btn
                      v-if="item.booking_status === 'confirmed'"
                      class="dashboard-booking__actions-cancel error"
                      @click="showCancelBookingPopup(item)"
                    >
                      {{$t('practitioner_profile.booking_tab_cancel_booking')}}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div v-if="!showProgress">
        <div class="dashboard-booking__title dashboard-booking__title--mt">
          {{$t('practitioner_profile.booking_tab_previous_appointments')}}
        </div>
        <div class="dashboard-booking__desk">
          <v-data-table
            :headers="headers"
            :items="previousAppointments"
            :expand="expand"
            item-key="id"
            hide-actions
            class="dashboard-booking__table"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <tr
                @click="props.expanded = !props.expanded"
                class="dashboard-booking__table-header"
                :class="previousClassObj(props.item)"
                :ref="props.item.id"
              >
                <td class="dashboard-booking__table-td dashboard-booking__table-number">{{ props.item.id }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-name">{{ props.item.customer_name | formatName }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-treatments">{{ props.item.treatments |
                  formatTreatments }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-location">{{ props.item.location_type |
                  capitalizeFirstLetter }}
                </td>
                <td
                        v-if="!isCanceledBooking(props.item)"
                        class="dashboard-booking__table-td dashboard-booking__table-time-date"
                >
                  {{ props.item.date | formatDate }}
                </td>
                <td
                        v-else
                        class="dashboard-booking__table-td dashboard-booking__table-time-date"
                >
                  <template v-if="bookingCancelBy(props.item) === 'customer'">
                    {{$t('practitioner_profile.booking_tab_customer_cancelled')}}
                  </template>
                  <template v-else-if="bookingCancelBy(props.item) === 'practitioner'">
                    {{$t('practitioner_profile.booking_tab_practitioner_cancelled')}}
                  </template>
                </td>
              </tr>
            </template>
            <template
              slot="expand"
              slot-scope="props"
            >
              <div>
                <table class="dashboard-booking__table">
                  <tr>
                    <td class="dashboard-booking__table-td dashboard-booking__table-number"></td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-name">
                      <div class="dashboard-booking__info">
                        <!-- <div>{{ props.item.customer_phone }}</div>
                        <div>{{ props.item.customer_email }}</div> -->
                      </div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-treatments">
                      <div class="dashboard-booking__prices">
                        <div
                          class="dashboard-booking__prices-item"
                          v-for="treat in getTreatmentObjectCalculated(props.item.treatments)['data']"
                          :key="treat.id"
                        >
                          <span>{{ treat.quantity }} x {{ treat.name }}</span>
                          <span>£{{ treat.total_price }}</span>
                        </div>

                        <div
                          class="dashboard-booking__prices-item"
                          v-if="props.item.location_type === 'mobile'"
                        >
                          <span>
                             {{$t('practitioner_profile.booking_tab_call_out_charge')}}
                          </span>
                          <span>£{{priceFormat(props.item.call_out_charge)}}</span>
                        </div>

                        <div class="dashboard-booking__prices-item">
                          <span>
                            {{$t('practitioner_profile.booking_tab_fee')}}
                          </span>
                          <span>
                            £{{getFee(props.item.total_amount, props.item.discount_amount, props.item.freely_fee)}}
                          </span>
                        </div>

                        <div
                                v-if="props.item.amount_refunded"
                                class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                        >
                          <span>
                            {{$t('practitioner_profile.booking_tab_refund_total')}}
                          </span>
                          <span>£{{ getAmountOfRefund(props.item.total_amount, props.item.discount_amount) }}</span>
                        </div>

                        <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total" v-if="!props.item.amount_refunded">
                          <span>
                            {{$t('practitioner_profile.booking_tab_sub_total')}}
                          </span>
                          <span >
                            £{{ getTotalIncome(props.item.total_amount, props.item.discount_amount, props.item.freely_fee) }}
                          </span>
                        </div>

                        <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total" v-else>
                          <span>
                            {{$t('practitioner_profile.booking_tab_total_income')}}
                          </span>
                          <span >£0.00</span>
                        </div>
                      </div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-location">
                      <div class="dashboard-booking__address">{{ props.item.location.address }}</div>
                      <div class="dashboard-booking__address">{{ props.item.location.city }}</div>
                      <div class="dashboard-booking__address">{{ props.item.location.postcode }}</div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-time-date">
                      <l-map
                        ref="map"
                        :zoom="zoom"
                        :center="formatMapCenter(props.item.location.home_location)"
                        :options="{ zoomControl: false }"
                        class="map"
                      >
                        <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                        <l-tile-layer
                          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                        />
                        <l-marker
                          :lat-lng="formatMapCenter(props.item.location.home_location)"
                          :interactive="false"
                        />
                      </l-map>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- <div class="dashboard-booking__actions">
                <button class="dashboard-booking__actions-view">
                  <template v-if="true">
                    View Notes
                  </template>
                  <template v-else>
                    Hide Notes
                  </template>
                </button>
              </div> -->
            </template>
          </v-data-table>
        </div>
        <div class="dashboard-booking__mob">

          <div class="dashboard-booking__mob-table">

            <div
              class="dashboard-booking__mob-item"
              v-for="item in previousAppointments"
              :key="item.id"
            >
              <div
                      class="dashboard-booking__mob-header"
                      :class="previousClassObj(item)"
              >
                <div class="dashboard-booking__mob-header-lside">
                  <strong>{{item.customer_name | formatName }}</strong>
                  <span>{{item.id}}</span>
                </div>
                <div
                        v-if="!isCanceledBooking(item)"
                        class="dashboard-booking__mob-header-rside"
                >
                  <strong>{{item.date | formatDate}}</strong>
                </div>
                <div
                        v-else
                        class="dashboard-booking__mob-header-rside"
                >
                  <template v-if="bookingCancelBy(item) === 'customer'">
                    <strong>
                      {{$t('practitioner_profile.booking_tab_customer_cancelled')}}
                    </strong>
                  </template>
                  <template v-else-if="bookingCancelBy(item) === 'practitioner'">
                    <strong>
                      {{$t('practitioner_profile.booking_tab_practitioner_cancelled')}}
                    </strong>
                  </template>
                </div>
              </div>
              <div class="dashboard-booking__mob-body">
                <div class="dashboard-booking__mob-body-inner">
                  <div class="dashboard-booking__prices">
                    <div
                      class="dashboard-booking__prices-item"
                      v-for="treat in getTreatmentObjectCalculated(item.treatments)['data']"
                      :key="treat.id"
                    >
                      <span>{{ treat.quantity }} x {{ treat.name }}</span>
                      <span>£{{ treat.total_price }}</span>
                    </div>

                    <div
                      class="dashboard-booking__prices-item"
                      v-if="item.location_type === 'mobile'"
                    >
                      <span>
                        {{$t('practitioner_profile.booking_tab_call_out_charge')}}
                      </span>
                      <span>£{{priceFormat(item.call_out_charge)}}</span>
                    </div>

                    <div class="dashboard-booking__prices-item">
                      <span>
                        {{$t('practitioner_profile.booking_tab_fee')}}
                      </span>
                      <span>£{{getFee(item.total_amount, item.discount_amount, item.freely_fee)}}</span>
                    </div>

                    <div
                            v-if="item.amount_refunded"
                            class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                    >
                      <span>
                         {{$t('practitioner_profile.booking_tab_refund_total')}}
                      </span>
                      <span>£{{ getAmountOfRefund(item.total_amount, item.discount_amount) }}</span>
                    </div>

                    <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                         v-if="!item.amount_refunded">
                      <span>
                        {{$t('practitioner_profile.booking_tab_sub_total')}}
                      </span>
                      <span>£{{ getTotalIncome(item.total_amount, item.discount_amount, item.freely_fee) }}</span>
                    </div>
                    <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total" v-else>
                      <span>
                        {{$t('practitioner_profile.booking_tab_total_income')}}
                      </span>
                      <span>£0.00</span>
                    </div>

                  </div>
                </div>
                <div class="dashboard-booking__mob-body-inner">
                  <span class="dashboard-booking__mob-title">
                    {{$t('practitioner_profile.booking_tab_location')}}
                  </span>

                  <div class="dashboard-booking__address-holder">
                    <div class="dashboard-booking__address">{{ item.location.address }},</div>
                    <div class="dashboard-booking__address">{{ item.location.city }},</div>
                    <div class="dashboard-booking__address">{{ item.location.postcode }}</div>
                  </div>

                  <l-map
                    ref="map"
                    :zoom="zoom"
                    :center="formatMapCenter(item.location.home_location)"
                    :options="{ zoomControl: false }"
                    class="map"
                  >
                    <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                    <l-tile-layer
                      url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                      attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                    />

                    <l-marker
                      :lat-lng="formatMapCenter(item.location.home_location)"
                      :interactive="false"
                    />
                  </l-map>
                </div>
                <div class="dashboard-booking__mob-body-inner">
                  <div class="dashboard-booking__actions">
                    <!--<v-btn-->
                      <!--class="dashboard-booking__actions-edit btn-light"-->
                      <!--@click="editBooking(item)"-->
                    <!--&gt;-->
                      <!--{{$t('practitioner_profile.booking_tab_edit_booking')}}-->
                    <!--</v-btn>-->

                    <!--<v-btn-->
                      <!--class="dashboard-booking__actions-cancel error"-->
                      <!--@click="cancelBooking(item)"-->
                    <!--&gt;-->
                      <!--{{$t('practitioner_profile.booking_tab_cancel_booking')}}-->
                    <!--</v-btn>-->
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <template>
      <v-layout row justify-center>
        <v-dialog v-model="cancelBookingDialog" persistent max-width="290">
          <v-card>
            <v-card-text>{{$t('practitioner_profile.booking_tab_cancel_popup_title')}}</v-card-text>
            <v-card-text v-if="showCancelProgress">
              <v-progress-linear
                      indeterminate
                      color="error"
                      height="3"
              ></v-progress-linear>
            </v-card-text>
            <v-card-actions>
              <v-btn
                      color="red darken-1"
                      flat
                      @click="cancelBooking"
                      :disabled="disableYesCancelButton"
              >
                {{$t('practitioner_profile.booking_tab_cancel_popup_yes')}}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" flat @click="hideCancelBookingPopup">
                {{$t('practitioner_profile.booking_tab_cancel_popup_no')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>

  </div>
</template>

<script>
  import axios from "axios"
  import * as CONFIG from "@/config.js"
  import {getTreatmentObject, formatTreatments, capitalizeFirstLetter, getDisplayUserName, empty, getHeightOfElement} from '@/helpers.js';

  import {LMap, LTileLayer, LMarker} from 'vue2-leaflet'
  import 'leaflet/dist/leaflet.css'

  import moment from 'moment'

  /* eslint-disable */

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Practitioner Bookings'
    },
    components: {
      LMap,
      LTileLayer,
      LMarker
    },
    data() {
      return {
        editBookingIsVisible: false,
        bookingDateMenu: false,
        editedBookingId: null,
        editedBookingDate: null,
        editedBookingTime: null,

        cancelBookingDialog:false,
        bookingForCancellation: null,
        showCancelProgress: false,
        disableYesCancelButton: false,

        showProgress: true,
        disabledUpdateBooking: false,

        zoom: 13,
        googleLayerOptions: {
          type: 'roadmap'
        },
        expand: false,
        headers: [
          {text: this.$i18n.t('practitioner_profile.booking_tab_ref'), align: 'left', sortable: false, value: 'id'},
          {text: this.$i18n.t('practitioner_profile.booking_tab_name'), sortable: false, value: 'customer_name'},
          {text: this.$i18n.t('practitioner_profile.booking_tab_treatments'), sortable: false, value: 'treatment'},
          {text: this.$i18n.t('practitioner_profile.booking_tab_location'), sortable: false, value: 'location_type'},
          {text: this.$i18n.t('practitioner_profile.booking_tab_time_date'), value: 'date'}
        ],
        upcomingAppointments: [],
        previousAppointments: [],
      }
    },
    computed: {
      formattedBookingDate() {
        return this.editedBookingDate ? moment(this.editedBookingDate).format('DD/MM/YYYY') : '';
      }
    },
    mounted() {
      this.getPractitionerBookings().then(
        response => {
          this.initializeBookingsTables(response.data);
          this.showProgress = false;
          this.setScroll();
        },
        error => {
          console.log(error.response.data);

          this.$store.commit('SET_NOTIFICATION', {
            text: error.response.data.message,
            color: 'orange'
          });

          this.showProgress = false;
          throw error;
        }
      )
        .catch(error => {
          console.log(error.response);
          this.showProgress = false;
          throw error;
        });
    },
    filters: {
      formatTreatments: function (value) {
        return formatTreatments(value);
      },
      capitalizeFirstLetter: function (value) {
        return capitalizeFirstLetter(value);
      },
      formatDate: function (dateValue) {
        const  date = moment(dateValue, moment.ISO_8601).format('DD/MM/YYYY');
        const time = moment(dateValue, moment.ISO_8601).format("HH:mm a");
        return `${date} ${time}`;
      },
      formatName: function (name) {
        return getDisplayUserName(name);
      }
    },
    methods: {
      bookingCancelBy(booking){
        if (booking.booking_status === 'cancelled by customer') {
          return 'customer';
        } else if (booking.booking_status === 'cancelled by practitioner') {
          return 'practitioner';
        }else{
          return false;
        }
      },
      isCanceledBooking(booking) {
        if (booking.booking_status === 'cancelled by customer' || booking.booking_status === 'cancelled by practitioner') {
          return true;
        } else {
          return false;
        }
      },
      upcomingClassObj(booking){
        return {
          [`booking-${booking.id}`] : true,
          'dashboard-booking__cancelled' : this.isCanceledBooking(booking)
        }
      },
      previousClassObj(booking){
        return {
          [`booking-${booking.id}`] : true,
          'dashboard-booking__cancelled' : this.isCanceledBooking(booking)
        }
      },
      setScroll(){
        if(!empty(this.$route.query['open-booking'])){
          const that = this;
          const navHeight = getHeightOfElement('nav.header');
          setTimeout((that) => {
            that.$refs[this.$route.query['open-booking']].click();
            that.$scrollTo(`.dashboard-booking__table-header.booking-${this.$route.query['open-booking']}`, 800, {offset: Number(navHeight * -1)});
          }, 0, that);
        }else{
          this.scrollToHeader();
        }
      },
      priceFormat(value){
        return Number(value).toFixed(2);
      },
      scrollToHeader(){
        this.$scrollTo('.header.v-toolbar');
      },
      getSubTotal(treatmentsPrice, callOutCharge) {
        return (Number(treatmentsPrice) + Number(callOutCharge)).toFixed(2);
      },
      getFee(total_amount, discount_amount, freely_fee){
        const total = Number(total_amount) + Number(discount_amount);
        const fee = Number(total) * Number(freely_fee);
        return fee.toFixed(2);
      },
      getTotalIncome(total_amount, discount_amount, freely_fee){
        const fee_amount = this.getFee(total_amount, discount_amount, freely_fee);
        const total = (Number(total_amount) + Number(discount_amount)) - Number(fee_amount);
        return total.toFixed(2);
      },
      getAmountOfRefund(total_amount, discount_amount){
        return Number(total_amount) + Number(discount_amount);
      },
      getTreatmentObjectCalculated(data) {
        return getTreatmentObject(data);
      },
      formatMapCenter(coords) {
        return L.latLng(coords.lat, coords.lng);
      },
      initializeBookingsTables(data) {
        console.log('customers', JSON.parse(JSON.stringify(data)));

        this.upcomingAppointments = data.filter((booking) => {
          return (Date.parse(booking.date) > Date.now() && booking.booking_status !== 'cancelled by customer' && booking.booking_status !== 'cancelled by practitioner');
        });

        this.previousAppointments = data.filter((booking) => {
          return (Date.parse(booking.date) < Date.now() || booking.booking_status === 'cancelled by customer' || booking.booking_status === 'cancelled by practitioner');
        });
      },
      showCancelBookingPopup(booking){
        this.bookingForCancellation = booking;
        this.cancelBookingDialog = true;
      },
      hideCancelBookingPopup(){
        this.bookingForCancellation = null;
        this.cancelBookingDialog = false;
        this.showCancelProgress = false;
        this.disableYesCancelButton = false;
      },
      cancelBooking() {

        this.showCancelProgress = true;
        this.disableYesCancelButton = true;

        axios({
          method: "put",
          url: `${CONFIG.API_URL}/booking/${this.bookingForCancellation.id}/cancelByPractitioner`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(response => {
              let bookingIndex = this.upcomingAppointments.indexOf(this.bookingForCancellation);
              if(bookingIndex !== -1){
                this.previousAppointments.push(this.bookingForCancellation);
                this.upcomingAppointments.splice(this.upcomingAppointments.indexOf(this.bookingForCancellation), 1);
              }
              this.bookingForCancellation.booking_status = 'cancelled by practitioner';
              this.hideCancelBookingPopup();
              this.$store.commit('SET_NOTIFICATION', {
                text: response.data.message,
                color: 'error'
              });
            })
          .catch(error => {
            this.hideCancelBookingPopup();
            console.log(error.response.data);
            throw error;
          });
      },
      currentDate() {
        const d = new Date();
        const month = (d.getMonth() + 1) < 10 ? `0${d.getMonth() + 1}` : (d.getMonth() + 1);
        const date = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
        return `${d.getFullYear()}-${month}-${date}`;
      },
      editBooking(booking) {
        this.editBookingIsVisible = true;

        this.editedBookingId = booking.id;
        this.editedBookingDate = booking.date.substring(0, 10);
        this.editedBookingTime = booking.date.substr(-8);

        console.log('booking.date', booking.date)
        console.log('time', this.editedBookingTime)
      },
      updateBookingDate() {
        this.bookingDateMenu = false;
      },
      saveBookingEdits() {

        this.disabledUpdateBooking = true;

        let data = {
          date: this.editedBookingDate,
          start_time: this.editedBookingTime
        };

        axios({
          method: "put",
          url: `${CONFIG.API_URL}/dashboard/practitioner/booking/${this.editedBookingId}`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${this.$store.getters.userToken || CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(response => {
              this.disabledUpdateBooking = false;
              this.$store.commit('SET_NOTIFICATION', {
                text: response.data.message,
                color: 'error'
              });

              for (let i = 0; i < this.upcomingAppointments.length; i++) {
                if (this.upcomingAppointments[i]['id'] == this.editedBookingId) {
                  this.upcomingAppointments[i]['date'] = `${this.editedBookingDate} ${this.editedBookingTime}`;
                }
              }

              this.editBookingIsVisible = false;
            })
          .catch(error => {
            console.log(error);
            this.disabledUpdateBooking = false;
            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            throw error;
          });
      },
      getPractitionerBookings() {
        return axios({
          method: "get",
          url: `${CONFIG.API_URL}/dashboard/practitioner/bookings`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        });
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .dashboard-booking {

    &__cancelled{
      background-color: rgba(255,82,82, 0.3) !important;
    }

    &__title {
      display: block;
      line-height: 1.2;
      margin-bottom: 40px;
      color: #383838;
      font-family: Nunito;
      font-size: 27px;
      font-weight: 600;

      &--mt {
        margin-top: 50px;

        @include r(1700) {
          margin-top: 30px;
        }
        @include r(1500) {
          margin-top: 20px;
        }
        @include r(750) {
          margin-top: 40px;
        }
      }
      @include r(1700) {
        font-size: 23px;
        margin-bottom: 30px;
      }
      @include r(1500) {
        font-size: 21px;
        margin-bottom: 20px;
      }
      @include r(1000) {
        font-size: 19px;
      }

      @include r(750) {
        font-size: 17px;
        text-align: center;
      }
    }
    &__table {
      width: 100%;
      border-spacing: 0;
      position: relative;
      z-index: 1;
      .text-xs-center {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
      .v-table {
        thead {
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
          border-radius: 0 0 4px 4px;
          background-color: #fff;
          position: relative;
          z-index: 2;

          tr {
            border-bottom: 0 !important;

            &.v-datatable__progress {
              th {
                padding: 0 24px !important;
              }
            }
          }

          th {
            color: #383838;
            font-size: 20px;
            font-weight: 600;
            padding: 14px 24px !important;

            @include r(1700) {
              font-size: 17px;
              padding: 12px 20px !important;
            }
            @include r(1500) {
              font-size: 15px;
              padding: 10px 18px !important;
            }

            @include r(1400) {
              font-size: 14px;
            }
            @include r(1300) {
              font-size: 13px;
            }
          }

          th.column.sortable .v-icon {
            display: none !important;
          }

          th.column.sortable {
            position: relative;
            &::after {
              display: inline-block;
              margin-left: 12px;
              content: "";
              width: 21px;
              height: 11px;
              background: {
                image: url("../../.././src/images/icons/icon-arrow-down-black.svg");
                repeat: no-repeat;
                size: contain;
              }
              position: relative;
              top: 0;
              transition: 0.1s;

              @include r(1700) {
                width: 17px;
                height: 9px;
              }
              @include r(1500) {
                width: 14px;
                height: 8px;
              }
            }
          }
          th.column.sortable.active {
            &::after {
              transform: rotate(180deg);
              position: relative;
              transition: 0.1s;
            }
          }
        }

        tbody {
          position: relative;
          z-index: 1;
          tr {
            border-bottom: 0 !important;

            &.dashboard-booking__table-header {
              &:hover {
                cursor: pointer;
              }
            }

            &:hover:not(.v-datatable__expand-row) {
              background-color: transparent;
            }
          }
          td {
            color: #535353;
            font-size: 20px;
            font-weight: 400;
            word-break: break-word;
            height: auto;
            @include r(1700) {
              font-size: 17px;
            }
            @include r(1500) {
              font-size: 15px;
            }

            @include r(1400) {
              font-size: 14px;
            }
            @include r(1300) {
              font-size: 13px;
            }
            @include r(750) {
              font-size: 15px;
            }
          }
        }
      }
      &-number {
        width: 10%;
      }
      &-name {
        width: 27.5%;
      }
      &-treatments {
        width: 27.5%;
      }
      &-location {
        width: 15%;
      }
      &-time-date {
        width: 20%;

        .map {
          width: 100%;
          height: 128px;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
          border-radius: 4px;

          @include r(1700) {
            height: 120px;
          }
          @include r(1500) {
            height: 110px;
          }
          @include r(1300) {
            height: 100px;
          }
        }
      }

      &-td {
        padding: 24px !important;

        @include r(1700) {
          padding: 20px !important;
        }
        @include r(1500) {
          padding: 16px 18px !important;
        }
        @include r(1400) {
          padding: 12px 18px !important;
        }
      }
    }

    &__prices {
      display: block;

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        &--total {
          border-top: 1px solid #bfbfbf;
        }

        span {
          color: #535353;
          font-size: 20px;
          font-weight: 400;

          &:first-child {
            padding-right: 8px;
          }

          @include r(1700) {
            font-size: 17px;
          }
          @include r(1500) {
            font-size: 15px;
          }

          @include r(1400) {
            font-size: 14px;
          }
          @include r(1300) {
            font-size: 13px;
          }
          @include r(750) {
            font-size: 15px;
          }
        }
      }
    }

    &__address {
      max-width: 180px;

      &-holder {
        display: flex;
        margin-top: 14px;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -3px;

        div {
          margin: 3px;
          font-size: 15px;
          flex: 0 0 auto;
          max-width: 100%;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 24px 24px 24px;

      @include r(1700) {
        padding: 0 20px 20px 20px;
      }

      @include r(1500) {
        padding: 0 18px 16px 18px;
      }
      @include r(1400) {
        padding: 0 18px 12px 18px;
      }

      @include r(750) {
        padding: 20px;
        justify-content: center;
      }

      &-edit {
        display: inline-block;
        height: 38px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        color: #ff5a5f;
        font-family: Nunito, Sans-Serif;
        font-size: 18px;
        font-weight: 400;
        padding: 0 20px;
        min-width: 203px;
        margin: 0;

        @include r(1700) {
          min-width: 187px;
          font-size: 17px;
        }
        @include r(1500) {
          min-width: 170px;
          font-size: 15px;
          height: 34px;
        }
        @include r(1400) {
          min-width: 150px;
          height: 32px;
          font-size: 14px;
        }

        @include r(750) {
          min-width: 115px;
          height: 43px;
          font-size: 12px;
          font-weight: 700;
          margin-right: 5px;
        }
      }

      &-cancel {
        display: inline-block;
        margin: 0 0 0 20px;
        height: 38px;
        border-radius: 4px;
        color: #fff;
        font-family: Nunito;
        font-size: 18px;
        font-weight: 400;
        padding: 0 20px;
        min-width: 203px;

        @include r(1700) {
          min-width: 187px;
          font-size: 17px;
          margin-left: 18px;
        }
        @include r(1500) {
          min-width: 170px;
          font-size: 15px;
          height: 34px;
          margin-left: 16px;
        }
        @include r(1400) {
          min-width: 150px;
          height: 32px;
          font-size: 14px;
          margin-left: 12px;
        }
        @include r(750) {
          min-width: 115px;
          height: 43px;
          font-size: 12px;
          font-weight: 700;
          margin-left: 5px;
        }
      }
    }

    &__desk {
      @include r(750) {
        display: none;
      }
    }

    &__mob {
      display: none;

      @include r(750) {
        display: block;
      }

      .dashboard-booking__prices-item--total {
        border-bottom: 1px solid #bfbfbf;
      }

      &-title {
        color: #535353;
        font-family: Nunito;
        font-size: 15px;
        font-weight: 600;
      }

      &-table {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background-color: #fff;
      }

      &-item {
        background-color: #fff;

        &:nth-child(even) {
          background-color: #f9f9f9;
        }
      }

      &-header {
        display: flex;
        padding: 28px 32px;

        @include r(420) {
          padding: 20px;
        }

        &-lside {
          flex: 1 1 auto;
          padding-right: 15px;

          strong {
            display: block;
            color: #383838;
            font-family: Nunito;
            font-size: 15px;
            font-weight: 600;
          }
          span {
            display: block;
            margin-top: 4px;
            color: #b2b2b2;
            font-family: Nunito;
            font-size: 15px;
            font-weight: 600;
          }
        }

        &-rside {
          flex: 0 0 auto;
          max-width: 100px;

          strong {
            color: #383838;
            font-family: Nunito;
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
      &-body {
        display: block;
        padding: 10px 32px 28px 32px;

        @include r(420) {
          padding: 10px 20px 20px 20px;
        }

        &-inner {
          display: block;
          position: relative;
          z-index: 1;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .map {
            max-width: 240px;
            margin: 20px auto 0 auto;
            width: 100%;
            height: 148px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
          }
        }
      }
    }
  }

  .booking-edit {
    height: auto;
    padding: 24px 30px;
    text-align: center;
    width: 496px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;

    @include r(540) {
      width: 100%;
      max-width: 400px;
    }

    &__inners {
      display: block;
      margin: 40px auto 0 auto;
      max-width: 320px;
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title {
      display: block;
      color: #383838;
      font-family: Nunito;
      font-size: 27px;
      font-weight: 600;

      @include r(1700) {
        font-size: 25px;
      }
      @include r(1500) {
        font-size: 23px;
      }
      @include r(1200) {
        font-size: 21px;
      }
      @include r(750) {
        font-size: 19px;
      }
    }
    &__text {
      display: block;
      color: #383838;
      font-family: Nunito;
      font-size: 27px;
      font-weight: 600;
      padding-right: 18px;
      @include r(1700) {
        font-size: 25px;
      }
      @include r(1500) {
        font-size: 23px;
      }
      @include r(1200) {
        font-size: 21px;
      }
      @include r(7500) {
        font-size: 19px;
      }
    }

    &__field {
      display: block;
      max-width: 200px;
      width: 100%;
      .datetimepicker .datepicker{
        top: -200px !important;
        margin-bottom: 40px !important;
      }
    }
    &__actions {
      display: flex;
      margin-top: 20px;
      justify-content: center;

      button {
        width: 113px;
      }
    }

    .date-time-picker .field.has-value .field-input {
      padding-top: 0;
    }

    .datepicker {
      bottom: -125px !important;
    }
  }
</style>
