import {SET_SEARCH_AVAILABILITY} from '../mutation-types';
import repository from '@/repository/repository.js';
import _ from 'lodash';

export default {
  state: {
    searchAvailabilities:[]
  },
  mutations: {
    [SET_SEARCH_AVAILABILITY]: (state, data) => {
      _.remove(state.searchAvailabilities, function (item) {
        return item.id = data.id;
      });
      state.searchAvailabilities.push({
        id: data.id,
        practitionerSubServices: data.practitionerSubServices,
        location_type: data.appointmentLocation
      });
      repository.saveValue('searchAvailabilities', JSON.stringify(state.searchAvailabilities));
    }
  },
  actions: {},
  getters: {
    searchAvailabilityById: state => id => {
      return state.searchAvailabilities.find(availability => Number(availability.id) === Number(id));
    }
  }
}