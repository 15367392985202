<template>
  <div>
    <div class="dashboard-service__header">
      <strong>{{ services_data.name }}</strong>
    </div>
    <div class="dashboard-service__search">
      <v-text-field
        :label="formatSearchPlaceholder(services_data.name)"
        prepend-inner-icon="search"
        v-model="searchSubService"
      ></v-text-field>
    </div>

    <div class="categories__items">
      <div
        class="categories__item"
        v-for="sub in filteredSubServicesList"
        :key="sub.id"
        :class="{'is-active': sub.show}"
      >
        <div
          class="categories__item-header"
          @click="eventHandling(sub, $event)"
        >
          <div class="categories__item-header-lside">
            <v-list-tile-action v-if="sub.price && !sub.show">
              <v-icon>done</v-icon>
            </v-list-tile-action>
            <div class="categories__item-name">{{ sub.name }}</div>
            <div class="categories__item-time"></div>
          </div>
          <div class="categories__item-header-rside">
            <div
              class="categories__item-price"
              v-if="sub.price && !sub.show"
            >
              {{$t('practitioner_profile.services_tab_price')}} £{{sub.price}}</div>
            <div
              class="categories__item-icon categories__item-icon--plus"
              v-if="!sub.price || sub.show"
            >

            </div>
            <div
              v-else-if="!sub.show"
              class="categories__item-btns"
            >
              <button class="categories__drop-edit edit-button">
              </button>
              <button class="categories__drop-delete remove-button">
              </button>
            </div>
          </div>
        </div>

        <div class="categories__item-body">
          <div class="categories__item-drop">
            <div class="categories__item-body-top">
              <div class="categories__drop-table">
                <div class="categories__drop-tr">
                  <div class="categories__drop-td categories__drop-td--hidd">
                    <div class="categories__drop-time">{{$t('practitioner_profile.services_tab_price')}}</div>
                  </div>
                  <div class="categories__drop-td">
                    <div class="categories__drop-vl">£</div>
                    <div class="categories__drop-field">
                      <input
                        type="number"
                        min="0"
                        step="0.5"
                        name
                        v-model="sub.rawPrice"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="categories__drop-actions">
                <v-btn
                  class="categories__drop-save error"
                  :disabled="!sub.rawPrice"
                  @click="addPrice(sub)"
                >
                  <template v-if="!sub.price">
                    {{$t('practitioner_profile.services_tab_add_treatment')}}
                  </template>
                  <template v-else>
                    {{$t('practitioner_profile.services_tab_update_treatment')}}
                  </template>
                </v-btn>
              </div>
            </div>
            <div class="categories__item-body-bottom">
              <div class="categories__item-name">{{ sub.name }}</div>
              <div class="categories__item-time"></div>
            </div>
          </div>
        </div>
        <div
          @click="sub.show = false"
          class="categories__item-overlay"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as CONFIG from "@/config.js";

/* eslint-disable */

export default {
  props: ['item'],
  data: function () {
    return {
      services_data: this.item,
      sub_services_data: this.item.sub_services,
      searchSubService: ''
    }
  },
  computed: {
    filteredSubServicesList () {
      return this.sub_services_data.filter(item => {
        return item.name.toLowerCase().includes(this.searchSubService.toLowerCase());
      });
    }
  },
  methods: {
    formatSearchPlaceholder (service_name) {
      return this.$i18n.t('practitioner_profile.services_tab_search_treatments', {service: service_name.toLowerCase()})
    },
    sortSubServices () {
      this.sub_services_data = _.orderBy(this.sub_services_data, ['order', 'name'], ['asc', 'asc']);
    },
    eventHandling (sub, event) {
      if (event.target.classList.contains('remove-button')) {
        this.removePrice(sub);
      } else if (event.target.classList.contains('edit-button')) {
        this.openItem(sub);
      } else {
        this.openItem(sub);
      }
    },
    openItem (sub) {
      for (let i = 0; i < this.sub_services_data.length; i++) {
        if (this.sub_services_data[i]['id'] != sub.id) {
          this.sub_services_data[i]['show'] = false;
        }
      }

      sub.show = (sub.show) ? false : true;
    },
    addPrice (sub) {
      sub.price = parseFloat(sub.rawPrice).toFixed(2);
      sub.order = 1;
      this.openItem(sub);

      const data = { sub_service_id: sub.id, price: sub.price, duration: sub.default_duration };

      if (sub.available) {
        this.addOrUpdatePractitionerSubService(data, 'put');
      } else {
        this.addOrUpdatePractitionerSubService(data, 'post');
        sub.available = true;
      }
    },
    removePrice (sub) {
      sub.price = '';
      sub.rawPrice = '';
      sub.order = 2;
      sub.available = false;

      const data = { sub_service_id: sub.id };
      this.removePractitionerSubService(data);
    },

    addOrUpdatePractitionerSubService (data, method) {
      axios({
        method: method,
        url: `${CONFIG.API_URL}/dashboard/practitioner/service`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
        }
      })
        .then(
          response => {
            if (method === 'put') {
              this.$store.commit('SET_NOTIFICATION', {
                text: this.$i18n.t('practitioner_profile.services_tab_notification_treatment_updated'),
                color: 'error'
              });
            }
            if (method === 'post') {
              this.sortSubServices();

              this.$store.commit('SET_NOTIFICATION', {
                text: this.$i18n.t('practitioner_profile.services_tab_notification_treatment_added'),
                color: 'error'
              });
            }
          },
          error => {
            console.log(error.response.data);
            throw error;
          }
        )
        .catch(error => {
          console.log(error.response);
          throw error;
        });
    },
    removePractitionerSubService (data) {
      axios({
        method: "delete",
        url: `${CONFIG.API_URL}/dashboard/practitioner/service`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
        }
      })
        .then(
          response => {
            this.sortSubServices();

            this.$store.commit('SET_NOTIFICATION', {
              text: this.$i18n.t('practitioner_profile.services_tab_notification_treatment_removed'),
              color: 'error'
            });
          },
          error => {
            console.log(error.response.data);
            throw error;
          }
        )
        .catch(error => {
          console.log(error.response);
          throw error;
        });
    }

  }
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.dashboard-services {
  .dashboard-service {
    display: block;
    margin-bottom: 50px;
    max-width: 690px;
    width: 100%;

    @include r(1000) {
      margin: 0 auto 30px auto;
    }

    @include r(700) {
      margin: 0 auto 15px auto;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__header {
      display: block;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      background-color: #fff;
      padding: 22px 83px 21px 83px;
      position: relative;

      @include r(1700) {
        padding: 20px 60px;
      }

      @include r(1500) {
        padding: 18px 40px;
      }

      @include r(700) {
        background-color: transparent;
        box-shadow: none;
        text-align: center;
        padding: 16px 20px;
      }

      strong {
        color: #535353;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 600;
        line-height: 1;

        @include r(1700) {
          font-size: 21px;
        }

        @include r(1500) {
          font-size: 19px;
        }

        @include r(1300) {
          font-size: 17px;
        }

        @include r(700) {
          font-size: 16px;
        }
      }
    }

    &__search {
      display: block;
      padding: 15px 83px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0 0 4px 4px;
      background-color: #fff;
      position: relative;
      z-index: 1;

      @include r(1700) {
        padding: 10px 60px;
      }

      @include r(1500) {
        padding: 8px 40px;
      }
      @include r(700) {
        padding: 7px 20px;
      }

      .v-input {
        margin: 0;
      }
      .v-text-field__details {
        display: none;
      }
    }

    &__items {
      display: block;
    }

    .categories {
      display: block;
      padding: 20px 80px;

      &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style-type: none;
        padding: 0;
        text-align: left;
        width: 100%;
        max-height: 444px;
        overflow-y: auto;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);

        @include r(1700) {
          max-height: 408px;
        }
        @include r(1500) {
          max-height: 366px;
        }
        @include r(700) {
          max-height: 324px;
        }
      }

      &__item {
        flex: 1 0 100%;
        outline: none;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        max-width: 100%;

        &:nth-child(even) {
          background-color: #f8f8f8;
        }

        &.is-active {
          .categories__item-body {
            display: block;
          }

          .categories__item-overlay {
            @include r(700) {
              display: block;
            }
          }

          .categories__item-drop {
            @include r(700) {
              position: fixed;
              max-width: 338px;
              top: 50%;
              left: 50%;
              z-index: 40;
              transform: translate(-50%, -50%);
            }
          }

          .categories__item-icon {
            .v-icon {
              transform: rotate(-180deg);
            }
          }
        }

        &:first-child {
          border-top: none !important;
        }

        &-header {
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: space-between;
          position: relative;
          padding: 22px 24px 22px 83px;
          min-height: 48px;

          @include r(1700) {
            padding: 20px 24px 20px 60px;
          }

          @include r(1500) {
            padding: 18px 24px 18px 60px;
          }
          @include r(700) {
            padding: 16px 16px 16px 46px;
          }

          .v-list__tile__action {
            min-width: 45px;
            position: absolute;
            top: 50%;
            left: 33px;
            transform: translateY(-50%);

            @include r(1700) {
              left: 18px;
            }
            @include r(700) {
              left: 13px;
              min-width: 26px;
            }
            .v-icon {
              color: #ff5354;
              font-size: 26px;
              @include r(700) {
                font-size: 22px;
              }
            }
          }

          &-lside {
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            padding-right: 20px;
          }

          &-rside {
            display: flex;
            align-items: center;
            flex: 0 0 auto;
          }
        }

        &-btns {
          display: flex;
          align-items: center;
          margin-left: 20px;

          @include r(700) {
            margin-left: 10px;
          }
        }

        &-icon {
          &--plus {
            width: 22px;
            height: 22px;
            background: {
              image: url("../../.././src/images/icons/icon-plus.svg");
              repeat: no-repeat;
              size: contain;
            }
            opacity: 1;

            @include r(1700) {
              width: 20px;
              height: 20px;
            }
            @include r(1500) {
              width: 18px;
              height: 18px;
            }
            @include r(700) {
              width: 17px;
              height: 17px;
            }
          }
        }

        &-time {
          display: inline-block;
          color: #a3a3a3;
          font-size: 20px;
          font-weight: 600;
        }

        &-name {
          display: inline-block;
          margin-right: 10px;
          color: #535353;
          font-size: 20px;
          font-weight: 400;

          @include r(1700) {
            font-size: 19px;
          }
          @include r(1500) {
            font-size: 17px;
          }
          @include r(700) {
            font-size: 15px;
          }
        }

        &-price {
          color: #535353;
          font-size: 16px;
          font-weight: 400;

          @include r(1500) {
            font-size: 14px;
          }
          @include r(700) {
            font-size: 13px;
          }
          @include r(500) {
            display: none;
          }
        }

        &-body {
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
          display: none;
          padding: 15px;

          @include r(700) {
            padding: 0;
          }

          &-top {
            @include r(700) {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 8px 10px 40px;
              box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
              border-radius: 4px;
              background-color: #fff;
            }
          }

          &-bottom {
            display: none;
            margin-top: 6px;
            padding: 13px 40px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            background-color: #fff;

            @include r(700) {
              display: block;
            }
          }
        }

        &-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 35;
          display: none;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

      &__add {
        display: block;

        &-btn {
          font-size: 17px;
          color: rgba(0, 0, 0, 0.87);
          font-weight: 400;
          outline: none;
          margin-top: 2px;
        }
      }

      &__edit {
        display: none;
        align-items: center;

        &-btn {
          display: inline-block;
          outline: none;

          i {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.87) !important;
          }
        }
        &-done {
          display: inline-block;
          margin-left: 3px;

          i {
            font-size: 16px;
            color: #ff5252 !important;
          }
        }
      }

      &__holder {
        position: relative;
        display: inline-block;
        margin-top: 25px;
      }
      &__drop {
        position: absolute;
        background-color: #fff;
        top: 0;
        left: calc(100% + 1px);
        width: 310px;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        padding: 10px 15px;

        &-settings {
          font-size: 15px;
          color: rgba(0, 0, 0, 0.8);
          font-weight: 500;
          display: block;
          text-align: center;
        }

        &-table {
          display: block;
        }

        &-actions {
          display: block;
          margin-top: 10px;
          text-align: center;
          @include r(700) {
            margin: 0 0 0 50px;
          }
        }

        &-vl {
          display: none;
          color: #535353;
          font-family: Nunito;
          font-size: 20px;
          font-weight: 400;
          margin-right: 3px;

          @include r(700) {
            display: block;
          }
        }

        &-field {
          display: block;
          width: 100%;
          max-width: 150px;

          input {
            display: block;
            width: 100%;
            height: 100%;
            outline: none;
            border: 0;
            text-align: center;
            padding: 0 12px;
            color: #535353;
            font-family: Nunito;
            font-size: 25px;
            font-weight: 400;
            border-bottom: 1px solid #e1e1e1;
            &::placeholder {
              color: #a3a3a3;
            }

            @include r(1700) {
              font-size: 21px;
            }
            @include r(1500) {
              font-size: 19px;
            }
            @include r(700) {
              font-size: 16px;
              max-width: 90px;
              min-width: 90px;
              padding: 0 8px;
            }
          }
        }

        &-cancel {
          font-size: 14px;
          color: #7b8994;
          font-weight: 500;
          margin-left: -12px;
          outline: none;

          &:hover {
            text-decoration: underline;
          }
        }
        &-save {
          min-width: 203px;
          height: 38px;
          border-radius: 4px;
          opacity: 1;
          margin: 0;

          @include r(700) {
            min-width: 115px;
            height: 43px;
            border-radius: 4px;
            font-size: 12px;
          }

          &.theme--light.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
            background-color: #ff5252 !important;
          }

          &.v-btn--disabled {
            background-color: #ff5252 !important;
            color: #fff !important;
            opacity: 0.4;
          }
        }

        &-edit {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: {
            image: url("../../.././src/images/icons/icon-edit.svg");
            repeat: no-repeat;
            size: contain;
          }
          outline: none;
          transition: 0.3s;

          @include r(1700) {
            width: 19px;
            height: 19px;
          }
          @include r(1500) {
            width: 17px;
            height: 17px;
          }
          @include r(700) {
            width: 15px;
            height: 15px;
          }

          &:hover {
            opacity: 0.7;
            transition: 0.3s;
          }
        }

        &-delete {
          display: inline-block;
          margin-left: 16px;
          width: 20px;
          height: 20px;
          background: {
            image: url("../../.././src/images/icons/icon-delete.svg");
            repeat: no-repeat;
            size: contain;
          }
          outline: none;
          transition: 0.3s;

          @include r(1700) {
            width: 19px;
            height: 19px;
          }
          @include r(1500) {
            width: 17px;
            height: 17px;
          }

          @include r(700) {
            width: 15px;
            height: 15px;
            margin-left: 8px;
          }
          @include r(500) {
            margin-left: 15px;
          }

          &:hover {
            opacity: 0.7;
            transition: 0.3s;
          }
        }

        &-tr {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);

          &:last-child {
            border-bottom: 0;
          }

          @include r(700) {
            display: block;
          }
        }
        &-td {
          flex: 0 0 50%;
          padding: 5px;
          text-align: center;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;

          &:last-child {
            border-right: 0;
          }

          &--hidd {
            @include r(700) {
              display: none;
            }
          }
        }

        &-time {
          display: block;
          color: #535353;
          font-size: 23px;
          font-weight: 400;

          @include r(1700) {
            font-size: 21px;
          }
          @include r(1500) {
            font-size: 19px;
          }
          @include r(700) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>