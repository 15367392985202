<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="error">
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Discount from administrator, %</v-subheader>
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-form
                      ref="discountForm"
                      v-model="discountFormValid"
                      lazy-validation
              >
                <v-text-field
                        name="discount"
                        placeholder="Discount"
                        type="text"
                        :rules="discountRules"
                        v-model="discount"
                        :error-messages="discountErrorMess"
                        mask="##"
                        @focus="clearErrorsFromServer('discount')"
                ></v-text-field>
              </v-form>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-btn
                      color="error"
                      @click="saveDiscount()"
                      :disabled="!discountFormValid"
              >Save</v-btn>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

  import axios from "axios"
  import * as CONFIG from "@/config.js"
  import {empty} from '@/helpers.js';

  export default {
    name: "customer-settings-popup",
    data(){
      return{
        customerId: null,
        dialog: false,
        discount: '',
        discountRules: [
          v => !!v || 'Discount field is required',
        ],
        discountErrorMess:[],
        discountFormValid: true
      }
    },
    methods:{
      openPopupForCustomer(customerId) {
        this.customerId = customerId;
        this.initPopup();
      },
      initPopup(){
        this.getAdminDiscountForCustomer()
          .then((response) =>{
            this.discount = response.data.discount;
            this.dialog = true;
          })
          .catch((error) => {
            if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
          })
      },
      getAdminDiscountForCustomer() {
        return axios.get(`${CONFIG.API_URL}/dashboard/admin/admin-discount-for-customer/${this.customerId}`, {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
          }
        });
      },
      setAdminDiscountForCustomer(data){
        return axios({
          method: 'post',
          url: `${CONFIG.API_URL}/dashboard/admin/admin-discount-for-customer/${this.customerId}`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      saveDiscount() {
        if (this.$refs.discountForm.validate()) {
          const data = {
            discount: this.discount,
          };
          this.setAdminDiscountForCustomer(data)
            .then(() => {
              this.$store.commit('SET_NOTIFICATION', {
                text: 'Settings successfully updated',
                color: 'error'
              });
            })
            .catch((error) => {
              if (!empty(error.response) && !empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              } else if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
                this.$store.commit('SET_NOTIFICATION', {
                  text: error.response.data.message,
                  color: 'orange'
                });
              }
            });
        }
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      clearErrorsFromServer(field){
        this[`${field}ErrorMess`] = [];
      }

    }
  }
</script>

<style>
  .v-dialog--fullscreen{
    width: 100% !important;
  }

</style>