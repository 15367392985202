<template>
  <div
    class="dashboard-content dashboard-booking"
    id="dashboard-content"
  >
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="dashboard-content__title">
      <h2>Bookings</h2>
    </div>
    <div class="dashboard-content__body">
      <template>

        <div class="datepicker-trigger">
          <input
                  type="text"
                  id="datepicker-trigger"
                  placeholder="Select dates"
                  :value="formatDates(dateOne, dateTwo)"
                  style="border:1px solid rgba(0, 0, 0, 0.2);padding:6px 10px"
          >

          <AirbnbStyleDatepicker
                  :trigger-element-id="'datepicker-trigger'"
                  :mode="'range'"
                  :fullscreen-mobile="true"
                  :date-one="dateOne"
                  :date-two="dateTwo"
                  @date-one-selected="val => { dateOne = val }"
                  @date-two-selected="val => { dateTwo = val }"
          />
        </div>
        <div>
          <v-btn
                  small
                  color="primary"
                  @click="downloadBookingXls"
           >Download Bookings XLS
          </v-btn>
         </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import * as CONFIG from "@/config.js"
import { vueTopprogress } from 'vue-top-progress'
import format from 'date-fns/format'
import { empty } from '@/helpers.js';


/* eslint-disable */

export default {
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Admin: Bookings'
  },
  components: {
      vueTopprogress,
  },
  data () {
    return {
      showProgress: true,
        dateFormat: 'D MMM',
        dateOne: '',
        dateTwo: ''

    }
  },
  mounted () {
  },
  filters: {
  },
  methods: {
    formatDates(dateOne, dateTwo) {
      let formattedDates = ''
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat)
      }
      if (dateTwo) {
        formattedDates += ' - ' + format(dateTwo, this.dateFormat)
      }
      return formattedDates
    },
    downloadBookingXls() {
      if(empty(this.dateOne) || empty(this.dateTwo)) {
          this.$store.commit('SET_NOTIFICATION', {
              text: 'Please select dates before load report',
              color: 'orange'
          });

          return;
      }

      console.log(this.dateOne, this.dateTwo);
      this.$refs.topProgress.start();
      axios({
        method: "get",
        url: `${CONFIG.API_URL}/dashboard/admin/getBookings?from=${this.dateOne}&to=${this.dateTwo}`,
        responseType: 'blob',
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken}`
        }
      }).then(
          response => {
              this.$refs.topProgress.done()
              this.loadingData = false;
              let headers = response.headers;
              console.log(headers['content-type']);
              let blob = new Blob([response.data],{type:headers['content-type']});
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "bookings.xls";
              link.click();
          },
          error => {
              this.$refs.topProgress.done()
              //console.log(error.response.data);
              this.$store.commit('SET_NOTIFICATION', {
                  text: 'Something goes wrong',
                  color: 'orange'
              });
              throw error;
          }

        )
        .catch(error => {
            this.$refs.topProgress.done()
            this.$store.commit('SET_NOTIFICATION', {
                text: 'Something goes wrong',
                color: 'orange'
            });
            //console.log(error.response.data);
            throw error;
         });
    }
  },
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.dashboard-content {
  display: block;
  flex: 1 1 auto;
  width: 100%;
  padding: 50px 120px 120px 100px;

  @include r(1700) {
    padding: 50px 100px 100px 80px;
  }

  @include r(1500) {
    padding: 40px 60px 60px 60px;
  }

  @include r(1300) {
    padding: 30px 40px 60px 40px;
  }
  @include r(1200) {
    padding: 28px 28px 40px 28px !important;
  }

  @include r(900) {
    padding: 28px 16px 20px 16px !important;
  }

  &__title {
    padding-bottom: 24px;

    @include r(1000) {
      padding-bottom: 0;
    }

    h2 {
      display: block;
      color: #383838;
      font-size: 37px;
      font-weight: 600;
      line-height: 1.1;

      @include r(1700) {
        font-size: 34px;
      }

      @include r(1500) {
        font-size: 32px;
      }

      @include r(1400) {
        font-size: 30px;
      }

      @include r(1300) {
        font-size: 28px;
      }

      @include r(1000) {
        display: none;
      }
    }
  }

  &__body {
    padding: 40px 22px 0 22px;
    position: relative;

    @include r(1200) {
      padding: 40px 0 0 0;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      height: 1px;
      left: 22px;
      width: calc(100% - 44px);
      background-color: #f7cecf;

      @include r(1200) {
        width: 100%;
        left: 0;
      }
      @include r(1000) {
        display: none;
      }
    }
  }

  .v-avatar {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
</style>
