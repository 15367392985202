<template>
  <div class="dashboard-content dashboard-calendar" id="dashboard-content">
    <div class="dashboard-content__title">
      <h2>
        {{$t('practitioner_profile.calendar_tab_title')}}
      </h2>
      <p>
        {{$t('practitioner_profile.calendar_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">
      <v-app id="dayspan" v-cloak>
        <ds-calendar-app
          ref="app"
          :calendar="calendar"
          :read-only="readOnly"
          @cancel="closePopup"
        >

          <template slot="title"><p></p></template>

          <template slot="today"><p></p></template>

          <template slot="prev" slot-scope="{prev, prevLabel}">

            <v-tooltip class="dashboard-calendar__prev">
              <v-btn slot="activator"
                     icon depressed class="ds-light-forecolor ds-skinny-button"
                     @click="prev">
                <v-icon>keyboard_arrow_left</v-icon>
              </v-btn>
              <span>{{ prevLabel }}</span>
            </v-tooltip>

          </template>

          <template slot="summary">
            <h1 class="dashboard-calendar__title">
              {{ customSummary }}
            </h1>
          </template>

          <template slot="next" slot-scope="{next, nextLabel}">
            <v-tooltip class="dashboard-calendar__next">
              <v-btn slot="activator"
                     icon depressed
                     class="ds-light-forecolor ds-skinny-button"
                     @click="next">
                <v-icon>keyboard_arrow_right</v-icon>
              </v-btn>
              <span>{{ nextLabel }}</span>

            </v-tooltip>
          </template>

          <template slot="view" slot-scope="{currentType, types}">
            <customViewForSlot
              :currentType="currentType"
              :types="types"
              :mobileWidth="mobileWidth"
              @custom-set-type="setCurrentViewType"
            >
            </customViewForSlot>
          </template>

          <template slot="eventPopover" slot-scope="slotData">
            <customCalendarEventPopover
              v-bind="slotData"
              :read-only="eventPopupReadOnly(slotData)"
              :allowEditOnReadOnly="eventPopupAllowEdit(slotData)"
              @custom-remove="eventRemove"
            >
              <template slot="eventPopoverBodyTop" slot-scope="slotData">
                <v-list-tile>
                  <v-list-tile-avatar>
                    <v-icon>access_time</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>{{eventPopupDate(slotData)}}</v-list-tile-title>
                    <v-list-tile-sub-title>{{eventPopupTime(slotData)}}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </customCalendarEventPopover>
          </template>

          <template slot="scheduleTitle" slot-scope="{schedule, calendarEvent, details}">
            <v-form
              ref="titleForm"
              v-model="createEventPopup.isTitleFormValid"
            >
              <v-text-field single-line hide-details solo flat
                            class="ds-event-title"
                            label="Title"
                            :readonly="false"
                            v-model="details.title"
                            @input="createEventPopupCheckTitle"
                            :rules="createEventPopup.titleRules"
              ></v-text-field>
            </v-form>
          </template>

          <template slot="scheduleSave">
            <div></div>
          </template>

          <template slot="scheduleActions" slot-scope="{calendarEvent, schedule, calendar, actioned, readOnly}">
            <v-tooltip bottom v-if="!readOnly && calendarEvent">
              <customScheduleActions
                slot="activator"
                v-bind="{$scopedSlots}"
                v-on="$listeners"
                :schedule="calendarEvent.schedule"
                :calendar-event="calendarEvent"
                :calendar="calendar"
                @custom-remove="eventRemove"
                @finish="actioned"
              >
                <v-btn icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </customScheduleActions>
              <span>Remove event</span>
            </v-tooltip>
          </template>

          <template slot="schedule" slot-scope="slotData">
            {{createEventPopupSetSlotData(slotData)}}

            <v-list>
              <v-list-tile>
                <v-list-tile-avatar>
                  <v-icon>access_time</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{createEventPopupDate(slotData)}}</v-list-tile-title>
                  <v-list-tile-sub-title>{{createEventPopupTime(slotData)}}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>

            <div class="checkout__form-field checkout__form-field--payment-hide">
              <v-text-field
                type="hidden"
                :error-messages="createEventPopup.messageErrorMess"
                solo
              ></v-text-field>
            </div>

            <customEventPopupButtons
              :schedule="slotData.schedule"
              :calendarEvent="slotData.calendarEvent"
              :calendar="slotData.calendar"
              :details="slotData.details"
              :day="slotData.day"
              :isCanSaveEvent="isCanSaveEvent"
              @custom-save="eventSave"
              @custom-update="eventUpdate"
            >
            </customEventPopupButtons>
          </template>

          <template slot="eventTimeTitle" slot-scope="{calendarEvent, details}">
            <div>
              <v-icon class="ds-ev-icon"
                      v-if="details.icon"
                      size="14"
                      :style="{color: details.forecolor}">
                {{ details.icon }}
              </v-icon>
              <strong class="ds-ev-title">{{ details.title }}</strong>
            </div>
            <div class="ds-ev-description">{{ getSelectedTime( calendarEvent ) }}</div>
          </template>

        </ds-calendar-app>
      </v-app>

      <customEventPopoverForMobile
        :mobilePopover="mobilePopover"
        @closeCustomMobilePopup="mobilePopover = false"
        @updateCalendarState="setCalendarState"
      ></customEventPopoverForMobile>

      <v-btn fab dark
             @click="mobilePopover = true"
             class="dashboard-calendar__add-event error"
      >
        <v-icon dark>add</v-icon>
      </v-btn>

    </div>
  </div>
</template>

<script>
  import {Calendar} from 'dayspan';
  import 'material-design-icons-iconfont/dist/material-design-icons.css'
  import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css';
  import moment from 'moment';
  import _ from 'lodash'
  import axios from 'axios'
  import * as CONFIG from '@/config.js'
  import {empty, isEmptyObj, getWidthOfWindow} from '@/helpers.js';

  import customCalendarEventPopover from '@/components/Calendar/CustomCalendarEventPopover';
  import customScheduleActions from '@/components/Calendar/CustomScheduleActions';
  import customEventPopupButtons from '@/components/Calendar/CustomEventPopupButtons';
  import customViewForSlot from '@/components/Calendar/CustomViewForSlot';
  import customEventPopoverForMobile from '@/components/Calendar/CustomEventPopoverForMobile';

  /* eslint-disable */

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Practitioner Calendar'
    },
    components: {
      customCalendarEventPopover,
      customScheduleActions,
      customEventPopupButtons,
      customViewForSlot,
      customEventPopoverForMobile
    },
    data() {
      return {
        calendar: Calendar.weeks(),
        readOnly: false,
        calendarEvents: [],
        bookingEvents: [],
        createEventPopup: {
          slotData: null,
          isTitleFormValid: false,
          titleRules: [
            v => !!v || "Title is required",
          ],
          messageErrorMess: [],
        },
        mobileWidth: false,
        displayDaysForMobile: 3,

        mobilePopover: false,
        beforeResizeWidth: ''
      }
    },
    created: function () {
      window.addEventListener('orientationchange', this.checkWindowSize);
    },
    destroyed: function () {
      window.addEventListener('orientationchange', this.checkWindowSize);
    },
    mounted: function () {
      this.changeDefaultSettings();
      this.setWindowSize();
    },
    computed: {
      isCanSaveEvent() {
        return this.createEventPopup.isTitleFormValid && empty(this.createEventPopup.messageErrorMess)
      },
      customSummary() {
        let large = this.$vuetify.breakpoint.mdAndUp;

        return this.calendar.summary(false, !large, false, !large);
      },
    },
    methods: {
      setWindowSize() {
        this.beforeResizeWidth = getWidthOfWindow();
      },
      changeDefaultSettings() {
        if (getWidthOfWindow() < 600) {
          this.mobileWidth = true;
          const index = _.findIndex(this.$dayspan.defaults.dsCalendarApp.types, ['id', 'X']);
          if (!empty(index)) {
            this.$dayspan.defaults.dsCalendarApp.types[index].size = this.displayDaysForMobile;
            this.$dayspan.defaults.dsCalendarApp.types[index].label = '3 Days';
            this.calendar = Calendar.days(this.displayDaysForMobile);
          }
        } else {
          this.mobileWidth = false;
          this.calendar = Calendar.weeks();
        }
        this.setCalendarState()
      },
      checkWindowSize: _.debounce(function () {
        if (this.beforeResizeWidth != getWidthOfWindow()) {
          this.beforeResizeWidth = getWidthOfWindow();
          this.changeDefaultSettings();
        }
      }, 200),
      setCalendarState() {
        const that = this;
        this.getEvents()
          .then((response) => {
            that.calendarEvents = that.preparedCalendarData(response.data);
            that.getBookingEvents()
              .then((response) => {
                that.bookingEvents = that.preparedCalendarData(response.data, 'booking');
                that.$refs.app.setState({events: _.concat(that.calendarEvents, that.bookingEvents)});
              });
          })
          .catch((error) => {
            if (error.response && !empty(error.response.data)) {
              this.addError(error.response.data)
            }
          });
      },
      setCurrentViewType(type) {
        this.$refs.app.currentType = type;
      },
      eventPopupReadOnly(slotData) {
        if (slotData.calendarEvent.data.type === 'booking') {
          return true;
        } else {
          return false;
        }
      },
      eventPopupAllowEdit(slotData) {
        if (slotData.calendarEvent.data.type === 'booking') {
          return false;
        } else {
          return true
        }
      },
      eventPopupDate(slotData) {
        let start = slotData.calendarEvent.start.date.clone().format("dddd Do MMM");
        let end = slotData.calendarEvent.end.date.clone().format("dddd Do MMM");
        return (start == end)? start: (start + ' - ' + end);
      },
      eventPopupTime(slotData) {
        if (slotData.calendarEvent.schedule.durationUnit === 'days') {
          if (slotData.calendarEvent.schedule.duration === 1) {
            return 'Full day';
          } else {
            return 'Full days';
          }
        } else {
          return this.getSelectedTime(slotData.calendarEvent);
        }
      },
      createEventPopupSetSlotData(slotData) {
        this.createEventPopup.slotData = slotData;
      },
      createEventPopupCheckTitle() {
        this.$refs.titleForm.validate();
      },
      createEventPopupDate(slotData) {
        let startOfDay = slotData.day.date.clone().startOf('day');
        let startTime;
        let endTime;
        let start;
        let end;

        if (!empty(slotData.schedule.times[0])) {
          startTime = startOfDay.clone().add(slotData.schedule.times[0].hour, 'hours').add(slotData.schedule.times[0].minute, 'minutes');
        } else {
          startTime = startOfDay;
        }

        start = startTime.clone().format('dddd Do MMM');

        if (slotData.schedule.durationUnit === 'days') {
          endTime = startTime.clone().add((slotData.schedule.duration - 1), slotData.schedule.durationUnit);
        } else {
          endTime = startTime.clone().add((slotData.schedule.duration), slotData.schedule.durationUnit);
        }

        end = endTime.clone().format('dddd Do MMM');

        if (startTime.dayOfYear() !== endTime.dayOfYear()) {
          return `${start}-${end}`
        }
        return slotData.day.date.clone().format("dddd Do MMM");
      },
      createEventPopupTime(slotData) {
        if (slotData.schedule.durationUnit === 'days') {
          if (slotData.schedule.duration === 1) {
            return 'Full day';
          } else {
            return 'Full days';
          }
        } else if (slotData.schedule.durationUnit === 'hours' || slotData.schedule.durationUnit === 'minutes') {
          let startOfDay = slotData.day.date.clone().startOf('day');
          let startTime;
          let endTime;
          if (!empty(slotData.schedule.times[0])) {
            startTime = startOfDay.clone().add(slotData.schedule.times[0].hour, 'hours').add(slotData.schedule.times[0].minute, 'minutes');
          } else {
            startTime = startOfDay;
          }
          if (slotData.schedule.durationUnit === 'days') {
            endTime = startTime.clone().add((slotData.schedule.duration - 1), slotData.schedule.durationUnit);
          } else {
            endTime = startTime.clone().add((slotData.schedule.duration), slotData.schedule.durationUnit);
          }

          return this.getSelectedTime({start: startTime, end: endTime});
        }
      },
      closePopup() {
        this.createEventPopup.messageErrorMess = [];
      },
      getBookingEvents() {
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/practitioner/calendar/bookings`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      getEvents() {
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/practitioner/timeoff`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      preparedCalendarData(data, type = 'time_off') {
        let calendarEvents = [];
        let obj;

        for (let i = 0; i < data.length; i++) {

          let event = data[i];

          obj = {};
          obj['data'] = {};
          obj['schedule'] = {};

          obj['data']['title'] = (type === 'time_off') ? ((event.title) ? event.title : 'no title') : 'Booking';
          obj['data']['id'] = event.id;
          obj['data']['color'] = (type === 'time_off') ? '#1976d2' : '#ff6666';
          obj['data']['busy'] = true;
          obj['data']['type'] = type;

          obj['schedule']['year'] = [moment(event.date, "YYYY-MM-DD").format("YYYY")];
          obj['schedule']['month'] = [Number(moment(event.date, "YYYY-MM-DD").format("M")) - 1];
          obj['schedule']['dayOfMonth'] = [moment(event.date, "YYYY-MM-DD").format("D")];


         if(event.date !== event.end_date || event.end_date !== null) {
           obj['schedule']['duration'] = moment(event.end_date, "YYYY-MM-DD").diff(moment(event.date, "YYYY-MM-DD"), 'days') +1;
           obj['schedule']['durationUnit'] = 'days';
         }

          if (event.full_day_off === false || type === 'booking') {
            obj['schedule']['times'] = [moment(event.start_time, "HH:mm:ss").format("HH:mm")];
            obj['schedule']['durationUnit'] = "minutes";
            obj['schedule']['duration'] = moment(event.end_time, "HH:mm:ss").diff(moment(event.start_time, "HH:mm:ss"), 'minutes');
          }

          calendarEvents.push(obj);
        }

        return calendarEvents;
      },
      eventUpdate(ev) {
        const that = this;
        const id = ev.details.id;
        let obj = this.getStartEndTime(ev);
        obj['title'] = ev.details.title;

        axios({
          method: 'put',
          url: `${CONFIG.API_URL}/dashboard/practitioner/timeoff/${id}`,
          data: JSON.stringify(obj),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
          .then(() => {
            this.setCalendarState();
            this.$refs.app.$refs.eventDialog.saved(ev);
          })
          .catch((error) => {
            if (error.response && !empty(error.response.data)) {
              this.addError(error.response.data)
            }
          });
      },
      eventSave(ev) {
        let obj = this.getStartEndTime(ev);
        obj['title'] = ev.details.title;

        if (!isEmptyObj(obj)) {
          axios({
            method: 'post',
            url: `${CONFIG.API_URL}/dashboard/practitioner/timeoff`,
            data: JSON.stringify(obj),
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
          })
            .then(() => {
              this.setCalendarState();
              this.$refs.app.$refs.eventDialog.saved(ev);
            })
            .catch((error) => {
              if (error.response && !empty(error.response.data)) {
                this.addError(error.response.data)
              }
            });
        }
      },
      addError(errors) {
        if (!isEmptyObj(errors.errors)) {
          for (let field in errors.errors) {
            for (let i = 0; i < errors.errors[field].length; i++) {
              this.createEventPopup.messageErrorMess.push(errors.errors[field][i]);
            }
          }
        } else if (!empty(errors.message)) {
          this.createEventPopup.messageErrorMess.push(errors.message);
        }
      },
      getStartEndTime(data) {
        let momentDate = data.day.date.clone();
        let obj = {};


        obj['date'] = momentDate.clone().format("YYYY-MM-DD");
        obj['end_date'] = momentDate.clone().add((data.schedule.durationInDays), data.schedule.durationUnit).format("YYYY-MM-DD");


        if (data.schedule.durationUnit === 'hours' || data.schedule.durationUnit === 'minutes') {
          obj['full_day_off'] = false;

          let startOfDay = momentDate.clone().startOf('day');
          let startTime;

          if (!empty(data.schedule.times[0])) {
            startTime = startOfDay.clone().add(data.schedule.times[0].hour, 'hours').add(data.schedule.times[0].minute, 'minutes');
            obj['start_time'] = startTime.clone().format('HH:mm:ss');
          } else {
            startTime = startOfDay;
            obj['start_time'] = startTime.clone().format('HH:mm:ss');
          }

          obj['end_time'] = startTime.clone().add((data.schedule.duration), data.schedule.durationUnit).format('HH:mm:ss');
        } else {
          obj['full_day_off'] = true;
        }

        return obj;
      },
      eventRemove(data) {
        const id = data.calendarEvent.data.id;

        if (!empty(id) && !data.handled && data.calendar) {
          axios({
            method: 'delete',
            url: `${CONFIG.API_URL}/dashboard/practitioner/timeoff/${id}`,
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
          })
            .then(() => {
              this.calendar.removeEvent(data.event);
              data.handled = true;
              this.$emit('remove', data);
              this.$emit('event-remove', data.event);
              this.setCalendarState();
            })
            .catch((error) => {
              console.error(error);
            })
        } else {
          console.error('Empty params');
        }
      },
      getSelectedTime(calendarEvent) {
        let sa = calendarEvent.start.format('a');
        let ea = calendarEvent.end.format('a');
        let sh = calendarEvent.start.format('h');
        let eh = calendarEvent.end.format('h');

        if (calendarEvent.start.minute !== 0) {
          sh += calendarEvent.start.format(':mm');
        }

        if (calendarEvent.end.minute !== 0) {
          eh += calendarEvent.end.format(':mm');
        }

        return (sa === ea) ? (sh + ' - ' + eh + ea) : (sh + sa + ' - ' + eh + ea);
      },
    }
  }
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .dashboard-calendar {
    position: relative;

    .dashboard-content__body {
      @include r(1000) {
        padding-top: 30px;
      }
    }
    .v-content {
      padding-top: 0 !important;
      margin-top: 34px;

      @include r(1500) {
        margin-top: 20px;
      }
      @include r(1400) {
        margin-top: 20px;
      }
      @include r(1300) {
        margin-top: 10px;
      }

      /*.ds-add-event-today {*/
      /*display: none !important;*/
      /*}*/
    }
    .v-toolbar {
      position: relative;
      background-color: transparent !important;
      padding: 3px !important;

      .v-toolbar__content {
        padding: 0 0 0 60px;
        position: relative;
        justify-content: center;
        border-bottom: 0;
        height: auto !important;

        @include r(1400) {
          padding-left: 50px;
        }

        @include r(1200) {
          justify-content: flex-start;
        }
        @include r(650) {
          padding-left: 36px;
        }
        @include r(450) {
          justify-content: center;
          padding-top: 50px;
        }

        & > .v-toolbar__title {
          display: none;
        }
        & > p {
          display: none;
        }
        & > .spacer {
          display: none;
        }
        .v-menu {
          position: absolute;
          top: 6px;
          right: 0;

          button {
            margin: 0;
            width: 100px;
            height: 36px;
            border-radius: 4px;
            background-color: #ff5a5f;
            color: #fff;
            font-family: Nunito;
            font-size: 14px;
            font-weight: 400;
            padding: 0;

            .v-btn__content {
              padding: 0 30px 0 15px;
              justify-content: flex-start;
            }

            i {
              font-size: 20px;
              position: absolute;
              top: 0;
              right: 8px;
              bottom: 0;
            }
          }

          @include r(450) {
            top: 0;
            right: calc(50% - 22px);
            transform: translateX(50%);
          }
        }

      }
    }
    .ds-calendar-event-menu {
      position: relative;
    }
    .ds-calendar-event {
      margin: 0;
      border-radius: 0;
    }

    .v-navigation-drawer {
      display: none;
    }

    .v-toolbar__side-icon {
      display: none;
    }
    .my-event {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 2px;
      background-color: #1867c0;
      color: #ffffff;
      border: 1px solid #1867c0;
      font-size: 12px;
      padding: 3px;
      cursor: pointer;
      margin-bottom: 1px;
      left: 4px;
      margin-right: 8px;
      position: relative;

      &.with-time {
        position: absolute;
        right: 4px;
        margin-right: 0;
      }
    }

    &__prev {
      order: 1;
      margin-right: 20px;
      @include r(1500) {
        margin-right: 15px;
      }
      @include r(650) {
        margin-right: 10px;
      }

      i {
        font-size: 34px;
        @include r(1500) {
          font-size: 30px;
        }
        @include r(650) {
          font-size: 28px;
        }
      }
    }

    &__title {
      display: block;
      color: #535353;
      font-family: Nunito;
      font-size: 23px;
      font-weight: 600;
      line-height: 1;
      order: 2;
      margin: 0;

      @include r(1700) {
        font-size: 21px;
      }
      @include r(1500) {
        font-size: 19px;
      }
      @include r(650) {
        font-size: 17px;
      }
      @include r(365) {
        font-size: 16px;
        text-align: center;
      }
    }

    &__next {
      order: 3;
      margin-left: 20px;
      @include r(1500) {
        margin-left: 15px;
      }
      @include r(650) {
        margin-left: 10px;
      }

      i {
        font-size: 34px;
        @include r(1500) {
          font-size: 30px;
        }
        @include r(650) {
          font-size: 28px;
        }
      }
    }

    .ds-week-view {
      background-color: transparent;
      position: relative;

      .ds-hour-list {
        background-color: transparent;
        border: 0;
      }
      .ds-day {
        background-color: #fff;

        /*&.ds-day-today {*/
        /*& > div {*/
        /*&:last-child {*/
        /*display: none;*/
        /*}*/
        /*}*/
        /*}*/
      }
      .ds-week-header {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background-color: #fff;
        margin: 0 0 0 60px !important;

        @include r(1400) {
          margin-left: 50px !important;
        }
        @include r(650) {
          margin-left: 36px !important;
        }
        .ds-calendar-event {
          top: 0 !important;
        }
        .ds-ev-title {
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .ds-all-events {
          flex: 0 0 100%;
          margin-top: 0;
          font-size: 0;

          & > div {
            margin-top: 8px;
          }
        }

        .ds-hour-list {
          display: none;
        }
        .ds-day {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          border-color: transparent;
          flex-wrap: wrap;
          padding: 12px;
          transition-duration: .3s;
          transition-property: background-color;
          border-bottom: 0;

          &:hover {
            background-color: #fff0f0;
            transition-duration: .3s;
            transition-property: background-color;
            cursor: pointer;
          }
          &:active {
            background-color: transparent;
          }

          @include r(1700) {
            padding: 10px;
          }
          @include r(620) {
            padding: 10px 7px;
            text-align: center;
            display: block;
          }

          .ds-week-weekday {
            color: #535353;
            font-family: Nunito;
            font-size: 15px;
            font-weight: 600;
            line-height: 1;
            text-transform: uppercase;
            padding: 2px;

            @include r(1400) {
              font-size: 14px;
            }
            @include r(1000) {
              font-size: 12px;
            }
            @include r(620) {
              text-align: center;
            }
            @include r(400) {
              font-size: 11px;
            }
          }
          .ds-week-date {
            color: #535353;
            font-family: Nunito;
            font-size: 15px;
            font-weight: 600;
            line-height: 1;
            padding: 2px;

            @include r(1400) {
              font-size: 14px;
            }
            @include r(1000) {
              font-size: 12px;
              padding: 2px;
            }
            @include r(620) {
              text-align: center;
            }
            @include r(400) {
              font-size: 11px;
            }
          }
        }
      }

      .ds-week-view-container {
        padding: 3px;
      }

      .ds-week-view-bottom {
        margin-top: 40px;

        @include r(1700) {
          margin-top: 30px;
        }
        @include r(1500) {
          margin-top: 20px;
        }
        .v-menu__activator {
          height: 100%;
        }
        .ds-calendar-event {
          padding-left: 0;
        }
        .ds-calendar-event-span {
          padding-left: .5em
        }
        .ds-week-view-scrollable {
          overflow: inherit;

          .ds-week-view-pane {
            top: -200px;
            position: relative;
          }
        }

        .ds-day {
          border-bottom: 0;

          /*&:last-child {*/
          /*border-right: 0;*/
          /*}*/
        }
        .ds-ev-description {
          max-width: 100%;
          word-wrap: break-word;
          display: block;
        }
        .ds-calendar-event {
          white-space: inherit;
        }
        .ds-ev-title {
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .ds-calendar-event-span {
          overflow: hidden;
        }

        .ds-hour-list {
          width: 60px;

          @include r(1400) {
            width: 50px;
          }

          @include r(650) {
            width: 36px;
          }

          .ds-hour {
            position: relative;

            &:nth-child(6) {
              visibility: hidden;
            }
            .ds-hour-text {
              color: #535353;
              font-family: Nunito;
              font-size: 15px;
              font-weight: 400;
              position: absolute;
              top: -13px;
              left: 0;

              @include r(1700) {
                font-size: 14px;
              }
              @include r(1400) {
                font-size: 13px;
                top: -10px;
              }
              @include r(650) {
                font-size: 11px;
                top: -10px;
              }
            }
          }
        }
        .ds-day {
          &:nth-child(2) {
            .ds-hour {
              border-left: 1px solid #e0e0e0;
            }
          }
          .ds-hour {
            transition-duration: .3s;
            transition-property: background-color;

            &:first-child {
              border-top: 1px solid #e0e0e0;
            }

            &:hover {
              background-color: #fff0f0;
              transition-duration: .3s;
              transition-property: background-color;
              cursor: pointer;
            }
          }
          &:active {
            .ds-hour {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  .v-dialog--persistent {
    max-width: 380px !important;
    border-radius: 10px;
    background-color: #fff;

    @include r(600) {
      max-width: 100% !important;
      border-radius: 0;
      width: 100% !important;
    }

    .ds-event-actions {
      display: none;
    }

    .v-card__text {
      padding: 13px !important;
    }

    .v-tabs {
      display: none;
    }
    .v-form {
      margin: 0;
      .v-text-field {
        margin: 0;
      }
    }
    .ds-event-header.ds-event-area {
      margin-left: 0 !important;
    }
    .ds-event-cancel {
      left: inherit !important;
      top: -10px;
      right: -10px;
      z-index: 2;

      .ds-button {
        width: 40px;
        height: 40px;
      }
    }
    .ds-event.ds-has-cancel .ds-event-area {
      margin-left: 0;
    }
    .v-input__control {
      padding: 0 70px !important;

      @include r(600) {
        padding: 0 50px 0 70px !important;
      }
    }
    .v-btn--depressed {
      width: 128px;
      height: 38px;
      border-radius: 4px;

      &.primary {
        background-color: #ff5a5f !important;
      }
    }
    .ds-event-body {
      & > div {
        &:last-child {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .v-input__slot {
      padding: 0 !important;

      input {
        border-bottom: 1px solid #ff5a5f;
      }
    }
  }

  .v-menu__content.menuable__content__active {
    contain: inherit !important;
  }

  .ds-calendar-event-popover-card {
    box-shadow: none !important;
    max-width: 380px !important;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #ccc !important;

    @include r(600) {
      max-width: 100% !important;
      border-radius: 0;
      padding-top: 68px;
      border: 0 !important;
    }
    .v-toolbar {
      background-color: #fff !important;
    }
    .v-btn--bottom.v-btn--absolute.v-btn--small {
      bottom: inherit !important;
      margin: 0;
      width: 36px;
      height: 36px;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.54) !important;
    }
    .v-btn--floating.v-btn--small {
      margin: 0;
      width: 36px;
      height: 36px;
      box-shadow: none;
      background-color: #ff5a5f !important;
    }
    .v-toolbar__title {
      margin-left: 47px !important;
    }
  }

  .ds-add-event-today {
    display: none;
  }

  .dashboard-calendar {
    &__add-event {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 10;

      @include r(700) {
        bottom: 5px;
        right: 5px;
      }

      &:hover, &:active, &:focus {
        position: fixed;
      }
    }
  }

  .v-dialog--persistent {
   .popup-close-calendar {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .theme--light.v-picker__body {
      background-color: transparent;
    }
    .vue-js-switch {
      display: block;
    }
    .v-list__tile {
      padding-left: 25px;
    }
    .v-text-field--single-line {
      display: block;
      text-align: center;
      margin: 20px 0 10px 0;
    }
    .availability__time-title {
      font-size: 15px;
    }
  }

  .v-tooltip {
    display: block;
  }

</style>
