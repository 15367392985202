<template>
  <div>
    <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
      <vue-upload-multiple-image
        @upload-success="uploadImageSuccess"
        @before-remove="beforeRemove"
        @edit-image="editImage"
        @limit-exceeded="limitExceeded"
        @obj-of-images-is-changed="objOfImagesIsChanged"
        :data-images="images"
        idUpload="myIdUpload"
        editUpload="myIdEdit"
        dragText="Add photo +"
        browseText=""
        primaryText="Main image"
        popupText="This image will be displayed first"
        markIsPrimaryText="Set as main"
        dropText="Drop your file here ..."
        :maxImage="10"
        :showPrimary="false"
      ></vue-upload-multiple-image>
    </div>

    <template>
      <v-layout row justify-center>
        <v-dialog v-model="removeDialog" persistent max-width="290">
          <v-card>
            <!--<v-card-title class="headline">Delete this image?</v-card-title>-->
            <v-card-text>Delete this image?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" flat @click="clearBeforeRemoveData">No</v-btn>
              <v-btn color="green darken-1" flat @click="removeImage">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>

    <template>
      <v-layout row justify-center>
        <v-dialog v-model="notificationDialog" persistent max-width="290">
          <v-card>
            <v-card-text>You can add only 10 images</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" flat @click="notificationDialog = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>

  </div>
</template>

<script>
  import axios from 'axios';
  import * as CONFIG from '@/config.js';
  import {empty} from '@/helpers.js';
  import VueUploadMultipleImage from '@/components/Libraries/VueUploadMultipleImage';
  import _ from 'lodash'

  export default {
    name: "multiple-upload-component",
    components: {
      VueUploadMultipleImage
    },
    data() {
      return {
        images: [],
        removeDialog: false,
        notificationDialog: false,
        beforeRemoveData: {
          index: '',
          done: '',
          fileList: ''
        }
      }
    },
    mounted() {
      this.setUploadedImage();
    },
    methods: {
      getRequest() {
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/practitioner/images`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
        })
      },
      uploadRequest(formData) {
        return axios({
          method: 'post',
          url: `${CONFIG.API_URL}/practitioner/images`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          data: formData
        })
      },
      removeRequest(id) {
        return axios({
          method: 'delete',
          url: `${CONFIG.API_URL}/practitioner/images/${id}`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
        })
      },
      setUploadedImage() {
        this.getRequest()
          .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
              this.images.push({
                path: response.data[i].src,
                default: 1,
                highlight: 1,
                id: response.data[i].id,
              });
            }
          })
          .catch((error) => {
            if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
          });
      },
      uploadImageSuccess(formData, index, fileList) {
        this.uploadRequest(formData)
          .then((response) => {
            const imageData = _.head(response.data.files);
            if (!empty(imageData)) {
              fileList[index].id = imageData.id
            } else {
              this.$store.commit('SET_NOTIFICATION', {
                text: 'Failed to set id for image',
                color: 'orange'
              });
            }
          })
          .catch((error) => {
            if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
          });
      },
      beforeRemove(index, done, fileList) {
        this.beforeRemoveData.index = index;
        this.beforeRemoveData.done = done;
        this.beforeRemoveData.fileList = fileList;
        this.removeDialog = true;
      },
      clearBeforeRemoveData() {
        this.beforeRemoveData.index = '';
        this.beforeRemoveData.done = '';
        this.beforeRemoveData.fileList = '';
        this.removeDialog = false;
      },
      removeImage() {
        const id = this.beforeRemoveData.fileList[this.beforeRemoveData.index].id;
        if (!empty(id)) {
          this.removeRequest(id)
            .then(() => {
              this.beforeRemoveData.done();
              this.clearBeforeRemoveData();
            })
            .catch((error) => {
              this.clearBeforeRemoveData();
              if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
                this.$store.commit('SET_NOTIFICATION', {
                  text: error.response.data.message,
                  color: 'orange'
                });
              }
            })
        } else {
          this.$store.commit('SET_NOTIFICATION', {
            text: 'Failed to get id for image',
            color: 'orange'
          });
        }
      },
      editImage(formData, index, fileList) {
        const id = fileList[index].id;
        if (!empty(id)) {
          this.removeRequest(id)
            .then(() => {
              this.uploadImageSuccess(formData, index, fileList);
            })
            .catch((error) => {
              if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
                this.$store.commit('SET_NOTIFICATION', {
                  text: error.response.data.message,
                  color: 'orange'
                });
              }
            });
        } else {
          this.$store.commit('SET_NOTIFICATION', {
            text: 'Failed to get id for image',
            color: 'orange'
          });
        }
      },
      limitExceeded(){
        this.notificationDialog = true;
      },
      objOfImagesIsChanged(images){
        this.$emit('amount-uploaded-images', images.length);
      }
    }
  }
</script>

<style lang="scss">
  #my-strictly-unique-vue-upload-multiple-image {
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;

    li {
      display: inline-block;
      margin: 0 10px;
    }

    a {
      color: #42b983;
    }

  }


</style>