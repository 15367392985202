<template>
  <PractitionerAvailability
          v-if="renderComponent"
          ref="practAvailabComponent"
          :id="practitionerId"
          :searchAddressToStore="searchAddressToStore"
          :searchAddress="searchAddress"
          :searchTown="searchTown"
          :searchPostcode="searchPostcode"
          :searchCountryId="searchCountryId"
          :searchCountryName="searchCountryName"
          :searchAddressComponents="searchAddressComponents"

          :searchAddressLat="searchLatitude"
          :searchAddressLon="searchLongitude"
          :initDate="initDate"
          :selectedLocationType="searchLocationType"
          :selectedSubServiceId="searchSubServiceId"
          :selectedSubServiceName="searchSubServiceName"
          :bookingFlow="booking_flow"
          @hide-availability-popup="hide"
  >
  </PractitionerAvailability>
</template>

<script>

  import {empty, removeIsFixedToHtml} from '@/helpers.js';
  import moment from 'moment';
  import PractitionerAvailability from '@/components/Partial/PractitionerAvailability'
  import _ from 'lodash';

  export default {
    props:['booking_id', 'booking_flow'],
    name: "customer-dashboard-rebook",
    components: {
      PractitionerAvailability
    },
    beforeRouteUpdate(to, from, next) {
      this.setPreBookingState(to, from);
      next();
    },
    beforeRouteLeave(to, from, next){
      removeIsFixedToHtml();
      next()
    },
    data() {
      return {
        renderComponent: false,
        searchAddress: null,
        searchTown: null,
        searchLatitude: null,
        searchLongitude: null,
        searchSubServiceId: null,
        searchSubServiceName: null,
        searchLocationType: null,
        searchPostcode: null,
        searchCountryName: null,
        searchCountryId: null,
        initDate: null,
        practitionerId: null,
        searchAddressComponents: null,
        searchAddressToStore: null,
        searchServiceType: null
      }
    },
    created: function () {
      this.initComponent();
    },
    methods:{
      setPreBookingState(to, from){
        this.$refs.practAvailabComponent.setPreBookingState(to, from);
      },
      initComponent(){
        this.$store.dispatch('GET_BOOKING', this.booking_id)
          .then((response) => {
            // eslint-disable-next-line
            console.log('GET_BOOKING');
            // eslint-disable-next-line
            console.log(response);
            // eslint-disable-next-line
            console.log('GET_BOOKING');
            this.searchAddress = response.location.address;
            this.searchAddressToStore = response.location.address;
            this.searchTown = response.location.city;
            this.searchLatitude = response.location.geoposition.lat;
            this.searchLongitude = response.location.geoposition.lng;
            this.searchSubServiceId = (!empty(_.head(response.treatments))) ? _.head(response.treatments).id : 170;
            // this.searchSubServiceName = (!empty(_.head(response.treatments))) ? _.head(response.treatments).name : 'Beard trim';
            this.searchServiceType = (!empty(_.head(response.treatments))) ? _.head(response.treatments).service_type : 'bridal';
            this.searchLocationType = response.location_type;
            this.searchPostcode = response.location.postcode;
            this.searchCountryName = response.location.country_name;
            this.searchCountryId = response.location.country_id;
            this.initDate = moment().clone().format('YYYY-MM-DD');
            this.practitionerId = response.practitioner_id;
            this.searchAddressComponents = (!empty(response.location.address_object)) ? response.location.address_object : {};
            this.setSearchParams();
            this.renderComponent = true;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          });
      },
      setSearchParams() {
        this.$store.dispatch('SET_SEARCH_PARAMS', {
          searchAddress: this.searchAddress,
          searchTown: this.searchTown,
          searchLatitude: this.searchLatitude,
          searchLongitude: this.searchLongitude,
          searchSubServiceId: this.searchSubServiceId,
          searchSubServiceName: this.searchSubServiceName,
          searchLocationType: this.searchLocationType,
          searchPostcode: this.searchPostcode,
          searchCountryName: this.searchCountryName,
          searchCountryId: this.searchCountryId,
          searchDate: this.initDate,
          searchServiceType: this.searchServiceType
        })
      },
      hide(){
        this.$router.go(-1);
      }
    }
  }
</script>

<style scoped>

</style>