<template>
  <li class="availability__day">
    <div class="availability__switch v-swith-main">
      <div class="v-swith-main__label">
        {{ dayOfWeek[0].toUpperCase() + dayOfWeek.slice(1) }}
      </div>
      <toggle-button
        :labels="{checked: 'Yes', unchecked: 'No'}"
        :color="{checked: '#6ACE63', unchecked: '#FF5A5F'}"
        :width="90"
        :height="32"
        v-model="availabilityByDay.show"
        @input="resetAvailabilityByDay(availabilityByDay.show)"
      />
    </div>
    <transition name="fade">
      <div
        v-if="availabilityByDay.show"
        class="availability__time"
      >
        <div class="availability__time-inners availability-hours">
          <div class="availability__time-inner">
            <div class="availability__time-title">
              {{$t('availability_slots.hours')}}
            </div>
            <!-- from -->
            <VueCtkDateTimePicker
              :class="{'bold-text': !isDefaultAvailabilityFrom}"
              v-model="availabilityByDay.available.from"
              formatted="HH:mm"
              format="HH:mm"
              :minute-interval="15"
              only-time
              label=""
              :error="availabilityByDay.error.from"
              @input="changeFromTime()"
              :disabled-hours="disabledHoursFrom(availabilityByDay.available.to)"
            ></VueCtkDateTimePicker>
          </div>
          <div class="availability__time-inner">
            <div class="availability__time-title">
              {{$t('availability_slots.to')}}
            </div>
            <!-- to -->
            <VueCtkDateTimePicker
              :class="{'bold-text': !isDefaultAvailabilityTo}"
              v-model="availabilityByDay.available.to"
              formatted="HH:mm"
              format="HH:mm"
              :minute-interval="15"
              only-time
              label=""
              :error="availabilityByDay.error.to"
              @input="changeToTime()"
              :disabled-hours="disabledHoursTo(availabilityByDay.available.from)"
            ></VueCtkDateTimePicker>
          </div>
        </div>

        <template v-for="(single_break, break_index) in availabilityByDay.break">
          <availability-breaks
            :key="break_index"
            :single_break="single_break"
            :break_index="break_index"
            @removeBreak="removeBreak"
            @updateBreakItem="updateBreakItem"
          ></availability-breaks>
        </template>

        <div class="availability__time-btn">
          <v-popover
            trigger="manual"
            :open="isTooltipBreakButton"
            offset="0"
            class="categories__item-name"
            :autoHide="true"
          >
            <v-btn
              class="text-xs-center text-sm-center text-md-center skip"
              flat
              small
              @click="addBreak();"
            >
              {{$t('availability_slots.add_break')}}
            </v-btn>
            <template slot="popover">
              <span>
                 {{$t('availability_slots.add_one_or_more_breaks')}}
              </span>
              &nbsp;
              <span
                @click.stop="isTooltipBreakButton = false"
                class="pointer"
              >
                 {{$t('availability_slots.ok')}}
              </span>
            </template>
          </v-popover>
        </div>
      </div>
    </transition>
  </li>
</template>

<script>
import AvailabilityBreaks from '@/components/PractitionerInformation/AvailabilityBreaks';

export default {
  name: "AvailabilitySlots",
  props: ['availabilityByDay', 'dayOfWeek', 'isTooltipBreakButtonProp', 'defaultTimeFrom', 'defaultTimeTo', 'defaultBreaks'],
  components: {
    AvailabilityBreaks
  },
  data () {
    return {
      isTooltipBreakButton: false
    };
  },
  created () {
    this.isTooltipBreakButton = this.isTooltipBreakButtonProp;
  },
  computed: {
    isDefaultAvailabilityFrom () {
      return (this.availabilityByDay.available.from == '09:00') ? true : false;
    },
    isDefaultAvailabilityTo () {
      return (this.availabilityByDay.available.to == '18:00') ? true : false;
    },
  },
  methods: {
    disabledHoursFrom (time_to) {
      const hours = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'];
      let disabled_hours = [];
      
      for (let i=0; i<hours.length; i++) {
        if (hours[i] > time_to) {
          disabled_hours.push(hours[i].slice(0,-3));
        }
      }

      return disabled_hours;
    },
    disabledHoursTo (time_from) {
      const hours = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'];
      let disabled_hours = [];
      
      for (let i=0; i<hours.length; i++) {
        if (hours[i] < time_from) {
          disabled_hours.push(hours[i].slice(0,-3));
        }
      }
      return disabled_hours;
    },
    resetAvailabilityByDay (status) {
      if (status) {
        this.availabilityByDay.available.from = this.defaultTimeFrom;
        this.availabilityByDay.available.to = this.defaultTimeTo;

        let breaks = [];
        for (let i = 0; i < this.defaultBreaks.length; i++) {
          breaks.push(JSON.parse(JSON.stringify(this.defaultBreaks[i])));
        }

        this.availabilityByDay.break = breaks;
      } else {
        this.availabilityByDay.available.from = '08:00';
        this.availabilityByDay.available.to = '18:00';
        this.availabilityByDay.break = [];
      }
    },
    addBreak () {
      this.isTooltipBreakButton = false;
      this.availabilityByDay.break.push({ "from": "13:00", "to": "14:00", "error": {from: false, to: false}});

      this.updateDefaultBreak();
    },
    removeBreak (index) {
      this.availabilityByDay.break.splice(index, 1);

      this.updateDefaultBreak();
    },

    changeFromTime () {
      this.$emit("timeFrom", this.availabilityByDay.available.from);
    },
    changeToTime () {
      this.$emit("timeTo", this.availabilityByDay.available.to);
    },
    updateDefaultBreak () {
      this.$emit("breaksUpdate", this.availabilityByDay.break);
    },
    updateBreakItem () {
      this.updateDefaultBreak();
    }
  }
}
</script>

<style>
.bold-text #DateTimePicker {
  font-weight: bold;
}

.date-time-picker .field.has-value .field-input {
  padding-top: 0;
}
</style>