<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm10
        md6
      >

        <v-card class="v-sync">
          <v-card-text>
            <p class="title text-xs-center text-sm-center text-md-center">
              Calendar Sync
            </p>

            <p class="main-text">
              It's easy to sync our calendar with iCal or Google Calendar.
            </p>

            <div class="text-xs-center step-btn-main step-btn-main--mt">
              <v-btn color="error">
                Click to Sync
              </v-btn>
            </div>

            <p class="title title--mt text-xs-center text-sm-center text-md-center">
              Account Tutorial
            </p>

            <p class="main-text">
              Learn how to make the most of your account.
            </p>

            <div class="text-xs-center step-btn-main step-btn-main--mt">
              <v-btn color="error">
                Take tutorial
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
};
</script>

<style lang="scss">
.v-sync {
  border-radius: 24px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 45px;
}
</style>