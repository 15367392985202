<template>
  <div
      class="dashboard-content dashboard-profile"
      id="dashboard-content"
  >
    <div class="dashboard-content__title">
      <h2>
        {{ $t('practitioner_profile.profile_tab_title') }}
      </h2>
      <p>
        {{ $t('practitioner_profile.profile_tab_desc') }}
      </p>
    </div>
    <div class="dashboard-content__body">
      <div v-if="showProgress">
        <v-progress-linear
            indeterminate
            color="error"
            height="3"
        ></v-progress-linear>
      </div>
      <div
          v-else
          class="dashboard-profile__container"
      >
        <div class="dashboard-profile__holder">

          <template>
            <div>
              <v-dialog v-model="dialog" content-class="v-dialog--pictures">
                <v-card>
                  <v-card-text>
                    <VueCropper
                        v-show="selectedFile"
                        ref="cropper"
                        :zoomable="false"
                        :src="selectedFile"
                        alt="Source Image"
                        :aspectRatio="1/1"
                        :initialAspectRatio="1/1"
                    ></VueCropper>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="error v-btn theme--light" @click="updateProfileImage($event), (dialog = false)">Save
                    </v-btn>
                    <v-btn color="error v-btn theme--light" text @click="dialog = false">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>

          <div class="dashboard-profile__edit">
            {{ $t('practitioner_profile.profile_tab_profile_image') }}
          </div>
          <div
              class="dashboard-profile__photo"
              @click="launchProfileImagePicker()"
          >
            <v-avatar
                v-if="!photoSrc && !isImageLoading"
                class="dashboard-profile__photo-img add-photo"
            >
              <span>
                {{ $t('practitioner_profile.profile_tab_add_a_photo') }}
              </span>
            </v-avatar>
            <v-avatar
                v-if="photoSrc && !isImageLoading"
                class="dashboard-profile__photo-img"
            >
              <img
                  :src="photoSrc"
                  alt="avatar"
              >
            </v-avatar>
            <v-avatar
                v-if="isImageLoading"
                class="dashboard-profile__photo-img add-photo"
            >
              <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </v-avatar>
          </div>
          <input
              type="file"
              ref="file"
              @change="onFileSelect"
              style="display:none"
          >
        </div>
        <div class="dashboard-profile__holder">
          <div class="dashboard-profile__edit">
            {{ $t('practitioner_profile.profile_tab_examples_work') }}
          </div>
          <div class="photos__multiple">
            <multiple-upload-component></multiple-upload-component>
          </div>
        </div>
        <div class="dashboard-profile__holder dashboard-profile__holder--white">
          <div class="dashboard-profile__inners">
            <div class="dashboard-profile__inner">
              <div class="dashboard-profile__edit">
                {{ $t('practitioner_profile.profile_tab_first_name') }}
              </div>
              <div class="dashboard-profile__field">
                <input
                    type="text"
                    v-model="firstName"
                    @change="updateFirstName"
                >
              </div>
            </div>
            <div class="dashboard-profile__inner">
              <div class="dashboard-profile__edit">
                {{ $t('practitioner_profile.profile_tab_last_name') }}
              </div>
              <div class="dashboard-profile__field">
                <input
                    type="text"
                    v-model="lastName"
                    @change="updateLastName"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-profile__holder dashboard-profile__holder--white">
          <div class="dashboard-profile__title">
            {{ $t('practitioner_profile.profile_tab_experience') }}
          </div>
          <div class="dashboard-profile__inners">
            <div class="dashboard-profile__inner dashboard-profile__inner--lg">
              <v-menu
                  v-model="experienceMenu"
                  :close-on-content-click="false"
                  full-width
                  :nudge-right="40"
              >
                <v-text-field
                    slot="activator"
                    :value="formattedExperienceDate"
                    prepend-icon="event"
                    :label="$t('practitioner_profile.profile_tab_started_working')"
                    :rules="experienceRules"
                    readonly
                ></v-text-field>
                <v-date-picker
                    v-model="experienceStartDate"
                    @change="updateExperience()"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    type="month"
                    landscape
                    color="error lighten-1"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="das hboard-profile__inner">
              <!-- TODO: add experience -->
            </div>
          </div>
        </div>
        <div class="dashboard-profile__holder dashboard-profile__holder--white">
          <div class="dashboard-profile__title">
            {{ $t('practitioner_profile.profile_tab_qualifications') }}
          </div>
          <div class="dashboard-profile__qualifications">

            <div
                class="dashboard-profile__qualifications-item"
                v-for="item in qualificationList"
                :key="item.id"
            >
              <v-text-field
                  :value="item.name"
                  v-model="item.name"
                  solo
                  flat
                  hide-details
                  @input="updateQualification"
              ></v-text-field>
              <button
                  @click="removeQualification(item)"
                  class="dashboard-profile__qualifications-item-delete"
              >
              </button>
            </div>
            <div class="dashboard-profile__qualifications-item">
              <input
                  type="text"
                  name=""
                  :placeholder="$t('practitioner_profile.profile_tab_add_more_qualifications')"
                  class="dashboard-profile__qualifications-field"
                  v-model="newQualification"
                  @keyup.enter="addQualification"
                  v-on:blur="addQualification"
              >
            </div>
            <div class="dashboard-profile__qualifications-item dashboard-profile__qualifications-item--action">
              <button
                  class="dashboard-profile__qualifications-add error"
                  :disabled="newQualification.length <= 0"
                  @click="addQualification"
              >
                {{ $t('practitioner_profile.profile_tab_add') }}
              </button>
            </div>
          </div>
        </div>
        <div class="dashboard-profile__holder dashboard-profile__holder--white">
          <div class="dashboard-profile__title">
            {{ $t('practitioner_profile.profile_tab_bio') }}
          </div>
          <div class="dashboard-profile__bio">
            <v-textarea
                v-model="bio"
                @change="updateBio"
                solo
                class="solo-textarea-white-radius"
            ></v-textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import * as CONFIG from '@/config.js'
import {empty} from '@/helpers.js';
import MultipleUploadComponent from '@/components/Partial/MultipleUploadComponent';
import moment from 'moment'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

/* eslint-disable */

export default {
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Practitioner Profile'
  },
  components: {
    MultipleUploadComponent,
    VueCropper
  },
  props: ['image_name'],
  data() {
    return {
      showProgress: true,
      isImageLoading: false,

      photoSrc: null,

      firstName: '',
      lastName: '',

      experienceStartDate: null,
      experienceMenu: false,
      experienceRules: [v => !!v || this.$i18n.t('practitioner_profile.profile_tab_js_error_working_date_required')],

      last_qualification_id: 0,
      newQualification: '',
      qualificationList: [],
      bio: '',

      mime_type: '',
      autoCrop: false,
      selectedFile: '',
      image: '',
      dialog: false,
      files: '',
    };
  },
  mounted() {
    this.getUserSettingsData();
    this.getUserPractitionerProfileData();
  },
  computed: {
    formattedExperienceDate() {
      return this.experienceStartDate ? moment(this.experienceStartDate).format('MMMM, YYYY') : '';
    },
  },
  methods: {
    getUserSettingsData() {
      axios({
        method: "get",
        url: `${CONFIG.API_URL}/dashboard/user/settings`,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
          CONFIG.DEV_USER_TOKEN}`
        }
      })
          .then(
              response => {
                const data = response.data;
                console.log('user settings', data);

                this.photoSrc = data.avatar;
                this.firstName = data.first_name;
                this.lastName = data.last_name;

                this.showProgress = false;
              },
              error => {
                console.log(error.response.data);

                this.showProgress = false;
                throw error;
              }
          )
          .catch(error => {
            console.log(error.response.data);

            this.showProgress = false;

            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            throw error;
          });
    },
    getUserPractitionerProfileData() {
      axios({
        method: "get",
        url: `${CONFIG.API_URL}/dashboard/practitioner/profile`,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
          CONFIG.DEV_USER_TOKEN}`
        }
      })
          .then(
              response => {
                const data = response.data;
                console.log('practitioner profile', data);

                this.experienceStartDate = data.start_working_date;

                const qualifications = data.qualifications;
                for (var i = 0; i < qualifications.length; i++) {
                  this.qualificationList.push({id: i, name: qualifications[i]});
                }
                ;
                this.bio = data.bio;

                this.showProgress = false;
              },
              error => {
                console.log(error.response.data);

                this.showProgress = false;
                throw error;
              }
          )
          .catch(error => {
            console.log(error.response.data);

            this.showProgress = false;

            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            throw error;
          });
    },
    updateFirstName: _.debounce(function () {
      const data = {first_name: this.firstName};
      this.updateUserSettings(data);
    }, 500),
    updateLastName: _.debounce(function () {
      const data = {last_name: this.lastName};

      this.updateUserSettings(data);
    }, 500),
    updateExperience: _.debounce(function () {
      this.experienceMenu = false;

      const data = {start_working_date: this.experienceStartDate + '-01'};
      this.updatePractitionerProfile(data);
    }, 500),
    updateQualification: _.debounce(function () {
      let qualifications = [];

      for (var i = 0; i < this.qualificationList.length; i++) {
        const item = this.qualificationList[i];
        if (item.name.length > 0) {
          qualifications.push(item.name);
        } else {
          this.qualificationList.splice(this.qualificationList.indexOf(item), 1);
        }
      }
      ;

      const data = {qualifications: qualifications};
      this.updatePractitionerProfile(data);
    }, 500),
    updateBio: _.debounce(function () {
      const data = {bio: this.bio};
      this.updatePractitionerProfile(data);
    }, 500),
    updateUserSettings(data) {
      // first_name, last_name
      if (!empty(data.first_name)) {
        this.firstName = data.first_name;
      }
      if (!empty(data.last_name)) {
        this.lastName = data.last_name;
      }

      axios({
        method: "put",
        url: `${CONFIG.API_URL}/dashboard/user/settings`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
          CONFIG.DEV_USER_TOKEN}`
        }
      })
          .then(
              (response) => {
                this.$store.commit('SET_CURRENT_USER_DATA', {name: `${this.firstName} ${this.lastName.charAt(0)}.`});

                this.$store.commit('SET_NOTIFICATION', {
                  text: response.data.message,
                  color: 'error'
                });
              },
              error => {
                console.log(error.response.data);
                throw error;
              }
          )
          .catch(error => {
            console.log(error.response.data);

            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            throw error;
          });
    },
    updatePractitionerProfile(data) {
      // experience, qualifications, bio
      axios({
        method: "put",
        url: `${CONFIG.API_URL}/dashboard/practitioner/profile`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
          CONFIG.DEV_USER_TOKEN}`
        }
      })
          .then(
              response => {
                console.log(response);

                this.$store.commit('SET_NOTIFICATION', {
                  text: response.data.message,
                  color: 'error'
                });
              },
              error => {
                console.log(error.response.data);
                throw error;
              }
          )
          .catch(error => {
            console.log(error.response.data);

            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            throw error;
          });
    },

    launchProfileImagePicker() {
      this.$refs.file.click();
    },

    updateProfileImage(event) {
      this.photoSrc = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {

        const data = new FormData();
        data.append('profileImage', blob, 'my-new-profile-pic.png');

        this.isImageLoading = true;

        axios({
          method: "post",
          url: `${CONFIG.API_URL}/practitioner/avatar`,
          data: data,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${this.$store.getters.userToken || CONFIG.DEV_USER_TOKEN}`
          }
        })
            .then(
                response => {
                  this.isImageLoading = false;

                  this.photoSrc = response.data.src;

                  this.$store.commit('SET_NOTIFICATION', {
                    text: response.data.message,
                    color: 'error'
                  });
                },
                error => {
                  console.log(error.response.data);
                  this.isImageLoading = false;
                  throw error;
                }
            )
            .catch(error => {
              console.log(error.response.data);
              this.isImageLoading = false;

              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
              throw error;
            });
      }, this.mime_type)
    },
    addQualification() {
      if (this.newQualification.trim() == '') return;

      let qualification = {
        id: ++this.last_qualification_id,
        name: this.newQualification,
      };
      this.qualificationList.push(qualification);
      this.newQualification = '';
      this.updateQualification();
    },
    removeQualification(qualification) {
      this.qualificationList.splice(this.qualificationList.indexOf(qualification), 1);
      this.updateQualification();
    },

    onFileSelect(event) {
      const file = event.target.files[0]
      this.mime_type = file.type
      console.log(this.mime_type)
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
  }
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.dashboard-content {
  display: block;
  flex: 1 1 auto;
  width: 100%;
  padding: 50px 120px 120px 100px;

  @include r(1700) {
    padding: 50px 100px 100px 80px;
  }

  @include r(1500) {
    padding: 40px 60px 60px 60px;
  }

  @include r(1300) {
    padding: 30px 40px 60px 40px;
  }
  @include r(1200) {
    padding: 28px 28px 40px 28px !important;
  }

  @include r(900) {
    padding: 28px 16px 20px 16px !important;
  }

  &__title {
    padding-bottom: 24px;

    @include r(1000) {
      padding-bottom: 0;
    }

    h2 {
      display: block;
      color: #383838;
      font-size: 37px;
      font-weight: 600;
      line-height: 1.1;

      @include r(1700) {
        font-size: 34px;
      }

      @include r(1500) {
        font-size: 32px;
      }

      @include r(1400) {
        font-size: 30px;
      }

      @include r(1300) {
        font-size: 28px;
      }

      @include r(1000) {
        display: none;
      }
    }

    p {
      display: block;
      margin: 10px 0 0 0;
      color: #535353;
      font-size: 15px;
      font-weight: 400;

      @include r(1000) {
        margin-top: 0;
      }
    }
  }

  &__body {
    padding: 40px 22px 0 22px;
    position: relative;

    @include r(1200) {
      padding: 40px 0 0 0;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      height: 1px;
      left: 22px;
      width: calc(100% - 44px);
      background-color: #f7cecf;

      @include r(1200) {
        width: 100%;
        left: 0;
      }
      @include r(1000) {
        display: none;
      }
    }
  }
}

.dashboard-profile {
  &__container {
    max-width: 678px;

    @include r(700) {
      max-width: 100%;
    }
  }

  &__edit {
    display: inline-block;
    position: relative;
    padding-right: 30px;
    color: #707070;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;

    @include r(1500) {
      font-size: 18px;
      padding-right: 26px;
    }
    @include r(700) {
      padding: 0;
    }
    @include r(550) {
      font-size: 16px;
    }

    &::after {
      position: absolute;
      content: "";
      right: 0;
      top: 50%;
      margin-top: -9px;
      width: 17px;
      height: 18px;
      background: {
        image: url("../.././images/icons/icon-edit.svg");
        repeat: no-repeat;
        size: contain;
      }
      @include r(1500) {
        width: 16px;
        height: 17px;
      }
      @include r(700) {
        display: none;
      }
    }
  }

  &__photo {
    width: 176px;
    height: 176px;
    display: block;
    margin: 20px 0 0 0;

    @include r(700) {
      width: 134px;
      height: 134px;
      margin: 15px auto 0 auto;
    }

    .v-avatar {
      border-radius: 25px;
      width: 176px !important;
      height: 176px !important;
      display: block;

      @include r(700) {
        width: 134px !important;
        height: 134px !important;
      }

      img {
        border-radius: 25px !important;
      }
    }


    &-img {
      background-color: #ff5252;
      margin-top: 0;
      display: flex !important;
      align-items: center;
      justify-content: center;
      transition-property: background-color;
      transition-duration: .3s;

      span {
        font-size: 18px;
        color: #fff;
      }

      &:hover {
        cursor: pointer;
        background-color: #ff7878;
      }
    }
  }

  &__holder {
    display: block;
    margin-top: 40px;

    @include r(1500) {
      margin-top: 30px;
    }
    @include r(700) {
      margin-top: 20px;
      text-align: center;
    }

    &:first-child {
      margin-top: 0;
    }

    &--white {
      @include r(700) {
        background-color: #fff;
        border-radius: 4px;
        padding: 20px;
        text-align: left;

        .dashboard-profile {
          &__title {
            padding-left: 30px;
          }

          &__edit {
            padding-left: 30px;
          }
        }
      }
      @include r(550) {
        padding: 20px 14px 16px 14px;
      }
    }
  }

  &__title {
    display: block;
    color: #707070;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;

    @include r(1500) {
      font-size: 18px;
    }
    @include r(550) {
      font-size: 16px;
    }
  }

  &__inners {
    display: flex;
    margin: 0 -15px;
    @include r(550) {
      flex-wrap: wrap;
    }
  }

  &__inner {
    flex: 0 0 auto;
    padding: 0 15px;

    &:last-child {
      @include r(550) {
        margin-top: 20px;
      }
    }

    @include r(700) {
      flex: 0 0 50%;
    }

    @include r(550) {
      flex: 0 0 100%;
    }

    &--lg {
      @include r(700) {
        flex: 0 0 100%;
      }
    }
  }

  &__field {
    display: block;
    margin-top: 20px;

    @include r(1500) {
      margin-top: 10px;
    }

    select,
    input {
      width: 172px;
      height: 55px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      background-color: #fff;
      text-align: center;
      padding: 0 12px;
      color: #707070;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 400;
      outline: none;

      @include r(1700) {
        font-size: 18px;
        height: 50px;
      }

      @include r(1500) {
        font-size: 17px;
        height: 46px;
      }
      @include r(700) {
        width: 100%;
        background-color: #f7f7f7;
      }
      @include r(550) {
        font-size: 15px;
      }
    }
  }

  &__qualifications {
    display: block;
    margin-top: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    overflow: hidden;

    @include r(700) {
      box-shadow: none;
      margin-top: 15px;
    }

    &-item {
      display: block;
      position: relative;

      @include r(700) {
        background-color: #f7f7f7;
        margin-bottom: 18px;
        border-radius: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .v-input__slot {
        padding: 22px 68px 22px 40px !important;
        background-color: transparent !important;

        @include r(1700) {
          padding: 18px 60px 18px 36px !important;
        }

        @include r(1500) {
          padding: 16px 56px 16px 30px !important;
        }

        @include r(550) {
          padding: 6px 56px 6px 30px !important;
        }

        input {
          color: #707070;
          font-family: Nunito;
          font-size: 20px;
          font-weight: 400;

          @include r(1700) {
            font-size: 18px;
          }
          @include r(1500) {
            font-size: 17px;
          }
          @include r(550) {
            font-size: 16px;
          }
        }
      }

      &:nth-child(even) {
        background-color: #f8f8f8;

        @include r(700) {
          background-color: #f7f7f7;
        }
      }

      &--action {
        padding: 24px 24px;
        display: flex;
        justify-content: flex-end;

        @include r(1700) {
          padding: 22px 20px;
        }

        @include r(1500) {
          padding: 20px 20px;
        }
      }

      &-delete {
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 24px;
        width: 20px;
        height: 20px;
        background: {
          image: url("../../.././src/images/icons/icon-delete.svg");
          repeat: no-repeat;
          size: contain;
        }
        outline: none;
        transition: 0.3s;

        @include r(1700) {
          width: 18px;
          height: 18px;
          right: 20px;
          margin-top: -9px;
        }
        @include r(1500) {
          width: 16px;
          height: 16px;
          margin-top: -8px;
        }

        @include r(550) {
          width: 14px;
          height: 14px;
          margin-top: -7px;
        }

        &:hover {
          opacity: 0.7;
          transition: 0.3s;
        }
      }
    }

    &-add {
      width: 128px;
      height: 38px;
      color: #fff;
      font-family: Nunito, Sans-Serif;
      font-size: 17px;
      font-weight: 400;
      display: inline-block;
      border-radius: 5px;

      &:disabled {
        opacity: 0.7;
      }
    }

    &-btn {
      display: block;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px 4px 0 0;
      background-color: #fff;
      padding: 26px 42px;
      width: 100%;
      position: relative;
      text-align: left;
      color: #707070;
      font-family: Nunito;
      font-size: 20px;
      outline: none;
      font-weight: 400;

      &::after {
        position: absolute;
        content: "";
        right: 28px;
        top: 50%;
        margin-top: -9px;
        width: 17px;
        height: 18px;
        background: {
          image: url("../.././images/icons/icon-edit.svg");
          repeat: no-repeat;
          size: contain;
        }
      }
    }

    &-field {
      display: block;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px 4px 0 0;
      background-color: #fff;
      padding: 22px 40px;
      width: 100%;
      position: relative;
      text-align: left;
      color: #707070;
      font-family: Nunito, Sans-Serif;
      font-size: 20px;
      font-weight: 400;
      outline: none;

      @include r(1700) {
        padding: 18px 36px;
        font-size: 18px;
      }
      @include r(1500) {
        padding: 16px 30px;
        font-size: 17px;
      }
      @include r(700) {
        background-color: #f7f7f7;
        border-radius: 4px;
        box-shadow: none;
      }
      @include r(550) {
        font-size: 15px;
      }
    }
  }

  &__bio {
    display: block;
    margin-top: 20px;

    textarea {
      resize: none;
    }

    @include r(700) {
      margin-top: 15px;
    }

    .v-input__slot {
      @include r(700) {
        background-color: #f7f7f7 !important;
        box-shadow: none !important;
        border-radius: 4px !important;
      }
    }
  }
}

.v-dialog--pictures {
  height: 90%;

  .v-card {
    height: 100% !important;

    .v-card__text {
      padding: 20px;
      height: calc(100% - 56px) !important;

      & > div {
        height: 100% !important;
      }

      .cropper-container.cropper-bg {
        height: 100% !important;
        background-image: none;

        .cropper-wrap-box {
          height: 100% !important;

          .cropper-canvas {
            height: 100% !important;
          }
        }
      }
    }

    .v-card__actions {
      padding: 0 20px 20px 20px;
    }
  }
}
</style>

