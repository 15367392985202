import { GET_CUSTOMER } from '../action-types';
import { SET_CUSTOMER_DATA } from '../mutation-types';
import * as CONFIG from "@/config.js";
import axios from "axios";

export default {
  state: {
    customer: {
      first_name: null,
      last_name: null,
      birthday: null,
      nationality: null,
      phone: null,
      email: null,
      country_of_residence: null,
      gender: null,
      active: null
    }
  },
  mutations: {
    [SET_CUSTOMER_DATA]: () => {

    }
  },
  actions: {
    [GET_CUSTOMER]: () => {
      return new Promise((resolve, reject) => {
        axios
          .get(`${CONFIG.API_URL}/dashboard/user/settings`, {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest"
            }
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  getters: {

  }

}