<template>
  <div>
    <div class="profile-sorting" :class="{ hide: $vuetify.breakpoint.width <= 1000}">
      <div class="profile-sorting__item">

        <v-popover
            trigger="manual"
            :open="restrictMobileWithoutPostCode"
            :close="!restrictMobileWithoutPostCode"
            :autoHide="false"
            placement="top"
            popoverClass="popover-postcode"
        >
          <template slot="popover">
            <span>
              {{ $t('practitioner_search_availability.postcode_missing_for_mobile_error') }}
            </span>
            &nbsp;
            <span
                @click.stop="restrictMobileWithoutPostCode = false"
                @click="breadcrumbsCloseFirst"
                class="pointer"
            >
             {{ $t('practitioner_search_availability.ok') }}
            </span>
          </template>
        </v-popover>
        <strong class="profile-sorting__span">{{ selectedSearchAddress }}</strong>
        <button
            class="profile-sorting__delete"
            @click="breadcrumbsCloseFirst"
        >
          <v-icon>close</v-icon>
        </button>
      </div>
      <div class="profile-sorting__item">
        <strong class="profile-sorting__span">{{
            $t(`practitioner_search.service_type_${searchServiceType}`)
          }}</strong>
        <button
            class="profile-sorting__delete"
            @click="breadcrumbsCloseSecond"
        >
          <v-icon>close</v-icon>
        </button>
      </div>
      <div class="profile-sorting__item profile-sorting__item--mob">
        <strong class="profile-sorting__span">{{ selectedLocationType | capitalize }}</strong>
        <button
            class="profile-sorting__delete"
            @click="breadcrumbsCloseThird"
        >
          <v-icon>close</v-icon>
        </button>
      </div>
      <div class="profile-sorting__item profile-sorting__item--mob">
        <strong class="profile-sorting__strong">
          {{ $t('breadcrumbs.select_practitioner') }}
        </strong>
      </div>
      <div class="profile-sorting__item profile-sorting__item--mob">
        <span class="profile-sorting__strong" v-html="$t('breadcrumbs.select_date_time')"></span>
      </div>
      <div class="profile-sorting__item profile-sorting__item--mob">
        <span class="profile-sorting__strong">
          {{ $t('breadcrumbs.confirm_payment') }}
        </span>
      </div>
    </div>
    <div class="profile-search">
      <div
          class="profile-search__lside"
          :class="{'is-open': mobileUi.showFilter}"
      >
        <div class="filter">
          <div
              class="filter__close"
              @click="hideMobileFilter"
          >
            <i
                aria-hidden="true"
                class="v-icon material-icons theme--light"
            >close</i>
          </div>
          <div class="filter__inner">
            <div class="filter__top">
              <span>
                 {{ $t('practitioner_search.filters') }}
              </span>
            </div>
          </div>
          <div class="filter__inner filter__inner--datepicker">
            <DatePicker
                @changedSelectedDate="changedDateFromComponent"
            ></DatePicker>
          </div>

          <div class="filter__inner">
            <div class="filter__top">
              <span>
                 {{ $t('practitioner_search.filter_by_time') }}
              </span>
            </div>
          </div>
          <div class="filter__inner filter-time">
            <v-layout row>
              <v-range-slider
                  v-model="selectedTime"
                  v-on:end="selectedTimeUpdate()"
                  :min="0"
                  :max="24"
                  :step="1"
              ></v-range-slider>
            </v-layout>
            <v-layout
                row
                class="filter-time__value"
            >
              <span>
                {{ formattedTime[0] }}
              </span>
              <span>
                {{ formattedTime[1] }}
              </span>
            </v-layout>
          </div>
          <div class="filter__inner">
            <div class="filter__title filter__title--sm">
              <span>
              {{ $t('practitioner_search.location') }}
                </span>
            </div>
            <div class="filter__checkboxes checkbox-block">
              <v-radio-group
                  v-model="selectedLocationType"
                  v-on:change="selectedTimeUpdate()"
                  hide-details
              >
                <v-radio
                    label="Mobile"
                    value="mobile"
                ></v-radio>
                <v-radio
                    label="Salon"
                    value="salon"
                ></v-radio>
                <v-radio
                    label="I Don’t Mind"
                    value="idm"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
          <div class="filter__inner checkbox-block" v-if="selectedLocationType === 'salon'">
            <div class="filter__title filter__title--sm">
              <span>
              {{ $t('practitioner_search.distance') }}
                </span>
            </div>
            <div class="filter__checkboxes">
              <v-radio-group
                  v-model="filteredDistance"
                  hide-details
              >
                <v-radio
                    :label="$t('practitioner_search.distance_label_1')"
                    :value='0.5'
                ></v-radio>
                <v-radio
                    :label="$t('practitioner_search.distance_label_2')"
                    :value='1'
                ></v-radio>
                <v-radio
                    :label="$t('practitioner_search.distance_label_3')"
                    :value='5'
                ></v-radio>
                <v-radio
                    :label="$t('practitioner_search.distance_label_4')"
                    :value='10'
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-search__content">
        <div class="profile-search__sort--wrap">
          <p v-if="!requestDataProgress && filteredPractitionerList.length > 0" class="profile-search__sort--title">
            {{ $t('practitioner_search.choose_treatment') }}</p>
          <div
              class="profile-search__sort"
              v-if="requestDataProgress"
          >
            <v-progress-linear
                indeterminate
                color="error"
                height="3"
            ></v-progress-linear>
          </div>
          <div
              class="profile-search__sort profile-search__sort--categories"
              v-else-if="filteredPractitionerList.length > 0"
          >
            <div class="profile-search__sort-inners">
              <div class="profile-search__sort-inner header__inner">
            <span
                class="profile-search__categories"
                :class="{'profile-search__categories_red': selectedCategory === null}"
                @click="setTreatmentsList('all')"
            >{{ $t('practitioner_search.all') }}</span>
              </div>
              <div
                  v-for="(category, slug) in availableTreatments"
                  class="profile-search__sort-inner header__inner"
                  :key="slug"
              >
            <span
                class="profile-search__categories"
                :class="{'profile-search__categories_red': selectedCategory === slug}"
                @click="setTreatmentsList(slug)"
            >{{ category.category }}</span>
              </div>
            </div>
            <div
                class="profile-search__sort-inner sort-filter"
                v-if="filteredPractitionerList.length > 0"
            >
              <v-select
                  :items="sortItems"
                  v-model="sortByValue"
                  item-text="text"
                  item-value="value"
                  :label="$t('practitioner_search.sort_by')"
                  solo
                  hide-details
                  color="error"
                  light
              ></v-select>
            </div>
          </div>

          <div
              class="profile-search__sort profile-search__sort--chip"
              v-if="showTreatmentList && filteredPractitionerList.length > 0"
          >
            <div
                class="profile-search__sort-inner profile-search__treatments-filter"
                v-if="!treatmentChip"
            >
              <v-select
                  :items="treatmentsList"
                  v-model="selectedTreatmentId"
                  item-text="text"
                  item-value="value"
                  :label="labelTreatmentList"
                  solo
                  hide-details
                  color="error"
                  light
              ></v-select>
            </div>

            <v-chip
                v-else
                close
                solo
                label
                v-model="treatmentChip"
                @input="closeChip"
            > {{ treatmentChipLabel }}
            </v-chip>

          </div>
          <div class="profile-actions-fix">
            <button
                class="profile-actions-fix__button profile-actions-fix__filter"
                @click="showMobileFilter"
            ><span>
        {{ $t('practitioner_search.filter') }}
      </span></button>
            <button
                class="profile-actions-fix__button profile-actions-fix__sort"
                @click="showMobileSort"
            ><span>
        {{ $t('practitioner_search.sort') }}
      </span></button>
          </div>
          <div class="profile-search__sort profile-search__sort--last">
            <div
                class="profile-search__sort-inner"
                v-if="filteredPractitionerList.length < 1 && !requestDataProgress && !updateDataProgress"
            >
              <strong>
                {{ $t('practitioner_search.no_available_practitioners_line', {address: this.selectedSearchAddress}) }}
              </strong>
            </div>

            <v-progress-linear
                class="v-progress-linear__sort"
                v-if="updateDataProgress"
                indeterminate
                color="error"
                height="3"
            ></v-progress-linear>
          </div>
        </div>
        <div class="profile-blocks">
          <div
              class="profile-block"
              v-for="item in practitionerListFilteredVisible"
              :key="item.id"
          >
            <div class="profile-block__lside">
              <div
                  v-if="item.avatar"
                  class="profile-block__photo"
                  v-bind:style="{ 'background-image': 'url(' + item.avatar + ')' }"
                  @click="showPractitinerInfo(`${ JSON.stringify(item) }`)"
              >
              </div>
              <div
                  v-else
                  class="profile-block__photo profile-block__photo--empty"
                  @click="showPractitinerInfo(`${ JSON.stringify(item) }`)"
              >
              </div>
              <star-rating
                  v-model=item.practitioner_rating
                  read-only
                  :show-rating="false"
                  :star-size="22"
                  :border-width="0"
                  :increment="0.5"
                  :rounded-corners="true"
                  active-color="#ffd100"
              ></star-rating>
              <div class="profile-block__views" v-if="item.reviews_amount > 0">{{ item.reviews_amount }}</div>
            </div>
            <div class="profile-block__mside">
              <div class="profile-block__title">
                <div
                    class="profile-block__name"
                    @click="showPractitinerInfo(`${ JSON.stringify(item) }`)"
                >{{ item.name }}
                </div>
                <div class="profile-block__quality">
                  <div class="icon-experience"></div>
                  <span>{{ item.experience | experience }}</span>
                </div>
                <div class="profile-block__items">
                  <div class="profile-block__item">
                    <star-rating
                        v-model=item.practitioner_rating
                        read-only
                        :show-rating="false"
                        :star-size="22"
                        :border-width="0"
                        :increment="0.5"
                        :rounded-corners="true"
                        active-color="#ffd100"
                    ></star-rating>
                    <div class="profile-block__views" v-if="item.reviews_amount > 0">{{ item.reviews_amount }}</div>
                  </div>
                  <div class="profile-block__item">
                    <div class="profile-block__item-title">
                      {{ $t('practitioner_search.experience') }}
                      <b>7 Years</b>
                    </div>
                  </div>
                  <!-- <div class="profile-block__item">
                    <div class="profile-block__item-title">Battersea <span>(1.2 miles away)</span></div>
                  </div> -->
                </div>
              </div>
              <div class="profile-block__description">
                <p @click="showPractitinerInfo(`${ JSON.stringify(item) }`)">
                  {{ item.bio }}
                </p>
                <div class="profile-block__description-right">
                  <div
                      class="profile-block__description-open"
                      @click="showPractitinerInfo(`${ JSON.stringify(item) }`)"
                  >
                    {{ $t('practitioner_search.view_profile') }}
                  </div>
                  <v-btn
                      color="error"
                      class="btn-book-now"
                      @click="showPractitinerProfileBooking(`${ JSON.stringify(item) }`)"
                  >
                    {{ $t('practitioner_search.select') }}
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="profile-block__rside">
              <div class="profile-block__price">
                <strong>
                  <p
                      :class="{'d-inline': $vuetify.breakpoint.width >= 500, 'd-block': $vuetify.breakpoint.width < 500}"
                      v-if="!isSelectedTreatmentId"
                  >
                    {{ $t('practitioner_search.treatment_form_price') }}
                  </p>
                  £{{ item.service_price }}
                </strong>
                <p v-if="isSelectedTreatmentId">{{ item.service_name }}</p>
              </div>
              <div class="profile-block__info">
                <div class="profile-block__info-item">
                  <v-icon v-if="item.work_location_types.mobile">done</v-icon>
                  <v-icon v-else>close</v-icon>
                  <span>
                    {{ $t('practitioner_search.mobile') }}
                  </span>
                </div>
                <div class="profile-block__info-item">
                  <v-icon v-if="item.work_location_types.salon">done</v-icon>
                  <v-icon v-else>close</v-icon>
                  <span>
                     {{ $t('practitioner_search.salon') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            class="profile-search__pagination"
            v-if="filteredPractitionerList.length > 0 && pagesCount > 1"
        >
          <v-pagination
              v-model="currentPage"
              :length="pagesCount"
              circle
              color="error"
              @input="scrollTop"
          ></v-pagination>
        </div>

      </div>
    </div>

    <router-view
        name="search_practitioner_information"
        @showBookingModal="showBookingModal"
    ></router-view>
    <router-view name="search_practitioner_availability"></router-view>
    <div
        class="profile-sort-mob"
        :class="{'is-open': mobileUi.showSort}"
    >
      <div
          class="profile-sort-mob__close"
          @click="hideMobileSort"
      >
        <v-icon>close</v-icon>
      </div>
      <div class="profile-sort-mob__holder">
        <div class="profile-sort-mob__items">
          <div
              class="profile-sort-mob__item"
              v-for="(item, index) in sortItems"
              :key="index"
          >
            <label
                :for="index"
                class="radio-checkbox"
            >
              <input
                  type="radio"
                  name="sort"
                  :id="index"
                  value="item.value"
                  @change="mobileSorting(item.value)"
              >
              <span></span>
              <p>{{ item.text }}</p>
            </label>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
/* eslint-disable */

import _ from 'lodash'
import axios from "axios";
import * as CONFIG from "@/config.js";
import {formatExperience, getWidthOfWindow, empty} from "@/helpers.js";
import {checkSearchParams} from '@/router/middleware';
import moment from 'moment';
import DatePicker from '@/components/Partial/DatePicker'

export default {
  name: "Profile",
  components: {
    DatePicker
  },
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Practitioner Search'
  },
  props: ['isOpenMobileMenu'],
  beforeRouteUpdate: (to, from, next) => {
    checkSearchParams({to, from})
        .then(() => next())
        .catch((reject) => {
          next(reject);
        })
  },
  data() {
    return {
      restrictMobileWithoutPostCode: null,
      firstLoad: true,
      requestDataProgress: true,
      updateDataProgress: false,

      selectedDate: null,
      selectedTime: [0, 24],
      formatTimeFrom: '',
      formatTimeTo: '',

      filteredDistance: 10,

      sortItems: [
        {text: this.$i18n.t('practitioner_search.sort_by_price_hl'), value: 'price_hl'},
        {text: this.$i18n.t('practitioner_search.sort_by_price_lh'), value: 'price_lh'},
        {text: this.$i18n.t('practitioner_search.sort_by_experience'), value: 'experience'},
        {text: this.$i18n.t('practitioner_search.sort_by_reviews'), value: 'reviews'},
        {text: this.$i18n.t('practitioner_search.sort_by_recently_joined'), value: 'joined'}
      ],
      sortByValue: null,

      selectedSearchAddress: null,
      selectedSearchTown: null,

      searchLatitude: null,
      searchLongitude: null,

      selectedSubServiceId: null,
      selectedSubServiceName: null,

      selectedLocationType: null,

      searchServiceType: null,
      selectedCategory: null,
      selectedTreatmentId: null,

      treatmentsList: [],
      showTreatmentList: false,
      labelTreatmentList: '',
      treatmentChip: false,
      treatmentChipLabel: '',

      availableTreatments: {},

      mobileUi: {
        showFilter: false,
        showSort: false
      },

      currentPage: 1,
      practitionerList: [],
      filteredPractitionerList: [],
      sortedPractitionerList: [],
      practitionerListFilteredVisible: [],
      window: {
        width: 0,
        height: 0
      }

    }
  },
  created: function () {
    this.setFormattedTime();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      return (value === 'idm') ? value.toUpperCase() : value[0].toUpperCase() + value.slice(1);
    },
    experience: function (value) {
      return formatExperience(value);
    }
  },
  computed: {
    pagesCount: function () {
      return Math.ceil(this.filteredPractitionerList.length / 10);
    },
    formattedTime: function () {
      let start = moment(this.selectedTime[0], 'kk').format('HH:mm');
      let end = moment(this.selectedTime[1], 'kk').format('HH:mm');
      end = (end === '00:00') ? moment(this.selectedTime[1], 'kk').subtract(1, 'minutes').format('HH:mm') : end;
      return [start, end];
    },
    // needUpdateAvailableTreatments() {
    //   return !empty(this.searchServiceType) && empty(this.selectedCategory) && empty(this.selectedTreatmentId);
    // },
    isSelectedTreatmentId() {
      return (!empty(this.selectedTreatmentId)) ? true : false;
    }
  },
  watch: {
    selectedSearchAddress() {
      this.displayMobileRestriction();
    },
    selectedLocationType() {
      this.displayMobileRestriction();
    },
    isOpenMobileMenu(status) {
      if (status) {
        this.hideMobileFilter();
      }
    },
    practitionerList() {
      this.filterPractitionersBy();
    },
    filteredDistance() {
      this.showUpdateDataProgress();
      // this.requestDataProgress = true;
      // this.resetTretmentList();
      this.requestPractitionerData(false);
    },
    filteredPractitionerList() {
      this.sortPractitionersBy();
    },
    currentPage() {
      this.preparedForPagination();
    },
    sortByValue() {
      this.sortPractitionersBy();
    },
    selectedTreatmentId(treatmentId) {
      this.showUpdateDataProgress();
      if (!empty(treatmentId)) {
        this.treatmentChip = true;
        let treatment = _.find(this.treatmentsList, (item) => {
          return item.value === treatmentId;
        });
        if (!empty(treatment)) {
          this.treatmentChipLabel = `Showing: ${treatment.text}`;
        }
        this.$store.dispatch('SET_SEARCH_PARAMS', {searchSubServiceId: treatmentId});
      } else {
        this.treatmentChip = false;
        this.treatmentChipLabel = '';
        this.$store.dispatch('SET_SEARCH_PARAMS', {searchSubServiceId: ''});
      }
      this.requestPractitionerData(false);
    }
  },
  mounted() {
    this.setSearchVariables();
    this.requestPractitionerData(true);
    this.$watch('selectedDate', (date) => this.watchForSelectedDate(date));
  },
  methods: {
    changedDateFromComponent(date) {
      this.selectedDate = date;
    },
    closeChip() {
      this.selectedTreatmentId = null;
      this.$store.dispatch('SET_SEARCH_PARAMS', {searchSubServiceId: ''});
    },
    showUpdateDataProgress() {
      if (!this.requestDataProgress) {
        this.updateDataProgress = true;
      }
    },
    createTreatmentsList(type) {
      const currentTypeData = this.availableTreatments[type];
      let list = [];
      for (let treatment in currentTypeData.treatments) {
        list.push({text: currentTypeData.treatments[treatment], value: treatment});
      }
      return list;
    },
    setTreatmentsList(type) {
      if (type === 'all') {
        this.resetTretmentList();
        return;
      }
      this.showUpdateDataProgress();
      this.selectedTreatmentId = null;
      this.treatmentsList = this.createTreatmentsList(type);
      this.selectedCategory = type;
      this.showTreatmentList = true;
      this.labelTreatmentList = this.$i18n.t('practitioner_search.label_treatment_list', {category: this.availableTreatments[type].category.toLowerCase()});
      this.requestPractitionerData(false);
    },
    resetTretmentList() {
      this.treatmentsList = [];
      this.selectedCategory = null;
      this.selectedTreatmentId = null;
      this.showTreatmentList = false;
      this.labelTreatmentList = '';
      this.requestPractitionerData(false);
    },
    watchForSelectedDate(date) {
      this.showUpdateDataProgress();
      // this.requestDataProgress = true;
      // this.resetTretmentList();
      this.setFormattedTime();
      this.requestPractitionerData(false);
      this.$store.dispatch('SET_SEARCH_PARAMS', {searchDate: date});
    },
    setSearchVariables() {
      if (Object.keys(this.$route.query).length !== 0 && this.$route.query.address && this.$route.query.town && this.$route.query.lat && this.$route.query.lon && this.$route.query.location && this.$route.query.type) {
        this.selectedSearchAddress = this.$route.query.address;
        this.selectedSearchTown = this.$route.query.town;
        this.searchLatitude = this.$route.query.lat;
        this.searchLongitude = this.$route.query.lon;
        // this.selectedSubServiceId = parseInt(this.$route.query.id);
        // this.selectedSubServiceName = this.$route.query.service;
        this.selectedLocationType = this.$route.query.location;
        this.searchServiceType = this.$route.query.type;

        this.$store.dispatch('SET_SEARCH_PARAMS', {
          searchAddress: this.$route.query.address,
          searchTown: this.$route.query.town,
          searchLatitude: this.$route.query.lat,
          searchLongitude: this.$route.query.lon,
          // searchSubServiceId: parseInt(this.$route.query.id),
          // searchSubServiceName: this.$route.query.service,
          searchLocationType: this.$route.query.location,
          searchServiceType: this.$route.query.type
        });
      } else {
        if (!this.$store.getters.isValidSearchParams) {
          this.$router.push({path: '/services-search'});
        }
        this.selectedSearchAddress = this.$store.getters.search.searchAddress;
        this.selectedSearchTown = this.$store.getters.search.searchTown;
        this.searchLatitude = this.$store.getters.search.searchLatitude;
        this.searchLongitude = this.$store.getters.search.searchLongitude;
        // this.selectedSubServiceId = this.$store.getters.search.searchSubServiceId;
        // this.selectedSubServiceName = this.$store.getters.search.searchSubServiceName;
        this.selectedLocationType = this.$store.getters.search.searchLocationType;
        this.searchServiceType = this.$store.getters.search.searchServiceType;
      }
    },
    filterPractitionersBy() {
      this.filteredPractitionerList = [];

      if (this.selectedLocationType === 'salon') {
        // for (let i = 0; i < this.practitionerList.length; i++) {
        //   if (this.practitionerList[i].distance_from_point.salon) {
        //     for (let k = 0; k < this.practitionerList[i].distance_from_point.salon.length; k++) {
        //       if (this.practitionerList[i].distance_from_point.salon[k] <= this.filteredDistance) {
        //         this.filteredPractitionerList.push(this.practitionerList[i]);
        //         break;
        //       }
        //     }
        //   }
        // }
        this.filteredPractitionerList = this.practitionerList;
      } else if (this.selectedLocationType === 'mobile') {
        // for (let i = 0; i < this.practitionerList.length; i++) {
        //   if (this.practitionerList[i].distance_from_point.home <= this.filteredDistance && this.practitionerList[i].distance_to_travel >= this.practitionerList[i].distance_from_point.home) {
        //     this.filteredPractitionerList.push(this.practitionerList[i]);
        //   }
        // }

        this.filteredPractitionerList = this.practitionerList;
      } else if (this.selectedLocationType === 'idm') {
        // for (let i = 0; i < this.practitionerList.length; i++) {
        //   let already_in_list = false;
        //
        //   if (this.practitionerList[i].distance_from_point.home <= this.filteredDistance && this.practitionerList[i].distance_to_travel >= this.practitionerList[i].distance_from_point.home) {
        //     already_in_list = true;
        //     this.filteredPractitionerList.push(this.practitionerList[i]);
        //   }
        //
        //   if (!already_in_list && this.practitionerList[i].distance_from_point.salon) {
        //     for (let k = 0; k < this.practitionerList[i].distance_from_point.salon.length; k++) {
        //       if (this.practitionerList[i].distance_from_point.salon[k] <= this.filteredDistance) {
        //         this.filteredPractitionerList.push(this.practitionerList[i]);
        //         break;
        //       }
        //     }
        //   }
        // }

        this.filteredPractitionerList = this.practitionerList;
      }

      return this.filteredPractitionerList;
    },
    sortPractitionersBy() {

      this.sortedPractitionerList = [];

      if (this.sortByValue === 'price_hl') {
        this.sortedPractitionerList = _.orderBy(this.filteredPractitionerList, ['service_price'], ['desc']);
      } else if (this.sortByValue === 'price_lh') {
        this.sortedPractitionerList = _.orderBy(this.filteredPractitionerList, ['service_price'], ['asc']);
      } else if (this.sortByValue === 'experience') {
        this.sortedPractitionerList = _.orderBy(this.filteredPractitionerList, ['experience'], ['desc']);
      } else if (this.sortByValue === 'reviews') {
        this.sortedPractitionerList = _.orderBy(this.filteredPractitionerList, ['reviews_amount'], ['desc']);
      } else if (this.sortByValue === 'joined') {
        this.sortedPractitionerList = _.orderBy(this.filteredPractitionerList, ['recently_joined'], ['desc']);
      } else {
        this.sortedPractitionerList = this.filteredPractitionerList;
      }

      this.preparedForPagination();
    },
    preparedForPagination() {
      this.practitionerListFilteredVisible = this.chunk(this.sortedPractitionerList, 10)[this.currentPage - 1];
    },
    displayMobileRestriction() {
      // console.log('selectedLocationType is ' + this.selectedLocationType);
      if (this.selectedLocationType == 'mobile') {
        this.restrictMobileWithoutPostCode = (this.selectedSearchAddress ? (!this.selectedSearchAddress.match(/(^|\W)[\w]{2,4}\s+[\w]{3}(\W|$)/)) : false);
        // console.log('restrictMobileWithoutPostCode is ' + this.restrictMobileWithoutPostCode);
      }
    },
    popoverClass() {
      return "popover-postcode";
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 980) {
        let code = this.$store.getters.search.searchPostcode;
        let address = this.$store.getters.search.searchAddress;
        this.selectedSearchAddress = (!empty(code)) ? code : address;
      } else {
        this.selectedSearchAddress = this.$store.getters.search.searchAddress;
      }
    },
    scrollTop() {
      this.$scrollTo('.header.v-toolbar');
    },
    chunk(array, size) {
      if (!array.length) {
        return [];
      }
      const head = array.slice(0, size);
      const tail = array.slice(size);

      return [head, ...this.chunk(tail, size)];
    },
    showMobileFilter() {
      this.mobileUi.showFilter = true;
    },
    hideMobileFilter() {
      this.mobileUi.showFilter = false;
    },
    showMobileSort() {
      this.mobileUi.showSort = true;
    },
    hideMobileSort() {
      this.mobileUi.showSort = false;
    },
    mobileSorting(sortBy) {
      this.sortByValue = sortBy;
      this.hideMobileSort();
      this.hideMobileFilter();
    },
    currentDate() {
      return moment().clone().format('YYYY-MM-DD');
    },
    setFormattedTime() {
      this.formatTimeFrom = moment(this.selectedTime[0], 'kk').format('HH:mm');
      this.formatTimeTo = moment(this.selectedTime[1], 'kk').format('HH:mm');
      this.formatTimeTo = (this.formatTimeTo === '00:00') ? '23:59' : this.formatTimeTo;
    },
    selectedTimeUpdate: _.debounce(function () {
      this.$store.dispatch('SET_SEARCH_PARAMS', {searchLocationType: this.selectedLocationType});
      this.showUpdateDataProgress();
      // this.requestDataProgress = true;
      // this.resetTretmentList();
      this.setFormattedTime();
      this.requestPractitionerData(false);
    }, 800),
    requestPractitionerData: _.debounce(function (firstLoad) {
      this.currentPage = 1;
      this.scrollTop();

      const params = {
        // sub_service: this.selectedSubServiceId,
        date: this.selectedDate,
        from: this.formatTimeFrom,
        to: this.formatTimeTo,
        lat: this.searchLatitude,
        lon: this.searchLongitude,
        locationtype: this.selectedLocationType,

        serviceType: this.searchServiceType,
        categorySlug: this.selectedCategory,
        treatmentId: this.selectedTreatmentId,
        // withAvailableTreatments: this.needUpdateAvailableTreatments,
        withAvailableTreatments: this.firstLoad,
        filteredDistance: this.filteredDistance
      };

      if (this.firstLoad === true) {
        params.all_practitioners = true;
      }

      this.getPractitioners(params)
          .then((response) => {
            const data = response.data.availablePractitioners;
            if (this.firstLoad === true) {
              this.availableTreatments = response.data.availableTreatments;
            }
            this.preparedPractitionerList(data);
            this.requestDataProgress = false;
            this.updateDataProgress = false;
            this.firstLoad = false;
          })
          .catch((error) => {
            this.practitionerList = [];
            // this.availableTreatments = [];
            this.requestDataProgress = false;
            this.updateDataProgress = false;
            console.log(error.response.data);
          });
    }, 100),
    preparedPractitionerList(data) {
      this.practitionerList = [];
      for (let id in data) {
        let lowestPriceTreatment = this.getLowestPriceTreatment(data[id].treatments);
        data[id]['id'] = id;
        data[id].service_name = (!empty(lowestPriceTreatment)) ? lowestPriceTreatment.treatmentName : '';
        data[id].service_price = (!empty(lowestPriceTreatment)) ? Number(lowestPriceTreatment.treatmentPrice) : '';
        this.practitionerList.push(data[id]);
      }
    },
    getLowestPriceTreatment(treatments) {
      let sortedTreatments = treatments.sort(this.compareServicesName);
      return _.head(sortedTreatments);
    },
    compareServicesName(a, b) {
      const a_number = parseFloat(a.treatmentPrice);
      const b_number = parseFloat(b.treatmentPrice);

      if (!isNaN(a_number) && !isNaN(b_number)) {
        return a_number - b_number;
      } else {
        return a.treatmentPrice.localeCompare(b.treatmentPrice)
      }
    },
    getPractitioners(params) {
      return axios.get(`${CONFIG.API_URL}/practitioners/available`, {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest"
        },
        params: params
      });
    },
    breadcrumbsCloseFirst() {
      this.$router.push({path: '/services-search'});
    },
    breadcrumbsCloseSecond() {
      this.$router.push({
        path: '/services-search', query: {
          address: this.selectedSearchAddress,
          town: this.selectedSearchTown,
          lat: this.searchLatitude,
          lon: this.searchLongitude,
          type: this.searchServiceType
        }
      });
    },
    breadcrumbsCloseThird() {
      this.$router.push({
        path: '/select-location', query: {
          address: this.selectedSearchAddress,
          town: this.selectedSearchTown,
          lat: this.searchLatitude,
          lon: this.searchLongitude,
          // id: this.selectedSubServiceId,
          // service: this.selectedSubServiceName
          type: this.searchServiceType
        }
      });
    },
    showPractitinerInfo(data) {
      const searchCoordinates = {
        lat: this.searchLatitude,
        lon: this.searchLongitude
      };
      let props = JSON.parse(data);
      props['search_coordinates'] = searchCoordinates;

      if (this.$router.currentRoute.name === 'search-practitioner-information') {
        this.$router.replace({name: 'search-practitioner-information', params: {data: props, id: props.id}});
      } else {
        this.$router.push({name: 'search-practitioner-information', params: {data: props, id: props.id}});
      }
    },
    showPractitinerProfileBooking(data) {
      const practitioner_data = JSON.parse(data);
      const props = {id: practitioner_data.id};
      this.$router.push({name: 'search-practitioner-availability', params: props});
    },
    showBookingModal(id, name, work_location_types) {
      const props = {id: id};
      this.$router.push({name: 'search-practitioner-availability', params: props});
    }
  }
};

</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.filter {
  padding: 40px 50px;

  &__datepicker {
    display: block;

    &-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;
    }

    &-prev {
      width: 25px;
      height: 25px;
      cursor: pointer;
      background: {
        image: url("../../images/icons/arrow-black-prev.svg");
        repeat: no-repeat;
        size: 8px;
        position: center;
      }
      transition: .3s;

      &:hover {
        opacity: .7;
      }
    }

    &-next {
      width: 25px;
      height: 25px;
      cursor: pointer;
      background: {
        image: url("../../images/icons/arrow-black-next.svg");
        repeat: no-repeat;
        size: 8px;
        position: center;
      }
      transition: .3s;

      &:hover {
        opacity: .7;
      }
    }

    &-year {
      width: 120px;
      margin-top: 2px;

      .v-input__slot {
        background-color: transparent !important;
        box-shadow: none !important;
        position: relative;
        text-align: center;
        padding-left: 24px !important;
      }

      .v-input__control {
        min-height: 32px !important;
      }

      .v-input__append-inner {
        position: absolute;
        right: -20px;
      }

      .v-input__icon {
        i {
          font-size: 17px;
          color: transparent !important;
          background: {
            image: url("../../.././src/images/icons/icon-drop-arrow-down-red.svg");
            repeat: no-repeat;
            position: center;
          }
          @include r(1500) {
            font-size: 14px;
            top: -1px;
            position: relative;
          }
        }
      }
    }

    &-table {
      display: flex;
      align-items: stretch;
      margin: 14px -2px 0 -2px;
      justify-content: center;
    }

    &-date {
      display: block;
      padding: 8px;
      min-width: 36px;
      border-radius: 5px;
      background-color: transparent;
      transition: .3s;
      margin: 0 2px;
      text-align: center;
      cursor: pointer;

      @include r(1500) {
        padding: 6px;
        min-width: 32px;
      }

      @include r(1300) {
        padding: 5px;
        min-width: 30px;
      }

      &.is-active {
        background-color: #f25757;

        .filter {
          &__datepicker {
            &-day {
              color: #fff;
            }

            &-number {
              color: #fff;
            }
          }
        }
      }

      &.is-start-month {
        background-color: #f25757;
        opacity: .2;

        .filter {
          &__datepicker {
            &-day {
              color: #fff;
            }

            &-number {
              color: #fff;
            }
          }
        }
      }

      &.is-disable {
        background-color: #888;
        opacity: .2;
        pointer-events: none;

        .filter {
          &__datepicker {
            &-day {
              color: #fff;
            }

            &-number {
              color: #fff;
            }
          }
        }
      }

      &:hover {
        background-color: #f25757;

        .filter {
          &__datepicker {
            &-day {
              color: #fff;
            }

            &-number {
              color: #fff;
            }
          }
        }
      }
    }

    &-day {
      display: block;
      color: #535353;
      font-family: Nunito;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      transition: .3s;

      @include r(1500) {
        font-size: 16px;
      }
      @include r(1300) {
        font-size: 15px;
      }
    }

    &-number {
      display: block;
      margin-top: 26px;
      color: #535353;
      font-family: Nunito;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      transition: .3s;

      @include r(1500) {
        font-size: 16px;
      }
      @include r(1300) {
        margin-top: 20px;
        font-size: 15px;
      }
    }
  }

  &__close {
    display: none;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    opacity: 0.9;
    transition: 0.3s;

    @include r(1000) {
      display: block;
    }

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }

    i {
      font-size: 32px;
    }
  }

  @include r(1700) {
    padding: 28px 32px;
  }

  @include r(1500) {
    padding: 30px 20px 40px 20px;
  }

  &__inner {
    display: block;
    margin-bottom: 24px;

    .v-input--selection-controls {
      @include r(1500) {
        margin-top: 8px;
      }
    }

    .v-radio {
      @include r(1500) {
        margin-bottom: 4px;
      }
    }

    @include r(1500) {
      margin-bottom: 22px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.filter-time {
      .v-input--slider {
        margin-top: 0;
      }

      @include r(650) {
        padding: 0 35px;
      }
    }

    &--datepicker {

      .v-picker__body {
        @include r(1000) {
          background-color: transparent;
        }
      }
    }
  }

  &__top {
    text-align: center;

    span {
      color: #888;
      font-size: 23px;
      font-weight: 400;
      display: inline-block;
      padding: 0 54px 20px 54px;
      border-bottom: 1px solid rgba(#f3555a, 0.29);

      @include r(1700) {
        font-size: 21px;
        padding: 0 50px 15px 50px;
      }

      @include r(1500) {
        font-size: 20px;
        padding: 0 44px 10px 44px;
      }
    }
  }

  &__title {
    display: block;
    text-align: center;
    margin-bottom: 40px;

    span {
      color: #888;
      font-size: 20px;
      font-weight: 400;
      padding: 0 54px 20px 54px;
      border-bottom: 1px solid rgba(243, 85, 90, 0.29);

      @include r(1700) {
        font-size: 21px;
        padding: 0 50px 15px 50px;
      }

      @include r(1500) {
        font-size: 20px;
        padding: 0 44px 10px 44px;
      }
      @include r(1300) {
        font-size: 20px;
      }
    }

    @include r(1700) {
      font-size: 18px;
    }

    @include r(1500) {
      font-size: 16px;
    }
  }

  &__checkboxes {
    display: block;

    @include r(650) {
      max-width: 163px;
      margin: 0 auto;
    }
  }
}

.profile-sorting {
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 40px;

  @include r(1700) {
    padding: 14px 30px;
  }
  @include r(1600) {
    padding: 14px 24px;
  }
  @include r(1400) {
    padding: 12px 20px;
  }
  @include r(980) {
    padding: 12px 18px;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: 5px 20px;
    position: relative;
    justify-content: center;

    @include r(1250) {
      padding: 5px 24px;
    }

    @include r(980) {
      flex: 1 1 auto;
      padding: 1px 10px;
    }

    &--mob {
      @include r(980) {
        display: none;
      }
    }

    &::after {
      right: 0;
      content: "";
      top: 50%;
      height: 32px;
      width: 1px;
      background-color: #272727;
      position: absolute;
      margin-top: -16px;

      @include r(980) {
        display: none;
      }
    }

    &:first-child {
      padding-left: 0;

      @include r(980) {
        padding-left: 10px;
      }
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }

  &__delete {
    margin: 2px 0 0 20px;
    opacity: 1;
    flex: 0 0 auto;
    outline: none;
    transition: 0.3s;
    @include r(1700) {
      margin-top: 1px;
    }
    @include r(1500) {
      margin-left: 14px;
    }

    @include r(980) {
      margin-left: 10px;
    }

    i {
      color: #272727 !important;
      font-size: 32px;
      line-height: 1;

      @include r(1700) {
        font-size: 28px;
      }

      @include r(1500) {
        font-size: 26px;
      }

      @include r(1400) {
        font-size: 24px;
      }

      &:hover {
        opacity: .7;
        transition: 0.3s;
      }
    }

    &:hover {
      opacity: 1;
      transition: 0.3s;
    }
  }

  &__strong {
    color: #272727;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 600;

    @include r(1700) {
      font-size: 18px;
    }
    @include r(1500) {
      font-size: 16px;
    }
    @include r(1350) {
      font-size: 15px;
    }
  }

  &__span {
    color: #272727;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 300;
    @include r(1700) {
      font-size: 18px;
    }
    @include r(1500) {
      font-size: 16px;
    }
    @include r(1350) {
      font-size: 15px;
    }
  }
}

.profile-search {
  display: flex;
  background-color: #fdfdfd;

  @include r(1000) {
    display: block;
  }

  &__categories {
    display: inline-block;
    padding: 8px 28px;
    border-radius: 50px;
    border: 1px solid #fb1010;
    color: #272727;
    font-family: Nunito, Sans-Serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;
    white-space: nowrap;

    @include r(1600) {
      padding: 5px 22px;
      font-size: 19px;
    }

    @include r(1500) {
      padding: 6px 20px;
      font-size: 18px;
    }

    @include r(1300) {
      padding: 5px 20px;
      font-size: 16px;
    }
    @include r(750) {
      font-size: 15px;
    }

    &:hover {
      background-color: #f25757;
      color: #fff;
      transition: 0.3s;
    }

    &_red {
      background-color: #f25757;
      color: #fff;
    }
  }

  &__treatments-filter {
    padding-right: 0px;

    width: 100%;
    max-width: 300px;

    @include r(600) {
      width: 100%;
    }
  }

  &__pagination {
    display: block;
    margin-top: 60px;
    text-align: center;

    @include r(1500) {
      margin-top: 40px;
    }

    @include r(1000) {
      margin-top: 30px;
    }

    button {
      outline: none;
    }
  }

  &__sort {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 34px;

    @include r(1700) {
      padding: 14px 30px;
    }

    @include r(1240) {
      padding: 12px 24px;
    }
    @include r(1000) {
      padding: 18px 20px 16px 20px;
      position: relative;
    }
    @include r(750) {
      padding-left: 0;
      padding-right: 0;
    }

    &--wrap {
      @include r(1000) {
        background: white;
        margin: 0 -20px;
        padding: 17px 20px 0;
        position: sticky;
        top: 40px;
        z-index: 8;
      }
    }
    &--title {
      text-align: center;
      font-size: 18px;
      margin: 10px 0 0;
    }

    &--chip {
      padding-top: 0;
    }

    &--last {
      position: relative;
      padding: 15px 0;

      .v-progress-linear__sort {
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
      }

      @include r(1000) {
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: #d9d9d9;
        }
      }
    }

    &--categories {
      @include r(1000) {
        display: block;
        width: 100%;
        overflow-x: auto;
      }
    }

    &-inners {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include r(1000) {
        display: block;
        white-space: nowrap;
      }

      .profile-search {
        &__sort {
          &-inner {
            margin-right: 14px;
            margin-bottom: 14px;

            @include r(1300) {
              margin-right: 10px;
              margin-bottom: 10px;
            }
            @include r(1000) {
              margin-bottom: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &-inner {
      strong {
        color: #888;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.1;

        @include r(1700) {
          font-size: 22px;
        }
        @include r(1500) {
          font-size: 20px;
        }

        @include r(1300) {
          font-size: 18px;
        }

        @include r(1000) {
          color: #383838;
        }
        @include r(600) {
          font-size: 15px;
        }
      }

      .v-input__slot {
        margin: 0;
        border-radius: 4px !important;
        border: 1px solid #b2b2b2 !important;
        box-shadow: none !important;
        background-color: transparent !important;
      }

      .v-text-field__details {
        display: none;
      }

      .v-input__control {
        @include r(1600) {
          min-height: 40px !important;
        }
        @include r(1300) {
          min-height: 36px !important;
        }
      }

      .v-select__selection {
        font-size: 20px !important;

        @include r(1600) {
          font-size: 19px !important;
        }

        @include r(1500) {
          font-size: 18px !important;
        }

        @include r(1300) {
          font-size: 16px !important;
        }
        @include r(750) {
          font-size: 15px !important;
        }
      }

      .v-label {
        color: #272727 !important;
        font-family: Nunito;
        font-size: 20px !important;
        font-weight: 400 !important;
        @include r(1600) {
          font-size: 19px !important;
        }

        @include r(1500) {
          font-size: 18px !important;
        }

        @include r(1300) {
          font-size: 16px !important;
        }
        @include r(750) {
          font-size: 15px !important;
        }
      }

      .v-input__icon {
        i {
          font-size: 17px;
          color: transparent !important;
          background: {
            image: url("../../.././src/images/icons/icon-arrow-down-black.svg");
            repeat: no-repeat;
            position: center;
          }
          @include r(1500) {
            font-size: 14px;
            top: -1px;
            position: relative;
          }
        }
      }
    }

    &-inner.sort-filter {
      padding-right: 0;
      width: auto;
      max-width: 220px;
      margin-left: 20px;
      flex: 0 0 auto;

      @include r(1000) {
        display: none;
      }
    }

    .v-chip {
      border-radius: 5px !important;
      background-color: #b2b2b2 !important;
      margin: 0;

      @include r(600) {
        width: 100%;
      }

      &__content {
        color: #fff;
        font-family: Nunito, sans-serif;
        font-size: 20px;
        font-weight: 500;
        padding: 9px 20px 9px 30px;
        height: auto;

        @include r(1500) {
          padding: 6px 20px 6px 30px;
          font-size: 18px;
        }

        @include r(1300) {
          padding: 5px 0;
          font-size: 17px;
        }
        @include r(750) {
          font-size: 15px;
        }
        @include r(600) {
          justify-content: space-between;
          width: 100%;
          white-space: normal !important;
        }
      }

      &__close {
        i {
          display: none;
        }

        position: relative;
        cursor: pointer;
        width: 25px;
        height: 25px;
        transition: .3s;
        margin-left: 30px;
        flex: 0 0 auto;

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: 'X';
          color: #fff;
          font-family: Nunito, sans-serif;
          font-size: 20px;
          font-weight: 300;

          @include r(1500) {
            font-size: 18px;
          }
          @include r(1300) {
            font-size: 17px;
          }
        }

        &:hover {
          opacity: .7;
        }
      }
    }
  }

  &__lside {
    flex: 0 0 400px;
    border-right: 2px solid #feabae;

    @include r(1700) {
      flex: 0 0 350px;
    }

    @include r(1500) {
      flex: 0 0 300px;
    }

    @include r(1300) {
      flex: 0 0 270px;
    }
    @include r(1000) {
      position: fixed;
      width: 300px;
      top: 129px;
      left: 0;
      max-height: calc(100% - 129px);
      height: calc(100% - 129px);
      overflow-y: auto;
      z-index: 25;
      background-color: #fff;
      transform: translateX(-100%);
      transition: 0.4s;

      &.is-open {
        transform: translateX(0%);
        transition: 0.4s;
      }
    }
    @include r(650) {
      top: 114px;
      max-height: calc(100% - 114px);
      height: auto;
      border: 1px solid #e2e2e2 !important;
      border-radius: 0 0 5px 5px;
    }

    @include r(500) {
      width: 100%;
      border: 0;
    }
  }

  &__content {
    flex: 1 1 auto;
    padding: 0 138px 100px 12px;

    @include r(1700) {
      padding: 0 70px 100px 12px;
    }
    @include r(1500) {
      padding: 0 12px 100px 12px;
    }
    @include r(1000) {
      padding: 0 20px 80px 20px;
    }
  }
}

.profile-blocks {
  display: block;
}

.profile-block {
  display: flex;
  padding: 50px 30px 20px 90px;
  border-top: 1px solid #d9d9d9;
  position: relative;

  @include r(1700) {
    padding: 40px 20px 20px 50px;
  }

  @include r(1500) {
    padding: 30px 34px;
  }

  @include r(1300) {
    padding: 22px 18px;
  }

  @include r(750) {
    padding: 22px 0;
  }

  @include r(600) {
    border-top: 0;
  }

  &:first-child {
    border-top-color: #fbd2d3;

    @include r(1000) {
      border-top: 0;
    }
  }

  &__lside {
    display: block;
    text-align: center;
    flex: 0 0 auto;

    .vue-star-rating {
      margin-top: 5px;
      justify-content: center;

      @include r(900) {
        display: none !important;
      }
    }

    .profile-block__views {
      @include r(900) {
        display: none;
      }
    }

    @include r(600) {
      position: absolute;
      top: 22px;
      left: 0;
      z-index: 3;
    }
  }

  &__photo {
    display: block;
    width: 176px;
    height: 176px;
    border-radius: 20px;
    cursor: pointer;
    background: {
      repeat: no-repeat;
      position: center;
      size: cover;
      image: url("../../.././src/images/icons/icon-user-photo.svg");
      color: #ff5a5f;
    }

    &--empty {
      background-size: auto;
    }

    @include r(1700) {
      width: 165px;
      height: 165px;
    }

    @include r(1500) {
      width: 150px;
      height: 150px;
    }

    @include r(1300) {
      width: 130px;
      height: 130px;
    }

    @include r(600) {
      width: 100px;
      height: 100px;
      border-radius: 10px;
    }
    @include r(360) {
      width: 90px;
      height: 90px;
      border-radius: 10px;
    }
  }

  &__views {
    display: block;
    text-align: center;
    color: #a3a3a3;
    font-size: 15px;
    font-weight: 400;
    margin-top: 2px;
  }

  &__mside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    padding: 0 70px;

    @include r(1700) {
      padding: 0 40px;
    }
    @include r(1500) {
      padding: 0 30px;
    }

    @include r(1300) {
      padding: 0 24px;
    }
    @include r(600) {
      padding: 0;
    }
  }

  &__items {
    display: none;

    @include r(900) {
      display: block;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-top: 6px;

    .vue-star-rating {
      svg {
        width: 18px;
        height: 18px;
      }
    }

    &-title {
      color: #383838;
      font-size: 13px;
      font-weight: 400;

      b {
        font-weight: 400;
        display: inline-block;
        margin-left: 5px;
      }

      span {
        color: #b2b2b2;
        display: inline-block;
        margin-left: 5px;
      }
    }

    .profile-block {
      &__views {
        margin: 0 0 0 4px;
        color: #a3a3a3;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include r(900) {
      display: block;
    }

    @include r(600) {
      padding-left: 116px;
    }

    @include r(360) {
      padding-left: 106px;
    }
  }

  &__name {
    display: inline-block;
    flex: 0 0 auto;
    color: #383838;
    font-size: 37px;
    margin-right: 80px;
    font-weight: 600;
    cursor: pointer;
    min-width: 200px;

    @include r(1700) {
      font-size: 30px;
      margin-right: 50px;
    }

    @include r(1500) {
      font-size: 26px;
    }

    @include r(1300) {
      font-size: 24px;
    }

    @include r(1000) {
      font-size: 20px;
    }
    @include r(600) {
      margin: 0;
      padding-right: 80px;
      display: block;
      width: 100%;
    }
  }

  &__quality {
    display: flex;
    align-items: center;
    flex: 1 1 auto;

    span {
      color: #535353;
      font-size: 20px;
      font-weight: 400;
      padding: 0 0 0 12px;

      @include r(1500) {
        font-size: 18px;
      }

      @include r(1300) {
        font-size: 16px;
      }
    }

    @include r(900) {
      display: none;
    }
  }

  &__description {
    margin-top: 20px;

    &-open {
      display: inline-block;
      color: #272727;
      font-family: Nunito;
      font-size: 15px;
      font-weight: 700;
      border-bottom: 1px solid #272727;
      margin-right: 20px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        border-bottom-color: transparent;
        transition: 0.3s;
      }
    }

    p {
      color: #535353;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      cursor: pointer;
      margin: 0;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      @include r(1700) {
        font-size: 18px;
        line-height: 24px;
      }

      @include r(1500) {
        font-size: 16px;
        line-height: 22px;
      }

      @include r(1300) {
        font-size: 15px;
        line-height: 20px;
      }

      @include r(900) {
        font-size: 13px;
        line-height: 17px;
      }
    }

    &-right {
      display: block;
      vertical-align: middle;
      text-align: right;

      margin: 20px 0 0 0;

      @include r(750) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 18px;
      }

      .btn-book-now {
        display: inline-block;
        min-width: 256px;
        width: auto;
        height: 44px;
        padding: 0;
        color: #fff;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
        border-radius: 5px;

        @include r(1500) {
          min-width: 200px;
          height: 38px;
          font-size: 17px;
        }

        @include r(1300) {
          min-width: 180px;
          height: 36px;
          font-size: 16px;
        }

        @include r(750) {
          margin-top: 18px;

          .v-btn__content {
            height: 36px;
          }
        }

        @include r(600) {
          min-width: 100%;
          height: 50px;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.34);
          border-radius: 0 0 4px 4px;
          background-color: #f25757;
          font-size: 15px;
          font-weight: 700;
          .v-btn__content {
            height: 50px;
          }
        }
      }
    }
  }

  &__rside {
    display: block;
    flex: 0 0 auto;
    min-width: 120px;
    max-width: 200px;

    @include r(600) {
      position: absolute;
      top: 21px;
      right: 0;
      min-width: 80px;
      max-width: 80px;
    }
  }

  &__price {
    text-align: center;

    strong {
      display: block;
      color: #383838;
      font-size: 47px;
      font-weight: 400;

      @include r(1700) {
        font-size: 40px;
      }

      @include r(1500) {
        font-size: 34px;
      }

      @include r(900) {
        font-size: 24px;
      }

      @include r(600) {
        font-size: 22px;
        text-align: right;
      }
    }

    p {
      display: block;
      color: #888888;
      font-size: 23px;
      font-weight: 400;
      margin: 0;

      @include r(1700) {
        font-size: 20px;
      }

      @include r(1500) {
        font-size: 18px;
      }
      @include r(900) {
        font-size: 16px;
      }
      @include r(600) {
        display: none;
      }
    }
  }

  &__info {
    display: block;
    margin-top: 50px;

    @include r(1700) {
      margin-top: 30px;
    }

    @include r(900) {
      margin-top: 20px;
    }
    @include r(600) {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
        margin-left: -7px;
      }

      .v-icon {
        color: #a3a3a3;

        @include r(1500) {
          font-size: 22px;
        }
      }

      span {
        color: #a3a3a3;
        font-size: 18px;
        font-weight: 400;
        padding-left: 8px;

        @include r(1500) {
          font-size: 16px;
        }
        @include r(900) {
          font-size: 15px;
        }
      }
    }
  }
}

.header.v-toolbar--fixed {
  z-index: 10 !important;
}

.profile-actions-fix {
  display: none;
  height: 53px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #f2efef;
  margin: 0 -20px;

  @include r(1000) {
    display: flex;
  }

  &__button {
    flex: 0 0 50%;
    outline: none;
    cursor: pointer;
    text-align: center;
    display: block;
    border-top: 1px solid #e2e2e2;

    span {
      color: #383838;
      font-family: Nunito;
      font-size: 15px;
      font-weight: 600;
      line-height: 12px;
      border-bottom: 1px solid transparent;
      transition: 0.3s;
    }

    &:hover {
      span {
        border-bottom-color: #383838;
        transition: 0.3s;
      }
    }
  }

  &__filter {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 60%;
      background-color: #e2e2e2;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }

    span::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      transform: rotate(90deg);
      background: {
        image: url("../.././images/icons/nav-next.svg");
        repeat: no-repeat;
        position: top left 11px;
        size: 9px;
      }
    }
  }

  &__sort {
    position: relative;

    span::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      transform: rotate(90deg);
      background: {
        image: url("../.././images/icons/nav-next.svg");
        repeat: no-repeat;
        position: top left 11px;
        size: 9px;
      }
    }
  }
}

.profile-sort-mob {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  background-color: #fff;
  padding: 25px 35px;
  max-height: 100%;
  overflow-y: auto;

  &::before {
    position: absolute;
    top: 25px;
    left: 35px;
    right: 35px;
    width: calc(100% - 70px);
    height: 1px;
    background-color: #e6e6e6;
    content: "";
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 43px;
    right: 40px;

    i {
      font-size: 28px;
    }
  }

  &__holder {
    display: block;
    text-align: center;
    padding-top: 70px;

    @include r(500) {
      text-align: left;
      padding-left: 15px;
    }
  }

  &__items {
    display: inline-block;
  }

  &__item {
    display: block;
    margin-top: 26px;

    p {
      color: #383838;
    }
  }

  &.is-open {
    display: block;
  }
}

.v-menu__content {
  .v-select-list {
    border: 1px solid #b2b2b2;
    border-radius: 5px;
    overflow: hidden;
  }
}

.hide {
  display: none !important;
}

.popover-postcode {
  z-index: 11;
  top: -20px !important;

  @include r(1000) {
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: 30% !important;
    width: 300px !important;
  }

  .popover-arrow {
    left: 50% !important;
    transform: translateX(-50%);
    @include r(1000) {
      display: none !important;
    }
  }
}
</style>
