import store from '@/store/index'
import {
  empty
} from '@/helpers.js';
import axios from "axios";
import * as CONFIG from "@/config.js";
import repository from '@/repository/repository.js';

import i18n from '../bootstrap/initI18Next';

/* eslint-disable */

export const checkUserSession = ({to, from}) => {
  store.dispatch('REFRESH_OR_RESET_AUTH_VARIABLES');
  store.dispatch('REFRESH_SEARCH_PARAMS');
  return Promise.resolve({to, from});
};

export const isNeedingRedirectAfterAuth = ({to, from}) => {
  if ((store.getters.needAuthorization === true || store.getters.modalAuthorization === true) || store.getters.modalCustomerReg === true) {
    store.commit('TOGGLE_NEED_AUTHORIZATION', false);
    store.commit('TOGGLE_MODAL_AUTHORIZATION', false);
    store.commit('TOGGLE_MODAL_CUSTOMER_REG', false);
  } else {
    store.commit('TOGGLE_NEED_AUTHORIZATION', false);
    store.commit('TOGGLE_MODAL_AUTHORIZATION', false);
    store.commit('TOGGLE_MODAL_CUSTOMER_REG', false);
    store.commit('SET_TARGET_PAGE', '');
    store.commit('SET_TARGET_PARAMS', {});
  }

  return Promise.resolve({to, from});
};

export const setUserForFullStory = ({to, from}) => {
  if (store.getters.isUserLoggedIn === true) {
    try {
      const currentUser = store.getters.currentUser;
      window.FS.identify(currentUser.user_id, {
        displayName: currentUser.name,
        email: currentUser.email
      });
    } catch (error) {
      console.log(error);
    }
  }

  return Promise.resolve({to, from});
};

export const authenticate = ({to, from}) => {
  return new Promise((resolve, reject) => {
    if (store.getters.isUserLoggedIn === true) {
      resolve({to, from});
    } else {
      store.commit('TOGGLE_NEED_AUTHORIZATION', true);
      store.commit('SET_TARGET_PAGE', to.name);
      reject('/login');
    }
  });
};

export const  modalAuthenticate = ({to, from}) => {
  return new Promise((resolve, reject) => {
    if (store.getters.isUserLoggedIn === true) {
      resolve({to, from});
    } else {
      store.commit('TOGGLE_MODAL_AUTHORIZATION', true);
      store.commit('SET_TARGET_PAGE', to.name);
      store.commit('SET_TARGET_PARAMS', to.params);
      if(from.name === null && from.path === '/'){
        reject('/login');
      }else {
        reject(from.fullPath);
      }
    }
  });
};

export const modalCustomerRegistration = ({to, from}) => {
  return new Promise((resolve, reject) => {
    if (store.getters.isUserLoggedIn === true) {
      resolve({to, from});
    } else {
      store.commit('TOGGLE_MODAL_CUSTOMER_REG', true);
      store.commit('SET_TARGET_PAGE', to.name);
      store.commit('SET_TARGET_PARAMS', to.params);
      if (from.name === null && from.path === '/') {
        reject('/customer-registration');
      } else {
        reject(from.fullPath);
      }
    }
  });
};

export const onlyForAdmin = ({to, from}) =>  {
  return new Promise((resolve, reject) => {
    if(store.getters.currentUser.user_roles.includes('admin')){
      resolve({to, from});
    }else{
      store.commit('SET_NOTIFICATION', {
        text: i18n.t('middleware_notification.available_only_for_admins'),
        color: 'orange'
      });
      reject(from.fullPath);
    }
  });
};

export const onlyForCustomer = ({to, from}) =>  {
  return new Promise((resolve, reject) => {
    if(store.getters.currentUser.user_roles.includes('customer')){
      resolve({to, from});
    }else{
      store.commit('SET_NOTIFICATION', {
        text: i18n.t('middleware_notification.available_only_for_customers'),
        color: 'orange'
      });
      reject(from.fullPath);
    }
  });
};

export const onlyForPractitioner = ({to, from}) => {
  return new Promise((resolve, reject) => {
    if(store.getters.currentUser.user_roles.includes('practitioner')){
      resolve({to, from});
    }else{
      store.commit('SET_NOTIFICATION', {
        text: i18n.t('middleware_notification.available_only_for_practitioners'),
        color: 'orange'
      });
      reject(from.fullPath);
    }
  });
};

export const validateBookingParams = ({to, from}) => {
  return new Promise((resolve, reject) => {
    if (typeof to.params.booking === 'undefined' || empty(to.params.booking)) {
      reject(from.fullPath);
    } else {
      resolve({to, from});
    }
  });
};

export const checkSearchParams = ({to, from}) => {
  return new Promise((resolve, reject) => {
    if(to.name === 'practitioner-search'){
      if(empty(to.query.address) || empty(to.query.lat) || empty(to.query.lon) || empty(to.query.type)){
        reject({path: '/services-search', query: to.query})
      }
      if(empty(to.query.location)){
        reject({path: '/select-location', query: to.query})
      }
    }
    resolve({to, from});
  });
};

export const checkBookingStatus = ({to, from}) => {

  return new Promise((resolve, reject) => {

    store.dispatch('GET_BOOKING', to.params.booking_id)
      .then((response) => {
        if (response.paid === true) {
          store.commit('SET_NOTIFICATION', {
            text: i18n.t('middleware_notification.book_has_already_been_paid'),
            color: 'orange'
          });

          if (from.name === null && from.path === '/') {
            reject('/');
          } else {
            reject(from.fullPath);
          }
        } else if (response.booking_status === 'confirmed') {
          resolve({to, from});
        } else {
          store.commit('SET_NOTIFICATION', {
            text: i18n.t('middleware_notification.booking_status_does_not_allow_payment'),
            color: 'orange'
          });

          if (from.name === null && from.path === '/') {
            reject('/');
          } else {
            reject(from.fullPath);
          }
        }
      })
      .catch((error) => {
        if (!empty(error.response) && error.response.status === 404) {
          store.commit('SET_NOTIFICATION', {
            text: i18n.t('middleware_notification.booking_was_automatically_deleted'),
            color: 'orange'
          });
        } else if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
          store.commit('SET_NOTIFICATION', {
            text: error.response.data.message,
            color: 'orange'
          });
        }
        if (from.name === null && from.path === '/') {
          reject('/');
        } else {
          reject(from.fullPath);
        }
        console.error(error);
      });
  });
};

export const checkRegistrationSteps = ({to, from}) => {
  return new Promise((resolve, reject) => {
    const currentUser = store.getters.currentUser;
    if (!empty(currentUser.practitioner_info.onboarding_step) && currentUser.practitioner_info.onboarding_step < 5) {
      axios({
        method: 'get',
        url: `${CONFIG.API_URL}/practitioner/onbording`,
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
      })
        .then((response) => {
          store.commit('SET_NOTIFICATION', {
            text: i18n.t('middleware_notification.please_complete_registration'),
            color: 'error'
          });
          reject(`/practitioner-information/${response.data.onboarding_step + 1}`)
        })
        .catch((error) => {
          if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
            store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'error'
            });
          }
          if (from.name === null && from.path === '/') {
            reject('/');
          } else {
            reject(from.fullPath);
          }
          console.error(error);
        });
    } else {
      resolve({to, from});
    }
  });
};

export const checkStepOnRegistrationFlow = ({to, from}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${CONFIG.API_URL}/practitioner/onbording`,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((response) => {
        if (!empty(response.data.onboarding_step) && response.data.onboarding_step !== (to.params.currentStep - 1)) {
          reject(`/practitioner-information/${response.data.onboarding_step + 1}`)
        } else {
          resolve({to, from})
        }
      })
      .catch((error) => {
        if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
          store.commit('SET_NOTIFICATION', {
            text: error.response.data.message,
            color: 'error'
          });
        }
        if (from.name === null && from.path === '/') {
          reject('/');
        } else {
          reject(from.fullPath);
        }
        console.error(error);
      });
  });
};

export const checkCookiesBar = ({to, from}) => {
  let isClickPanel = repository.getValue('is-click-panel');
  let isShowPanel = repository.getValue('is-show-panel');
  if (empty(isShowPanel) && empty(isClickPanel)) {
    store.commit('SET_IS_NEED_SHOW_COOKIE', true);
    store.commit('SET_SHOW_COOKIE_BAR');
  }else {
    store.commit('SET_IS_NEED_SHOW_COOKIE', false);
  }

  return Promise.resolve({to, from});
};

export const checkBookingStatusBeforeReview = ({to, from}) => {

  let backUrl = '';
  if (from.name === null && from.path === '/') {
    backUrl = '/';
  } else {
    backUrl = from.fullPath;
  }

  return new Promise((resolve, reject) => {
    if (!empty(to.params) && !empty(to.params.booking_id)) {
      store.dispatch('GET_BOOKING', to.params.booking_id)
        .then((booking) => {
          if (booking.booking_status === 'completed' && booking.review === true) {
            resolve({to, from});
          } else if (booking.review === false) {
            store.commit('SET_NOTIFICATION', {
              text: i18n.t('middleware_notification.you_already_made_a_review'),
              color: 'orange'
            });
            reject(backUrl);
          } else if (booking.booking_status !== 'completed') {
            store.commit('SET_NOTIFICATION', {
              text: i18n.t('middleware_notification.status_does_not_allow_make_a_review'),
              color: 'orange'
            });
            reject(backUrl);
          }
        })
        .catch((error) => {
          if (!empty(error.response) && error.response.status === 404) {
            store.commit('SET_NOTIFICATION', {
              text: i18n.t('middleware_notification.booking_not_found'),
              color: 'orange'
            });
          }
          reject(backUrl);
        })
    } else {
      store.commit('SET_NOTIFICATION', {
        text: i18n.t('middleware_notification.you_link_is_broken'),
        color: 'orange'
      });
      reject(backUrl);
    }
  });
};

export const isValidToken = ({to, from}) => {

  let backUrl = '/';
  const token = to.params.token;

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${CONFIG.API_URL}/dashboard/user/inviteReview/${token}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((response) => {
        if (response.data.review === true) {
          resolve({to, from});
        } else {
          reject(backUrl);
          store.commit('SET_NOTIFICATION', {
            text: i18n.t('middleware_notification.you_already_made_review_by_this_link'),
            color: 'orange'
          });
        }
      })
      .catch((error) => {
        if (!empty(error.response) && error.response.status === 400) {
          store.commit('SET_NOTIFICATION', {
            text: i18n.t('middleware_notification.invalid_token'),
            color: 'orange'
          });
        }
        reject(backUrl);
      });
  });
};