<template>
  <div>
    <div class="select-services">
      <div class="select-service select-service--lg select-service-mobile">
        <strong class="select-service__title">
          {{$t('select_location.my_home')}}
        </strong>
        <p class="select-service__description">
          {{$t('select_location.my_home_desc')}}
        </p>
        <img
          src="../.././images/home/search-mobile-2020.jpg"
          class="select-service__photo"
          alt=""
        >
        <v-btn
          color="error"
          class="select-service__btn"
          @click="runSearch('mobile')"
        >
          {{$t('select_location.my_home_button')}}
        </v-btn>
      </div>
      <div class="select-service select-service--lg select-service--salon">
        <strong class="select-service__title">
          {{$t('select_location.practitioner_home_salon')}}
        </strong>
        <p class="select-service__description">
          {{$t('select_location.practitioner_home_salon_desc')}}
        </p>
        <img
          src="../.././images/home/search-salon-2020.jpg"
          class="select-service__photo"
          alt=""
        >
        <v-btn
          color="error"
          class="select-service__btn"
          @click="runSearch('salon')"
        >
          {{$t('select_location.practitioner_home_salon_button')}}
        </v-btn>
      </div>
      <div class="select-service select-service--all">
        <strong>
          {{$t('select_location.view_all_title')}}
        </strong>
        <p>
          {{$t('select_location.view_all_desc')}}
        </p>
        <v-btn
          color="error"
          class="select-service__btn"
          @click="runSearch('idm')"
        >
          {{$t('select_location.view_all_button')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { empty } from '@/helpers.js';

export default {
  name: "select-location",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Select Locations'
  },
  data () {
    return {
      selectedLocationType: '',

      searchTown: '',
      searchLatitude: '',
      searchLongitude: '',
      selectedSearchAddress: '',
      selectedSubServiceId: '',
      selectedSubServiceName: '',
      selectedType: ''
    }
  },
  mounted () {
    this.$scrollTo('.header.v-toolbar');
  },
  created: function () {
    if (Object.keys(this.$route.query).length !== 0) {
      this.selectedSearchAddress = (!empty(this.$route.query.address)) ? this.$route.query.address : '';
      this.searchTown = (!empty(this.$route.query.town)) ? this.$route.query.town : '';
      this.searchLatitude = (!empty(this.$route.query.lat)) ? this.$route.query.lat : '';
      this.searchLongitude = (!empty(this.$route.query.lon)) ? this.$route.query.lon : '';
      // this.selectedSubServiceId = (!empty(this.$route.query.id)) ? parseInt(this.$route.query.id) : '';
      // this.selectedSubServiceName = (!empty(this.$route.query.service)) ? this.$route.query.service : '';
      this.selectedType = (!empty(this.$route.query.type)) ? this.$route.query.type : '';
    }
  },
  methods: {
    getQueryObject () {
      const query = {};
      if (!empty(this.selectedSearchAddress)) {
        query['address'] = this.selectedSearchAddress;
      }
      if (!empty(this.searchTown)) {
        query['town'] = this.searchTown;
      }
      if (!empty(this.searchLatitude)) {
        query['lat'] = this.searchLatitude;
      }
      if (!empty(this.searchLongitude)) {
        query['lon'] = this.searchLongitude;
      }
      // if (!empty(this.selectedSubServiceId)) {
      //   query['id'] = this.selectedSubServiceId;
      // }
      // if (!empty(this.selectedSubServiceName)) {
      //   query['service'] = this.selectedSubServiceName;
      // }
      if(!empty(this.selectedType)){
        query['type'] = this.selectedType;
      }
      if (!empty(this.selectedLocationType)) {
        query['location'] = this.selectedLocationType;
      }
      return query;
    },
    runSearch (location_type) {
      this.selectedLocationType = location_type;
      const query = this.getQueryObject();
      this.$router.push({ path: '/practitioner-search', query: query });
    }
  }
}
</script>


<style scoped lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.card {
  cursor: pointer;
}

.cards-container {
  .v-card__title {
    justify-content: center;
    padding: 24px 16px 30px 16px;
  }
}

.search-container {
  width: 720px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px !important;
  background-image: linear-gradient(to right, #fff 0%, #fdfdfd 100%);
  display: block;
  margin: 46px auto;

  @include r(1500) {
    max-width: 600px;
    width: 100%;
  }

  &__middle {
    max-width: 380px;
    margin: 0 auto;

    .v-form {
      margin-top: 0;
    }
  }
}

.select-services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 60px 220px 70px 220px;

  @include r(1700) {
    justify-content: center;
  }

  @include r(1500) {
    padding: 50px 40px 60px 40px;
  }

  @include r(1140) {
    padding: 50px 24px 60px 24px;
  }
  @include r(750) {
    padding: 10px 24px 40px 24px;
  }
  @include r(420) {
    padding: 10px 4px 40px 4px;
  }

  .select-service {
    &--lg {
      flex: 0 0 630px;
      padding: 0 20px 20px 20px;
      text-align: center;

      @include r(1800) {
        flex: 0 0 600px;
      }

      @include r(1700) {
        flex: 0 0 500px;
      }
      @include r(1140) {
        flex: 0 0 50%;
      }
      @include r(750) {
        flex: 0 0 100%;
        padding-top: 20px;
      }

      p {
        margin-top: 10px;
      }

      .select-service {
        &__btn {
          max-width: 450px;
          width: 100%;
          margin: 70px auto 0 auto;
          font-size: 23px;
          height: 75px;

          @include r(1700) {
            max-width: 400px;
            margin-top: 50px;
            height: 60px;
            font-size: 20px;
          }

          @include r(1500) {
            max-width: 360px;
            margin-top: 40px;
            height: 58px;
          }
          @include r(1140) {
            max-width: 300px;
            margin-top: 30px;
            height: 50px;
            font-size: 18px;
          }
          @include r(900) {
            max-width: 265px;
            height: 50px;
            font-size: 15px;
            border-radius: 4px;
          }
        }
      }
    }

    &--all {
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;

      @include r(1700) {
        margin-top: 40px;
      }
      @include r(1140) {
        padding: 0 24px;
      }
      @include r(900) {
        display: block;
        text-align: center;
        margin-top: 20px;
      }
      strong {
        flex: 0 0 auto;
      }

      p {
        padding: 0 30px;
        @include r(900) {
          padding: 14px 0 24px 0;
        }
      }

      .select-service {
        &__btn {
          flex: 0 0 auto;
          max-width: 156px;
          min-width: 156px;
          height: 47px;
          border-radius: 10px;
          font-size: 20px;

          @include r(1500) {
            font-size: 18px;
          }
          @include r(1140) {
            max-width: 140px;
            min-width: 140px;
            height: 42px;
            font-size: 16px;
          }
          @include r(900) {
            display: block;
            margin: 0 auto;
            max-width: 265px;
            min-width: 265px;
            height: 50px;
            font-size: 15px;
            border-radius: 4px;
          }
        }
      }
    }

    &__photo {
      display: block;
      width: 100%;
      margin-top: 40px;

      @include r(750) {
        max-width: 560px;
        margin: 30px auto 0 auto;
      }
    }

    &__btn {
      display: block;
      border-radius: 10px;
      font-weight: 700;
      margin: 0;
    }

    strong {
      display: block;
      color: #383838;
      font-size: 27px;
      font-weight: 700;

      @include r(1700) {
        font-size: 26px;
      }

      @include r(1500) {
        font-size: 24px;
      }

      @include r(1400) {
        font-size: 22px;
      }
      @include r(700) {
        font-size: 20px;
      }
    }

    p {
      display: block;
      margin-bottom: 0;
      color: #535353;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;

      @include r(1500) {
        font-size: 18px;
      }
      @include r(1400) {
        font-size: 17px;
      }
      @include r(700) {
        font-size: 15px;
      }
    }
  }
}
</style>
