<template>
  <div>
    <div class="availability">
      <p class="title text-xs-center text-sm-center text-md-center">
        {{$t('practitioner_registration.step_4_title')}}
      </p>

      <p class="main-text">
        {{$t('practitioner_registration.step_4_desc')}}
      </p>

      <div class="availability__weeks">
        <div
          v-if="locations.mobile"
          class="availability__week"
        >
          <p class="availability__title">
            {{$t('practitioner_registration.step_4_mobile')}}
          </p>
          <ul class="availability__days">
            <template v-for="(availabilityByDay, dayOfWeek, day_index) in availability.mobile.schedule">
              <AvailabilitySlots
                :availabilityByDay="availabilityByDay"
                :dayOfWeek="dayOfWeek"
                :key="day_index"
                :isTooltipBreakButtonProp="true"
                :defaultTimeFrom="defaultTimeFrom"
                :defaultTimeTo="defaultTimeTo"
                :defaultBreaks="defaultBreaks"
                @breaksUpdate="breaksUpdate"
                @timeFrom="timeFrom"
                @timeTo="timeTo"
              ></AvailabilitySlots>
            </template>
          </ul>
        </div>
        <template v-if="locations.salon">
          <div
            v-for="(salon, salon_index) in availability.salon"
            class="availability__week"
            :key="salon_index"
          >
            <p class="availability__title">
              {{$t('practitioner_registration.step_4_salon')}}
            </p>
            <ul class="availability__days">
              <template v-for="(availabilityByDay, dayOfWeek, day_index) in salon.schedule">
                <AvailabilitySlots
                  :availabilityByDay="availabilityByDay"
                  :dayOfWeek="dayOfWeek"
                  :key="`${salon_index}-${day_index}`"
                  :isTooltipBreakButtonProp="true"
                  :defaultTimeFrom="defaultTimeFrom"
                  :defaultTimeTo="defaultTimeTo"
                  :defaultBreaks="defaultBreaks"
                  @breaksUpdate="breaksUpdate"
                  @timeFrom="timeFrom"
                  @timeTo="timeTo"
                ></AvailabilitySlots>
              </template>
            </ul>
          </div>
        </template>
      </div>

      <div class="text-xs-center step-btn-main">
        <v-btn
          color="error"
          :disabled="!isAvailabilitySelected || beingProcessed"
          @click="sendAvailabilityInformation()"
        >
          {{$t('practitioner_registration.next')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as CONFIG from '@/config.js'
import { empty } from '@/helpers.js';

import AvailabilitySlots from '@/components/PractitionerInformation/AvailabilitySlots';

/* eslint-disable */

export default {
  props: ['locations', 'availabilityByLocations'],
  components: {
    AvailabilitySlots
  },
  data: function () {
    return {
      availability: this.availabilityByLocations,

      defaultTimeFrom: '09:00',
      defaultTimeTo: '18:00',
      defaultBreaks: [],
      beingProcessed: false
    }
  },
  mounted () {
    this.initAvailabilityComponent();
  },
  watch: {
    '$route' (to, from) {
      console.log(to);
      if (to.params.currentStep === "4") {
        this.initAvailabilityComponent();
      }
    }
  },
  computed: {
    filteredAvailability: function () {
      return this.filterAvailabilityData(JSON.stringify(this.availability))
    },
    isAvailabilitySelected: function () {
      let selected = [];
      if (this.locations.mobile === true) {
        for (let key in this.availability.mobile.schedule) {
          if (this.availability.mobile.schedule[key].show === true) {
            selected.push(1);
            break;
          }
        }
      }

      if (this.locations.salon === true) {
        for (let salon in this.availability.salon) {
          for (let day in this.availability.salon[salon].schedule) {
            if (this.availability.salon[salon].schedule[day].show === true) {
              selected.push(1);
              break;
            }
          }
        }
      }

      if (this.locations.salon === true && this.locations.mobile === true && selected.length === (this.availability.salon.length + 1)) {
        return true;
      } else if (this.locations.salon === true && this.locations.mobile === false && selected.length === this.availability.salon.length) {
        return true;
      } else if (this.locations.salon === false && this.locations.mobile === true && selected.length === 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    initAvailabilityComponent () {
      axios({
        method: "get",
        url: `${CONFIG.API_URL}/dashboard/practitioner/locationtypes`,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        }
      })
        .then((response) => {
          if (!empty(response.data.mobile)) {
            this.locations.mobile = true;
          }
          if (!empty(response.data.salon)) {
            this.locations.salon = true;
          }
          if (!empty(response.data.salon)) {
            this.getUserSalons()
              .then(response => {
                this.setAvailability(response);
              })
              .catch(error => {
                console.log(error.response);
                throw error;
              })
          } else {
            this.setAvailability();
          }
        });
    },
    setAvailability (response = {}) {
      let salonsAvailability = [];
      let mobileAvailability = {};

      if (this.locations.salon === true && !empty(response)) {
        let salons = this.getSalonsData(response.data.salons);
        for (let index in salons) {
          salonsAvailability.push({
            'data': {
              'id': salons[index].id,
              'name': (!empty(salons[index].name)) ? salons[index].name : salons[index].address
            },
            'schedule': {
              "Sunday": {
                "show": false,
                "error": {"from": false, "to": false},
                "available": {
                  "from": "09:00",
                  "to": "18:00"
                },
                "break": []
              },
              "Monday": {
                "show": false,
                "error": {"from": false, "to": false},
                "available": {
                  "from": "09:00",
                  "to": "18:00"
                },
                "break": []
              },
              "Tuesday": {
                "show": false,
                "error": {"from": false, "to": false},
                "available": {
                  "from": "09:00",
                  "to": "18:00"
                },
                "break": []
              },
              "Wednesday": {
                "show": false,
                "error": {"from": false, "to": false},
                "available": {
                  "from": "09:00",
                  "to": "18:00"
                },
                "break": []
              },
              "Thursday": {
                "show": false,
                "error": {"from": false, "to": false},
                "available": {
                  "from": "09:00",
                  "to": "18:00"
                },
                "break": []
              },
              "Friday": {
                "show": false,
                "error": {"from": false, "to": false},
                "available": {
                  "from": "09:00",
                  "to": "18:00"
                },
                "break": []
              },
              "Saturday": {
                "show": false,
                "error": {"from": false, "to": false},
                "available": {
                  "from": "09:00",
                  "to": "18:00"
                },
                "break": []
              }
            }
          });
        }
      }

      if (this.locations.mobile === true) {
        mobileAvailability = {
          'data': {
            'id': '',
            'name': ''
          },
          'schedule': {
            "Sunday": {
              "show": false,
              "error": {"from": false, "to": false},
              "available": {
                "from": "09:00",
                "to": "18:00"
              },
              "break": []
            },
            "Monday": {
              "show": false,
              "error": {"from": false, "to": false},
              "available": {
                "from": "09:00",
                "to": "18:00"
              },
              "break": []
            },
            "Tuesday": {
              "show": false,
              "error": {"from": false, "to": false},
              "available": {
                "from": "09:00",
                "to": "18:00"
              },
              "break": []
            },
            "Wednesday": {
              "show": false,
              "error": {"from": false, "to": false},
              "available": {
                "from": "09:00",
                "to": "18:00"
              },
              "break": []
            },
            "Thursday": {
              "show": false,
              "error": {"from": false, "to": false},
              "available": {
                "from": "09:00",
                "to": "18:00"
              },
              "break": []
            },
            "Friday": {
              "show": false,
              "error": {"from": false, "to": false},
              "available": {
                "from": "09:00",
                "to": "18:00"
              },
              "break": []
            },
            "Saturday": {
              "show": false,
              "error": {"from": false, "to": false},
              "available": {
                "from": "09:00",
                "to": "18:00"
              },
              "break": []
            }
          }
        };
      }

      this.availability['mobile'] = mobileAvailability;
      this.availability['salon'] = salonsAvailability;
      this.updateAvailability(this.availability);
    },
    updateAvailability (availability) {
      this.$emit("updateAvailability", availability);
    },
    getUserSalons () {
      return axios({
        method: "get",
        url: `${CONFIG.API_URL}/practitioner/salons`,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        }
      })
    },
    getSalonsData (salons) {
      let data = [];
      if (!empty(salons)) {
        for (let index in salons) {
          data[index] = {
            "id": salons[index].id,
            "name": salons[index].name,
            "postcode": salons[index].postcode,
            "address": salons[index].address
          }
        }
      }
      return data;
    },
    filterAvailabilityData (data) {
      let json_data = JSON.parse(data);

      let filtered_data = {};
      for (let service in json_data) {
        if (service === 'mobile' && this.locations.mobile === true) {
          filtered_data[1] = {};
          filtered_data[1] = this.getFilteredData(json_data[service]['schedule']);
        }
        if (service === 'salon' && this.locations.salon === true) {
          filtered_data[2] = {};
          for (let id in json_data[service]) {
            filtered_data[2][json_data[service][id].data.id] = this.getFilteredData(json_data[service][id]['schedule']);
          }
        }
      }

      return filtered_data;
    },
    getFilteredData (data) {
      let filtered_data = {};
      for (let day in data) {
        if (data[day]['show']) {
          filtered_data[day.toLowerCase()] = { 'available': data[day]['available'] };
          if (data[day]['break'].length > 0) {
            filtered_data[day.toLowerCase()] = {
              'available': data[day]['available'],
              'break': data[day]['break']
            }
          } else {
            filtered_data[day.toLowerCase()] = {
              'available': data[day]['available'],
              'break': {}
            }
          }
        } else {
          filtered_data[day.toLowerCase()] = {};
        }
      }

      return filtered_data;
    },
    changeStep (step) {
      this.$emit('changeStep', step)
    },
    sendAvailabilityInformation () {
      this.beingProcessed = true;
      axios({
        method: "post",
        url: `${CONFIG.API_URL}/practitioner/availability`,
        data: JSON.stringify(this.filteredAvailability),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `Bearer ${this.$store.getters.userToken || CONFIG.DEV_USER_TOKEN}`
        }
      })
        .then(
          response => {
            console.log(response);
            this.beingProcessed = false;
            this.changeStep(5);
          })
        .catch(error => {
          this.beingProcessed = false;
          console.log(error.response.data);
          throw error;
        });
    },

    timeFrom (time) {
      this.defaultTimeFrom = time;
    },
    timeTo (time) {
      this.defaultTimeTo = time;
    },
    breaksUpdate (breaks) {
      this.defaultBreaks = breaks;
    },
  }
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.availability {
  max-width: 500px;
  margin: 0 auto;

  &__week {
    display: block;
    padding: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    @include r(700) {
      padding: 30px 0 0 0;
    }

    &:first-child {
      padding-top: 30px;
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__days {
    display: block;
    padding: 0;
  }

  &__switch {
    .v-input--selection-controls {
      margin: 0;
      padding: 0;
    }
    .v-input__control {
      flex: 0 0 100%;
    }
    .v-input__slot {
      flex: 0 0 100%;
      justify-content: space-between;
      .v-label {
        font-size: 17px;
        font-weight: 600;
      }
    }
  }

  &__title {
    display: block;
    text-align: center;
    color: #383838;
    font-family: Nunito;
    font-size: 27px;
    font-weight: 700;

    @include r(1500) {
      font-size: 23px;
    }
    @include r(1000) {
      font-size: 20px;
    }
    @include r(700) {
      font-size: 17px;
    }
  }

  &__switch {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .v-swith-main__label {
      font-weight: 600;
      color: #707070;
      font-size: 18px;

      @include r(700) {
        font-size: 16px;
      }
    }
  }

  &__day {
    display: block;
    padding: 20px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.5);

    @include r(1500) {
      padding: 18px 0;
    }

    @include r(700) {
      padding: 16px 0;
    }

    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    }
  }

  &__time {
    margin-top: 20px;

    &-btn {
      flex: 0 0 100%;
      margin-top: 10px;
      text-align: center;

      a {
        color: #7b8994;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-inners {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 0px;

      &:last-child {
        margin-bottom: 0;
      }

      &.availability-break {
        position: relative;

        .availability__time-btn {
          position: absolute;
          right: -25px;
          top: 1px;
          z-index: 2;

          button {
            min-width: auto !important;
          }
        }
      }
    }
    &-inner {
      flex: 0 0 50%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.4);
        padding-left: 0;
      }

      &:nth-child(2) {
        justify-content: flex-start;
      }
    }
    &-title {
      font-size: 17px;
      font-weight: 400;
      color: #7b8994;
      margin-right: 12px;

      @include r(700) {
        font-size: 15px;
      }
    }
    &-am {
      font-size: 17px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);

      @include r(700) {
        font-size: 15px;
      }
    }
  }
}

.v-stepper {
  overflow: inherit;
}

.v-stepper__wrapper {
  overflow: inherit;
}

.v-stepper__items {
  overflow: inherit;
}
</style>