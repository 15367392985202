<template>

  <div class="home" data-view>
    <div class="home-hero home-hero--main">
      <div class="home__wrapper">
        <div class="home-hero__content">
          <div class="home-hero__description">
            <div class="home-hero__title">
              {{$t('home.home_main_title')}}
            </div>
            <div class="home-hero__form">
              <ServicesSearchForm></ServicesSearchForm>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-services">
      <div class="home__wrapper">
        <div class="home-services__content">
          <div class="home__title">
            {{ $t('home.choose_from_huge_range_of_services') }}
          </div>
          <div class="home-services__items">
            <div class="home-services__item" @click="onServices()">
              <div class="home-services__inner">
                <img
                    src="../../src/images/home/icon-make-up.svg"
                    alt=""
                >
              </div>
              <span>{{ $t('home.beauty') }}</span>
            </div>
            <div class="home-services__item" @click="onServices()">
              <div class="home-services__inner">
                <img
                    src="../.././src/images/home/icon-facial.svg"
                    alt=""
                >
              </div>
              <span>{{ $t('home.facial') }}</span>
            </div>
            <div class="home-services__item" @click="onServices()">
              <div class="home-services__inner">
                <img
                    src="../.././src/images/home/icon-hair.svg"
                    alt=""
                >
              </div>
              <span>{{ $t('home.hair') }}</span>
            </div>
            <div class="home-services__item" @click="onServices()">
              <div class="home-services__inner">
                <img
                    src="../.././src/images/home/icon-nails.svg"
                    alt=""
                >
              </div>
              <span>{{ $t('home.nails') }}</span>
            </div>
            <div class="home-services__item" @click="onServices()">
              <div class="home-services__inner">
                <img
                    src="../.././src/images/home/icon-eyes.svg"
                    alt=""
                >
              </div>
              <span>{{ $t('home.eyes') }}</span>
            </div>
            <div class="home-services__item" @click="onServices()">
              <div class="home-services__inner">
                <img
                    src="../.././src/images/home/icon-massage.svg"
                    alt=""
                >
              </div>
              <span>{{ $t('home.massage') }}</span>
            </div>
          </div>
          <div class="home-services__action">
            <v-btn
                class="error"
                @click="onServices()"
            >
              {{ $t('home.view_full_range_of_service') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="home-how-it-works">
      <div class="home__wrapper">
        <div class="home-how-it-works__content">
          <div class="home-how-it-works__lside">
            <div class="home__title">
              {{ $t('home.how_it_works') }}
            </div>
            <img src="../../src/images/home/photo-hit-2020.jpg"
                 class="home-how-it-works__image home-how-it-works__image--mob" alt="">
            <div class="home-how-it-works__items">
              <div class="home-how-it-works__item">
                <div class="home-how-it-works__number">1</div>
                <div class="home-how-it-works__description">
                  {{ $t('home.enter_location_and_treatment') }}
                </div>
              </div>
              <div class="home-how-it-works__item">
                <div class="home-how-it-works__number">2</div>
                <div class="home-how-it-works__description">
                  {{ $t('home.select_the_location_of_the_treatment') }}
                </div>
              </div>
              <div class="home-how-it-works__item">
                <div class="home-how-it-works__number">3</div>
                <div class="home-how-it-works__description">
                  {{ $t('home.browse_through_a_list_of_vetted') }}
                </div>
              </div>
              <div class="home-how-it-works__item">
                <div class="home-how-it-works__number">4</div>
                <div class="home-how-it-works__description">
                  {{ $t('home.choose_and_pay_for_a_treatment') }}
                </div>
              </div>
            </div>
            <div class="home-how-it-works__action">
              <v-btn

                  @click="onServicesSearch"
                  class="error"
              >
                {{ $t('home.get_started') }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="home-how-it-works__image home-how-it-works__image--main home-how-it-works__image--desk"></div>
    </div>

    <div class="home-advantages">
      <div class="home__wrapper">
        <div class="home-advantages__content">
          <div class="home__title">{{ $t('home.verified_qualified_reviewed') }}</div>
          <div class="home-advantages__items">
            <div class="home-advantages__item">
              <div class="home-advantages__inner">
                <img
                    src="../.././src/images/home/home-icon-1.svg"
                    alt=""
                >
              </div>
              <p>
                {{ $t('home.verified_qualified_reviewed_1') }}
              </p>
            </div>
            <div class="home-advantages__item">
              <div class="home-advantages__inner">
                <img
                    src="../.././src/images/home/home-icon-2.svg"
                    alt=""
                >
              </div>
              <p>
                {{ $t('home.verified_qualified_reviewed_2') }}
              </p>
            </div>
            <div class="home-advantages__item">
              <div class="home-advantages__inner">
                <img
                    src="../.././src/images/home/home-icon-3.svg"
                    alt=""
                >
              </div>
              <p>
                {{ $t('home.verified_qualified_reviewed_3') }}
              </p>
            </div>
          </div>
          <div class="home-advantages__action">
            <v-btn

                @click="onSupport()"
                class="error"
            >
              {{ $t('home.trust_safety') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="home-choice">
      <div class="home__title">
        {{ $t('home.in_location_that_suits_you') }}
      </div>
      <div class="home-choice__inners">
        <div class="home-choice__inner">
          <div class="home-choice__inner-description">
            <strong>
              {{ $t('home.comfort_of_your_own_home') }}
            </strong>
            <p>
              {{ $t('home.hectic_schedule_youll_benefit_from_the_ease') }}
            </p>
          </div>
          <div class="home-choice__inner-photo">
            <img
                src="../.././src/images/home/search-mobile-2020.jpg"
                alt=""
            >
          </div>
          <div class="home-choice__inner-action">
            <v-btn
                class="error"
                @click="onSearch()"
            >{{ $t('home.search_mobile_practitioners') }}
            </v-btn>
          </div>
        </div>
        <div class="home-choice__inner">
          <div class="home-choice__inner-description">
            <strong>
              {{ $t('home.the_salon_experience') }}
            </strong>
            <p>
              {{ $t('home.need_to_escape_еake_treatment') }}
            </p>
          </div>
          <div class="home-choice__inner-photo">
            <img
                src="../.././src/images/home/search-salon-2020.jpg"
                alt=""
            >
          </div>
          <div class="home-choice__inner-action">
            <v-btn
                class="error"
                @click="onSearch()"
            >
              {{ $t('home.search_local_salons') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="home-feedback">
      <div class="home__wrapper">
        <div class="home-feedback__content">
          <div class="home__title">
            {{ $t('home.home_feedback') }}
          </div>
          <div class="home-feedback__holder">
            <div class="home-feedback__lside">
              <div class="home-feedback__info">
                <div class="home-feedback__info-photo">
                  <img
                      src="../.././src/images/home/photo-customer-2020.png"
                      alt=""
                  >
                </div>
                <div class="home-feedback__info-rside" v-html="$t('home.home_feedback_practitioner')">
                </div>
              </div>
            </div>
            <div class="home-feedback__rside">
              <p>
                {{ $t('home.home_feedback_description') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-freelance">
      <div class="home__wrapper">
        <div class="home-freelance__content">
          <div class="home-freelance__title">
            <strong>
              {{ $t('home.are_you_freelance_therapist') }}
            </strong>
            <p v-html="$t('home.become_freely_practitioner_today')"></p>
          </div>
          <v-btn
              @click="onLearnMore()"
              class="error home-freelance__button"
          >
            {{ $t('home.learn_more') }}
          </v-btn>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import ServicesSearchForm from '@/components/PractitionerSearch/ServicesSearchForm'

export default {
  name: "Home",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Home page'
  },
  components: {
    ServicesSearchForm
  },
  methods: {
    onSearch() {
      this.$router.push({path: '/services-search'});
    },
    onServicesSearch() {
      this.$router.push({name: 'services-search'});
    },
    onLearnMore() {
      this.$router.push({name: 'join-freely'});
      this.$scrollTo('.header.v-toolbar');
    },
    onServices() {
      this.$router.push({name: 'services'});
      this.$scrollTo('.header.v-toolbar');
    },
    onSignUp() {
      this.$router.push({name: 'practitioner-registration'});
    },
    onSupport() {
      this.$router.push({name: 'support'});
      this.$scrollTo('.header.v-toolbar');
    }
  }
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.home {
  @include r(700) {
    background-color: #f9f9f9;
  }

  &__wrapper {
    margin: 0 auto;
    display: block;
    padding: 0 24px;
  }

  &__title {
    display: block;
    text-align: center;
    color: #272727;
    font-family: Nunito;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;

    @include r(1700) {
      font-size: 34px;
    }

    @include r(1500) {
      font-size: 30px;
    }

    @include r(1000) {
      font-size: 26px;
    }

    @include r(700) {
      font-size: 24px;
    }

    @include r(550) {
      font-size: 22px;
    }

    @include r(450) {
      font-size: 19px;
    }

    &--white {
      color: #fff;
    }
  }

  &-hero {
    position: relative;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    &--main {
      background-image: url("../.././src/images/home/bg-hero-2020.jpg");

      @include r(400) {
        background: {
          image: url("../.././src/images/home/bg-hero-2020-mob.jpg");
        }
      }
    }

    @include r(600) {
      background: {
        size: auto 250px;
        position: top center;
      }
      padding-top: 250px;
    }


    .home {
      &__wrapper {
        max-width: 1408px;

        @include r(1700) {
          max-width: 1270px;
        }
        @include r(1600) {
          max-width: 1170px;
        }
        @include r(1500) {
          max-width: 1070px;
        }
        @include r(1400) {
          max-width: 1000px;
        }
        @include r(1300) {
          max-width: 980px;
        }
      }
    }

    &__content {
      padding: 250px 0 350px 0;

      @include r(1700) {
        padding: 230px 0 300px 0;
      }
      @include r(1500) {
        padding: 220px 0 280px 0;
      }
      @include r(1300) {
        padding: 160px 0 200px 0;
      }
      @include r(1000) {
        padding: 100px 0;
      }
      @include r(700) {
        position: relative;
        padding: 70px 0 130px 0;
      }
      @include r(600) {
        padding: 40px 0;
      }
    }

    &__description {
      max-width: 700px;

      @include r(1700) {
        max-width: 600px;
      }
      @include r(1500) {
        max-width: 500px;
      }

      @include r(700) {
        max-width: 100%;
      }
    }

    &__title {
      display: block;
      font-family: 'Hind', sans-serif !important;
      color: #fff;
      font-size: 58px;
      font-weight: 700;
      line-height: 1;

      @include r(1700) {
        font-size: 48px;
      }

      @include r(1500) {
        font-size: 38px;
      }

      @include r(900) {
        font-size: 32px;
      }

      @include r(700) {
        font-size: 28px;
      }
      @include r(600) {
        color: #47434d;
      }
      @include r(550) {
        font-size: 26px;
      }
      @include r(450) {
        font-size: 24px;
      }
    }

    &__text {
      display: block;
      margin-top: 70px;
      color: #080808;
      font-size: 30px;
      font-weight: 400;
      line-height: 1.2;

      @include r(1700) {
        font-size: 26px;
        margin-top: 30px;
      }

      @include r(1500) {
        font-size: 23px;
      }

      @include r(900) {
        font-size: 20px;
      }

      @include r(700) {
        font-size: 18px;
        margin-top: 25px;
      }

      @include r(600) {
        color: #04000a;
      }

      @include r(550) {
        font-size: 17px;
      }
      @include r(450) {
        font-size: 15px;
      }
    }

    &__form {
      display: block;
      max-width: 656px;
      width: 100%;
      margin-top: 30px;

      & > div {
        display: block;
        width: 100%;
      }

      @include r(1700) {
        max-width: 600px;
        margin-top: 40px;
      }

      @include r(1500) {
        max-width: 550px;
      }
      @include r(700) {
        max-width: 100%;
      }
      @include r(550) {
        display: block;
        margin: 30px auto 0 auto;
      }

      .v-form {
        display: flex;
        flex-wrap: wrap;

        @include r(550) {
          display: block;
        }

        .v-text-field.v-text-field--solo .v-label {
          left: 0 !important;
          right: inherit !important;
        }

        .v-text-field {
          flex: 0 0 50%;

          @include r(550) {
            display: block;
            width: 100%;
          }

          .v-input__slot {
            height: 66px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.34);
            border-radius: 0;
            color: #535353;
            font-family: Nunito;
            font-size: 23px;
            font-weight: 400;

            @include r(1700) {
              height: 60px;
              font-size: 21px;
            }
            @include r(1500) {
              height: 56px;
              font-size: 20px;
            }
            @include r(900) {
              height: 50px;
              font-size: 18px;
            }
            @include r(700) {
              font-size: 17px;
            }
          }
        }

        .v-messages__message {
          color: #fff;

          @include r(600) {
            color: #ff5252;
          }
        }
      }

      button.v-btn {
        width: 100%;
        height: 66px;
        margin: 0;
        color: #fff;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;

        @include r(1700) {
          height: 60px;
          font-size: 21px;
        }

        @include r(1500) {
          height: 56px;
          font-size: 20px;
        }
        @include r(900) {
          height: 50px;
          font-size: 18px;
        }
        @include r(700) {
          font-size: 17px;
        }
        @include r(550) {
          margin-top: 5px;
        }
      }
    }
  }

  &-freelance {
    background-color: #f25757;

    .home {
      &__wrapper {
        max-width: 1408px;

        @include r(400) {
          padding: 0 18px;
        }
      }
    }

    &__content {
      padding: 36px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include r(1700) {
        padding: 30px 0;
      }

      @include r(1500) {
        padding: 26px 0;
      }
      @include r(900) {
        padding: 24px 0;
      }
      @include r(800) {
        justify-content: space-between;
      }
      @include r(650) {
        padding: 22px 0;
      }

      button {
        width: 154px;
        height: 49px;
        color: #fff;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
        margin: 0;
        background-color: transparent !important;
        border: 1px solid #fdf7f7 !important;
        border-radius: 5px !important;

        &::before {
          opacity: 0;
        }

        @include r(1700) {
          font-size: 19px;
          height: 46px;
        }
        @include r(1500) {
          font-size: 18px;
          height: 44px;
        }
        @include r(900) {
          font-size: 15px;
          height: 42px;
          width: 112px;
        }
        @include r(650) {
          flex: 0 0 auto;
        }
        @include r(450) {
          width: 89px;
          height: 40px;
          border-radius: 4px;
          font-size: 12px;
        }
      }
    }

    &__title {
      @include r(650) {
        flex: 1 1 auto;
        padding-right: 8px;
      }

      strong {
        display: inline-block;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: 1.2;
        position: relative;
        top: 1px;
        @include r(1700) {
          font-size: 23px;
        }
        @include r(1500) {
          font-size: 21px;
        }
        @include r(900) {
          font-size: 20px;
        }
        @include r(550) {
          font-size: 18px;
        }
        @include r(450) {
          font-size: 15px;
        }
      }

      p {
        display: inline-block;
        color: #fff;
        font-size: 25px;
        font-weight: 300;
        line-height: 1.1;
        margin: 0 12px;

        @include r(1700) {
          font-size: 23px;
        }
        @include r(1500) {
          font-size: 21px;
        }
        @include r(900) {
          font-size: 20px;
        }
        @include r(800) {
          display: block;
          margin-left: 0;
        }
        @include r(650) {
          display: block;
          margin: 4px 0 0 0;
        }
        @include r(550) {
          font-size: 18px;
        }
        @include r(450) {
          font-size: 15px;
          line-height: 1.4;
        }

        span {
          color: #fff;
          font-family: "Berkshire Swash";
          font-size: 28px;
          font-weight: 400;
          display: inline-block;
          line-height: 1.1;
          font-style: normal;
          margin: 0 7px;

          @include r(1700) {
            font-size: 27px;
          }
          @include r(1500) {
            font-size: 25px;
          }
          @include r(900) {
            font-size: 24px;
          }
          @include r(550) {
            font-size: 22px;
          }
          @include r(450) {
            font-size: 20px;
          }
        }
      }
    }
  }

  &-services {
    background-color: #fff;

    @include r(600) {
      background-color: #f9f9f9;
    }

    .home {
      &__wrapper {
        max-width: 1240px;

        @include r(1500) {
          max-width: 1070px;
        }
        @include r(1400) {
          max-width: 1000px;
        }
        @include r(1300) {
          max-width: 980px;
        }
      }
    }

    &__content {
      padding: 80px 0;

      @include r(1300) {
        padding: 70px 0;
      }

      @include r(1000) {
        padding: 60px 0;
      }

      @include r(700) {
        padding: 60px 0;
      }
      @include r(600) {
        padding-top: 0;
      }
    }

    &__items {
      display: flex;
      margin: 85px -15px 0 -15px;
      justify-content: space-between;

      @include r(1700) {
        margin-top: 65px;
      }

      @include r(1500) {
        margin-top: 50px;
      }
      @include r(800) {
        flex-wrap: wrap;
      }

      @include r(500) {
        margin-top: 40px;
      }
    }

    &__item {
      flex: 0 0 16.6666667%;
      padding: 15px;
      cursor: pointer;

      @include r(800) {
        flex: 0 0 33.3333333%;
      }

      @include r(500) {
        flex: 0 0 50%;
      }

      span {
        display: block;
        margin-top: 40px;
        color: #47434d;
        font-family: Nunito;
        font-size: 30px;
        font-weight: 700;
        text-align: center;

        @include r(1700) {
          font-size: 26px;
          margin-top: 45px;
        }

        @include r(1500) {
          font-size: 22px;
          margin-top: 30px;
        }
        @include r(700) {
          font-size: 20px;
          margin-top: 20px;
        }
        @include r(550) {
          font-size: 19px;
          margin-top: 15px;
        }
      }

      a {
        text-decoration: none;

        &:hover {
          .home {
            &-services {
              &__btn {
                background-color: #b2b2b2;
                color: #fff;
                transition: 0.3s;
              }
            }
          }
        }
      }
    }

    &__inner {
      display: flex;
      max-height: 80px;
      max-width: 90px;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      @include r(1700) {
        max-height: 70px;
        max-width: 80px;
      }

      @include r(550) {
        max-height: 50px;
        max-width: 60px;
      }

      img {
        max-height: 100%;
        max-width: 100%;
        display: inline-block;
      }
    }

    &__action {
      display: block;
      text-align: center;
      margin-top: 60px;

      @include r(1400) {
        margin-top: 45px;
      }

      button {
        display: inline-block;
        max-width: 450px;
        width: 100%;
        height: 75px;
        color: #fff;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        border-radius: 10px;
        margin: 0;

        @include r(1700) {
          max-width: 420px;
          height: 70px;
          font-size: 21px;
        }

        @include r(1500) {
          max-width: 320px;
          height: 60px;
          font-size: 18px;
        }

        @include r(900) {
          max-width: 300px;
          height: 55px;
          font-size: 16px;
          border-radius: 4px;
        }

        @include r(450) {
          max-width: 265px;
          height: 50px;
          font-size: 15px;
        }
      }
    }
  }

  &-choice {
    display: block;
    padding: 90px 0;
    background-color: #f7f7f7;

    @include r(700) {
      background-color: transparent;
      position: relative;

      &::after {
        top: 0;
        left: 46px;
        right: 46px;
        height: 1px;
        background-color: #b2b2b2;
        position: absolute;
        content: "";
      }
    }

    @include r(1500) {
      padding: 80px 0;
    }

    @include r(1300) {
      padding: 70px 0;
    }

    @include r(1000) {
      padding: 60px 0;
    }

    @include r(700) {
      padding: 60px 0;
    }

    .home {
      &__title {
        padding: 0 24px;

        @include r(830) {
          display: none;
        }
      }
    }

    &__inners {
      display: flex;
      align-items: stretch;

      @include r(830) {
        display: block;
        padding: 0 24px;
      }
    }

    &__inner {
      flex: 0 0 50%;

      @include r(830) {
        margin-bottom: 50px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-description {
        display: block;
        padding: 100px 24px;
        text-align: center;
        max-width: 550px;
        margin: 0 auto;

        @include r(1700) {
          padding: 80px 24px;
        }

        @include r(1500) {
          padding: 80px 24px 60px 24px;
        }
        @include r(900) {
          padding: 40px 24px;
        }

        @include r(830) {
          padding: 0 24px 40px 24px;
        }

        @include r(550) {
          padding: 0 0 35px 0;
        }

        strong {
          display: block;
          color: #272727;
          font-size: 30px;
          font-weight: 600;
          line-height: 1.2;

          @include r(1700) {
            font-size: 26px;
          }

          @include r(1500) {
            font-size: 22px;
          }
          @include r(700) {
            font-size: 20px;
          }
          @include r(550) {
            font-size: 19px;
          }
        }

        p {
          display: block;
          margin: 30px 0 0 0;
          color: #47434d;
          font-size: 25px;
          font-weight: 300;
          line-height: 1.2;

          @include r(1700) {
            font-size: 21px;
          }

          @include r(1500) {
            font-size: 19px;
            margin-top: 20px;
          }
          @include r(900) {
            font-size: 18px;
          }
          @include r(550) {
            font-size: 17px;
          }
        }
      }

      &-photo {
        display: block;
        font-size: 0;

        @include r(830) {
          max-width: 500px;
          margin: 0 auto;
        }

        img {
          width: 100%;
        }
      }

      &-action {
        display: block;
        text-align: center;
        margin-top: 75px;

        @include r(1700) {
          margin-top: 60px;
        }

        @include r(1500) {
          margin-top: 40px;
        }

        @include r(700) {
          margin-top: 34px;
        }

        button {
          display: inline-block;
          max-width: 450px;
          width: 100%;
          height: 75px;
          border-radius: 10px;
          color: #fff;
          font-family: Nunito;
          font-size: 23px;
          font-weight: 700;
          margin: 0;

          @include r(1700) {
            max-width: 420px;
            height: 70px;
            font-size: 21px;
          }

          @include r(1500) {
            max-width: 320px;
            height: 60px;
            font-size: 18px;
          }

          @include r(900) {
            max-width: 300px;
            height: 55px;
            font-size: 16px;
            border-radius: 4px;
          }

          @include r(450) {
            max-width: 265px;
            height: 50px;
            font-size: 15px;
          }
        }
      }
    }
  }

  &-how-it-works {
    background-color: #f7f7f7;
    position: relative;

    @include r(700) {
      background-color: transparent;
      position: relative;

      &::after {
        top: 0;
        left: 46px;
        right: 46px;
        height: 1px;
        background-color: #b2b2b2;
        position: absolute;
        content: "";
      }
    }

    .home {
      &__wrapper {
        max-width: 1658px;
      }
    }

    &__content {
      padding: 80px 0;

      @include r(1300) {
        padding: 70px 0;
      }

      @include r(1000) {
        padding: 60px 0;
      }

    }

    &__lside {
      width: 50%;
      padding-right: 170px;

      @include r(1500) {
        padding: 0 120px 0 80px;
      }

      @include r(1440) {
        padding: 0 100px 0 70px;
      }
      @include r(1300) {
        padding: 0 90px 0 60px;
      }
      @include r(1100) {
        padding-right: 60px;
      }
      @include r(1023) {
        width: 100%;
        padding: 0;
      }
    }

    &__image {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 50%;
      height: 640px;
      background: {
        size: cover;
        position: center left;
      }

      @include r(1500) {
        height: 560px;
      }
      @include r(1400) {
        height: 500px;
      }

      &--main {
        background-image: url("../.././src/images/home/photo-hit-2020.jpg");
      }

      &--desk {
        @include r(1023) {
          display: none;
        }
      }

      &--mob {
        display: none;
        position: relative;
        transform: none;
        top: 0;
        background: none;
        height: auto;

        @include r(1023) {
          display: block;
          max-width: 500px;
          width: 100%;
          margin: 30px auto 0 auto;
        }
      }
    }

    &__items {
      display: block;

      @include r(1700) {
        margin-top: 40px;
      }
      @include r(1500) {
        margin-top: 20px;
      }

      @include r(1000) {
        flex-wrap: wrap;
      }
      @include r(600) {
        margin: 20px 0 0 0;
      }
    }

    &__item {
      padding: 5px 0;
      display: flex;
      align-items: center;
    }

    &__number {
      display: block;
      flex: 0 0 auto;
      min-width: 60px;
      color: #b2b2b2;
      font-family: "Pacifico", cursive;
      font-size: 100px;
      font-weight: 400;
      line-height: 1;
      text-align: center;

      @include r(1700) {
        font-size: 90px;
      }
      @include r(1400) {
        font-size: 80px;
      }
      @include r(1300) {
        font-size: 75px;
      }

      @include r(600) {
        flex: 0 0 auto;
        position: relative;
      }
    }

    &__description {
      flex: 1 1 auto;
      padding: 25px 0 0 30px;
      display: block;
      color: #47434d;
      font-family: Nunito;
      font-size: 23px;
      font-weight: 400;
      line-height: 1.2;

      @include r(1700) {
        font-size: 21px;
      }

      @include r(1500) {
        font-size: 19px;
      }
      @include r(1400) {
        padding: 25px 0 0 26px;
        font-size: 18px;
      }

      @include r(1300) {
        font-size: 17px;
        margin-top: 20px;
        padding: 6px 0 0 26px;
      }
      @include r(1023) {
        padding: 0 0 0 30px;
      }
      @include r(600) {
        text-align: left;
        margin: 0;
        font-weight: 300;
        font-size: 17px;
        padding: 20px 0 0 20px;
      }
    }

    &__action {
      display: block;
      text-align: center;
      margin-top: 70px;

      @include r(1700) {
        margin-top: 80px;
      }

      @include r(1500) {
        margin-top: 65px;
      }
      @include r(1400) {
        margin-top: 40px;
      }

      @include r(550) {
        margin-top: 40px;
      }

      button {
        display: inline-block;
        max-width: 450px;
        width: 100%;
        height: 75px;
        border-radius: 10px;
        color: #fff;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        margin: 0;

        @include r(1700) {
          max-width: 420px;
          height: 70px;
          font-size: 21px;
        }

        @include r(1500) {
          max-width: 320px;
          height: 60px;
          font-size: 18px;
        }

        @include r(900) {
          max-width: 300px;
          height: 55px;
          font-size: 16px;
          border-radius: 4px;
        }

        @include r(450) {
          max-width: 265px;
          height: 50px;
          font-size: 15px;
        }
      }
    }
  }

  &-practitioner {
    background-color: #f7f7f7;
    overflow: hidden;

    @include r(700) {
      background-color: transparent;
      position: relative;

      &::after {
        top: 0;
        left: 46px;
        right: 46px;
        height: 1px;
        background-color: #b2b2b2;
        position: absolute;
        content: "";
      }
    }

    .home {
      &__title {
        span {
          color: #272727;
          font-family: "Berkshire Swash";
          font-size: 50px;
          font-weight: 400;
          display: inline-block;
          margin: 0 8px;

          @include r(1700) {
            font-size: 45px;
          }
          @include r(1500) {
            font-size: 40px;
          }
          @include r(1300) {
            font-size: 37px;
          }
          @include r(700) {
            font-size: 32px;
          }
          @include r(550) {
            font-size: 28px;
            margin: 0 4px;
          }
        }
      }

      &__wrapper {
        max-width: 1556px;
      }
    }

    &__content {
      padding: 90px 0 100px 0;

      @include r(1500) {
        padding: 80px 0;
      }

      @include r(1300) {
        padding: 70px 0;
      }

      @include r(1000) {
        padding: 60px 0;
      }

      @include r(700) {
        padding: 60px 0;
      }
    }

    &__holder {
      display: block;
      position: relative;
      margin-top: 90px;
      max-width: 670px;
      padding: 180px 0 110px 0;

      @include r(1700) {
        padding: 160px 0 100px 0;
        margin-top: 70px;
      }
      @include r(1600) {
        padding-left: 20px;
      }
      @include r(1500) {
        padding: 120px 0 100px 0;
      }
      @include r(1400) {
        padding: 80px 0;
      }
      @include r(1400) {
        margin-top: 50px;
      }
      @include r(1250) {
        max-width: 55%;
      }
      @include r(900) {
        max-width: 100%;
        padding: 0;
        margin-top: 20px;
      }
    }

    &__description {
      display: block;

      @include r(900) {
        text-align: center;
      }
    }

    &__slogan {
      display: block;
      color: #47434d;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      position: relative;
      z-index: 2;

      @include r(1700) {
        font-size: 35px;
      }
      @include r(1600) {
        font-size: 30px;
      }

      @include r(1500) {
        font-size: 24px;
        padding: 0 80px;
      }
      @include r(1200) {
        padding: 0 40px;
      }
      @include r(1100) {
        font-size: 22px;
      }
      @include r(900) {
        font-size: 20px;
        display: inline-block;
        margin: 20px 0;
      }
      @include r(550) {
        font-size: 17px;
      }
      @include r(450) {
        font-weight: 300;
        padding: 0 20px;
      }

      &::before {
        content: "“";
        position: absolute;
        top: 0;
        left: -20px;
        color: #b2b2b2;
        font-family: "Berkshire Swash";
        font-size: 150px;
        font-weight: 400;
        line-height: 10px;
        @include r(1700) {
          font-size: 130px;
          top: 6px;
        }
        @include r(1600) {
          font-size: 110px;
          left: 0;
        }
        @include r(1500) {
          font-size: 100px;
          left: 40px;
        }
        @include r(1400) {
          font-size: 90px;
          left: 60px;
        }
        @include r(1200) {
          left: 20px;
        }
        @include r(900) {
          left: 0;
          font-size: 80px;
          top: 7px;
        }
        @include r(550) {
          font-size: 70px;
        }
        @include r(450) {
          top: 2px;
        }
      }

      &::after {
        content: "”";
        position: absolute;
        bottom: -12px;
        right: -24px;
        color: #b2b2b2;
        font-family: "Berkshire Swash";
        font-size: 150px;
        font-weight: 400;
        line-height: 10px;

        @include r(1700) {
          font-size: 130px;
          bottom: -16px;
          right: 0;
        }
        @include r(1600) {
          font-size: 110px;
        }
        @include r(1500) {
          font-size: 100px;
          right: 50px;
        }
        @include r(1400) {
          font-size: 90px;
          right: 80px;
        }
        @include r(1200) {
          right: 20px;
        }
        @include r(900) {
          right: 0;
          bottom: -30px;
          font-size: 80px;
        }
        @include r(550) {
          font-size: 70px;
        }
      }
    }

    &__info {
      display: flex;
      align-items: center;
      margin: 130px auto 0 auto;
      max-width: 570px;
      position: relative;
      z-index: 2;

      @include r(1400) {
        margin-top: 100px;
      }

      @include r(900) {
        text-align: left;
        margin-top: 50px;
      }
      @include r(450) {
        padding-top: 10px;
      }

      &-photo {
        display: block;
        flex: 0 0 104px;
        width: 104px;
        height: 104px;
        border-radius: 10px;
        overflow: hidden;

        &--barber {
          background: {
            image: url("../.././src/images/home/barberprofile.jpg");
            repeat: no-repeat;
            size: cover;
            position: center top;
          }
        }

        img {
          width: 100%;
        }

        @include r(450) {
          width: 62px;
          height: 62px;
          border-radius: 10px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &-rside {
        padding-left: 20px;
        flex: 1 1 auto;

        @include r(450) {
          padding-left: 0;
        }

        strong {
          display: block;
          color: #47434d;
          font-size: 20px;
          line-height: 1.1;
          font-weight: 700;

          @include r(1500) {
            font-size: 19px;
          }
          @include r(730) {
            font-size: 18px;
          }
          @include r(550) {
            font-size: 17px;
          }
          @include r(450) {
            font-size: 15px;
            padding-left: 80px;
          }
        }

        span {
          display: block;
          margin-top: 10px;
          color: #47434d;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.1;

          @include r(1500) {
            font-size: 17px;
          }
          @include r(730) {
            font-size: 16px;
          }
          @include r(550) {
            font-size: 15px;
          }
          @include r(450) {
            font-size: 14px;
            padding-left: 80px;
          }
        }

        p {
          display: block;
          margin: 10px 0 0 0;
          color: #47434d;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.1;
          @include r(1500) {
            font-size: 17px;
          }
          @include r(730) {
            font-size: 16px;
          }
          @include r(550) {
            font-size: 15px;
          }
          @include r(450) {
            text-align: center;
            margin-top: 30px;
          }
        }
      }
    }

    &__photo {
      display: block;
      z-index: 1;
      position: absolute;
      left: calc(100% + 140px);
      top: 0;
      bottom: 0;
      background: {
        image: url("../.././src/images/home/barber.jpg");
        repeat: no-repeat;
        size: cover;
        position: top left;
      }
      width: 47vw;

      @include r(1620) {
        width: 46.3vw;
      }

      @include r(1580) {
        width: 45.8vw;
      }

      @include r(1500) {
        width: 48vw;
        left: calc(100% + 80px);
      }

      @include r(1450) {
        width: 47vw;
      }
      @include r(1400) {
        width: 46vw;
        left: calc(100% + 50px);
      }
      @include r(1300) {
        width: 45vw;
        left: calc(100% + 10px);
      }
      @include r(1200) {
        width: 44vw;
      }
      @include r(900) {
        width: 100%;
        max-width: 500px;
        position: relative;
        height: 320px;
        margin: 30px auto 0 auto;
        background-position: top right;
        left: 0;
      }
      @include r(550) {
        height: 260px;
      }
      @include r(450) {
        height: 180px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 75px;

      @include r(1400) {
        margin-top: 56px;
      }
      @include r(730) {
        display: block;
        text-align: center;
      }
      @include r(450) {
        margin-top: 40px;
      }
    }

    &__more {
      max-width: 450px;
      width: 100%;
      display: inline-block;
      height: 75px;
      border-radius: 10px;
      background-color: #fff !important;
      border: 4px solid #f25757;
      color: #272727;
      font-family: Nunito;
      font-size: 23px;
      font-weight: 700;
      text-align: center;
      transition: 0.3s;
      margin: 0 20px;
      overflow: hidden;

      &::before {
        border-radius: 5px;
        @include r(900) {
          border-radius: 0;
        }
      }

      .v-ripple__container {
        border-radius: 5px;
        @include r(900) {
          border-radius: 0;
        }
      }

      @include r(1700) {
        max-width: 420px;
        height: 70px;
        font-size: 21px;
      }

      @include r(1500) {
        max-width: 320px;
        height: 60px;
        font-size: 18px;
      }

      @include r(900) {
        max-width: 300px;
        height: 55px;
        font-size: 16px;
        border-radius: 4px;
      }
      @include r(730) {
        margin-bottom: 20px;
      }

      @include r(450) {
        max-width: 265px;
        height: 50px;
        font-size: 15px;
        display: block;
        margin: 0 auto 20px auto;
      }
    }

    &__sign-up {
      max-width: 450px;
      margin: 0 20px;
      width: 100%;
      height: 75px;
      border-radius: 10px;
      color: #fff;
      font-family: Nunito;
      font-size: 23px;
      font-weight: 700;

      @include r(1700) {
        max-width: 420px;
        height: 70px;
        font-size: 21px;
      }

      @include r(1500) {
        max-width: 320px;
        height: 60px;
        font-size: 18px;
      }

      @include r(900) {
        max-width: 300px;
        height: 55px;
        font-size: 16px;
        border-radius: 4px;
      }

      @include r(450) {
        max-width: 265px;
        height: 50px;
        font-size: 15px;
        margin: 0 auto;
        display: block;
      }
    }
  }

  &-advantages {
    @include r(700) {
      background-color: transparent;
      position: relative;

      &::after {
        top: 0;
        left: 46px;
        right: 46px;
        height: 1px;
        background-color: #b2b2b2;
        position: absolute;
        content: "";
      }
    }

    .home {
      &__wrapper {
        max-width: 1658px;
      }
    }

    &__content {
      padding: 90px 0 100px 0;
      @include r(1500) {
        padding: 80px 0;
      }

      @include r(1300) {
        padding: 70px 0;
      }

      @include r(1000) {
        padding: 60px 0;
      }

      @include r(700) {
        padding: 60px 0;
      }
    }

    &__items {
      display: flex;
      margin: 80px -20px 0 -20px;
      justify-content: space-between;

      @include r(1700) {
        margin-top: 60px;
      }

      @include r(1500) {
        margin-top: 50px;
      }
      @include r(730) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
      }
    }

    &__item {
      flex: 0 0 auto;
      max-width: 452px;
      padding: 20px;
      text-align: center;

      @include r(1500) {
        flex: 0 0 33.3333333%;
        max-width: 100%;
      }
      @include r(730) {
        flex: 0 0 50%;
      }
      @include r(500) {
        flex: 0 0 100%;
      }

      p {
        display: block;
        margin: 30px 0 0 0;
        color: #47434d;
        font-size: 23px;
        font-weight: 400;
        line-height: 1.3;

        @include r(1700) {
          font-size: 21px;
        }

        @include r(1500) {
          font-size: 19px;
          padding: 0 40px;
        }
        @include r(1400) {
          font-size: 18px;
        }
        @include r(1200) {
          font-size: 17px;
          padding: 0;
        }
        @include r(730) {
          font-weight: 300;
        }
      }
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 115px;

      @include r(1500) {
        max-width: 110px;
        margin: 0 auto;
      }
      @include r(1200) {
        max-width: 90px;
        min-height: 90px;
      }

      img {
        max-width: 126px;
        width: 100%;
        display: inline-block;
      }
    }

    &__action {
      display: block;
      text-align: center;
      margin-top: 90px;

      @include r(1700) {
        margin-top: 70px;
      }

      @include r(1500) {
        margin-top: 60px;
      }
      @include r(730) {
        margin-top: 34px;
      }

      button {
        display: inline-block;
        max-width: 450px;
        width: 100%;
        height: 75px;
        border-radius: 10px;
        color: #fff;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        margin: 0;

        @include r(1700) {
          max-width: 420px;
          height: 70px;
          font-size: 21px;
        }

        @include r(1500) {
          max-width: 320px;
          height: 60px;
          font-size: 18px;
        }

        @include r(900) {
          max-width: 300px;
          height: 55px;
          font-size: 16px;
          border-radius: 4px;
        }

        @include r(450) {
          max-width: 265px;
          height: 50px;
          font-size: 15px;
        }
      }
    }
  }

  &-booked {
    background-color: #f7f7f7;

    @include r(700) {
      background-color: transparent;
      position: relative;

      &::after {
        top: 0;
        left: 46px;
        right: 46px;
        height: 1px;
        background-color: #b2b2b2;
        position: absolute;
        content: "";
      }
    }

    .home {
      &__wrapper {
        max-width: 1178px;

        @include r(1500) {
          max-width: 1070px;
        }
        @include r(1400) {
          max-width: 1000px;
        }
        @include r(1300) {
          max-width: 980px;
        }
      }
    }

    &__content {
      padding: 90px 0;
      @include r(1500) {
        padding: 70px 0;
      }

      @include r(1400) {
        padding: 50px 0;
      }
    }

    &__holder {
      display: flex;
      align-items: center;
      margin: 60px -20px 0 -20px;

      @include r(1500) {
        margin-top: 40px;
      }
      @include r(1400) {
        margin-top: 25px;
      }
      @include r(850) {
        display: block;
        margin: 30px 0 0 0;
      }
    }

    &__lside {
      flex: 0 0 50%;
      padding: 20px;

      @include r(850) {
        padding: 10px 0;
      }

      .home {
        &-practitioner {
          &__info {
            margin-top: 0;
          }
        }
      }
    }

    &__rside {
      flex: 0 0 50%;
      padding: 20px;
      @include r(850) {
        padding: 30px 0 0 0;
      }
      @include r(850) {
        padding-top: 20px;
      }

      p {
        display: block;
        margin: 0;
        text-align: right;
        color: #47434d;
        font-size: 25px;
        font-weight: 300;
        line-height: 1.2;

        @include r(1700) {
          font-size: 23px;
        }
        @include r(1500) {
          font-size: 21px;
        }
        @include r(1400) {
          font-size: 18px;
        }
        @include r(850) {
          font-size: 17px;
          text-align: center;
        }
        @include r(550) {
          font-size: 15px;
        }
      }
    }
  }

  &-feedback {
    background-color: #fff;

    @include r(700) {
      background-color: transparent;
      position: relative;

      &::after {
        top: 0;
        left: 46px;
        right: 46px;
        height: 1px;
        background-color: #b2b2b2;
        position: absolute;
        content: "";
      }
    }

    .home {
      &__wrapper {
        max-width: 1658px;
      }
    }

    &__content {
      padding: 90px 0;
      @include r(1500) {
        padding: 70px 0;
      }

      @include r(1400) {
        padding: 50px 0;
      }
    }

    &__holder {
      display: flex;
      align-items: center;
      margin: 60px -50px 0 -50px;

      @include r(1500) {
        margin-top: 40px;
      }
      @include r(1440) {
        margin: 60px -20px 0 -20px;
      }
      @include r(1400) {
        margin-top: 25px;
      }
      @include r(850) {
        flex-direction: column;
        margin: 30px 0 0 0;
      }
    }

    &__lside {
      flex: 0 0 50%;
      padding: 20px 50px;
      order: 2;

      @include r(1440) {
        padding: 20px;
      }

      @include r(850) {
        flex: 0 0 100%;
        padding: 30px 0 0 0;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      margin: 0;
      position: relative;
      z-index: 2;

      @include r(900) {
        text-align: left;
      }

      @include r(500) {
        align-items: flex-start;
      }

      &-photo {
        display: block;
        flex: 0 0 auto;
        width: 120px;
        width: 120px;
        border-radius: 10px;
        overflow: hidden;
        font-size: 0;

        @include r(1440) {
          width: 110px;
          width: 110px;
        }

        @include r(500) {
          width: 92px;
          height: 92px;
        }

        img {
          width: 100%;
        }
      }

      &-rside {
        padding-left: 30px;
        flex: 1 1 auto;

        @include r(1023) {
          padding-left: 20px;
        }

        strong {
          display: block;
          color: #47434d;
          font-size: 20px;
          line-height: 1.1;
          font-weight: 700;

          @include r(1500) {
            font-size: 19px;
          }
          @include r(730) {
            font-size: 18px;
          }
          @include r(550) {
            font-size: 17px;
          }
          @include r(500) {
            padding-top: 35px;
          }
          @include r(450) {
            font-size: 15px;
          }
        }

        span {
          display: block;
          margin-top: 12px;
          color: #47434d;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.1;

          @include r(1500) {
            font-size: 17px;
          }
          @include r(730) {
            font-size: 16px;
          }
          @include r(550) {
            font-size: 15px;
            margin-top: 7px;
          }
          @include r(450) {
            font-size: 14px;
          }
        }

        p {
          display: block;
          margin: 12px 0 0 0;
          color: #47434d;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.1;
          @include r(1500) {
            font-size: 17px;
          }
          @include r(730) {
            font-size: 16px;
          }
          @include r(550) {
            font-size: 15px;
          }
          @include r(500) {
            margin: 40px 0 0 -112px;
          }
        }
      }
    }

    &__rside {
      flex: 0 0 50%;
      padding: 20px 50px;
      order: 1;

      @include r(1440) {
        padding: 20px;
      }

      @include r(850) {
        flex: 0 0 100%;
        padding: 20px 0 0 0;
      }

      p {
        display: block;
        margin: 0;
        color: #47434d;
        font-size: 30px;
        font-weight: 600;
        line-height: 1.2;
        position: relative;
        padding: 0 60px;
        max-width: 720px;

        @include r(1700) {
          padding: 0 50px;
        }
        @include r(1600) {
          padding: 0 45px;
        }
        @include r(1400) {
          padding: 0 40px;
        }
        @include r(1300) {
          padding: 0 35px;
        }
        @include r(900) {
          padding: 0 30px;
        }
        @include r(550) {
          padding: 0 20px;
        }

        &::before {
          content: "“";
          position: absolute;
          top: 5px;
          left: 0;
          color: #b2b2b2;
          font-family: "Berkshire Swash";
          font-size: 150px;
          font-weight: 400;
          line-height: 10px;
          @include r(1700) {
            font-size: 130px;
          }
          @include r(1600) {
            font-size: 110px;
          }
          @include r(1500) {
            font-size: 100px;
          }
          @include r(1400) {
            font-size: 90px;
          }
          @include r(900) {
            font-size: 80px;
          }
          @include r(550) {
            font-size: 70px;
          }
        }

        &::after {
          content: "”";
          position: absolute;
          bottom: -40px;
          right: 0;
          color: #b2b2b2;
          font-family: "Berkshire Swash";
          font-size: 150px;
          font-weight: 400;
          line-height: 10px;

          @include r(1700) {
            font-size: 130px;
          }
          @include r(1600) {
            font-size: 110px;
          }
          @include r(1500) {
            font-size: 100px;
          }
          @include r(1400) {
            font-size: 90px;
          }
          @include r(900) {
            font-size: 80px;
          }
          @include r(550) {
            font-size: 70px;
          }
        }

        @include r(1700) {
          font-size: 23px;
        }
        @include r(1500) {
          font-size: 21px;
        }
        @include r(1400) {
          font-size: 18px;
        }
        @include r(850) {
          font-size: 17px;
          text-align: center;
        }
        @include r(550) {
          font-size: 15px;
        }
      }
    }
  }

  &-find {
    background-color: #f25757;

    .home {
      &__wrapper {
        max-width: 1178px;
      }
    }

    &__content {
      padding: 90px 0 100px 0;
      @include r(1500) {
        padding: 70px 0;
      }

      @include r(1300) {
        padding: 60px 0;
      }

      @include r(1000) {
        padding: 60px 0;
      }

      @include r(700) {
        padding: 50px 0;
      }
    }

    &__action {
      display: block;
      text-align: center;
      margin-top: 70px;

      @include r(1700) {
        margin-top: 55px;
      }
      @include r(1500) {
        margin-top: 40px;
      }
      @include r(700) {
        margin-top: 30px;
      }

      button {
        display: inline-block;
        max-width: 450px;
        width: 100%;
        height: 75px;
        border-radius: 10px;
        background-color: #fff !important;
        color: #272727;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        margin: 0;

        @include r(1700) {
          max-width: 420px;
          height: 70px;
          font-size: 21px;
        }

        @include r(1500) {
          max-width: 320px;
          height: 60px;
          font-size: 18px;
        }

        @include r(900) {
          max-width: 300px;
          height: 55px;
          font-size: 16px;
          border-radius: 4px;
        }

        @include r(450) {
          max-width: 265px;
          height: 50px;
          font-size: 15px;
        }
      }
    }
  }
}
</style>
