<template>
  <div class="dashboard-customer__rside">
    <div class="dashboard-customer__checked">
      <div class="dashboard-customer__checks">
        <template v-for="(n, index) in numberOfIterations">
          <div
                  class="dashboard-customer__check"
                  :key="index"
                  :class="{'is-check': surpriseBookings[index]}"
          >
          </div>
        </template>
      </div>
      <div
              v-if="numberOfBookings !== 3"
              class="dashboard-customer__gift"
      >
      </div>
    </div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div class="dashboard-customer__question" v-on="on">?</div>
      </template>
      <span
              v-if="numberOfBookings !== 3 && loadingIsFinished"
              v-html="$t('customer_profile.dashboard_tab_help_description_for_surprise', {numberOfBookings: remainingBookings})"
      >
      </span>
      <span
              v-if="numberOfBookings === 3 && numberOfCompletedBookings !== 3 && loadingIsFinished"
      >
                 {{$t('customer_profile.dashboard_tab_help_awaiting_for_surprise')}}
      </span>
      <span
              v-if="numberOfBookings === 3 && numberOfCompletedBookings === 3 && loadingIsFinished"
      >
                  {{$t('customer_profile.dashboard_tab_help_congrats_for_surprise')}}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import _ from 'lodash';
  import moment from 'moment';

  export default {
    name: "booking-counter",
    data(){
      return{
        bookings:[],
        paidBookings:[],
        surpriseBookings: [],
        numberOfBookings: 0,
        numberOfIterations:2,
        remainingBookings:3,
        numberOfCompletedBookings:0,
        loadingIsFinished: false
      }
    },
    mounted() {
      this.getBookings();
    },
    watch: {
      paidBookings(){
        let numberByModulo = Number(this.paidBookings.length) % 3;

        if (numberByModulo === 0 && this.paidBookings.length !== 0) {
          this.numberOfBookings = 3;
          this.remainingBookings = 0;
          this.numberOfIterations = 3;
        } else {
          this.numberOfBookings = numberByModulo;
          this.remainingBookings = 3 - numberByModulo;
          this.numberOfIterations = 2;
        }
        this.surpriseBookings = _.reverse(_.slice(this.paidBookings, 0,  this.numberOfBookings));
      }
    },
    methods:{
      getBookings(){
        this.requestBookings()
          .then((response) => {
            this.bookings = response.data;
            this.paidBookings = this.getPaidBookings();
            this.preparePaidBookings();
            this.loadingIsFinished = true;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
            this.loadingIsFinished = true;
          });
      },
      getPaidBookings(){
        return this.bookings.filter((booking) => {
          return booking.paid === true && booking.booking_status !== 'cancelled by customer' && booking.booking_status !== 'cancelled by practitioner';
        })
      },
      preparePaidBookings(){
        for(let i=0; i < this.paidBookings.length; i++){
          this.paidBookings[i].sort_date = moment(this.paidBookings[i].created_at, moment.ISO_8601).clone().format("x");
        }
      },
      requestBookings(){
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/user/bookings`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>