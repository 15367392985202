<template>
  <div
          class="dashboard-content dashboard-settings"
          id="dashboard-content"
  >
    <div class="dashboard-content__title">
      <h2>
        {{$t('practitioner_profile.invite_reviews_tab_title')}}
      </h2>
      <p>
        {{$t('practitioner_profile.invite_reviews_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">
      <div class="dashboard-settings__container">
        <InviteReviewsComponent></InviteReviewsComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import InviteReviewsComponent from '@/components/Partial/InviteReviewsComponent';
  export default {
    name: "practitioner-dashboard-invite-reviewers",
    components:{
      InviteReviewsComponent
    }
  }
</script>

<style scoped>

</style>