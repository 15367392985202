<template>

  <div class="services">
    <div class="services__wrapper">
      <div class="services__content">
        <div class="services__title">
          <strong>Services</strong>
        </div>
        <div class="services__text">Our Independent practitioners provide a whole host of services. Select a category
          and view the full list or available treatments.
        </div>
        <div class="services__items">
          <div class="services__item">
            <div class="services__item-icon">
              <img src="../.././src/images/pages/services/s-make-up.svg" alt="">
            </div>
            <div class="services__item-description">
              <h3>Make-Up</h3>
              <p><strong>Make-up artists are trained professionals who live and breathe face shapes, contours, color
                theory,
                skin care, etc.</strong> They have an eye for beauty that can provide a unique perspective to your
                “usual” hair
                or makeup routine.</p>
            </div>
          </div>
          <div class="services__item">
            <div class="services__item-icon">
              <img src="../.././src/images/pages/services/s-hair.svg" alt="">
            </div>
            <div class="services__item-description">
              <h3>Hair</h3>
              <p><strong>Hair stylists have professionalism and personality.</strong> They are someone who takes their
                profession
                seriously, who listens to and wants to make their client happy and communicates well. </p>
            </div>
          </div>
          <div class="services__item">
            <div class="services__item-icon">
              <img src="../.././src/images/pages/services/s-massage.svg" alt="">
            </div>
            <div class="services__item-description">
              <h3>Massage</h3>
              <p><strong>Massage therapy has been growing and changing in recent years, and more and more people are
                realising
                that massage can have a wide range of benefits.</strong> A massage can help relieve pain, relax your
                muscles,
                improve your posture, or reduce your stress.</p>
            </div>
          </div>
          <div class="services__item">
            <div class="services__item-icon">
              <img src="../.././src/images/pages/services/s-nails.svg" alt="">
            </div>
            <div class="services__item-description">
              <h3>Nails</h3>
              <p><strong>Getting your nails done is one of the best ways to practice self-care.</strong> They instantly
                relax you and
                allow you to disengage from the other worries in the world to focus on just your nails. In fact, it’s
                not unusual to give yourself a new one every few days!</p>
            </div>
          </div>
          <div class="services__item">
            <div class="services__item-icon">
              <img src="../.././src/images/pages/services/s-facial.svg" alt="">
            </div>
            <div class="services__item-description">
              <h3>Facial</h3>
              <p><strong>Nothing can match the feeling you get when you glide your fingers over your face and feel that
                soft and
                milky skin.</strong> Most of you follow a daily skin care routine at home. It might not be elaborate,
                but it at
                least helps you maintain your skin. But once in a while, it’s good to let the professionals take care of
                your skin.</p>
            </div>
          </div>
          <div class="services__item">
            <div class="services__item-icon">
              <img src="../.././src/images/pages/services/s-waxing.svg" alt="">
            </div>
            <div class="services__item-description">
              <h3>Waxing</h3>
              <p><strong>From legs and underarms to bikini and brazilian waxes, enjoy expert hair removal at
                home.</strong> Our waxing
                menu combines luxury, comfort and exceptional results all in the convenience of your own home for a more
                ‘ooooo’ than ‘ouch’ waxing experience. </p>
            </div>
          </div>
          <div class="services__item">
            <div class="services__item-icon">
              <img src="../.././src/images/pages/services/s-eyes.svg" alt="">
            </div>
            <div class="services__item-description">
              <h3>Eyes</h3>
              <p><strong>Whether it’s a subtle lash lift treatment that you’re after, or some stunning lash extensions,
                our
                expert beauty technicians are sure to deliver a professional home service every time.</strong> The best
                part?
                They’re all done in the comfort of your own home.</p>
            </div>
          </div>
          <div class="services__item">
            <div class="services__item-icon">
              <img src="../.././src/images/pages/services/s-main.svg" alt="">
            </div>
            <div class="services__item-description services__item-description--center">
              <div class="services__item-title">Do you provide these services?</div>
              <div class="services__item-txt">Take your work further and join <span>freely</span> today.</div>
              <router-link to="/join-freely">
                <v-btn
                  class="error"
                  @click="onLearnMore()"
                >Learn More
                </v-btn>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "Services",
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Services'
    },
    methods: {
      onLearnMore() {
        this.$router.push({name: 'join-freely'});
        this.$scrollTo('.header.v-toolbar');
      }
    }
  };
</script>

<style scoped lang="scss">

  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .services {
    &__wrapper {
      max-width: 1580px;
      margin: 0 auto;
      padding: 0 24px;

      @include r(1700) {
        max-width: 1270px;
      }
      @include r(1600) {
        max-width: 1170px;
      }
      @include r(1500) {
        max-width: 1070px;
      }
      @include r(1400) {
        max-width: 1000px;
      }
      @include r(1300) {
        max-width: 980px;
      }
    }

    &__content {
      padding: 150px 0 220px 0;

      @include r(1700) {
        padding: 120px 0 200px 0;
      }
      @include r(1500) {
        padding: 70px 0 160px 0;
      }
      @include r(1000) {
        padding: 50px 0 100px 0;
      }

      @include r(700) {
        padding: 50px 0 70px 0;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 70px;
      border-bottom: 1px solid #b2b2b2;

      @include r(1500) {
        padding: 0 0 60px 0;
      }
      @include r(900) {
        padding: 0 0 50px 0;
      }
      @include r(700) {
        padding: 0;
        border-bottom: 0;
      }

      strong {
        display: inline-block;
        color: #272727;
        font-size: 55px;
        font-weight: 700;
        line-height: 1.2;

        @include r(1700) {
          font-size: 45px;
        }
        @include r(1500) {
          font-size: 40px;
        }
        @include r(1300) {
          font-size: 37px;
        }
        @include r(700) {
          font-size: 32px;
        }
        @include r(550) {
          font-size: 28px;
        }
      }

    }
    &__text {
      display: block;
      text-align: center;
      max-width: 930px;
      color: #47434d;
      font-size: 25px;
      font-weight: 300;
      margin: 50px auto 0 auto;

      @include r(1700) {
        font-size: 23px;
      }
      @include r(1500) {
        font-size: 21px;
      }
      @include r(1400) {
        font-size: 18px;
      }
      @include r(850) {
        font-size: 17px;
      }
      @include r(700) {
        padding-top: 35px;
        position: relative;
        margin-top: 35px;
        &::before {
          top: 0;
          left: 20px;
          right: 20px;
          height: 1px;
          background-color: #b2b2b2;
          position: absolute;
          content: '';
        }
      }

      @include r(550) {
        text-align: left;
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      margin: 50px -45px 0 -45px;

      @include r(1700) {
        margin: 45px -40px 0 -40px;
      }

      @include r(1500) {
        margin: 36px -32px 0 -32px;
      }

      @include r(1400) {
        margin: 32px -28px 0 -28px;
      }
      @include r(700) {
        margin-top: 24px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      flex: 0 0 50%;
      padding: 40px 45px;

      @include r(1700) {
        padding: 35px 40px;
      }

      @include r(1500) {
        padding: 28px 32px;
      }

      @include r(1400) {
        padding: 22px 28px;
      }

      @include r(900) {
        flex: 0 0 100%;
      }

      @include r(550) {
        display: block;
        padding: 8px 28px;
      }

      &-icon {
        flex: 0 0 138px;
        min-width: 138px;
        display: flex;
        justify-content: center;

        @include r(1700) {
          flex: 0 0 110px;
          min-width: 110px;
        }

        @include r(1500) {
          flex: 0 0 86px;
          min-width: 86px;
        }

        @include r(1400) {
          flex: 0 0 80px;
          min-width: 80px;
        }
        @include r(550) {
          min-width: 50px;
          max-width: 60px;
          margin: 0 auto;
        }
        img {
          max-width: 100%;
        }
      }

      &-description {
        flex: 1 1 auto;
        padding-left: 56px;

        @include r(1700) {
          padding-left: 46px;
        }
        @include r(1500) {
          padding-left: 36px;
        }

        @include r(550) {
          padding: 0;
        }

        h3 {
          display: block;
          color: #47434d;
          font-size: 40px;
          font-weight: 700;
          line-height: 1.2;

          @include r(1700) {
            font-size: 32px;
          }
          @include r(1500) {
            font-size: 28px;
          }
          @include r(1400) {
            font-size: 24px;
          }
          @include r(900) {
            font-size: 22px;
          }
          @include r(550) {
            font-size: 19px;
            text-align: center;
          }
        }

        p {
          display: block;
          margin: 30px 0 0 0;
          color: #47434d;
          font-size: 20px;
          font-weight: 300;

          @include r(1700) {
            font-size: 19px;
            margin-top: 24px;
          }
          @include r(1500) {
            font-size: 17px;
            margin-top: 18px;
          }

          strong {
            font-weight: 600;
          }
        }

        &--center {
          text-align: center;

          button {
            display: inline-block;
            max-width: 450px;
            width: 100%;
            height: 75px;
            border-radius: 10px;
            color: #fff;
            font-family: Nunito;
            font-size: 23px;
            font-weight: 700;
            margin: 35px 0 0 0;

            @include r(1700) {
              max-width: 420px;
              height: 70px;
              font-size: 21px;
            }

            @include r(1500) {
              max-width: 320px;
              height: 60px;
              font-size: 18px;
            }

            @include r(900) {
              max-width: 300px;
              height: 55px;
              font-size: 16px;
              border-radius: 4px;
            }

            @include r(450) {
              max-width: 265px;
              height: 50px;
              font-size: 15px;
            }
          }

          .services {
            &__item {
              &-title {
                display: block;
                color: #47434d;
                font-size: 30px;
                font-weight: 700;
                line-height: 1.2;

                @include r(1700) {
                  font-size: 28px;
                }
                @include r(1500) {
                  font-size: 24px;
                }
                @include r(1400) {
                  font-size: 22px;
                }
                @include r(900) {
                  font-size: 20px;
                }

                @include r(700) {
                  font-size: 19px;
                }
              }

              &-txt {
                display: block;
                margin-top: 23px;
                color: #47434d;
                font-size: 25px;
                font-weight: 300;
                line-height: 1.2;

                @include r(1700) {
                  font-size: 23px;
                }
                @include r(1500) {
                  font-size: 21px;
                }
                @include r(1400) {
                  font-size: 18px;
                }
                @include r(850) {
                  font-size: 17px;
                }

                span {
                  display: inline-block;
                  margin: 0 8px;
                  color: #272727;
                  font-family: "Berkshire Swash";
                  font-size: 30px;
                  font-weight: 400;
                  line-height: 1.2;

                  @include r(1700) {
                    font-size: 28px;
                  }
                  @include r(1500) {
                    font-size: 24px;
                  }
                  @include r(1400) {
                    font-size: 22px;
                  }
                  @include r(900) {
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
