import {
  SET_BOOKING_DATA_TO_STORE
} from '../mutation-types';

import {
  CREATE_BOOKING,
  GET_BOOKING
} from '../action-types';

import axios from "axios/index";
import * as CONFIG from '@/config.js';
import {
  empty
} from '@/helpers.js';

export default {
  state: {
    booking: {
      booking_id: null,
      practitioner_id: null,
      services_ids: null,
      total_amount: null,
      date: null,
      start_time: null,
      location_type: null,
      address: {
        city: null,
        postcode: null,
        address: null,
        country_id: null,
        country_name: null,
        location: {
          lat: null,
          lng: null
        }
      }
    }
  },
  mutations: {
    [SET_BOOKING_DATA_TO_STORE]: (state, booking) => {
      state.booking.booking_id = booking.booking_id;
      state.booking.practitioner_id = booking.practitioner_id;
      state.booking.services_ids = booking.services_ids;
      state.booking.total_amount = booking.total_amount;
      state.booking.date = booking.date;
      state.booking.start_time = booking.start_time;
      state.booking.location_type = booking.location_type;
      state.booking.address = booking.address;
    }
  },
  actions: {
    [CREATE_BOOKING]: (context, booking) => {
      return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${CONFIG.API_URL}/booking`,
            data: JSON.stringify(booking),
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_BOOKING]: (context, booking_id) => {
      return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${CONFIG.API_URL}/booking/${booking_id}`,
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
          })
          .then((response) => {
            let booking;

            if (!empty(Object.keys(response.data)[0])) {
              booking = response.data[Object.keys(response.data)[0]];
              booking['location'] = response.data['location'];
            } else {
              booking = [];
            }

            resolve(booking);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  getters: {
    booking(state) {
      return state.booking;
    }
  }
}