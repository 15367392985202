<template>
  <div class="about-us">
    <div class="about-us__wrapper">
      <div class="about-us__content">
        <div class="about-us__title">
          <strong>{{$t('about_us.freely')}}</strong>
          <span>{{$t('about_us.adverb')}}</span>
          <p>{{$t('about_us.not_under_the_control')}}
            <b>{{$t('about_us.i_work_freely')}}</b>
          </p>
        </div>
        <div class="about-us__holder">
          <div class="about-us__navigation">
            <div class="about-us__buttons">
              <div
                class="about-us__button"
                :class="{'is-active': aboutUs}"
                @click="switchTabs('aboutUs')"
              >
                {{$t('about_us.our_story_button')}}
              </div>
              <div
                class="about-us__button"
                :class="{'is-active': outTeam}"
                @click="switchTabs('outTeam')"
              >
                {{$t('about_us.meet_the_team')}}
              </div>
              <div
                class="about-us__button"
                :class="{'is-active': contact}"
                @click="switchTabs('contact')"
              >
                {{$t('about_us.contact')}}
              </div>
            </div>
          </div>
          <div class="about-us__bodies">
            <div
              class="about-us__body is-about"
              v-if="aboutUs"
            >
              <div class="about-us__body-title about-us__body-title--mob">
                {{$t('about_us.our_story_tab_title')}}
              </div>
              <div class="about-us__body-content">
                <div class="about-us__body-text about-us__body-text--str">
                  {{$t('about_us.our_story_tab_description')}}
                </div>
                <div class="about-us__body-strong">
                  {{$t('about_us.our_story_tab_paragraph_title_1')}}
                </div>
                <div class="about-us__body-text" v-html="$t('about_us.our_story_tab_paragraph_description_1')">
                </div>
                <div class="about-us__body-strong">
                  {{$t('about_us.our_story_tab_paragraph_title_2')}}
                </div>
                <div class="about-us__body-text" v-html="$t('about_us.our_story_tab_paragraph_description_2')">
                </div>
                <div class="about-us__body-strong" v-html="$t('about_us.our_story_tab_paragraph_title_3')">
                </div>
                <div class="about-us__body-text"  v-html="$t('about_us.our_story_tab_paragraph_description_3')">
                </div>
              </div>
            </div>
            <div
              class="about-us__body is-team"
              v-if="outTeam"
            >
              <div class="about-us__body-title">
                {{$t('about_us.our_team_tab_title')}}
              </div>
              <div class="about-us__body-content">
                <div class="about-us__body-items">
                  <div class="about-us__body-item">
                    <div class="about-us__body-photo">
                      <img
                              src="../../src/images/pages/aboutus/nick.jpeg"
                              alt=""
                      >
                    </div>
                    <div class="about-us__body-name">
                      {{$t('about_us.our_team_tab_name_1')}}
                    </div>
                    <div class="about-us__body-position">
                      {{$t('about_us.our_team_tab_position_1')}}
                    </div>
                    <div class="about-us__body-description">
                      {{$t('about_us.our_team_tab_description_1')}}
                    </div>
                  </div>
                  <div class="about-us__body-item">
                    <div class="about-us__body-photo">
                      <img
                              src="../../src/images/pages/aboutus/olly.png"
                              alt=""
                      >
                    </div>
                    <div class="about-us__body-name">
                      {{$t('about_us.our_team_tab_name_2')}}
                    </div>
                    <div class="about-us__body-position">
                      {{$t('about_us.our_team_tab_position_2')}}
                    </div>
                    <div class="about-us__body-description">
                      {{$t('about_us.our_team_tab_description_2')}}
                    </div>
                  </div>
                  <div class="about-us__body-item">
                    <div class="about-us__body-photo">
                      <img
                              src="../../src/images/pages/aboutus/aaron.jpeg"
                              alt=""
                      >
                    </div>
                    <div class="about-us__body-name">
                      {{$t('about_us.our_team_tab_name_3')}}
                    </div>
                    <div class="about-us__body-position">
                      {{$t('about_us.our_team_tab_position_3')}}
                    </div>
                    <div class="about-us__body-description">
                      {{$t('about_us.our_team_tab_description_3')}}
                    </div>
                  </div>
                </div>
                <div class="about-us__body-items">
                  <div class="about-us__body-item">
                    <div class="about-us__body-photo">
                      <img
                              src="../../src/images/pages/aboutus/melania.png"
                              alt=""
                      >
                    </div>
                    <div class="about-us__body-name">
                      {{$t('about_us.our_team_tab_name_4')}}
                    </div>
                    <div class="about-us__body-position">
                      {{$t('about_us.our_team_tab_position_4')}}
                    </div>
                    <div class="about-us__body-description">
                      {{$t('about_us.our_team_tab_description_4')}}
                    </div>
                  </div>
                  <div class="about-us__body-item">
                    <div class="about-us__body-photo">
                      <img
                              src="../../src/images/pages/aboutus/tom.png"
                              alt=""
                      >
                    </div>
                    <div class="about-us__body-name">
                      {{$t('about_us.our_team_tab_name_5')}}
                    </div>
                    <div class="about-us__body-position">
                      {{$t('about_us.our_team_tab_position_5')}}
                    </div>
                    <div class="about-us__body-description">
                      {{$t('about_us.our_team_tab_description_5')}}
                    </div>
                  </div>
                  <div class="about-us__body-item">
                    <div class="about-us__body-photo">
                      <img
                              src="../../src/images/pages/aboutus/izzy.png"
                              alt=""
                      >
                    </div>
                    <div class="about-us__body-name">
                      {{$t('about_us.our_team_tab_name_6')}}
                    </div>
                    <div class="about-us__body-position">
                      {{$t('about_us.our_team_tab_position_6')}}
                    </div>
                    <div class="about-us__body-description">
                      {{$t('about_us.our_team_tab_description_6')}}
                    </div>
                  </div>
                </div>
                <div class="about-us__body-items">
                  <div class="about-us__body-item">
                    <div class="about-us__body-photo">
                      <img
                              src="../../src/images/pages/aboutus/kevin.jpg"
                              alt=""
                      >
                    </div>
                    <div class="about-us__body-name">
                      {{$t('about_us.our_team_tab_name_7')}}
                    </div>
                    <div class="about-us__body-position">
                      {{$t('about_us.our_team_tab_position_7')}}
                    </div>
                    <div class="about-us__body-description">
                      {{$t('about_us.our_team_tab_description_7')}}
                    </div>
                  </div>
                  <div class="about-us__body-item">
                    <div class="about-us__body-photo">
                      <img
                              src="../../src/images/pages/aboutus/graeme.jpg"
                              alt=""
                      >
                    </div>
                    <div class="about-us__body-name">
                      {{$t('about_us.our_team_tab_name_8')}}
                    </div>
                    <div class="about-us__body-position">
                      {{$t('about_us.our_team_tab_position_8')}}
                    </div>
                    <div class="about-us__body-description">
                      {{$t('about_us.our_team_tab_description_8')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="about-us__body is-contacts"
              v-if="contact"
            >
              <div class="about-us__body-title">
                {{$t('about_us.contact_tab_title')}}
              </div>
              <div class="about-us__body-content">
                <div class="about-us__body-contacts">
                  <div class="about-us__body-form">
                    <v-form
                      ref="contactForm"
                      v-model="isContactFormValid"
                      lazy-validation
                    >
                      <div class="about-us__body-form-item">
                        <v-text-field
                          class="solo-field-border-gray"
                          name="name"
                          :placeholder="$t('about_us.placeholder_name')"
                          type="text"
                          :rules="nameRules"
                          :validate-on-blur="true"
                          v-model="name"
                          :error-messages="nameErrorMess"
                          @focus="clearErrorMess('nameErrorMess')"
                          solo
                          required
                        ></v-text-field>
                      </div>
                      <div class="about-us__body-form-item">
                        <v-text-field
                          class="solo-field-border-gray"
                          name="email"
                          :placeholder="$t('about_us.placeholder_email')"
                          type="email"
                          :rules="emailRules"
                          :validate-on-blur="true"
                          v-model="email"
                          :error-messages="emailErrorMess"
                          @focus="clearErrorMess('emailErrorMess')"
                          solo
                          required
                        ></v-text-field>
                      </div>
                      <div class="about-us__body-form-item">
                        <v-text-field
                          class="solo-field-border-gray"
                          name="subject"
                          :placeholder="$t('about_us.placeholder_subject')"
                          type="text"
                          :rules="subjectRules"
                          :validate-on-blur="true"
                          v-model="subject"
                          :error-messages="subjectErrorMess"
                          @focus="clearErrorMess('subjectErrorMess')"
                          solo
                          required
                        ></v-text-field>
                      </div>
                      <div class="about-us__body-form-item">
                        <v-textarea
                          class="solo-textarea-border-gray"
                          :solo="true"
                          :flat="true"
                          :full-width="true"
                          :placeholder="$t('about_us.placeholder_message')"
                          name="message"
                          rows="10"
                          v-model="message"
                          :rules="messageRules"
                          :validate-on-blur="true"
                          :error-messages="messageErrorMess"
                          @focus="clearErrorMess('messageErrorMess')"
                        ></v-textarea>
                      </div>
                      <div class="about-us__body-form-item">
                        <v-btn
                          class="error"
                          @click="submitForm()"
                          :disabled="!isContactFormValid || isHasErrorMess"
                        >
                          {{$t('about_us.submit')}}
                        </v-btn>
                      </div>
                    </v-form>
                  </div>
                  <div class="about-us__body-info">
                    <div class="about-us__body-info-map"></div>
                    <div class="about-us__body-info-items">
                      <div class="about-us__body-info-item about-us__body-info-item--lg">
                        <strong>
                          {{$t('about_us.london_office')}}
                        </strong>
                        <span>
                            {{$t('about_us.london_office_address')}}
                        </span>
                      </div>
                      <!--<div class="about-us__body-info-item about-us__body-info-item--md">
                        <strong>
                          {{$t('about_us.phone_number')}}
                        </strong>
                        <span>
                          <a href="tel:020 1234 5678">020 1234 5678</a>
                        </span>
                      </div>-->
                      <div class="about-us__body-info-item about-us__body-info-item--md">
                        <strong>
                          {{$t('about_us.email_address')}}
                        </strong>
                        <span>
                          <a href="mailto:hello@freelywellness.com">hello@freelywellness.com</a>
                        </span>
                      </div>
                      <div class="about-us__body-info-item about-us__body-info-item--md">
                        <strong>
                          {{$t('about_us.social_media')}}
                        </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/freelywellness/" target="_blank"><div class="icon-facebook icon-facebook--black"></div></a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/freelywellness/" target="_blank"><div class="icon-instagram icon-instagram--black"></div></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as CONFIG from '@/config.js'
import { empty } from '@/helpers.js';

export default {
  name: "HowItWorks",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | About Us'
  },
  data () {
    return {
        bgImage: require("../../src/images/pages/aboutus/izzy.jpeg"),
        aboutUs: true,
      outTeam: false,
      contact: false,

      isContactFormValid: true,

      name: '',
      nameRules: [v => !!v || "Name is required"],
      nameErrorMess: [],

      email: '',
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      emailErrorMess: [],

      subject: '',
      subjectRules: [v => !!v || "Subject is required"],
      subjectErrorMess: [],

      message: '',
      messageRules: [v => !!v || "Message is required"],
      messageErrorMess: [],
    }
  },
  computed: {
    isHasErrorMess () {
      return !(empty(this.nameErrorMess) && empty(this.emailErrorMess) && empty(this.subjectErrorMess) && empty(this.messageErrorMess));
    }
  },
  methods: {
    switchTabs (tab) {
      this.aboutUs = false;
      this.outTeam = false;
      this.contact = false;
      this[tab] = true;
    },
    submitForm () {
      if (this.$refs.contactForm.validate() && !this.isHasErrorMess) {
        const data = {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message
        };

        axios({
          method: 'post',
          url: `${CONFIG.API_URL}/contact`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
          .then(() => {
            this.clearFormFields();
            this.$store.commit('SET_NOTIFICATION', {
              text: 'Your request has been successfully sent.',
              color: 'error'
            });
          })
          .catch((error) => {
            if (!empty(error.response.data.errors)) {
              this.fieldsErrorFromServer(error.response.data.errors);
            }
          });
      }
    },
    fieldsErrorFromServer (errors) {
      if (!empty(errors)) {
        for (let field in errors) {
          for (let i = 0; i < errors[field].length; i++) {
            this[`${field}ErrorMess`].push(errors[field][i]);
          }
        }
      }
    },
    clearErrorMess (field) {
      this[field] = [];
    },
    clearFormFields () {
      this.name = '';
      this.email = '';
      this.subject = '';
      this.message = '';
    }
  }
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.about-us {
  &__wrapper {
    max-width: 1580px;
    margin: 0 auto;
    padding: 0 24px;

    @include r(1700) {
      max-width: 1440px;
    }

    @include r(1500) {
      max-width: 1240px;
    }

    @include r(1400) {
      max-width: 1140px;
    }
  }

  &__content {
    padding: 150px 0 220px 0;

    @include r(1700) {
      padding: 120px 0 200px 0;
    }
    @include r(1500) {
      padding: 70px 0 160px 0;
    }
    @include r(1000) {
      padding: 50px 0 100px 0;
    }

    @include r(700) {
      padding: 50px 0 70px 0;
    }
  }

  &__title {
    display: block;
    text-align: center;
    padding-bottom: 70px;
    border-bottom: 1px solid #b2b2b2;

    @include r(1700) {
      padding-bottom: 50px;
    }

    @include r(1500) {
      padding-bottom: 45px;
    }
    @include r(900) {
      padding-bottom: 25px;
    }

    @include r(700) {
      padding-bottom: 18px;
      border-bottom: 0;
      position: relative;

      &::after {
        bottom: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: #b2b2b2;
        position: absolute;
        content: "";
      }
    }

    strong {
      display: inline-block;
      margin-left: 16px;
      color: #272727;
      font-family: "Berkshire Swash";
      font-size: 61px;
      font-weight: 400;
      line-height: 1.2;

      @include r(1700) {
        font-size: 55px;
      }
      @include r(1500) {
        font-size: 45px;
      }
      @include r(1300) {
        font-size: 44px;
      }
      @include r(700) {
        font-size: 38px;
      }
    }

    span {
      display: block;
      margin-top: 30px;
      color: #47434d;
      font-size: 20px;
      font-weight: 300;
      font-style: italic;

      @include r(1700) {
        font-size: 19px;
        margin-top: 20px;
      }
      @include r(1500) {
        font-size: 18px;
        margin-top: 15px;
      }
      @include r(1400) {
        font-size: 17px;
      }
      @include r(850) {
        font-size: 16px;
      }
      @include r(550) {
        font-size: 15px;
      }
    }
    p {
      display: block;
      margin: 20px 0 0 0;
      color: #47434d;
      font-size: 40px;
      font-weight: 300;
      line-height: 1.4;

      @include r(1700) {
        font-size: 32px;
        margin-top: 18px;
      }
      @include r(1500) {
        font-size: 26px;
        margin-top: 15px;
      }
      @include r(1400) {
        font-size: 24px;
      }
      @include r(850) {
        font-size: 19px;
      }

      b {
        display: block;
        margin-top: 20px;
        font-weight: 400;

        @include r(1700) {
          margin-top: 17px;
        }

        @include r(1500) {
          margin-top: 15px;
        }
      }
    }
  }

  &__holder {
    display: block;
    margin-top: 75px;

    @include r(1700) {
      margin-top: 65px;
    }
    @include r(1500) {
      margin-top: 55px;
    }
    @include r(700) {
      margin-top: 37px;
    }
  }

  &__navigation {
    display: block;
    max-width: 1140px;
    margin: 0 auto;

    @include r(1400) {
      padding: 0 40px;
    }

    @include r(1000) {
      padding: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin: 0 -20px;

    @include r(1000) {
      margin: 0 -20px;
    }
    @include r(650) {
      align-items: stretch;
    }
  }

  &__button {
    flex: 0 0 calc(33.3333333% - 40px);
    margin: 0 20px;
    height: 74px;
    border-radius: 10px;
    border: 4px solid #b2b2b2;
    color: #47434d;
    font-size: 20px;
    font-weight: 700;
    line-height: 68px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    @include r(1000) {
      flex: 0 0 calc(33.3333333% - 20px);
      margin: 0 10px;
    }

    @include r(1700) {
      height: 70px;
      line-height: 62px;
    }

    @include r(1500) {
      height: 60px;
      font-size: 18px;
      line-height: 52px;
    }

    @include r(900) {
      height: auto;
      padding: 16px 7px;
      font-size: 16px;
      line-height: 1;
      border-width: 1px;
    }
    @include r(650) {
      flex: 0 0 calc(33.3333333% - 10px);
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
    }

    &:hover,
    &.is-active {
      background-color: #b2b2b2;
      color: #fff;
      transition: 0.3s;
    }
  }

  &__bodies {
    display: block;
    margin: 70px auto 0 auto;

    @include r(1700) {
      margin-top: 50px;
    }
    @include r(1500) {
      margin-top: 40px;
    }
    @include r(700) {
      position: relative;
      margin-top: 35px;
    }
  }

  &__body {
    &-title {
      display: block;
      text-align: center;
      color: #47434d;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.2;

      @include r(1700) {
        font-size: 32px;
      }
      @include r(1500) {
        font-size: 28px;
      }
      @include r(1400) {
        font-size: 25px;
      }
      @include r(900) {
        font-size: 22px;
      }

      &--mob {
        @include r(700) {
          display: none;
        }
      }
    }

    &-content {
      display: block;
      margin-top: 50px;

      @include r(1700) {
        margin-top: 40px;
      }
      @include r(1500) {
        margin-top: 30px;
      }
      @include r(1300) {
        margin-top: 25px;
      }
    }

    &.is-about {
      padding: 0 85px;
      @include r(1400) {
        padding: 0 40px;
      }
      @include r(1000) {
        padding: 0;
      }
      .about-us {
        &__body {
          &-text {
            display: block;
            color: #47434d;
            font-size: 25px;
            font-weight: 400;
            line-height: 1.2;
            margin-top: 25px;
            @include r(1700) {
              font-size: 23px;
              margin-top: 22px;
            }
            @include r(1500) {
              font-size: 19px;
              margin-top: 18px;
            }
            @include r(1400) {
              font-size: 18px;
            }
            @include r(850) {
              font-size: 17px;
            }
            @include r(700) {
              font-weight: 300;
            }

            &--str {
              @include r(700) {
                font-weight: 700;
              }
            }
          }

          &-strong {
            display: block;
            margin-top: 50px;
            color: #47434d;
            font-size: 30px;
            font-weight: 700;
            line-height: 1.2;

            @include r(1700) {
              font-size: 25px;
              margin-top: 40px;
            }
            @include r(1500) {
              font-size: 23px;
              margin-top: 35px;
            }
            @include r(1400) {
              font-size: 21px;
              margin-top: 30px;
            }
            @include r(850) {
              font-size: 19px;
            }
            @include r(700) {
              text-align: center;
            }
          }
        }
      }
    }

    &.is-team {
      padding: 0 75px;

      @include r(1200) {
        padding: 0 40px;
      }
      @include r(1000) {
        padding: 0;
      }
      .about-us {
        &__body {
          &-items {
            display: flex;
            justify-content: center;
            margin: 0 -42px;
            @include r(1500) {
              margin: 0 -30px;
            }
            @include r(1300) {
              margin: 0 -25px;
            }

            @include r(1200) {
              margin: 0 -20px;
            }
            @include r(830) {
              flex-wrap: wrap;
            }
          }

          &-item {
            flex: 0 0 33.3333333%;
            padding: 42px;
            display: block;
            text-align: center;

            @include r(1700) {
              padding-top: 30px;
            }
            @include r(1500) {
              padding: 20px 30px 30px 30px;
            }
            @include r(1300) {
              padding: 20px 25px 30px 25px;
            }
            @include r(1200) {
              padding: 20px;
            }
            @include r(830) {
              flex: 0 0 50%;
            }
            @include r(530) {
              flex: 0 0 100%;
            }
          }
          &-photo {
            display: block;
            margin: 0 auto;
            width: 286px;
            height: 286px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }

            @include r(1700) {
              width: 250px;
              height: 250px;
            }

            @include r(1500) {
              width: 230px;
              height: 230px;
            }

            @include r(1400) {
              width: 200px;
              height: 200px;
            }

            @include r(1300) {
              width: 190px;
              height: 190px;
            }
            @include r(1000) {
              width: 170px;
              height: 170px;
            }

            img {
              max-width: 100%;
            }
          }
          &-name {
            display: block;
            margin-top: 30px;
            color: #47434d;
            font-size: 30px;
            font-weight: 700;
            line-height: 1.2;

            @include r(1700) {
              font-size: 25px;
              margin-top: 26px;
            }
            @include r(1500) {
              font-size: 23px;
              margin-top: 23px;
            }
            @include r(1400) {
              font-size: 22px;
              margin-top: 18px;
            }
            @include r(850) {
              font-size: 19px;
            }
            @include r(700) {
              font-size: 17px;
            }
          }

          &-position {
            display: block;
            margin-top: 14px;
            color: #47434d;
            font-size: 30px;
            font-weight: 400;
            line-height: 1.2;

            @include r(1700) {
              font-size: 25px;
              margin-top: 13px;
            }
            @include r(1500) {
              font-size: 23px;
              margin-top: 12px;
            }
            @include r(1400) {
              font-size: 21px;
              margin-top: 10px;
            }
            @include r(850) {
              font-size: 19px;
            }
            @include r(700) {
              font-size: 17px;
            }
          }
          &-description {
            display: block;
            margin-top: 14px;
            color: #47434d;
            font-size: 25px;
            font-weight: 300;
            line-height: 1.2;
            text-align: center;

            @include r(1700) {
              font-size: 23px;
              margin-top: 13px;
            }
            @include r(1500) {
              font-size: 19px;
              margin-top: 12px;
            }
            @include r(1400) {
              font-size: 18px;
              margin-top: 10px;
            }
            @include r(700) {
              font-size: 17px;
            }
          }
        }
      }
    }
    &.is-contacts {
      padding: 0 15px;
      @include r(1200) {
        padding: 0 40px;
      }
      @include r(1000) {
        padding: 0;
      }
      .about-us {
        &__body {
          &-contacts {
            display: flex;
            justify-content: space-between;

            @include r(1700) {
              justify-content: center;
            }
            @include r(750) {
              display: block;
            }
          }

          &-content {
            margin-top: 70px;

            @include r(1700) {
              margin-top: 50px;
            }
            @include r(1500) {
              margin-top: 30px;
            }
          }
          &-form {
            flex: 0 0 auto;
            max-width: 640px;
            width: 100%;

            .v-form {
              margin-top: 0;
            }

            @include r(1700) {
              max-width: 600px;
            }

            @include r(1400) {
              max-width: 550px;
            }

            @include r(1200) {
              max-width: 50%;
            }
            @include r(750) {
              max-width: 100%;
            }
            @include r(700) {
              background-color: #fff;
              padding: 30px 20px 20px 20px;
              border-radius: 4px;
            }
            @include r(450) {
              padding: 20px 12px 12px 12px;
            }
            &-item {
              display: block;
              margin-bottom: 40px;
              text-align: center;

              @include r(1700) {
                margin-bottom: 30px;
              }

              @include r(1500) {
                margin-bottom: 20px;
              }

              .solo-field-border-gray {
                .v-input__slot {
                  border: 3px solid #b2b2b2;
                  border-radius: 10px;
                  padding: 0 70px;

                  @include r(1700) {
                    height: 60px;
                    padding: 0 50px;
                  }
                  @include r(1500) {
                    height: 55px;
                    padding: 0 30px;
                  }
                  @include r(700) {
                    background-color: #f7f7f7;
                    border: 0;
                    height: 46px;
                  }
                }
              }

              .solo-textarea-border-gray {
                .v-input__slot {
                  border: 3px solid #b2b2b2;
                  border-radius: 10px;
                  padding: 20px 70px;

                  @include r(1700) {
                    padding: 16px 50px;
                    height: 200px;
                  }
                  @include r(1500) {
                    padding: 12px 30px;
                    height: 180px;
                  }
                  @include r(700) {
                    background-color: #f7f7f7;
                    border: 0;
                    height: 140px;
                  }
                }
              }

              input {
                &::placeholder {
                  color: #47434d;
                }
              }
              textarea {
                resize: none;
                height: 100%;
                &::placeholder {
                  color: #47434d;
                }
              }
              button {
                display: inline-block;
                max-width: 450px;
                width: 100%;
                height: 75px;
                border-radius: 10px;
                color: #fff;
                font-family: Nunito;
                font-size: 23px;
                font-weight: 700;
                margin: 0;

                @include r(1700) {
                  max-width: 420px;
                  height: 70px;
                  font-size: 21px;
                }

                @include r(1500) {
                  max-width: 320px;
                  height: 60px;
                  font-size: 18px;
                }

                @include r(900) {
                  max-width: 300px;
                  height: 55px;
                  font-size: 16px;
                  border-radius: 4px;
                }

                @include r(450) {
                  max-width: 265px;
                  height: 50px;
                  font-size: 15px;
                }
              }
            }
          }
          &-info {
            flex: 0 0 auto;
            width: 100%;
            max-width: 540px;

            @include r(1700) {
              padding-left: 50px;
            }
            @include r(1400) {
              max-width: 450px;
            }
            @include r(1200) {
              max-width: 50%;
            }
            @include r(750) {
              max-width: 100%;
              padding: 30px 0 0 0;
            }
            &-map {
              display: block;
              margin: 0 auto;
              width: 268px;
              height: 268px;
              border-radius: 50%;
              //border: 7px solid #b2b2b2;
              background-image: url("../images/img/about-us-map.png");

              @include r(1700) {
                width: 250px;
                height: 250px;
              }

              @include r(1500) {
                width: 230px;
                height: 230px;
              }

              @include r(1400) {
                width: 200px;
                height: 200px;
              }

              @include r(1300) {
                width: 190px;
                height: 190px;
              }
              @include r(1000) {
                width: 170px;
                height: 170px;
              }
            }

            &-items {
              display: flex;
              flex-wrap: wrap;
              margin: 50px -20px 0 -20px;
              justify-content: center;

              @include r(1700) {
                margin-top: 40px;
              }
              @include r(1500) {
                margin: 30px -15px 0 -15px;
              }
            }

            &-item {
              display: inline-block;
              padding: 30px 20px;
              text-align: center;

              @include r(1700) {
                padding: 20px;
              }
              @include r(1500) {
                padding: 15px;
              }

              strong {
                display: block;
                color: #47434d;
                font-size: 30px;
                font-weight: 700;
                line-height: 1.2;
                @include r(1700) {
                  font-size: 23px;
                }
                @include r(1500) {
                  font-size: 21px;
                }
                @include r(1400) {
                  font-size: 21px;
                }
                @include r(850) {
                  font-size: 19px;
                }
              }

              span {
                display: block;
                margin-top: 10px;
                color: #47434d;
                font-size: 30px;
                font-weight: 400;
                @include r(1700) {
                  font-size: 23px;
                  margin-top: 8px;
                }
                @include r(1500) {
                  font-size: 21px;
                  margin-top: 6px;
                }
                @include r(1400) {
                  font-size: 21px;
                }
                @include r(850) {
                  font-size: 19px;
                }
                @include r(700) {
                  font-size: 17px;
                }
              }
              ul {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                padding: 0;

                li {
                  display: inline-block;
                  transition: 0.3s;
                  padding: 0 20px;

                  &:hover {
                    opacity: 0.7;
                    transition: 0.3s;
                  }
                }
              }

              &--md {
                flex: 0 0 50%;

                @include r(450) {
                  flex: 0 0 100%;
                }
              }

              &--lg {
                flex: 0 0 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
