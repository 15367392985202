<template>
  <v-container
    fluid
    grid-list-lg
    class="cards-container"
  >
    <div>
      <v-flex class="register-card search-container">
        <div class="search-container__middle">
          <ServicesSearchForm ref="servicesSearchForm"></ServicesSearchForm>
        </div>
      </v-flex>
    </div>
  </v-container>
</template>

<script>
import ServicesSearchForm from '@/components/PractitionerSearch/ServicesSearchForm'

export default {
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Services Search'
  },
  components: {
    ServicesSearchForm
  },
  beforeRouteUpdate(to, from, next) {
    const that = this;
    setTimeout((that)=>{
      that.$refs.servicesSearchForm.initSearchForm();
    },0,that);
    next();
  },
  mounted () {
    this.$scrollTo('.header.v-toolbar');
  }
}
</script>

<style scoped lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.card {
  cursor: pointer;
}

.cards-container {
  .v-card__title {
    justify-content: center;
    padding: 24px 16px 30px 16px;
  }
}

.search-container {
  position: relative;
  z-index: 1;
  width: 720px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px !important;
  background-image: linear-gradient(to right, #fff 0%, #fdfdfd 100%);
  display: block;
  margin: 46px auto;

  @include r(1500) {
    max-width: 600px;
    width: 100%;
  }

  &__middle {
    max-width: 380px;
    margin: 0 auto;
  }
}
</style>