<template>
  <v-card class="register-card login-form" :class="{'login-form-page': isLoginPage}">
    <v-card-text>
      <div class="profile-booking__close" v-if="!isLoginPage" @click="hide">
        <v-icon>close</v-icon>
      </div>
      <p class="login-form__top">
        {{$t('auth_forms.you_must_log_in')}}
      </p>
      <div class="login-form__middle">

        <v-form
          ref="form"
          v-model="formValid"
          lazy-validation
        >
          <v-text-field
            name="email"
            :label="$t('auth_forms.email')"
            type="email"
            :rules="emailRules"
            v-model="email"
            :error-messages="emailErrorMess"
            @focus="clearErrorMess('emailErrorMess')"
            @keyup.enter="onSubmit"
            required
            solo
            class="solo-field-white"
          ></v-text-field>

          <v-text-field
            name="password"
            :label="$t('auth_forms.password')"
            type="password"
            :rules="passwordRules"
            v-model="password"
            :error-messages="passwordErrorMess"
            @focus="clearErrorMess('passwordErrorMess')"
            @keyup.enter="onSubmit"
            required
            solo
            class="solo-field-white"
          ></v-text-field>
        </v-form>
        <v-card-actions>
          <v-btn
            block
            color="error"
            @click="onSubmit"
            :loading="loading"
          >
            {{$t('auth_forms.login')}}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card-text>

    <p class="login-form__bottom" v-if="isLoginPage && !showPractitionerRegistration">
      {{$t('auth_forms.do_not_have_an_account')}}
      <a href="#" @click.prevent="redirectToCustomerRegistration">{{$t('auth_forms.sign_up')}}</a>
      {{$t('auth_forms.now')}}
    </p>
    <p class="login-form__bottom" v-if="isLoginPage && showPractitionerRegistration">
      {{$t('auth_forms.do_not_have_an_account')}}
      <router-link :to="{ path: '/practitioner-registration' }">{{$t('auth_forms.sign_up')}}</router-link>
      {{$t('auth_forms.now')}}
    </p>
    <p class="login-form__bottom" v-if="!isLoginPage && !showPractitionerRegistration">
      {{$t('auth_forms.do_not_have_an_account')}}
      <a href="#" @click.prevent="switchAuthenticationModal">{{$t('auth_forms.sign_up')}}</a> {{$t('auth_forms.now')}}
    </p>

    <p class="login-form__bottom" v-if="!isLoginPage && showPractitionerRegistration && this.$router.currentRoute.name !== 'practitioner-registration'">
      {{$t('auth_forms.do_not_have_an_account')}}
      <router-link :to="{ path: '/practitioner-registration', force: true }">{{$t('auth_forms.sign_up')}}</router-link>
      {{$t('auth_forms.now')}}
    </p>
    <p class="login-form__bottom" v-if="!isLoginPage && showPractitionerRegistration && this.$router.currentRoute.name === 'practitioner-registration'">
      {{$t('auth_forms.do_not_have_an_account')}}
      <a href="#" @click.prevent="toggleModalAuth">{{$t('auth_forms.sign_up')}}</a> {{$t('auth_forms.now')}}
    </p>

    <p class="login-form__bottom" v-if="isLoginPage">
      <a href="#" @click.prevent="redirectToResetPasswordPage">
        {{$t('auth_forms.forgot_password_link')}}
      </a>
    </p>
    <p class="login-form__bottom" v-if="!isLoginPage">
      <a href="#" @click.prevent="showResetPasswordModal">
        {{$t('auth_forms.forgot_password_link')}}
      </a>
    </p>

  </v-card>
</template>
<script>
  import {empty} from '@/helpers.js';

  export default {
    props:['useInModal'],
    name: "Login",
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Sign In'
    },
    data() {
      return {
        formValid: false,

        email: "",
        emailRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_email_required'),
          v => /.+@.+/.test(v) || this.$i18n.t('auth_forms.js_error_email_format')
        ],
        emailErrorMess: [],

        password: "",
        passwordRules: [v => !!v || this.$i18n.t('auth_forms.js_error_password_required')],
        passwordErrorMess: [],

        practitionerRoutes:[
          'practitioner-dashboard',
          'practitioner-dashboard-calendar',
          'practitioner-dashboard-bookings',
          'practitioner-dashboard-services',
          'practitioner-dashboard-availability',
          'practitioner-dashboard-locations',
          'practitioner-dashboard-profile',
          'practitioner-dashboard-settings'
        ],
        role: ''
      }
    },
    beforeRouteUpdate(to, from, next) {
      if(!empty(to.params) && !empty(to.params.role)){
        this.role = to.params.role;
      }
      next();
    },
    mounted() {
      if(!empty(this.$route.params) && !empty(this.$route.params.role)){
        this.role = this.$route.params.role
      }
      if (this.isLoginPage) {
        this.$scrollTo('.header.v-toolbar');
      }
    },
    computed: {
      loading: function () {
        return (this.$store.getters.authStatus === 'loading') ? true : false;
      },
      isLoginPage: function () {
        if (!this.useInModal) {
          return true;
        } else {
          return false;
        }
      },
      showPractitionerRegistration() {
        if (this.practitionerRoutes.includes(this.$store.getters.targetPage) || (!empty(this.role) && this.role === 'practitioner')) {
          return true;
        } else {
          return false;
        }
      }
    },
    methods: {
      redirectToCustomerRegistration(){
        if(!empty(this.$store.getters.targetPage)){
          this.$store.commit('TOGGLE_NEED_AUTHORIZATION', true);
        }
        this.$router.push({path: '/customer-registration'});
      },
      redirectToResetPasswordPage(){
        this.$router.push({path: '/reset-password-request'});
      },
      hide() {
        this.$emit("hideLoginModal");
      },
      toggleModalAuth(){
        this.$scrollTo('.header.v-toolbar');
        this.$store.commit('TOGGLE_NEED_AUTHORIZATION', false);
        this.$store.commit('TOGGLE_MODAL_AUTHORIZATION', false);
      },
      showResetPasswordModal(){
        this.$emit('showResetPasswordModal');
      },
      switchAuthenticationModal(){
        this.$emit('switchAuthenticationModal')
      },
      clearErrorMess(field) {
        this[field] = [];
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      onSubmit() {
        const user_data = {'email': this.email, 'password': this.password};
        this.$store.dispatch('LOGIN_REQUEST', user_data)
          .then((response) => {
            this.$store.commit('SET_NOTIFICATION', {
              text: this.$i18n.t('auth_forms.welcome_notif'),
              color: 'error'
            });

            if (!empty(this.$store.getters.targetPage)) {
              // eslint-disable-next-line
              console.log('-------------------11');
              this.$router.replace({name: this.$store.getters.targetPage, params: this.$store.getters.params});
            } else {
              // eslint-disable-next-line
              console.log('-------------------22');
              if (response.data.user_roles.includes('practitioner')) {
                this.$router.push("/practitioner");
              } else if (response.data.user_roles.includes('admin')) {
                this.$router.push("/admin");
              } else {
                this.$router.push("/customer");
              }
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.fieldsErrorFromServer({'email': [''], 'password': [this.$i18n.t('auth_forms.js_error_invalid_password_or_email')]});
            }
            if (!empty(error.response.data.errors)) {
              this.fieldsErrorFromServer(error.response.data.errors);
            }
            this.$store.commit('AUTH_ERROR');
          });
      }
    }
  };
</script>

<style lang="scss">

  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .login-form {
    max-width: 720px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px !important;
    background-image: linear-gradient(to right, #fff 0%, #fdfdfd 100%);

    &-page {
      margin: 70px auto;

      @include r(1000) {
        margin: 50px auto;
      }
    }

    @include r(1500) {
      max-width: 600px;
      width: 100%;
    }
    &__top {
      display: block;
      text-align: center;
      color: #a3a3a3;
      font-family: Nunito;
      font-size: 15px;
      font-weight: 400;
    }

    &__middle {
      max-width: 380px;
      margin: 0 auto;

      .v-card__actions {
        padding: 0;
        margin-top: 20px;
      }
    }

    &__bottom {
      display: block;
      margin-top: 10px;
      text-align: center;
      color: #a3a3a3;
      font-family: Nunito;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.1;

      a {
        color: #535353;
        font-weight: 600;
        border-bottom: 1px solid transparent;
        text-decoration: none;
        transition: .3s;

        &:hover {
          border-bottom-color: #535353;
          transition: .3s;
        }
      }
    }
  }
</style>
