<template>
  <div>
    <div class="service">
      <p class="title text-xs-center text-sm-center text-md-center">
        {{$t('practitioner_registration.step_2_title')}}
      </p>
      <p class="main-text">
        {{$t('practitioner_registration.step_2_desc')}}
      </p>
      <div v-if="filteredList.length > 0">
        <v-tabs show-arrows>
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab
            v-for="item in filteredList"
            :href="'#tab-' + item.gid"
            :key="item.gid"
            @click="selectedMainService = item.gid; durationPrice = ''; searchService = ''; isTooltipItem = false; isTooltipSubItem = false;"
          >
            <v-popover
              trigger="manual"
              :open="isTooltipItem"
              offset="5"
              v-if="item.gid == 8"
              :autoHide="false"
              placement="top"
            >
              {{ item.name }}
              <template slot="popover">
                <span>
                  {{$t('practitioner_registration.step_2_select_category_services_pricing')}}
                </span>
                &nbsp;
                <span
                  @click.stop="isTooltipItem = false"
                  class="pointer"
                >
                 {{$t('practitioner_registration.step_2_ok')}}
                </span>
              </template>
            </v-popover>
            <span v-else>{{ item.name }}</span>
          </v-tab>

          <v-tabs-items>
            <v-tab-item
              v-for="item in filteredList"
              :value="'tab-' + item.gid"
              :key="item.gid"
            >
              <v-card flat>
                <v-card-text>
                  <div class="categories">
                    <div class="categories__search">
                      <div class="categories__search-icon">
                        <v-icon>search</v-icon>
                      </div>
                      <div class="categories__search-field">
                        <input
                          type="text"
                          :placeholder="$t('practitioner_registration.step_2_search_service')"
                          name
                          v-model="searchService"
                        >
                      </div>
                    </div>
                    <br>
                    <div class="categories__items">
                      <div
                        class="categories__item"
                        v-for="sub in item.sub_services"
                        :key="sub.sub_gid"
                        :class="{'is-active': sub.show}"
                      >
                        <div
                          class="categories__item-header"
                          @click="eventHandling(sub, $event)"
                        >
                          <div class="categories__item-header-lside">
                            <v-list-tile-action v-if="sub.price && !sub.show">
                              <v-icon>done</v-icon>
                            </v-list-tile-action>

                            <v-popover
                              trigger="manual"
                              :open="isTooltipSubItem"
                              offset="5"
                              v-if="sub.sub_gid == 1 && item.gid == 8"
                              popoverClass="categories__item-name"
                              :autoHide="false"
                            >
                              {{ sub.name }}
                              <template slot="popover">
                                <span v-html="$t('practitioner_registration.step_2_select_more_than_one')"></span>
                                &nbsp;
                                <span
                                  @click.stop="isTooltipSubItem = false"
                                  class="pointer"
                                >
                                  {{$t('practitioner_registration.step_2_ok')}}
                                </span>
                              </template>
                            </v-popover>
                            <div
                              v-else
                              class="categories__item-name"
                            >{{ sub.name }}</div>
                          </div>
                          <div class="categories__item-header-rside">
                            <div
                              v-if="sub.price && !sub.show"
                              class="categories__item-price"
                            >
                              {{$t('practitioner_registration.step_2_price')}} £{{sub.price}}
                            </div>
                            <div
                              class="categories__item-icon categories__item-icon--plus"
                              v-if="!sub.price || sub.show"
                            >
                            </div>
                            <div
                              v-else-if="!sub.show"
                              class="categories__item-btns"
                            >
                              <button class="categories__drop-edit edit-button">
                              </button>
                              <button class="categories__drop-delete remove-button">
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="categories__item-body">
                          <div class="categories__item-drop">
                            <div class="categories__item-body-top">
                              <div class="categories__drop-table">
                                <div class="categories__drop-tr">
                                  <div class="categories__drop-td categories__drop-td--hidd">
                                    <div class="categories__drop-time">{{$t('practitioner_registration.step_2_price')}}</div>
                                  </div>
                                  <div class="categories__drop-td">
                                    <div class="categories__drop-vl">£</div>
                                    <div class="categories__drop-field">
                                      <input
                                        type="number"
                                        min="0"
                                        step="0.5"
                                        name
                                        v-model="sub.rawPrice"
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="categories__drop-actions">
                                <v-btn
                                  class="categories__drop-save error"
                                  :disabled="!sub.rawPrice"
                                  @click="addPrice(sub)"
                                >
                                  <template v-if="!sub.price">
                                    {{$t('practitioner_registration.step_2_add_treatment')}}
                                  </template>
                                  <template v-else>
                                    {{$t('practitioner_registration.step_2_update_treatment')}}
                                  </template>
                                </v-btn>
                              </div>
                            </div>
                            <div class="categories__item-body-bottom">
                              <div class="categories__item-name">{{ sub.name }}</div>
                              <div class="categories__item-time"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          @click="sub.show = false"
                          class="categories__item-overlay"
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
      <div v-else>
        <v-progress-linear
          indeterminate
          color="error"
          height="3"
        ></v-progress-linear>
      </div>

      <div class="text-xs-center step-btn-main">
        <v-btn
          color="error"
          @click="storePractitionerServices()"
          :disabled="servicesIsSelected || beingProcessed"
        >
          {{$t('practitioner_registration.next')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as CONFIG from "@/config.js";
import { empty, isEmptyObj } from "@/helpers.js";

/* eslint-disable */

export default {
  data () {
    return {
      isTooltipItem: true,
      isTooltipSubItem: true,

      services: [],
      selectedMainService: 1,

      searchService: "",
      durationPrice: "",
      beingProcessed: false
    };
  },
  mounted () {
    axios
      .get(`${CONFIG.API_URL}/servicesWithSubServices`, {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
        }
      })
      .then(
        response => {
          response = this.prepareServices(response);
          this.services = response.data.sort(this.compareServicesName);
          this.$scrollTo('.header.v-toolbar');
        },
        error => {
          console.log(error.response.data);
          throw error;
        }
      )
      .catch(error => {
        console.log(error.response.data);
        throw error;
      });
  },
  computed: {
    filteredList () {
      let non_selected_categories = this.services.filter(item => {
        return item.id != this.selectedMainService;
      });

      let selected_categories = this.services.filter(item => {
        return item.id === this.selectedMainService;
      });

      if (selected_categories.length > 0) {
        let sub_services = {
          created_at: selected_categories[0].created_at,
          id: selected_categories[0].id,
          name: selected_categories[0].name,
          sub_services: selected_categories[0].sub_services.filter(item => {
            return item.name
              .toLowerCase()
              .includes(this.searchService.toLowerCase());
          }),
          updated_at: selected_categories[0].updated_at
        };
        non_selected_categories.splice(
          this.selectedMainService - 1,
          0,
          sub_services
        );
      }
      return this.humanSortingForServices(non_selected_categories);
    },
    selectedServicesList () {
      let selectedServicesList = {};
      for (let mainIndex in this.services) {
        for (let subIndex in this.services[mainIndex].sub_services) {
          if (!empty(this.services[mainIndex].sub_services[subIndex].price)) {
            selectedServicesList[this.services[mainIndex].sub_services[subIndex].id] = {
              'duration': this.services[mainIndex].sub_services[subIndex].default_duration,
              'price': this.services[mainIndex].sub_services[subIndex].price,
            };
          }
        }
      }
      return selectedServicesList;
    },
    servicesIsSelected () {
      return (!isEmptyObj(this.selectedServicesList)) ? false : true;
    }
  },
  methods: {
    humanSortingForServices(services) {
      let sub_services = [];
      for (let i = 0; i < services.length; i++) {
        sub_services = services[i].sub_services.sort(this.compareServicesName);
        services[i].sub_services = sub_services;
      }

      return services;
    },
    compareServicesName(a, b) {
      const a_number = parseFloat(a.name);
      const b_number = parseFloat(b.name);

      if (!isNaN(a_number) && !isNaN(b_number)) {
        return a_number - b_number;
      } else {
        return a.name.localeCompare(b.name)
      }
    },
    eventHandling (sub, event) {
      this.isTooltipItem = false;
      this.isTooltipSubItem = false;

      if (event.target.classList.contains('remove-button')) {
        this.removePrice(sub);
      } else if (event.target.classList.contains('edit-button')) {
        this.openItem(sub);
      } else {
        this.openItem(sub);
      }
    },
    openItem (sub) {
      for (let i = 0; i < this.services.length; i++) {
        let sub_services = this.services[i]['sub_services'];
        for (let k = 0; k < sub_services.length; k++) {
          if (sub_services[k]['sub_gid'] != sub.sub_gid) {
            sub_services[k]['show'] = false;
          }
        }
      }

      sub.show = (sub.show) ? false : true;
    },
    addPrice (sub) {
      sub.price = parseFloat(sub.rawPrice).toFixed(2);
      this.openItem(sub);
    },
    removePrice (sub) {
      sub.price = '';
      sub.rawPrice = '';
    },
    changeStep (step) {
      this.$emit("changeStep", step);
    },
    prepareServices (response) {
      let cat_id = 0;

      for (let id in response.data) {
        cat_id = cat_id + 1;
        response.data[id].gid = cat_id;

        let sub_gid = 0;
        for (let sub_id in response.data[id].sub_services) {
          sub_gid = sub_gid + 1;
          response.data[id].sub_services[sub_id]['sub_gid'] = sub_gid;

          response.data[id].sub_services[sub_id]['price'] = '';
          response.data[id].sub_services[sub_id]['rawPrice'] = '';
          response.data[id].sub_services[sub_id]['show'] = false;
        }
      }
      return response;
    },
    storePractitionerServices () {
      this.beingProcessed = true;
      axios({
        method: "post",
        url: `${CONFIG.API_URL}/practitioner/subservices`,
        data: JSON.stringify(this.selectedServicesList),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
        }
      })
        .then(
          response => {
            console.log(response);
            this.beingProcessed = false;
            this.changeStep(3);
          }
        )
        .catch(error => {
          this.beingProcessed = false;
          console.log(error.response.data);
          throw error;
        });
    }
  }
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.service {
  .services-list {
    margin-left: 100px;
    margin-right: 100px;
  }

  .step-btn-main {
    margin-top: 5px;
    @include r(700) {
      margin-top: 30px;
    }
  }

  .v-tabs {
    margin-top: 30px;

    &__container {
      height: 32px !important;
    }

    .v-card__text {
      @include r(700) {
        padding: 0;
      }
    }

    &__item {
      margin: 0 14px;
      padding: 0 50px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      background-image: linear-gradient(270deg, #ffffff 0%, #fdfdfd 100%);
      color: rgba(0, 0, 0, 0.87);
      font-size: 18px;
      font-weight: 700;
      text-transform: none;
      @include r(1400) {
        margin: 0 10px;
      }
      @include r(700) {
        margin: 0 5px;
        padding: 0 20px;
        font-size: 15px;
      }

      &--active {
        background-image: linear-gradient(270deg, #ff6162 0%, #ff5253 100%);
        color: #fff;
        opacity: 1;
      }
    }

    &__slider-wrapper {
      display: none;
    }
    &__wrapper {
      padding: 3px 0;
      margin: 0 60px;

      @include r(700) {
        margin: 0 46px;
      }
    }
    &__container {
      height: 28px;
    }
    &__icon--prev {
      padding: 0 5px;
      line-height: 32px;
      background-color: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      color: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      height: 32px;
      top: 3px;
      position: absolute;

      @include r(700) {
        left: 0;
      }
    }
    &__icon--next {
      padding: 0 5px;
      line-height: 32px;
      background-color: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      color: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      height: 32px;
      top: 3px;
      position: absolute;

      @include r(700) {
        right: 0;
      }
    }
  }

  .categories {
    display: block;
    padding: 20px 80px;

    @include r(1600) {
      padding: 20px 60px;
    }
    @include r(1400) {
      padding: 20px;
    }

    @include r(700) {
      padding: 30px 0 0 0;
    }

    &__search {
      display: flex;
      align-content: center;
      position: relative;
      max-width: 700px;
      margin: 0 auto;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        margin-right: 15px;

        i {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.7) !important;

          @include r(700) {
            font-size: 22px;
          }
        }

        @include r(700) {
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: none;
          margin: 0;
          height: 45px;
          width: 45px;
        }
      }

      &-field {
        display: block;
        max-width: 350px;
        width: 100%;
        height: 32px;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        border: 0;

        @include r(700) {
          height: 45px;
        }

        @include r(520) {
          max-width: 100%;
        }

        input {
          width: 100%;
          height: 100%;
          display: block;
          border: none;
          outline: none;
          padding: 0 30px;
          color: #535353;
          font-family: Nunito;
          font-size: 18px;
          background-color: transparent;
          font-weight: 400;

          @include r(700) {
          }

          @include r(700) {
            padding-left: 60px;
            font-size: 15px;
          }
        }
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style-type: none;
      padding: 10px 22px;
      text-align: left;
      width: 100%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      max-width: 700px;
      margin: 0 auto;

      @include r(959) {
        background-color: #fff;
        padding: 0;
      }
    }

    &__item {
      flex: 1 0 100%;
      outline: none;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      max-width: 100%;
      border-bottom: 1px solid #d8d8d8;

      @include r(959) {
        border-bottom: 0;
      }

      &:nth-child(even) {
        @include r(959) {
          background-color: #f9f9f9;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      &.is-active {
        .categories__item-body {
          display: block;
        }

        .categories__item-overlay {
          @include r(700) {
            display: block;
          }
        }

        .categories__item-drop {
          @include r(700) {
            position: fixed;
            max-width: 338px;
            top: 50%;
            left: 50%;
            z-index: 40;
            transform: translate(-50%, -50%);
          }
        }

        .categories__item-icon {
          .v-icon {
            transform: rotate(-180deg);
          }
        }
      }

      &:first-child {
        border-top: none !important;
      }

      &-header {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 14px 20px 14px 60px;
        min-height: 48px;
        @include r(700) {
          padding: 12px 20px 12px 48px;
        }
        .v-list__tile__action {
          min-width: 40px;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);

          @include r(700) {
            left: 14px;
            min-width: 30px;
          }
          .v-icon {
            color: #ff5354;
            font-size: 26px;

            @include r(700) {
              font-size: 22px;
            }
          }
        }

        &-lside {
          display: flex;
          align-items: center;
          flex: 1 1 auto;
          padding-right: 20px;
        }

        &-rside {
          display: flex;
          align-items: center;
          flex: 0 0 auto;
        }
      }

      &-btns {
        display: flex;
        align-items: center;
        margin-left: 20px;
      }

      &-icon {
        &--plus {
          width: 19px;
          height: 19px;
          background: {
            image: url("../../.././src/images/icons/icon-plus.svg");
            repeat: no-repeat;
            size: contain;
          }
          opacity: 1;
        }
      }

      &-time {
        display: inline-block;
        color: #a3a3a3;
        font-size: 20px;
        font-weight: 600;
      }

      &-name {
        display: inline-block;
        margin-right: 10px;
        color: #535353;
        font-size: 18px;
        font-weight: 400;

        @include r(700) {
          font-size: 15px;
          line-height: 1.2;
        }
      }

      &-price {
        color: #535353;
        font-size: 16px;
        font-weight: 400;
        @include r(700) {
          font-size: 15px;
        }
      }

      &-body {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        display: none;
        padding: 15px;

        @include r(700) {
          padding: 0;
        }

        &-top {
          @include r(700) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 8px 10px 40px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            background-color: #fff;
          }
        }

        &-bottom {
          display: none;
          margin-top: 6px;
          padding: 13px 40px;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          background-color: #fff;

          @include r(700) {
            display: block;
          }
        }
      }

      &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 35;
        display: none;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    &__add {
      display: block;

      &-btn {
        font-size: 17px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
        outline: none;
        margin-top: 2px;
      }
    }

    &__edit {
      display: none;
      align-items: center;

      &-btn {
        display: inline-block;
        outline: none;

        i {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }
      &-done {
        display: inline-block;
        margin-left: 3px;

        i {
          font-size: 16px;
          color: #ff5252 !important;
        }
      }
    }

    &__holder {
      position: relative;
      display: inline-block;
      margin-top: 25px;
    }
    &__drop {
      position: absolute;
      background-color: #fff;
      top: 0;
      left: calc(100% + 1px);
      width: 310px;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      padding: 10px 15px;

      &-settings {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
        display: block;
        text-align: center;
      }

      &-table {
        display: block;
      }

      &-actions {
        display: block;
        margin-top: 10px;
        text-align: center;
        @include r(700) {
          margin: 0 0 0 50px;
        }
      }

      &-vl {
        display: block;
        color: #535353;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
        margin-right: 3px;
      }

      &-field {
        display: block;
        width: 100%;
        max-width: 150px;

        input {
          display: block;
          width: 100%;
          height: 100%;
          outline: none;
          border: 0;
          text-align: center;
          padding: 0 12px;
          color: #535353;
          font-family: Nunito;
          font-size: 25px;
          font-weight: 400;
          border-bottom: 1px solid #e1e1e1;
          &::placeholder {
            color: #a3a3a3;
          }

          @include r(1700) {
            font-size: 21px;
          }
          @include r(1500) {
            font-size: 19px;
          }
          @include r(700) {
            font-size: 16px;
            max-width: 90px;
            min-width: 90px;
            padding: 0 8px;
          }
        }
      }

      &-cancel {
        font-size: 14px;
        color: #7b8994;
        font-weight: 500;
        margin-left: -12px;
        outline: none;

        &:hover {
          text-decoration: underline;
        }
      }
      &-save {
        min-width: 203px;
        height: 38px;
        border-radius: 4px;
        opacity: 1;
        margin: 0;

        @include r(700) {
          min-width: 115px;
          height: 43px;
          border-radius: 4px;
          font-size: 12px;
        }

        &.theme--light.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
          background-color: #ff5252 !important;
        }

        &.v-btn--disabled {
          background-color: #ff5252 !important;
          color: #fff !important;
          opacity: 0.4;
        }
      }

      &-edit {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: {
          image: url("../../.././src/images/icons/icon-edit.svg");
          repeat: no-repeat;
          size: contain;
        }
        outline: none;
        transition: 0.3s;

        @include r(1700) {
          width: 19px;
          height: 19px;
        }
        @include r(1500) {
          width: 17px;
          height: 17px;
        }
        @include r(700) {
          width: 15px;
          height: 15px;
        }

        &:hover {
          opacity: 0.7;
          transition: 0.3s;
        }
      }

      &-delete {
        display: inline-block;
        margin-left: 16px;
        width: 20px;
        height: 20px;
        background: {
          image: url("../../.././src/images/icons/icon-delete.svg");
          repeat: no-repeat;
          size: contain;
        }
        outline: none;
        transition: 0.3s;

        @include r(1700) {
          width: 19px;
          height: 19px;
        }
        @include r(1500) {
          width: 17px;
          height: 17px;
        }

        @include r(700) {
          width: 15px;
          height: 15px;
          margin-left: 8px;
        }
        @include r(500) {
          margin-left: 15px;
        }

        &:hover {
          opacity: 0.7;
          transition: 0.3s;
        }
      }

      &-tr {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        &:last-child {
          border-bottom: 0;
        }

        @include r(700) {
          display: block;
        }
      }
      &-td {
        flex: 0 0 50%;
        padding: 5px;
        text-align: center;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
          border-right: 0;
        }

        &--hidd {
          @include r(700) {
            display: none;
          }
        }
      }

      &-time {
        display: block;
        color: #535353;
        font-size: 23px;
        font-weight: 400;

        @include r(1700) {
          font-size: 21px;
        }
        @include r(1500) {
          font-size: 19px;
        }
        @include r(700) {
          font-size: 16px;
        }
      }
    }
  }
}

</style>
