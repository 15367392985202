<template>
  <div
    class="dashboard-content dashboard-booking"
    id="dashboard-content"
  >
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="dashboard-content__title">
      <h2>Practitioners</h2>
    </div>

    <div class="dashboard-content__body">
      <template>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Practitioner search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-card-title>



        <div class="datepicker-trigger">
          <input
                  type="text"
                  id="datepicker-trigger"
                  placeholder="Select dates"
                  :value="formatDates(dateOne, dateTwo)"
                  style="border:1px solid rgba(0, 0, 0, 0.2);padding:6px 10px"
          >

          <AirbnbStyleDatepicker
                  :trigger-element-id="'datepicker-trigger'"
                  :mode="'range'"
                  :fullscreen-mobile="true"
                  :date-one="dateOne"
                  :date-two="dateTwo"
                  @date-one-selected="val => { dateOne = val }"
                  @date-two-selected="val => { dateTwo = val }"
          />
        </div>
        <div>
          <v-btn
                  small
                  color="primary"
                  @click="downloadPractitionersXls"
          >Download Practitioners XLS
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="practitioners"
          :search="search"
          class="elevation-1"
          :loading="showProgress"
          :rows-per-page-items='[10,25,50,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
        >
          <template v-slot:items="props">
            <td>
              {{ props.item.user_id }}
            </td>
            <td>
              <v-avatar color="red lighten-4">
                <img
                  v-if="props.item.avatar"
                  :src="props.item.avatar"
                  alt="avatar"
                >
                <span
                  v-else
                  class="white--text headline"
                >{{ getAvatarLetters(props.item.first_name, props.item.last_name) }}</span>
              </v-avatar>
            </td>
            <td>{{ props.item.first_name }}</td>
            <td>{{ props.item.last_name }}</td>
            <td>{{ props.item.email }}</td>
            <td>
              <div>
                <v-btn
                  flat
                  small
                  color="error"
                  @click="openProfile(props.item.user_id)"
                >Open profile</v-btn>
              </div>
              <div>
                <v-btn
                  v-if="props.item.approved"
                  flat
                  small
                  color="error"
                  @click="approveProfile(props.item.user_id, props)"
                >Reject Approval</v-btn>
                <v-btn
                  v-else
                  flat
                  small
                  color="success"
                  @click="approveProfile(props.item.user_id, props)"
                >Approve</v-btn>
              </div>
            </td>
          </template>
          <v-alert
            v-slot:no-results
            :value="true"
            color="error"
            icon="warning"
          >
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import * as CONFIG from "@/config.js"
import format from 'date-fns/format'
import { empty } from '@/helpers.js';
import { vueTopprogress } from 'vue-top-progress'


/* eslint-disable */

export default {
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Admin: Practitioners'
  },
  components: {
    vueTopprogress
  },
  data () {
    return {
      showProgress: true,

      search: '',
      headers: [
        { text: 'User Id', value: 'user_id' },
        { text: 'Avatar', sortable: false },
        { text: 'First Name', value: 'first_name' },
        { text: 'Larst Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', sortable: false },
      ],
      practitioners: [],
      dateOne: '',
      dateTwo: '',
      dateFormat: 'D MMM',
    }
  },
  mounted () {
    this.getPractitioners().then(
      response => {
        this.practitioners = response.data;
        this.showProgress = false;
      },
      error => {
        console.log(error.response.data);

        this.$store.commit('SET_NOTIFICATION', {
          text: error.response.data.message,
          color: 'orange'
        });

        this.showProgress = false;
        throw error;
      }
    )
      .catch(error => {
        this.$router.push('/logout');

        console.log(error.response.data);
        this.showProgress = false;
        throw error;
      });
  },
  filters: {
  },
  methods: {
    formatDates(dateOne, dateTwo) {
      let formattedDates = '';
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat)
      }
      if (dateTwo) {
        formattedDates += ' - ' + format(dateTwo, this.dateFormat)
      }
      return formattedDates
    },
    downloadPractitionersXls() {
      if(empty(this.dateOne) || empty(this.dateTwo)) {
        this.$store.commit('SET_NOTIFICATION', {
          text: 'Please select dates before load report',
          color: 'orange'
        });

        return;
      }
      this.$refs.topProgress.start();
        axios({
          method: "get",
          url: `${CONFIG.API_URL}/dashboard/admin/getPractitioners?from=${this.dateOne}&to=${this.dateTwo}`,
          responseType: 'blob',
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken}`
          }
        }).then(
          response => {
            this.$refs.topProgress.done()
            let headers = response.headers;
            console.log(headers['content-type']);
            let blob = new Blob([response.data],{type:headers['content-type']});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "practitioners.xls";
            link.click();
          },
          error => {
            this.$refs.topProgress.done()
            this.$store.commit('SET_NOTIFICATION', {
               text: 'Something goes wrong',
               color: 'orange'
            });
            throw error;
          }
        )
        .catch(error => {
            this.$refs.topProgress.done()
            this.$store.commit('SET_NOTIFICATION', {
              text: 'Something goes wrong',
              color: 'orange'
            });
                  //console.log(error.response.data);
                  throw error;
        });
    },
    getAvatarLetters (first_name, last_name) {
      first_name = first_name ? first_name.charAt(0) : '';
      last_name = last_name ? last_name.charAt(0) : '';
      return `${first_name}${last_name}`;
    },
    getPractitioners () {
      return axios({
        method: "get",
        url: `${CONFIG.API_URL}/dashboard/admin/practitioners`,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
        }
      })
    },
    openProfile (user_id) {
      axios({
        method: "get",
        url: `${CONFIG.API_URL}/dashboard/admin/userLogIn/${user_id}`,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
        }
      }).then(
        response => {
          this.$router.push('/logout');

          setTimeout(() => {
            this.$store.commit('AUTH_SUCCESS', response.data.access_token);
            this.$store.commit('SET_CURRENT_USER_DATA', response.data);

            if (response.data.user_roles.includes('practitioner')) {
              this.$router.push("/practitioner/bookings");
            } else if (response.data.user_roles.includes('admin')) {
              this.$router.push("/admin");
            } else {
              this.$router.push("/customer/bookings");
            }
          }, 2000);
        },
        error => {
          console.log(error.response.data);
          this.$store.commit('SET_NOTIFICATION', {
            text: error.response.data.message,
            color: 'orange'
          });
          throw error;
        }
      )
        .catch(error => {
          console.log(error.response.data);
          throw error;
        });
    },
    approveProfile (user_id, props) {

      let approve = props.item.approved? 'reject': 'approve';
      axios({
        method: "post",
        url: `${CONFIG.API_URL}/practitioner-approval/${approve}/${user_id}`,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
          CONFIG.DEV_USER_TOKEN}`
        }
      })
      .then(
        response => {
          props.item.approved = response.data[0].approved;
        },
        error => {
          console.log(error.response.data);
          this.$store.commit('SET_NOTIFICATION', {
            text: error.response.data.message,
            color: 'orange'
          });
          throw error;
        }
      )
      .catch(error => {
        console.log(error.response.data);
        throw error;
      });
    }
  },
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.dashboard-content {
  display: block;
  flex: 1 1 auto;
  width: 100%;
  padding: 50px 120px 120px 100px;

  @include r(1700) {
    padding: 50px 100px 100px 80px;
  }

  @include r(1500) {
    padding: 40px 60px 60px 60px;
  }

  @include r(1300) {
    padding: 30px 40px 60px 40px;
  }
  @include r(1200) {
    padding: 28px 28px 40px 28px !important;
  }

  @include r(900) {
    padding: 28px 16px 20px 16px !important;
  }

  &__title {
    padding-bottom: 24px;

    @include r(1000) {
      padding-bottom: 0;
    }

    h2 {
      display: block;
      color: #383838;
      font-size: 37px;
      font-weight: 600;
      line-height: 1.1;

      @include r(1700) {
        font-size: 34px;
      }

      @include r(1500) {
        font-size: 32px;
      }

      @include r(1400) {
        font-size: 30px;
      }

      @include r(1300) {
        font-size: 28px;
      }

      @include r(1000) {
        display: none;
      }
    }
  }

  &__body {
    padding: 40px 22px 0 22px;
    position: relative;

    @include r(1200) {
      padding: 40px 0 0 0;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      height: 1px;
      left: 22px;
      width: calc(100% - 44px);
      background-color: #f7cecf;

      @include r(1200) {
        width: 100%;
        left: 0;
      }
      @include r(1000) {
        display: none;
      }
    }
  }

  .v-avatar {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
</style>
