import Vue from 'vue'
import Router from 'vue-router'

import Meta from 'vue-meta'
Vue.use(Meta)

import Home from '@/components/Home';
import HairDresserHome from '@/components/HairDresserHome';
import BeauticianHome from '@/components/BeauticianHome';
import MassageTherapistHome from '@/components/MassageTherapistHome';
import MaleGrooming from '@/components/MaleGrooming';

import PractitionerRegistration from '@/components/Auth/PractitionerRegistration'
import CustomerRegistration from '@/components/Auth/CustomerRegistration'
import Login from '@/components/Auth/Login'
import Logout from '@/components/Auth/Logout'

import AboutUs from '@/components/AboutUs'
import OurTeam from '@/components/OurTeam'
import Contacts from '@/components/Contacts'

import Support from '@/components/Support'
import JoinFreely from '@/components/JoinFreely'

import Services from '@/components/Services'

import Hygiene from '@/components/Hygiene'

import HowItWorks from '@/components/HowItWorks'

import PractitionerInformation from '@/components/PractitionerInformation/PractitionerInformation'

import ServicesSearch from '@/components/PractitionerSearch/ServicesSearch'
import SelectLocation from '@/components/PractitionerSearch/SelectLocation'
import PractitionerSearch from '@/components/PractitionerSearch/PractitionerSearch'

import PractitionerDashboard from '@/components/PractitionerDashboard/PractitionerDashboard'
import PractitionerDashboardDashboard from '@/components/PractitionerDashboard/PractitionerDashboardDashboard'
import PractitionerDashboardCalendar from '@/components/PractitionerDashboard/PractitionerDashboardCalendar'
import PractitionerDashboardBookings from '@/components/PractitionerDashboard/PractitionerDashboardBookings'
import PractitionerDashboardServices from '@/components/PractitionerDashboard/PractitionerDashboardServices'
import PractitionerDashboardAvailability from '@/components/PractitionerDashboard/PractitionerDashboardAvailability'
import PractitionerDashboardLocations from '@/components/PractitionerDashboard/PractitionerDashboardLocations'
import PractitionerDashboardProfile from '@/components/PractitionerDashboard/PractitionerDashboardProfile'
import PractitionerDashboardSettings from '@/components/PractitionerDashboard/PractitionerDashboardSettings'
import PractitionerDashboardInviteReviewers from '@/components/PractitionerDashboard/PractitionerDashboardInviteReviewers'

import CustomerDashboard from '@/components/CustomerDashboard/CustomerDashboard'
import CustomerDashboardDashboard from '@/components/CustomerDashboard/CustomerDashboardDashboard'
import CustomerDashboardBookings from '@/components/CustomerDashboard/CustomerDashboardBookings'
import CustomerDashboardWallet from '@/components/CustomerDashboard/CustomerDashboardWallet'
import CustomerDashboardProfile from '@/components/CustomerDashboard/CustomerDashboardProfile'
import CustomerDashboardSettings from '@/components/CustomerDashboard/CustomerDashboardSettings'
import CustomerDashboardReferral from '@/components/CustomerDashboard/CustomerDashboardReferral'
import CustomerDashboardRebook from '@/components/CustomerDashboard/CustomerDashboardRebook'

import CustomerDashboardReviews from '@/components/CustomerDashboard/CustomerDashboardReviews'

import AdminDashboard from '@/components/AdminDashboard/AdminDashboard'
import AdminDashboardPractitioners from '@/components/AdminDashboard/AdminDashboardPractitioners'
import AdminDashboardCustomers from '@/components/AdminDashboard/AdminDashboardCustomers'
import AdminDashboardBookings from '@/components/AdminDashboard/AdminDashboardBookings'
import AdminDashboardLoyaltySchemes from '@/components/AdminDashboard/AdminDashboardLoyaltySchemes'
import AdminDashboardStatistics from '@/components/AdminDashboard/AdminDashboardStatistics'


import SearchPractitionerAvailability from '@/components/PractitionerSearch/PractitionerProfileBooking'
import SearchPractitionerInformation from '@/components/PractitionerSearch/PractitionerInformation'
import Payment from '@/components/PractitionerSearch/Payment'
import CreateBooking from '@/components/PractitionerSearch/CreateBooking'
import PaymentSuccess from '@/components/PractitionerSearch/PaymentSuccess'

import SyncCalendar from '@/components/PractitionerInformation/SyncCalendar'

import Profile from '@/components/Profile'

import NotFound from '@/components/NotFound'

import InvitedClientsReview from '@/components/InvitedClients/InvitedClientsReview'

import ResetPasswordRequest from '@/components/Auth/ResetPasswordRequest'
import ResetPassword from '@/components/Auth/ResetPassword'

import {
  authenticate,
  modalAuthenticate,
  modalCustomerRegistration,
  validateBookingParams,
  checkUserSession,
  isNeedingRedirectAfterAuth,
  onlyForAdmin,
  onlyForCustomer,
  onlyForPractitioner,
  checkSearchParams,
  checkBookingStatus,
  checkRegistrationSteps,
  checkStepOnRegistrationFlow,
  checkCookiesBar,
  setUserForFullStory,
  checkBookingStatusBeforeReview,
  isValidToken
} from './middleware';

Vue.use(Router);

/* eslint-disable */

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/hair-dresser',
      name: 'hair-dresser-home',
      component: HairDresserHome
    },
    {
      path: '/beautician',
      name: 'beautician-home',
      component: BeauticianHome
    },
    {
      path: '/massage-therapist',
      name: 'massage-therapist-home',
      component: MassageTherapistHome
    },
    {
      path: '/male-grooming',
      name: 'male-grooming-home',
      component: MaleGrooming
    },
    {
      path: '/practitioner-registration',
      name: 'practitioner-registration',
      component: PractitionerRegistration
    },
    {
      path: '/customer-registration',
      name: 'customer-registration',
      component: CustomerRegistration
    },
    {
      path: '/login/:role?',
      name: 'login',
      component: Login,
      props: true
    },
    {
      path: '/logout',
      name: 'logout',
      components: {
        logout: Logout
      }
    },
    {
      path: '/reset-password-request',
      name: 'reset-password-request',
      component: ResetPasswordRequest,
      props: true
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: ResetPassword,
      props: true
    },
    {
      path: '/cookies-policy',
      name: 'cookies-policy',
      component: () => import(/* webpackChunkName: "cookies-policy" */ '../components/CookiesPolicy.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import(/* webpackChunkName: "privacy-policy" */ '../components/PrivacyPolicy.vue')
    },
    {
      path: '/terms-of-service',
      name: 'terms-of-service',
      component: () => import(/* webpackChunkName: "terms-of-service" */ '../components/TermsOfService.vue')
    },

    {
      path: '/about-us',
      name: 'about-us',
      component: AboutUs
    },
    {
      path: '/our-team',
      name: 'our-team',
      component: OurTeam
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts
    },

    {
      path: '/support',
      name: 'support',
      component: Support
    },

    {
      path: '/join-freely',
      name: 'join-freely',
      component: JoinFreely
    },

    {
      path: '/services',
      name: 'services',
      component: Services
    },
    {
      path: '/hygiene',
      name: 'hygiene',
      component: Hygiene
    },
    {
      path: '/how-it-works',
      name: 'how-it-works',
      component: HowItWorks,

      children: [
        {
          path: 'customer',
          name: 'how-it-works-customer',
          component: HowItWorks
        },
        {
          path: 'practitioner',
          name: 'how-it-works-practitioner',
          component: HowItWorks
        },
        {
          path: 'faq',
          name: 'how-it-works-faq',
          component: HowItWorks
        }
      ]
    },
    {
      path: '/practitioner-information/:currentStep?',
      name: 'practitioner-information',
      component: PractitionerInformation,
      props: true,
      beforeEnter: (to, from, next) => {
        checkStepOnRegistrationFlow({to, from})
          .then(() => next())
          .catch((reject) => {
            next(reject);
          })
      },
    },

    {
      path: '/services-search',
      name: 'services-search',
      component: ServicesSearch
    },
    {
      path: '/select-location',
      name: 'select-location',
      component: SelectLocation
    },
    {
      path: '/practitioner-search',
      name: 'practitioner-search',
      component: PractitionerSearch,
      beforeEnter: (to, from, next) => {
        checkSearchParams({to, from})
          .then(() => next())
          .catch((reject) => {
            next(reject);
          })
      },
      children: [{
        path: 'availability/:id/:withBookingState?',
        name: 'search-practitioner-availability',
        components: {
          search_practitioner_availability: SearchPractitionerAvailability
        },
        props: {
          search_practitioner_availability: true
        },
        children: [{
          path: '/create-booking',
          name: 'create-booking',
          components: {
            create_booking: CreateBooking
          },
          props: {
            create_booking: true
          },
          beforeEnter: (to, from, next) => {
            modalCustomerRegistration({to, from})
              .then((resolve) => validateBookingParams(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject)
              });
          }
        }]
      },
      {
        path: 'profile/:id',
        name: 'search-practitioner-information',
        components: {
          search_practitioner_information: SearchPractitionerInformation
        },
        props: {
          search_practitioner_information: true
        }
      }]
    },

    {
      path: '/practitioner',
      component: PractitionerDashboard,
      children: [
        {
          path: '',
          name: 'practitioner-dashboard',
          components: {
            practitioner_dashboard_dashboard: PractitionerDashboardDashboard
          },
          props: {
            practitioner_dashboard_dashboard: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'calendar',
          name: 'practitioner-dashboard-calendar',
          components: {
            practitioner_dashboard_calendar: PractitionerDashboardCalendar
          },
          props: {
            practitioner_dashboard_calendar: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'bookings',
          name: 'practitioner-dashboard-bookings',
          components: {
            practitioner_dashboard_bookings: PractitionerDashboardBookings
          },
          props: {
            practitioner_dashboard_bookings: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'services',
          name: 'practitioner-dashboard-services',
          components: {
            practitioner_dashboard_services: PractitionerDashboardServices
          },
          props: {
            practitioner_dashboard_services: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'availability',
          name: 'practitioner-dashboard-availability',
          components: {
            practitioner_dashboard_availability: PractitionerDashboardAvailability
          },
          props: {
            practitioner_dashboard_availability: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'locations',
          name: 'practitioner-dashboard-locations',
          components: {
            practitioner_dashboard_locations: PractitionerDashboardLocations
          },
          props: {
            practitioner_dashboard_locations: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'profile',
          name: 'practitioner-dashboard-profile',
          components: {
            practitioner_dashboard_profile: PractitionerDashboardProfile
          },
          props: {
            practitioner_dashboard_profile: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'settings',
          name: 'practitioner-dashboard-settings',
          components: {
            practitioner_dashboard_settings: PractitionerDashboardSettings
          },
          props: {
            practitioner_dashboard_settings: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'invite-reviewers',
          name: 'practitioner-dashboard-invite-reviewers',
          components: {
            practitioner_dashboard_invite_reviewers: PractitionerDashboardInviteReviewers
          },
          props: {
            practitioner_dashboard_invite_reviewers: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForPractitioner(resolve))
              .then((resolve) => checkRegistrationSteps(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        }
      ]
    },

    {
      path: '/customer',
      component: CustomerDashboard,
      children: [
        {
        path: '',
        name: 'customer-dashboard',
        components: {
          customer_dashboard_dashboard: CustomerDashboardDashboard
        },
        props: {
          customer_dashboard_dashboard: true
        },
        beforeEnter: (to, from, next) => {
          modalAuthenticate({to, from})
            .then((resolve) => onlyForCustomer(resolve))
            .then(() => next())
            .catch((reject) => {
              next(reject);
            });
        },
          children: [
            {
              path: '/rebook-booking/:booking_id/:booking_flow',
              name: 'customer-dashboard-rebook',
              components: {
                customer_dashboard_rebook: CustomerDashboardRebook
              },
              props: {
                customer_dashboard_rebook: true
              },
              children: [{
                path: '/rebook/create-booking',
                name: 'rebook-create-booking',
                components: {
                  rebook_create_booking: CreateBooking
                },
                props: {
                  rebook_create_booking: true
                },
                beforeEnter: (to, from, next) => {
                  modalCustomerRegistration({to, from})
                    .then((resolve) => validateBookingParams(resolve))
                    .then(() => next())
                    .catch((reject) => {
                      next(reject)
                    });
                }
              }]
            }
          ]
      },
        {
          path: 'bookings',
          name: 'customer-dashboard-bookings',
          components: {
            customer_dashboard_bookings: CustomerDashboardBookings
          },
          props: {
            customer_dashboard_bookings: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForCustomer(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          },
          children: [
            {
              path: '/rebook-booking/:booking_id/:booking_flow',
              name: 'booking-tab-customer-rebook',
              components: {
                booking_tab_customer_rebook: CustomerDashboardRebook
              },
              props: {
                booking_tab_customer_rebook: true
              },
              children: [{
                path: '/rebook/create-booking',
                name: 'booking-tab-rebook-create-booking',
                components: {
                  booking_tab_rebook_create_booking: CreateBooking
                },
                props: {
                  booking_tab_rebook_create_booking: true
                },
                beforeEnter: (to, from, next) => {
                  modalCustomerRegistration({to, from})
                    .then((resolve) => validateBookingParams(resolve))
                    .then(() => next())
                    .catch((reject) => {
                      next(reject)
                    });
                }
              }]
            }
          ]
        },
        {
          path: 'wallet',
          name: 'customer-dashboard-wallet',
          components: {
            customer_dashboard_wallet: CustomerDashboardWallet
          },
          props: {
            customer_dashboard_wallet: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForCustomer(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'profile',
          name: 'customer-dashboard-profile',
          components: {
            customer_dashboard_profile: CustomerDashboardProfile
          },
          props: {
            customer_dashboard_profile: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForCustomer(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'settings',
          name: 'customer-dashboard-settings',
          components: {
            customer_dashboard_settings: CustomerDashboardSettings
          },
          props: {
            customer_dashboard_settings: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForCustomer(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'referral',
          name: 'customer-dashboard-referral',
          components: {
            customer_dashboard_referral: CustomerDashboardReferral
          },
          props: {
            customer_dashboard_referral: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForCustomer(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'reviews/:booking_id',
          name: 'customer-dashboard-reviews',
          components: {
            customer_dashboard_reviews: CustomerDashboardReviews
          },
          props: {
            customer_dashboard_reviews: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForCustomer(resolve))
              .then((resolve) => checkBookingStatusBeforeReview(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        }
      ]
    },

    {
      path: '/admin',
      component: AdminDashboard,
      beforeEnter: (to, from, next) => {
        modalAuthenticate({to, from})
          .then((resolve) => onlyForAdmin(resolve))
          .then(() => next())
          .catch((reject) => {
            next(reject);
          });
      },
      children: [
        {
          path: '',
          name: 'admin-dashboard',
          components: {
            admin_dashboard_practitioners: AdminDashboardPractitioners
          },
          props: {
            admin_dashboard_practitioners: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForAdmin(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'practitioners',
          name: 'admin-dashboard-practitioners',
          components: {
            admin_dashboard_practitioners: AdminDashboardPractitioners
          },
          props: {
            admin_dashboard_practitioners: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForAdmin(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'customers',
          name: 'admin-dashboard-customers',
          components: {
            admin_dashboard_customers: AdminDashboardCustomers
          },
          props: {
            admin_dashboard_customers: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForAdmin(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'bookings',
          name: 'admin-dashboard-bookings',
          components: {
            admin_dashboard_customers: AdminDashboardBookings
          },
          props: {
            admin_dashboard_bookings: true
          },
          beforeEnter: (to, from, next) => {
             modalAuthenticate({to, from})
               .then((resolve) => onlyForAdmin(resolve))
               .then(() => next())
               .catch((reject) => {
                 next(reject);
               });
             }
          },
        {
          path: 'loyalty-schemes',
          name: 'admin-dashboard-loyalty-schemes',
          components: {
            admin_dashboard_loyalty_schemes: AdminDashboardLoyaltySchemes
          },
          props: {
            admin_dashboard_loyalty_schemes: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForAdmin(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        },
        {
          path: 'statistics',
          name: 'admin-dashboard-statistics',
          components: {
            admin_dashboard_statistics: AdminDashboardStatistics
          },
          props: {
            admin_dashboard_statistics: true
          },
          beforeEnter: (to, from, next) => {
            modalAuthenticate({to, from})
              .then((resolve) => onlyForAdmin(resolve))
              .then(() => next())
              .catch((reject) => {
                next(reject);
              });
          }
        }
      ]
    },
    {
      path: '/sync-calendar',
      name: 'SyncCalendar',
      component: SyncCalendar
    },
    {
      path: '/payment/:booking_id',
      name: 'Payment',
      component: Payment,
      props: true,
      beforeEnter: (to, from, next) => {
        modalAuthenticate({to, from})
          .then((resolve) => checkBookingStatus(resolve))
          .then(() => next())
          .catch((reject) => {
            next(reject);
          });
      }

    },
    {
      path: '/payment-success/:booking_id',
      name: 'payment-success',
      component: PaymentSuccess,
      props: true,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        authRequired: true
      },
      beforeEnter: (to, from, next) => {
        authenticate({to, from})
          .then(() => next())
          .catch((reject) => {
            next(reject);
          });
      }
    },
    {
      path: '/invited-client-review/:token',
      name: 'invited-client-review',
      component: InvitedClientsReview,
      props: true,
      beforeEnter: (to, from, next) => {
        modalAuthenticate({to, from})
          .then((resolve) => isValidToken(resolve))
          .then(() => next())
          .catch((reject) => {
            next(reject);
          });
      }
    },
    {
      path: '*',
      component: NotFound
    }
  ]
});

router.beforeEach((to, from, next) => {

    const body = document.body;
    if (body.classList.contains('is-open-menu')) {
        body.classList.remove('is-open-menu');
    }

  checkUserSession({to, from})
    .then((resolve) => isNeedingRedirectAfterAuth(resolve))
    .then((resolve) => checkCookiesBar(resolve))
    .then((resolve) => setUserForFullStory(resolve))
    .then(() => next());
});

export default router;
