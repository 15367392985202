<template>
  <div>

    <Breadcrumbs
            :searchAddress="breadcrumbs.searchAddress"
            :searchServiceType = "breadcrumbs.searchServiceType"
            :searchLocationType="breadcrumbs.searchLocationType"
            :bookingPractitioner="breadcrumbs.bookingPractitioner"
            :bookingPractitionerId="breadcrumbs.bookingPractitionerId"
            :bookingDate="breadcrumbs.bookingDate"
    ></Breadcrumbs>

    <div class="checkout">
      <div class="checkout__top">
        <div
          class="checkout__back"
          @click="redirectBack"
        >
          {{$t('payment.back')}}
        </div>
        <div class="checkout__title">
          {{$t('payment.checkout')}}
        </div>
      </div>
      <div class="checkout__holder">
        <div class="checkout__form">
          <div class="checkout__title checkout__title--desk">
            {{$t('payment.checkout')}}
          </div>
          <div
            class="checkout__form-fieldset"
            v-if="false"
          >
            <div class="checkout__form-title">
              {{$t('payment.appointment_address')}}
            </div>
            <div class="checkout__form-items">
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--lg">

                  <vuetify-google-autocomplete
                    id="house-flat"
                    label="House / Flat no."
                    types="geocode"
                    country="UK"
                    clearable
                    v-on:placechanged="setHouseFlat"
                    v-on:no-results-found="noResultsFound"
                    v-model="houseFlat"
                  ></vuetify-google-autocomplete>

                </div>
              </div>
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--lg">
                  <vuetify-google-autocomplete
                    id="street-name"
                    label="Street Name"
                    types="geocode"
                    country="UK"
                    clearable
                    v-on:placechanged="setStreetName"
                    v-on:no-results-found="noResultsFound"
                    v-model="streetName"
                  ></vuetify-google-autocomplete>
                </div>
              </div>
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--lg">
                  <vuetify-google-autocomplete
                    id="town-city"
                    label="Town / City"
                    types="(cities)"
                    country="UK"
                    clearable
                    v-on:placechanged="setTownCity"
                    v-on:no-results-found="noResultsFound"
                    v-model="townCity"
                  ></vuetify-google-autocomplete>
                </div>
              </div>
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--lg">

                  <vuetify-google-autocomplete
                    id="county"
                    label="County"
                    types="(regions)"
                    clearable
                    v-on:placechanged="setCounty"
                    v-on:no-results-found="noResultsFound"
                    v-model="county"
                  ></vuetify-google-autocomplete>
                </div>
              </div>
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--lg">
                  <div class="checkout__form-field">
                    <label
                      for=""
                      class="checkout__form-label"
                    >
                      {{$t('payment.postcode')}}
                    </label>
                    <input
                      type="text"
                      v-model="postalCode"
                    >
                  </div>
                </div>
              </div>
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--lg">
                  <div class="checkout__form-field checkout__form-field--center">
                    <label
                      for="save-address"
                      class="red-checkbox"
                    >
                      <input
                        type="checkbox"
                        id="save-address"
                        name="save-address"
                      >
                      <span></span>
                      {{$t('payment.save_this_address')}}
                    </label>
                  </div>
                </div>
              </div>

              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--lg">
                  <div class="checkout__form-field">
                    <label
                      for=""
                      class="checkout__form-label"
                    >
                      {{$t('payment.notes')}}
                    </label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      v-model="bookingNotes"
                      @blur="storeBookingNotes"
                      :placeholder="$t('payment.notes_desc')"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="checkout__form-fieldset">
            <div class="checkout__form-title">
              {{$t('payment.billing_details')}}
            </div>
            <div class="checkout__form-items">
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--md">
                  <div class="checkout__form-field">
                    <div class="solo-label">
                      {{$t('payment.first_names')}}
                    </div>
                    <v-text-field
                      name="first_name"
                      type="text"
                      v-model="userDetails.first_name"
                      :error-messages="userDetails.first_nameErrorMess"
                      required
                      solo
                      class="solo-field-gray"
                    ></v-text-field>
                  </div>
                </div>
                <div class="checkout__form-item checkout__form-item--md">
                  <div class="checkout__form-field">
                    <div class="solo-label">
                      {{$t('payment.last_name')}}
                    </div>
                    <v-text-field
                      name="last_name"
                      type="text"
                      v-model="userDetails.last_name"
                      :error-messages="userDetails.last_nameErrorMess"
                      required
                      solo
                      class="solo-field-gray"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--md">
                  <label
                    for=""
                    class="checkout__form-label"
                  >
                    {{$t('payment.date_of_birth')}}
                  </label>
                  <div class="checkout__form-fields">
                    <div class="checkout__form-field checkout__form-field--sm">
                      <v-select
                        :items="daysForBirth"
                        label="Day"
                        v-model="userDetails.day"
                        required
                        :error-messages="userDetails.dayErrorMess"
                        solo
                        class="solo-field-gray"
                      ></v-select>
                    </div>
                    <div class="checkout__form-field checkout__form-field--sm">

                      <v-select
                        :items="monthsForBirth"
                        label="Month"
                        v-model="userDetails.month"
                        required
                        :error-messages="userDetails.monthErrorMess"
                        solo
                        class="solo-field-gray"
                      ></v-select>

                    </div>
                    <div class="checkout__form-field checkout__form-field--sm">
                      <v-select
                        :items="yearsForBirth"
                        label="Year"
                        v-model="userDetails.year"
                        required
                        :error-messages="userDetails.yearErrorMess"
                        solo
                        class="solo-field-gray"
                      ></v-select>
                    </div>
                  </div>
                  <div class="checkout__form-field checkout__form-field--payment-hide">
                    <v-text-field
                      type="hidden"
                      :error-messages="userDetails.birthdayErrorMess"
                      solo
                    ></v-text-field>
                  </div>
                </div>
                <div class="checkout__form-item checkout__form-item--md">
                  <label
                    for=""
                    class="checkout__form-label"
                  >
                    {{$t('payment.gender')}}
                  </label>
                  <div class="checkout__form-fields checkout__form-fields--checkboxes">
                    <v-radio-group
                      v-model="userDetails.gender"
                      :mandatory="false"
                      :error-messages="userDetails.genderErrorMess"
                    >
                      <div class="checkout__form-fields checkout__form-fields--checkboxes">
                        <div class="checkout__form-field">
                          <v-radio
                            :label="$t('payment.male')"
                            value="m"
                            off-icon="check_box_outline_blank"
                            on-icon="check_box"
                          ></v-radio>
                        </div>
                        <div class="checkout__form-field">
                          <v-radio
                            :label="$t('payment.female')"
                            value="f"
                            off-icon="check_box_outline_blank"
                            on-icon="check_box"
                          ></v-radio>
                        </div>
                      </div>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <div class="checkout__form-string">
                <div class="checkout__form-item checkout__form-item--md">
                  <div class="checkout__form-field">
                    <div class="solo-label">
                      {{$t('payment.phone_number')}}
                    </div>
                    <v-text-field
                      name="phone"
                      placeholder="07737129828"
                      type="text"
                      v-model="userDetails.phone"
                      :error-messages="userDetails.phoneErrorMess"
                      mask="###########"
                      required
                      solo
                      class="solo-field-gray"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="checkout__form-fieldset">
            <div class="checkout__form-title">
              {{$t('payment.payment')}}
            </div>
            <p class="checkout__form-HelpText">{{$t('payment.cancellation_help_text')}}</p>
            <div class="checkout__form-items">

              <PaymentBlock
                ref="stripePayment"
                :booking_id="booking_id"
                :booking="booking"
                :userDetails="userDetails"
                :isErrorsByUsersFields="isErrorsByUsersFields"
                @hide-progress="hideProgress"
                @payment-success="conversionTracking"
              ></PaymentBlock>

              <div class="checkout__form-string checkout__form-string--mt checkout__form-string--center">
                <div class="checkout__form-item checkout__form-item--lg">
                  <div class="checkout__form-field checkout__form-field--checkbox">
                    <v-checkbox
                      v-model="termsConditions"
                      :label="$t('payment.confirm_terms_conditions')"
                      :error-messages="termsConditionsErrorMess"
                    ></v-checkbox>
                  </div>
                </div>
              </div>
              <div class="checkout__form-string checkout__form-string--light checkout__form-string--center">
                <div class="checkout__form-item checkout__form-item--lg">
                  <div class="checkout__form-field checkout__form-field--checkbox">
                    <v-checkbox
                      v-model="privacyPolicy"
                      :label="$t('payment.confirm_privacy_policy')"
                      :error-messages="privacyPolicyErrorMess"
                    ></v-checkbox>
                  </div>
                </div>
              </div>
              <v-progress-linear
                indeterminate
                color="error"
                height="3"
                v-if="showProgress"
              ></v-progress-linear>
              <div class="checkout__form-string checkout__form-string--mt checkout__form-string--center">
                <v-btn
                  color="error"
                  class="checkout__form-button v-btn theme--light error"
                  @click="confirmPayment()"
                  :disabled="isErrorsByUsersFields || !isSelectedConfirmFields"
                >
                  {{$t('payment.confirm_payment')}}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="checkout__sidebar">
          <div class="checkout__title checkout__title--sidebar-desk">
            {{$t('payment.appointment_summary')}}
          </div>
          <div class="checkout__title checkout__title--sidebar-mob">
            {{$t('payment.booking_summary')}}
          </div>
          <div class="checkout__sidebar-inners">
            <div class="checkout__sidebar-inner">
              <div class="checkout__sidebar-title">
                {{$t('payment.practitioner')}}
              </div>
              <div class="checkout__sidebar-value">{{booking.practitioner_name}}</div>
            </div>
            <div class="checkout__sidebar-inner">
              <div class="checkout__sidebar-title">
                {{$t('payment.cancellation_policy')}}
              </div>
              <div class="checkout__sidebar-value">
                <!--{{booking.cancellation_policy}}-->
                {{$t('payment.cancellation_policy_default')}}
                <v-tooltip
                        bottom
                        max-width="500"
                >
                  <template v-slot:activator="{ on }">
                    <span class="checkout__sidebar-icon" v-on="on"></span>
                  </template>
                  <span v-html="$t('payment.cancellation_policy_default_desc')"></span>
                </v-tooltip>
              </div>
            </div>
            <div class="checkout__sidebar-inner">
              <div class="checkout__sidebar-title">
                {{$t('payment.date')}}
              </div>
              <div class="checkout__sidebar-value">{{bookingDate}}</div>
            </div>
            <div class="checkout__sidebar-inner">
              <div class="checkout__sidebar-title">
                {{$t('payment.time')}}
              </div>
              <div class="checkout__sidebar-value">{{bookingStartTime}}</div>
            </div>
            <div class="checkout__sidebar-inner">
              <div class="checkout__sidebar-title">
                {{$t('payment.treatment')}}
              </div>

              <div class="checkout__sidebar-items">
                <div
                  class="checkout__sidebar-item"
                  v-for="(slot, index) in preparedTreatments"
                  :key="index"
                >
                  <div class="checkout__sidebar-item-lside">{{slot.quantity}} x {{slot.name}}</div>
                  <div class="checkout__sidebar-item-rside">£{{slot.total_price}}</div>
                </div>

                <div
                  class="checkout__sidebar-item"
                  v-if="this.booking.location_type === 'mobile'"
                >
                  <div class="checkout__sidebar-item-lside">
                    {{$t('payment.call_out_charge')}}
                  </div>
                  <div class="checkout__sidebar-item-rside">£{{booking.call_out_charge}}</div>
                </div>

                <div
                        class="checkout__sidebar-item"
                        v-if="isSetDiscount"
                >
                  <div class="checkout__sidebar-item-lside">
                    {{$t('payment.loyalty_bonus', {discount: booking.discount})}}
                  </div>
                  <div class="checkout__sidebar-item-rside">-£{{booking.discount_amount}}</div>
                </div>

                <div class="checkout__sidebar-item checkout__sidebar-item--total">
                  <div class="checkout__sidebar-item-lside">
                    {{$t('payment.sub_total')}}
                  </div>
                  <div class="checkout__sidebar-item-rside">£{{booking.total_amount}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import moment from 'moment';
import { empty, getHeightOfWindow } from '@/helpers.js';
import axios from "axios";
import 'leaflet/dist/leaflet.css';
import * as CONFIG from "@/config.js";
import { getTreatmentObject } from '@/helpers.js';
import PaymentBlock from '@/components/PractitionerSearch/PaymentBlock';
import Breadcrumbs from '@/components/Partial/Breadcrumbs';

/* eslint-disable */

export default {
  props: ['booking_id'],
  name: "payment",
  components: {
    PaymentBlock,
    Breadcrumbs
  },
  data () {
    return {
      booking: {
        cancellation_policy: null,
        date: null,
        id: null,
        location_type: null,
        practitioner_name: null,
        start_time: null,
        treatments: [],
        total_amount: null,
        call_out_charge: null,
      },
      countries: [],
      preparedTreatments: [],
      houseFlat: '',
      streetName: '',
      townCity: '',
      county: '',
      postalCode: '',

      bookingNotes: '',

      userDetails: {
        first_name: '',
        first_nameErrorMess: [],

        last_name: '',
        last_nameErrorMess: [],

        phone: '',
        phoneErrorMess: [],

        gender: '',
        genderErrorMess: [],

        day: '',
        dayErrorMess: [],

        month: '',
        monthErrorMess: [],

        year: '',
        yearErrorMess: [],

        email: '',

        birthdayErrorMess: [],
      },

      termsConditions: false,
      termsConditionsErrorMess: [],
      privacyPolicy: false,
      privacyPolicyErrorMess: [],

      showProgress: false,

      breadcrumbs:{
        searchAddress:'',
        // searchSubServiceName:'',
        searchServiceType:'',
        searchLocationType:'',
        bookingPractitioner:'',
        bookingPractitionerId: '',
        bookingDate:'',
      }
    }
  },

  created: function () {
    this.$store.dispatch('GET_BOOKING', this.booking_id)
      .then((response) => { console.log(response);
        this.booking = response;
        const dataTreatment = getTreatmentObject(this.booking.treatments);
        this.preparedTreatments = dataTreatment['data'];
        this.setBreadcrumbsData();
      });

    this.$store.dispatch('GET_CUSTOMER')
      .then((response) => {
        this.setUserData(response);
      });

    this.setAddress({ address_components: this.$store.getters.search.searchAddressComponents });
    this.$scrollTo('.header.v-toolbar');
  },
  computed: {
    bookingDate () {
      return moment(this.booking.date, "YYYY-MM-DD").format("dddd Do MMMM YYYY");
    },
    bookingStartTime () {
      return moment(this.booking.start_time, "HH-mm-ss").format("HH.mm a");
    },
    daysForBirth () {
      let days = [];
      for (let i = 1; i < 32; i++) {
        if (i < 10) {
          days.push(String(`0${i}`));
        } else {
          days.push(String(i));
        }
      }
      return days;
    },
    monthsForBirth () {
      let months = [];
      for (let i = 1; i < 13; i++) {
        if (i < 10) {
          months.push(String(`0${i}`));
        } else {
          months.push(String(i));
        }
      }
      return months;
    },
    yearsForBirth () {
      let years = [];
      for (let i = 1950; i < 2020; i++) {
        years.push(String(i));
      }
      return years;
    },
    isErrorsByUsersFields () {
      return !(empty(this.userDetails.first_nameErrorMess) && empty(this.userDetails.last_nameErrorMess)
        && empty(this.userDetails.phoneErrorMess) && empty(this.userDetails.genderErrorMess)
        && empty(this.userDetails.dayErrorMess) && empty(this.userDetails.monthErrorMess)
        && empty(this.userDetails.yearErrorMess) && empty(this.userDetails.birthdayErrorMess));
    },
    isSelectedConfirmFields () {
      return empty(this.termsConditionsErrorMess) && empty(this.privacyPolicyErrorMess);
    },
    isSetDiscount() {
      if (!empty(this.booking.discount) && this.booking.discount !== 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    'userDetails.first_name': function (first_name) {
      this.checkSingleField(first_name, 'first_name', this.$i18n.t('payment.js_error_first_name_required'));
      this.storeUserDetails({ 'first_name': first_name });
    },
    'userDetails.last_name': function (last_name) {
      this.checkSingleField(last_name, 'last_name', this.$i18n.t('payment.js_error_last_name_required'));
      this.storeUserDetails({ 'last_name': last_name });
    },
    'userDetails.day': function (day) {
      this.checkSingleField(day, 'day', this.$i18n.t('payment.js_error_day_required'));
      if (this.checkBirthFields('day')) {
        this.storeUserDetails({ 'birthday': `${this.userDetails.year}-${this.userDetails.month}-${day}` });
      }
    },
    'userDetails.month': function (month) {
      this.checkSingleField(month, 'month', this.$i18n.t('payment.js_error_month_required'));
      if (this.checkBirthFields('month')) {
        this.storeUserDetails({ 'birthday': `${this.userDetails.year}-${month}-${this.userDetails.day}` });
      }
    },
    'userDetails.year': function (year) {
      this.userDetails.birthdayErrorMess = [];
      this.checkSingleField(year, 'year', this.$i18n.t('payment.js_error_year_required'));
      if (this.checkBirthFields('year')) {
        this.storeUserDetails({ 'birthday': `${year}-${this.userDetails.month}-${this.userDetails.day}` });
      }
    },
    'userDetails.gender': function (gender) {
      this.checkSingleField(gender, 'gender', this.$i18n.t('payment.js_error_gender_required'));
      this.storeUserDetails({ 'gender': gender });
    },
    'userDetails.phone': function (phone) {
      this.checkPhoneField(phone, 'phone');
      if (empty(this.userDetails.phoneErrorMess)) {
        this.storeUserDetails({ 'phone': phone });
      }
    },
    'termsConditions': function (value) {
      this.termsConditionsErrorMess = [];
      if (!value) {
        this.termsConditionsErrorMess.push(this.$i18n.t('payment.js_error_field_required'));
      }
    },
    'privacyPolicy': function (value) {
      this.privacyPolicyErrorMess = [];
      if (!value) {
        this.privacyPolicyErrorMess.push(this.$i18n.t('payment.js_error_field_required'))
      }
    }
  },
  methods: {
    conversionTracking() {
      try {
        if (CONFIG.APP_ENV === 'production') {
          console.log('conversion function called');
          console.log('Amount ' + this.booking.total_amount);
          // window.gtag('event', 'conversion', {
          //   'send_to': 'AW-755360510/KONOCJHtlqEBEP7Fl-gC',
          //   'value': this.booking.total_amount,
          //   'currency': 'GBP'
          // });
        }
      } catch (error) {
        console.log(error);
      }
    },
    setBreadcrumbsData(){
      this.breadcrumbs.searchAddress = this.$store.getters.search.searchAddress;
      // this.breadcrumbs.searchSubServiceName = this.$store.getters.search.searchSubServiceName;
      this.breadcrumbs.searchServiceType = this.$store.getters.search.searchServiceType;
      this.breadcrumbs.searchLocationType = this.$store.getters.search.searchLocationType;
      this.breadcrumbs.bookingPractitioner = this.booking.practitioner_name;
      this.breadcrumbs.bookingPractitionerId = this.booking.practitioner_id;
      this.breadcrumbs.bookingDate =  `${this.bookingDate} / ${this.bookingStartTime}`;
    },
    setUserData (response) {
      const fields = ['first_name', 'last_name', 'phone', 'gender', 'birthday', 'email'];
      for (let i = 0; i < fields.length; i++) {
        if (!empty(response[fields[i]])) {
          if (fields[i] === 'birthday') {
            this.userDetails.day = this.customerBirthday(response.birthday, 'day');
            this.userDetails.month = this.customerBirthday(response.birthday, 'month');
            this.userDetails.year = this.customerBirthday(response.birthday, 'year');
          } else {
            this.userDetails[fields[i]] = response[fields[i]];
          }
        }
      }
    },
    setAddress (placeResultData) {
      if (!empty(placeResultData.address_components)) {
        for (let i = 0; i < placeResultData.address_components.length; i++) {
          if (placeResultData.address_components[i].types.includes('street_number')) {
            console.log('setAddress');
            this.houseFlat = placeResultData.address_components[i].long_name;
          }
          if (placeResultData.address_components[i].types.includes('route')) {
            this.streetName = placeResultData.address_components[i].long_name
          }
          if (placeResultData.address_components[i].types.includes('postal_town')) {
            this.townCity = placeResultData.address_components[i].long_name
          }
          if (placeResultData.address_components[i].types.includes('country')) {
            this.county = placeResultData.address_components[i].long_name
          }
          if (placeResultData.address_components[i].types.includes('postal_code')) {
            this.postalCode = placeResultData.address_components[i].long_name
          }
        }
      }
    },
    setHouseFlat (addressData, placeResultData) {
      console.log(placeResultData);
      if (!empty(placeResultData)) {
        setTimeout(() => {
          this.setAddress(placeResultData)
        }, 0);

      }
    },
    setStreetName (addressData, placeResultData) {
      console.log(addressData);
      if (!empty(placeResultData)) {
        this.setAddress(placeResultData)
      }
    },
    setTownCity (addressData, placeResultData) {
      console.log(addressData);
      if (!empty(placeResultData)) {
        this.setAddress(placeResultData)
      }
    },
    setCounty (addressData, placeResultData) {
      console.log(addressData);
      if (!empty(placeResultData)) {
        this.setAddress(placeResultData)
      }
    },
    setClarifyAddress (addressData, placeResultData) {
      if (!empty(placeResultData)) {
        this.marker.lat = addressData.latitude;
        this.marker.lng = addressData.longitude;
        this.mapCenter = L.latLng(this.marker.lat, this.marker.lng);
      }
    },
    noResultsFound () {
      console.log("not found");
    },
    customerBirthday (date, type) {
      if (type === 'day') {
        return String(moment(date, "YYYY-MM-DD").clone().format("DD"));
      } else if (type === 'month') {
        return String(moment(date, "YYYY-MM-DD").clone().format("MM"));
      } else if (type === 'year') {
        return String(moment(date, "YYYY-MM-DD").clone().format("YYYY"));
      }
    },
    storeBookingNotes () {
      axios({
        method: 'post',
        url: `${CONFIG.API_URL}/booking/${this.booking_id}/notes`,
        data: JSON.stringify({ notes: this.bookingNotes }),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

    },
    checkBirthFields (field) {
      let isValid = true;
      if (empty(this.userDetails['day']) && field !== 'day') {
        this.userDetails.dayErrorMess.push(this.$i18n.t('payment.js_error_day_required'));
        isValid = false;
      }
      if (empty(this.userDetails['month']) && field !== 'month') {
        this.userDetails.monthErrorMess.push(this.$i18n.t('payment.js_error_month_required'));
        isValid = false;
      }
      if (empty(this.userDetails['year']) && field !== 'year') {
        this.userDetails.yearErrorMess.push(this.$i18n.t('payment.js_error_year_required'));
        isValid = false;
      }
      return isValid;
    },
    checkPhoneField (value, fieldName) {
      this.userDetails[`${fieldName}ErrorMess`] = [];
      if (empty(value)) {
        this.userDetails[`${fieldName}ErrorMess`].push(this.$i18n.t('payment.js_error_phone_required'));
      }
      if (!empty(value) && value.length != 11) {
        this.userDetails[`${fieldName}ErrorMess`].push(this.$i18n.t('payment.js_error_phone_format'));
      }
    },
    checkSingleField: function (value, fieldName, message) {
      this.userDetails[`${fieldName}ErrorMess`] = [];
      if (!value) {
        this.userDetails[`${fieldName}ErrorMess`].push(message);
      }
    },
    checkUsersFields () {
      const validateFields = [
        { name: 'first_name', displayName: 'First name' },
        { name: 'last_name', displayName: 'Last name' },
        { name: 'phone', displayName: 'Phone Number' },
        { name: 'gender', displayName: 'Gender' },
        { name: 'day', displayName: 'Day' },
        { name: 'month', displayName: 'Month' },
        { name: 'year', displayName: 'Year' },
      ];

      let isValid = true;

      for (let i = 0; i < validateFields.length; i++) {
        if (empty(this.userDetails[validateFields[i].name])) {
          isValid = false;
          this.userDetails[`${validateFields[i].name}ErrorMess`] = [];
          this.userDetails[`${validateFields[i].name}ErrorMess`].push(this.$i18n.t(`payment.js_error_${validateFields[i].name}_required`));
        }
      }

      return isValid;
    },
    checkConfirmsFields () {
      this.termsConditionsErrorMess = [];
      this.privacyPolicyErrorMess = [];

      if (!this.termsConditions) {
        this.termsConditionsErrorMess.push(this.$i18n.t('payment.js_error_field_required'))
      }
      if (!this.privacyPolicy) {
        this.privacyPolicyErrorMess.push(this.$i18n.t('payment.js_error_field_required'))
      }
    },
    fieldsErrorFromServer (errors) {
      if (!empty(errors)) {
        for (let field in errors) {
          for (let i = 0; i < errors[field].length; i++) {
            this.userDetails[`${field}ErrorMess`].push(errors[field][i]);
          }
        }
      }
    },
    storeUserDetails: _.debounce(function (data) {
      console.log(data);
      axios({
        method: 'put',
        url: `${CONFIG.API_URL}/dashboard/user/settings`,
        data: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (!empty(error.response.data.errors)) {
            this.fieldsErrorFromServer(error.response.data.errors);
          }
          console.log(error);
        });

    }, 500),
    confirmPayment () {
      this.checkUsersFields();
      this.checkConfirmsFields();
      if (!this.isErrorsByUsersFields && this.isSelectedConfirmFields) {
        this.showProgress = true;
        this.$store.dispatch('GET_BOOKING', this.booking_id)
          .then((response) => {
            if (response.paid === true) {
              this.hideProgress();
              this.$store.commit('SET_NOTIFICATION', {
                text: this.$i18n.t('payment.notification_already_been_paid'),
                color: 'orange'
              });
              this.$router.back();
            } else if (response.booking_status === 'confirmed' && response.paid === false) {
              this.$refs.stripePayment.paymentStripe();
            } else {
              this.hideProgress();
              this.$store.commit('SET_NOTIFICATION', {
                text: this.$i18n.t('payment.notification_does_not_allow_payment'),
                color: 'orange'
              });
              this.$router.back();
            }
          })
          .catch((error) => {
            this.hideProgress();
            if (error.response.status === 404) {
              this.$store.commit('SET_NOTIFICATION', {
                text: this.$i18n.t('payment.notification_booking_automatically_deleted'),
                color: 'orange'
              });
              this.$router.back();
            } else if (!empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
              this.$router.back();
            }
            console.error(error);
          });
      } else {
        const that = this;
        setTimeout((that) => { that.scrollToErrors() }, 0, that);
      }
    },
    scrollToErrors () {
      const navHeight = getHeightOfWindow();
      this.$scrollTo('.error--text', 800, { offset: Number((navHeight / 2) * -1) });
    },
    hideProgress () {
      this.showProgress = false;
    },
    redirectBack () {
      this.$router.back();
    }
  }
}
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.checkout {
  display: block;
  padding: 40px 24px 90px 24px;
  background-color: #f7f7f7;

  @include r(1000) {
    padding: 40px 24px 60px 24px;
  }
  @include r(700) {
    padding: 20px 16px 20px 16px;
  }

  &__holder {
    display: flex;
    align-items: flex-start;
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;

    @include r(1700) {
      max-width: 1440px;
    }

    @include r(1500) {
      max-width: 1240px;
    }

    @include r(1300) {
      max-width: 1100px;
    }

    @include r(900) {
      flex-wrap: wrap;
      background-color: #fff;
    }
  }

  &__form {
    display: block;
    flex: 1 1 auto;
    margin-right: 86px;
    padding: 34px 70px 60px 70px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.34);
    border-radius: 5px;
    background-image: linear-gradient(270deg, #fff 0%, #fdfdfd 100%);

    @include r(1500) {
      padding: 30px 60px 46px 60px;
      margin-right: 60px;
    }
    @include r(1300) {
      padding: 30px 40px;
      margin-right: 30px;
    }

    @include r(1100) {
      padding: 30px 24px;
      margin-right: 20px;
    }

    @include r(900) {
      flex: 0 0 100%;
      order: 2;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      background-image: none;
      padding: 0 24px 30px 24px;
    }
    @include r(600) {
      padding: 0 14px 24px 14px;
    }

    &-button {
      max-width: 457px;
      width: 100%;
      display: block;
      margin: 0 auto;
      height: 87px;
      font-size: 23px;
      text-transform: none;

      @include r(1700) {
        max-width: 420px;
        height: 80px;
        font-size: 21px;
      }

      @include r(1500) {
        max-width: 360px;
        height: 60px;
        font-size: 19px;
      }
      @include r(1300) {
        max-width: 320px;
        height: 54px;
        font-size: 18px;
      }

      @include r(1100) {
        max-width: 265px;
        height: 50px;
        font-size: 16px;
      }
    }

    &-fieldset {
      display: block;
      margin-top: 40px;

      @include r(1700) {
        margin-top: 30px;
      }
      @include r(1500) {
        margin-top: 20px;
      }
    }

    &-title {
      display: block;
      color: #535353;
      font-family: Nunito;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.2;

      @include r(1700) {
        font-size: 23px;
      }

      @include r(1500) {
        font-size: 20px;
      }

      @include r(1100) {
        font-size: 18px;
      }
      @include r(900) {
        font-size: 17px;
      }
      @include r(700) {
        font-size: 15px;
      }
    }

    &-HelpText{
      margin-top: 14px;
      color: #535353;
    }

    &-fields {
      display: flex;
      margin: 0 -10px;
      align-items: flex-start;

      @include r(1500) {
        margin: 0 -5px;
      }
      @include r(360) {
        flex-wrap: wrap;
      }

      .checkout {
        &__form {
          &-field {
            padding: 0 10px;
            min-height: 45px;
            display: flex;
            align-items: center;

            @include r(1500) {
              padding: 0 5px;
            }

            &--sm {
              flex: 0 0 33.3333333%;

              @include r(360) {
                flex: 0 0 50%;
              }
            }

            &--md {
              flex: 0 0 50%;
            }
          }
        }
      }

      &--checkboxes {
        padding-left: 20px;

        @include r(1500) {
          padding-left: 10px;
        }
        @include r(1100) {
          padding-left: 0;
        }

        .v-input {
          margin-top: 0;
          padding-top: 0;
        }

        .v-radio {
          margin: 0 !important;
        }

        .v-label {
          @include r(1500) {
            font-size: 17px;
            position: relative !important;
            top: 2px !important;
          }
          @include r(700) {
            font-size: 15px;
          }
        }

        .v-input--selection-controls__input {
          width: auto !important;
        }
        .v-icon {
          pointer-events: none;
          color: #f5565b !important;
          font-size: 24px;
        }
      }
    }

    &-field {
      display: block;

      &--checkbox {
        .v-input {
          margin-top: 0;
          padding-top: 0;
        }

        .v-radio {
          margin: 0 !important;
        }
        .v-icon {
          pointer-events: none;
          color: #f5565b !important;
          font-size: 24px;
        }

        .v-input--selection-controls__input {
          width: auto !important;
          order: 1;
          margin: 0;
        }
        .v-label {
          color: #535353;
          font-family: Nunito;
          font-size: 18px;
          font-weight: 400;
          padding-left: 12px;
          order: 2;

          @include r(1500) {
            font-size: 17px;
          }
          @include r(700) {
            font-size: 15px;
          }
        }
      }

      &--center {
        text-align: center;

        .red-checkbox {
          justify-content: center;
        }
      }
    }

    &-items {
      display: block;
      margin-top: 12px;
    }

    &-string {
      display: flex;
      margin: 10px -10px 0 -10px;

      @include r(1500) {
        margin: 4px -5px 0 -5px;
      }

      @include r(1100) {
        flex-wrap: wrap;
      }

      &--light {
        margin-top: 0;
      }

      &--center {
        justify-content: center;

        @include r(1100) {
          justify-content: flex-start;
        }
      }

      &--mt {
        margin-top: 40px;
      }
    }

    &-label {
      display: block;
      color: #535353;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 14px;

      @include r(1700) {
        font-size: 18px;
      }
      @include r(1500) {
        font-size: 17px;
      }
      @include r(1100) {
        font-size: 16px;
      }

      @include r(700) {
        font-size: 15px;
      }
    }
    &-item {
      padding: 10px;
      flex: 1 1 auto;

      @include r(1500) {
        padding: 5px;
      }

      &--lg {
        max-width: 500px;
      }
      &--md {
        max-width: 360px;
        @include r(1100) {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      &--sm {
        max-width: 280px;
      }

      &--xs {
        max-width: 150px;
      }
    }
  }

  &__sidebar {
    display: block;
    flex: 0 0 540px;
    width: 540px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.34);
    border-radius: 5px;
    background-image: linear-gradient(270deg, #fff 0%, #fdfdfd 100%);
    padding: 34px 48px 50px 48px;

    @include r(1700) {
      flex: 0 0 440px;
      padding: 34px 30px 50px 30px;
    }

    @include r(1500) {
      flex: 0 0 400px;
      padding: 30px 28px 46px 28px;
    }

    @include r(1300) {
      flex: 0 0 360px;
      padding: 30px 28px 46px 28px;
    }

    @include r(1100) {
      flex: 0 0 320px;
      padding: 30px 24px 40px 24px;
    }

    @include r(900) {
      flex: 0 0 100%;
      order: 1;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      background-image: none;
      padding: 24px 24px 0 24px;
    }

    &-inners {
      display: block;
      margin-top: 20px;
    }

    &-inner {
      display: block;
      margin-top: 26px;

      @include r(1500) {
        margin-top: 20px;
      }
    }
    &-title {
      display: block;
      color: #383838;
      font-family: Nunito;
      font-size: 23px;
      font-weight: 700;
      line-height: 1.2;
      padding: 0 35px 0 48px;

      @include r(1700) {
        font-size: 20px;
        padding: 0 30px;
      }
      @include r(1500) {
        font-size: 17px;
        padding: 0 26px;
      }
      @include r(1100) {
        font-size: 16px;
        padding: 0 18px;
      }
      @include r(700) {
        font-size: 15px;
      }
    }

    &-value {
      display: flex;
      align-items: center;
      margin-top: 7px;
      color: #535353;
      font-family: Nunito;
      font-size: 23px;
      font-weight: 400;
      line-height: 1.2;
      padding: 0 35px 0 48px;

      @include r(1700) {
        font-size: 20px;
        padding: 0 30px;
      }
      @include r(1500) {
        font-size: 17px;
        padding: 0 26px;
      }

      @include r(1100) {
        font-size: 16px;
        padding: 0 18px;
      }
      @include r(700) {
        font-size: 15px;
      }
    }
    &-icon {
      display: inline-block;
      margin-left: 5px;
      width: 21px;
      height: 21px;
      background: {
        image: url("../../images/icons/icon-q.svg");
        repeat: no-repeat;
        size: contain;
      }
    }
    &-items {
      display: block;
      margin-top: 10px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 35px 15px 48px;
      border-bottom: 1px solid #d9d9d9;

      @include r(1700) {
        padding: 13px 30px;
      }

      @include r(1500) {
        padding: 10px 26px;
      }

      @include r(1100) {
        padding: 10px 20px;
      }

      &:last-child {
        border-bottom: 0;

        @include r(700) {
          border-bottom: 1px solid #d9d9d9;
        }
      }

      &-lside {
        flex: 1 1 auto;
        padding-right: 20px;
        color: #535353;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 400;
        line-height: 1.2;

        @include r(1700) {
          font-size: 20px;
        }

        @include r(1500) {
          font-size: 17px;
        }

        @include r(1100) {
          font-size: 16px;
        }
        @include r(700) {
          font-size: 15px;
        }
      }

      &-rside {
        flex: 0 0 auto;
        text-align: right;
        color: #535353;
        font-family: Nunito;
        font-size: 24px;
        font-weight: 400;

        @include r(1700) {
          font-size: 21px;
        }

        @include r(1500) {
          font-size: 18px;
        }
        @include r(1100) {
          font-size: 16px;
        }
        @include r(700) {
          font-size: 15px;
        }
      }

      &--total {
        .checkout {
          &__sidebar {
            &-item {
              &-lside {
                font-weight: 700;
                color: #383838;
              }
              &-rside {
                font-weight: 700;
                color: #383838;
              }
            }
          }
        }
      }
    }
  }

  &__title {
    display: block;
    text-align: center;
    color: #383838;
    font-family: Nunito;
    font-size: 27px;
    font-weight: 700;
    line-height: 1.2;

    @include r(1700) {
      font-size: 25px;
    }
    @include r(1500) {
      font-size: 23px;
    }
    @include r(1300) {
      font-size: 21px;
    }

    @include r(1100) {
      font-size: 20px;
    }

    &--desk {
      @include r(900) {
        display: none;
      }
    }

    &--sidebar {
      &-desk {
        @include r(900) {
          display: none;
        }
      }
      &-mob {
        display: none;
        @include r(900) {
          display: block;
          padding-bottom: 16px;
          border-bottom: 1px solid #dadada;
        }

        @include r(700) {
          font-size: 15px;
        }
      }
    }
  }

  &__top {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px 12px;

    @include r(900) {
      display: flex;
    }
  }

  &__back {
    display: block;
    position: relative;
    padding-left: 16px;
    top: -1px;
    color: #383838;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 2px;
      width: 9px;
      height: 16px;
      background: {
        image: url("../../.././src/images/icons/icon-arrow-right-dark.svg");
        repeat: no-repeat;
        size: contain;
      }
      transition: 0.3s;
    }

    &:hover {
      &::before {
        transform: translateX(-4px);
        transition: 0.3s;
      }
    }
  }
}
</style>
