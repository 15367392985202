<template>

  <div
    class="dashboard-content dashboard-availability"
    id="dashboard-content"
  >
    <div class="dashboard-content__title">
      <h2>
        {{$t('practitioner_profile.work_hours_tab_title')}}
      </h2>
      <p>
        {{$t('practitioner_profile.work_hours_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">

      <div v-if="showProgress">
        <v-progress-linear
          indeterminate
          color="error"
          height="3"
        ></v-progress-linear>
      </div>

      <div class="availability__weeks">
        <div
          class="availability__week"
          v-if="showMobile"
        >
          <p class="dashboard-availability__title">
            Mobile
          </p>
          <ul class="availability__days">
            <template v-for="(availabilityByDay, dayOfWeek, day_index) in availability.mobile.schedule">
              <AvailabilitySlots
                :availabilityByDay="availabilityByDay"
                :dayOfWeek="dayOfWeek"
                :key="day_index"
                :isTooltipBreakButtonProp="isTooltipBreakButton"
                :defaultTimeFrom="defaultTimeFrom"
                :defaultTimeTo="defaultTimeTo"
                :defaultBreaks="defaultBreaks"
                @breaksUpdate="breaksUpdate"
                @timeFrom="timeFrom"
                @timeTo="timeTo"
              ></AvailabilitySlots>
            </template>
          </ul>
        </div>

        <template v-if="showSalon">
          <p class="dashboard-availability__title dashboard-availability__title--mt">
            Salon
          </p>
          <div
            v-for="(salon, salon_index) in availability.salon"
            class="availability__week"
            :key="salon_index"
          >
            <p
              class="title text-xs-center text-sm-center text-md-center"
              v-if="salon.data.name"
            >{{salon.data.name}}</p>
            <ul class="availability__days">
              <template v-for="(availabilityByDay, dayOfWeek, day_index) in salon.schedule">
                <AvailabilitySlots
                  :availabilityByDay="availabilityByDay"
                  :dayOfWeek="dayOfWeek"
                  :key="`${salon_index}-${day_index}`"
                  :isTooltipBreakButtonProp="isTooltipBreakButton"
                  :defaultTimeFrom="defaultTimeFrom"
                  :defaultTimeTo="defaultTimeTo"
                  :defaultBreaks="defaultBreaks"
                  @breaksUpdate="breaksUpdate"
                  @timeFrom="timeFrom"
                  @timeTo="timeTo"
                ></AvailabilitySlots>
              </template>
            </ul>
          </div>
        </template>
      </div>

    </div>
  </div>

</template>

<script>
import AvailabilitySlots from '@/components/PractitionerInformation/AvailabilitySlots';

import axios from "axios";
import * as CONFIG from "@/config.js";

import { orderDaysOfWeek, empty } from '@/helpers.js';

/* eslint-disable */

export default {
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Practitioner Availability'
  },
  components: {
    AvailabilitySlots,
  },
  data: function () {
    return {
      defaultTimeFrom: '09:00',
      defaultTimeTo: '18:00',
      defaultBreaks: [],

      showMobile: false,
      showSalon: false,
      showProgress: true,
      isTooltipBreakButton: false,

      availability: {
        "mobile": {
          "data": { "id": "", "name": "" },
          "schedule": {
            "sunday": { "show": false, "error": {"from": false, "to": false}, "available": { "from": "09:00", "to": "20:00" }, "break": [] },
            "monday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "tuesday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "wednesday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "thursday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "friday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "saturday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] }
          }
        },
        "salon": [{
          "data": { "id": "", "name": "" },
          "schedule": {
            "sunday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "monday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "tuesday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "wednesday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "thursday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "friday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] },
            "saturday": { "show": false, "error": {"from": false, "to": false},  "available": { "from": "09:00", "to": "18:00" }, "break": [] }
          }
        }]
      },
      firstLoading: true
    }
  },
  watch: {
    availability: {
      handler: function () {
        this.formatAvailabilityFromComponent();
      },
      deep: true
    }
  },
  mounted () {
    this.getPractitionerAvailability();
  },
  methods: {
    formatAvailabilityToComponent (availability_response) {
      let availability = {
        mobile: { schedule: {}, data: { id: "", name: "" } },
        salon: [{ schedule: {}, data: { id: "", name: "" } }]
      };

      if (availability_response.hasOwnProperty('mobile')) {
        let unordered_schedule = {};

        const days = availability_response.mobile;

        for (let day in days) {
          if (days.hasOwnProperty(day)) {
            if (Array.isArray(days[day])) {
              unordered_schedule[day] = { show: false, error: {from: false, to: false}, available: { from: "09:00", to: "18:00" }, break: [] };
            } else {

              if(!empty(days[day].break)){
                for (let i = 0; i < days[day].break.length; i++) {
                  days[day].break[i].error = {from: false, to: false};
                }
              }else{
                days[day].break = [];
              }
              unordered_schedule[day] = days[day];
              unordered_schedule[day].show = true;
              unordered_schedule[day].error = {from: false, to: false};
            }
          }
        }
        availability.mobile.schedule = orderDaysOfWeek(unordered_schedule);
      } else {
        availability.mobile.schedule = this.availability.mobile.schedule;
      }

      if (availability_response.hasOwnProperty('salon')) {
        let unordered_schedule = {};

        for (let salon in availability_response.salon) {
          availability.salon[0].data.id = salon;
          const days = availability_response.salon[salon];

          for (let day in days) {
            if (days.hasOwnProperty(day)) {
              if (Array.isArray(days[day])) {
                unordered_schedule[day] = { show: false, error: {from: false, to: false}, available: { from: "09:00", to: "18:00" }, break: [] };
              } else {

                if(!empty(days[day].break)){
                  for (let i = 0; i < days[day].break.length; i++) {
                    days[day].break[i].error = {from: false, to: false};
                  }
                }else{
                  days[day].break = [];
                }
                unordered_schedule[day] = days[day];
                unordered_schedule[day].show = true;
                unordered_schedule[day].error = {from: false, to: false};
              }
            }
          }
        }
        availability.salon[0].schedule = orderDaysOfWeek(unordered_schedule);
      } else {
        availability.salon[0].schedule = this.availability.salon[0].schedule;
      }
      ;

      this.availability = availability;
    },
    formatAvailabilityFromComponent () {
      const mobile_days = this.availability.mobile.schedule;
      const salon_id = this.availability.salon[0].data.id;
      const salon_days = this.availability.salon[0].schedule;
      let mobileTimeIntervalIsValid = true;
      let salonTimeIntervalIsValid = true;
      let mobileBreakIntervalIsValid = {sunday: 1, monday:1, tuesday:1, wednesday:1, thursday:1, friday:1, saturday:1};
      let salonBreakIntervalIsValid = {sunday: 1, monday:1, tuesday:1, wednesday:1, thursday:1, friday:1, saturday:1};

      let availability = {};

      if (this.showMobile) {
        availability.mobile = {};

        for (let day in mobile_days) {
          availability.mobile[day] = {};

          if (mobile_days[day].show) {

            if (!empty(mobile_days[day].available.from) && !empty(mobile_days[day].available.to)) {
              mobile_days[day].error.from = false;
              mobile_days[day].error.to = false;
              mobileTimeIntervalIsValid = true;

              availability.mobile[day].available = mobile_days[day].available;
              availability.mobile[day].break = mobile_days[day].break;

            } else {
              mobile_days[day].error.from = Boolean(empty(mobile_days[day].available.from));
              mobile_days[day].error.to = Boolean(empty(mobile_days[day].available.to));
              mobileTimeIntervalIsValid = false;
              break;
            }

            for(let i=0; i<mobile_days[day].break.length; i++){
              if(empty(mobile_days[day].break[i].from) || empty(mobile_days[day].break[i].to)){
                mobileBreakIntervalIsValid[day] = 0;
                mobile_days[day].break[i].error.from = Boolean(empty(mobile_days[day].break[i].from));
                mobile_days[day].break[i].error.to = Boolean(empty(mobile_days[day].break[i].to));
                break;
              }else{
                mobileBreakIntervalIsValid[day] = 1;
                mobile_days[day].break[i].error.from = false;
                mobile_days[day].break[i].error.to = false;
              }
            }
          }
        }
      }

      if (this.showSalon) {
        availability.salon = {};
        availability.salon[salon_id] = {};
        for (let day in salon_days) {
          availability.salon[salon_id][day] = {};

          if (salon_days[day].show) {
            if(!empty(salon_days[day].available.from) && !empty(salon_days[day].available.to)){
              salon_days[day].error.from = false;
              salon_days[day].error.to = false;
              salonTimeIntervalIsValid = true;

              availability.salon[salon_id][day].available = salon_days[day].available;
              availability.salon[salon_id][day].break = salon_days[day].break;
            }else{
              salon_days[day].error.from = Boolean(empty(salon_days[day].available.from));
              salon_days[day].error.to = Boolean(empty(salon_days[day].available.to));
              salonTimeIntervalIsValid = false;
              break;
            }

            for(let i=0; i<salon_days[day].break.length; i++){
              if(empty(salon_days[day].break[i].from) || empty(salon_days[day].break[i].to)){
                salonBreakIntervalIsValid[day] = 0;
                salon_days[day].break[i].error.from = Boolean(empty(salon_days[day].break[i].from));
                salon_days[day].break[i].error.to = Boolean(empty(salon_days[day].break[i].to));
                break;
              }else{
                salonBreakIntervalIsValid[day] = 1;
                salon_days[day].break[i].error.from = false;
                salon_days[day].break[i].error.to = false;
              }
            }
          }
        }
      }

      if(mobileTimeIntervalIsValid && this.checkDaysByBreak(mobileBreakIntervalIsValid) === 7 && salonTimeIntervalIsValid && this.checkDaysByBreak(salonBreakIntervalIsValid) === 7){
        this.updatePractitionerAvailability(availability);
      }
    },
    checkDaysByBreak(data) {
      let sum = 0;
      for (let day in data) {
        sum += data[day]
      }
      return sum;
    },
    getPractitionerAvailability () {
      axios({
        method: "get",
        url: `${CONFIG.API_URL}/dashboard/practitioner/availability`,
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
        }
      })
        .then(
          response => {
            let availability_response = response.data;
            this.formatAvailabilityToComponent(availability_response);

            this.showProgress = false;

            this.showMobile = (availability_response.mobile) ? true : false;
            this.showSalon = (availability_response.salon) ? true : false;
          },
          error => {
            console.log(error.response.data);

            this.showProgress = false;

            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });

            throw error;
          }
        )
        .catch(error => {
          console.log(error.response);
          throw error;
        });
    },
    updatePractitionerAvailability (data) {
      if (!this.firstLoading) {
        axios({
          method: "put",
          url: `${CONFIG.API_URL}/dashboard/practitioner/availability`,
          data: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
              CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(
            response => {
              console.log('updatePractitionerAvailability', response);

              // this.$store.commit('SET_NOTIFICATION', {
              //   text: 'Succesfully updated',
              //   color: 'error'
              // });

            },
            error => {
              console.log(error.response.data);

              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });

              throw error;
            }
          )
          .catch(error => {
            console.log(error.response);
            throw error;
          });
      }
      this.firstLoading = false;
    },
    timeFrom (time) {
      this.defaultTimeFrom = time;
    },
    timeTo (time) {
      this.defaultTimeTo = time;
    },
    breaksUpdate (breaks) {
      this.defaultBreaks = breaks;
    },
  },
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.dashboard-content {
  display: block;
  flex: 1 1 auto;
  width: 100%;
  padding: 50px 120px 120px 100px;

  @include r(1700) {
    padding: 50px 100px 100px 80px;
  }

  @include r(1500) {
    padding: 40px 60px 60px 60px;
  }

  @include r(1300) {
    padding: 30px 40px 60px 40px;
  }
  @include r(1200) {
    padding: 28px 28px 40px 28px !important;
  }

  @include r(900) {
    padding: 28px 16px 20px 16px !important;
  }

  &__title {
    padding-bottom: 24px;

    @include r(1000) {
      padding-bottom: 0;
    }

    h2 {
      display: block;
      color: #383838;
      font-size: 37px;
      font-weight: 600;
      line-height: 1.1;

      @include r(1700) {
        font-size: 34px;
      }

      @include r(1500) {
        font-size: 32px;
      }

      @include r(1400) {
        font-size: 30px;
      }

      @include r(1300) {
        font-size: 28px;
      }

      @include r(1000) {
        display: none;
      }
    }

    p {
      display: block;
      margin: 10px 0 0 0;
      color: #535353;
      font-size: 15px;
      font-weight: 400;

      @include r(1000) {
        margin-top: 0;
      }
    }
  }

  &__body {
    padding: 40px 22px 0 22px;
    position: relative;

    @include r(1200) {
      padding: 40px 0 0 0;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      height: 1px;
      left: 22px;
      width: calc(100% - 44px);
      background-color: #f7cecf;

      @include r(1200) {
        width: 100%;
        left: 0;
      }
      @include r(1000) {
        display: none;
      }
    }
  }
}

.dashboard-availability {
  &__title {
    color: #383838;
    font-size: 27px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;

    @include r(1500) {
      font-size: 25px;
      margin-bottom: 18px;
    }

    @include r(1000) {
      font-size: 20px;
    }

    @include r(500) {
      font-size: 16px;
    }

    &--mt {
      margin-top: 40px;

      @include r(1200) {
        margin-top: 10px;
      }
    }
  }
  .availability__switch .v-input__slot .v-label {
    @include r(500) {
      font-size: 16px;
    }
  }
  .availability__day {
    @include r(1000) {
      padding: 15px 0;
    }
  }
  .availability__weeks {
    max-width: 635px;
    margin-left: -65px;

    @include r(1500) {
      margin-left: -35px;
    }
    @include r(1300) {
      margin-left: -15px;
    }
    @include r(1200) {
      margin: 0;
    }
    @include r(1000) {
      margin: 0 auto;
    }
  }
  .availability__week {
    padding: 0 85px 50px 85px;

    @include r(1500) {
      padding: 0 55px 50px 55px;
    }

    @include r(1300) {
      padding: 0 35px 50px 35px;
    }
    @include r(1200) {
      border-bottom: 0;
    }
    @include r(700) {
      padding: 0 0 30px 0;
    }
  }
}
</style>
