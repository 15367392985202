<template>
  <div>
    <div class="finished">
      <p class="title text-xs-center text-sm-center text-md-center">
        {{$t('practitioner_registration.step_6_title')}}
      </p>

      <p class="main-text">
        {{$t('practitioner_registration.step_6_desc')}}
      </p>

      <div
        class="text-xs-center text-sm-center text-md-center finished__photo_block"
        @click="launchFilePicker()"
      >
        <v-avatar
          size="140px"
          v-if="!photoSrc && !isImageLoading"
          class="finished__photo"
        >
          <span>
            {{$t('practitioner_registration.step_6_add_photo')}}
          </span>
        </v-avatar>

        <v-avatar
          size="140px"
          v-if="photoSrc && !isImageLoading"
          class="finished__photo"
        >
          <img
            :src="photoSrc"
            alt="avatar"
          >
        </v-avatar>

        <v-avatar
          size="140px"
          v-if="isImageLoading"
          class="finished__photo"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-avatar>

      </div>

      <input
        type="file"
        ref="file"
        @change="onFileChange($event)"
        style="display:none"
      >

      <InviteReviewsComponent></InviteReviewsComponent>

      <div class="text-xs-center step-btn-main">
        <v-btn
          color="error"
          @click="openPractitionerProfile"
        >
          {{$t('practitioner_registration.step_6_view_my_profile')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import * as CONFIG from '@/config.js'
  import InviteReviewsComponent from '@/components/Partial/InviteReviewsComponent';

  /* eslint-disable */

  export default {
    components:{
      InviteReviewsComponent
    },
    data() {
      return {
        photoSrc: null,
        isImageLoading: false
      };
    },
    methods: {
      openPractitionerProfile() {
        this.$router.replace({path: '/practitioner/profile'});
        this.$scrollTo('.header.v-toolbar');
      },
      changeStep(step) {
        this.$emit("changeStep", step);
      },
      launchFilePicker() {
        this.$refs.file.click();
      },
      onFileChange(event) {
        let data = new FormData();
        data.append('name', 'my-picture');
        data.append('profileImage', event.target.files[0]);

        this.isImageLoading = true;

        axios({
          method: "post",
          url: `${CONFIG.API_URL}/practitioner/avatar`,
          data: data,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${this.$store.getters.userToken || CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(
            response => {
              this.isImageLoading = false;
              this.photoSrc = response.data.src;
              console.log(response.data.message);
            },
            error => {
              console.log(error.response.data);
              this.isImageLoading = false;
              throw error;
            }
          )
          .catch(error => {
            console.log(error.response.data);
            this.isImageLoading = false;
            throw error;
          });
      }
    }
  };
</script>

<style lang="scss">

  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .finished {
    &__photo_block {
      width: 140px;
      margin: auto;

      .v-avatar {
        border-radius: 25px;
        background-color: #ff5a5f !important;

        span {
          color: #fff;
          font-family: Nunito;
          font-size: 15px;
          font-weight: 700;
        }

        img {
          border-radius: 25px !important;
        }
      }
    }

    .v-btn__content {
      @include r(700) {
        font-size: 15px;
      }
    }

    &__skip-btn {
      margin-top: 20px;
    }

    &__invite {
      @include r(700) {
        font-size: 15px !important;
        min-width: 60px !important;
      }
    }

    &__photo {
      margin-top: 20px;
      background-color: #ff5252;
    }
    &__photo:hover {
      cursor: pointer;
    }

    &__form {
      max-width: 400px;
      margin: 40px auto 0 auto;
    }

    &__fields {
      display: block;
      position: relative;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      input {
        padding-right: 110px;
      }

      button {
        position: absolute;
        margin: 0;
        top: 12px;
        right: 0;
        border-radius: 10px;
        font-size: 13px;
        font-weight: 400;
        height: 28px;
        min-width: 78px;

        &:hover {
          position: absolute;
        }
      }
    }

    .add-a-photo {
      font-size: 16px;
      margin-top: 30px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      padding: 0;
      height: auto;

      &::before {
        display: none;
      }

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }

    .skip {
      font-size: 16px;
      margin-top: 5px;
      font-weight: 400;
      color: #7b8994;
      padding: 0;
      height: auto;

      &::before {
        display: none;
      }

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }

    .main-text {
      max-width: 700px;
      margin: 30px auto 0 auto;

      @include r(700) {
        margin-top: 20px;
      }
    }
  }
</style>