import { REFRESH_CURRENT_USER_DATA, RESET_CURRENT_USER_DATA } from '../action-types';
import { SET_CURRENT_USER_DATA } from '../mutation-types';
import {empty} from '@/helpers.js';
import repository from '@/repository/repository.js';

export default {
  state: {
    current_user: {
      name: '',
      email: '',
      user_roles: [],
      user_id:'',
      practitioner_info:{
        practitioner_rating: 0,
        reviews_amount: 0,
        onboarding_step: null
      }
    }
  },
  mutations: {
    [SET_CURRENT_USER_DATA]: (state, data) => {
      for(let name in state.current_user){
        if(!empty(data[name]) && ((state.current_user[name] instanceof Array) || (state.current_user[name] instanceof Object)) ){
          state.current_user[name] = data[name];
          repository.saveValue(name, JSON.stringify(data[name]));
        }else if(!empty(data[name])){
          state.current_user[name] = data[name];
          repository.saveValue(name, data[name]);
        }
      }
    },
  },
  actions: {
    [REFRESH_CURRENT_USER_DATA]: ({commit, state}) => {
      for(let name in state.current_user){
        if(((state.current_user[name] instanceof Array) || (state.current_user[name] instanceof Object))){
          commit('SET_CURRENT_USER_DATA', {[name]: (!empty(repository.getValue(name))) ? JSON.parse(repository.getValue(name)) : null });
        }else{
          commit('SET_CURRENT_USER_DATA', {[name]:  (!empty(repository.getValue(name))) ? repository.getValue(name) : null });
        }
      }
    },
    [RESET_CURRENT_USER_DATA]: ({state}) => {
      for (let name in state.current_user){
        if(state.current_user[name] instanceof Array){
          state.current_user[name] = [];
          repository.deleteValue(name);
        }else if(state.current_user[name] instanceof Object && name === 'practitioner_info'){
          state.current_user[name] = {
            practitioner_rating: 0,
            reviews_amount: 0,
            onboarding_step: null
          };
          repository.deleteValue(name);
        }else{
          state.current_user[name] = '';
          repository.deleteValue(name);
        }
      }
    }
  },
  getters: {
    currentUser(state){
      return state.current_user;
    }
  }
}