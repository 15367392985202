<template>
  <div class="dashboard">
    <div
      class="dashboard-sidebar-open"
      @click="showSidebarMobile = !showSidebarMobile"
      :class="{ 'is-open': showSidebarMobile }"
    >
      {{ active_practitioner_dashboard_item_name }}
    </div>
    <div
      class="dashboard-sidebar"
      :class="{ 'is-open': showSidebarMobile }"
    >
      <div
        class="dashboard-sidebar__container"
        id="dashboard-sidebar__container"
        :class="{'is-bottom': sidebarScrollClass}"
      >
        <div class="dashboard-sidebar__top">
          <div class="dashboard-sidebar__name">{{ this.$store.getters.currentUser.name }}</div>
          <star-rating
            :rating="practitioner_rating"
            read-only
            :show-rating="false"
            :star-size="22"
            :border-width="0"
            :increment="0.5"
            :rounded-corners="true"
            active-color="#ffd100"
            inactive-color="#fff"
          ></star-rating>
          <div class="dashboard-sidebar__reviews">{{reviews_amount}}</div>
        </div>
        <div class="dashboard-sidebar__menu">
          <ul>
            <li
              v-for="item in dashboardList"
              :key="item.id"
              @click="activate(item)"
              :class="{ active : active_practitioner_dashboard_item == item.id }"
            >
              <router-link :to=item.to>
                <div :class=item.icon></div>
                <span>{{ item.name }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="/logout"
                class="logout"
              >
                <span>Logout</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <router-view name="practitioner_dashboard_dashboard"></router-view>
    <router-view name="practitioner_dashboard_calendar"></router-view>
    <router-view name="practitioner_dashboard_bookings"></router-view>
    <router-view name="practitioner_dashboard_services"></router-view>
    <router-view name="practitioner_dashboard_availability"></router-view>
    <router-view name="practitioner_dashboard_locations"></router-view>
    <router-view name="practitioner_dashboard_profile"></router-view>
    <router-view name="practitioner_dashboard_settings"></router-view>
    <router-view name="practitioner_dashboard_invite_reviewers"></router-view>
  </div>
</template>

<script>
/* eslint-disable */
import { empty, checkPositionSidebar } from '@/helpers.js';

export default {
  data () {
    return {
      dashboardList: [
        { id: '1', to: '/practitioner', icon: 'icon icon-dashboard', name: this.$i18n.t('practitioner_profile.sidebar_menu_dashboard') },
        { id: '2', to: '/practitioner/calendar', icon: 'icon icon-calendar', name: this.$i18n.t('practitioner_profile.sidebar_menu_calendar') },
        { id: '3', to: '/practitioner/bookings', icon: 'icon icon-bookings', name: this.$i18n.t('practitioner_profile.sidebar_menu_bookings') },
        { id: '4', to: '/practitioner/services', icon: 'icon icon-services', name: this.$i18n.t('practitioner_profile.sidebar_menu_services') },
        { id: '5', to: '/practitioner/availability', icon: 'icon icon-availability', name: this.$i18n.t('practitioner_profile.sidebar_menu_work_hours') },
        { id: '6', to: '/practitioner/locations', icon: 'icon icon-locations', name: this.$i18n.t('practitioner_profile.sidebar_menu_locations') },
        { id: '7', to: '/practitioner/profile', icon: 'icon icon-profile', name: this.$i18n.t('practitioner_profile.sidebar_menu_profile') },
        { id: '8', to: '/practitioner/settings', icon: 'icon icon-settings', name: this.$i18n.t('practitioner_profile.sidebar_menu_settings') },
        { id: '9', to: '/practitioner/invite-reviewers', icon: 'icon icon-profile', name: this.$i18n.t('practitioner_profile.sidebar_menu_invite_reviews') },

      ],
      active_practitioner_dashboard_item: 1,
      active_practitioner_dashboard_item_name: '',
      practitioner_rating: 0,
      reviews_amount: 0,
      sidebarScrollClass: false,
      showSidebarMobile: false
    };
  },
  created: function () {
    const currentUser = this.$store.getters.currentUser;
    this.practitioner_rating = currentUser.practitioner_info.practitioner_rating;
    this.reviews_amount = currentUser.practitioner_info.reviews_amount;
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    $route (to, from) {
      for (let i = 0; i < this.dashboardList.length; i++) {
        if (this.dashboardList[i]['to'] == to.path) {
          this.active_practitioner_dashboard_item = this.dashboardList[i]['id'];
          this.active_practitioner_dashboard_item_name = this.dashboardList[i]['name'];
        }
      }
    }
  },
  mounted () {
    for (let i = 0; i < this.dashboardList.length; i++) {
      if (this.dashboardList[i]['to'] == this.$route.path) {
        this.active_practitioner_dashboard_item = this.dashboardList[i]['id'];
        this.active_practitioner_dashboard_item_name = this.dashboardList[i]['name'];
      }
    }
  },
  methods: {
    activate: function (item) {
      this.showSidebarMobile = false;
      this.$scrollTo('.header.v-toolbar');
      this.active_practitioner_dashboard_item = item.id;
      this.active_practitioner_dashboard_item_name = item.name;
    },
    handleScroll () {
      if (checkPositionSidebar()) {
        this.sidebarScrollClass = true;
      } else {
        this.sidebarScrollClass = false;
      }
    }
  }
}
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.dashboard {
  display: flex;

  @include r(1000) {
    position: relative;
    display: block;
    min-height: 600px;
  }
}

.dashboard-sidebar {
  position: relative;
  width: 300px;
  flex: 0 0 300px;
  background-color: #f25757;
  z-index: 1;
  min-height: 845px;

  @include r(1700) {
    min-height: 779px;
  }

  @include r(1500) {
    min-height: 720px;
    width: 280px;
    flex: 0 0 280px;
  }
  @include r(1300) {
    min-height: 718px;
    width: 260px;
    flex: 0 0 260px;
  }

  @include r(1000) {
    min-height: 555px;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    transition: 0.5s;
    transform: translateY(-100%);
    z-index: 3;
    visibility: hidden;

    &.is-open {
      transform: translateY(0);
      visibility: visible;
      transition: 0.5s;
    }
  }

  &-open {
    display: none;
    position: relative;
    background-color: #f25757;
    width: 100%;
    height: 50px;
    text-align: center;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    line-height: 50px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
    z-index: 4;

    @include r(767) {
      font-size: 15px;
    }

    &::before {
      position: absolute;
      content: "";
      top: 20px;
      left: 28px;
      background: {
        image: url("../../.././src/images/icons/arrow-down-white.svg");
        repeat: no-repeat;
        size: contain;
      }
      width: 16px;
      height: 9px;
      transition: 0.3s;
    }

    @include r(1000) {
      display: block;
    }

    &.is-open {
      border-bottom-color: #d9d9d9;
      transition: 0.3s;
      &::before {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }
  }

  &__container {
    display: block;
    top: 132px;
    position: fixed;
    width: 300px;

    @include r(1500) {
      width: 280px;
    }
    @include r(1300) {
      width: 260px;
    }

    @include r(1800) {
      top: 114px;
    }

    @include r(1700) {
      top: 97px;
    }

    @include r(1500) {
      top: 86px;
    }

    @include r(1300) {
      top: 80px;
    }
    @include r(1000) {
      position: relative;
      top: 0;
      width: 100%;
    }

    &.is-bottom {
      position: absolute;
      bottom: 0;
      top: auto;
    }
  }

  &__top {
    display: block;
    padding: 50px 20px;
    text-align: center;

    @include r(1700) {
      padding: 45px 20px 40px 20px;
    }

    @include r(1500) {
      padding: 40px 20px 35px 20px;
    }

    @include r(1400) {
      padding: 30px 20px 25px 20px;
    }

    @include r(1000) {
      display: none;
    }

    .vue-star-rating {
      margin-top: 4px;
      justify-content: center;

      svg {
        @include r(1400) {
          width: 20px;
          height: 20px;
        }
        @include r(1300) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  &__name {
    display: block;
    color: #fff;
    font-size: 29px;
    font-weight: 700;
    line-height: 1.1;

    @include r(1700) {
      font-size: 25px;
    }

    @include r(1500) {
      font-size: 23px;
    }
    @include r(1400) {
      font-size: 21px;
    }
  }

  &__reviews {
    display: block;
    text-align: center;
    color: #fff;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 400;
  }

  &__menu {
    padding-bottom: 80px;

    @include r(1700) {
      padding-bottom: 60px;
    }

    @include r(1500) {
      padding-bottom: 40px;
    }
    @include r(1000) {
      padding: 30px 0;
    }

    ul {
      padding: 0;
      li {
        display: block;
        margin-bottom: 10px;

        @include r(1400) {
          margin-bottom: 7px;
        }

        &.active {
          a {
            background-color: #cb5b5e;
            transition: 0.3s;
            &::before {
              transform: translateX(0);
              transition: 0.3s;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: flex;
          align-items: center;
          padding: 17px 20px 17px 140px;
          text-decoration: none;
          position: relative;
          transition: 0.3s;

          @include r(1700) {
            padding: 16px 20px 16px 130px;
          }

          @include r(1500) {
            padding: 15px 20px 15px 120px;
          }

          @include r(1400) {
            padding: 13px 20px 13px 120px;
          }

          @include r(1300) {
            padding: 12px 20px 12px 100px;
          }
          @include r(1000) {
            padding: 15px 20px 15px 130px;
          }

          &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            width: 6px;
            background-color: #fff;
            transform: translateX(-100%);
            transition: 0.3s;
          }

          &:hover {
            background-color: #cb5b5e;
            transition: 0.3s;
            &::before {
              transform: translateX(0);
              transition: 0.3s;
            }
          }

          .icon {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            background: {
              repeat: no-repeat;
              size: contain;
            }

            &-dashboard {
              width: 33px;
              height: 33px;
              background-image: url("../.././images/icons/dashboard/menu-icon-dashboard.svg");
              left: 70px;

              @include r(1700) {
                width: 32px;
                height: 32px;
                left: 65px;
              }

              @include r(1500) {
                width: 29px;
                height: 29px;
                left: 62px;
                transform: translateY(-54%);
              }
              @include r(1300) {
                left: 52px;
              }
              @include r(1000) {
                left: 70px;
              }
            }

            &-calendar {
              width: 30px;
              height: 31px;
              background-image: url("../.././images/icons/dashboard/menu-icon-calendar.svg");
              left: 71px;

              @include r(1700) {
                width: 29px;
                height: 30px;
                left: 66px;
              }

              @include r(1500) {
                width: 27px;
                height: 28px;
                left: 62px;
                transform: translateY(-54%);
              }
              @include r(1300) {
                left: 52px;
              }
              @include r(1000) {
                left: 70px;
              }
            }
            &-bookings {
              width: 29px;
              height: 31px;
              background-image: url("../.././images/icons/dashboard/menu-icon-booking.svg");
              left: 71px;

              @include r(1700) {
                width: 28px;
                height: 30px;
                left: 67px;
              }

              @include r(1500) {
                width: 26px;
                height: 28px;
                left: 63px;
                transform: translateY(-54%);
              }
              @include r(1300) {
                left: 52px;
              }
              @include r(1000) {
                left: 70px;
              }
            }
            &-services {
              width: 31px;
              height: 31px;
              background-image: url("../.././images/icons/dashboard/menu-icon-services.svg");
              left: 70px;

              @include r(1700) {
                width: 30px;
                height: 30px;
                left: 65px;
              }

              @include r(1500) {
                width: 28px;
                height: 28px;
                left: 62px;
                transform: translateY(-54%);
              }
              @include r(1300) {
                left: 51px;
              }
              @include r(1000) {
                left: 70px;
              }
            }
            &-availability {
              width: 31px;
              height: 31px;
              background-image: url("../.././images/icons/dashboard/menu-icon-availability.svg");
              left: 70px;

              @include r(1700) {
                width: 30px;
                height: 30px;
                left: 67px;
              }

              @include r(1500) {
                width: 28px;
                height: 28px;
                left: 63px;
                transform: translateY(-54%);
              }
              @include r(1300) {
                left: 52px;
              }
              @include r(1000) {
                left: 70px;
              }
            }
            &-locations {
              width: 31px;
              height: 31px;
              background-image: url("../.././images/icons/dashboard/menu-icon-locations.svg");
              left: 70px;

              @include r(1700) {
                width: 30px;
                height: 30px;
                left: 67px;
              }

              @include r(1500) {
                width: 28px;
                height: 28px;
                left: 64px;
                transform: translateY(-54%);
              }
              @include r(1300) {
                left: 52px;
              }
              @include r(1000) {
                left: 70px;
              }
            }
            &-profile {
              width: 22px;
              height: 31px;
              background-image: url("../.././images/icons/dashboard/menu-icon-profile.svg");
              left: 74px;

              @include r(1700) {
                width: 21px;
                height: 30px;
                left: 71px;
              }

              @include r(1500) {
                width: 20px;
                height: 28px;
                left: 68px;
                transform: translateY(-54%);
              }
              @include r(1300) {
                left: 56px;
              }
              @include r(1000) {
                left: 75px;
              }
            }
            &-settings {
              width: 31px;
              height: 31px;
              background-image: url("../.././images/icons/dashboard/menu-icon-settings.svg");
              left: 71px;

              @include r(1700) {
                width: 30px;
                height: 30px;
                left: 68px;
              }

              @include r(1500) {
                width: 28px;
                height: 28px;
                left: 66px;
                transform: translateY(-54%);
              }
              @include r(1300) {
                left: 54px;
              }
              @include r(1000) {
                left: 72px;
              }
            }
          }

          span {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            line-height: 1;

            @include r(1700) {
              font-size: 19px;
            }

            @include r(1500) {
              font-size: 18px;
            }
            @include r(1000) {
              font-size: 15px;
            }
          }

          &.logout {
            padding: 17px 20px;
            justify-content: center;
            margin-top: 20px;

            @include r(1700) {
              padding: 16px 20px;
            }
            @include r(1500) {
              padding: 15px 20px;
            }
            @include r(1000) {
              padding: 15px 20px 15px 130px;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}
</style>
