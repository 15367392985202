<template>
    <div class="support">
        <div class="support__wrapper">
            <div class="support__content">
                <div class="support__title">
                    <strong>Support Centre</strong>
                </div>
                <div class="support__search">
                    <div class="support__search-title">Search for a topic or view our extensive library of articles to help you.
                    </div>
                    <div class="support__search-form">
                        <input type="text" placeholder="Search" v-model="searchQuery">
                        <v-btn class="error"></v-btn>
                    </div>
                    <div class="support__navigation">
                        <div class="support__buttons">
                            <div class="support__button"
                                 @click="setSearchFor('customer')"
                                 :class="{'is-active': searchFor==='customer'}"
                            >I’m A Customer
                            </div>
                            <div class="support__button"
                                 @click="setSearchFor('practitioner')"
                                 :class="{'is-active': searchFor==='practitioner'}"
                            >I’m A Practitioner
                            </div>
                        </div>
                    </div>
                </div>
                <div class="support__bodies">
                    <div class="support__body is-customer">
                        <div class="support__categories">
                            <div class="support__category"
                                 v-for="(item, index) in categoriesByRole.content"
                                 :key="index"
                                 @click="toggleActiveCategory(item)"
                                 :class="{'is-active': item.category.isActive === true}"
                            >
                                <div class="support__category-btn">
                                    <div class="support__category-description">
                                        <strong>{{item.category.title}}</strong>
                                        <p>{{item.category.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="support__faq" v-if="!categoriesByRole.content.length">
                            <div class="support__faq-empty">
                                Sorry, nothing was found by your request
                            </div>
                        </div>

                        <div class="support__holder" id="questions">
                            <div class="support__faq" v-if="questionsByActiveCategory.length">
                                <v-expansion-panel>
                                    <v-expansion-panel-content
                                            v-for="(item, index) in questionsByActiveCategory"
                                            :key="index"
                                    >
                                        <div slot="header">
                                            <div class="support__faq-header">{{ item.title }}</div>
                                            <!--<div class="support__faq-subheader">{{ item.excerption }}</div> -->
                                        </div>
                                        <v-card>
                                            <v-card-text>
                                                <div class="support__faq-description">{{ item.description }}</div>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {empty, getHeightOfElement} from '@/helpers.js';

    export default {
        name: "HowItWorks",
        metaInfo: {
            title: 'Freely',
            titleTemplate: '%s | Support'
        },
        data() {
            return {
                searchQuery: '',
                searchFor: 'customer',
                customer: {
                    data: {},
                    content: [
                        {
                            category: {
                                slug: 'get_started',
                                title: 'Getting started',
                                description: 'Signing up to Freely and how it works.',
                                isActive: false
                            },
                            support: {
                                title: 'Support Centre'
                            },
                            questions: [
                                {
                                    title: 'Creating an account',
                                    excerption: 'You only need to sign up if you wish to proceed with a booking (you won’t regret it), you can do as many searches as you like without creating an account.',
                                    description: 'You only need to sign up if you wish to proceed with a booking (you won’t regret it), you can do as many searches as you like without creating an account. If you find a practitioner that you would like to book, you will be prompted to sign up in the booking flow – you will only need to enter your full name, email address and phone number.'
                                },
                                {
                                    title: 'Finding a practitioner',
                                    excerption: 'Get Started Enter excerption excerption excerption 2.',
                                    description: 'Do a search by using the search box on the main homepage or in the search box in the menu bar - enter your full address or post code. Then start typing to find the treatment you would like, simply start typing ‘massage’ and you will see a list of all the massage services available, then simply click the search button.'
                                },
                                {
                                    title: 'Book a treatment at your home or at the practitioner’s home or salon',
                                    excerption: 'Get Started Enter excerption excerption excerption 3.',
                                    description: 'There is a huge range of practitioners signed up to Freely, some offer mobile only services, so they will come to you. Others work out of their home salon or a professional salon, so you will need to travel to them. Some offer both, working certain days or hours from a fixed location, then offering mobile services on other days. ' +
                                        'If you have a preference you can choose one or the other, we will then only show you practitioners that fit with your search criteria. If you choose ‘I don’t mind’ we will show you all practitioners, you will then need to confirm your preferred location prior to checkout. '
                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'managing_your_appointments',
                                title: 'Managing your appointments',
                                description: 'Cancellations, time changes etc.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'Find your practitioner’s phone number',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Your practitioner’s phone number is shown on the screen as soon as you pay for a booking, you will also find it in your appointment confirmation email and also in your user area, within the appointment summary. '
                                },
                                {
                                    title: 'Changing the time/day of your appointment',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'If it’s more than 24 hours in advance of your appointment and you need to re-arrange the time/date, simply give your practitioner a call to see if they can move the appointment for you.'
                                        + 'If you agree to amend your appointment time/date, the practitioner will need to edit your appointment time/day in their user area, once they have done so, you will receive an updated booking confirmation email which details the appointment time changes.'
                                },
                                {
                                    title: 'Cancellations, last minute changes and ‘no shows’',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'Cancelling a booking more than 24 hours before the appointment is due will result in a full refund, phew! Many practitioners are flexible though, so if you simply need to change the time/day of the appointment, we recommend reaching out to the practitioner by phone to discuss this.'
                                        + 'No refunds can be given if you cancel an appointment within 24 hours or do not show up to the appointment at the agreed time/date. Practitioners have busy schedules to manage, so last minute cancellations can really hurt their business, and we don’t want that! If you would like to re-schedule an appointment, but it’s within 24 hours, we recommend just asking the practitioner anyway, whilst they’re not obliged to do so, many will do what they can to help their customers. '
                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'payments',
                                title: 'Payments',
                                description: 'Accepted cards and your payment security.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'How to pay',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'You need to pay for your treatment at the time of booking on Freely, once you have found a practitioner, confirmed your timings and signed up, you will be taken to a secure checkout page.'
                                },
                                {
                                    title: 'Accepted cards',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'We use Stripe as our payment processor, so you can be confident that your payment will be handled securely and efficiently. You can pay by VISA, MasterCard, American Express or Apple Pay. '
                                },
                                {
                                    title: 'Payment security',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'Your payment funds are stored securely and are only released to the practitioner 48 hours after the treatment has taken place.'
                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'trust_and_safety',
                                title: 'Trust and safety',
                                description: 'Best practices, your data and verified practitioners.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'Check before you buy! ',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Practitioner’s qualifications, experience and examples of previous work are shown on their profile, as well as reviews from past customers. Prior to making a booking, we recommend you check through this information to ensure they meet your standards.'
                                },
                                {
                                    title: 'Safety and expectation',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'We recommend to only book a practitioner who has a profile photo, so that when you arrive at their home/salon or they arrive at your home, you can immediately see if they are the person you booked. In the event they do not match with the person you expected, we recommend that you do not proceed with the appointment and then contact the Freely customer services team to arrange a refund and inform us of the incident. '
                                },
                                {
                                    title: 'The red ‘verified’ tick',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'Look out for a red tick on practitioner’s profiles, this means the practitioner has sent copies of their insurance and qualifications to Freely for verification. If a practitioner doesn’t have a tick, but has qualifications listed, there’s not necessarily any cause for concern, it just means they are yet to be verified by the Freely team.'
                                },
                                {
                                    title: 'Your data',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'Your phone number will be shared with the practitioner when you make a booking. If you have booked for the practitioner to come and treat you at your home/office, the address of your location will also be shared with the practitioner. '
                                        + 'We comply with EU data privacy regulations to ensure your personal information is safe and secure and we will not share or sell your data to third parties, unless it’s being used in a secure manner, to help improve our service.'
                                },
                                {
                                    title: 'Unacceptable behaviour',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'In the event your practitioner exhibits a behaviour you deem to be unacceptable, we recommend stopping the treatment immediately and then informing law enforcement (if necessary) and then the Freely customer services team.'
                                }

                            ]
                        },
                        {
                            category: {
                                slug: 'reviews',
                                title: 'Reviews',
                                description: 'Best practices and how it works.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'How it works',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Once you have completed a treatment with a practitioner you will be able to leave them a review of the experience (be nice, but honest!). You will receive a notification email to remind you, simply follow the link from the email or login to your account and click the ‘leave review’ button in the appointment summary. '
                                },
                                {
                                    title: 'Best practices',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'Your review is valuable to practitioners and other customers using Freely, it helps practitioners build up their reputation and improve their service, whilst giving new customers more information on what to expect from a practitioner. With this in mind, we recommend that you leave honest, but constructive reviews to help both parties. Any reviews deemed to be unfair, abusive, discriminatory or slanderous will be removed.'
                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'account_management',
                                title: 'Account management',
                                description: 'Changing your settings and deleting your account. – The boring stuff.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'Changing your email address or password',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Simply log in to your account, navigate to the ‘settings’ tab and you can easily change your email address or password.'
                                },
                                {
                                    title: 'Deleting your account',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'Simply log in to your account, navigate to the ‘settings’ tab and click the ‘delete account’ button at the bottom of the page.'
                                },
                                {
                                    title: 'Contacting Freely',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'Just head to the contact page, where you will find our phone number and email address, alternatively you can talk to us using the live chat feature on the website – simply click it to open a new chat.'
                                }
                            ]
                        }
                    ]
                },
                practitioner: {
                    data: {},
                    content: [
                        {
                            category: {
                                slug: 'getting_started',
                                title: 'Getting started',
                                description: 'Creating an account and listing your services.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'Sign up',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Creating an account is quick and simple (it really is), click the ‘practitioner sign up’ button in the main menu bar, then follow the steps to create a profile and list your services, pricing, work locations and work hours. '
                                },
                                {
                                    title: 'Started sign up but didn’t finish?',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'To complete sign up, click the sign in button in the menu bar, enter the same details that you entered previously and you will be re-directed to the step of the sign up flow you had previously reached.'
                                },
                                {
                                    title: 'How long does it take?',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'Getting through sign up and adding the basics take less than ten minutes, we then recommend spending a little bit of time writing a great bio to showcase yourself and your skills and also then adding any existing appointments/time off to your calendar.'
                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'your_account',
                                title: 'Your account',
                                description: 'Managing your profile, services and work hours.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'Your services',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'List as many or as few as you like, if you can’t find a service that you normally offer, please just contact the Freely team and we will add it as soon as possible. '
                                },
                                {
                                    title: 'Your work hours',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'It’s important to complete this section accurately, as this will determine what times and days customers can book your services. Make sure you use the ‘add break’ feature to narrow down your availability for a given day. Once you have detailed your normal work hours, you can tailor your availability further by adding appointments/time off to your calendar.'
                                },
                                {
                                    title: 'Your profile',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'You can edit your profile in your user area, it’s quick and easy to change your bio, add more qualifications and photos.'
                                },
                                {
                                    title: 'Your calendar',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'Keep your availability up to date by using your calendar, if you are already booked then make sure you block the time on your calendar, (e.g you have a booking, a doctors appointment or are ill etc.), you can quickly add this to your Freely calendar. It’s important you add any ‘time off’ to your calendar, as it will ensure that customers don’t end up booking your at a time when you’re not actually available.'
                                },
                                {
                                    title: 'Your locations',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'We understand that some days you may work from your home/salon for part of the day and then offer mobile services earlier/later in the day, we have made it simple for you to manage this - you can offer treatments from your home/salon, just mobile treatments, or both! You can easily turn these options on or off, just make sure you update the work hours for each location in the ‘Work hours’ tab of your user area.'
                                        + 'We only share your address with customers that book a treatment with you and if that treatment is at your home/salon. If you only offer mobile services, customers will not be shown your home address'
                                },
                                {
                                    title: 'Deactivating your account',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'If you no longer wish to offer your services through Freely (unlikely), you can deactivate your account from the ‘Settings’ tab in your user area.'
                                },
                                {
                                    title: 'Booking a treatment for yourself',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'If anyone deserves a relaxing massage or facial, it’s you! You only need to register once on Freely, so simply log in to your practitioner account and then click on the ‘Customer account’ link in the main menu, you can then book treatments with your fellow practitioners straight away! Any bookings will show up within your ‘Customer account’, keeping them separate from any bookings your customers have with you. '
                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'managing_your_appointments',
                                title: 'Managing your appointments',
                                description: 'View booking history, upcoming bookings.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'How it works',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Customers are able to book and pay for your services online instantly, so it’s important your pricing and availability for your services is accurate and up to date. You will receive an email notification and text message if you receive a new booking, the details of the booking will be summarised in the email we send you and also in your user area, under the ‘Bookings’ tab. ' +
                                        'The customer’s phone number is included within the booking email and also in the booking overview in your user area. Make sure you call the customer to discuss any additional information you need or any other details of the appointment. '
                                },
                                {
                                    title: 'Editing a booking',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'Sometimes life gets in the way, for both practitioners and customers alike. If you need to edit an appointment time, providing you have agreed it with the customer, you can do so by clicking the ‘edit’ booking button in the booking overview. The customer will be sent an email if you edit their booking, confirming the new appointment time. '
                                },
                                {
                                    title: 'Cancelling a booking',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'You can cancel a booking from the booking overview in your user area, the customer will receive a full refund. We take cancelled bookings seriously at Freely (we’re sure you do too), as it can cause major inconvenience to customers. If for whatever reason you’re unable to offer the treatment at the time the customer booked, please try and re-arrange it directly with the customer. ' +
                                        'In the event you need to cancel a booking please try not to do so at the last minute, so it gives the customer time to find an alternative. Freely may apply penalties to your account for cancelling a booking, unless you are able to prove you had to cancel due to extraneous circumstances.'
                                },
                                {
                                    title: 'Customer cancellations and ‘no shows’',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'We know that last minute cancellations and ‘no shows’ cause significant problems for independent practitioners, so we take payment from customers up front when they book a treatment with you. In the event the customer cancels, they will receive a full refund if they cancel more than 24 hours before the booking is due to take place. If they cancel within 24 hours or do not show up to the appointment at the agreed time, they will not receive a refund and you will be paid in full. ' +
                                        'If a customer does need to re-schedule within 24 hours, whilst you’re within your rights to refuse, please try and be flexible where possible. Your flexibility will be greatly appreciated by the customer and will likely encourage them to book a treatment with you again in the future.'

                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'payments_and_fees',
                                title: 'Payments and fees',
                                description: 'How it works and our fees.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'How it works',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Customers make payment online at the time they book an appointment with you. Freely holds the payment securely, until 48 hours after the treatment has taken place – we then automatically transfer the funds to you. Just make sure you have added your bank details in the ‘Settings’ section of your user area, we can’t send you the money if we don’t have them!'
                                },
                                {
                                    title: 'Freely’s fees',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'We only charge a fee on bookings we generate for you, our fee is fixed at 20% including VAT. Lets say you have charged £100 for a treatment, once the treatment has been completed, we will transfer £80 directly to your bank account – simple!'
                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'trust_and_safety',
                                title: 'Trust and safety',
                                description: 'Best practices, your data and verifying your profile.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'Safety and expectation',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Customers are able to book your services instantly online, once they have booked we will share their phone number with you. We recommend giving them a call, to discuss the treatment/s they have booked and make sure you feel comfortable going to their home or welcoming them in to your home or salon. If after the call, something doesn’t add up and you just don’t feel comfortable treating them, you can cancel the booking. In this situation you will need to alert the Freely team to let them know what has happened.'
                                },
                                {
                                    title: 'Get a red ‘verified’ tick on your profile',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'We can’t recommend this enough, customers love it! All you need to do is send us a copy of your qualification certificates and your valid insurance policy, we will then check the documents, make sure it matches what you have written on your profile and then give your profile the Freely ‘verified’ tick.'
                                },
                                {
                                    title: 'Your data',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'Your phone number will be shared with the customer once they have paid for a booking. If the customer has booked a treatment which takes place at your home/salon, this address will also be shared with the customer. If you only work as a mobile practitioner, your address will not be shared with any customers. '
                                        + 'We comply with EU data privacy regulations to ensure your personal information is safe and secure and we will not share or sell your data to third parties, unless it’s being used in a secure manner to help improve our service.'
                                },
                                {
                                    title: 'Unacceptable behaviour',
                                    excerption: 'Enter excerption excerption excerption 3.',
                                    description: 'In the event a customer exhibits a behaviour you deem to be unacceptable, we recommend stopping the treatment immediately and then informing law enforcement (if necessary) and then the Freely customer services team.'
                                }
                            ]
                        },
                        {
                            category: {
                                slug: 'customer_reviews',
                                title: 'Customer reviews',
                                description: 'Best practices and how it works.',
                                isActive: false
                            },
                            questions: [
                                {
                                    title: 'How it works',
                                    excerption: 'Enter your location and treatment on the homepage.',
                                    description: 'Once a customer has completed a treatment with you, they will be able to leave a review of their experience (Freely encourage customers to be nice, but honest!). You will receive a notification email to let you know when a new review has been left. Simply follow the link from the email to navigate to your profile to see your reviews. If you feel a review is unfair, abusive, discriminatory or slanderous, please get in touch with the Freely team, if we are in agreement with you, the review will be removed.'
                                },
                                {
                                    title: 'Best practices',
                                    excerption: 'Enter excerption excerption excerption 2.',
                                    description: 'Customers love reading reviews prior to booking a treatment, so you will notice that lots of 5 star reviews will result in lots of bookings! Remember to go the extra mile to delight your customers and once you have finished the treatment, remind them to leave you a review. We send the customer a reminder email to leave you a review and they can also do so by clicking the ‘leave review’ button in the summary of their booking with you.'
                                }
                            ]
                        }
                    ]


                },
            };
        },
        computed: {
            clearedSearchQuery() {
                return _.trim(this.searchQuery);
            },
            questionsByActiveCategory() {
                let activeCategory = '';
                for (let i = 0; i < this[this.searchFor].content.length; i++) {
                    if (this[this.searchFor].content[i].category.isActive) {
                        const categories = this.getCategoriesBySearchQuery(this[this.searchFor].content);
                        if (!empty(categories)) {
                            activeCategory = categories.filter((item) => {
                                return item.category.slug === this[this.searchFor].content[i].category.slug;
                            });
                        }
                    }
                }

                if (!empty(activeCategory)) {
                    return _.head(activeCategory).questions;
                } else {
                    return {questions: []};
                }
            },
            categoriesByRole() {
                if (this.clearedSearchQuery) {
                    const categories = this.getCategoriesBySearchQuery(this[this.searchFor].content);
                    if (!empty(categories) && _.head(categories)) {
                        this.deactivateCategories();
                        _.head(categories).category.isActive = true;
                    }
                    return {data: this[this.searchFor].data, content: categories};
                } else {
                    return this[this.searchFor];
                }
            }
        },
        methods: {
            getCategoriesBySearchQuery(data) {
                let results = [];
                let category;
                let content;
                let questions;

                let searchByTitle;
                let searchByExcerp;
                let searchByDesc;

                for (let i = 0; i < data.length; i++) {
                    category = data[i];
                    questions = [];
                    for (let j = 0; j < category.questions.length; j++) {
                        content = category.questions[j];

                        searchByTitle = -1;
                        searchByExcerp = -1;
                        searchByDesc = -1;

                        searchByTitle = content.title.search(new RegExp(this.clearedSearchQuery, 'i'));
                        searchByExcerp = content.excerption.search(new RegExp(this.clearedSearchQuery, 'i'));
                        searchByDesc = content.description.search(new RegExp(this.clearedSearchQuery, 'i'));

                        if (searchByTitle >= 0 || searchByExcerp >= 0 || searchByDesc >= 0) {
                            questions.push(content);
                        }
                    }

                    if (!empty(questions)) {
                        results.push({category: category.category, questions: questions})
                    }
                }

                return results;
            },
            setSearchFor(type) {
                this.searchFor = type;
            },
            deactivateCategories() {
                for (let i = 0; i < this[this.searchFor].content.length; i++) {
                    this[this.searchFor].content[i].category.isActive = false;
                }
            },
            toggleActiveCategory(category) {
                if (category.category.isActive) {
                    category.category.isActive = false;
                } else {
                    this.deactivateCategories();
                    category.category.isActive = true;
                    const navHeight = getHeightOfElement('nav.header');
                    this.$scrollTo('#questions', 500, {offset: Number(navHeight * -1)});
                }
            }
        }

    };
</script>


<style lang="scss">

    @mixin r($width) {
        @media only screen and (max-width: "#{$width}px") {
            @content;
        }
    }

    .support {
        &__wrapper {
            max-width: 1580px;
            margin: 0 auto;
            padding: 0 24px;

            @include r(1700) {
                max-width: 1440px;
            }

            @include r(1500) {
                max-width: 1240px;
            }

            @include r(1400) {
                max-width: 1140px;
            }
        }

        &__content {
            padding: 150px 0 220px 0;

            @include r(1700) {
                padding: 120px 0 200px 0;
            }
            @include r(1500) {
                padding: 70px 0 160px 0;
            }
            @include r(1000) {
                padding: 50px 0 100px 0;
            }

            @include r(700) {
                padding: 50px 0 70px 0;
            }
        }

        &__title {
            display: block;
            text-align: center;
            padding-bottom: 70px;
            border-bottom: 1px solid #b2b2b2;
            @include r(1700) {
                padding-bottom: 50px;
            }

            @include r(1500) {
                padding-bottom: 45px;
            }
            @include r(900) {
                padding-bottom: 25px;
            }
            @include r(700) {
                border-bottom: 0;
                padding-bottom: 0;
            }

            strong {
                display: inline-block;
                color: #272727;
                font-family: Nunito;
                font-size: 55px;
                font-weight: 700;
                line-height: 1.2;

                @include r(1700) {
                    font-size: 45px;
                }
                @include r(1500) {
                    font-size: 40px;
                }
                @include r(1300) {
                    font-size: 37px;
                }
                @include r(700) {
                    font-size: 32px;
                }
                @include r(550) {
                    font-size: 28px;
                }
            }
        }

        &__search {
            display: block;
            max-width: 755px;
            margin: 70px auto 0 auto;

            @include r(1700) {
                max-width: 694px;
                margin-top: 60px;
            }
            @include r(1500) {
                max-width: 634px;
                margin-top: 50px;
            }
            @include r(1400) {
                max-width: 574px;
                margin-top: 40px;
            }
            @include r(850) {
                max-width: 514px;
                margin-top: 30px;
            }

            &-title {
                display: block;
                text-align: center;
                color: #47434d;
                font-size: 25px;
                font-weight: 300;
                line-height: 1.2;

                @include r(1700) {
                    font-size: 23px;
                }
                @include r(1500) {
                    font-size: 21px;
                }
                @include r(1400) {
                    font-size: 19px;
                }
                @include r(850) {
                    font-size: 17px;
                }
            }
            &-form {
                display: block;
                position: relative;
                margin-top: 40px;
                @include r(1500) {
                    margin-top: 32px;
                }

                input {
                    width: 100%;
                    height: 49px;
                    border-radius: 4px;
                    border: 1px solid #000;
                    outline: none;
                    display: block;
                    padding: 0 110px 0 40px;
                    color: #47434d;
                    font-family: Nunito, Sans-Serif;
                    font-size: 20px;
                    font-weight: 400;

                    @include r(1500) {
                        font-size: 18px;
                    }
                    @include r(1200) {
                        font-size: 16px;
                    }

                    @include r(700) {
                        font-size: 14px;
                        padding: 0 80px 0 20px;
                    }
                    @include r(450) {
                        font-size: 12px;
                        padding: 0 60px 0 20px;
                        height: 40px;
                    }

                }

                .v-btn {
                    position: absolute !important;
                    margin: 0;
                    top: 0;
                    right: -1px;
                    width: 73px;
                    height: 49px;
                    border-radius: 4px;
                    background: {
                        image: url(".././images/icons/icon-search.svg");
                        repeat: no-repeat;
                        position: center;
                    }
                    min-width: auto;
                    z-index: 2;
                    @include r(700) {
                        width: 60px;
                        background-size: 20px;
                    }
                    @include r(450) {
                        width: 40px;
                        background-size: 15px;
                        height: 40px;
                    }
                }
            }
        }

        &__navigation {
            display: block;
            margin: 70px auto 0 auto;

            @include r(1700) {
                margin-top: 50px;
            }
            @include r(1500) {
                margin-top: 40px;
            }
            @include r(1000) {
                margin-top: 30px;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            margin: 0 -20px;

            @include r(1000) {
                margin: 0 -10px;
            }
            @include r(650) {
                align-items: stretch;
            }
        }

        &__button {
            flex: 0 0 calc(50% - 40px);
            margin: 0 20px;
            height: 74px;
            border-radius: 10px;
            border: 4px solid #b2b2b2;
            color: #47434d;
            font-size: 20px;
            font-weight: 700;
            line-height: 68px;
            text-align: center;
            cursor: pointer;
            transition: .3s;

            @include r(1000) {
                flex: 0 0 calc(50% - 20px);
                margin: 0 10px;
            }

            @include r(1700) {
                height: 70px;
                line-height: 62px;
            }

            @include r(1500) {
                height: 60px;
                font-size: 18px;
                line-height: 52px;
            }

            @include r(900) {
                height: auto;
                padding: 16px 7px;
                font-size: 16px;
                line-height: 1;
                border-width: 1px;
            }
            @include r(650) {
                flex: 0 0 calc(50% - 10px);
                margin: 0 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
            }

            &:hover, &.is-active {
                background-color: #b2b2b2;
                color: #fff;
                transition: .3s;
            }
        }

        &__bodies {
            display: block;
            margin-top: 65px;
            padding: 0 160px;

            @include r(1700) {
                margin-top: 50px;
            }
            @include r(1500) {
                margin-top: 30px;
                padding: 0 120px;
            }
            @include r(1400) {
                padding: 0 80px;
            }
            @include r(1200) {
                padding: 0 70px;
            }
            @include r(1100) {
                padding: 0 30px;
            }
            @include r(1000) {
                padding: 0;
            }
        }

        &__categories {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -37px;
            align-items: stretch;

            @include r(1500) {
                margin: 0 -25px;
            }
            @include r(900) {
                margin: 0 -10px;
            }
        }

        &__category {
            flex: 0 0 33.3333333%;
            padding: 37px;

            @include r(1500) {
                padding: 25px;
            }
            @include r(900) {
                padding: 10px;
            }
            @include r(820) {
                flex: 0 0 50%;
            }
            @include r(500) {
                flex: 0 0 100%;
            }

            &.is-active {
                .support {
                    &__category {
                        &-btn {
                            border: 4px solid #b2b2b2;
                            transition: .3s;
                        }
                    }
                }
            }

            &-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 50px 40px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 10px;
                background-color: #f7f7f7;
                border: 2px solid transparent;
                cursor: pointer;
                transition: .3s;

                @include r(1500) {
                    padding: 40px 30px;
                }

                @include r(700) {
                    border-width: 4px;
                    padding: 28px 20px;
                }

                &:hover {
                    border-color: #b2b2b2;
                    transition: .3s;
                }
            }
            &-description {
                display: block;
                text-align: center;

                strong {
                    display: block;
                    color: #47434d;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 1.2;

                    @include r(1700) {
                        font-size: 19px;
                    }
                    @include r(1500) {
                        font-size: 18px;
                    }
                    @include r(1300) {
                        font-size: 17px;
                    }
                }

                p {
                    display: block;
                    margin-top: 20px;
                    color: #47434d;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 1.2;
                    @include r(1700) {
                        font-size: 19px;
                        margin-top: 18px;
                    }
                    @include r(1500) {
                        font-size: 18px;
                        margin-top: 16px;
                    }
                    @include r(1300) {
                        font-size: 17px;
                        margin-top: 14px;
                    }
                }
            }
        }

        &__holder {
            margin-top: 40px;
            padding-top: 40px;

            @include r(1500) {
                margin-top: 20px;
                padding-top: 20px;
            }
        }

        &__faq {
            display: block;

            &-header {
                display: block;
                color: #47434d;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.2;

                @include r(1700) {
                    font-size: 19px;
                }

                @include r(1500) {
                    font-size: 18px;
                }
                @include r(1400) {
                    font-size: 17px;
                }
            }

            &-empty {
                display: block;
                text-align: center;
                color: #47434d;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.2;

                @include r(1700) {
                    font-size: 19px;
                }

                @include r(1500) {
                    font-size: 18px;
                }
                @include r(1400) {
                    font-size: 17px;
                }
            }

            &-subheader {
                margin-top: 22px;
                color: #47434d;
                font-size: 20px;
                font-weight: 400;
                line-height: 1.2;
                @include r(1700) {
                    font-size: 19px;
                    margin-top: 18px;
                }

                @include r(1500) {
                    font-size: 18px;
                    margin-top: 16px;
                }
                @include r(1400) {
                    font-size: 17px;
                }
                @include r(1000) {
                    font-size: 16px;
                }
                @include r(700) {
                    font-size: 15px;
                }
            }
            &-description {
                line-height: 1.2;
                color: #47434d;
                font-size: 20px;
                font-weight: 400;
                @include r(1700) {
                    font-size: 19px;
                }

                @include r(1500) {
                    font-size: 18px;
                }
                @include r(1400) {
                    font-size: 17px;
                }
                @include r(1000) {
                    font-size: 16px;
                }
                @include r(700) {
                    font-size: 15px;
                }
            }

            .v-expansion-panel {
                border: 0;
                box-shadow: none;
            }

            .v-expansion-panel__container {
                background-color: transparent !important;
                border-bottom: 0 !important;
                border-top: 1px solid #b2b2b2 !important;
            }
            .v-expansion-panel__header {
                padding: 26px 0;

                @include r(1700) {
                    padding: 23px 0;
                }

                @include r(1500) {
                    padding: 20px 0;
                }
            }
            .v-expansion-panel__header__icon {
                //display: none;
                .v-icon {
                    font-size: 30px;
                }
            }
            .v-expansion-panel__body {
                .theme--light.v-sheet {
                    background-color: transparent !important;
                }

                .v-card__text {
                    padding: 0 0 30px 0;
                }
            }
        }
    }


</style>
