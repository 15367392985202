<template>
  <v-toolbar
      app
      class="header"
  >
    <div class="header__lside">
      <div class="header__inner">
        <div
            class="header__logo"
            @click="onHome()"
        >
          <img
              src=".././images/icons/freelylogo-black.svg"
              alt=""
          >
        </div>
      </div>
    </div>
    <div class="header__rside header--mob">
      <div class="header__inner">
        <div
            class="header__link"
            :class="[currentPage.includes('services') ? isActivePage: '']"
            @click="onServices()"
        >
          Services
        </div>
      </div>
      <div class="header__inner">
        <div
            class="header__link"
            :class="[currentPage.includes('how-it-works') ? isActivePage: '']"
            @click="onHowItWorks()"
        >
          {{ $t('header.how_it_works') }}
        </div>
      </div>
      <div class="header__inner">
        <div
            class="header__link"
            :class="[currentPage.includes('hygiene') ? isActivePage: '']"
            @click="onHygiene"
        >
          Hygiene
        </div>
      </div>
      <div class="header__inner">
        <div
            class="header__link"
            :class="[currentPage.includes('join-freely') ? isActivePage: '']"
            @click="onJoinFreely()"
        >
          Work with us
        </div>
      </div>
      <template v-if="!this.$store.getters.isUserLoggedIn">
        <div class="header__inner is-hidden">
          <router-link
              to="/login"
              tag="span"
              class="header__link header__link--lg"
          >
            {{ $t('header.how_it_works') }}
          </router-link>
        </div>
        <div class="header__inner">
          <router-link
              to="/login"
              tag="span"
              class="header__link"
          >
            {{ $t('header.log_in') }}
          </router-link>
        </div>
        <div class="header__inner">
          <router-link
              to="/practitioner-registration"
              tag="span"
              class="header__sign-up"
          >
            {{ $t('header.practitioner_sign_up') }}
          </router-link>
        </div>
      </template>

      <template v-if="this.$store.getters.isUserLoggedIn">
        <div
            class="header__inner"
            v-if="this.$store.getters.currentUser.user_roles.includes('admin')"
        >
          <div
              class="header__link header__link--lg"
              :class="{ 'router-link-active': isAdminBtnActive }"
              @click="onAdmin()"
          >
            {{ $t('header.admin_dashboard') }}
          </div>
        </div>
        <div
            class="header__inner"
            v-if="this.$store.getters.currentUser.user_roles.includes('practitioner')"
        >
          <div
              class="header__link header__link--lg"
              :class="{ 'router-link-active': isPractitionerBtnActive }"
              @click="onPractitioner()"
          >
            {{ $t('header.practitioner_account') }}
          </div>
        </div>
        <div
            class="header__inner"
            v-if="this.$store.getters.currentUser.user_roles.includes('practitioner') || this.$store.getters.currentUser.user_roles.includes('customer')"
        >
          <div
              class="header__link header__link--lg"
              :class="{ 'router-link-active': isCustomerBtnActive }"
              @click="onCustomer()"
          >
            {{ $t('header.customer_account') }}
          </div>
        </div>
      </template>

      <div
          v-if="this.$store.getters.isUserLoggedIn"
          class="header__inner"
      >
        <router-link
            to="/logout"
            class="header__link"
        >
          {{ $t('header.logout') }}
        </router-link>
      </div>
    </div>
    <div
        class="burger-menu"
        @click="mobileMenuOpenClose"
    >
      <span></span>
      <span></span>
    </div>

  </v-toolbar>
</template>

<script>
import {empty} from '@/helpers.js';

/* eslint-disable */

export default {
  data() {
    return {
      isAdminBtnActive: false,
      isPractitionerBtnActive: false,
      isCustomerBtnActive: false,

      isSearchFormValid: true,
      addressRules: [v => !!v || 'Address is required'],

      selectedSearchAddress: '',
      searchTown: '',
      searchPostcode: '',
      searchLatitude: '',
      searchLongitude: '',

      isActivePage: 'router-link-active'
    }
  },
  computed: {
    currentPage() {
      return this.$route.path;
    }
  },
  mounted() {
    this.checkRoutes();
  },
  watch: {
    '$route'(to, from) {
      this.checkRoutes();
    }
  },
  methods: {
    checkRoutes() {
      if (this.$router.currentRoute.name != null && this.$router.currentRoute.name.indexOf('practitioner-dashboard') !== -1) {
        this.isPractitionerBtnActive = true;
        this.isAdminBtnActive = false;
        this.isCustomerBtnActive = false;
      } else if (this.$router.currentRoute.name != null && this.$router.currentRoute.name.indexOf('customer-dashboard') !== -1) {
        this.isCustomerBtnActive = true;
        this.isAdminBtnActive = false;
        this.isPractitionerBtnActive = false;
      } else if (this.$router.currentRoute.name != null && this.$router.currentRoute.name.indexOf('admin-dashboard') !== -1) {
        this.isAdminBtnActive = true;
        this.isCustomerBtnActive = false;
        this.isPractitionerBtnActive = false;
      } else {
        this.isAdminBtnActive = false;
        this.isCustomerBtnActive = false;
        this.isPractitionerBtnActive = false;
      }
    },
    onAdmin() {
      this.isAdminBtnActive = true;
      this.isCustomerBtnActive = false;
      this.isPractitionerBtnActive = false;
      this.$router.push("/admin");
    },
    onPractitioner() {
      this.isAdminBtnActive = false;
      this.isCustomerBtnActive = false;
      this.isPractitionerBtnActive = true;
      this.$router.push("/practitioner/bookings");
    },
    onCustomer() {
      this.isAdminBtnActive = false;
      this.isPractitionerBtnActive = false;
      this.isCustomerBtnActive = true;
      this.$router.push("/customer/bookings");
    },
    getAddressData: function (addressData, placeResultData) {
      if (!empty(addressData) && !empty(placeResultData)) {
        this.clearAddressData();
        this.parseAddress(addressData, placeResultData);
        this.$store.dispatch('RESET_SEARCH_PARAMS')
            .then(() => {
              this.$store.dispatch('SET_SEARCH_PARAMS', {
                searchPostcode: this.searchPostcode,
                searchCountryName: addressData.country,
                searchCountryId: 'UK',
                searchAddressComponents: placeResultData.address_components
              });
            });
      }
    },
    clearAddressData() {
      this.selectedSearchAddress = '';
      this.searchTown = '';
      this.searchPostcode = '';
      this.searchLatitude = '';
      this.searchLongitude = '';
    },
    parseAddress(addressData, placeResultData) {
      if (!empty(placeResultData.name)) {
        this.searchAddress = placeResultData.name;
      }
      if (!empty(placeResultData.address_components)) {
        for (let i = 0; i < placeResultData.address_components.length; i++) {
          if (placeResultData.address_components[i].types.includes('postal_town')) {
            this.searchTown = placeResultData.address_components[i].long_name
          }
          if (placeResultData.address_components[i].types.includes('locality') && empty(this.searchTown)) {
            this.searchTown = placeResultData.address_components[i].long_name
          }
          if (placeResultData.address_components[i].types.includes('postal_code')) {
            this.searchPostcode = placeResultData.address_components[i].long_name
          }
        }

        if (addressData.latitude) {
          this.searchLatitude = addressData.latitude.toFixed(7);
        }
        if (addressData.longitude) {
          this.searchLongitude = addressData.longitude.toFixed(7);
        }
      }
    },
    getQueryObject() {
      const query = {};
      if (!empty(this.selectedSearchAddress)) {
        query['address'] = this.selectedSearchAddress;
      }
      if (!empty(this.searchTown)) {
        query['town'] = this.searchTown;
      }
      if (!empty(this.searchLatitude)) {
        query['lat'] = this.searchLatitude;
      }
      if (!empty(this.searchLongitude)) {
        query['lon'] = this.searchLongitude;
      }

      return query;
    },
    noResultsFound() {
      console.log("not found");
    },
    onHome() {
      this.$router.push({name: 'home'});
      this.$scrollTo('.header.v-toolbar');
    },
    onJoinFreely() {
      this.$router.push({name: 'join-freely'});
      this.$scrollTo('.header.v-toolbar');
    },
    onHygiene() {
      this.$router.push({name: 'hygiene'});
      this.$scrollTo('.header.v-toolbar');
    },
    onServices() {
      this.$router.push({name: 'services'});
      this.$scrollTo('.header.v-toolbar');
    },
    onHowItWorksPractitioner() {
      this.$router.push({name: 'how-it-works-practitioner'});
      this.$scrollTo('.header.v-toolbar');
    },
    onHowItWorks() {
      const currentUserRoles = this.$store.getters.currentUser.user_roles;
      // customer
      if (currentUserRoles.length == 1) {
        this.$router.push({name: 'how-it-works-customer'});
      }
      // practitioner
      if (currentUserRoles.length == 2) {
        this.$router.push({name: 'how-it-works-practitioner'});
      }
      // guest
      if (currentUserRoles.length == 0) {
        this.$router.push({name: 'how-it-works'});
      }
      this.$scrollTo('.header.v-toolbar');
    },
    mobileMenuOpenClose() {
      const body = document.body;
      if (body.classList.contains('is-open-menu')) {
        body.classList.remove('is-open-menu');
        this.$emit('toggleMobileMenu', false);
      } else {
        body.classList.add('is-open-menu');
        this.$emit('toggleMobileMenu', true);
      }
    },
    search() {
      if (this.$refs.searchForm.validate()) {
        const query = this.getQueryObject();
        this.selectedSearchAddress = '';
        this.$refs.searchInHeader.clear();
        this.$router.push({path: '/practitioner-search', query: query});
      } else {
        this.$router.push({path: '/services-search'});
      }
    }
  },
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.header {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
  padding: 0 !important;

  @include r(1000) {
    background-color: #f9f9f9 !important;
  }

  .v-toolbar__content {
    padding: 39px 90px;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include r(1800) {
      padding: 30px 60px;
    }

    @include r(1700) {
      padding: 24px 50px;
    }

    @include r(1500) {
      padding: 20px 40px;
    }

    @include r(1300) {
      padding: 20px 30px;
    }

    @include r(650) {
      padding: 18px 82px 18px 24px;
    }
  }

  &__logo {
    position: relative;
    max-width: 128px;
    font-size: 0;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
      transition: 0.3s;
    }

    img {
      width: 100%;
    }

    @include r(1700) {
      max-width: 108px;
    }

    @include r(1300) {
      max-width: 94px;
    }

    @include r(650) {
      max-width: 66px;
    }
  }

  &__lside {
    display: flex;
    align-items: center;
  }

  &__search {
    display: block;
    position: relative;

    .v-input__append-inner {
      display: none !important;
    }

    .v-form .v-text-field {
      margin-top: 0;
    }

    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 100% !important;
    }

    .v-input__slot {
      width: 336px !important;
      height: 49px !important;
      border-radius: 4px !important;
      border: 1px solid #000;
      text-align: center;
      outline: none;
      display: block;
      padding: 0 87px 0 14px !important;
      color: #47434d;
      font-family: Nunito, Sans-Serif;
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      background-color: transparent !important;
      min-height: 100% !important;

      .v-label {
        font-size: 20px;
        left: 0;
        min-width: 100%;
        text-align: left;

        @include r(1500) {
          font-size: 18px;
        }
        @include r(1500) {
          font-size: 17px;
        }
        @include r(1100) {
          font-size: 14px;
        }
        @include r(650) {
          font-size: 11px;
        }
      }

      @include r(1500) {
        padding-right: 74px !important;
        width: 300px !important;
        height: 48px !important;
        font-size: 18px;
      }
      @include r(1400) {
        width: 280px !important;
        height: 40px !important;
        font-size: 17px;
      }
      @include r(1100) {
        width: 240px !important;
        font-size: 14px !important;
      }

      @include r(650) {
        padding-right: 50px !important;
        width: 100% !important;
        height: 32px !important;
        font-size: 11px !important;
      }
    }

    .v-text-field input {
      width: 100%;
      height: 100%;
    }

    .v-text-field__slot {
      height: 100%;
      width: 100%;
    }

    .v-btn {
      position: absolute !important;
      margin: 0;
      top: 0;
      right: -1px;
      width: 73px;
      height: 49px;
      border-radius: 0 4px 4px 0;
      background: {
        image: url(".././images/icons/icon-search.svg");
        repeat: no-repeat;
        position: center;
      }
      min-width: auto;
      z-index: 2;

      @include r(1500) {
        height: 48px;
        width: 63px;
        background-size: 19px;
      }

      @include r(1400) {
        height: 40px;
        width: 63px;
        background-size: 19px;
      }
      @include r(650) {
        width: 38px;
        height: 32px;
        background-size: 12px;
      }
    }
  }

  &__rside {
    display: flex;
    align-items: center;

    .header {
      &__inner {
        margin-left: 50px;

        @include r(1600) {
          margin-left: 30px;
        }

        @include r(1500) {
          margin-left: 25px;
        }

        @include r(1200) {
          margin-left: 15px;
        }

        @include r(650) {
          margin-left: 20px;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__phone {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }
  }

  &__sign-up {
    display: inline-block;
    padding: 8px 40px;
    border-radius: 4px;
    border: 1px solid #f25757;
    color: #f25757;
    font-family: Nunito, Sans-Serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    @include r(1500) {
      padding: 6px 30px;
      font-size: 18px;
    }

    @include r(1300) {
      padding: 7px 28px;
      font-size: 17px;
    }
    @include r(1200) {
      font-size: 15px;
      padding: 6px 28px;
    }

    &:hover {
      background-color: #f25757;
      color: #fff;
      transition: 0.3s;
    }
  }

  &__inner {
    display: inline-block;

    &.is-hidden {
      display: none;
    }
  }

  &__link {
    display: inline-block;
    color: #272727;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 400;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
    text-decoration: none;

    &:hover {
      border-bottom-color: #535353;
      transition: 0.3s;
    }

    &.router-link-active {
      border-bottom-color: #535353;
      transition: 0.3s;
    }

    @include r(1500) {
      font-size: 18px;
    }

    @include r(1300) {
      font-size: 17px;
    }

    @include r(1200) {
      font-size: 15px;
    }

    &--lg {
      padding: 11px 18px;
      border-radius: 4px;
      background-color: transparent;
      border: none;

      @include r(1800) {
        padding: 11px 18px;
      }

      @include r(1500) {
        padding: 9px 18px;
      }

      @include r(1300) {
        padding: 9px 18px 9px 18px;
      }

      &:hover {
        background-color: #f25757;
        color: #fff;
        transition: 0.3s;
      }

      &.router-link-active {
        background-color: #f25757;
        color: #fff;
        transition: 0.3s;
      }
    }
  }

  .burger-menu {
    display: none;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    right: 24px;
    width: 32px;
    height: 22px;
    cursor: pointer;
    z-index: 2;
    transition: 0.3s;

    @include r(1000) {
      display: block;
    }

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 2px;
      background-color: #000;
      transition: 0.3s;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #000;
      transition: 0.3s;
    }

    span {
      display: block;
      position: absolute;
      top: 10px;
      width: 100%;
      height: 2px;
      transform: rotate(0deg);
      background-color: #000;
      transition: 0.3s;
    }
  }

  &--mob {
    @include r(1000) {
      display: none;
    }
  }
}

.mob-menu {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
  transform: translateY(-100%);
  transition: 0.3s;
  padding-top: 80px;

  @include r(650) {
    padding-top: 68px;
  }

  &__container {
    display: block;
    max-height: 100%;
    overflow-y: auto;

    ul {
      display: block;
      margin: 0 auto;
      text-align: center;
      max-width: 300px;
      padding: 15px;

      li {
        display: block;
        margin: 10px;

        span {
          width: 100%;
          height: 100%;
          padding: 28px;
          color: #383838;
          font-family: Nunito;
          font-size: 19px;
          line-height: 1;
          font-weight: 400;
          display: block;
          border-bottom: 1px solid #ebebeb;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

body.is-open-menu {
  @include r(1000) {
    overflow: hidden;

    .burger-menu {
      &::before,
      &::after {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
      }

      span {
        &:nth-child(1) {
          transform: rotate(-45deg);
          transition: 0.3s;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
          transition: 0.3s;
        }
      }
    }

    .header {
      &__lside {
        opacity: 0;
        pointer-events: none;
      }

      &.theme--light.v-toolbar {
        background-color: #fff !important;
      }
    }
    .mob-menu {
      visibility: visible;
      transform: translateY(0);
      transition: 0.3s;
    }
  }
}
</style>
