<template>
  <div>
    <DashboardSidebar></DashboardSidebar>
  </div>
</template>

<script>
  import DashboardSidebar from '@/components/PractitionerDashboard/DashboardSidebar';

  export default {
    components: {
      DashboardSidebar,
    },
  };
</script>

<style scoped lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }
</style>
