<template>
  <div
    class="dashboard-content dashboard-practitioner"
    id="dashboard-content"
  >
    <div class="dashboard-content__title">
      <h2>
        {{$t('practitioner_profile.dashboard_tab_dashboard')}}
      </h2>
    </div>
    <div class="dashboard-content__body">
      <div class="dashboard-practitioner__title">
        {{$t('practitioner_profile.dashboard_tab_do_not_forget')}}
      </div>
      <div class="dashboard-practitioner__info">
        <div class="dashboard-practitioner__info-inner">
          <div class="dashboard-practitioner__info-title">
            <div class="dashboard-practitioner__info-icon">
              <img src="../../../src/images/icons/icon-upload-photo.svg" alt="">
            </div>
            <strong>
              {{$t('practitioner_profile.dashboard_tab_upload_profile_photo')}}
            </strong>
          </div>
          <div class="dashboard-practitioner__info-status">
            <img
                    v-if="statistic.avatar"
                    src="../../../src/images/icons/icon-checked-green.svg"
                    alt=""
            >
            <img
                    v-else
                    src="../../../src/images/icons/icon-x-red.svg"
                    alt=""
            >
          </div>
        </div>
        <div class="dashboard-practitioner__info-inner">
          <div class="dashboard-practitioner__info-title">
            <div class="dashboard-practitioner__info-icon">
              <img src="../../../src/images/icons/icon-camera.svg" alt="">
            </div>
            <strong>
              {{$t('practitioner_profile.dashboard_tab_upload_images_of_work')}}
            </strong>
          </div>
          <div class="dashboard-practitioner__info-status">
            <img
                    v-if="statistic.work_images"
                    src="../../../src/images/icons/icon-checked-green.svg"
                    alt=""
            >
            <img
                    v-else
                    src="../../../src/images/icons/icon-x-red.svg"
                    alt=""
            >
          </div>
        </div>
        <div class="dashboard-practitioner__info-inner">
          <div class="dashboard-practitioner__info-title">
            <div class="dashboard-practitioner__info-icon">
              <img src="../../../src/images/icons/icon-bio.svg" alt="">
            </div>
            <strong>
              {{$t('practitioner_profile.dashboard_tab_write_your_bio')}}
            </strong>
          </div>
          <div class="dashboard-practitioner__info-status">
            <img
                    v-if="statistic.bio"
                    src="../../../src/images/icons/icon-checked-green.svg"
                    alt=""
            >
            <img
                    v-else
                    src="../../../src/images/icons/icon-x-red.svg"
                    alt=""
            >
          </div>
        </div>
        <div class="dashboard-practitioner__info-inner">
          <div class="dashboard-practitioner__info-title">
            <div class="dashboard-practitioner__info-icon">
              <img src="../../../src/images/icons/icon-calendar.svg" alt="">
            </div>
            <strong>
              {{$t('practitioner_profile.dashboard_tab_update_your_calendar')}}
            </strong>
          </div>
          <div class="dashboard-practitioner__info-status">
            <img
                    v-if="statistic.update_calendar"
                    src="../../../src/images/icons/icon-checked-green.svg"
                    alt=""
            >
            <img
                    v-else
                    src="../../../src/images/icons/icon-x-red.svg"
                    alt=""
            >
          </div>
        </div>
        <div class="dashboard-practitioner__info-inner">
          <div class="dashboard-practitioner__info-title">
            <div class="dashboard-practitioner__info-icon">
              <img src="../../../src/images/icons/icon-bank-details.svg" alt="">
            </div>
            <strong>
              {{$t('practitioner_profile.dashboard_tab_add_bank_details')}}
            </strong>
          </div>
          <div class="dashboard-practitioner__info-status">
            <img
                    v-if="statistic.bank_details"
                    src="../../../src/images/icons/icon-checked-green.svg"
                    alt=""
            >
            <img
                    v-else
                    src="../../../src/images/icons/icon-x-red.svg"
                    alt=""
            >
          </div>
        </div>
      </div>
      <div class="dashboard-practitioner__title dashboard-practitioner__title--border">
        {{$t('practitioner_profile.dashboard_tab_summary')}}
      </div>
      <div class="dashboard-practitioner__summary">
        <div class="dashboard-practitioner__summary-inner">
          <strong>
            {{$t('practitioner_profile.dashboard_tab_number_of_appointments')}}
          </strong>
          <h3>{{statistic.number_of_bookings}}</h3>
        </div>
        <div class="dashboard-practitioner__summary-inner">
          <strong>
            {{$t('practitioner_profile.dashboard_tab_total_income')}}
          </strong>
          <h3>£ {{statistic.total_income}}</h3>
        </div>
      </div>
      <div class="dashboard-cards dashboard-cards--practitioner">
        <div class="dashboard-cards__title">
          <span>
            {{$t('practitioner_profile.dashboard_tab_upcoming_appointments')}}
          </span>
        </div>

        <div class="dashboard-cards__inners">
          <div class="dashboard-cards__inner" v-for="(booking, index_b) in upcomingAppointments" :key="index_b">
            <div class="dashboard-cards__card">
              <div class="dashboard-cards__card-top">
                <div class="dashboard-cards__card-photo">
                  <img v-if="loadImg"
                       :src="getImgUrl(booking.first_service_name)"
                       alt=""
                  >
                </div>
                <div class="dashboard-cards__card-description">
                  <div class="dashboard-cards__card-info">
                    <div class="dashboard-cards__card-name">{{booking.customer_name | formatName}}</div>
                    <div class="dashboard-cards__card-date">{{booking.date | formatDate}}</div>
                  </div>
                  <div class="dashboard-cards__card-type" v-for="(treatment, index_t) in booking.treatments"
                       :key="index_t">
                    {{treatment.name}}
                  </div>
                  <div class="dashboard-cards__card-location">{{booking.location_type | capitalizeFirstLetter}}</div>
                  <div class="dashboard-cards__card-location" v-if="booking.show_phone">Phone:
                    {{booking.customer_phone}}
                  </div>
                  <div class="dashboard-cards__card-actions">
                    <v-btn class="btn-lite" @click="booking.show_phone = true">Contact</v-btn>
                    <v-btn class="error" @click="redirectToBooking(booking.id)">View</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty, getDisplayUserName, capitalizeFirstLetter} from '@/helpers.js';
  import moment from 'moment';
  import _ from 'lodash';

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Practitioner Dashboard'
    },
    data() {
      return {
        statistic: {
          avatar: false,
          work_images: false,
          bio: false,
          update_calendar: false,
          bank_details: false,
          number_of_bookings: 0,
          total_income: 0
        },
        upcomingAppointments:{},
        loadImg: false
      }
    },
    mounted() {
      // this.$router.push({name: 'practitioner-dashboard'});
      this.getStatistic();
      this.getBookings();
    },
    filters:{
      capitalizeFirstLetter: function (value) {
        return capitalizeFirstLetter(value);
      },
      formatDate: function (dateValue) {
        return moment(dateValue, moment.ISO_8601).clone().format('DD/MM/YYYY');
      },
      formatName: function (name) {
        return getDisplayUserName(name);
      }
    },
    methods:{
      redirectToBooking(booking_id){
        this.$router.push({name: 'practitioner-dashboard-bookings', query:{'open-booking': booking_id}});
      },
      getImgUrl(service){
        const images = require.context('../../../src/images/services/', false, /\.png$/);
        return images('./' + service + "-lg.png")
      },
      getBookings() {
        this.requestBookings()
          .then((response) => {
            const data = response.data;
            let filteredData = data.filter((booking) => {
              const startTime = moment(booking.date, moment.ISO_8601).clone().format("x");
              const nowTime = moment().subtract(15, 'minutes').format("x");
              if (startTime > nowTime && booking.booking_status !== 'cancelled by customer' && booking.booking_status !== 'cancelled by practitioner') {
                return true;
              } else {
                return false;
              }
            });

            this.upcomingAppointments = this.prepareUpcomingAppointments(filteredData);
            this.loadImg = true;
          })
      },
      requestBookings() {
        return axios({
          method: "get",
          url: `${CONFIG.API_URL}/dashboard/practitioner/bookings`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          }
        })
      },
      prepareUpcomingAppointments(bookings){
        for(let i=0; i<bookings.length; i++){
          bookings[i].show_phone = false;
          let serviceName = (!empty(_.head(bookings[i].treatments))) ? _.head(bookings[i].treatments).service : '';
          bookings[i].first_service_name = serviceName;
        }
        return bookings;
      },
      getStatistic() {
        this.requestStatistic()
          .then((response) => {
            const data = response.data;
            this.statistic.avatar  = data.avatar;
            this.statistic.work_images  = data.work_images;
            this.statistic.bio  = data.bio;
            this.statistic.update_calendar  = data.update_calendar;
            this.statistic.bank_details  = data.bank_details;
            this.statistic.number_of_bookings  = data.number_of_bookings;
            this.statistic.total_income  = data.total_income;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          })
      },
      requestStatistic(){
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/practitioner/statistic-by-profile`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      }
    }
  };
</script>

<style scoped lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .dashboard-practitioner {
    .dashboard-content {
      &__body {
        @include r(1000) {
          padding-top: 0;
        }
      }
    }
    &__title {
      display: block;
      color: #383838;
      font-family: Nunito, sans-serif;
      font-size: 27px;
      font-weight: 600;

      @include r(1500) {
        font-size: 25px;
      }
      @include r(1400) {
        font-size: 23px;
      }
      @include r(1140) {
        text-align: center;
      }

      @include r(1000) {
        font-size: 20px;
      }

      @include r(500) {
        font-size: 16px;
      }

      &--border {
        padding-top: 40px;
        margin-top: 40px;
        position: relative;
        @include r(1000) {
          padding-top: 0;
        }
        @include r(767) {
          margin-top: 30px;
        }
        &::before {
          position: absolute;
          content: "";
          top: 0;
          height: 1px;
          left: 0;
          width: 100%;
          background-color: #f7cecf;

          @include r(1000) {
            display: none;
          }
        }
      }
    }
    &__info {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;

      @include r(767) {
        display: block;
      }

      &-inner {
        flex: 0 0 20%;
        padding: 17px;

        @include r(1500) {
          padding: 12px;
        }

        @include r(1200) {
          padding: 8px;
        }
        @include r(767) {
          display: flex;
          align-items: center;
          padding: 0;
          margin-top: 10px;
          justify-content: center;
        }
      }
      &-title {
        display: block;
        border-radius: 5px;
        background-color: #fff;
        padding: 20px 16px;

        @include r(767) {
          flex: 1 1 auto;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          max-width: 300px;
        }

        strong {
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          color: #272727;
          font-family: Nunito, sans-serif;
          font-size: 20px;
          line-height: 1.2;
          font-weight: 700;
          margin-top: 18px;
          min-height: 48px;

          @include r(1800) {
            font-size: 19px;
            margin-top: 16px;
          }
          @include r(1700) {
            font-size: 17px;
            margin-top: 14px;
          }
          @include r(1500) {
            font-size: 15px;
          }
          @include r(1100) {
            min-height: 58px;
          }
          @include r(767) {
            flex: 1 1 auto;
            margin: 0;
            padding: 0 0 0 20px;
            text-align: left;
            justify-content: flex-start;
          }
        }
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        min-height: 56px;
        max-height: 56px;

        @include r(1700) {
          min-height: 50px;
          max-height: 50px;
          max-width: 54px;
        }
        @include r(1400) {
          min-height: 40px;
          max-height: 40px;
          max-width: 45px;
        }
        @include r(767) {
          min-height: 30px;
          max-height: 30px;
          max-width: 35px;
          flex: 0 0 auto;
          margin: 0;
        }
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
      &-status {
        display: block;
        text-align: center;
        margin-top: 30px;
        font-size: 0;

        @include r(1700) {
          margin-top: 26px;
        }
        @include r(1500) {
          margin-top: 20px;
        }
        @include r(1400) {
          margin-top: 15px;
        }

        @include r(767) {
          flex: 0 0 auto;
          margin: 0 0 0 30px;
        }
        @include r(400) {
          margin: 0 0 0 20px;
        }

        img {
          display: inline-block;
          max-width: 43px;
          width: 100%;

          @include r(1700) {
            max-width: 38px;
          }
          @include r(1500) {
            max-width: 34px;
          }
          @include r(1400) {
            max-width: 30px;
          }
          @include r(400) {
            max-width: 28px;
          }
        }
      }
    }

    &__summary {
      display: flex;
      align-items: stretch;
      margin: 10px -15px 0 -15px;

      @include r(767) {
        display: block;
        margin: 24px 0 0 0;
      }

      &-inner {
        margin: 30px 48px 0 48px;
        border-radius: 5px;
        background-color: #fff;
        display: block;
        text-align: center;
        padding: 40px 40px 60px 40px;
        flex: 0 0 calc(50% - 96px);

        @include r(1840) {
          padding: 34px;
          margin: 30px 20px 0 20px;
          flex: 0 0 calc(50% - 40px);
        }
        @include r(1700) {
          padding: 30px;
        }
        @include r(1500) {
          padding: 24px;
        }
        @include r(767) {
          max-width: 360px;
          margin: 10px auto 0 auto;
        }

        strong {
          display: block;
          color: #272727;
          font-family: Nunito, sans-serif;
          font-size: 20px;
          font-weight: 700;
          @include r(1800) {
            font-size: 19px;
          }
          @include r(1700) {
            font-size: 17px;
          }
          @include r(1500) {
            font-size: 15px;
          }
        }

        h3 {
          display: block;
          color: #272727;
          font-family: Nunito, sans-serif;
          font-size: 40px;
          margin-top: 50px;
          font-weight: 700;

          @include r(1800) {
            font-size: 35px;
            margin-top: 40px;
          }
          @include r(1700) {
            font-size: 32px;
            margin-top: 35px;
          }
          @include r(1500) {
            font-size: 28px;
            margin-top: 20px;
          }
          @include r(1400) {
            font-size: 24px;
            margin-top: 16px;
          }
          @include r(767) {
            font-size: 20px;
            margin-top: 15px;
          }
        }
      }
    }
  }
</style>
