import {isLocalStorage} from '@/helpers.js';

class LocalStorage {

  isAvailableStorage() {
    return isLocalStorage();
  }

  deleteValue(name) {
    localStorage.removeItem(name);
  }

  saveValue(name, value) {
    localStorage.setItem(name, value);
  }

  getValue(name) {
    return localStorage.getItem(name);
  }
}

export default new LocalStorage();