<template>
  <div class="profile-sorting" :class="{ hide: $vuetify.breakpoint.width <= 980}">
    <div class="profile-sorting__item">
      <strong class="profile-sorting__strong">{{ searchAddressUpdated }}</strong>
      <button
              class="profile-sorting__delete"
              @click="breadcrumbsCloseFirst"
      >
        <v-icon>close</v-icon>
      </button>
    </div>
    <div class="profile-sorting__item">
      <strong class="profile-sorting__strong">{{ $t(`practitioner_search.service_type_${searchServiceType}`) }}</strong>
      <button
              class="profile-sorting__delete"
              @click="breadcrumbsCloseSecond"
      >
        <v-icon>close</v-icon>
      </button>
    </div>
    <div class="profile-sorting__item profile-sorting__item--mob">
      <strong class="profile-sorting__strong">{{ searchLocationType }}</strong>
      <button
              class="profile-sorting__delete"
              @click="breadcrumbsCloseThird"
      >
        <v-icon>close</v-icon>
      </button>
    </div>
    <div class="profile-sorting__item profile-sorting__item--mob">
      <strong class="profile-sorting__strong">{{ bookingPractitioner }}</strong>
      <button
              class="profile-sorting__delete"
              @click="breadcrumbsCloseFourth"
      >
        <v-icon>close</v-icon>
      </button>
    </div>
    <div class="profile-sorting__item profile-sorting__item--mob">
      <strong class="profile-sorting__strong">{{ bookingDate }}</strong>
      <button
              class="profile-sorting__delete"
              @click="breadcrumbsCloseFifth"
      >
        <v-icon>close</v-icon>
      </button>
    </div>
    <div class="profile-sorting__item profile-sorting__item--mob">
      <span class="profile-sorting__span">Confirm Payment</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "breadcrumbs",
    props:[
      'searchAddress',
      'searchServiceType',
      'searchLocationType',
      'bookingPractitioner',
      'bookingPractitionerId',
      'bookingDate'
    ],
    data(){
      return{
          searchAddressUpdated: this.searchAddress,
          window: {
              width: 0,
              height: 0
          }
      }
    },
    created: function () {
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods:{
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
        if (this.window.width < 980) {
          this.searchAddressUpdated = this.$store.getters.search.searchPostcode;
        } else {
          this.searchAddressUpdated = this.$store.getters.search.searchAddress;
        }
      },
      breadcrumbsCloseFirst () {
        this.$router.push({ path: '/services-search' });
      },
      breadcrumbsCloseSecond () {
        this.$router.push({
          path: '/services-search', query: {
            address: this.$store.getters.search.searchAddress,
            town: this.$store.getters.search.searchTown,
            lat: this.$store.getters.search.searchLatitude,
            lon: this.$store.getters.search.searchLongitude,
          }
        });
      },
      breadcrumbsCloseThird () {
        this.$router.push({
          path: '/select-location', query: {
            address: this.$store.getters.search.searchAddress,
            town: this.$store.getters.search.searchTown,
            lat: this.$store.getters.search.searchLatitude,
            lon: this.$store.getters.search.searchLongitude,
            // id: this.$store.getters.search.searchSubServiceId,
            // service: this.$store.getters.search.searchSubServiceName,
            type: this.$store.getters.search.searchServiceType
          }
        });
      },
      breadcrumbsCloseFourth(){
        this.$router.push({
          path: '/practitioner-search', query: {
            address: this.$store.getters.search.searchAddress,
            town: this.$store.getters.search.searchTown,
            lat: this.$store.getters.search.searchLatitude,
            lon: this.$store.getters.search.searchLongitude,
            // id: this.$store.getters.search.searchSubServiceId,
            // service: this.$store.getters.search.searchSubServiceName,
            type: this.$store.getters.search.searchServiceType,
            location: this.$store.getters.search.searchLocationType,
          }
        });
      },
      breadcrumbsCloseFifth(){
        const props = {
          id: this.bookingPractitionerId,
          withBookingState: 'with-booking-state'
        };
        this.$router.push({ name: 'search-practitioner-availability', params: props });
      }
    }
  }
</script>

<style scoped>

  .hide{
    display: none !important;
  }

</style>
