<template>
  <v-card class="register-card login-form login-form-page">
    <v-card-text>
      <p class="login-form__top">
        {{$t('auth_forms.title_reset_your_password')}}
      </p>
      <div class="login-form__middle">

        <v-form
                ref="resetForm"
                v-model="isResetFormValid"
                lazy-validation
        >
          <v-text-field
                  name="email"
                  :label="$t('auth_forms.email')"
                  type="email"
                  :rules="emailRules"
                  v-model="email"
                  :error-messages="emailErrorMess"
                  @focus="clearErrorMess('emailErrorMess')"
                  @keyup.enter="onSubmit"
                  required
                  solo
                  class="solo-field-white"
          ></v-text-field>

          <v-text-field
                  name="password"
                  :label="$t('auth_forms.password')"
                  type="password"
                  :rules="passwordRules"
                  v-model="password"
                  :error-messages="passwordErrorMess"
                  @focus="clearErrorMess('passwordErrorMess')"
                  @keyup.enter="onSubmit"
                  required
                  solo
                  class="solo-field-white"
          ></v-text-field>


          <v-text-field
                  name="confirm-password"
                  :label="$t('auth_forms.password_confirmation')"
                  type="password"
                  :rules="password_confirmationRules"
                  v-model="password_confirmation"
                  :error-messages="password_confirmationErrorMess"
                  @focus="clearErrorMess('password_confirmationErrorMess')"
                  required
                  solo
                  class="solo-field-white"
          ></v-text-field>


          <div class="checkout__form-field checkout__form-field--payment-hide">
            <v-text-field
                    v-if="tokenErrorMess.length > 0"
                    type="hidden"
                    :error-messages="tokenErrorMess"
                    solo
            ></v-text-field>
          </div>

        </v-form>
        <v-card-actions>
          <v-btn
                  block
                  color="error"
                  :disabled="!isResetFormValid || isHasErrorMess"
                  @click="resetPassword"
          >
            {{$t('auth_forms.button_reset_password')}}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card-text>

  </v-card>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty} from '@/helpers.js';

  export default {
    name: "reset-password",
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Reset password'
    },
    props: ['token'],
    mounted() {
      this.$scrollTo('.header.v-toolbar');
    },
    computed: {
      isHasErrorMess() {
        return !(empty(this.emailErrorMess) && empty(this.passwordErrorMess) && empty(this.password_confirmationErrorMess) && empty(this.tokenErrorMess));
      }
    },
    data() {
      return {
        isResetFormValid: true,
        email: '',
        emailRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_email_required'),
          v => /.+@.+/.test(v) || this.$i18n.t('auth_forms.js_error_email_format')
        ],
        emailErrorMess: [],

        password: '',
        passwordRules: [v => !!v || this.$i18n.t('auth_forms.js_error_password_required')],
        passwordErrorMess: [],

        password_confirmation: '',
        password_confirmationRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_password_confirmation_required'),
          v => v === this.password || this.$i18n.t('auth_forms.js_error_password_should_match')
        ],
        password_confirmationErrorMess: [],
        tokenErrorMess: []
      }
    },
    methods: {
      resetPassword() {
        if (this.$refs.resetForm.validate() && !this.isHasErrorMess) {

          const data = {
            email: this.email,
            token: this.token,
            password: this.password,
            password_confirmation: this.password_confirmation
          };

          this.resetRequest(data)
            .then(() => {
              this.$router.push({path: '/login'});
              this.$store.commit('SET_NOTIFICATION', {
                text: this.$i18n.t('auth_forms.password_reset_successful'),
                color: 'error'
              });
            })
            .catch((error) => {
              if (!empty(error.response) && !empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              } else if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
                this.$store.commit('SET_NOTIFICATION', {
                  text: error.response.data.message,
                  color: 'orange'
                });
              }
            })
        }
      },
      resetRequest(data) {
        return axios({
          method: 'post',
          url: `${CONFIG.API_URL}/auth/resetPassword`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        });
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      clearErrorMess(field) {
        this[field] = [];
        this.tokenErrorMess = [];
      },
    }
  }
</script>

<style scoped>

</style>