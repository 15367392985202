<template>
  <v-container
    fluid
    fill-height
  >

        <v-card class="register-card register-card--create" :class="{'register-card--customer': isCustomerRegPage}">
          <v-card-text>
            <div class="profile-booking__close" v-if="!isCustomerRegPage" @click="hide">
              <v-icon>close</v-icon>
            </div>
            <p class="register-card__title">
              {{$t('auth_forms.create_customer_account')}}
            </p>

            <v-form
              ref="form"
              v-model="formValid"
              lazy-validation
            >
              <v-text-field
                name="firstName"
                :label="$t('auth_forms.first_name')"
                v-model="first_name"
                :rules="first_nameRules"
                :error-messages="first_nameErrorMess"
                @focus="clearErrorMess('first_nameErrorMess')"
                required
              ></v-text-field>

              <v-text-field
                name="email"
                :label="$t('auth_forms.email')"
                type="email"
                :rules="emailRules"
                v-model="email"
                :error-messages="emailErrorMess"
                @focus="clearErrorMess('emailErrorMess')"
                required
              ></v-text-field>

              <v-text-field
                name="phone"
                :label="$t('auth_forms.phone')"
                type="text"
                :rules="phoneRules"
                :placeholder="phonePlaceholder"
                v-model="phone"
                :error-messages="phoneErrorMess"
                :validate-on-blur="true"
                mask="###########"
                @focus="clearErrorMess('phoneErrorMess'), setPlaceholder('phone','07737129828')"
                required
              ></v-text-field>

              <v-text-field
                name="password"
                :label="$t('auth_forms.password')"
                type="password"
                :rules="passwordRules"
                v-model="password"
                :error-messages="passwordErrorMess"
                @focus="clearErrorMess('passwordErrorMess')"
                required
              ></v-text-field>

              <v-text-field
                name="confirm-password"
                :label="$t('auth_forms.password_confirmation')"
                type="password"
                :rules="password_confirmationRules"
                v-model="password_confirmation"
                :error-messages="password_confirmationErrorMess"
                @focus="clearErrorMess('password_confirmationErrorMess')"
                required
              ></v-text-field>

              <div class="checkout__form-fields checkout__form-fields--checkboxes">
                <div class="checkout__form-field">
                  <v-checkbox
                          :rules="agreeRules"
                          v-model="isAgree"
                          class="agree"
                  >
                    <span slot="label"> {{$t('auth_forms.agree_to_the')}} <a href="#" @click.prevent="onTermsOfServices()" > {{$t('auth_forms.terms_conditions')}} </a> {{$t('auth_forms.and')}} <a href="#" @click.prevent="onPrivacyPolicy()" > {{$t('auth_forms.privacy_policy')}} </a>.</span>
                  </v-checkbox>
                </div>
              </div>

            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              color="error"
              :disabled="!formValid || isHasErrorMess || beingProcessed"
              @click="registration"
            >
              {{$t('auth_forms.sign_up')}}
            </v-btn>
          </v-card-actions>

          <!--<v-card-text class="register-card-terms">-->
            <!--{{$t('auth_forms.by_signing_up_you_are_confirming')}}-->
            <!--<a-->
              <!--href="#"-->
              <!--@click="onTermsOfServices()"-->
            <!--&gt;-->
              <!--{{$t('auth_forms.terms_conditions')}}-->
            <!--</a> {{$t('auth_forms.and')}} <a-->
              <!--href="#"-->
              <!--@click="onPrivacyPolicy()"-->
            <!--&gt;{{$t('auth_forms.privacy_policy')}}</a>.-->
          <!--</v-card-text>-->

          <v-card-text class="register-card-terms" v-if="!isCustomerRegPage">
            <span>{{$t('auth_forms.already_have_an_account')}}, <b><a href="#" @click.prevent="switchAuthenticationModal">{{$t('auth_forms.sign_in')}}</a></b></span>
          </v-card-text>

        </v-card>
  </v-container>
</template>

<script>

import { empty } from '@/helpers.js';

/* eslint-disable */

export default {
  props:['useInModal'],
  name: "customer-registration",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Customer Sign Up'
  },
  data () {
    return {
      formValid: true,
      userRole: "customer",

      first_name: '',
      first_nameRules: [v => !!v || this.$i18n.t('auth_forms.js_error_first_name_required')],
      first_nameErrorMess: [],

      email: '',
      emailRules: [
        v => !!v || this.$i18n.t('auth_forms.js_error_email_required'),
        v => /.+@.+/.test(v) || this.$i18n.t('auth_forms.js_error_email_format')
      ],
      emailErrorMess: [],

      phone: '',
      phoneRules: [
        v => !!v || this.$i18n.t('auth_forms.js_error_phone_required'),
        v => /^\d+$/.test(v) || this.$i18n.t('auth_forms.js_error_phone_numbers'),
        v => !(v.length != 11)  || this.$i18n.t('auth_forms.js_error_phone_format')
      ],
      phoneErrorMess: [],
      phonePlaceholder: '',

      password: '',
      passwordRules: [v => !!v || this.$i18n.t('auth_forms.js_error_password_required')],
      passwordErrorMess: [],

      password_confirmation: '',
      password_confirmationRules: [
        v => !!v || this.$i18n.t('auth_forms.js_error_password_confirmation_required'),
        v => v === this.password || this.$i18n.t('auth_forms.js_error_password_should_match')
      ],
      password_confirmationErrorMess: [],

      isAgree: false,
      agreeRules: [
        v => !!v || this.$i18n.t('auth_forms.js_error_check_to_continue'),
      ],

      beingProcessed: false
    }
  },
  mounted () {
    if(this.isCustomerRegPage){
      this.$scrollTo('.header.v-toolbar');
    }
  },
  computed: {
    isHasErrorMess () {
      return !(empty(this.first_nameErrorMess) && empty(this.emailErrorMess)
        && empty(this.phoneErrorMess) && empty(this.passwordErrorMess) && empty(this.password_confirmationErrorMess));
    },
    isCustomerRegPage: function () {
      if (!this.useInModal) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    hide() {
      this.$emit("hideCustomerRegModal");
    },
    switchAuthenticationModal(){
      this.$emit('switchAuthenticationModal')
    },
    setPlaceholder(field, placeholder){
      this[`${field}Placeholder`] = placeholder;
    },
    onTermsOfServices () {
      const routeData = this.$router.resolve({ name: 'terms-of-service' });
      window.open(routeData.href, '_blank');
    },
    onPrivacyPolicy () {
      const routeData = this.$router.resolve({ name: 'privacy-policy' });
      window.open(routeData.href, '_blank');
    },
    clearErrorMess (field) {
      this[field] = [];
    },
    registration () {
      if (this.$refs.form.validate() && !this.isHasErrorMess) {

        const userData = {
          user_role: this.userRole,
          first_name: this.first_name,
          email: this.email,
          phone: this.phone,
          password: this.password,
          password_confirmation: this.password_confirmation
        };
        this.beingProcessed = true;
        this.$store.dispatch('REGISTER_REQUEST', userData)
          .then((response) => {
            this.beingProcessed = false;
            this.$store.commit('SET_NOTIFICATION', {
              text: response.data.message,
              color: 'error'
            });

            if (!empty(this.$store.getters.targetPage)) {
              this.$router.replace({ name: this.$store.getters.targetPage, params: this.$store.getters.params });
            } else {
              this.$router.push('/customer');
            }
          })
          .catch((error) => {
            this.beingProcessed = false;
            if (!empty(error.response.data.errors)) {
              this.fieldsErrorFromServer(error.response.data.errors);
            }
            console.log(error);
          });
      }
    },
    fieldsErrorFromServer (errors) {
      if (!empty(errors)) {
        for (let field in errors) {
          for (let i = 0; i < errors[field].length; i++) {
            this[`${field}ErrorMess`].push(errors[field][i]);
          }
        }
      }
    }
  }
}
</script>

<style scoped>
</style>