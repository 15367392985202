import axios from 'axios'
import * as CONFIG from '@/config.js'
import moment from 'moment';
import repository from '@/repository/repository.js';

import {
  REGISTER_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  REFRESH_OR_RESET_AUTH_VARIABLES
} from '../action-types';

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  RESET_AUTH_VARIABLES,
  REFRESH_AUTH_VARIABLES
} from '../mutation-types';

import {empty} from "../../helpers";

export default {

  state: {
    userToken: '',
    status: '',
    expiredTokenTime: ''
  },
  mutations: {
    [AUTH_REQUEST]: (state) => {
      state.status = 'loading';
    },
    [AUTH_SUCCESS]: (state, token) => {
      state.userToken = token;
      repository.saveValue('user-token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      state.status = 'success';
      state.expiredTokenTime = moment().clone().unix();
      repository.saveValue('expired-token-time', state.expiredTokenTime);
    },
    [RESET_AUTH_VARIABLES]: (state) => {
      repository.deleteValue('user-token');
      state.userToken = '';
      repository.deleteValue('expired-token-time');
      state.expiredTokenTime = '';
      delete axios.defaults.headers.common['Authorization']
    },
    [REFRESH_AUTH_VARIABLES]: (state) => {
      state.userToken = repository.getValue('user-token');
      state.expiredTokenTime = moment().clone().unix();
      repository.saveValue('expired-token-time', state.expiredTokenTime);
      axios.defaults.headers.common['Authorization'] = `Bearer ${state.userToken}`;
    },
    [AUTH_ERROR]: (state) => {
      state.status = 'error';
    }
  },
  actions: {

    [REGISTER_REQUEST]: ({commit}, user_registration_data) => {

      commit('AUTH_REQUEST');
      commit('CLEAR_NOTIFICATION');

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${CONFIG.API_URL}/auth/user`,
          data: JSON.stringify(user_registration_data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }).then(
          (response) => {
            commit('AUTH_SUCCESS', response.data.access_token);
            commit('SET_CURRENT_USER_DATA', response.data);
            resolve(response);
          }
        ).catch((error) => {
          commit('AUTH_ERROR');
          reject(error);
        });
      });
    },
    [LOGIN_REQUEST]: ({commit}, {email, password}) => {
      const user_data = {email, password};
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${CONFIG.API_URL}/auth/user/login`,
          data: JSON.stringify(user_data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
          .then((response) => {
            commit('AUTH_SUCCESS', response.data.access_token);
            commit('SET_CURRENT_USER_DATA', response.data);
            resolve(response);
            // eslint-disable-next-line
            console.log(response);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    [LOGOUT_REQUEST]: ({commit, dispatch}) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${CONFIG.API_URL}/auth/user/logout`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          }
        })
          .then((response) => {
            commit('RESET_AUTH_VARIABLES');
            dispatch('RESET_CURRENT_USER_DATA');
            resolve(response);
          })
          .catch((error) => {
            commit('RESET_AUTH_VARIABLES');
            dispatch('RESET_CURRENT_USER_DATA');
            reject(error);
          })
      });
    },
    [REFRESH_OR_RESET_AUTH_VARIABLES]: ({commit, dispatch}) => {
      const currentTime = moment().clone().unix();
      const expiredTokenTime = repository.getValue('expired-token-time');
      const sessionTime = Number(expiredTokenTime) + Number(CONFIG.SESSION_TIME);

      if (!empty(repository.getValue('user-token'))) {
        if (Number(sessionTime) < Number(currentTime)) {
          // eslint-disable-next-line
          console.log(1);
          commit('RESET_AUTH_VARIABLES');
          dispatch('RESET_CURRENT_USER_DATA');
        } else {
          // eslint-disable-next-line
          console.log(2);
          commit('REFRESH_AUTH_VARIABLES');
          dispatch('REFRESH_CURRENT_USER_DATA');
        }
      } else {
        // eslint-disable-next-line
        console.log(3);
        commit('RESET_AUTH_VARIABLES');
        dispatch('RESET_CURRENT_USER_DATA');
      }
    }
  },
  getters: {
    authStatus(state) {
      return state.status;
    },
    userToken(state) {
      return state.userToken;
    },
    isUserLoggedIn(state) {
      return !!state.userToken
    }
  }

}