<template>
  <v-dialog v-model="showWrapper">
    <div
            class="profile-booking"
            v-if="showModal"
    >
      <div
              class="profile-booking__close"
              @click="hide()"
      >
        <v-icon>close</v-icon>
      </div>
      <div class="profile-booking__back" @click="hide()"> {{$t('practitioner_search_availability.back')}} </div>
      <div class="profile-booking__name">
        {{$t('practitioner_search_availability.treatment_with', {practitioner: this.practitionerName})}}
      </div>
      <div class="profile-booking__steps">
        <div class="profile-booking__step">
          <div class="profile-booking__title profile-booking__title--brd-t">
            {{$t('practitioner_search_availability.confirm_location')}}
          </div>
          <div class="profile-booking__locations">
            <div
                    v-if="showMobileLocation"
                    class="profile-booking__location"
            >

              <label
                      for="rd-1"
                      class="radio-checkbox"
              >
                <input
                        type="radio"
                        id="rd-1"
                        name="rd-1"
                        value="mobile"
                        v-model="appointmentLocation"
                >
                <span></span>
                {{ searchAddress }}
              </label>

              <div class="v-text-field__details profile-booking__location-error" v-if="this.restrictMobileWithoutPostCode">
                <div class="v-messages theme--light error--text">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">{{$t('practitioner_search_availability.postcode_missing_for_mobile_error')}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
                    v-if="showSalonLocation"
                    class="profile-booking__location"
            >
              <label
                      for="rd-2"
                      class="radio-checkbox"
              >
                <input
                        type="radio"
                        id="rd-2"
                        name="rd-1"
                        value="salon"
                        v-model="appointmentLocation"
                >
                <span></span>
                {{$t('practitioner_search_availability.practitioner_location_in', {address: this.searchTown})}}
              </label>
            </div>
            <div class="profile-booking__location profile-booking__location--bottom">
              <p v-if="showSalonLocation">
                {{$t('practitioner_search_availability.address_details_after_booking')}}
              </p>
            </div>
          </div>
        </div>
        <div class="profile-booking__step">
          <div class="profile-booking__title">
            {{$t('practitioner_search_availability.confirm_treatment')}}
          </div>
          <div class="profile-booking__services">
            <div
                    v-for="service in practitionerSubServices"
                    :key="service.details.id"
                    class="profile-booking__service"
            >
              <div class="profile-booking__lside">
                <i aria-hidden="true" class="v-icon material-icons theme--light">check</i>
                <span>{{ service.quantity }}&nbsp;x&nbsp;</span>
                <span>{{ service.details.name }}</span>
              </div>
              <div class="profile-booking__rside">
                <span>£{{totalAmountByService(service.details.price, service.quantity)}}</span>
                <v-btn
                        class="error profile-booking__btn-small"
                        small
                        @click="removeService(service.details.id)"
                >-
                </v-btn>
                <v-btn
                        class="error profile-booking__btn-small"
                        small
                        @click="addService(service.details)"
                >+
                </v-btn>
              </div>
            </div>
            <v-expansion-panel expand v-model="panel">
              <v-expansion-panel-content>
                <div slot="header" class="profile-booking__lside profile-booking__lside--add">
                  <span>
                    {{$t('practitioner_search_availability.add_another_treatment')}}
                  </span>
                </div>
                <v-card>
                  <div
                          v-for="service in availablePractitionerSubServices"
                          :key="service.id"
                          class="profile-booking__service"
                  >
                    <div class="profile-booking__lside">
                      <span>{{ service.name }}</span>
                    </div>
                    <div class="profile-booking__rside">
                      <span class="pl-2">£{{service.price}}</span>
                      <v-btn
                              class="error profile-booking__btn-small-radius"
                              small
                              @click="addService(service)"

                      >
                        {{$t('practitioner_search_availability.add')}}
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <div
                    class="profile-booking__service"
                    v-if="appointmentLocation === 'mobile'"
            >
              <div class="profile-booking__lside profile-booking__lside--add">
                <span>
                   {{$t('practitioner_search_availability.call_out_charge')}}
                </span>
              </div>
              <div class="profile-booking__rside">
                <span>£{{ CallOutCharge }}</span>
              </div>
            </div>

            <div class="profile-booking__service profile-booking__service--discount" v-if="isSetDiscount">
              <div class="profile-booking__lside profile-booking__lside--add">
                <span>
                   {{discount}} % {{$t('practitioner_search_availability.discount_bonus')}}
                </span>
              </div>
              <div class="profile-booking__rside">
                <span>-£ {{ discountAmount }}</span>
              </div>
            </div>

            <div class="profile-booking__service" v-if="!this.$store.getters.isUserLoggedIn">
              <div class="profile-booking__lside profile-booking__lside--add">
                <span>
                  {{$t('practitioner_search_availability.discount')}}
                </span>
              </div>
              <div class="profile-booking__rside profile-booking__rside--add">
                <span>
                  {{$t('practitioner_search_availability.discount_description')}}
                </span>
              </div>
            </div>

            <div
                    class="profile-booking__service"
                    v-if="practitionerSubServices.length > 0"
            >
              <div class="profile-booking__lside">
                <span>
                  {{$t('practitioner_search_availability.sub_total')}}
                </span>
              </div>
              <div class="profile-booking__rside">
                <span>£{{ totalPrice }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="profile-booking__step">
          <div class="profile-booking__settings">
            <div class="profile-booking__date">
              <div class="profile-booking__title profile-booking__title--brd">
                {{$t('practitioner_search_availability.select_a_date')}}
              </div>
              <v-date-picker
                      v-model="selectedDate"
                      full-width
                      no-title
                      color="error lighten-1"
                      :min="currentDate()"
                      :disabled="!isSelectedFirstStep || !isSelettedSecondStep"
                      class="elevation-0"
              ></v-date-picker>
            </div>
            <div class="profile-booking__time">
              <div class="profile-booking__title profile-booking__title--brd">
                {{$t('practitioner_search_availability.select_a_time')}}
              </div>

              <div
                      class="profile-booking__select"
                      v-if="!isSelectedFirstStep || !isSelettedSecondStep"
              >
                {{$t('practitioner_search_availability.confirm_all_steps')}}
              </div>

              <div
                      class="profile-booking__select"
                      v-if="isSelectedFirstStep && isSelettedSecondStep && !isHaveAvailableSlots"
              >
                {{$t('practitioner_search_availability.no_available_slots')}}
              </div>

              <div class="profile-booking__times">

                <v-progress-linear
                        indeterminate
                        color="error"
                        height="3"
                        v-if="requestTimeSlots"
                ></v-progress-linear>

                <template
                        v-if="!requestTimeSlots && isHaveAvailableSlots && isSelectedFirstStep && isSelettedSecondStep">
                  <div class="profile-booking__timeslots">
                    <div
                            v-for="(slot, sub_index) in preparedTimeSlots[slotsDisplay.currentIndex]"
                            :key="sub_index"
                            class="profile-booking__timeslot"
                    >
                      <button
                              v-bind:class="{'is-active': slot.selected === true}"
                              @click="selectSlot(slot, slotsDisplay.currentIndex, sub_index)"
                      >{{ slot.time.slice(0, -3) }}
                      </button>
                    </div>
                  </div>
                  <div class="profile-booking__actions">
                    <div
                            class="profile-booking__early"
                            v-if="slotsDisplay.previous"
                    >
                      <a
                              href="#"
                              @click="updateDisplayedSlots('-')"
                      >
                        {{$t('practitioner_search_availability.early_times')}}
                      </a>
                    </div>

                    <div
                            class="profile-booking__later"
                            v-if="slotsDisplay.next"
                    >
                      <a
                              href="#"
                              @click="updateDisplayedSlots('+')"
                      >
                        {{$t('practitioner_search_availability.later_times')}}
                      </a>
                    </div>
                  </div>
                </template>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-booking__bottom">
        <router-view
                name="create_booking"
        ></router-view>

        <router-view
                name="rebook_create_booking"
        ></router-view>

        <router-view
                name="booking_tab_rebook_create_booking"
        ></router-view>
        <v-btn
                color="error"
                class="profile-booking__button"
                @click="createBooking()"
                :disabled="!isSelectedFirstStep || !isSelettedSecondStep || !isHaveAvailableSlots || !isSelectedSlot"
        >
          {{$t('practitioner_search_availability.continue_to_payment')}}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty, addIsFixedToHtml} from '@/helpers.js';

  /* eslint-disable */

  export default {
    props: [
      'id',
      'withBookingState',
      'searchAddressToStore',
      'searchAddress',
      'searchTown',
      'searchAddressLat',
      'searchAddressLon',
      'initDate',
      'selectedLocationType',
      'selectedSubServiceId',
      'selectedSubServiceName',
      'searchPostcode',
      'searchCountryId',
      'searchCountryName',
      'searchAddressComponents',
      'bookingFlow'
    ],
    // beforeRouteUpdate(to, from, next) {
    //   this.setPreBookingState(to, from);
    //   next();
    // },
    // beforeRouteEnter(to, from, next) {
    //   next(vm => {
    //     vm.setPreBookingState(to, from);
    //   });
    // },
    // beforeRouteLeave(to, from, next){
    //   removeIsFixedToHtml();
    //   next()
    // },
    data() {
      return {
        restrictMobileWithoutPostCode: false,
        showWrapper: true,
        availablePractitionerSubServices: [],
        practitionerSubServices: [],
        showMobileLocation: false,
        showSalonLocation: false,
        appointmentLocation: null,
        availableTimeSlots: [],

        preparedTimeSlots: [],
        selectedTimeSlot: {
          index: null,
          subIndex: null
        },

        panel: [],

        slotsDisplay: {
          currentIndex: 0,
          previous: false,
          next: false
        },

        practitionerId: null,
        practitionerName: null,
        workLocationTypes: null,
        call_out_charge: null,
        discount: 0,
        discount_mark: null,

        // searchAddress: null,
        // searchAddressToStore: null,
        // searchTown: null,
        // searchAddressLat: null,
        // searchAddressLon: null,
        selectedDate: null,
        // selectedLocationType: null,
        // selectedSubServiceId: null,
        // selectedSubServiceName: null,

        showModal: true,
        requestTimeSlots: false
      };
    },
    created: function () {
      // this.searchAddressToStore = this.getAddressToStore();
      // this.searchAddress = this.$store.getters.search.searchAddress;
      // this.searchTown = this.$store.getters.search.searchTown;
      // this.searchAddressLat = this.$store.getters.search.searchLatitude;
      // this.searchAddressLon = this.$store.getters.search.searchLongitude;
      // this.selectedDate = this.$store.getters.search.searchDate;
      // this.selectedLocationType = this.$store.getters.search.searchLocationType;
      // this.selectedSubServiceId = this.$store.getters.search.searchSubServiceId;
      // this.selectedSubServiceName = this.$store.getters.search.searchSubServiceName;

      this.selectedDate = this.initDate;

      this.practitionerId = this.id;
      this.$store.dispatch('ADD_PRACTITIONER_TO_STATE', {
        id: this.id,
        lat: this.searchAddressLat,
        lon: this.searchAddressLon
      })
        .then((data) => {
          this.practitionerName = data.name;
          this.workLocationTypes = data.work_location_types;
          this.call_out_charge = data.call_out_charge;

          if (
            this.selectedLocationType === "mobile" &&
            this.workLocationTypes.mobile
          ) {
            this.showMobileLocation = true;
          }
          if (
            this.selectedLocationType === "salon" &&
            this.workLocationTypes.salon
          ) {
            this.showSalonLocation = true;
          }
          if (this.selectedLocationType === "idm") {
            if (this.workLocationTypes.mobile) {
              this.showMobileLocation = true;
            }
            if (this.workLocationTypes.salon) {
              this.showSalonLocation = true;
            }
          }
        });

      this.appointmentLocation = this.selectedLocationType == 'idm'? 'mobile': this.selectedLocationType;
      this.displayMobileRestriction();
      this.getPractitionerSubServices();
      if(this.$store.getters.isUserLoggedIn){
        this.getDiscount();
      }
    },
    mounted(){
      addIsFixedToHtml();
    },
    computed: {
      totalAmount: function () {
        let totalAmount = this.practitionerSubServices.reduce((accum, service) => {
          return accum + service.details.price * service.quantity;
        }, 0);

        return Number(totalAmount).toFixed(2);
      },
      CallOutCharge: function () {
        return (Number(this.call_out_charge)).toFixed(2);
      },
      totalPrice: function () {
        let price = this.totalAmount;
        if (this.appointmentLocation === 'mobile') {
          price = ((Number(price) + Number(this.CallOutCharge)) - Number(this.discountAmount)).toFixed(2);
        } else {
          price = (Number(price) - Number(this.discountAmount)).toFixed(2);
        }
        return price;
      },
      discountAmount: function(){
        let sum = this.totalAmount;
        if (this.appointmentLocation === 'mobile') {
          sum = Number(sum) + Number(this.CallOutCharge);
        }
        const discount = (sum * (Number(this.discount)/100)).toFixed(2);
        return discount;
      },
      isSetDiscount() {
        if (!empty(this.discount) && this.discount !== 0) {
          return true;
        } else {
          return false;
        }
      },
      isSelectedFirstStep: function () {
        return !!this.appointmentLocation;
      },
      isSelettedSecondStep: function () {
        return this.practitionerSubServices.length !== 0;
      },
      isHaveAvailableSlots: function () {
        return this.availableTimeSlots.length !== 0
      },
      isSelectedSlot: function () {
        for (let i = 0; i < this.preparedTimeSlots.length; i++) {
          for (let j = 0; j < this.preparedTimeSlots[i].length; j++) {
            if (this.preparedTimeSlots[i][j]['selected'] === true) {
              return true;
            }
          }
        }
        return false;
      },
      bookedServicesId: function () {
        let id = [];
        for (let i = 0; i < this.practitionerSubServices.length; i++) {
          for (let j = 0; j < this.practitionerSubServices[i].quantity; j++) {
            id.push(this.practitionerSubServices[i].details.id)
          }
        }
        return id;
      },
      bookedStartTime: function () {
        for (let i = 0; i < this.preparedTimeSlots.length; i++) {
          for (let j = 0; j < this.preparedTimeSlots[i].length; j++) {
            if (this.preparedTimeSlots[i][j]['selected'] === true) {
              return this.preparedTimeSlots[i][j]['time'];
            }
          }
        }
        return null;
      }
    },
    watch: {
      showWrapper() {
        if (this.showWrapper === false) {
          this.hide();
        }
      },
      selectedDate() {
        this.availableBookingStartTimes();
      },
      appointmentLocation() {
        this.availableBookingStartTimes();
      },
      practitionerSubServices() {
        this.availableBookingStartTimes();
      },
    },
    methods: {
      totalAmountByService(price, quantity) {
        return Number(price * quantity).toFixed(2);
      },
      displayMobileRestriction() {
        console.log('displayMobileRestriction');
        if (this.appointmentLocation == 'mobile') {
          this.restrictMobileWithoutPostCode = this.searchAddress? !this.searchAddress.match(/\W[\w]{2,4}\s+[\w]{3}\W/): false;
        }
      },
      getDiscount() {
        const currentUser = this.$store.getters.currentUser;
        axios.get(`${CONFIG.API_URL}/booking/loyalty-scheme/${currentUser.user_id}/${this.practitionerId}`, {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
          }
        })
          .then((response) => {
            const data = response.data;
            if (data.loyalty === true) {
              this.discount = data.discount;
              this.discount_mark = data.mark;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          })
      },
      setPreBookingState(to, from){
        if(from.name === 'Payment'){
          const state = this.$store.getters.searchAvailabilityById(this.id);
          if(!empty(state)){
            this.practitionerSubServices = state.practitionerSubServices;
            this.appointmentLocation = state.location_type
          }
        }
      },
      resetSlotsParams() {
        this.selectedTimeSlot.index = null;
        this.selectedTimeSlot.subIndex = null;
        this.slotsDisplay.currentIndex = 0;
        this.slotsDisplay.previous = false;
        this.slotsDisplay.next = false;
      },
      setAvailableTimeSlots(){
        let itemCounter = 0;
        let groupCounter = 0;
        let arr = [];
        arr[groupCounter] = [];

        for (let i = 0; i < this.availableTimeSlots.length; i++) {
          if (itemCounter >= 16) {
            groupCounter++;
            itemCounter = 0;
            arr[groupCounter] = [];
          }
          itemCounter++;
          arr[groupCounter].push({
            time: this.availableTimeSlots[i],
            selected: false
          });
        }

        this.preparedTimeSlots = arr;
      },
      updateDisplayedSlots(action) {
        const preparedLength = this.preparedTimeSlots.length;

        if (this.slotsDisplay.currentIndex + 1 < preparedLength && action === '+') {
          this.slotsDisplay.currentIndex++;
        }
        if (this.slotsDisplay.currentIndex > 0 && action === '-') {
          this.slotsDisplay.currentIndex--;
        }
        if (this.slotsDisplay.currentIndex === 0 && preparedLength === 1) {
          this.slotsDisplay.previous = false;
          this.slotsDisplay.next = false;
        }
        if (this.slotsDisplay.currentIndex === 0 && preparedLength > 1) {
          this.slotsDisplay.previous = false;
          this.slotsDisplay.next = true;
        }
        if (this.slotsDisplay.currentIndex > 0 && this.slotsDisplay.currentIndex + 1 < preparedLength) {
          this.slotsDisplay.previous = true;
          this.slotsDisplay.next = true;
        }
        if (this.slotsDisplay.currentIndex > 0 && this.slotsDisplay.currentIndex + 1 === preparedLength) {
          this.slotsDisplay.previous = true;
          this.slotsDisplay.next = false;
        }
      },
      selectSlot(slot, index, subIndex) {
        if (this.selectedTimeSlot.index !== null && this.selectedTimeSlot.subIndex !== null) {
          this.preparedTimeSlots[this.selectedTimeSlot.index][this.selectedTimeSlot.subIndex].selected = false;
        }
        this.selectedTimeSlot.index = index;
        this.selectedTimeSlot.subIndex = subIndex;
        slot.selected = true;
      },
      hide() {
        this.$emit('hide-availability-popup');
      },
      currentDate() {
        const d = new Date();
        const month =
          d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        const date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
        return `${d.getFullYear()}-${month}-${date}`;
      },
      // getAddressToStore() {
      //   let streetName = null;
      //   let streetNumber = null;
      //   for (let i = 0; i < this.$store.getters.search.searchAddressComponents.length; i++) {
      //     if (this.$store.getters.search.searchAddressComponents[i].types[0] == 'street_number') {
      //       streetNumber = this.$store.getters.search.searchAddressComponents[i].long_name;
      //     }
      //     if (this.$store.getters.search.searchAddressComponents[i].types[0] == 'route') {
      //       streetName = this.$store.getters.search.searchAddressComponents[i].long_name;
      //     }
      //   }
      //
      //   if ((!streetNumber || 0 === streetNumber.length) && (!streetName || 0 === streetName.length)) {
      //     return this.$store.getters.search.searchAddress;
      //   }
      //   return streetNumber + ' ' + streetName;
      // },
      getPractitionerSubServices() {
        axios
          .get(`${CONFIG.API_URL}/practitioner/${this.practitionerId}/services`, {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest"
            }
          })
          .then(
            response => {
              const response_data = response.data;
              this.availablePractitionerSubServices = response_data.sub_services;
              for (let i = 0; i < this.availablePractitionerSubServices.length; i++) {
                if (this.availablePractitionerSubServices[i].id === Number(this.selectedSubServiceId) && empty(this.withBookingState)) {
                  this.addService(this.availablePractitionerSubServices[i]);
                }
              }
            },
            error => {
              console.log(error.response.data);
              throw error;
            }
          )
          .catch(error => {
            console.log(error.response.data);
            throw error;
          });
      },
      availableBookingStartTimes() {
        if (
          this.appointmentLocation &&
          this.practitionerSubServices.length > 0 &&
          this.selectedDate
        ) {
          this.requestTimeSlots = true;

          let sub_services = [];
          for (let i = 0; i < this.practitionerSubServices.length; i++) {
            sub_services.push(
              `sub_service[]=${this.practitionerSubServices[i].details.id}`
            );
          }
          sub_services = sub_services.join("&");

          const params = `${sub_services}&date=${this.selectedDate}&locationtype=${this.appointmentLocation}`;

          console.log(params);
          axios
            .get(
              `${CONFIG.API_URL}/practitioner/${
                this.practitionerId
                }/availableBookingStartTimes?${params}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-Requested-With": "XMLHttpRequest"
                }
              }
            )
            .then(
              response => {
                console.log("response", response);
                if (this.appointmentLocation === "mobile") {
                  this.availableTimeSlots = response.data;
                } else if (this.appointmentLocation === "salon") {
                  if (!empty(Object.keys(response.data)[0])) {
                    this.availableTimeSlots = response.data[Object.keys(response.data)[0]];
                  } else {
                    this.availableTimeSlots = [];
                  }
                } else {
                  this.availableTimeSlots = [];
                }
                this.requestTimeSlots = false;
                this.setAvailableTimeSlots();
                this.resetSlotsParams();
                this.updateDisplayedSlots();

                console.log(this.availableTimeSlots);
              }
            )
            .catch(error => {
              console.log(error);
              throw error;
            });
        }
      },
      addService(product) {
        const serviceInList = this.practitionerSubServices.findIndex(p => {
          return p.details.id === product.id;
        });

        this.panel = [];

        if (serviceInList === -1) {
          this.practitionerSubServices.push({
            details: product,
            quantity: 1
          });
        } else {
          this.practitionerSubServices[serviceInList].quantity++;
        }
      },
      removeService(id) {
        const serviceInList = this.practitionerSubServices.findIndex(p => {
          return p.details.id === id;
        });

        if (this.practitionerSubServices[serviceInList].quantity <= 1) {
          this.practitionerSubServices.splice(serviceInList, 1);
        } else {
          this.practitionerSubServices[serviceInList].quantity--;
        }
      },
      createBooking() {

        this.$store.commit('SET_SEARCH_AVAILABILITY', {
          id: this.practitionerId,
          practitionerSubServices: this.practitionerSubServices,
          appointmentLocation: this.appointmentLocation
        });

        let booking = {
          practitioner_id: this.practitionerId,
          services_ids: this.bookedServicesId,
          total_amount: this.totalPrice,
          discount_amount: (this.isSetDiscount) ? this.discountAmount : null,
          discount_mark: (this.isSetDiscount) ? this.discount_mark : null,
          discount: (this.isSetDiscount) ? this.discount : null,
          date: this.selectedDate,
          start_time: this.bookedStartTime,
          location_type: this.appointmentLocation
        };

        let address = {
          city: this.searchTown,
          postcode: (!empty(this.searchPostcode)) ? this.searchPostcode : null,
          address: this.searchAddressToStore,
          country_id: this.searchCountryId,
          country_name: this.searchCountryName,
          location: {
            lat: this.searchAddressLat,
            lng: this.searchAddressLon
          }
        };

        if (this.appointmentLocation === 'mobile') {
          booking['address'] = address;
          booking['address_object'] = JSON.stringify(this.searchAddressComponents);
        }

        if(this.bookingFlow == 'booking'){
          this.$router.push({name: 'create-booking', params: {booking}});
        }else if(this.bookingFlow == 'dashboard-tab'){
          this.$router.push({name: 'rebook-create-booking', params: {booking}});
        }else if(this.bookingFlow == 'booking-tab'){
          this.$router.push({name: 'booking-tab-rebook-create-booking', params: {booking}});
        }
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .profile-booking {
    position: fixed;
    top: 0;
    left: 50%;
    max-height: 100%;
    margin-left: -593px;
    overflow-y: auto;
    max-width: 1186px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    background-color: #fefefe;
    z-index: 10;
    padding: 50px 180px 140px 290px;

    @include r(1700) {
      padding: 50px 120px 140px 200px;
      max-width: 980px;
      margin-left: -490px;
    }

    @include r(1500) {
      padding: 50px 100px 140px 180px;
      max-width: 940px;
      margin-left: -470px;
    }

    @include r(1000) {
      max-width: 100%;
      left: 0;
      margin: 0;
      border-radius: 0;
    }

    @include r(900) {
      padding: 30px 70px 100px 70px;
    }
    @include r(730) {
      padding: 30px 40px 80px 40px;
    }

    @include r(360) {
      padding: 30px 20px 60px 20px;
    }

    &__back {
      display: none;
      position: relative;
      padding-left: 18px;
      top: -1px;
      color: #383838;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;

      @include r(900) {
        display: inline-block;
      }

      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 2px;
        width: 9px;
        height: 16px;
        background: {
          image: url("../../.././src/images/icons/icon-arrow-right-dark.svg");
          repeat: no-repeat;
          size: contain;
        }
        transition: .3s;
      }

      &:hover {
        &::before {
          transform: translateX(-4px);
          transition: .3s;
        }
      }
    }
    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
      cursor: pointer;

      @include r(900) {
        display: none;
      }

      .v-icon {
        font-size: 32px;
      }
    }

    &__name {
      display: block;
      text-align: center;
      margin-bottom: 70px;
      padding-right: 110px;
      color: #383838;
      font-size: 37px;
      font-weight: 600;
      width: 100%;

      @include r(1700) {
        font-size: 28px;
        margin-bottom: 50px;
      }

      @include r(1700) {
        padding-right: 60px;
      }

      @include r(1500) {
        font-size: 26px;
      }
      @include r(900) {
        padding-right: 0;
        margin: 20px 0 30px 0;
      }
      @include r(730) {
        font-size: 24px;
      }
      @include r(600) {
        font-size: 20px;
      }
    }

    &__step {
      display: block;
      margin-bottom: 28px;

      @include r(1500) {
        margin-bottom: 15px;
      }
    }

    &__lside {
      flex: 1 1 auto;
      display: flex;
      align-items: center;

      i.theme--light.v-icon {
        margin-right: 8px;
        color: #1eda1e;
      }

      span {
        color: #535353;
        font-size: 20px;
        font-weight: 400;

        @include r(1500) {
          font-size: 17px;
        }

        @include r(900) {
          font-size: 15px;
        }
        @include r(600) {
          font-size: 13px;
        }
      }

      &--add {
        span {
          color: #a3a3a3;
        }
      }
    }

    &__rside {
      flex: 0 0 auto;
      display: flex;
      align-items: center;

      span {
        color: #535353;
        font-size: 23px;
        font-weight: 400;
        line-height: 1;

        @include r(1700) {
          font-size: 21px;
        }
        @include r(1500) {
          font-size: 18px;
        }

        @include r(600) {
          font-size: 13px;
        }
      }
      &--add {
        span {
          color: #a3a3a3 !important;
        }
      }
    }

    &__btn-small {
      margin: 0 0 0 12px;
      min-width: 40px;
    }
    &__btn-small-radius {
      margin: 0 0 0 12px;
      min-width: 70px;
      border-radius: 10px;
    }

    &__services {
      padding-right: 110px;

      @include r(1700) {
        padding-right: 60px;
      }

      @include r(900) {
        padding-right: 0;
      }
    }

    .v-expansion-panel__header {
      @include r(600) {
        padding: 14px;
      }
    }
    .v-expansion-panel:not(:first-child)::after {
      display: none;
    }

    &__service {
      display: flex;
      border-top: 1px solid #d9d9d9;
      padding: 22px 26px 22px 20px;
      align-items: center;

      &--discount {
        .profile-booking__lside, .profile-booking__rside {
          span {
            color: #f25757 !important;
            font-weight: 600;
          }
        }
      }

      @include r(1500) {
        padding: 16px 22px 16px 30px;
      }

      @include r(600) {
        padding: 14px;
      }

      &--add {
        cursor: pointer;
        padding-top: 16px;
        padding-bottom: 16px;

        @include r(1500) {
          padding-top: 9px;
          padding-bottom: 9px;
        }
        span {
          color: #a3a3a3;
        }
        .profile-booking {
          &__rside {
            span {
              font-size: 30px;
            }
          }
        }
      }
    }

    &__locations {
      padding-right: 110px;

      @include r(1700) {
        padding-right: 60px;
      }

      @include r(900) {
        padding-right: 0;
      }
    }

    &__location {
      display: block;
      border-top: 1px solid #d9d9d9;

      &-error {
        padding: 0 0 10px 36px;
        margin-top: -6px;
        @include r(1500) {
          padding-left: 30px;
        }
        @include r(600) {
          padding-left: 0;
        }
      }

      label {
        padding: 24px 26px 24px 36px;

        @include r(1500) {
          padding: 18px 22px 18px 30px;
        }
        @include r(600) {
          padding: 16px 0;
          font-size: 15px;
        }
      }

      &--bottom {
        padding: 26px 26px 26px 36px;

        @include r(1500) {
          padding: 19px 22px 19px 30px;
        }
        @include r(600) {
          padding: 16px 0;
          display: none;
        }
      }

      p {
        color: #a3a3a3;
        font-size: 17px;
        font-weight: 400;
        margin: 0;
        padding-left: 29px;

        @include r(900) {
          font-size: 15px;
        }

        @include r(600) {
          padding-left: 0;
          font-size: 13px;
          text-align: center;
        }
      }
    }

    &__settings {
      display: flex;
      margin-top: 50px;

      @include r(600) {
        flex-wrap: wrap;
        margin-top: 20px;
      }
    }

    &__date {
      flex: 0 0 48%;
      padding-right: 20px;

      @include r(600) {
        flex: 0 0 100%;
        padding: 0 0 20px 0;
      }

      .theme--light.v-picker__body {
        background: #fff !important;
      }
      .v-date-picker-header {
        padding: 4px 0;
      }
      .v-date-picker-table {
        padding: 0;
      }
    }
    &__time {
      flex: 0 0 52%;
      padding-left: 20px;

      @include r(600) {
        flex: 0 0 100%;
        padding: 0;
      }
    }

    &__title {
      display: block;
      color: #383838;
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 30px;
      padding-left: 15px;

      @include r(1700) {
        font-size: 21px;
        margin-bottom: 20px;
      }
      @include r(1500) {
        font-size: 19px;
        margin-bottom: 15px;
      }

      @include r(1000) {
        font-size: 18px;
      }

      @include r(600) {
        font-size: 15px;
        font-weight: 700;
      }

      &--brd-t {
        @include r(600) {
          padding-top: 15px;
          border-top: 1px solid #d6d6d6;
        }
      }

      &--brd {
        @include r(600) {
          padding-bottom: 15px;
          border-bottom: 1px solid #d6d6d6;
        }
      }
    }

    &__times {
      display: block;

      &.is-hidden {
        display: none;
      }
    }

    &__select {
      display: block;
      text-align: center;
      color: #a3a3a3;
      font-size: 25px;
      padding: 80px 30px 0 30px;
      font-weight: 400;

      @include r(1700) {
        font-size: 23px;
      }

      @include r(1500) {
        font-size: 20px;
      }

      @include r(900) {
        font-size: 15px;
        padding-top: 36px;
      }

      &.is-hidden {
        display: none;
      }
    }

    &__timeslots {
      display: flex;
      flex-wrap: wrap;
      margin: 20px -4px 0 -4px;
    }
    &__timeslot {
      flex: 0 0 25%;
      padding: 4px;

      button {
        width: 100%;
        border: 0;
        outline: none;
        height: 50px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background-color: #fefefe;
        transition: 0.3s;
        color: #707070;
        font-family: Nunito;
        font-size: 18px;
        font-weight: 400;
        text-align: center;

        @include r(1700) {
          height: 44px;
          font-size: 17px;
        }
        @include r(1500) {
          height: 42px;
          font-size: 16px;
        }

        &:hover {
          background-color: #f8575c;
          color: #fff;
          transition: 0.3s;
        }

        &.is-active {
          background-color: #f8575c;
          color: #fff;
          transition: 0.3s;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__early {
      margin-top: 12px;
      display: block;
      flex: 1 1 auto;
      text-align: left;

      a {
        display: inline-block;
        color: #888;
        font-size: 18px;
        margin-top: 5px;
        font-weight: 400;
        line-height: 1;
        text-decoration: none;
        border-bottom: 1px solid transparent;

        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 16px;
        }

        &:hover {
          border-bottom-color: #888;
          transition: 0.3s;
        }
      }
    }
    &__later {
      margin-top: 12px;
      text-align: right;
      display: block;
      flex: 1 1 auto;

      a {
        display: inline-block;
        color: #888;
        font-size: 18px;
        margin-top: 5px;
        font-weight: 400;
        line-height: 1;
        text-decoration: none;
        border-bottom: 1px solid transparent;

        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 16px;
        }

        &:hover {
          border-bottom-color: #888;
          transition: 0.3s;
        }
      }
    }

    &__bottom {
      display: block;
      padding-right: 110px;
      text-align: center;
      margin-top: 60px;

      @include r(1700) {
        padding-right: 60px;
        margin-top: 40px;
      }

      @include r(1500) {
        padding-right: 50px;
      }
      @include r(900) {
        padding-right: 0;
      }
    }

    &__button {
      max-width: 457px;
      width: 100%;
      display: inline-block;
      height: 87px;
      font-size: 23px;
      margin: 0;

      @include r(1700) {
        max-width: 360px;
        height: 65px;
        font-size: 21px;
      }
      @include r(1500) {
        max-width: 320px;
        height: 60px;
        font-size: 19px;
      }
      @include r(600) {
        max-width: 265px;
        height: 50px;
        font-size: 15px;
        border-radius: 4px;
      }
    }
  }
</style>
