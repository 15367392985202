<template>

  <div class="join-freely">
    <div class="join-freely__wrapper">
      <div class="join-freely__content">
        <div class="join-freely__title">
          <p>
            <strong>Join</strong>
            <span>freely</span>
          </p>
          <v-btn @click="onSearchService()" class="error">Sign up</v-btn>
        </div>
        <div class="join-freely__inners">
          <div class="join-freely__inner">
            <img src="../.././src/images/pages/joinfreely/join-img.svg" alt="">
            <h3>Grow your customer base.</h3>
            <p><strong>Discover new customers.</strong> Set up your profile and sit back whilst we bring customers to
              you. The ability for you to list mobile and salon based services is simple, ensuring you’re optimising
              your day. </p>
            <p><strong>Increase your earnings.</strong> You are in control of your own pricing and availability. We only
              take a small commission, so you keep most of what you charge. </p>
          </div>
          <div class="join-freely__inner">
            <img src="../.././src/images/pages/joinfreely/join-img-2.svg" alt="">
            <h3>Get booked quickly.</h3>
            <p><strong>Instant book.</strong> You are in control of when you work and your pricing, customers can then
              book you instantly. Reducing back and forth prior to a booking enables you to relax.</p>
            <p><strong>Connect your Google Calendar.</strong> Our calendar integration ensures your availability is
              always up to date.
              Appointments you add to your Google Calendar are automatically taken in to account by your Freely calendar
              - ensuring you’re not double booked!</p>
          </div>
          <div class="join-freely__inner">
            <img src="../.././src/images/pages/joinfreely/join-img-3.svg" alt="">
            <h3>Make your life stress-free.</h3>
            <p><strong>Work as much or as little as you like.</strong> Customise your availability and set your own work
              days and hours.
              This, along with the ability to set your own pricing, gives you full control over your job.</p>
            <p><strong>Take secure card payments.</strong> Ever had a customer cancel last minute? Set your own
              cancellation policy and relax, as we take payment when the service is booked. </p>
          </div>
        </div>
        <div class="join-freely__action">
          <router-link to="/practitioner-registration">
            <v-btn class="error">Sign Up</v-btn>
          </router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "JoinFreely",
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Join Freely'
    },
    methods: {
      onSearchService() {
        this.$router.push({path: '/practitioner-registration'});
      }
    }
  };
</script>

<style scoped lang="scss">

  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .join-freely {
    &__wrapper {
      max-width: 1580px;
      margin: 0 auto;
      padding: 0 24px;

      @include r(1700) {
        max-width: 1270px;
      }
      @include r(1600) {
        max-width: 1170px;
      }
      @include r(1500) {
        max-width: 1070px;
      }
      @include r(1400) {
        max-width: 1000px;
      }
      @include r(1300) {
        max-width: 980px;
      }
    }

    &__content {
      padding: 150px 0 180px 0;

      @include r(1700) {
        padding: 120px 0 200px 0;
      }
      @include r(1500) {
        padding: 70px 0 160px 0;
      }
      @include r(1000) {
        padding: 50px 0 100px 0;
      }

      @include r(700) {
        padding: 50px 0 70px 0;
      }
    }

    &__title {
      display: block;
      padding-bottom: 70px;
      border-bottom: 1px solid #b2b2b2;
      flex-wrap: wrap;

      p {
        display: flex;
        align-items: center;
        justify-content: center;

        @include r(550) {
          display: block;
          text-align: center;
        }
      }

      @include r(1500) {
        padding: 0 220px 60px 220px;
      }
      @include r(900) {
        padding: 0 0 50px 0;
      }
      @include r(700) {
        padding: 0;
        border-bottom: 0;
      }

      strong {
        display: inline-block;
        color: #272727;
        font-size: 55px;
        font-weight: 700;
        line-height: 1.2;
        @include r(1700) {
          font-size: 45px;
        }
        @include r(1500) {
          font-size: 40px;
        }
        @include r(1300) {
          font-size: 37px;
        }
        @include r(700) {
          font-size: 32px;
        }
        @include r(550) {
          font-size: 28px;
          display: block;
        }
      }

      span {
        display: inline-block;
        margin-left: 16px;
        color: #272727;
        font-family: "Berkshire Swash";
        font-size: 61px;
        font-weight: 400;
        line-height: 1.2;

        @include r(1700) {
          font-size: 55px;
        }
        @include r(1500) {
          font-size: 45px;
        }
        @include r(550) {
          display: block;
          margin: 2px 0 0 0;
        }
      }
      button {
        display: block;
        max-width: 350px;
        width: 100%;
        height: 75px;
        border-radius: 10px;
        color: #fff;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        margin: 50px auto 0 auto;

        @include r(1700) {
          height: 70px;
          font-size: 21px;
          margin-top: 42px;
        }

        @include r(1500) {
          max-width: 320px;
          height: 60px;
          font-size: 18px;
          margin-top: 36px;
        }

        @include r(900) {
          max-width: 300px;
          height: 55px;
          font-size: 16px;
          border-radius: 4px;
        }
        @include r(700) {
          display: none;
        }

        @include r(450) {
          max-width: 265px;
          height: 50px;
          font-size: 15px;
        }
      }
    }
    &__inners {
      display: block;

      @include r(700) {
        padding-top: 5px;
        position: relative;
        margin-top: 35px;
        &::before {
          top: 0;
          left: 20px;
          right: 20px;
          height: 1px;
          background-color: #b2b2b2;
          position: absolute;
          content: '';
        }
      }
    }

    &__inner {
      display: block;
      max-width: 960px;
      margin: 96px auto 0 auto;

      @include r(1700) {
        margin-top: 80px;
        max-width: 900px;
      }

      @include r(1500) {
        margin-top: 60px;
        max-width: 800px;
      }

      @include r(1400) {
        margin-top: 50px;
        max-width: 760px;
      }

      @include r(1300) {
        margin-top: 40px;
        max-width: 700px;
      }

      img {
        max-width: 180px;
        display: block;
        margin: 0 auto;

        @include r(1500) {
          max-width: 160px;
        }

        @include r(900) {
          max-width: 140px;
        }
        @include r(700) {
          max-width: 120px;
        }
      }

      h3 {
        display: block;
        text-align: center;
        color: #47434d;
        font-size: 40px;
        font-weight: 700;
        margin-top: 40px;

        @include r(1700) {
          font-size: 34px;
          margin-top: 35px;
        }
        @include r(1500) {
          font-size: 30px;
          margin-top: 30px;
        }
        @include r(1400) {
          font-size: 26px;
          margin-top: 25px;
        }
        @include r(900) {
          font-size: 22px;
          margin-top: 20px;
        }
        @include r(550) {
          font-size: 19px;
        }
      }

      p {
        display: block;
        margin: 60px 0 0 0;
        color: #47434d;
        font-size: 25px;
        font-weight: 300;

        @include r(1700) {
          font-size: 23px;
          margin-top: 50px;
        }
        @include r(1500) {
          font-size: 21px;
          margin-top: 40px;
        }
        @include r(1400) {
          font-size: 18px;
          margin-top: 30px;
        }
        @include r(850) {
          font-size: 17px;
          margin-top: 20px;
        }

        strong {
          font-weight: 600;
        }
      }
    }

    &__action {
      display: block;
      text-align: center;
      margin-top: 100px;

      @include r(1700) {
        margin-top: 80px;
      }
      @include r(1500) {
        margin-top: 60px;
      }
      @include r(1400) {
        margin-top: 50px;
      }
      @include r(1300) {
        margin-top: 40px;
      }

      button {
        display: inline-block;
        max-width: 450px;
        width: 100%;
        height: 75px;
        border-radius: 10px;
        color: #fff;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        margin: 0;
        @include r(1700) {
          max-width: 420px;
          height: 70px;
          font-size: 21px;
        }

        @include r(1500) {
          max-width: 320px;
          height: 60px;
          font-size: 18px;
        }

        @include r(900) {
          max-width: 300px;
          height: 55px;
          font-size: 16px;
          border-radius: 4px;
        }

        @include r(450) {
          max-width: 265px;
          height: 50px;
          font-size: 15px;
        }
      }
    }
  }
</style>
