<template>
  <PractitionerAvailability
    ref="practAvailabComponent"
    :id="id"
    :withBookingState="withBookingState"
    :searchAddressToStore="searchAddressToStore"
    :searchAddress="searchAddress"
    :searchTown="searchTown"

    :searchPostcode="searchPostcode"
    :searchCountryId="searchCountryId"
    :searchCountryName="searchCountryName"
    :searchAddressComponents="searchAddressComponents"

    :searchAddressLat="searchAddressLat"
    :searchAddressLon="searchAddressLon"
    :initDate="selectedDate"
    :selectedLocationType="selectedLocationType"
    :selectedSubServiceId="selectedSubServiceId"
    :selectedSubServiceName="selectedSubServiceName"
    bookingFlow="booking"
    @hide-availability-popup="hide"
  >
  </PractitionerAvailability>
</template>

<script>
  import {empty, removeIsFixedToHtml} from '@/helpers.js';
  import PractitionerAvailability from '@/components/Partial/PractitionerAvailability'
  import _ from 'lodash';

  /* eslint-disable */

  export default {
    props: ['id', 'withBookingState'],
    components: {
      PractitionerAvailability
    },
    beforeRouteUpdate(to, from, next) {
      this.setPreBookingState(to, from);
      next();
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.setPreBookingState(to, from);
      });
    },
    beforeRouteLeave(to, from, next){
      removeIsFixedToHtml();
      next()
    },
    data() {
      return {
        searchAddress: null,
        searchAddressToStore: null,
        searchTown: null,

        searchPostcode: null,
        searchCountryId: null,
        searchCountryName: null,
        searchAddressComponents: null,

        searchAddressLat: null,
        searchAddressLon: null,
        selectedDate: null,
        selectedLocationType: null,
        selectedSubServiceId: null,
        selectedSubServiceName: null,
      };
    },
    created: function () {
      this.searchAddressToStore = this.getAddressToStore();
      this.searchAddress = this.$store.getters.search.searchAddress;
      this.searchTown = this.$store.getters.search.searchTown;

      this.searchPostcode = this.$store.getters.search.searchPostcode;
      this.searchCountryId = this.$store.getters.search.searchCountryId;
      this.searchCountryName = this.$store.getters.search.searchCountryName;
      this.searchAddressComponents = this.$store.getters.search.searchAddressComponents;

      this.searchAddressLat = this.$store.getters.search.searchLatitude;
      this.searchAddressLon = this.$store.getters.search.searchLongitude;
      this.selectedDate = this.$store.getters.search.searchDate;
      this.selectedLocationType = this.$store.getters.search.searchLocationType;
      this.selectedSubServiceId = this.$store.getters.search.searchSubServiceId;
      this.selectedSubServiceName = this.$store.getters.search.searchSubServiceName;
    },

    methods: {
      setPreBookingState(to, from){
        this.$refs.practAvailabComponent.setPreBookingState(to, from);
      },
      hide() {
        if (!empty(this.withBookingState) && this.withBookingState === 'with-booking-state') {
          this.$store.dispatch('SET_SEARCH_PARAMS', { searchSubServiceId: ''});
          this.$router.replace({
            path: '/practitioner-search', query: {
              address: this.$store.getters.search.searchAddress,
              town: this.$store.getters.search.searchTown,
              lat: this.$store.getters.search.searchLatitude,
              lon: this.$store.getters.search.searchLongitude,
              // id: this.$store.getters.search.searchSubServiceId,
              // service: this.$store.getters.search.searchSubServiceName,
              type: this.$store.getters.search.searchServiceType,
              location: this.$store.getters.search.searchLocationType,
            }
          });
        } else {
          this.$router.go(-1);
        }
      },
      addressNotification() {
        this.$store.commit('SET_NOTIFICATION', {
          text: 'Empty search address, please try again',
          color: 'orange'
        });
      },
      getAddressToStore() {
        if (!empty(this.$store.getters.search.searchAddress)) {
          const addressArr = this.$store.getters.search.searchAddress.split(', ');
          const addressStr = _.head(addressArr);

          if (!empty(addressStr)) {
            return addressStr;
          } else {
            this.addressNotification();
          }
        } else {
          this.addressNotification();
        }
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .profile-booking {
    position: fixed;
    top: 0;
    left: 50%;
    max-height: 100%;
    margin-left: -593px;
    overflow-y: auto;
    max-width: 1186px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    background-color: #fefefe;
    z-index: 10;
    padding: 50px 180px 140px 290px;

    @include r(1700) {
      padding: 50px 120px 140px 200px;
      max-width: 980px;
      margin-left: -490px;
    }

    @include r(1500) {
      padding: 50px 100px 140px 180px;
      max-width: 940px;
      margin-left: -470px;
    }

    @include r(1000) {
      max-width: 100%;
      left: 0;
      margin: 0;
      border-radius: 0;
    }

    @include r(900) {
      padding: 30px 70px 100px 70px;
    }
    @include r(730) {
      padding: 30px 40px 80px 40px;
    }

    @include r(360) {
      padding: 30px 20px 60px 20px;
    }

    &__back {
      display: none;
      position: relative;
      padding-left: 18px;
      top: -1px;
      color: #383838;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;

      @include r(900) {
        display: inline-block;
      }

      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 2px;
        width: 9px;
        height: 16px;
        background: {
          image: url("../../.././src/images/icons/icon-arrow-right-dark.svg");
          repeat: no-repeat;
          size: contain;
        }
        transition: .3s;
      }

      &:hover {
        &::before {
          transform: translateX(-4px);
          transition: .3s;
        }
      }
    }
    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
      cursor: pointer;

      @include r(900) {
        display: none;
      }

      .v-icon {
        font-size: 32px;
      }
    }

    &__name {
      display: block;
      text-align: center;
      margin-bottom: 70px;
      padding-right: 110px;
      color: #383838;
      font-size: 37px;
      font-weight: 600;
      width: 100%;

      @include r(1700) {
        font-size: 28px;
        margin-bottom: 50px;
      }

      @include r(1700) {
        padding-right: 60px;
      }

      @include r(1500) {
        font-size: 26px;
      }
      @include r(900) {
        padding-right: 0;
        margin: 20px 0 30px 0;
      }
      @include r(730) {
        font-size: 24px;
      }
      @include r(600) {
        font-size: 20px;
      }
    }

    &__step {
      display: block;
      margin-bottom: 28px;

      @include r(1500) {
        margin-bottom: 15px;
      }
    }

    &__lside {
      flex: 1 1 auto;

      span {
        color: #535353;
        font-size: 20px;
        font-weight: 400;

        @include r(1500) {
          font-size: 17px;
        }

        @include r(900) {
          font-size: 15px;
        }
        @include r(600) {
          font-size: 13px;
        }
      }

      &--add {
        span {
          color: #a3a3a3;
        }
      }
    }

    &__rside {
      flex: 0 0 auto;
      display: flex;
      align-items: center;

      span {
        color: #535353;
        font-size: 23px;
        font-weight: 400;
        line-height: 1;

        @include r(1700) {
          font-size: 21px;
        }
        @include r(1500) {
          font-size: 18px;
        }

        @include r(600) {
          font-size: 13px;
        }
      }
    }

    &__btn-small {
      margin: 0 0 0 12px;
      min-width: 40px;
    }
    &__btn-small-radius {
      margin: 0 0 0 12px;
      min-width: 70px;
      border-radius: 10px;
    }

    &__services {
      padding-right: 110px;

      @include r(1700) {
        padding-right: 60px;
      }

      @include r(900) {
        padding-right: 0;
      }
    }

    .v-expansion-panel__header {
      @include r(600) {
        padding: 14px;
      }
    }

    &__service {
      display: flex;
      border-top: 1px solid #d9d9d9;
      padding: 22px 26px 22px 20px;
      align-items: center;

      &--discount {
        .profile-booking__lside, .profile-booking__rside {
          span {
            color: #f25757 !important;
            font-weight: 600;
          }
        }
      }

      @include r(1500) {
        padding: 16px 22px 16px 30px;
      }

      @include r(600) {
        padding: 14px;
      }

      &--add {
        cursor: pointer;
        padding-top: 16px;
        padding-bottom: 16px;

        @include r(1500) {
          padding-top: 9px;
          padding-bottom: 9px;
        }
        span {
          color: #a3a3a3;
        }
        .profile-booking {
          &__rside {
            span {
              font-size: 30px;
            }
          }
        }
      }
    }

    &__locations {
      padding-right: 110px;

      @include r(1700) {
        padding-right: 60px;
      }

      @include r(900) {
        padding-right: 0;
      }
    }

    &__location {
      display: block;
      border-top: 1px solid #d9d9d9;

      label {
        padding: 24px 26px 24px 36px;

        @include r(1500) {
          padding: 18px 22px 18px 30px;
        }
        @include r(600) {
          padding: 16px 0;
          font-size: 15px;
        }
      }

      &--bottom {
        padding: 26px 26px 26px 36px;

        @include r(1500) {
          padding: 19px 22px 19px 30px;
        }
        @include r(600) {
          padding: 16px 0;
          display: none;
        }
      }

      p {
        color: #a3a3a3;
        font-size: 17px;
        font-weight: 400;
        margin: 0;
        padding-left: 29px;

        @include r(900) {
          font-size: 15px;
        }

        @include r(600) {
          padding-left: 0;
          font-size: 13px;
          text-align: center;
        }
      }
    }

    &__settings {
      display: flex;
      margin-top: 50px;

      @include r(600) {
        flex-wrap: wrap;
        margin-top: 20px;
      }
    }

    &__date {
      flex: 0 0 48%;
      padding-right: 20px;

      @include r(600) {
        flex: 0 0 100%;
        padding: 0 0 20px 0;
      }

      .theme--light.v-picker__body {
        background: #fff !important;
      }
      .v-date-picker-header {
        padding: 4px 0;
      }
      .v-date-picker-table {
        padding: 0;
      }
    }
    &__time {
      flex: 0 0 52%;
      padding-left: 20px;

      @include r(600) {
        flex: 0 0 100%;
        padding: 0;
      }
    }

    &__title {
      display: block;
      color: #383838;
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 30px;
      padding-left: 15px;

      @include r(1700) {
        font-size: 21px;
        margin-bottom: 20px;
      }
      @include r(1500) {
        font-size: 19px;
        margin-bottom: 15px;
      }

      @include r(1000) {
        font-size: 18px;
      }

      @include r(600) {
        font-size: 15px;
        font-weight: 700;
      }

      &--brd-t {
        @include r(600) {
          padding-top: 15px;
          border-top: 1px solid #d6d6d6;
        }
      }

      &--brd {
        @include r(600) {
          padding-bottom: 15px;
          border-bottom: 1px solid #d6d6d6;
        }
      }
    }

    &__times {
      display: block;

      &.is-hidden {
        display: none;
      }
    }

    &__select {
      display: block;
      text-align: center;
      color: #a3a3a3;
      font-size: 25px;
      padding: 80px 30px 0 30px;
      font-weight: 400;

      @include r(1700) {
        font-size: 23px;
      }

      @include r(1500) {
        font-size: 20px;
      }

      @include r(900) {
        font-size: 15px;
        padding-top: 36px;
      }

      &.is-hidden {
        display: none;
      }
    }

    &__timeslots {
      display: flex;
      flex-wrap: wrap;
      margin: 20px -4px 0 -4px;
    }
    &__timeslot {
      flex: 0 0 25%;
      padding: 4px;

      button {
        width: 100%;
        border: 0;
        outline: none;
        height: 50px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background-color: #fefefe;
        transition: 0.3s;
        color: #707070;
        font-family: Nunito;
        font-size: 18px;
        font-weight: 400;
        text-align: center;

        @include r(1700) {
          height: 44px;
          font-size: 17px;
        }
        @include r(1500) {
          height: 42px;
          font-size: 16px;
        }

        &:hover {
          background-color: #f8575c;
          color: #fff;
          transition: 0.3s;
        }

        &.is-active {
          background-color: #f8575c;
          color: #fff;
          transition: 0.3s;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__early {
      margin-top: 12px;
      display: block;
      flex: 1 1 auto;
      text-align: left;

      a {
        display: inline-block;
        color: #888;
        font-size: 18px;
        margin-top: 5px;
        font-weight: 400;
        line-height: 1;
        text-decoration: none;
        border-bottom: 1px solid transparent;

        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 16px;
        }

        &:hover {
          border-bottom-color: #888;
          transition: 0.3s;
        }
      }
    }
    &__later {
      margin-top: 12px;
      text-align: right;
      display: block;
      flex: 1 1 auto;

      a {
        display: inline-block;
        color: #888;
        font-size: 18px;
        margin-top: 5px;
        font-weight: 400;
        line-height: 1;
        text-decoration: none;
        border-bottom: 1px solid transparent;

        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 16px;
        }

        &:hover {
          border-bottom-color: #888;
          transition: 0.3s;
        }
      }
    }

    &__bottom {
      display: block;
      padding-right: 110px;
      text-align: center;
      margin-top: 60px;

      @include r(1700) {
        padding-right: 60px;
        margin-top: 40px;
      }

      @include r(1500) {
        padding-right: 50px;
      }
      @include r(900) {
        padding-right: 0;
      }
    }

    &__button {
      max-width: 457px;
      width: 100%;
      display: inline-block;
      height: 87px;
      font-size: 23px;
      margin: 0;

      @include r(1700) {
        max-width: 360px;
        height: 65px;
        font-size: 21px;
      }
      @include r(1500) {
        max-width: 320px;
        height: 60px;
        font-size: 19px;
      }
      @include r(600) {
        max-width: 265px;
        height: 50px;
        font-size: 15px;
        border-radius: 4px;
      }
    }
  }
</style>
