<template>
  <v-container>
    <v-layout row>
      <v-flex xs12>
        <h1>Profile</h1>
        <pre v-if="user">{{ user | pretty }}</pre>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import * as CONFIG from "@/config.js";

/* eslint-disable */

export default {
  name: "Profile",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Profile'
  },
  data () {
    return {
      user: null,
    };
  },
  mounted () {
    axios
      .get(`${CONFIG.API_URL}/auth/user`, {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
        }
      })
      .then(
        response => {
          this.user = JSON.stringify(response.data);
          console.log(this.user);
        },
        error => {
          console.log(error.response.data);
          throw error;
        }
      )
      .catch(error => {
        console.log(error.response.data);
        throw error;
      });
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    }
  }
};
</script>

<style scoped>
</style>
