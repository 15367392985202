<template>
  <div
          class="dashboard-content dashboard-booking"
          id="dashboard-content"
  >
    <div class="dashboard-content__title">
      <h2>Statistics</h2>
    </div>
    <div class="dashboard-content__body">
      <div class="dashboard-settings__container">
        <v-card>
          <v-container
                  fluid
                  grid-list-lg
          >
            <v-layout row wrap>

              <v-flex xs4>
                <v-card>
                  <v-card-title primary-title>
                    <div>
                      <div class="headline text-center pb-2">
                        Search download
                      </div>
                      <div class="pb-3">
                        <DatePicker
                                @changedSelectedDate="changedDateFromComponent"
                        ></DatePicker>
                      </div>

                      <div>
                        <v-form
                                ref="formAddress"
                                lazy-validation
                        >
                          <vuetify-google-autocomplete
                                  ref="address"
                                  id="search-address"
                                  :label="$t('services_search.label_enter_your_address')"
                                  placeholder=""
                                  append-icon="search"
                                  country="UK"
                                  clearable
                                  v-on:placechanged="getAddressData"
                                  v-on:no-results-found="noResultsFound"
                                  types="geocode"
                                  :rules="addressRules"
                                  v-model="selectedSearchAddress"
                                  solo
                                  class="solo-field-white"
                                  @focusin.native="clearAddress()"
                          ></vuetify-google-autocomplete>
                        </v-form>
                      </div>
                      <div>
                        <v-select
                                :items="distanceItems"
                                v-model="selectedDistance"
                                item-text="text"
                                item-value="value"
                                label="Select distance"
                                color="rgba(0,0,0,0.87)"
                        ></v-select>
                      </div>
                    </div>
                    <div>
                      <v-btn class="error" @click="send">Download now</v-btn>
                    </div>
                  </v-card-title>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import { empty } from '@/helpers.js';
  import DatePicker from '@/components/Partial/DatePicker'

  export default {
    name: "admin-dashboard-statistics",
    components:{
      DatePicker
    },
    data(){
      return{
        selectedDate: '',
        selectedSearchAddress: '',

        searchLatitude: null,
        searchLongitude: null,

        addressRules: [v => !!v || this.$i18n.t('services_search.js_error_address_is_required')],
        selectedDistance: 15,
        distanceItems:[
          {text:0.5, value: 0.5},
          {text:1, value: 1},
          {text:5, value: 5},
          {text:10, value: 10},
          {text:15, value: 15},
        ]
      }
    },
    methods:{
      changedDateFromComponent(date) {
        this.selectedDate = date;
      },
      getAddressData(addressData){
        if (!empty(addressData)) {
          this.parseAddress(addressData);
        }
      },
      parseAddress(addressData) {
        if (addressData.latitude) {
          this.searchLatitude = addressData.latitude.toFixed(7);
        }
        if (addressData.longitude) {
          this.searchLongitude = addressData.longitude.toFixed(7);
        }
      },
      clearAddress(){
        this.$refs.address.clear();
        const element = document.getElementById('search-address');
        if(!empty(element)){
          element.value = '';
        }
        this.selectedSearchAddress = '';
        this.searchLatitude = null;
        this.searchLongitude = null;
      },
      noResultsFound(){
        // eslint-disable-next-line
        console.log("not found");
      },
      send(){
        if(this.$refs.formAddress.validate()){
          if(empty(this.searchLatitude) || empty(this.searchLongitude)){
            this.$store.commit('SET_NOTIFICATION', {
              text: 'Unable to establish coordinates at the address. Please select the address from the drop-down list',
              color: 'orange'
            });
            return;
          }

          const data = {
            date: this.selectedDate,
            lon: this.searchLongitude,
            lat: this.searchLatitude,
            address: this.selectedSearchAddress,
            distance: this.selectedDistance
          };

          this.compileDocument(data)
            .then((response)=>{
              let headers = response.headers;
              let blob = new Blob([response.data],{type:headers['content-type']});
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "search.xls";
              link.click();
            })
            .catch((error) => {
              this.$store.commit('SET_NOTIFICATION', {
                text: 'Unable to compile the document please try again',
                color: 'orange'
              });
              // eslint-disable-next-line
              console.log(error);
            });
        }
      },
      compileDocument(data) {
        return axios({
          method: "get",
          url: `${CONFIG.API_URL}/dashboard/admin/search-download`,
          responseType: 'blob',
          headers: {
            // "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken}`
          },
          params: data
        })
      }
    }
  }
</script>

<style scoped>

  .text-center{
    text-align: center;
  }

</style>