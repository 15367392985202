<template>
  <div class="hygiene">

    <div class="hygiene-hero">
      <div class="hygiene__wrapper">
        <div class="hygiene-hero__content">
          <div class="hygiene-hero__holder">
            <div class="hygiene-hero__title">{{ $t('hygiene.hygiene_hero_title') }}</div>
            <div class="hygiene-hero__description">{{ $t('hygiene.hygiene_hero_description') }}
            </div>
          </div>
        </div>
      </div>
      <img src="../../src/images/pages/hygiene/bg-covid-image.jpg" class="hygiene-hero__image" alt="">
    </div>

    <div class="hygiene-protocols">
      <div class="hygiene__wrapper">
        <div class="hygiene-protocols__content">
          <div class="home__title">
            {{ $t('hygiene.new_protocols_for_at_home') }}
          </div>
          <div class="hygiene-protocols__inners">
            <div class="hygiene-protocols__inner">
              <div class="hygiene-protocols__inner-description">
                <p v-html="$t('hygiene.to_reduce_the_risk')">
                </p>
              </div>
            </div>
            <div class="hygiene-protocols__inner">
              <div class="hygiene-protocols__inner-description">
                <p v-html="$t('hygiene.all_our_freely_practitioners')">
                </p>
              </div>
            </div>
          </div>
          <div class="hygiene-protocols__action">
            <v-btn
                class="error"
                @click="onServices()"
            >
              {{ $t('home.view_full_range_of_service') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="hygiene-list hygiene-list--gray">
      <div class="hygiene__wrapper">
        <div class="hygiene-list__content">
          <div class="hygiene-list__inners">
            <div class="hygiene-list__inner">
              <div class="hygiene__title">{{ $t('hygiene.before_your_home_treatment') }}</div>
              <ul class="hygiene-list__items">
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.practitioners_will_confirm') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.all_equipment_and_tools') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.when_travelling_to_your_appointment') }}</p>
                </li>
              </ul>
            </div>
            <div class="hygiene-list__inner">
              <div class="hygiene__title">{{ $t('hygiene.during_your_home_treatment') }}</div>
              <ul class="hygiene-list__items">
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.your_practitioner_will_arrive') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.once_shown_to_the_treatment_space') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.your_practitioner_will_re-sanitise') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.after_your_treatment') }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hygiene-list">
      <div class="hygiene__wrapper">
        <div class="hygiene-list__content">
          <div class="hygiene-list__inners">
            <div class="hygiene-list__inner">
              <div class="hygiene__title hygiene__title--center">{{ $t('hygiene.after_your_home_treatment') }}</div>
              <img src="../../src/images/home/photo-hit-2020.jpg"
                   class="hygiene-list__image hygiene-list__image--mob" alt="">
              <ul class="hygiene-list__items">
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.customers_are_advised') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.for_an_extra_precaution') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.if_you_develop_symptoms') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.in_the_unlikely_event') }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="hygiene-list__image hygiene-list__image--desk"></div>
    </div>

    <div class="hygiene-list hygiene-list--gray">
      <div class="hygiene__wrapper">
        <div class="hygiene-list__content">
          <div class="hygiene-list__inners hygiene-list__inners--actions">
            <div class="hygiene-list__inner">
              <div class="hygiene__title">{{ $t('hygiene.how_our_customers_can_prepare') }}</div>
              <ul class="hygiene-list__items">
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.we_ask_that_you_get') }}
                  </p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.if_your_treatment') }}
                  </p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.please_clean_and_disinfect') }}</p>
                </li>
              </ul>
              <div class="hygiene-list__action">
                <v-btn
                    class="error"
                    @click="onServicesSearch()"
                >
                  {{ $t('hygiene.search_mobile_practitioners') }}
                </v-btn>
              </div>
            </div>
            <div class="hygiene-list__inner">
              <div class="hygiene__title">{{ $t('hygiene.during_your_salon_treatment') }}</div>
              <ul class="hygiene-list__items">
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.salons_have_put_safeguarding') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.you_will_be_asked') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.there_will_be_ongoing') }}</p>
                </li>
                <li class="hygiene-list__item">
                  <p>{{ $t('hygiene.where_possible_please_wear') }}
                  </p>
                </li>
              </ul>
              <div class="hygiene-list__action">
                <v-btn
                    class="error"
                    @click="onServicesSearch()"
                >
                  {{ $t('hygiene.search_local_salons') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-freelance">
      <div class="home__wrapper">
        <div class="home-freelance__content">
          <div class="home-freelance__title">
            <strong>
              {{ $t('home.are_you_freelance_therapist') }}
            </strong>
            <p v-html="$t('home.become_freely_practitioner_today')"></p>
          </div>
          <v-btn
              @click="onLearnMore()"
              class="error home-freelance__button"
          >
            {{ $t('home.learn_more') }}
          </v-btn>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "Hygiene",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Hygiene'
  },
  methods: {
    onLearnMore() {
      this.$router.push({name: 'join-freely'});
      this.$scrollTo('.header.v-toolbar');
    },
    onServices() {
      this.$router.push({name: 'services'});
      this.$scrollTo('.header.v-toolbar');
    },
    onServicesSearch() {
      this.$router.push({name: 'services-search'});
      this.$scrollTo('.header.v-toolbar');
    },
  }
};
</script>

<style scoped lang="scss">

@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.hygiene {
  &__wrapper {
    margin: 0 auto;
    display: block;
    padding: 0 24px;
    max-width: 1658px;

    @include r(1700) {
      max-width: 1270px;
    }
    @include r(1600) {
      max-width: 1170px;
    }
    @include r(1500) {
      max-width: 1070px;
    }
    @include r(1400) {
      max-width: 1000px;
    }
    @include r(1300) {
      max-width: 980px;
    }
  }

  &__title {
    display: block;
    text-align: center;
    color: #272727;
    font-family: Nunito;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;

    @include r(1700) {
      font-size: 34px;
    }

    @include r(1500) {
      font-size: 30px;
    }

    @include r(1000) {
      font-size: 26px;
    }

    @include r(830) {
      padding: 0 24px;
    }

    @include r(700) {
      font-size: 24px;
    }

    @include r(550) {
      font-size: 22px;
    }

    @include r(450) {
      font-size: 19px;
    }

    &--white {
      color: #fff;
    }
  }

  &-hero {
    position: relative;

    @include r(740) {
      padding: 0 60px;
    }

    &::after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      z-index: 1;
      width: 50%;
      height: 100%;
      background-color: #47434d;
      @include r(920) {
        display: none;
      }
    }

    .hygiene {
      &__wrapper {
        @include r(920) {
          padding: 0;
        }
      }
    }

    &__content {
      padding: 110px 0 260px 0;
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 2;

      @include r(1700) {
        padding: 100px 0 240px 0;
      }
      @include r(1400) {
        padding: 90px 0 220px 0;
      }
      @include r(1300) {
        padding: 80px 0 200px 0;
      }
      @include r(1000) {
        padding: 60px 0 180px 0;
      }
      @include r(920) {
        padding: 60px 60px 120px 60px;
        background-color: #47434d;
      }
      @include r(740) {
        padding: 60px 60px 110px 60px;
        margin: 0 -60px;
      }
    }

    &__holder {
      width: 50%;
      padding-left: 190px;

      @include r(1700) {
        padding-left: 120px;
      }
      @include r(1400) {
        padding-left: 100px;
      }
      @include r(1300) {
        padding-left: 80px;
      }
      @include r(1000) {
        padding-left: 60px;
      }
      @include r(920) {
        padding: 0;
        width: 100%;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
      @include r(920) {
        position: relative;
        object-fit: inherit;
        margin: -60px auto 0 auto;
        width: 100%;
        display: block;
        max-width: 600px;
        height: auto;
        display: block;
        z-index: 2;
      }
      @include r(740) {
        margin: -60px 0 0 0;
      }
    }

    &__title {
      display: block;
      color: #f5f2f2;
      font-family: Hind, sans-serif;
      font-size: 55px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;

      @include r(1700) {
        font-size: 48px;
      }

      @include r(1500) {
        font-size: 38px;
      }

      @include r(900) {
        font-size: 32px;
      }

      @include r(700) {
        font-size: 28px;
      }
      @include r(550) {
        font-size: 26px;
      }
      @include r(450) {
        font-size: 24px;
      }
    }

    &__description {
      display: block;
      margin-top: 90px;
      color: #faf7f7;
      font-family: Nunito, sans-serif;
      font-size: 25px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;


      @include r(1700) {
        font-size: 23px;
        margin-top: 80px;
      }
      @include r(1500) {
        font-size: 21px;
        margin-top: 70px;
      }
      @include r(1400) {
        font-size: 19px;
        margin-top: 60px;
      }
      @include r(920) {
        margin-top: 40px;
      }
      @include r(850) {
        font-size: 17px;
      }
      @include r(600) {
        font-size: 15px;
      }

    }
  }

  &-protocols {

    &__content {
      padding: 90px 0;

      @include r(1500) {
        padding: 80px 0;
      }

      @include r(1300) {
        padding: 70px 0;
      }

      @include r(1000) {
        padding: 60px 0;
      }
      @include r(740) {
        padding: 50px 36px;
      }

    }

    &__inners {
      display: flex;
      align-items: stretch;
      margin: 50px -70px 0 -70px;

      @include r(1400) {
        margin: 40px -40px 0 -40px;
      }

      @include r(830) {
        display: block;
        margin: 40px 0 0 0;
      }
    }

    &__inner {
      flex: 0 0 50%;
      padding: 24px 70px;

      @include r(1400) {
        padding: 24px 40px;
      }

      @include r(900) {
        padding: 24px 40px;
      }

      @include r(830) {
        padding: 0 24px;
      }

      @include r(550) {
        padding: 0 0 5px 0;

        &:last-child {
          padding-bottom: 0;
        }
      }

      &-description {

        p {
          display: block;
          color: #47434d;
          font-size: 25px;
          font-weight: 300;
          line-height: 1.2;

          @include r(1700) {
            font-size: 21px;
          }

          @include r(1500) {
            font-size: 19px;
          }
          @include r(900) {
            font-size: 18px;
          }
          @include r(550) {
            font-size: 17px;
          }
        }
      }

      &-action {
        display: block;
        text-align: center;
        margin-top: 75px;

        @include r(1700) {
          margin-top: 60px;
        }

        @include r(1500) {
          margin-top: 40px;
        }

        @include r(700) {
          margin-top: 34px;
        }

        button {
          display: inline-block;
          max-width: 450px;
          width: 100%;
          height: 75px;
          border-radius: 10px;
          color: #fff;
          font-family: Nunito;
          font-size: 23px;
          font-weight: 700;
          margin: 0;

          @include r(1700) {
            max-width: 420px;
            height: 70px;
            font-size: 21px;
          }

          @include r(1500) {
            max-width: 320px;
            height: 60px;
            font-size: 18px;
          }

          @include r(900) {
            max-width: 300px;
            height: 55px;
            font-size: 16px;
            border-radius: 4px;
          }

          @include r(450) {
            max-width: 265px;
            height: 50px;
            font-size: 15px;
          }
        }
      }
    }

    &__action {
      display: block;
      text-align: center;
      margin-top: 75px;

      @include r(1700) {
        margin-top: 60px;
      }

      @include r(1500) {
        margin-top: 40px;
      }

      @include r(700) {
        margin-top: 34px;
      }

      button {
        display: inline-block;
        max-width: 450px;
        width: 100%;
        height: 75px;
        border-radius: 10px;
        color: #fff;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        margin: 0;

        @include r(1700) {
          max-width: 420px;
          height: 70px;
          font-size: 21px;
        }

        @include r(1500) {
          max-width: 320px;
          height: 60px;
          font-size: 18px;
        }

        @include r(900) {
          max-width: 300px;
          height: 55px;
          font-size: 16px;
          border-radius: 4px;
        }

        @include r(450) {
          max-width: 265px;
          height: 50px;
          font-size: 15px;
        }
      }
    }

  }

  &-list {
    position: relative;

    .hygiene {
      &__wrapper {
        max-width: 1490px;

        @include r(1700) {
          max-width: 1270px;
        }
        @include r(1600) {
          max-width: 1170px;
        }
        @include r(1500) {
          max-width: 1070px;
        }
        @include r(1400) {
          max-width: 1000px;
        }
        @include r(1300) {
          max-width: 980px;
        }
      }
    }

    &--gray {
      background-color: #f7f7f7;
      @include r(1000) {
        background-color: transparent;
      }
    }

    &__content {
      padding: 100px 0;
      @include r(1000) {
        padding: 0;
      }
    }

    &__inners {
      display: flex;
      align-items: stretch;
      margin: 0 -130px;
      @include r(1700) {
        margin: 0 -100px;
      }
      @include r(1500) {
        margin: 0 -70px;
      }
      @include r(1200) {
        margin: 0 -50px;
      }
      @include r(1000) {
        display: block;
        margin: 0;
      }

      &--actions {
        .hygiene-list__inner {
          position: relative;
          padding-bottom: 140px;
          @include r(1000) {
            padding-bottom: 50px;
          }
        }

        .hygiene-list__action {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          @include r(1000) {
            position: relative;
          }
        }
      }
    }

    &__inner {
      flex: 0 0 50%;
      padding: 0 130px;

      @include r(1700) {
        padding: 0 100px;
      }
      @include r(1500) {
        padding: 0 70px;
      }
      @include r(1200) {
        padding: 0 70px;
      }
      @include r(1000) {
        padding: 50px 0;
        border-top: 1px solid #b2b2b2;
      }
      @include r(600) {
        padding: 50px 24px;
      }

      .hygiene {
        &__title {
          text-align: left;
          min-height: 80px;

          @include r(1000) {
            padding-left: 30px;
          }
          @include r(600) {
            padding-left: 20px;
          }
        }
      }
    }

    &__image {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 50%;
      height: 680px;
      background: {
        image: url("../.././src/images/pages/hygiene/image-covid-2.jpg");
        size: cover;
        position: center left;
      }

      &--desk {
        @include r(1000) {
          display: none;
        }
      }

      &--mob {
        display: none;
        position: relative;
        transform: none;
        top: 0;
        background: none;
        height: auto;

        @include r(1000) {
          display: block;
          max-width: 500px;
          width: 100%;
          margin: 30px auto 0 auto;
        }
      }
    }

    &__items {
      display: block;
      margin-top: 50px;
      padding-left: 0;

      @include r(1700) {
        margin-top: 40px;
      }
      @include r(1500) {
        margin-top: 30px;
      }
      @include r(700) {
        margin-top: 20px;
      }
    }

    &__item {
      display: block;
      margin-top: 40px;
      position: relative;


      @include r(1700) {
        margin-top: 30px;
      }
      @include r(1500) {
        margin-top: 20px;
      }
      @include r(1000) {
        padding-left: 30px;
      }
      @include r(600) {
        padding-left: 20px;
      }

      &::before {
        position: absolute;
        content: "";
        left: -80px;
        top: 7px;
        width: 25px;
        height: 25px;
        background-color: #b2b2b2;
        border-radius: 50%;

        @include r(1700) {
          width: 24px;
          height: 24px;
          left: -65px;
          top: 6px;
        }
        @include r(1700) {
          width: 23px;
          height: 23px;
        }
        @include r(1500) {
          width: 20px;
          height: 20px;
          left: -40px;
          top: 5px;
        }
        @include r(1200) {
          width: 17px;
          height: 17px;
          left: -30px;
          top: 5px;
        }
        @include r(1000) {
          width: 14px;
          height: 14px;
          left: 0;
        }
        @include r(600) {
          width: 10px;
          height: 10px;
          top: 7px;
          left: -10px;
        }
      }

      p {
        display: block;
        margin: 0;
        color: #47434d;
        font-size: 25px;
        font-weight: 300;

        @include r(1700) {
          font-size: 23px;
        }
        @include r(1500) {
          font-size: 19px;
        }
        @include r(1400) {
          font-size: 18px;
        }
        @include r(850) {
          font-size: 17px;
        }

        strong {
          font-weight: 600;
        }
      }
    }

    &__action {
      display: block;
      text-align: center;
      margin-top: 75px;

      @include r(1700) {
        margin-top: 60px;
      }

      @include r(1500) {
        margin-top: 40px;
      }

      @include r(700) {
        margin-top: 34px;
      }

      button {
        display: inline-block;
        max-width: 450px;
        width: 100%;
        height: 75px;
        border-radius: 10px;
        color: #fff;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        margin: 0;

        @include r(1700) {
          max-width: 420px;
          height: 70px;
          font-size: 21px;
        }

        @include r(1500) {
          max-width: 320px;
          height: 60px;
          font-size: 18px;
        }

        @include r(900) {
          max-width: 300px;
          height: 55px;
          font-size: 16px;
          border-radius: 4px;
        }

        @include r(450) {
          max-width: 265px;
          height: 50px;
          font-size: 15px;
        }
      }
    }

    .hygiene {
      &__title {
        &--center {
          @include r(1000) {
            text-align: center;
          }
        }
      }
    }
  }

}

.home {
  &-freelance {
    background-color: #f25757;

    .home {
      &__wrapper {
        max-width: 1408px;

        @include r(400) {
          padding: 0 18px;
        }
      }
    }

    &__content {
      padding: 36px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include r(1700) {
        padding: 30px 0;
      }

      @include r(1500) {
        padding: 26px 0;
      }
      @include r(900) {
        padding: 24px 0;
      }
      @include r(800) {
        justify-content: space-between;
      }
      @include r(650) {
        padding: 22px 0;
      }

      button {
        width: 154px;
        height: 49px;
        color: #fff;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
        margin: 0;
        background-color: transparent !important;
        border: 1px solid #fdf7f7 !important;
        border-radius: 5px !important;

        &::before {
          opacity: 0;
        }

        @include r(1700) {
          font-size: 19px;
          height: 46px;
        }
        @include r(1500) {
          font-size: 18px;
          height: 44px;
        }
        @include r(900) {
          font-size: 15px;
          height: 42px;
          width: 112px;
        }
        @include r(650) {
          flex: 0 0 auto;
        }
        @include r(450) {
          width: 89px;
          height: 40px;
          border-radius: 4px;
          font-size: 12px;
        }
      }
    }

    &__title {
      @include r(650) {
        flex: 1 1 auto;
        padding-right: 8px;
      }

      strong {
        display: inline-block;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: 1.2;
        position: relative;
        top: 1px;
        @include r(1700) {
          font-size: 23px;
        }
        @include r(1500) {
          font-size: 21px;
        }
        @include r(900) {
          font-size: 20px;
        }
        @include r(550) {
          font-size: 18px;
        }
        @include r(450) {
          font-size: 15px;
        }
      }

      p {
        display: inline-block;
        color: #fff;
        font-size: 25px;
        font-weight: 300;
        line-height: 1.1;
        margin: 0 12px;

        @include r(1700) {
          font-size: 23px;
        }
        @include r(1500) {
          font-size: 21px;
        }
        @include r(900) {
          font-size: 20px;
        }
        @include r(800) {
          display: block;
          margin-left: 0;
        }
        @include r(650) {
          display: block;
          margin: 4px 0 0 0;
        }
        @include r(550) {
          font-size: 18px;
        }
        @include r(450) {
          font-size: 15px;
          line-height: 1.4;
        }

        span {
          color: #fff;
          font-family: "Berkshire Swash";
          font-size: 28px;
          font-weight: 400;
          display: inline-block;
          line-height: 1.1;
          font-style: normal;
          margin: 0 7px;

          @include r(1700) {
            font-size: 27px;
          }
          @include r(1500) {
            font-size: 25px;
          }
          @include r(900) {
            font-size: 24px;
          }
          @include r(550) {
            font-size: 22px;
          }
          @include r(450) {
            font-size: 20px;
          }
        }
      }
    }
  }
}


</style>
