<template>
  <transition name="fade">
    <div
      class="profile-info is-close"
      v-if="showSidebar"
    >
      <div class="profile-info__container" id="profile-info">
        <div
          class="profile-info__close"
          @click="closeSidebar()"
        >
          <v-icon>close</v-icon>
        </div>
        <v-progress-linear
          v-if="bioDataProgress || reviewsDataProgress || treatmentsDataProgress"
          indeterminate
          color="error"
          height="3"
        ></v-progress-linear>
        <div class="profile-info__top">
          <div class="profile-info__inner">
            <div class="profile-info-mob__navigation">
              <div class="profile-info-mob__back" @click="closeSidebar()">
                {{$t('practitioner_search_profile.back')}}
              </div>
              <div class="profile-info-mob__tabs">
                <div class="profile-info-mob__tab" @click="scrollingTab(1, '#bio_tab', 800)"
                     :class="[ activeSidebarTab === 1 ? 'is-show' : '' ]">
                  {{$t('practitioner_search_profile.bio')}}
                </div>
                <div class="profile-info-mob__tab" @click="scrollingTab(2, '#reviews_tab', 1600)"
                     :class="[ activeSidebarTab === 2 ? 'is-show' : '' ]">
                  {{$t('practitioner_search_profile.reviews')}}
                </div>
                <div class="profile-info-mob__tab" @click="scrollingTab(3, '#treatments_tab', 2400)"
                     :class="[ activeSidebarTab === 3 ? 'is-show' : '' ]">
                  {{$t('practitioner_search_profile.treatments')}}
                </div>
              </div>
            </div>
          </div>
          <div class="profile-info__holder">
            <swiper
              :options="swiperOption"
              v-if="userImages.length > 0 && showAvatar"
              ref="swiperManager"
            >
              <swiper-slide
                v-for="(image, index) in userImages"
                :key="image"
              >
                <div class="swiper-zoom-container">
                  <div class="profile-info__photo"
                       :style="{ 'background-image': 'url(' + image + ')' }"
                       @click="showInLightBox(index)"
                  >
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
            <div
              v-if="userImages.length == 0 && showAvatar"
              class="profile-info__photo-empty"
            ></div>

            <div class="profile-info__inner profile-info__inner--description" v-if="!reviewsDataProgress">
              <div class="profile-info-mob__items">
                <div class="profile-info-mob__item">
                  <div class="profile-info-mob__name">{{ practitionerName }}</div>
                </div>
                <div class="profile-info-mob__item">
                  <star-rating
                    v-model="practitionerRating"
                    read-only
                    :show-rating="false"
                    :star-size="22"
                    :border-width="0"
                    :increment="0.5"
                    :rounded-corners="true"
                    active-color="#ffd100"
                  ></star-rating>
                  <div class="profile-info__views" v-if="practitionerReviewsCount > 0">{{ practitionerReviewsCount }}</div>
                </div>
                <div class="profile-info-mob__item">
                  <div class="profile-info-mob__item-title">
                    {{$t('practitioner_search_profile.experience')}}
                    <b>{{ practitionerExperience | experience }}</b></div>
                </div>
                <!--<div class="profile-info-mob__item">-->
                <!--<div class="profile-info-mob__item-title">-->
                <!--{{$t('practitioner_search_profile.battersea')}}-->
                <!--<span>(1.2 miles away)</span></div>-->
                <!--</div>-->
              </div>
            </div>
          </div>
          <div class="profile-info__inner" v-if="!reviewsDataProgress">
            <div class="profile-info__rating">
              <star-rating
                v-model="practitionerRating"
                read-only
                :show-rating="false"
                :star-size="22"
                :border-width="0"
                :increment="0.5"
                :rounded-corners="true"
                active-color="#ffd100"
              ></star-rating>
              <div class="profile-info__views" v-if="practitionerReviewsCount > 0">{{ practitionerReviewsCount }}</div>
            </div>
            <div class="profile-info__name">{{ practitionerName }}</div>
          </div>
        </div>
        <div class="profile-info__bottom">
          <div class="profile-info__inner">
            <div class="profile-info__tabs">
              <div class="profile-info__buttons">
                <div
                  class="profile-info__button 1111"
                  @click="scrollingTab(1, '#bio_tab', 800)"
                  :class="[ activeSidebarTab === 1 ? 'is-show' : '' ]"
                >
                  <span>Bio</span>
                </div>
                <div
                  class="profile-info__button"
                  @click="scrollingTab(2, '#reviews_tab', 1600)"
                  :class="[ activeSidebarTab === 2 ? 'is-show' : '' ]"
                >
                  <span>Reviews</span>
                </div>
                <div
                  class="profile-info__button"
                  @click="scrollingTab(3, '#treatments_tab', 2400)"
                  :class="[ activeSidebarTab === 3 ? 'is-show' : '' ]"
                >
                <span>
                  {{$t('practitioner_search_profile.additional_treatments')}}
                </span>
                </div>
              </div>
              <div class="profile-info__contents">
                <div
                  class="profile-info__tab profile-info__tab--bio is-show" id="bio_tab">
                  <div class="profile-info__bio" v-if="!bioDataProgress">
                    <div class="profile-info__bio-top">
                      <div class="profile-info__bio-items">
                        <div class="profile-info__bio-item">
                          <v-icon v-if="workLocationTypeMobile">done</v-icon>
                          <v-icon v-else>close</v-icon>
                          <span>
                             {{$t('practitioner_search_profile.mobile')}}
                          </span>
                        </div>
                        <div class="profile-info__bio-item">
                          <v-icon v-if="workLocationTypeSalon">done</v-icon>
                          <v-icon v-else>close</v-icon>
                          <span>
                            {{$t('practitioner_search_profile.salon')}}
                          </span>
                        </div>
                      </div>
                      <div class="profile-info__bio-experience">
                        <div class="icon-experience"></div>
                        <span>{{ practitionerExperience | experience }}</span>
                      </div>
                    </div>
                    <div class="profile-info__bio-description">
                      <p>
                        {{ practitionerBio }}
                      </p>
                    </div>
                    <template
                      v-if="practitionerQualification != null && practitionerQualification.length > 0"
                    >
                      <br>
                      <p>Qualifications:</p>
                      <ul>
                        <li v-for="(item, index) in practitionerQualification" :key="index">
                          {{ item }}
                        </li>
                      </ul>
                    </template>

                    <template
                      v-if="!bioDataProgress && this.$store.getters.search.searchLocationType != 'mobile' && workLocationTypeSalon == true"
                    >
                      <br>
                      <p>Salon location:</p>
                      <l-map
                        ref="map"
                        :zoom="mapZoom"
                        :center="mapCenter"
                        :options="{ zoomControl: false }"
                        class="profile-info__bio-map"
                      >
                        <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                        <l-tile-layer
                          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                        />
                        <l-circle
                          :lat-lng="mapCenter"
                          :radius="1609.34"
                          :opacity="0.7"
                          :fillOpacity="0.3"
                          color="#00FFFF"
                          fillColor="#00FFFF"
                          :weight="1"
                          :interactive="false"
                        />
                      </l-map>
                      <div class="profile-info__bio-description"

                      >
                        <p>
                          {{$t('practitioner_search_profile.exact_location')}}
                        </p>
                      </div>
                    </template>

                  </div>
                </div>
                <div
                  class="profile-info__tab profile-info__tab--reviews is-show" id="reviews_tab">
                  <div class="profile-info__reviews" v-if="!reviewsDataProgress">
                    <div
                      class="profile-info__treatments-top"
                      v-if="!isReviewsAvailable"
                    >
                      <span>
                        {{$t('practitioner_search_profile.no_reviews ')}}
                      </span>
                    </div>
                    <div
                      class="profile-info__reviews-types"
                      v-if="!reviewsDataProgress && isReviewsAvailable"
                    >
                      <div class="profile-info__reviews-type">
                        <div class="profile-info__reviews-type-lside">
                          <strong>
                            {{$t('practitioner_search_profile.overall')}}
                          </strong>
                        </div>
                        <div class="profile-info__reviews-type-rside">
                          <strong>{{ practitionerRating }}</strong>
                          <div class="profile-info__reviews-type-rating">
                            <star-rating
                              v-model="practitionerRating"
                              read-only
                              :show-rating="false"
                              :star-size="19"
                              :border-width="0"
                              :increment="0.5"
                              :rounded-corners="true"
                              active-color="#ffd100"
                            ></star-rating>
                          </div>
                        </div>
                      </div>
                      <div class="profile-info__reviews-type">
                        <div class="profile-info__reviews-type-lside">
                          <span>
                            {{$t('practitioner_search_profile.friendliness')}}
                          </span>
                        </div>
                        <div class="profile-info__reviews-type-rside">
                          <span>{{ practitionerReviews.friendliness }}</span>
                          <div class="profile-info__reviews-type-rating">
                            <star-rating
                              v-model="practitionerReviews.friendliness"
                              read-only
                              :show-rating="false"
                              :star-size="19"
                              :border-width="0"
                              :increment="0.5"
                              :rounded-corners="true"
                              active-color="#ffd100"
                            ></star-rating>
                          </div>
                        </div>
                      </div>
                      <div class="profile-info__reviews-type">
                        <div class="profile-info__reviews-type-lside">
                          <span>
                            {{$t('practitioner_search_profile.communication')}}
                          </span>
                        </div>
                        <div class="profile-info__reviews-type-rside">
                          <span>{{ practitionerReviews.communication }}</span>
                          <div class="profile-info__reviews-type-rating">
                            <star-rating
                              v-model="practitionerReviews.communication"
                              read-only
                              :show-rating="false"
                              :star-size="19"
                              :border-width="0"
                              :increment="0.5"
                              :rounded-corners="true"
                              active-color="#ffd100"
                            ></star-rating>
                          </div>
                        </div>
                      </div>
                      <div class="profile-info__reviews-type">
                        <div class="profile-info__reviews-type-lside">
                          <span>
                             {{$t('practitioner_search_profile.satisfaction')}}
                          </span>
                        </div>
                        <div class="profile-info__reviews-type-rside">
                          <span>{{ practitionerReviews.satisfaction }}</span>
                          <div class="profile-info__reviews-type-rating">
                            <star-rating
                              v-model="practitionerReviews.satisfaction"
                              read-only
                              :show-rating="false"
                              :star-size="19"
                              :border-width="0"
                              :increment="0.5"
                              :rounded-corners="true"
                              active-color="#ffd100"
                            ></star-rating>
                          </div>
                        </div>
                      </div>
                      <div class="profile-info__reviews-type">
                        <div class="profile-info__reviews-type-lside">
                          <span>
                            {{$t('practitioner_search_profile.timekeeping')}}
                          </span>
                        </div>
                        <div class="profile-info__reviews-type-rside">
                          <span>{{ practitionerReviews.timekeeping }}</span>
                          <div class="profile-info__reviews-type-rating">
                            <star-rating
                              v-model="practitionerReviews.timekeeping"
                              read-only
                              :show-rating="false"
                              :star-size="19"
                              :border-width="0"
                              :increment="0.5"
                              :rounded-corners="true"
                              active-color="#ffd100"
                            ></star-rating>
                          </div>
                        </div>
                      </div>


                    </div>

                    <div
                      class="profile-info__reviews-items"
                      v-if="!reviewsDataProgress"
                    >
                      <br>
                      <div
                        class="profile-info__reviews-item"
                        v-for="item in practitionerReviewsInfo"
                        :key="item.id"
                      >
                        <br>
                        <div class="profile-info__reviews-top">
                          <div class="profile-info__reviews-name">{{ item.reviewer_name }}</div>
                          <div class="profile-info__reviews-rating">
                            <star-rating
                              v-model="item.rating"
                              read-only
                              :show-rating="false"
                              :star-size="19"
                              :border-width="0"
                              :increment="0.5"
                              :rounded-corners="true"
                              active-color="#ffd100"
                            ></star-rating>
                          </div>
                        </div>
                        <div class="profile-info__reviews-date">{{ item.date }}</div>
                        <div class="profile-info__reviews-comment">
                          <p>
                            {{ item.text }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="profile-info__tab profile-info__tab--treatments is-show" id="treatments_tab">
                  <div
                    class="profile-info__treatments"
                    v-if="!treatmentsDataProgress"
                  >
                    <div class="profile-info__treatments-top">
                      <span>
                        {{$t('practitioner_search_profile.you_can_add_more_treatments')}}
                      </span>
                    </div>
                    <div class="profile-info__treatments-items">
                      <div
                        class="profile-info__treatments-item"
                        v-for="item in practitionerTreatments"
                        :key="item.id"
                      >
                        <div class="profile-info__treatments-name">{{ item.name }}</div>
                        <div class="profile-info__treatments-price">£{{ item.price }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-info__select">
        <v-btn
          block
          color="error"
          class="btn-book-now profile-info__book"
          @click="showPractitionerBookingModal()"
        >
          {{$t('practitioner_search_profile.select')}}
        </v-btn>
      </div>

      <light-box
        :images="lightBoxImages"
        ref="lightbox"
        :show-light-box="false"
      >
        <template v-slot:previousThumb><span></span></template>
        <template v-slot:nextThumb><span></span></template>
      </light-box>

    </div>
  </transition>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {formatExperience, addIsFixedToHtml, removeIsFixedToHtml} from "@/helpers.js";

  import {LMap, LTileLayer, LCircle} from 'vue2-leaflet';
  import 'leaflet/dist/leaflet.css';

  /* eslint-disable */

  export default {
    props: ['id'],
    data: function () {
      return {
        swiperOption: {
          slidesPerView: 3,
          centeredSlides: true,
          loop: false,
          spaceBetween: 45,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            500: {
              slidesPerView: 1,
              spaceBetween: 30,
              centeredSlides: false
            }
          }
        },
        userImages: [],
        showAvatar: false,

        bioDataProgress: false,
        reviewsDataProgress: false,
        treatmentsDataProgress: false,

        isReviewsAvailable: false,
        showSidebar: true,
        practitionerId: this.id,
        practitionerRating: null,
        practitionerReviewsCount: null,
        practitionerName: null,
        workLocationTypes: {},
        workLocationTypeMobile: false,
        workLocationTypeSalon: false,
        practitionerExperience: null,
        practitionerBio: null,
        practitionerQualification: null,

        practitionerReviews: {},
        practitionerReviewsInfo: [],
        // practitionerReviewsOverall: 0,
        practitionerTreatments: {},

        activeSidebarTab: 1,

        mapZoom: 12,
        mapCenter: L.latLng(0, 0),
        googleLayerOptions: {
          type: 'roadmap'
        },
      }
    },
    beforeRouteUpdate(to, from, next) {
      this.practitionerId = to.params.id;
      this.getMapLocation();
      next();
    },
    beforeRouteLeave(to, from, next) {
      removeIsFixedToHtml();
      next()
    },
    created() {
      this.getMapLocation();
      this.getReviews();
      this.getTreatments();
      this.setAmountOfSliders();
      window.addEventListener('resize', this.setAmountOfSliders);
    },
    destroyed() {
      window.removeEventListener('resize', this.setAmountOfSliders);
    },
    mounted() {
      addIsFixedToHtml();
    },
    components: {
      LMap,
      LTileLayer,
      LCircle
    },
    filters: {
      experience: function (value) {
        return formatExperience(value);
      }
    },
    computed: {
      lightBoxImages() {
        let data = [];
        for (let i = 0; i < this.userImages.length; i++) {
          data.push({src: this.userImages[i], thumb: this.userImages[i]})
        }
        return data;
      }
    },
    methods: {
      showInLightBox(index) {
        this.$refs.lightbox.showImage(index);
      },
      setAmountOfSliders() {
        // this.swiperOption.slidesPerView = (this.$vuetify.breakpoint.width >= 500) ? 3 : 1;
      },
      scrollingTab(indexTab, selector, duration = 800) {
        this.activeSidebarTab = indexTab;
        this.$scrollTo(selector, duration, {container: '#profile-info', force: true,});
      },
      closeSidebar() {
        this.showAvatar = false;
        this.$router.go(-1);
        // this.showSidebar = false;
      },
      getReviews() {
        this.reviewsDataProgress = true;

        axios
          .get(`${CONFIG.API_URL}/practitioner/${this.practitionerId}/reviews`, {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest"
            }
          })
          .then(
            response => {
              const response_data = response.data;

              this.practitionerReviews = response_data.rating;
              this.practitionerReviewsInfo = response_data.reviews;

              // let i = 0;
              // let average = 0;
              // for (let item in this.practitionerReviews) {
              //   average+=this.practitionerReviews[item];
              //   i++;
              // }
              // this.practitionerReviewsOverall = Number(average / i).toFixed(1);

              this.reviewsDataProgress = false;
              this.isReviewsAvailable = true;
            })
          .catch(error => {
            this.reviewsDataProgress = false;
            this.isReviewsAvailable = false;
            console.log(error.response);
            throw error;
          });
      },
      getTreatments() {
        this.practitionerTreatments = {};
        this.treatmentsDataProgress = true;

        axios
          .get(`${CONFIG.API_URL}/practitioner/${this.practitionerId}/services`, {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest"
            }
          })
          .then(
            response => {
              const response_data = response.data;
              this.practitionerTreatments = response_data.sub_services;
              this.treatmentsDataProgress = false;
            })
          .catch(error => {
            this.treatmentsDataProgress = false;
            console.log(error.response.data);
            throw error;
          });
      },
      getMapLocation() {
        this.bioDataProgress = true;
        this.userImages = [];

        axios
          .get(`${CONFIG.API_URL}/practitioner/${this.practitionerId}/info`, {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest"
            }
          })
          .then(
            response => {
              const response_data = response.data;

              for (var i = 0; i < response_data.work_images.length; i++) {
                this.userImages.push(response_data.work_images[i]);
              }

              if (response_data.avatar) {
                this.userImages.splice(1, 0, response_data.avatar);
              }

              this.showAvatar = true;

              this.practitionerRating = response_data.practitioner_rating;
              this.practitionerReviewsCount = response_data.reviews_amount;
              this.practitionerName = response_data.name;
              this.workLocationTypes = response_data.work_location_types;
              this.workLocationTypeMobile = response_data.work_location_types.mobile;
              this.workLocationTypeSalon = response_data.work_location_types.salon;
              this.practitionerExperience = response_data.experience;
              this.practitionerBio = response_data.bio;
              this.bioDataProgress = false;
              this.practitionerQualification = response_data.qualifications;

              const lat = response_data.geoposition.lat;
              const lon = response_data.geoposition.lon;
              this.mapZoom = 12;
              this.mapCenter = L.latLng(lat, lon);

              if (this.userImages.length > 1) {
                setTimeout(() => {
                  this.$refs.swiperManager.swiper.slideNext();
                }, 500);
              }
            })
          .catch(error => {
            this.bioDataProgress = false;
            console.log(error.response.data);
            throw error;
          });
      },
      showPractitionerBookingModal() {
        this.$emit('showBookingModal', this.practitionerId, this.practitionerName, this.workLocationTypes)
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .profile-info {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 480px;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px 0 0;
    top: 128px;

    @include r(1800) {
      top: 110px;
    }

    @include r(1700) {
      top: 97px;
      width: 440px;
    }

    @include r(1500) {
      top: 88px;
    }

    @include r(1400) {
      top: 82px;
    }

    @include r(1300) {
      top: 80px;
    }
    @include r(650) {
      top: 68px;
    }

    @include r(1500) {
      width: 400px;
    }

    @include r(1400) {
      width: 375px;
    }
    @include r(1000) {
      z-index: 14;
      box-shadow: none;
      border-radius: 0;
    }

    @include r(500) {
      background-color: #f9f9f9;
      width: 100%;
    }

    &__holder {
      @include r(500) {
        display: flex;
        align-items: flex-start;
        padding: 0 24px;
      }
      .swiper-container {
        @include r(500) {
          padding: 0 24px;
          width: 150px;
          flex: 0 0 auto;
        }
        @include r(400) {
          padding: 0 24px;
          flex: 0 0 auto;
        }
        .swiper-button-prev {
          left: 0;
          top: 0;
          outline: none;
          margin: 0;
          bottom: 0;
          height: 100%;
          width: 33.3333333%;
          background: {
            image: url("../.././images/icons/nav-prev.svg");
            repeat: no-repeat;
            position: center right 4px;
            size: 10px;
          }
          cursor: pointer;

          @include r(500) {
            width: 24px;
            background-position: center left;
          }
        }
        .swiper-button-next {
          right: 0;
          top: 0;
          outline: none;
          margin: 0;
          bottom: 0;
          height: 100%;
          width: 33.3333333%;
          background: {
            image: url("../.././images/icons/nav-next.svg");
            repeat: no-repeat;
            position: center left 4px;
            size: 10px;
          }
          cursor: pointer;
          @include r(500) {
            width: 24px;
            background-position: center right;
          }
        }
        .swiper-slide {
          cursor: pointer;
          transition: .3s;

          &:hover {
            opacity: .8;
          }
          @include r(500) {
            height: 98px !important;
          }
        }
      }
    }

    &-mob {
      &__navigation {
        display: none;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        @include r(900) {
          display: flex;
        }
      }

      &__back {
        display: inline-block;
        position: relative;
        padding-left: 14px;
        top: -1px;
        color: #383838;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;

        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 2px;
          width: 9px;
          height: 16px;
          background: {
            image: url("../../.././src/images/icons/icon-arrow-right-dark.svg");
            repeat: no-repeat;
            size: contain;
          }
          transition: .3s;
        }

        &:hover {
          &::before {
            transform: translateX(-4px);
            transition: .3s;
          }
        }
      }

      &__tabs {
        display: flex;
        align-items: center;
      }

      &__tab {
        display: inline-block;
        color: #383838;
        font-size: 15px;
        font-weight: 600;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        margin-left: 28px;
        transition: .3s;

        &:hover, &.is-show {
          border-bottom-color: #383838;
          transition: .3s;
        }
      }
      &__items {
        display: none;
        padding-top: 20px;

        @include r(900) {
          display: block;
        }
      }

      &__name {
        display: block;
        color: #383838;
        font-size: 20px;
        font-weight: 600;
      }
      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .vue-star-rating {
          height: 19px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .vue-star-rating {
          svg {
            width: 18px;
            height: 18px;
          }
        }

        &-title {
          color: #383838;
          font-size: 13px;
          font-weight: 400;

          b {
            font-weight: 400;
            display: inline-block;
            margin-left: 5px;
          }

          span {
            color: #b2b2b2;
            display: inline-block;
            margin-left: 5px;

          }
        }
        .profile-info {
          &__views {
            margin: 0 0 0 4px;
            color: #a3a3a3;
            font-size: 10px;
            font-weight: 400;

            @include r(900) {
              position: relative;
              top: 2px;
            }
          }
        }
      }

    }

    &__inner {
      padding: 0 50px;
      width: 100%;

      @include r(1700) {
        padding: 0 40px;
      }
      @include r(1500) {
        padding: 0 36px;
      }
      @include r(1400) {
        padding: 0 30px;
      }
      @include r(900) {
        padding: 0 24px;
      }

      &--description {
        @include r(500) {
          padding-right: 0;
          width: auto;
          flex: 1 1 auto;

          .profile-info-mob__items {
            padding-top: 12px;
            @include r(400) {
              padding-top: 4px;
            }
          }
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      padding: 68px 0 20px 0;
      position: relative;
      z-index: 2;
      max-height: calc(100% - 105px);
      overflow-y: auto;

      @include r(1800) {
        max-height: calc(100% - 90px);
      }

      @include r(1700) {
        max-height: calc(100% - 80px);
      }

      @include r(1500) {
        padding: 60px 0 20px 0;
        max-height: calc(100% - 70px);
      }

      @include r(1400) {
        max-height: calc(100% - 60px);
      }

      @include r(1000) {
        max-height: calc(100% - 50px);
      }
      @include r(900) {
        padding: 23px 0 20px 0;
      }
      @include r(500) {
        max-height: calc(100% - 91px);
      }
    }

    &__top {
      flex: 0 0 auto;
    }
    &__bottom {
      display: flex;
      flex: 1 1 auto;
      padding-top: 38px;

      @include r(1700) {
        padding-top: 30px;
      }
      @include r(1500) {
        padding-top: 20px;
      }
      @include r(900) {
        padding-top: 0;
      }
    }

    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 6;
      cursor: pointer;
      transition: .3s;

      &:hover {
        opacity: .7;
        transition: .3s;
      }

      @include r(1500) {
        top: 14px;
        right: 14px;
      }
      @include r(900) {
        display: none;
      }

      .v-icon {
        font-size: 32px;
      }
    }

    &__select {
      @include r(500) {
        padding: 20px 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #f9f9f9;
        border-top: 1px solid #dbdbdb;
        z-index: 5;
      }
    }

    &__book {
      position: absolute;
      margin: 0;
      border-radius: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
      height: 105px;
      background-color: #f8575c;
      text-align: center;
      font-weight: 600;
      color: #fff;
      font-size: 20px;
      width: 100%;
      &:hover, &:focus {
        position: absolute;
      }

      @include r(1800) {
        height: 90px;
      }

      @include r(1700) {
        height: 80px;
      }

      @include r(1500) {
        height: 70px;
        font-size: 18px;
      }
      @include r(1400) {
        height: 60px;
        font-size: 17px;
      }
      @include r(1000) {
        height: 50px;
        font-size: 15px;
      }

      @include r(500) {
        position: relative;
        border-radius: 4px;

        &:hover {
          position: relative !important;
        }
      }
    }

    &__photo-empty {
      display: block;
      border-radius: 20px;
      margin: 0 auto;
      height: 128px;
      width: 128px;
      background: {
        image: url("../../.././src/images/icons/icon-user-photo.svg");
        repeat: no-repeat;
        position: center;
        color: #ff5a5f;
      }

      @include r(1700) {
        height: 114px;
        width: 114px;
      }
      @include r(1500) {
        height: 101px;
        width: 101px;
        border-radius: 15px;
      }
      @include r(1400) {
        height: 93px;
        width: 93px;
      }

      @include r(900) {
        border-radius: 10px;
      }

      @include r(500) {
        height: 128px;
        width: 128px;
      }
      @include r(450) {
        height: 98px;
        width: 98px;
      }
      @include r(390) {
        height: 93px;
        width: 93px;
      }
      @include r(360) {
        height: 83px;
        width: 83px;
      }
    }

    &__photo {
      display: block;
      border-radius: 20px;
      height: 128px;
      width: 100%;
      background: {
        repeat: no-repeat;
        position: center;
        size: cover;
        color: #ff5a5f;
      }

      @include r(1700) {
        height: 114px;
      }
      @include r(1500) {
        height: 101px;
        border-radius: 15px;
      }
      @include r(1400) {
        height: 93px;
      }

      @include r(900) {
        border-radius: 10px;
      }

      @include r(500) {
        height: 98px;
      }
    }

    &__views {
      display: block;
      text-align: center;
      color: #a3a3a3;
      font-size: 17px;
      font-weight: 400;
      margin-top: 2px;

      @include r(1500) {
        font-size: 15px;
      }
    }

    &__rating {
      display: block;
      margin-top: 10px;

      @include r(900) {
        display: none;
      }

      .vue-star-rating {
        justify-content: center;

        svg {
          @include r(1400) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__name {
      display: block;
      text-align: center;
      color: #383838;
      font-size: 37px;
      font-weight: 600;

      @include r(1700) {
        font-size: 30px;
      }
      @include r(1500) {
        font-size: 26px;
      }
      @include r(1300) {
        font-size: 23px;
      }
      @include r(900) {
        display: none;
      }
    }

    &__tabs {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__buttons {
      display: flex;
      align-items: stretch;
      flex: 0 0 auto;

      @include r(900) {
        display: none;
      }
    }

    &__button {
      border-right: 1px solid #f8575c;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: none;
      cursor: pointer;
      transition: .3s;

      &:hover {
        opacity: .7;
        transition: .3s;
      }

      &:first-child {
        flex: 0 0 19%;
        justify-content: flex-start;
      }
      &:nth-child(2) {
        flex: 0 0 43%;
      }
      &:nth-child(3) {
        flex: 0 0 38%;
        justify-content: center;
        span {
          text-align: center;
        }
      }

      span {
        color: #535353;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;

        @include r(1700) {
          font-size: 17px;
        }

        @include r(1500) {
          font-size: 15px;
        }
      }

      &:last-child {
        border-right: none;
      }

      &.is-show {
        span {
          font-weight: 600;
        }
      }
    }

    &__tab {
      display: none;

      &.is-show {
        display: block;
      }
    }

    &__contents {
      display: block;
      flex: 1 1 auto;
      margin-top: 20px;
    }

    &__bio {

      @include r(900) {
        margin-top: -10px;
      }
      @include r(500) {
        padding-bottom: 20px;
        border-bottom: 1px solid #000;
      }
      &-top {
        display: flex;
        align-items: center;
        justify-content: center;

        @include r(900) {
          display: none;
        }
      }

      &-map {
        display: block;
        margin-top: 14px;
        width: 100%;
        height: 150px !important;
        border-radius: 10px;

        @include r(1500) {
          height: 120px !important;
        }
      }

      &-items {
        display: block;
        padding-right: 30px;
      }

      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .v-icon {
          @include r(1500) {
            font-size: 22px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        span {
          color: #707070;
          font-size: 18px;
          font-weight: 400;
          padding-left: 12px;
          line-height: 27px;

          @include r(1700) {
            font-size: 16px;
          }

          @include r(1500) {
            font-size: 15px;
          }
        }
      }
      &-experience {
        display: block;
        padding-left: 30px;
        text-align: center;

        .icon-experience {
          margin: 0 auto;
        }

        span {
          display: block;
          margin-top: 8px;
          color: #707070;
          font-size: 18px;
          font-weight: 400;
          line-height: 18px;

          @include r(1700) {
            font-size: 16px;
          }

          @include r(1500) {
            font-size: 15px;
          }
        }
      }

      &-description {
        display: block;
        margin-top: 30px;

        @include r(1500) {
          margin-top: 15px;
        }
        @include r(900) {
          margin-top: 12px;
        }
        p {
          display: block;
          color: #535353;
          font-size: 15px;
          font-weight: 400;
          margin: 0;
        }
      }
    }

    &__reviews {

      &-types {
        display: block;
        padding: 20px 0;
        border-bottom: 1px solid #f0f0f0;
      }

      &-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 17px;
        @include r(1700) {
          margin-bottom: 14px;
        }
        @include r(1500) {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        strong {
          color: #272727;
          font-size: 18px;
          font-weight: 700;
          @include r(1700) {
            font-size: 16px;
          }

          @include r(1500) {
            font-size: 15px;
          }
        }

        span {
          color: #272727;
          font-size: 18px;
          font-weight: 400;
          @include r(1700) {
            font-size: 16px;
          }

          @include r(1500) {
            font-size: 15px;
          }
        }

        &-lside {
          flex: 1 1 auto;
          padding-right: 12px;
        }
        &-rside {
          display: flex;
          align-items: center;
          flex: 0 0 auto;
        }
        &-rating {
          margin-left: 8px;
        }
      }

      &-items {
        display: block;
      }

      &-item {
        display: block;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-name {
        display: inline-block;
        color: #6c6c6c;
        font-size: 20px;
        font-weight: 400;
        padding-right: 12px;
      }
      &-rating {
        display: inline-block;

        svg {
          @include r(900) {
            width: 18px;
            height: 18px;
          }
        }
      }
      &-date {
        display: block;
        margin-top: 5px;
        color: #a3a3a3;
        font-size: 15px;
        font-weight: 400;
      }
      &-comment {
        display: block;
        margin-top: 5px;

        p {
          color: #535353;
          font-size: 15px;
          font-weight: 400;

          @include r(900) {
            font-size: 13px;
          }
        }
      }
    }

    &__treatments {
      &-top {
        display: block;
        text-align: center;
        color: #888;
        font-size: 14px;
        font-weight: 400;

        @include r(900) {
          font-size: 13px;
        }
      }
      &-items {
        display: block;
        margin-top: 5px;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 26px 14px 26px 14px;
        border-bottom: 1px solid #d6d6d6;

        @include r(1700) {
          padding: 18px 12px 18px 12px;
        }

        @include r(1500) {
          padding: 16px 10px 16px 10px;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      &-name {
        display: inline-block;
        color: #535353;
        font-size: 18px;
        font-weight: 400;

        @include r(1700) {
          font-size: 16px;
        }

        @include r(1500) {
          font-size: 15px;
        }
      }
      &-price {
        display: inline-block;
        color: #535353;
        font-size: 23px;
        font-weight: 400;
        padding-left: 12px;

        @include r(1700) {
          font-size: 20px;
        }

        @include r(1500) {
          font-size: 18px;
        }
      }
    }
    .line {
      border-bottom: 1px solid #d6d6d6;
      padding-top: 18px;
      width: 95%;
      margin: 0 auto 30px;
    }
    .vue-lb-container {
      .vue-lb-thumbnail {
        padding: 0;
        button {
          display: none;
        }
      }
    }
  }
</style>
