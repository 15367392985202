<template>
  <v-app>
    <Navbar @toggleMobileMenu="toggleMobileMenu">
    </Navbar>

    <v-content>
      <router-view name="logout"></router-view>
      <router-view :isOpenMobileMenu="isOpenMobileMenu"></router-view>
    </v-content>

    <Footer/>

    <div class="mob-menu">
      <div class="mob-menu__container">
        <ul>
          <li>
            <router-link
                to="/services"
                tag="span"
            >
              Services
            </router-link>
          </li>
          <li>
            <router-link
                to="/how-it-works"
                tag="span"
            >How It Works
            </router-link>
          </li>
          <li>
            <router-link
                to="/hygiene"
                tag="span"
            >
              Hygiene
            </router-link>
          </li>
          <li>
            <router-link
                to="/join-freely"
                tag="span"
            >
              Work with us
            </router-link>
          </li>
          <li
              v-if="this.$store.getters.isUserLoggedIn && this.$store.getters.currentUser.user_roles.includes('admin')"
          >
            <router-link
                to="/admin"
                tag="span"
            >Admin Dashboard
            </router-link>
          </li>
          <li
              v-if="this.$store.getters.isUserLoggedIn && this.$store.getters.currentUser.user_roles.includes('practitioner')"
          >
            <router-link
                to="/practitioner/bookings"
                tag="span"
            >Practitioner Account
            </router-link>
          </li>
          <li
              v-if="this.$store.getters.isUserLoggedIn && (this.$store.getters.currentUser.user_roles.includes('practitioner') || this.$store.getters.currentUser.user_roles.includes('customer'))"
          >
            <router-link
                to="/customer/bookings"
                tag="span"
            >Customer Account
            </router-link>
          </li>

          <li>
            <router-link
                v-if="isUserLoggedIn"
                to="/logout"
                tag="span"
            >Logout
            </router-link>
            <router-link
                v-if="!isUserLoggedIn"
                to="/login"
                tag="span"
            >Log In
            </router-link>
          </li>
          <li>
            <router-link
                v-if="!isUserLoggedIn"
                to="/practitioner-registration"
                tag="span"
            >Practitioner Sign Up
            </router-link>
          </li>
          <li>
            <router-link
                to="/about-us"
                tag="span"
            >Contact
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div
        class="cookies-bar"
        :class="{'is-hide': !this.$store.getters.needShowCookieBar}"
    >
      <div class="cookies-bar__description">
        To provide you with the best possible experience of our website, we may use cookies, as described <a
          href="/cookies-policy"
          target="_blank"
      >here</a>. By
        clicking accept, closing the banner, or continuing to browse our website you consent to the use of such cookies.
      </div>
      <div class="cookies-bar__actions">
        <div
            class="cookies-bar__accept"
            @click="clickByCookies"
        >Accept
        </div>
        <div
            class="cookies-bar__close"
            @click="clickByCookies"
        ></div>
      </div>
    </div>

    <template v-if="message.text">
      <v-snackbar
          :multi-line="true"
          :timeout="1500"
          :color="message.color"
          @input="closeMessageDialog"
          :value="true"
          top
      >
        {{ message.text }}
        <v-btn
            dark
            flat
            @click.native="closeMessageDialog"
        > Close
        </v-btn>
      </v-snackbar>
    </template>

    <template v-if="this.$store.getters.modalAuthorization">
      <v-dialog v-model="showLoginWrapper">
        <Login
            @hideLoginModal="hideLoginModal"
            @switchAuthenticationModal="switchAuthenticationModal"
            @showResetPasswordModal="switchLoginResetModal"
            :useInModal="true"
        />
      </v-dialog>
    </template>

    <template v-if="this.$store.getters.modalCustomerReg">
      <v-dialog v-model="showCustomerRegWrapper">
        <CustomerRegistration
            @hideCustomerRegModal="hideCustomerRegModal"
            @switchAuthenticationModal="switchAuthenticationModal"
            :useInModal="true"
        />
      </v-dialog>
    </template>

    <template v-if="showResetWrapper">
      <v-dialog v-model="showResetWrapper">
        <ResetPasswordRequest
            @showLoginModal="switchLoginResetModal"
            @hideResetPasswordModal="hideResetModal"
            :useInModal="true"
        />
      </v-dialog>
    </template>

  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Login from "@/components/Auth/Login";
import CustomerRegistration from "@/components/Auth/CustomerRegistration";
import ResetPasswordRequest from "@/components/Auth/ResetPasswordRequest";

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Login,
    CustomerRegistration,
    ResetPasswordRequest
  },
  data() {
    return {
      showLoginWrapper: true,
      showCustomerRegWrapper: true,
      showResetWrapper: false,
      isOpenMobileMenu: false
    }
  },
  watch: {
    showLoginWrapper() {
      if (this.showLoginWrapper === false) {
        this.hideLoginModal();
        this.showLoginWrapper = true;
      }
    },
    showCustomerRegWrapper() {
      if (this.showCustomerRegWrapper === false) {
        this.hideCustomerRegModal();
        this.showCustomerRegWrapper = true;
      }
    }
  },
  methods: {
    toggleMobileMenu(status) {
      this.isOpenMobileMenu = status;
    },
    closeMessageDialog() {
      this.$store.commit('CLEAR_NOTIFICATION');
    },
    switchLoginResetModal() {
      if (this.$store.getters.modalAuthorization) {
        this.$store.commit('TOGGLE_MODAL_AUTHORIZATION', false);
        this.showResetWrapper = true;
      } else {
        this.$store.commit('TOGGLE_MODAL_AUTHORIZATION', true);
        this.showResetWrapper = false;
      }
    },
    hideResetModal() {
      this.showResetWrapper = false;
      this.$store.commit('SET_TARGET_PAGE', '');
      this.$store.commit('SET_TARGET_PARAMS', {});
    },
    hideLoginModal() {
      this.$store.commit('TOGGLE_MODAL_AUTHORIZATION', false);
      this.$store.commit('SET_TARGET_PAGE', '');
      this.$store.commit('SET_TARGET_PARAMS', {});
    },
    hideCustomerRegModal() {
      this.$store.commit('TOGGLE_MODAL_CUSTOMER_REG', false);
      this.$store.commit('SET_TARGET_PAGE', '');
      this.$store.commit('SET_TARGET_PARAMS', {});
    },
    switchAuthenticationModal() {
      if (this.$store.getters.modalCustomerReg) {
        this.$store.commit('TOGGLE_MODAL_CUSTOMER_REG', false);
        this.$store.commit('TOGGLE_MODAL_AUTHORIZATION', true);
      } else {
        this.$store.commit('TOGGLE_MODAL_CUSTOMER_REG', true);
        this.$store.commit('TOGGLE_MODAL_AUTHORIZATION', false);
      }
    },
    clickByCookies() {
      this.$store.commit('SET_IS_NEED_SHOW_COOKIE', false);
      this.$store.commit('SET_CLICK_COOKIE_BAR');
    }
  },
  computed: {
    message() {
      return this.$store.getters.message;
    },
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    }
  }
}
</script>

<style lang="scss">
* {
  font-family: "Nunito", sans-serif;
}

.headline {
  font-family: "Nunito", sans-serif !important;
}

@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.application--wrap {
  justify-content: space-between;
}

.v-content {
  padding: 128px 0 0 0 !important;
  position: relative;

  @include r(1800) {
    padding-top: 110px !important;
  }

  @include r(1700) {
    padding-top: 96px !important;
  }

  @include r(1500) {
    padding-top: 82px !important;
  }

  @include r(1400) {
    padding-top: 82px !important;
  }

  @include r(1300) {
    padding-top: 80px !important;
  }
  @include r(1200) {
    padding-top: 77px !important;
  }
  @include r(650) {
    padding-top: 62px !important;
  }
}

.v-btn__content {
  text-transform: capitalize;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.8);
}

.v-btn {
  &::before {
    opacity: 0;
  }
}

.v-btn--block {
  height: 56px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 300;
}

.title {
  font-size: 24px !important;

  &--mt {
    margin-top: 30px;
  }

  @include r(700) {
    font-size: 19px !important;
  }
}

.popup-main-container {
  padding: 30px 15px;

  .popup-main-text {
    font-size: 16px;
    color: #000;
    display: block;
    text-align: center;
  }
}

.main-text {
  font-size: 17px;
  display: block;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 30px;

  &--mt0 {
    margin-top: 0;
  }

  &--bold {
    font-weight: 500;
  }

  &--light {
    color: #7b8994;
  }

  @include r(700) {
    font-size: 15px;
    margin-top: 20px;
  }
}

.v-stepper {
  border-radius: 24px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  @include r(959) {
    background-color: transparent !important;
    box-shadow: none;
  }

  .theme--light.v-sheet {
    @include r(959) {
      background-color: transparent !important;
    }
  }

  .theme--light.v-tabs__bar {
    @include r(959) {
      background-color: transparent !important;
    }
  }

  .theme--light.v-list {
    @include r(959) {
      background-color: transparent !important;
    }
  }

  &__content {
    padding: 45px 45px 65px 45px;

    @include r(700) {
      padding: 30px 0 0 0;
    }
  }

  &__header {
    box-shadow: none;
    padding: 30px 30px 0 30px;

    @include r(700) {
      padding: 20px 0 0 0;
    }
  }
}

.step-btn-main {
  margin-top: 50px;

  @include r(700) {
    margin-top: 30px;
  }

  &--mt {
    margin-top: 30px;
  }

  .v-btn.v-btn--flat {
    background-color: #ccc;
    color: #fff;

    &:hover {
      background-color: #b3b1b1;
    }
  }

  .v-btn {
    height: 56px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    width: 230px;

    @include r(700) {
      width: auto;
      padding: 0 20px;
      height: 43px;
      font-size: 12px;
      min-width: 115px;
    }
  }
}

.checkbox-block {
  .v-input--checkbox {
    .v-input__slot {
      margin: 0;

      .v-input--selection-controls__input {
        order: 1;
        width: auto !important;
        margin: 0 14px 0 0 !important;
      }

      .v-label {
        order: 2;
        color: #888;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.1;
        margin-top: 1px;

        @include r(1700) {
          font-size: 18px;
        }

        @include r(1500) {
          font-size: 16px;
        }
      }

      .accent--text {
        color: #f5565b !important;
      }

      .theme--light.v-icon {
        pointer-events: none;
        color: #f5565b !important;
        font-size: 28px;

        @include r(1700) {
          font-size: 24px;
        }

        @include r(1500) {
          font-size: 22px;
        }
      }
    }
  }

  .v-input--radio-group__input {
    .v-radio {
      margin: 0;

      .v-input--selection-controls__input {
        order: 1;
        width: auto !important;
        margin: 0 14px 0 0 !important;
      }

      .v-label {
        order: 2;
        color: #888;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.1;
        margin-top: 1px;

        @include r(1700) {
          font-size: 18px;
        }

        @include r(1500) {
          font-size: 16px;
        }
      }

      .accent--text {
        color: #f5565b !important;
      }

      .theme--light.v-icon {
        pointer-events: none;
        color: #f5565b !important;
        font-size: 28px;

        @include r(1700) {
          font-size: 26px;
        }

        @include r(1500) {
          font-size: 24px;
        }
      }
    }
  }
}

.v-input {
  &__slot {
    display: flex;
    align-items: center;

    .v-input--selection-controls__input {
      order: 2;
      margin: 0 0 0 20px;
    }

    .v-label {
      order: 1;
      font-size: 24px;
      font-weight: 500;
      min-width: 76px;
    }
  }

  &--switch {
    &__track {
      background-color: #ff5252;
      border-radius: 8px;
      height: 30px;
      width: 80px;
      opacity: 1;
      top: 0;
      right: 0;
      left: 0;

      @include r(700) {
        width: 68px;
        height: 25px;
      }

      &.success--text {
        background-color: #4caf50;
      }
    }

    &__thumb {
      top: 1px;
      left: 1px;
      height: 28px;
      width: 38px;
      border-radius: 7px;
      background-color: #fff !important;

      @include r(700) {
        width: 32px;
        height: 23px;
      }
    }
  }

  &--selection-controls {
    &__input {
      width: 80px !important;
      height: 30px;

      @include r(700) {
        width: 68px !important;
        height: 25px;
      }
    }

    &__ripple {
      display: none;
    }
  }
}

.v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple,
.v-input--switch.v-input--is-dirty .v-input--switch__thumb {
  transform: translateX(40px);
  @include r(700) {
    transform: translateX(34px);
  }
}

.v-stepper--alt-labels .v-stepper__step {
  @include r(700) {
    padding: 10px;
    flex: 0 0 auto;
  }
  @include r(520) {
    padding: 2px;
  }
}

.v-stepper__step__step {
  width: 50px;
  height: 50px;
  font-size: 17px;

  @include r(520) {
    width: 36px;
    height: 36px;
    font-size: 15px;
    margin-bottom: 0 !important;
  }
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 50px -63px 0;
  @include r(700) {
    display: none;
  }
}

.theme--light.v-stepper
.v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
.v-stepper__step__step {
  background-color: #ff5252 !important;
  opacity: 0.5;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.filter-time {
  padding: 0 22px;

  &__value {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #888;
      font-size: 15px;
      font-weight: 400;

      &:first-child {
        padding-right: 25px;
        position: relative;

        &::after {
          width: 18px;
          height: 1px;
          background-color: #f3555a;
          top: 50%;
          left: calc(100% + -7px);
          content: "";
          position: absolute;
        }
      }

      &:last-child {
        padding-left: 25px;
      }
    }
  }

  .v-messages {
    display: none;
  }

  .v-slider__thumb-container {
    color: #f3555a !important;
    caret-color: #f3555a !important;
    height: 9px;
    transform: translateY(-50%);

    &::before {
      display: none;
    }
  }

  .v-slider__track__container {
    background-color: #f3555a !important;
    border-color: #f3555a !important;
    border-radius: 5px;
    height: 9px;
  }

  .v-slider__track-fill {
    background-color: #f3555a !important;
    border-color: #f3555a !important;
    height: 9px;
    top: 1px;
  }

  .v-slider__track {
    background-color: #faa5a5 !important;
    border-color: #faa5a5 !important;
    height: 9px;
  }

  .v-slider__thumb {
    width: 25px;
    height: 25px;
    border: 2px solid #f3555a !important;
    background-color: #ea8487 !important;
    cursor: pointer;
    transform: scale(1) translateY(-50%);

    &::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background: {
        image: url("./images/icons/icon-lines-for-range-slider.svg");
        repeat: no-repeat;
        size: contain;
      }
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.icon-experience {
  width: 29px;
  height: 36px;
  background: {
    image: url("./images/icons/icon-experience.svg");
    repeat: no-repeat;
    size: contain;
  }

  @include r(1500) {
    width: 25px;
    height: 32px;
  }
}

.icon-master-card {
  width: 76px;
  height: 62px;
  background: {
    image: url("./images/icons/master-card.svg");
    repeat: no-repeat;
    size: contain;
  }

  @include r(1500) {
    width: 55px;
    height: 42px;
  }

  @include r(1180) {
    width: 44px;
    height: 33px;
  }
}

.icon-visa {
  width: 86px;
  height: 26px;
  background: {
    image: url("./images/icons/visa.svg");
    repeat: no-repeat;
    size: contain;
  }

  @include r(1500) {
    width: 60px;
    height: 19px;
  }

  @include r(1180) {
    width: 49px;
    height: 18px;
  }
}

.icon-facebook {
  width: 39px;
  height: 39px;
  background: {
    image: url("./images/icons/icon-facebook.svg");
    repeat: no-repeat;
    size: contain;
  }
  cursor: pointer;

  &--black {
    background-image: url("./images/icons/icon-facebook-black.svg");
  }

  @include r(1500) {
    width: 34px;
    height: 34px;
  }

  @include r(1180) {
    width: 26px;
    height: 26px;
  }
}

.icon-instagram {
  width: 39px;
  height: 38px;
  background: {
    image: url("./images/icons/icon-inst.svg");
    repeat: no-repeat;
    size: contain;
  }
  cursor: pointer;

  &--black {
    background-image: url("./images/icons/icon-inst-black.svg");
  }

  @include r(1500) {
    width: 34px;
    height: 33px;
  }

  @include r(1180) {
    width: 26px;
    height: 26px;
  }
}

.icon-twitter {
  width: 44px;
  height: 37px;
  background: {
    image: url("./images/icons/icon-twitter.svg");
    repeat: no-repeat;
    size: contain;
  }
  cursor: pointer;

  &--black {
    background-image: url("./images/icons/icon-twitter-black.svg");
  }

  @include r(1500) {
    width: 34px;
    height: 29px;
  }

  @include r(1180) {
    width: 27px;
    height: 23px;
  }
}

.icon-phone {
  width: 41px;
  height: 41px;
  background: {
    image: url("./images/icons/icon-phone.svg");
    repeat: no-repeat;
    size: contain;
  }

  @include r(1500) {
    width: 34px;
    height: 34px;
  }

  @include r(1300) {
    width: 30px;
    height: 30px;
  }
}

.theme--light.v-picker__body {
  background: #fafafa;
}

.v-date-picker-table {
  height: auto;
}

.pointer {
  cursor: pointer;
}

.radio-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  color: #707070;
  font-family: Nunito, Sans-Serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.1;
  cursor: pointer;

  &--light {
    font-size: 18px;
  }

  @include r(1500) {
    font-size: 18px;
  }

  span {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    border: 1px solid #f25757;
    background-color: transparent;
    display: inline-block;
    margin-right: 16px;
    transition: 0.3s;
    position: relative;
    top: -1px;
  }

  input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  input[type="radio"]:checked ~ span {
    background-color: #f25757;
    transition: 0.3s;
  }

  p {
    margin: 0;
    color: #a3a3a3;
    padding-left: 6px;
  }
}

.red-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  color: #535353;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.1;
  cursor: pointer;

  @include r(1500) {
    font-size: 17px;
  }

  span {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    border: 1px solid #f8575c;
    background-color: transparent;
    display: inline-block;
    margin-right: 16px;
    transition: 0.3s;
    position: relative;
    top: -1px;
  }

  input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  input[type="checkbox"]:checked ~ span {
    background-color: #f8575c;
    transition: 0.3s;
  }

  p {
    margin: 0;
    color: #a3a3a3;
    padding-left: 6px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9;
}

.v-dialog {
  box-shadow: none !important;
  margin: 0;
  width: auto !important;
  z-index: initial;
}

.solo-label {
  display: block;
  color: #535353;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 14px;

  @include r(1700) {
    font-size: 19px;
  }

  @include r(1500) {
    font-size: 17px;
  }

  @include r(1100) {
    font-size: 16px;
  }

  @include r(700) {
    font-size: 15px;
  }
}

.solo-field-white {
  .v-input__slot {
    width: 100%;
    height: 55px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16) !important;
    border-radius: 4px;
    background-color: #fff;
    color: #707070;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;

    @include r(1700) {
      height: 50px;
      font-size: 18px;
    }

    @include r(1500) {
      height: 46px;
      font-size: 17px;
    }
    @include r(550) {
      font-size: 15px;
    }
  }
}

.solo-field-white-radius {
  .v-input__slot {
    width: 100%;
    height: 55px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16) !important;
    border-radius: 0;
    background-color: #fff;
    color: #707070;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;

    @include r(1700) {
      height: 50px;
      font-size: 18px;
    }

    @include r(1500) {
      height: 46px;
      font-size: 17px;
    }
    @include r(550) {
      font-size: 15px;
    }
  }
}

.solo-field-white-radius-center {
  .v-input__slot {
    width: 100%;
    height: 55px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16) !important;
    border-radius: 0;
    background-color: #fff;
    color: #707070;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;

    @include r(1700) {
      height: 50px;
      font-size: 18px;
    }

    @include r(1500) {
      height: 46px;
      font-size: 17px;
    }
    @include r(550) {
      font-size: 15px;
    }

    input {
      text-align: center;
    }
  }
}

.solo-field-white-center {
  .v-input__slot {
    width: 100%;
    height: 55px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16) !important;
    border-radius: 4px;
    background-color: #fff;
    color: #707070;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;

    @include r(1700) {
      height: 50px;
      font-size: 18px;
    }

    @include r(1500) {
      height: 46px;
      font-size: 17px;
    }
    @include r(550) {
      font-size: 15px;
    }

    input {
      text-align: center;
    }
  }
}

.solo-field-gray {
  .v-input__slot {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background-color: #f7f7f7 !important;
    color: #707070;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;
    box-shadow: none !important;

    @include r(1700) {
      font-size: 18px;
    }

    @include r(1500) {
      font-size: 17px;
    }
    @include r(550) {
      font-size: 15px;
    }
  }

  .v-text-field__details {
    margin-bottom: 0 !important;
  }
}

.solo-field-gray-center {
  .v-input__slot {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background-color: #f7f7f7 !important;
    color: #707070;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;
    box-shadow: none !important;

    @include r(1700) {
      font-size: 18px;
    }

    @include r(1500) {
      font-size: 17px;
    }
    @include r(550) {
      font-size: 15px;
    }

    input {
      text-align: center;
    }
  }
}

.solo-field-border-gray {
  .v-input__slot {
    width: 100%;
    height: 74px;
    border-radius: 10px;
    border: 3px solid #b2b2b2;
    background-color: #fff;
    color: #47434d;
    font-family: Nunito;
    padding: 0 90px;
    font-size: 20px;
    font-weight: 700;
    box-shadow: none !important;

    @include r(1700) {
      height: 50px;
      font-size: 18px;
    }

    @include r(1500) {
      height: 46px;
      font-size: 17px;
    }
    @include r(550) {
      font-size: 15px;
    }
  }

  .v-text-field__details {
    margin-bottom: 0 !important;
  }
}

.solo-textarea-border-gray {
  .v-input__slot {
    width: 100%;
    height: 220px;
    border-radius: 10px;
    border: 3px solid #b2b2b2;
    background-color: #fff;
    color: #47434d;
    font-family: Nunito;
    padding: 18px 90px;
    font-size: 20px;
    font-weight: 700;
    box-shadow: none !important;
    resize: none;

    @include r(1700) {
      font-size: 18px;
    }

    @include r(1500) {
      font-size: 17px;
    }
    @include r(550) {
      font-size: 15px;
    }
  }

  .v-text-field__details {
    margin-bottom: 0 !important;
  }
}

.solo-textarea-white-radius {
  .v-input__slot {
    width: 100%;
    height: 220px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16) !important;
    border-radius: 0;
    background-color: #fff;
    padding: 0 !important;
    margin: 0 !important;

    .v-text-field__slot {
      height: 100%;
      padding: 30px 40px;
      margin: 0 !important;

      @include r(1700) {
        padding: 24px 36px;
      }
      @include r(1700) {
        padding: 22px 30px;
      }
    }

    textarea {
      margin: 0 !important;
      height: 100%;
      line-height: 1.2;
      padding: 0 !important;
      color: #707070;
      font-family: Nunito;
      font-size: 20px !important;
      font-weight: 400;

      @include r(1700) {
        font-size: 18px !important;
      }

      @include r(1500) {
        font-size: 17px !important;
      }
      @include r(550) {
        font-size: 15px !important;
      }
    }
  }
}

.v-menu__content {
  box-shadow: none !important;
  border-radius: 4px;
}

.btn-light {
  background-color: #fff !important;
}

.v-btn {
  box-shadow: none !important;
}

// tooltip
.tooltip {
  display: block !important;
  z-index: 8;

  .tooltip-inner {
    background: #ff5353;
    color: white;
    border-radius: 4px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #ff5353;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #ff5353;

    .popover-inner {
      background: $color;
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.cookies-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;

  &.is-hide {
    transform: translateY(100%);
    opacity: 0.5;
    transition: 0.3s;
  }

  @include r(900) {
    padding: 12px 25px;
  }
  @include r(700) {
    padding: 12px 20px;
  }

  &__description {
    display: inline-block;
    flex: 1 1 auto;
    color: #000;
    font-size: 15px;
    text-align: left;

    @include r(700) {
      font-size: 13px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    padding-left: 38px;
    flex: 0 0 auto;

    @include r(900) {
      padding-left: 25px;
    }
    @include r(700) {
      padding-left: 20px;
    }

    @include r(500) {
      display: block;
      text-align: center;
      padding-left: 16px;
    }
  }

  &__accept {
    display: inline-block;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    border-bottom: 1px solid #000;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      border-bottom-color: transparent;
      transition: 0.3s;
    }
  }

  &__close {
    display: block;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border: {
      width: 1px;
      style: solid;
      color: #000;
      radius: 50%;
    }
    background: {
      image: url(".././src/images/icons/icon-delete.svg");
      repeat: no-repeat;
      size: 12px;
      position: center;
    }
    margin-left: 20px;
    transition: 0.3s;

    @include r(700) {
      margin-left: 15px;
    }
    @include r(500) {
      margin: 18px auto 0 auto;
    }

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }
  }
}

.v-swith-main {
  display: flex;
  align-items: center;

  &__label {
    display: inline-block;
    color: #383838;
    font-family: Nunito;
    font-size: 27px;
    font-weight: 700;
    margin-right: 24px;

    @include r(1500) {
      font-size: 22px;
      margin-right: 22px;
    }

    @include r(700) {
      font-size: 15px;
      margin-right: 20px;
    }
  }

  .vue-js-switch {
    .v-switch-core {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 10px !important;

      @include r(1500) {
        width: 78px !important;
        height: 28px !important;
        border-radius: 8px !important;
      }
      @include r(700) {
        width: 68px !important;
        height: 26px !important;
        border-radius: 6px !important;
      }
    }

    .v-switch-button {
      width: 47px !important;
      height: 30px !important;
      top: -2px !important;
      left: -2px !important;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 9px !important;
      background-image: linear-gradient(
              270deg,
              #fff 0%,
              #fdfdfd 100%
      ) !important;
      @include r(1500) {
        width: 42px !important;
        height: 26px !important;
        border-radius: 7px !important;
      }
      @include r(700) {
        width: 34px !important;
        height: 24px !important;
        border-radius: 5px !important;
      }
    }

    .v-switch-label {
      color: #fff;
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
      @include r(1500) {
        font-size: 15px;
        line-height: 28px !important;
      }
      @include r(700) {
        font-size: 14px;
        line-height: 26px !important;
      }

      &.v-right {
        right: 9px;
        @include r(1500) {
          right: 7px !important;
        }
      }

      &.v-left {
        left: 9px;
        @include r(1500) {
          left: 7px !important;
        }
        @include r(700) {
          left: 6px;
        }
      }
    }

    &.toggled {
      .v-switch-button {
        transform: translate3d(44px, 3px, 0px) !important;
        @include r(1500) {
          transform: translate3d(37px, 3px, 0px) !important;
        }
        @include r(700) {
          transform: translate3d(35px, 3px, 0px) !important;
        }
      }
    }
  }
}

.v-snack--top {
  top: 45%;
}

html {
  &.is-fixed {
    overflow: hidden;
    height: 100%;

    body {
      height: 100%;
      overflow: hidden;
    }
  }
}

.pac-logo:after {
  content: none;
}

.datetimepicker:not(.inline) .datepicker {
  @include r(415) {
    padding-bottom: 40px;
    max-width: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
  }
}

.v-picker__title {
  @include r(600) {
    display: none;
  }
}

.v-picker--landscape .v-picker__body, .v-picker--landscape .v-picker__actions {
  @include r(600) {
    margin-left: 0;
  }
}
</style>