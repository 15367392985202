<template>
  <v-footer
    class="footer"
    height="auto"
  >
    <div class="footer__logo" @click="onHome()">
      <img src="../.././src/images/icons/freelylogo-white.svg" alt="">
    </div>
    <div class="footer__top">
      <div class="footer__payment">
        <div class="footer__title">
          {{$t('footer.secure_payment')}}
        </div>
        <ul>
          <li>
            <div class="icon-master-card"></div>
          </li>
          <li>
            <div class="icon-visa"></div>
          </li>
        </ul>
      </div>
      <div class="footer__social">
        <div class="footer__title">
          {{$t('footer.social')}}
        </div>
        <ul>
          <li>
            <a href="https://www.facebook.com/freelywellness/" target="_blank"><div class="icon-facebook"></div></a>
          </li>
          <li>
            <a href="https://www.instagram.com/freelywellness/" target="_blank"><div class="icon-instagram"></div></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer__middle">
      <div
        class="footer__inner footer__inner--logo"
        @click="onHome()"
      >
        <img src="../.././src/images/icons/freelylogo-gray.svg" alt="">
      </div>
      <div class="footer__inner">
        <div class="footer__title">
          {{$t('footer.about_freely')}}
        </div>
        <ul>
          <li>
            <div
              class="footer__link"
              @click="onAboutUs()"
            >
              {{$t('footer.our_story')}}
            </div>
          </li>
          <li>
            <div
              class="footer__link"
              @click="onHowItWorks()"
            >
              {{$t('footer.how_it_works')}}
            </div>
          </li>
          <li>
            <router-link
              to="/services-search"
              tag="span"
              class="footer__link"
            >
              {{$t('footer.services')}}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="footer__inner">
        <div class="footer__title">
          {{$t('footer.practitioners')}}
        </div>
        <ul>
          <li>
            <router-link
              to="/practitioner-registration"
              tag="span"
              class="footer__link"
            >
              {{$t('footer.practitioner_sign_up')}}
            </router-link>
          </li>
          <li>
            <router-link
                    :to="{name: 'login', params: {role: 'practitioner'}}"
                    tag="span"
                    class="footer__link"
            >
              {{$t('footer.practitioner_sign_in')}}
            </router-link>
          </li>
          <li>
            <div
              class="footer__link"
              @click="onPractitioner()"
            >
              {{$t('footer.my_account')}}
            </div>
          </li>
        </ul>
      </div>
      <div class="footer__inner">
        <div class="footer__title">
          {{$t('footer.customers')}}
        </div>
        <ul>
          <li>
            <router-link
              to="/customer-registration"
              tag="span"
              class="footer__link"
            >
              {{$t('footer.customer_sign_up')}}
            </router-link>
          </li>
          <li>
            <router-link
                    :to="{name: 'login', params: {role: 'customer'}}"
                    tag="span"
                    class="footer__link"
            >
              {{$t('footer.customer_sign_in')}}
            </router-link>
          </li>
          <li>
            <div
              class="footer__link"
              @click="onCustomer()"
            >
              {{$t('footer.my_account')}}
            </div>
          </li>
        </ul>
      </div>
      <!--<div class="footer__inner">
        <div class="footer__title">
          {{$t('footer.top_cities')}}
        </div>
        <ul>
          <li>
            <a
              href="#"
              class="footer__link"
            >
              {{$t('footer.london')}}
            </a>
          </li>
          <li>
            <a
              href="#"
              class="footer__link"
            >
              {{$t('footer.manchester')}}
            </a>
          </li>
          <li>
            <a
              href="#"
              class="footer__link"
            >
              {{$t('footer.birmingham')}}
            </a>
          </li>
        </ul>
      </div>-->
      <div class="footer__inner">
        <div class="footer__title">
          {{$t('footer.support')}}
        </div>
        <ul>
          <li>
            <a
              href="mailto:hello@freelywellness.com"
              class="footer__link"
            >hello@freelywellness.com</a>
          </li>
          <li>
            <a
              href="#"
              class="footer__link"
            >
              {{$t('footer.live_chat')}}
            </a>
          </li>
          <li>
            <div
              class="footer__link"
              @click="onSupport()"
            >
              {{$t('footer.faqs')}}
            </div>
          </li>
        </ul>
      </div>
      <div class="footer__inner footer__inner--mob">
        <div
          class="footer__title pointer"
          @click="onTermsOfServices()"
        >
          {{$t('footer.terms_conditions')}}
        </div>
        <ul>
          <li>
            <div
              class="footer__link"
              @click="onPrivacyPolicy()"
            >
              {{$t('footer.privacy_policy')}}
            </div>
          </li>
          <li>
            <div
              class="footer__link"
              @click="onCookiesPolicy()"
            >
              {{$t('footer.cookies_policy')}}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer__bottom">
      <ul>
        <li><span class="footer__text">
          {{$t('footer.2020_freely')}}
        </span></li>
        <li class="footer__bottom--mob">
          <div
            class="footer__link"
            @click="onTermsOfServices()"
          >
            {{$t('footer.terms_conditions')}}
          </div>
        </li>
        <li class="footer__bottom--mob">
          <div
            class="footer__link"
            @click="onPrivacyPolicy()"
          >
            {{$t('footer.privacy_policy')}}
          </div>
        </li>
        <li class="footer__bottom--mob">
          <div
            class="footer__link"
            @click="onCookiesPolicy()"
          >
            {{$t('footer.cookies_policy')}}
          </div>
        </li>
      </ul>
    </div>
  </v-footer>
</template>

<script>
export default {
  methods: {
    onHome () {
      this.$router.push({ name: 'home' });
      this.$scrollTo('.header.v-toolbar');
    },
    onAboutUs () {
      this.$router.push({ name: 'about-us' });
      this.$scrollTo('.header.v-toolbar');
    },
    onSupport () {
      this.$router.push({ name: 'support' });
      this.$scrollTo('.header.v-toolbar');
    },

    onTermsOfServices () {
      const routeData = this.$router.resolve({ name: 'terms-of-service' });
      window.open(routeData.href, '_blank');
    },
    onPrivacyPolicy () {
      const routeData = this.$router.resolve({ name: 'privacy-policy' });
      window.open(routeData.href, '_blank');
    },
    onCookiesPolicy () {
      const routeData = this.$router.resolve({ name: 'cookies-policy' });
      window.open(routeData.href, '_blank');
    },

    onPractitioner () {
      this.$router.push("/practitioner/bookings");
    },
    onCustomer () {
      this.$router.push({ name: 'customer-dashboard' });
    },
    onHowItWorks () {
      const currentUserRoles = this.$store.getters.currentUser.user_roles;
      // customer
      if (currentUserRoles.length == 1) {
        this.$router.push({ name: 'how-it-works-customer' });
      }
      // practitioner
      if (currentUserRoles.length == 2) {
        this.$router.push({ name: 'how-it-works-practitioner' });
      }
      // guest
      if (currentUserRoles.length == 0) {
        this.$router.push({ name: 'how-it-works' });
      }
      this.$scrollTo('.header.v-toolbar');
    },
  }
};
</script>

<style scoped lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.footer {
  background-color: #272727 !important;
  padding: 40px 80px;
  flex-direction: column;
  position: relative;
  z-index: 2;

  @include r(1400) {
    padding: 40px;
  }

  @include r(1300) {
    padding: 40px 30px;
  }

  @include r(1000) {
    padding: 30px 40px 80px 40px;
  }
  @include r(560) {
    padding: 30px 26px 80px 26px;
  }

  &__title {
    color: #fff;
    font-size: 20px;
    font-weight: 700;

    @include r(1500) {
      font-size: 18px;
    }

    @include r(1300) {
      font-size: 17px;
    }

    @include r(1180) {
      font-size: 14px;
    }
  }

  &__logo {
    display: none;
    transition: .3s;

    &:hover {
      cursor: pointer;
      opacity: .8;
      transition: .3s;
    }


    img {
      width: 100%;
      max-width: 72px;
    }
    @include r(1000) {
      display: block;
    }
  }

  &__top {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    width: 100%;

    @include r(1000) {
      margin-top: 20px;
      justify-content: center;
    }
    @include r(630) {
      display: block;
    }
  }

  &__payment {
    display: flex;
    align-items: center;

    @include r(1000) {
      order: 2;
    }

    @include r(630) {
      justify-content: center;
    }

    @include r(560) {
      display: block;
      text-align: center;
    }

    ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin-left: 6px;

      @include r(560) {
        justify-content: center;
        margin: 0;
      }

      li {
        display: block;
        margin-left: 34px;

        @include r(1500) {
          margin-left: 20px;
        }
        @include r(560) {
          margin: 10px;
        }
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin-left: 120px;

    @include r(1500) {
      margin-left: 80px;
    }
    @include r(1000) {
      order: 1;
      margin: 0 50px 0 0;
    }

    @include r(630) {
      margin: 20px 0 0 0;
      justify-content: center;
    }

    @include r(560) {
      display: block;
      text-align: center;
      margin-top: 10px;
    }
    ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin-left: 6px;

      @include r(560) {
        justify-content: center;
        margin: 0;
      }

      li {
        display: block;
        margin-left: 34px;
        transition: 0.3s;
        &:hover {
          opacity: 0.7;
          transition: 0.3s;
        }

        @include r(1500) {
          margin-left: 20px;
        }
        @include r(560) {
          margin: 10px;
        }
      }
    }
  }

  &__middle {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    width: 100%;
    padding: 0 120px 0 0;

    @include r(1180) {
      margin-top: 10px;
    }

    @include r(1700) {
      padding: 0 80px 0 0;
    }
    @include r(1500) {
      padding: 0;
    }
    @include r(1000) {
      margin: 10px -16px 0 -16px;
    }
    @include r(900) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @include r(560) {
      margin: 10px -10px 0 -10px;
    }
  }

  &__link {
    display: inline-block;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.1;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;

    @include r(1500) {
      font-size: 18px;
    }

    @include r(1300) {
      font-size: 17px;
    }

    @include r(1180) {
      font-size: 14px;
    }

    &:hover {
      border-bottom-color: #fff;
      transition: 0.3s;
    }
  }
  &__text {
    display: inline-block;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    line-height: 21px;
    border-bottom: 1px solid transparent;
    text-decoration: none;

    @include r(1500) {
      font-size: 18px;
    }

    @include r(1180) {
      font-size: 14px;
    }
  }

  &__inner {
    flex: 0 0 auto;
    padding: 20px;
    @include r(1000) {
      padding: 16px;
    }
    @include r(900) {
      flex: 0 0 33.3333333%;
    }

    @include r(560) {
      flex: 0 0 50%;
      padding: 10px;
    }

    &--mob {
      display: none;

      @include r(1000) {
        display: block;
      }
    }

    &--logo {
      padding-top: 60px;
      font-size: 0;

      img {
        width: 100%;
        max-width: 128px;
        transition: .3s;

        &:hover {
          cursor: pointer;
          opacity: .8;
          transition: .3s;
        }


        @include r(1700) {
          max-width: 108px;
        }

        @include r(1300) {
          max-width: 94px;
        }

      }


      @include r(1000) {
        display: none;
      }

    }

    ul {
      padding: 0;
      li {
        display: block;
        margin-top: 18px;

        @include r(1180) {
          margin-top: 16px;
        }

        @include r(1000) {
          margin-top: 12px;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 148px;
    margin-top: 50px;

    @include r(1180) {
      margin-top: 10px;
    }

    @include r(1700) {
      padding: 0 106px;
    }

    @include r(1500) {
      padding: 0 27px;
    }
    @include r(1000) {
      padding: 0;
      justify-content: center;
    }

    ul {
      padding: 0;
      li {
        display: inline-block;
        margin-left: 40px;

        @include r(1000) {
          margin-left: 0;
        }

        &.footer__bottom--mob {
          @include r(1000) {
            display: none;
          }
        }
      }
    }
  }
}
</style>
