<template>
    <div class="dashboard-content__body">
      <div class="dashboard-reviews__title">{{title}}</div>
      <!--<div class="dashboard-reviews__title">Gel Nails Manicure, Mobile, 21/02/19</div>-->

      <div class="dashboard-reviews__items">
        <v-form
                v-model="isValidForm"
                ref="form"
                lazy-validation
        >
          <div class="dashboard-reviews__item">
            <div class="dashboard-reviews__item-title">
              {{$t('customer_profile.reviews_component_timekeeping')}}
            </div>
            <div class="dashboard-reviews__item-description">
              {{$t('customer_profile.reviews_component_timekeeping_desc')}}
            </div>

            <div class="dashboard-reviews__item-rating">
              <v-rating
                      v-model="timekeeping"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$vuetify.icons.ratingFull"
                      half-increments
                      hover
              ></v-rating>

              <div class="checkout__form-field checkout__form-field--payment-hide">
                <v-text-field
                        v-model="timekeeping"
                        :rules="timekeepingRules"
                        :error-messages="timekeepingErrorMess"
                        type="hidden"
                        solo
                ></v-text-field>
              </div>
            </div>

          </div>
          <div class="dashboard-reviews__item">
            <div class="dashboard-reviews__item-title">
              {{$t('customer_profile.reviews_component_friendliness')}}
            </div>
            <div class="dashboard-reviews__item-description">
              {{$t('customer_profile.reviews_component_friendliness_desc')}}
            </div>
            <div class="dashboard-reviews__item-rating">

              <v-rating
                      v-model="friendliness"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$vuetify.icons.ratingFull"
                      half-increments
                      hover
              ></v-rating>

              <div class="checkout__form-field checkout__form-field--payment-hide">
                <v-text-field
                        v-model="friendliness"
                        :rules="friendlinessRules"
                        :error-messages="friendlinessErrorMess"
                        type="hidden"
                        solo
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="dashboard-reviews__item">
            <div class="dashboard-reviews__item-title">
              {{$t('customer_profile.reviews_component_communication')}}
            </div>
            <div class="dashboard-reviews__item-description">
              {{$t('customer_profile.reviews_component_communication_desc')}}
            </div>

            <div class="dashboard-reviews__item-rating">

              <v-rating
                      v-model="communication"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$vuetify.icons.ratingFull"
                      half-increments
                      hover
              ></v-rating>

              <div class="checkout__form-field checkout__form-field--payment-hide">
                <v-text-field
                        v-model="communication"
                        :rules="communicationRules"
                        :error-messages="communicationErrorMess"
                        type="hidden"
                        solo
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="dashboard-reviews__item">
            <div class="dashboard-reviews__item-title">
              {{$t('customer_profile.reviews_component_satisfaction')}}
            </div>
            <div class="dashboard-reviews__item-description">
              {{$t('customer_profile.reviews_component_satisfaction_desc')}}
            </div>
            <div class="dashboard-reviews__item-rating">
              <v-rating
                      v-model="satisfaction"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$vuetify.icons.ratingFull"
                      half-increments
                      hover
              ></v-rating>
              <div class="checkout__form-field checkout__form-field--payment-hide">
                <v-text-field
                        v-model="satisfaction"
                        :rules="satisfactionRules"
                        :error-messages="satisfactionErrorMess"
                        type="hidden"
                        solo
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="dashboard-reviews__item">
            <div class="dashboard-reviews__item-title">
              {{$t('customer_profile.reviews_component_review')}}
            </div>
            <div class="dashboard-reviews__item-description">
              {{$t('customer_profile.reviews_component_review_desc')}}
            </div>
            <div class="dashboard-reviews__item-field">
              <v-textarea
                      v-model="review"
                      :rules="reviewRules"
                      :error-messages="reviewErrorMess"
                      @focus="changedReview"
                      solo
                      class="solo-textarea-white-radius"
              ></v-textarea>
            </div>
            <div class="dashboard-reviews__item-action">
              <v-btn
                      class="error"
                      @click="addReview"
                      :disabled="!isValidForm"
              >
                {{$t('customer_profile.reviews_component_submit_review')}}
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>

    </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty} from '@/helpers.js';

  /* eslint-disable */

  export default {
    name: "reviewsComponent",
    props:[
      'title',
      'timekeepingErrorMess',
      'friendlinessErrorMess',
      'communicationErrorMess',
      'satisfactionErrorMess',
      'reviewErrorMess'
    ],
    data: function () {
      return {
        isValidForm: true,
        timekeeping: null,
        timekeepingRules: [
          v => !empty(v) || this.$i18n.t('customer_profile.reviews_component_js_error_timekeeping_required'),
        ],
        friendliness: null,
        friendlinessRules: [
          v => !empty(v) || this.$i18n.t('customer_profile.reviews_component_js_error_friendliness_required'),
        ],
        communication: null,
        communicationRules: [
          v => !empty(v) || this.$i18n.t('customer_profile.reviews_component_js_error_communication_required'),
        ],
        satisfaction: null,
        satisfactionRules: [
          v => !empty(v) || this.$i18n.t('customer_profile.reviews_component_js_error_satisfaction_required'),
        ],
        review:'',
        reviewRules: [
          v => !empty(v) || this.$i18n.t('customer_profile.reviews_component_js_error_review_required'),
          v => !(v.length > 255) || this.$i18n.t('customer_profile.reviews_component_js_error_review_max_length'),
        ]
      }
    },
    created: function () {
      this.setWatch();
    },
    mounted(){
      this.setWatch();
    },
    methods: {
      setWatch(){
        this.$watch('timekeeping', (value) => this.changedRating(value, 'timekeeping'));
        this.$watch('friendliness', (value) => this.changedRating(value, 'friendliness'));
        this.$watch('communication', (value) => this.changedRating(value, 'communication'));
        this.$watch('satisfaction', (value) => this.changedRating(value, 'satisfaction'));
      },
      changedRating(value, type){
        const data = {
          rating: value,
          type: type
        };
        this.$emit('rating-was-change', data);
      },
      changedReview(event){
        this.$emit('review-was-change', event);
      },
      addReview(){
        if(this.$refs.form.validate()){
          const data = {
            timekeeping :  this.timekeeping,
            friendliness: this.friendliness,
            communication: this.communication,
            satisfaction: this.satisfaction,
            review: this.review
          };
          this.$emit('review-data-is-valid', data)
        }
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .dashboard-reviews {
    &__title {
      display: block;
      line-height: 1.2;
      margin-bottom: 40px;
      color: #383838;
      font-family: Nunito;
      font-size: 27px;
      font-weight: 600;

      @include r(1700) {
        font-size: 23px;
        margin-bottom: 30px;
      }
      @include r(1500) {
        font-size: 21px;
        margin-bottom: 20px;
      }
      @include r(1000) {
        font-size: 19px;
      }

      @include r(750) {
        font-size: 17px;
        text-align: center;
      }
    }

    &__items {
      margin-top: 40px;
      @include r(1700) {
        margin-top: 30px;
      }
      @include r(700) {
        background-color: #fff;
        border-radius: 4px;
        padding: 20px;
        text-align: left;

        .dashboard-profile {
          &__title {
            padding-left: 30px;
          }

          &__edit {
            padding-left: 30px;
          }
        }
      }
      @include r(550) {
        padding: 20px 14px 16px 14px;
      }
    }

    &__item {
      margin-bottom: 28px;

      @include r(1700) {
        margin-bottom: 24px;
      }
      @include r(1500) {
        margin-bottom: 20px;
      }
      @include r(1100) {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        display: block;
        color: #383838;
        font-size: 20px;
        font-weight: 600;

        @include r(1500) {
          font-size: 19px;
        }
        @include r(730) {
          font-size: 18px;
        }
        @include r(550) {
          font-size: 17px;
        }
        @include r(450) {
          font-size: 15px;
        }
      }

      &-description {
        margin-top: 10px;
        color: #b2b2b2;
        font-size: 20px;
        font-weight: 400;

        @include r(1500) {
          font-size: 19px;
        }
        @include r(1000) {
          margin-top: 5px;
        }
        @include r(730) {
          font-size: 18px;
        }
        @include r(550) {
          font-size: 17px;
        }
        @include r(450) {
          font-size: 15px;
        }
      }
      &-rating {
        display: block;
        margin-top: 10px;
        @include r(1000) {
          margin-top: 5px;
        }
      }
      &-field {
        display: block;
        margin-top: 20px;
        max-width: 714px;

        textarea {
          resize: none;
        }

        @include r(700) {
          margin-top: 15px;
        }

        .v-input__slot {
          @include r(700) {
            background-color: #f7f7f7 !important;
            box-shadow: none !important;
            border-radius: 4px !important;
          }
        }
      }
      &-action {
        .v-btn {
          max-width: 203px;
          width: 100%;

          @include r(700) {
            max-width: 160px;
          }
        }
      }
    }

  }
</style>
