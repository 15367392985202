<template>
  <div
          class="dashboard-content dashboard-booking"
          id="dashboard-content"
  >
    <div class="dashboard-content__title">
      <h2>Loyalty schemes</h2>
    </div>

    <div class="dashboard-content__body">
      <div class="dashboard-settings__container">
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            Discount for the first booking, %
          </div>
          <div class="dashboard-settings__inners">
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                <v-form
                        ref="firstBookingDiscount"
                        v-model="isFirstBookingDiscountValid"
                        lazy-validation
                >
                  <v-text-field
                          type="text"
                          v-model="first_booking_discount"
                          :error-messages="first_booking_discountErrorMess"
                          :rules="first_booking_discountRules"
                          mask="##"
                          label="Set discount"
                          @focus="clearErrorsFromServer('first_booking_discount')"
                  ></v-text-field>
                </v-form>
              </div>
            </div>
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                <button
                        class="dashboard-profile__qualifications-add error"
                        @click="saveDiscountForFirstBooking"
                        :disabled="!isFirstBookingDiscountValid"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            Discount for the rebooking, %
          </div>
          <div class="dashboard-settings__inners">
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                <v-form
                        ref="reBookingDiscount"
                        v-model="isRebookDiscountValid"
                        lazy-validation
                >
                  <v-text-field
                          type="text"
                          v-model="rebook_discount"
                          :error-messages="rebook_discountErrorMess"
                          :rules="rebook_discountRules"
                          label="Set discount"
                          mask="##"
                          @focus="clearErrorsFromServer('rebook_discount')"
                  ></v-text-field>
                </v-form>
              </div>
            </div>
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                <button
                        class="dashboard-profile__qualifications-add error"
                        @click="saveDiscountForRebook"
                        :disabled="!isRebookDiscountValid"
                >
                  Save
                </button>

              </div>
            </div>
          </div>
        </div>

        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            Discount for the date interval, %
          </div>

          <div class="dashboard-settings__inners">
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                <label for="">
                  Select the From date
                </label>

                <VueCtkDateTimePicker
                        v-model="startTime"
                        formatted="YYYY-MM-DD HH:mm"
                        format="YYYY-MM-DD HH:mm"
                        label=""
                        color="#f25757"
                        :error="startTimeError"
                        @is-shown="clearTimeRangeError"
                ></VueCtkDateTimePicker>

              </div>
            </div>
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                <label for="">
                  Select the To date
                </label>

                <VueCtkDateTimePicker
                        v-model="endTime"
                        formatted="YYYY-MM-DD HH:mm"
                        format="YYYY-MM-DD HH:mm"
                        label=""
                        color="#f25757"
                        :error="endTimeError"
                        @is-shown="clearTimeRangeError"
                ></VueCtkDateTimePicker>

              </div>
            </div>
          </div>

          <div class="dashboard-settings__inners"
               v-if="
               startEndTimeErrorMess.length > 0 ||
               time_discount_fromErrorMess.length > 0 ||
               time_discount_toErrorMess.length > 0
              "
          >
            <div class="checkout__form-field checkout__form-field--payment-hide">
              <v-text-field
                      v-if="startEndTimeErrorMess.length > 0"
                      type="hidden"
                      :error-messages="startEndTimeErrorMess"
                      solo
              ></v-text-field>
              <v-text-field
                      v-if="time_discount_fromErrorMess.length > 0"
                      type="hidden"
                      :error-messages="time_discount_fromErrorMess"
                      solo
              ></v-text-field>
              <v-text-field
                      v-if="time_discount_toErrorMess.length > 0"
                      type="hidden"
                      :error-messages="time_discount_toErrorMess"
                      solo
              ></v-text-field>
            </div>
          </div>

          <div class="dashboard-settings__inners">
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                <v-form
                        ref="timeDiscount"
                        v-model="isTimeDiscountValid"
                        lazy-validation
                >
                  <v-text-field
                          type="text"
                          v-model="time_discount"
                          :error-messages="time_discountErrorMess"
                          :rules="time_discountRules"
                          label="Set discount"
                          mask="##"
                          @focus="clearErrorsFromServer('time_discount')"
                  ></v-text-field>
                </v-form>
              </div>
            </div>
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                <button
                        class="dashboard-profile__qualifications-add error"
                        @click="saveDiscountForTime"
                        :disabled="!isValidtimeAndDiscount"
                >
                  Save
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty} from '@/helpers.js';
  import moment from 'moment';
  export default {
    name: "admin-dashboard-loyalty-schemes",
    mounted() {
      this.initPage();
    },
    data(){
      return{
        first_booking_discount: null,
        isFirstBookingDiscountValid: true,
        first_booking_discountErrorMess: [],
        first_booking_discountRules: [
          v => !!v || 'Discount field for the first booking  is required',
        ],

        rebook_discount: null,
        isRebookDiscountValid: true,
        rebook_discountErrorMess: [],
        rebook_discountRules:[
          v => !!v || 'Discount field for the rebooking is required',
        ],

        startTime: null,
        startTimeError: false,
        endTime: null,
        endTimeError: false,
        startEndTimeErrorMess: [],
        time_discount_fromErrorMess: [],
        time_discount_toErrorMess: [],

        time_discount: null,
        isTimeDiscountValid: true,
        time_discountErrorMess: [],
        time_discountRules: [
          v => !!v || 'Discount field for the time is required',
        ],
      }
    },
    computed:{
      isValidtimeAndDiscount(){
        if(empty(this.startEndTimeErrorMess) && empty(this.time_discount_fromErrorMess) && empty(this.time_discount_toErrorMess) && this.isTimeDiscountValid){
          return true;
        }else{
          return false;
        }
      }
    },
    methods:{
      saveDiscountForFirstBooking(){
        if (this.$refs.firstBookingDiscount.validate()) {
          const url = `${CONFIG.API_URL}/dashboard/admin/set-first-booking-discount`;
          const data = {first_booking_discount: this.first_booking_discount};
          const message = 'Discount successfully updated';
          this.saveLoyaltyScheme(url, data, message);
        }
      },
      saveDiscountForRebook() {
        if (this.$refs.reBookingDiscount.validate()) {
          const url = `${CONFIG.API_URL}/dashboard/admin/set-rebook-discount`;
          const data = {rebook_discount: this.rebook_discount};
          const message = 'Discount successfully updated';
          this.saveLoyaltyScheme(url, data, message);
        }
      },
      saveDiscountForTime(){
        const isValidDiscount = this.$refs.timeDiscount.validate();
        const isValidTime = this.checkTimeRange();
        if (isValidDiscount && isValidTime) {
          const url = `${CONFIG.API_URL}/dashboard/admin/set-time-discount`;
          const data = {
            time_discount: this.time_discount,
            time_discount_from: this.startTime,
            time_discount_to: this.endTime
          };
          const message = 'Discount successfully updated';
          this.saveLoyaltyScheme(url, data, message);
        }
      },
      clearTimeRangeError(){
        this.startEndTimeErrorMess = [];
        this.time_discount_fromErrorMess = [];
        this.time_discount_toErrorMess = [];
      },
      checkTimeRange(){
        if(empty(this.startTime) || empty(this.endTime) ){
          this.startEndTimeErrorMess.push('From and End fields are required');
          return false;
        }else{
          // const now = moment().subtract(5, 'minutes').format('X');
          const start = moment(this.startTime, "YYYY-MM-DD HH:mm").format('X');
          const end = moment(this.endTime, "YYYY-MM-DD HH:mm").format('X');

          // if (start < now) {
          //   this.startEndTimeErrorMess.push('Start time can not be less than the current time');
          //   return false;
          // } else

          if (start > end) {
            this.startEndTimeErrorMess.push('Start time can not be longer end time');
            return false;
          } else if (start === end) {
            this.startEndTimeErrorMess.push('Start time cannot be equal to end time');
            return false;
          } else {
            return true;
          }
        }
      },
      initPage() {
        this.getLoyaltyScheme()
          .then((response) => {
            if(!empty(response.data)){
              this.first_booking_discount = response.data.first_booking_discount;
              this.rebook_discount = response.data.rebook_discount;
              this.time_discount = response.data.time_discount;
              this.startTime = moment(response.data.time_discount_from, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm') ;
              this.endTime = moment(response.data.time_discount_to, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm') ;
            }

          })
      },
      getLoyaltyScheme() {
        return axios({
          method: "get",
          url: `${CONFIG.API_URL}/dashboard/admin/get-loyalty-schemes`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
      },
      saveLoyaltyScheme(url, data, message) {
        axios({
          method: "post",
          url: url,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          data: JSON.stringify(data),
        })
          .then(() => {
            this.$store.commit('SET_NOTIFICATION', {
              text: message,
              color: 'error'
            });
          })
          .catch((error) => {
            if (!empty(error.response) && !empty(error.response.data.errors)) {
              this.fieldsErrorFromServer(error.response.data.errors);
            } else if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
          })
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      clearErrorsFromServer(field){
        this[`${field}ErrorMess`] = [];
      }
    }
  }
</script>

<style scoped>
  .dashboard-settings__field button{
    float: right;
  }
</style>