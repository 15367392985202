import Vue from 'vue'
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import * as locales from '../lang/lang.json';

let i18n = null;

class InitI18Next {

  constructor() {
    if (!i18n) {
      this.initPackage();
    }

    return i18n;
  }

  initPackage() {
    Vue.use(VueI18Next);
    i18next.init({
      lng: 'en',
      resources: {
        en: {translation: locales.en}
      }
    });

    i18n = new VueI18Next(i18next);
  }

}

export default new InitI18Next();

