<template>
  <div>

    <div class="checkout__form-string">

      <div class="checkout__form-item checkout__form-item--md">
        <div class="checkout__form-field checkout__form-field--payment-hide">
          <label for="" class="checkout__form-label">
            {{$t('payment.card_number')}}
          </label>
          <div ref="refCardNumber" id="refCardNumber"></div>
          <v-text-field
            type="hidden"
            :error-messages="cardNumberError"
            solo
          ></v-text-field>
        </div>
      </div>
    </div>
    <div class="checkout__form-string">
      <div class="checkout__form-item checkout__form-item--xs">
        <div class="checkout__form-field checkout__form-field--payment-hide">
          <label for="" class="checkout__form-label">
            {{$t('payment.expiry_date')}}
          </label>
          <div ref="refExpiryDate" id="refExpiryDate"></div>
          <v-text-field
            type="hidden"
            :error-messages="cardExpiryError"
            solo
          ></v-text-field>
        </div>
      </div>
      <div class="checkout__form-item checkout__form-item--xs">
        <div class="checkout__form-field checkout__form-field--payment-hide">
          <label for="" class="checkout__form-label">
            {{$t('payment.cvv')}}
          </label>

          <div ref='refCardCvv' id="refCardCvv"></div>
          <v-text-field
            type="hidden"
            :error-messages="cardCvcError"
            solo
          ></v-text-field>
        </div>
      </div>
    </div>
    <div class="checkout__form-string">
      <div class="checkout__form-item">
        <div class="checkout__form-field checkout__form-field--payment-hide">
          <v-text-field
            type="hidden"
            :error-messages="stripeOrTokenError"
            solo
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty, getHeightOfWindow} from '@/helpers.js';

  /* eslint-disable */ 

  export default {
    props: ['booking_id', 'booking', 'isErrorsByUsersFields', 'userDetails'],
    name: "payment-form",
    data() {
      return {
        stripe: null,
        elements: null,
        cardNumberElement:  undefined,
        cardExpiryElement: undefined,
        cardCvcElement: undefined,
        cardNumberError: [],
        cardExpiryError: [],
        cardCvcError: [],
        stripeServerToken: null,
        stripeOrTokenError: [],
        elementStyles: {
          base: {
            color: '#32325D',
            fontWeight: 500,
            fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
              color: '#CFD7DF',
            },
            ':-webkit-autofill': {
              color: '#e39f48',
            },
          },
          invalid: {
            color: '#E25950',

            '::placeholder': {
              color: '#FFCCA5',
            },
          },
        },
        elementClasses: {
          focus: 'focused',
          empty: 'empty',
          invalid: 'invalid',
        }
      }
    },
    mounted: function () {
      this.initStripe();
      this.destroyCardElements();
      this.createCardElements();
      this.listenerCardElements();
      this.getTokenFromServer();
    },
    methods: {
      initStripe(){
        this.stripe = window.Stripe(CONFIG.STRIPE_PUBLIC_KEY, {betas: ['payment_intent_beta_3']});
        this.elements = this.stripe.elements();
      },
      destroyCardElements() {
        if (!empty(this.cardNumberElement)) {
          this.cardNumberElement.unmount();
          this.cardNumberElement.destroy();
        }
        if (!empty(this.cardExpiryElement)) {
          this.cardExpiryElement.unmount();
          this.cardExpiryElement.destroy();
        }
        if (!empty(this.cardCvcElement)) {
          this.cardCvcElement.unmount();
          this.cardCvcElement.destroy();
        }
      },
      createCardElements() {
        this.cardNumberElement = this.elements.create('cardNumber', {
          style: this.elementStyles,
          classes: this.elementClasses,
        });
        this.cardNumberElement.mount('#refCardNumber');

        this.cardExpiryElement = this.elements.create('cardExpiry', {
          style: this.elementStyles,
          classes: this.elementClasses,
        });
        this.cardExpiryElement.mount(this.$refs.refExpiryDate);

        this.cardCvcElement = this.elements.create('cardCvc', {
          style: this.elementStyles,
          classes: this.elementClasses,
        });
        this.cardCvcElement.mount(this.$refs.refCardCvv);
      },
      listenerCardElements() {
        this.cardNumberElement.on('change', (ev) => this.setResponse(ev));
        this.cardExpiryElement.on('change', (ev) => this.setResponse(ev));
        this.cardCvcElement.on('change', (ev) => this.setResponse(ev));
      },
      getTokenFromServer() {
        axios({
          method: 'get',
          url: `${CONFIG.API_URL}/booking/${this.booking_id}/paymentIntent`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
          .then((response) => {
            this.stripeServerToken = response.data.client_secret;
          })
          .catch((error) => {
            this.stripeOrTokenError = [];
            this.stripeOrTokenError.push(`Failed to get token for this booking ${error.message}`);
            this.scrollToErrors();
          });
      },
      conversionTrackingAndRedirect() {
        try {
          const that = this;
          if (CONFIG.APP_ENV === 'production') {
            console.log('conversion function called');
            console.log('Amount ' + this.booking.total_amount);
            window.gtag('event', 'conversion', {
              'send_to': 'AW-755360510/KONOCJHtlqEBEP7Fl-gC',
              'value': this.booking.total_amount,
              'currency': 'GBP',
              'transaction_id': this.booking_id,
              'event_callback': function () {
                console.log('event_callback');
                that.$router.push({name: 'payment-success', params: {booking_id: that.booking_id}});
              }
            });
            console.log('without error');
          } else {
            this.$router.push({name: 'payment-success', params: {booking_id: this.booking_id}})
          }
        } catch (error) {
          console.log(error);
          this.$router.push({name: 'payment-success', params: {booking_id: this.booking_id}})
        }
      },
      paymentStripe() {
        const that = this;
        try {
          this.stripe.handleCardPayment(this.stripeServerToken, this.cardNumberElement, {
              source_data: {
                owner: {name: this.userDetails.email}
              }
            }
          ).then(function (result) {
            that.$emit('hide-progress');
            if (result.error && result.error.type !== 'validation_error') {
              that.stripeOrTokenError = [];
              that.stripeOrTokenError.push(result.error.message);
              that.scrollToErrors();
              // console.log(result);
            } else {
              if (!result.error) {
                that.$store.commit('SET_NOTIFICATION', {
                  text: that.$i18n.t('payment.notification_payment_was_successful'),
                  color: 'error'
                });

                that.conversionTrackingAndRedirect();
                // that.$emit('payment-success');
                // that.$router.push({name: 'payment-success', params: {booking_id: that.booking_id}})
              }
              // console.log(result);
            }
          })
        } catch (error) {
          that.$emit('hide-progress');
          console.error(error);
          that.stripeOrTokenError = [];
          that.stripeOrTokenError.push(error.message);
          that.scrollToErrors();
        }
      },
      setResponse(result) {
        this.$emit('hide-progress');
        this.stripeOrTokenError = [];

        if (result.elementType === 'cardNumber') {
          this.cardNumberError = [];
        }
        if (result.elementType === 'cardExpiry') {
          this.cardExpiryError = [];
        }
        if (result.elementType === 'cardCvc') {
          this.cardCvcError = [];
        }

        if (result.error) {
          if (result.elementType === 'cardNumber') {
            this.cardNumberError = [];
            this.cardNumberError.push(result.error.message);
          }
          if (result.elementType === 'cardExpiry') {
            this.cardExpiryError = [];
            this.cardExpiryError.push(result.error.message);
          }
          if (result.elementType === 'cardCvc') {
            this.cardCvcError = [];
            this.cardCvcError.push(result.error.message);
          }
          this.scrollToErrors();
        }
      },
      scrollToErrors: _.debounce(function () {
        const navHeight = getHeightOfWindow();
        this.$scrollTo('.error--text', 800, {offset: Number((navHeight/2) * -1)});
      }, 200)
    }
  }
</script>


<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .checkout {
    display: flex;
    padding: 40px 140px 90px 140px;
    align-items: flex-start;
    background-color: #f7f7f7;

    &__form {
      display: block;
      flex: 1 1 auto;
      margin-right: 86px;
      padding: 34px 70px 60px 70px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.34);
      border-radius: 5px;
      background-image: linear-gradient(270deg, #fff 0%, #fdfdfd 100%);

      &-button {
        max-width: 457px;
        width: 100%;
        display: block;
        margin: 0 auto;
        height: 87px;
        font-size: 23px;
        text-transform: none;
      }

      &-fieldset {
        display: block;
      }

      &-title {
        display: block;
        color: #535353;
        font-family: Nunito;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
      }

      &-fieldset {
        display: block;
        margin-top: 40px;
      }

      &-fields {
        display: flex;
        margin: 0 -10px;
        align-items: flex-start;

        .checkout {
          &__form {
            &-field {
              padding: 0 10px;
              min-height: 45px;
              display: flex;
              align-items: center;
              &--sm {
                flex: 0 0 33.3333333%;
              }

              &--md {
                flex: 0 0 50%;
              }
            }
          }
        }

        &--checkboxes {
          padding-left: 20px;

          .v-input {
            margin-top: 0;
            padding-top: 0;
          }

          .v-radio {
            margin: 0 !important;
          }

          .v-input--selection-controls__input {
            width: auto !important;
            order: 1;
            margin: 0;
          }
          .v-label {
            color: #535353;
            font-family: Nunito;
            font-size: 18px;
            font-weight: 400;
            padding-left: 12px;
          }
        }
      }

      &-field {
        display: block;

        &--payment-hide {
          .v-input__slot {
            display: none;
          }
          .v-input__control {
            min-height: auto !important;
          }
          .v-text-field__details {
            margin-bottom: 0 !important;
          }

          .StripeElement {
            width: 100%;
            height: 45px;
            border-radius: 5px;
            background-color: #f7f7f7 !important;
            color: #707070;
            font-family: Nunito;
            font-size: 20px;
            font-weight: 400;
            box-shadow: none !important;
            padding: 12px;
          }
        }

        &--center {
          text-align: center;

          .red-checkbox {
            justify-content: center;
          }
        }
      }

      &-items {
        display: block;
        margin-top: 12px;
      }

      &-string {
        display: flex;
        margin: 10px -10px 0 -10px;

        &--light {
          margin-top: 0;
        }

        &--center {
          justify-content: center;
        }

        &--mt {
          margin-top: 40px;
        }
      }

      &-label {
        display: block;
        color: #535353;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 14px;
      }
    }
    &__title {
      display: block;
      text-align: center;
      color: #383838;
      font-family: Nunito;
      font-size: 27px;
      font-weight: 700;
      line-height: 1.2;
    }
  }
</style>