<template>
  <v-container>
    <v-layout row>
      <v-flex xs12>
        <h1>How It Works</h1>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "HowItWorks",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | How It Works'
  }
};
</script>

<style scoped>
</style>
