<template>
  <div class="dashboard-content dashboard-reviews" id="dashboard-content">
    <div class="dashboard-content__title">
      <h2>
        {{$t('customer_profile.reviews_page_title')}}
      </h2>
      <p>
        {{$t('customer_profile.reviews_page_desc')}}
      </p>
    </div>

    <ReviewsComponent
            @review-data-is-valid="addReview"
            @rating-was-change="ratingWasChange"
            @review-was-change="reviewWasChange"
            :title="title"
            :timekeepingErrorMess="timekeepingErrorMess"
            :friendlinessErrorMess="friendlinessErrorMess"
            :communicationErrorMess="communicationErrorMess"
            :satisfactionErrorMess="satisfactionErrorMess"
            :reviewErrorMess="reviewErrorMess"
    ></ReviewsComponent>

  </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty, capitalizeFirstLetter} from '@/helpers.js';
  import ReviewsComponent from '@/components/Partial/ReviewsComponent';
  import moment from 'moment';

  /*eslint-disable */

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Customer Reviews'
    },
    props:['booking_id'],
    components:{
      ReviewsComponent
    },
    mounted(){
      this.scrollToHeader();
      this.getBooking();
    },
    data: function () {
      return {
        title:'',
        timekeepingErrorMess:[],
        friendlinessErrorMess:[],
        communicationErrorMess:[],
        satisfactionErrorMess:[],
        reviewErrorMess:[],
      }
    },
    methods: {
      scrollToHeader(){
        this.$scrollTo('.header.v-toolbar');
      },
      getBooking() {
        this.$store.dispatch('GET_BOOKING', this.booking_id)
          .then((data) => {
            this.setTitle(data)
          })
          .catch((error) => {
            if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
          })
      },
      setTitle(data) {
        let treatments = [];
        for (let i = 0; i < data.treatments.length; i++) {
          treatments.push(data.treatments[i].name);
        }
        let treatmentsString = treatments.join(', ');
        this.title = `${data.practitioner_name} ${treatmentsString}, ${capitalizeFirstLetter(data.location_type)}, ${moment(data.date, "YYYY-MM-DD").format("DD/MM/YY")}`;
      },
      postRequest(data) {
        return axios({
          method: 'post',
          url: `${CONFIG.API_URL}/dashboard/user/booking/${this.booking_id}/review`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      addReview(data) {
        this.postRequest(data)
          .then(() => {
            this.$router.push({path: '/customer'});
            this.$store.commit('SET_NOTIFICATION', {
              text: this.$i18n.t('customer_profile.reviews_page_notif_review_successfully_added'),
              color: 'error'
            });
          })
          .catch((error) => {
            if (!empty(error.response) && !empty(error.response.data.errors)) {
              this.fieldsErrorFromServer(error.response.data.errors);
            }else if(!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)){
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
          })
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      ratingWasChange(data){
        this[`${data.type}ErrorMess`] = [];
      },
      reviewWasChange(){
        this.reviewErrorMess = [];
      }
    }
  };
</script>

<style lang="scss">
</style>
