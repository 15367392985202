<template>
  <div>
    <p class="text-xs-center text-sm-center text-md-center main-text main-text--opc main-text--stepper">
      {{$t('invite_reviewers_component.first_paragraph')}}
    </p>
    <p class="text-xs-center text-sm-center text-md-center main-text main-text--opc main-text--stepper">
      {{$t('invite_reviewers_component.second_paragraph')}}
    </p>

    <div class="finished__form">
      <div class="finished__fields" v-for="(invite, index) in invitations" :key="index">
        <v-form
                :ref="`form_${index}`"
                v-model="invite.formValid"
                lazy-validation

        >
          <v-text-field
                  type="email"
                  :rules="emailRules"
                  label="Email address"
                  v-model="invite.email"
                  :disabled="invite.disabled"
                  :error-messages="invite.reviewer_emailErrorMess"
                  @focus="clearErrorMess(index)"
                  color="error"
                  :loading="invite.loading"
                  required
          ></v-text-field>
          <v-btn
                  color="error"
                  :disabled="invite.disabled || !invite.email || !invite.formValid"
                  @click="sendInviteEmail(invite.email, index)"
                  class="finished__invite"
          >invite
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import * as CONFIG from '@/config.js';
  import {empty} from "@/helpers.js";

  export default {
    name: "inviteReviewsComponent",
    data(){
      return {
        emailRules: [v => /.+@.+/.test(v) || this.$i18n.t('invite_reviewers_component.js_error_email_format')],
        invitations: [
          {
            email: null,
            disabled: false,
            formValid: true,
            reviewer_emailErrorMess:[],
            loading: false
          },
          {
            email: null,
            disabled: false,
            formValid: true,
            reviewer_emailErrorMess:[],
            loading: false
          },
          {
            email: null,
            disabled: false,
            formValid: true,
            reviewer_emailErrorMess:[],
            loading: false
          }
        ]
      }
    },
    created () {
      this.getInvitedEmails();
    },
    methods:{
      getInvitedEmails(){
        axios({
          method: "get",
          url: `${CONFIG.API_URL}/practitioner/reviewers`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${this.$store.getters.userToken || CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then((response) => {
            const invitedReviewers = response.data;
            for(let i=0; i<invitedReviewers.length; i++){
              if (i <= 3) {
                this.invitations[i].email = invitedReviewers[i].email;
                this.invitations[i].disabled = true;
              } else {
                this.invitations[i].push({
                  email: invitedReviewers[i].email,
                  disabled: true,
                  formValid: true,
                  reviewer_emailErrorMess: [],
                  loading: false
                });
              }
            }
          })
          .catch((error) => {
            if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            } else {
              console.log(error);
              throw error;
            }
          });
      },
      sendInviteEmail(email, index) {
        const reviewer_email = {
          reviewer_email: email
        };
        this.invitations[index].loading = true;
        axios({
          method: "post",
          url: `${CONFIG.API_URL}/practitioner/reviewers`,
          data: JSON.stringify(reviewer_email),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${this.$store.getters.userToken || CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(() => {
            this.invitations[index].disabled = true;
            this.invitations[index].loading = false;
          })
          .catch((error) => {
            this.invitations[index].loading = false;
            if (!empty(error.response) && !empty(error.response.data.errors)) {
              this.fieldsErrorFromServer(error.response.data.errors, index);
            } else if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            } else {
              console.log(error);
              throw error;
            }
          });
      },
      fieldsErrorFromServer(errors, index){
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this.invitations[index][`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      clearErrorMess(index){
        this.invitations[index].reviewer_emailErrorMess = [];
      }
    }
  }
</script>

<style scoped>

</style>