import {
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION
} from '../mutation-types';

export default {
  state: {
    message: {
      text: null,
      color: null
    }
  },
  mutations: {
    [SET_NOTIFICATION]: (state, payload) => {
      state.message.text = payload.text;
      state.message.color = payload.color;
    },
    [CLEAR_NOTIFICATION]: (state) => {
      state.message.text = null;
      state.message.color = null;
    }
  },
  getters: {
    message(state) {
      return state.message;
    }
  }
}