export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const TOGGLE_NEED_AUTHORIZATION = 'TOGGLE_NEED_AUTHORIZATION';
export const SET_TARGET_PAGE = 'SET_TARGET_PAGE';
export const SET_TARGET_PARAMS = 'SET_TARGET_PARAMS';


export const RESET_AUTH_VARIABLES = 'RESET_AUTH_VARIABLES';
export const REFRESH_AUTH_VARIABLES = 'REFRESH_AUTH_VARIABLES';
export const TOGGLE_MODAL_AUTHORIZATION = 'TOGGLE_MODAL_AUTHORIZATION';

export const UPDATE_SEARCH_PARAM = 'UPDATE_SEARCH_PARAM';
export const SET_PRACTITIONER_DATA = 'SET_PRACTITIONER_DATA';

export const SET_BOOKING_DATA_TO_STORE = 'SET_BOOKING_DATA_TO_STORE';

export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';

export const SET_CURRENT_USER_DATA = 'SET_CURRENT_USER_DATA';

export const SET_CLICK_COOKIE_BAR = 'SET_CLICK_COOKIE_BAR';
export const SET_SHOW_COOKIE_BAR = 'SET_SHOW_COOKIE_BAR';
export const SET_IS_NEED_SHOW_COOKIE = 'SET_IS_NEED_SHOW_COOKIE';

export const TOGGLE_MODAL_CUSTOMER_REG = 'TOGGLE_MODAL_CUSTOMER_REG';

export const SET_SEARCH_AVAILABILITY = 'SET_SEARCH_AVAILABILITY';