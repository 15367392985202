<template>
  <div class="filter__datepicker">
    <div class="filter__datepicker-nav">
      <div class="filter__datepicker-prev" @click="changeWeek(0)"></div>
      <div class="filter__datepicker-year custom-datepicker">
        <v-select
                :items="monthItems"
                v-model="selectedMonth"
                item-text="text"
                item-value="value"
                label="Select date"
                solo
                color="error"
                light
                hide-details
                @change="changedMonth"
        ></v-select>
      </div>
      <div class="filter__datepicker-next" @click="changeWeek(1)"></div>
    </div>
    <div class="filter__datepicker-table">
      <div
              class="filter__datepicker-date"
              v-for="(data, index) in week"
              @click="changeSelectDate(data.format_date)"
              :key="index"
              :class="dateClassObject(data)"
      >
        <div class="filter__datepicker-day">{{data.day}}</div>
        <div class="filter__datepicker-number">{{data.date}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import  moment from 'moment';
  import {getFirstLetter} from "@/helpers.js";
  export default {
    name: "DatePicker",
    data() {
      return {
        now: null,
        dayOfWeek: null,
        startWeekDate: null,
        endWeekDate: null,
        week:[],
        monthItems: [],
        selectedMonth: null,
        selectedDate: null,

        startMonthDate:null,
      }
    },
    created: function () {
      this.initDatePicker();
    },
    methods:{
      dateClassObject(data){
        return {
          'is-start-month': (data.format_date ===  this.startMonthDate || data.format_date === moment().format('YYYY-MM-DD')) && data.format_date !==  this.selectedDate,
          'is-active': data.format_date ===  this.selectedDate,
          'is-disable': data.moment.format('x') < moment().startOf('day').format('x')
        }
      },
      changedMonth(event){
        const dateFromMonth = moment(event, 'YYYYMM').startOf('month');
        this.dayOfWeek = dateFromMonth.clone().day();
        // this.renderWeek(this.dayOfWeek, dateFromMonth);
        this.simpleRenderWeek(this.dayOfWeek, dateFromMonth);
        this.startMonthDate = dateFromMonth.clone().format('YYYY-MM-DD');
      },
      initDatePicker(){
        this.now =  moment();
        this.dayOfWeek = moment().clone().day();
        this.generateMonthItems(this.now);
        // this.renderWeek(this.dayOfWeek, this.now);
        this.simpleRenderWeek(this.dayOfWeek, this.now);
      },
      generateMonthItems(date)
      {
        const now = moment();
        const diffDate = date.diff(now, 'month', true);
        let startDate = now.clone();
        let amountOfItem = 6;
        if(diffDate < 0){
          if(Math.abs(Math.ceil(diffDate)) + 1 > amountOfItem){
            amountOfItem = Math.abs(Math.ceil(diffDate)) + 1;
          }
          startDate =  date.clone();
        }else if(Math.ceil(diffDate) >= amountOfItem ){
          amountOfItem = Math.ceil(diffDate) + 1;
        }

        this.monthItems = [];

        for(let i=0; i<amountOfItem; i++){
          this.monthItems.push({
            text: startDate.clone().format('MMM YYYY'),
            value: startDate.clone().format('YYYYMM')
          });
          startDate.add(1, 'months');
        }

        this.selectedMonth = date.clone().format('YYYYMM');
      },
      changeWeek(bool){
        if (bool) {
          const initDate = this.endWeekDate.clone().add(1, 'days');
          this.dayOfWeek = initDate.clone().day();
          this.generateMonthItems(initDate);
          // this.renderWeek(this.dayOfWeek, initDate);
          this.simpleRenderWeek(this.dayOfWeek, initDate);
        } else {
          const initDate = this.startWeekDate.clone().subtract(7, 'days');
          this.dayOfWeek = initDate.clone().day();
          this.generateMonthItems(initDate);
          // this.renderWeek(this.dayOfWeek, initDate);
          this.simpleRenderWeek(this.dayOfWeek, initDate);
        }
      },
      simpleRenderWeek(dayOfWeek, date){
        this.week = [];
        for (let i = 0; i <= 6; i++) {
          let day = date.clone().add(i, 'days');
          this.setStartEndDateOfWeek(i, day);
          this.week.push({
            day: getFirstLetter(day.format('dd')),
            date: day.format('DD'),
            moment: day,
            format_date: day.format('YYYY-MM-DD'),
          });
        }
      },
      renderWeek(dayOfWeek, date) {
        this.week = [];
        for (let i = 0; i <= 6; i++) {
          if (i <= dayOfWeek) {
            let day = date.clone().add(-Number(dayOfWeek - i), 'days');
            this.setStartEndDateOfWeek(i, day);
            this.week.push({
              day: getFirstLetter(day.format('dd')),
              date: day.format('DD'),
              moment: day,
              format_date: day.format('YYYY-MM-DD'),
            });
          } else {
            let day = date.clone().add(Number(i - dayOfWeek), 'days');
            this.setStartEndDateOfWeek(i, day);
            this.week.push({
              day: getFirstLetter(day.format('dd')),
              date: day.format('DD'),
              moment: day,
              format_date: day.format('YYYY-MM-DD'),
            });
          }
        }
      },
      setStartEndDateOfWeek(i, date){
        if (i === 0) {
          this.startWeekDate = date;
        }
        if (i === 6) {
          this.endWeekDate = date;
        }
      },
      changeSelectDate(format_date){
        this.selectedDate = format_date;
        this.$emit("changedSelectedDate", this.selectedDate);
      }
    }
  }
</script>

<style>
  .custom-datepicker .v-select__selection--comma {
    color: #888;
  }
</style>