<template>
  <div>
    <template slot="activator">
      <slot></slot>
    </template>
    <v-btn fab dark small color="primary" @click="showDialog=true" v-if="canRemove">
      <v-icon dark>remove</v-icon>
    </v-btn>
    <slot name="scheduleActionsAdditional" v-bind="{schedule, calendarEvent, calendar}"></slot>

    <template>
      <v-layout row justify-center>
        <v-dialog v-model="showDialog" persistent max-width="290">
          <v-card>
            <v-card-text>Are you sure you want to remove this event?</v-card-text>
            <v-card-actions>
              <v-btn color="red darken-1" flat @click="remove">Yes</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" flat @click="showDialog=false">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>

  </div>
</template>

<script>
  import {Schedule, Calendar, CalendarEvent, Functions as fn} from 'dayspan';

  export default {
    name: 'customScheduleActions',
    props:
      {
        schedule:
          {
            required: true,
            type: Schedule
          },

        calendarEvent:
          {
            required: true,
            type: CalendarEvent
          },

        calendar:
          {
            type: Calendar
          },
        allowRemove:
          {
            type: Boolean,
            default() {
              return true;
            }
          }
      },

    data() {
      return {
        showDialog: false,
        movingDate: false,
        movingTime: false,
        includingDate: false,
        includingTime: false,
        targetDate: null,
        targetTime: null
      }
    },

    computed:
      {
        repeats() {
          return !this.schedule.isSingleEvent();
        },
        event() {
          return this.calendarEvent.event;
        },
        canRemove() {
          return this.allowRemove;
        }
      },
    methods:
      {
        remove() {
          let ev = this.getEvent('remove');
          this.$emit('custom-remove', ev);
          this.$emit('finish', ev);
        },
        getEvent(type, extra = {}) {
          return fn.extend({

            type: type,
            schedule: this.schedule,
            calendar: this.calendar,
            calendarEvent: this.calendarEvent,
            event: this.event,
            handled: false,
            refresh: true,
            $vm: this,
            $element: this.$el

          }, extra);
        }
      }
  }
</script>

<style scoped lang="scss">

</style>
