<template>
  <div>
    <div class="availability__time-btn">
      {{$t('availability_slots.break')}}
    </div>
    <transition name="fade">
      <div class="availability__time-inners availability-break">
        <div class="availability__time-inner">
          <div class="availability__time-title">
            {{$t('availability_slots.hours')}}
          </div>
          <VueCtkDateTimePicker
            :class="{'bold-text': !isDefaultBreakFrom}"
            v-model="single_break.from"
            formatted="HH:mm"
            format="HH:mm"
            :minute-interval="15"
            only-time
            label=""
            :error="errorFrom"
            @input="updateBreakItemTime()"
            :disabled-hours="disabledHoursFrom(single_break.to)"
          ></VueCtkDateTimePicker>
        </div>
        <div class="availability__time-inner">
          <div class="availability__time-title">
            {{$t('availability_slots.to')}}
          </div>
          <VueCtkDateTimePicker
            :class="{'bold-text': !isDefaultBreakTo}"
            v-model="single_break.to"
            formatted="HH:mm"
            format="HH:mm"
            :minute-interval="15"
            only-time
            label=""
            :error="errorTo"
            @input="updateBreakItemTime()"
            :disabled-hours="disabledHoursTo(single_break.from)"
          ></VueCtkDateTimePicker>
        </div>
        <div class="availability__time-btn">
          <v-btn
            class="text-xs-center text-sm-center text-md-center skip"
            flat
            small
            @click="removeBreak(break_index)"
          >
            X
          </v-btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import { empty } from '@/helpers.js';

export default {
  name: "availability-breaks",
  props: ['single_break', 'break_index'],
  computed: {
    isDefaultBreakFrom () {
      return (this.single_break.from == '13:00') ? true : false;
    },
    isDefaultBreakTo () {
      return (this.single_break.to == '14:00') ? true : false;
    },
    errorFrom(){
      if(!empty(this.single_break) && !empty(this.single_break.error)){
        return this.single_break.error.from
      }else{
        return false;
      }
    },
    errorTo(){
      if(!empty(this.single_break) && !empty(this.single_break.error)){
        return this.single_break.error.to
      }else{
        return false;
      }
    }
  },
  methods: {
    disabledHoursFrom (time_to) {
      const hours = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'];
      let disabled_hours = [];
      
      for (let i=0; i<hours.length; i++) {
        if (hours[i] > time_to) {
          disabled_hours.push(hours[i].slice(0,-3));
        }
      }

      return disabled_hours;
    },
    disabledHoursTo (time_from) {
      const hours = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'];
      let disabled_hours = [];
      
      for (let i=0; i<hours.length; i++) {
        if (hours[i] < time_from) {
          disabled_hours.push(hours[i].slice(0,-3));
        }
      }
      return disabled_hours;
    },
    removeBreak (break_index) {
      this.$emit("removeBreak", break_index);
    },
    updateBreakItemTime () {
      this.$emit("updateBreakItem");
    }
  }
}
</script>

<style scoped>
.date-time-picker .field.has-value .field-input {
  padding-top: 0;
}
</style>