export function empty(value) {
  if (typeof value == 'undefined' || value === null || value.length === 0 || value === "" || !/[^\s]/.test(value) || /^\s*$/.test(value)) {
    return true;
  } else {
    return false;
  }
}

export function isEmptyObj(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function formatExperience(monthCount) {
  function getPlural(number, word) {
    return number === 1 && word.one || word.other;
  }

  let months = {
      one: 'month',
      other: 'months'
    },
    years = {
      one: 'year',
      other: 'years'
    },
    m = monthCount % 12,
    y = Math.floor(monthCount / 12),
    result = [];

  y && result.push(y + ' ' + getPlural(y, years));
  m && result.push(m + ' ' + getPlural(m, months));
  return result.join(', ');
}

export function isLocalStorage() {
  const test = 'true';
  try {
    if (typeof localStorage !== 'object') {
      return false;
    }
    localStorage.setItem('test', test);
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

export function orderDaysOfWeek(days_week_object) {
  const sorter = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6
  };

  let tmp = [];
  Object.keys(days_week_object).forEach(function (key) {
    let value = days_week_object[key];
    let index = sorter[key.toLowerCase()];
    tmp[index] = {
      key: key,
      value: value
    };
  });

  let orderedData = {};
  tmp.forEach(function (obj) {
    orderedData[obj.key] = obj.value;
  });
  return orderedData;
}

export function getTreatmentObject(data) {
  let treatment = {};
  treatment['data'] = {};
  treatment['sumPrice'] = 0;
  for (let i = 0; i < data.length; i++) {
    let id = data[i].id;
    if (!treatment['data'].hasOwnProperty(data[i].id)) {
      treatment['data'][id] = {};
      treatment['data'][id]['quantity'] = 1;
      treatment['data'][id]['name'] = data[i].name;
      treatment['data'][id]['price'] = data[i].price;
      treatment['data'][id]['total_price'] = Number(data[i].price).toFixed(2);
    } else {
      treatment['data'][id]['quantity'] = treatment['data'][data[i].id]['quantity'] + 1;
      treatment['data'][id]['total_price'] = (Number(treatment['data'][data[i].id]['price']) * Number(treatment['data'][data[i].id]['quantity'])).toFixed(2);
    }

    treatment['sumPrice'] = (Number(treatment['sumPrice']) + Number(treatment['data'][id]['price'])).toFixed(2);
  }
  return treatment;
}

export function formatTreatments (value) {
  let treatments = [];
  for (let item in value) {
    treatments.push(value[item].name);
  }
  treatments = [...new Set(treatments.sort())];
  let treatment_name = treatments[0];
  const treatments_length = treatments.length;
  
  if ( treatments_length > 1) {
    treatment_name = `${treatment_name} (+${treatments_length - 1})`;
  }
  return treatment_name;
}

export function capitalizeFirstLetter (value) {
  if (!value) return ''
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function getFirstLetter (value) {
  if (!value) return ''
  value = value.toString();
  return value.charAt(0).toUpperCase();
}

export function getDisplayUserName(fullName) {
  if (!empty(fullName)) {
    let arrByName = fullName.split(' ');
    if (arrByName.length > 1) {
      let firstName = arrByName[0];
      let lastName = arrByName[1].toString();
      return `${capitalizeFirstLetter(firstName)} ${lastName.charAt(0).toUpperCase()}.`
    } else {
      return capitalizeFirstLetter(fullName);
    }
  } else {
    return '';
  }
}

export function getFirstUserName(fullName) {
  if (!empty(fullName)) {
    let arrByName = fullName.split(' ');
    if (arrByName.length > 1) {
      return capitalizeFirstLetter(arrByName[0])
    } else {
      return capitalizeFirstLetter(fullName);
    }
  } else {
    return '';
  }
}

export function getAvatarLetters (fullName) {
  if (!empty(fullName)) {
    let arrByName = fullName.split(' ');
    if (arrByName.length > 1) {
      let firstName = arrByName[0].toString();
      let lastName = arrByName[1].toString();
      return `${capitalizeFirstLetter(firstName.charAt(0).toUpperCase())}. ${lastName.charAt(0).toUpperCase()}.`
    } else {
      return capitalizeFirstLetter(fullName.charAt(0).toUpperCase());
    }
  } else {
    return '';
  }
}

export function formatDate (value) {
  return value.slice(0, -3);
}

export function checkPositionSidebar() {
  let dashboardСontentElem = document.getElementById('dashboard-content');
  let dashboardSidebarElem = document.getElementById('dashboard-sidebar__container');

  if (!empty(dashboardСontentElem) && !empty(dashboardSidebarElem)) {

    let contentHeight = dashboardСontentElem.offsetHeight;
    let sidebarHeight = dashboardSidebarElem.offsetHeight;
    let sidebarBottomPos = contentHeight - sidebarHeight;
    let trigger = window.pageYOffset;

    if (trigger > sidebarBottomPos) {
      return true;
    } else {
      return false;
    }

  }
}

export function getHeightOfElement(selector) {
  const element = document.querySelector(selector);
  if (!empty(element)) {
    return element.offsetHeight;
  } else {
    return 0;
  }
}

export function getHeightOfWindow() {
  return window.innerHeight;
}

export function getWidthOfWindow() {
  return window.innerWidth
}

export function addIsFixedToHtml() {
  const html = document.getElementsByTagName('html');
  if(!empty(html[0])){
    html[0].classList.add('is-fixed');
  }
}

export function removeIsFixedToHtml() {
  const html = document.getElementsByTagName('html');
  if(!empty(html[0])){
    html[0].classList.remove('is-fixed');
  }
}

export function toggleIsFixedToHtml() {
  const html = document.getElementsByTagName('html');
  if(!empty(html[0]) && html[0].classList.contains('is-fixed')){
    html[0].classList.remove('is-fixed');
  }else if(!empty(html[0])){
    html[0].classList.add('is-fixed');
  }
}