import {ADD_PRACTITIONER_TO_STATE} from '../action-types';
import { SET_PRACTITIONER_DATA } from '../mutation-types';
import * as CONFIG from "@/config.js";
import {empty} from '@/helpers.js';
import axios from "axios";

/* eslint-disable */ 

export default {

  state: {
    practitioner:{
      avatar: '',
      practitioner_rating: '',
      reviews_amount: '',
      name: '',
      work_location_types: '',
      experience: '',
      bio: '',
      call_out_charge: ''
    }
  },
  mutations: {
    [SET_PRACTITIONER_DATA]: (state, data) => {
      for(let param in state.practitioner){
        state.practitioner[param] = data[param];
      }
    }
  },
  actions: {

    [ADD_PRACTITIONER_TO_STATE]: ({commit}, {id, lat, lon}) => {

      let url = `${CONFIG.API_URL}/practitioner/${id}/info`;

      if(!empty(lat) && !empty(lon)){
        url = `${CONFIG.API_URL}/practitioner/${id}/info?lat=${lat}&lon=${lon}`;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest"
            }
          })
          .then(
            response => {
              commit('SET_PRACTITIONER_DATA', response.data);
              resolve(response.data);
            }
          )
          .catch(error => {
            console.log(error.response);
            reject(error);
          });
      });
    }
  },
  getters: {
    practitioner(state){
      return state.practitioner;
    }
  }

}