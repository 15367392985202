<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm12
        md10
        lg8
      >
        <v-stepper
          alt-labels
          v-model="step"
        >
          <v-stepper-header>
            <v-stepper-step
              :complete="step > 1"
              step="1"
              color="error"
            >
              {{$t('practitioner_registration.sign_up')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="step > 2"
              step="2"
              color="error"
            >
              {{$t('practitioner_registration.services')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="step > 3"
              step="3"
              color="error"
            >
              {{$t('practitioner_registration.locations')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="step > 4"
              step="4"
              color="error"
              v-html=""
            >
              {{$t('practitioner_registration.availability')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="step > 5"
              step="5"
              color="error"
            >
              {{$t('practitioner_registration.skills_experience')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="6"
              color="error"
            >
              {{$t('practitioner_registration.finished')}}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
            </v-stepper-content>

            <v-stepper-content step="2">
              <Services @changeStep="changeStep" />
            </v-stepper-content>

            <v-stepper-content step="3">
              <Locations
                @changeStep="changeStep"
                @updateLocations="updateLocations"
                @updateAvailability="updateAvailability"
              />
            </v-stepper-content>

            <v-stepper-content step="4">
              <Availability
                :locations="locations"
                :availabilityByLocations="availability"
                @changeStep="changeStep"
              />
            </v-stepper-content>

            <v-stepper-content step="5">
              <SkillsExperience @changeStep="changeStep" />
            </v-stepper-content>

            <v-stepper-content step="6">
              <Finished @changeStep="changeStep" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Services from '@/components/PractitionerInformation/Services'
import Locations from '@/components/PractitionerInformation/Locations'
import Availability from '@/components/PractitionerInformation/Availability'
import SkillsExperience from '@/components/PractitionerInformation/SkillsExperience'
import Finished from '@/components/PractitionerInformation/Finished'

import { empty } from '@/helpers.js';

export default {
  name: 'PractitionerInformation',
  props:['currentStep'],
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | Practitioner registration'
  },
  components: {
    Services,
    Locations,
    Availability,
    SkillsExperience,
    Finished,
  },
  created() {
    if (!empty(this.currentStep) && this.currentStep > 2) {
      this.step = this.currentStep;
    }
  },
  data: function () {
    return {
      step: 2,
      locations: {
        mobile: false,
        salon: false,
        data: {}
      },
      availability: {
        mobile: {},
        salon: []
      }
    }
  },
  methods: {
    changeStep (step) {
      this.step = step;
      const currentUser = this.$store.getters.currentUser;
      this.$store.commit('SET_CURRENT_USER_DATA', {
        practitioner_info: {
          practitioner_rating: currentUser.practitioner_info.practitioner_rating,
          reviews_amount: currentUser.practitioner_info.reviews_amount,
          onboarding_step: this.step - 1
        }
      });
      this.$router.replace({path: `/practitioner-information/${step}`});
      this.$scrollTo('.header.v-toolbar');
    },
    updateLocations (locations) {
      this.locations.mobile = locations.mobile;
      this.locations.salon = locations.salon;
      this.locations.data = JSON.stringify(locations.data);
    },
    updateAvailability (availability) {
      this.availability.mobile = availability.mobile;
      this.availability.salon = availability.salon;
    }
  }
};
</script>

<style scoped>
</style>