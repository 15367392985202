import {SET_CLICK_COOKIE_BAR, SET_SHOW_COOKIE_BAR, SET_IS_NEED_SHOW_COOKIE} from '../mutation-types';
import repository from '@/repository/repository.js';

export default {
  state: {
    isNeedShowCookie: true,
  },
  mutations: {
    [SET_IS_NEED_SHOW_COOKIE]: (state, bool) => {
      state.isNeedShowCookie = bool;
    },
    [SET_CLICK_COOKIE_BAR]: () => {
      repository.saveValue('is-click-panel', 'true');
    },
    [SET_SHOW_COOKIE_BAR]: () => {
      repository.saveValue('is-show-panel', 'true');
    },
  },
  actions: {},
  getters: {
    needShowCookieBar(state) {
      return state.isNeedShowCookie;
    }
  }
}