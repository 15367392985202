<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
      >
        <v-card class="register-card register-card--create">
          <v-card-text>
            <p class="register-card__title">
              {{$t('auth_forms.create_practitioner_account')}}
            </p>

            <v-form
              ref="form"
              v-model="formValid"
              lazy-validation
            >
              <v-text-field
                name="firstName"
                :label="$t('auth_forms.first_name')"
                v-model="first_name"
                :rules="first_nameRules"
                :error-messages="first_nameErrorMess"
                @focus="clearErrorMess('first_nameErrorMess')"
                required
              ></v-text-field>

              <v-text-field
                name="lastName"
                :label="$t('auth_forms.last_name')"
                v-model="last_name"
                :rules="last_nameRules"
                :error-messages="last_nameErrorMess"
                @focus="clearErrorMess('last_nameErrorMess')"
                required
              ></v-text-field>

              <v-text-field
                name="phone"
                :label="$t('auth_forms.phone')"
                type="text"
                :rules="phoneRules"
                :placeholder="phonePlaceholder"
                v-model="phone"
                :error-messages="phoneErrorMess"
                :validate-on-blur="true"
                mask="###########"
                @focus="clearErrorMess('phoneErrorMess'), setPlaceholder('phone','07737129828')"
                required
              ></v-text-field>

              <v-text-field
                name="email"
                :label="$t('auth_forms.email')"
                type="email"
                :rules="emailRules"
                v-model="email"
                :error-messages="emailErrorMess"
                @focus="clearErrorMess('emailErrorMess')"
                required
              ></v-text-field>

              <v-text-field
                name="password"
                :label="$t('auth_forms.password')"
                type="password"
                :rules="passwordRules"
                v-model="password"
                :error-messages="passwordErrorMess"
                @focus="clearErrorMess('passwordErrorMess')"
                required
              ></v-text-field>

              <v-text-field
                name="confirm-password"
                :label="$t('auth_forms.password_confirmation')"
                type="password"
                :rules="password_confirmationRules"
                v-model="password_confirmation"
                :error-messages="password_confirmationErrorMess"
                @focus="clearErrorMess('password_confirmationErrorMess')"
                required
              ></v-text-field>

              <v-radio-group
                :label="$t('auth_forms.gender')"
                v-model="gender"
                :mandatory="false"
                :error-messages="genderErrorMess"
                :rules="genderRules"
                class="checkbox-string-label"
              >
                <div class="checkout__form-fields checkout__form-fields--checkboxes">
                  <div class="checkout__form-field">
                    <v-radio
                      :label="$t('auth_forms.male')"
                      value="m"
                      off-icon="check_box_outline_blank"
                      on-icon="check_box"
                    ></v-radio>
                  </div>
                  <div class="checkout__form-field">
                    <v-radio
                      :label="$t('auth_forms.female')"
                      value="f"
                      off-icon="check_box_outline_blank"
                      on-icon="check_box"
                    ></v-radio>
                  </div>
                </div>
              </v-radio-group>

              <v-select
                      :items="appointment_schedulingItems"
                      v-model="appointment_scheduling"
                      :rules="appointment_schedulingRules"
                      :error-messages="appointment_schedulingErrorMess"
                      item-text="text"
                      item-value="value"
                      :label="$t('auth_forms.how_do_you_manage_your_scheduling')"
              ></v-select>

              <v-select
                :items="how_you_findItems"
                v-model="how_you_find"
                :rules="how_you_findRules"
                :error-messages="how_you_findErrorMess"
                item-text="text"
                item-value="value"
                :label="$t('auth_forms.how_did_you_hear_about_us')"
              ></v-select>

              <!--<p-->
              <!--class="register-card-clients"-->
              <!--v-bind:class="{ 'error&#45;&#45;text': !clientsCountValid }"-->
              <!--&gt;How many clients do you treat per week?</p>-->
              <!--<v-btn-toggle-->
              <!--v-model="clientsCount"-->
              <!--class="register-card-clients-btn-group"-->
              <!--&gt;-->
              <!--<v-btn-->
              <!--small-->
              <!--value='{"from": 0, "to": 5}'-->
              <!--@click="clientsCountValidClick"-->
              <!--&gt;-->
              <!--0 - 5-->
              <!--</v-btn>-->
              <!--<v-btn-->
              <!--small-->
              <!--value='{"from": 5, "to": 10}'-->
              <!--@click="clientsCountValidClick"-->
              <!--&gt;-->
              <!--5 - 10-->
              <!--</v-btn>-->
              <!--<v-btn-->
              <!--small-->
              <!--value='{"from": 10, "to": 20}'-->
              <!--@click="clientsCountValidClick"-->
              <!--&gt;-->
              <!--10 - 20-->
              <!--</v-btn>-->
              <!--<v-btn-->
              <!--small-->
              <!--value='{"from": 20}'-->
              <!--@click="clientsCountValidClick"-->
              <!--&gt;-->
              <!--20 +-->
              <!--</v-btn>-->
              <!--</v-btn-toggle>-->

              <!-- <transition name="fade">
                <span
                  class="v-messages error--text"
                  v-if="!clientsCountValid"
                >Clients count is required</span>
              </transition> -->

            <div class="checkout__form-fields checkout__form-fields--checkboxes">
              <div class="checkout__form-field">
                <v-checkbox
                  :rules="agreeRules"
                  v-model="isAgree"
                  class="agree"
                >
                  <span slot="label"> {{$t('auth_forms.agree_to_the')}} <a href="#" @click.prevent="onTermsOfServices()" > {{$t('auth_forms.terms_conditions')}} </a> {{$t('auth_forms.and')}} <a href="#" @click.prevent="onPrivacyPolicy()" > {{$t('auth_forms.privacy_policy')}} </a>.</span>
                </v-checkbox>
              </div>
            </div>

            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              color="error"
              @click="onSubmit"
              :loading="loading"
              :disabled="!formValid || loading || beingProcessed || isHasErrorMess && !isAgree"
            >
              {{$t('auth_forms.sign_up')}}
            </v-btn>
          </v-card-actions>

          <!--<v-card-text class="register-card-terms">-->
            <!--{{$t('auth_forms.by_signing_up_you_are_confirming')}}-->
            <!--<a-->
              <!--href="#"-->
              <!--@click="onTermsOfServices()"-->
            <!--&gt;{{$t('auth_forms.terms_conditions')}}</a> {{$t('auth_forms.and')}} <a-->
            <!--href="#"-->
            <!--@click="onPrivacyPolicy()"-->
          <!--&gt;{{$t('auth_forms.privacy_policy')}}</a>.-->
          <!--</v-card-text>-->

        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import * as CONFIG from "@/config.js";
  import {empty} from '@/helpers.js';

  /* eslint-disable */

  export default {
    name: "practitioner-registration",
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Practitioner Sign Up'
    },
    data() {
      return {
        formValid: false,
        userRole: "practitioner",
        isAgree: false,
        agreeRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_check_to_continue'),
        ],

        first_name: '',
        first_nameRules: [v => !!v || this.$i18n.t('auth_forms.js_error_first_name_required')],
        first_nameErrorMess: [],

        last_name: "",
        last_nameRules: [v => !!v || this.$i18n.t('auth_forms.js_error_last_name_required')],
        last_nameErrorMess: [],

        email: '',
        emailRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_email_required'),
          v => /.+@.+/.test(v) || this.$i18n.t('auth_forms.js_error_email_format')
        ],
        emailErrorMess: [],

        phone: '',
        phoneRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_phone_required'),
          v => /^\d+$/.test(v) || this.$i18n.t('auth_forms.js_error_phone_numbers'),
          v => !(v.length != 11) || this.$i18n.t('auth_forms.js_error_phone_format')
        ],
        phoneErrorMess: [],
        phonePlaceholder: '',

        gender: '',
        genderErrorMess: [],
        genderRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_gender_required'),
        ],

        how_you_find: '',
        how_you_findErrorMess: [],
        how_you_findRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_how_you_find_required'),
        ],
        how_you_findErrorMess: [],
        how_you_findItems: [
          {value: 'google_search', text: this.$i18n.t('auth_forms.google_search')},
          {value: 'facebook', text: this.$i18n.t('auth_forms.facebook')},
          {value: 'instagram', text: this.$i18n.t('auth_forms.instagram')},
          {value: 'twitter', text: this.$i18n.t('auth_forms.twitter')},
          {value: 'linkedin', text: this.$i18n.t('auth_forms.linkedin')},
          {value: 'newspaper_magazine', text: this.$i18n.t('auth_forms.newspaper_magazine')},
          {value: 'recommendation', text: this.$i18n.t('auth_forms.recommendation')},
          {value: 'other', text: this.$i18n.t('auth_forms.other')},
        ],

          appointment_scheduling: '',
          appointment_schedulingErrorMess: [],
          appointment_schedulingRules: [
              v => !!v || this.$i18n.t('auth_forms.js_error_appointment_scheduling_required'),
          ],
          appointment_schedulingErrorMess: [],
          appointment_schedulingItems: [
              {value: 'apple_ical', text: this.$i18n.t('auth_forms.apple_ical')},
              {value: 'google_calendar', text: this.$i18n.t('auth_forms.google_calendar')},
              {value: 'microsoft_outlook_calendar', text: this.$i18n.t('auth_forms.microsoft_outlook_calendar')},
              {value: 'other_digital', text: this.$i18n.t('auth_forms.other_digital')},
              {value: 'notebook', text: this.$i18n.t('auth_forms.notebook')},
          ],


        password: '',
        passwordRules: [v => !!v || this.$i18n.t('auth_forms.js_error_password_required')],
        passwordErrorMess: [],

        password_confirmation: '',
        password_confirmationRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_password_confirmation_required'),
          v => v === this.password || this.$i18n.t('auth_forms.js_error_password_should_match')
        ],
        password_confirmationErrorMess: [],

        clientsCount: "",
        clientsCountValid: true,

        beingProcessed: false
      }
    },
    mounted() {
      this.$scrollTo('.header.v-toolbar');
    },
    computed: {
      loading() {
        return (this.$store.getters.authStatus === 'loading') ? true : false;
      },
      isHasErrorMess() {
        return !(empty(this.first_nameErrorMess) && empty(this.last_nameErrorMess) && empty(this.emailErrorMess)
          && empty(this.phoneErrorMess) && empty(this.genderErrorMess) && empty(this.how_you_findErrorMess) && empty(this.appointment_schedulingErrorMess) && empty(this.passwordErrorMess) && empty(this.password_confirmationErrorMess));
      }
    },
    methods: {
      conversionTracking() {
        try {
          if (CONFIG.APP_ENV === 'production') {
            window.gtag('event', 'conversion', {'send_to': 'AW-755360510/BgAeCPuOop0BEP7Fl-gC'});
          }
        } catch (error) {
          console.log(error);
        }
      },
      setPlaceholder(field, placeholder) {
        this[`${field}Placeholder`] = placeholder;
      },
      onTermsOfServices () {
        const routeData = this.$router.resolve({ name: 'terms-of-service' });
        window.open(routeData.href, '_blank');
      },
      onPrivacyPolicy () {
        const routeData = this.$router.resolve({ name: 'privacy-policy' });
        window.open(routeData.href, '_blank');
      },
      clientsCountValidClick() {
        this.clientsCountValid = true;
        if (this.$refs.form.validate()) {
          this.formValid = true;
        }
      },
      clearErrorMess(field) {
        this[field] = [];
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      onSubmit() {
        // if (!this.clientsCount) {
        //   this.clientsCountValid = false;
        //   this.formValid = false
        // } else {
        //   this.clientsCountValid = true;
        //   this.formValid = true;
        // }

        if (this.$refs.form.validate() && !this.isHasErrorMess && this.isAgree) {
          this.beingProcessed = true;
          const user_registration_data = {
            user_role: this.userRole,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone: this.phone,
            gender: this.gender,
            password: this.password,
            password_confirmation: this.password_confirmation,
            how_you_find: this.how_you_find,
            appointment_scheduling: this.appointment_scheduling
            // amount_of_treats_per_week: JSON.parse(this.clientsCount),
          };

          this.$store.dispatch('REGISTER_REQUEST', user_registration_data)
            .then((response) => {
              this.beingProcessed = false;
              this.conversionTracking();
              this.$store.commit('SET_NOTIFICATION', {
                text: response.data.message,
                color: 'error'
              });
              console.log(response);
              this.$router.replace("/practitioner-information");
            })
            .catch((error) => {
              this.beingProcessed = false;
              if (!empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              }
            });
        }
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .register-card {
    padding: 40px 60px;
    max-width: 720px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 22px;
    background-image: linear-gradient(to right, #fff 0%, #fdfdfd 100%);

    @include r(1500) {
      padding: 24px 50px;
      max-width: 560px;
    }

    @include r(700) {
      padding: 25px 30px;
    }

    @include r(420) {
      padding: 25px 20px;
    }

    &--create {
      margin: 26px auto;

      @include r(1500) {
        margin: 0 auto;
      }
      @include r(700) {
        margin: 0 auto;
      }

      .v-input__slot .v-label {
        @include r(1500) {
          font-size: 15px;
        }
      }
      .v-input--selection-controls {
        @include r(1500) {
          margin-top: 0;
        }
      }
      .v-input input {
        padding: 6px 0;
        max-height: 28px;
      }
    }

    &--customer {
      margin: 26px auto;

      @include r(1500) {
        margin: 0 auto;
      }
    }

    &__title {
      display: block;
      text-align: center;
      color: #47434d;
      font-size: 24px;
      font-weight: 700;
      margin: 0;

      @include r(1500) {
        font-size: 21px;
      }

      @include r(700) {
        font-size: 19px;
      }
    }

    .v-form {
      margin-top: 5px;
    }
    .v-card__actions {
      padding: 0;
      margin-top: 10px;

      button {
        @include r(1700) {
          font-size: 21px;
        }

        @include r(1500) {
          height: 49px;
          font-size: 18px;
        }

        @include r(900) {
          font-size: 16px;
          border-radius: 4px;
        }

        @include r(450) {
          font-size: 15px;
        }
      }
    }
    .v-input__slot {
      margin-bottom: 8px !important;

      @include r(1500) {
        margin-bottom: 5px !important;
      }
    }
    .v-input--selection-controls__input {
      height: auto;
    }
    .checkout__form-field {
      min-height: auto !important;

      span {
        a {
          color: #535353;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .agree {
        padding-top: 10px;
      }
    }
  }

  .checkbox-string-label {
    .v-label {
      order: 1;
    }
    .checkout__form-fields {
      order: 2;
      padding-left: 0;
      .v-label {
        min-width: 70px !important;
      }
    }
  }

  .register-card.v-card {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    .v-card__text {
      padding: 0;
    }

    &__text {
      padding: 0 60px;
    }

    &__actions {
      padding: 0 60px;
      margin-top: 40px;
    }
  }

  .v-input__slot .v-label {
    font-size: 17px;
    font-weight: 400;

    @include r(700) {
      font-size: 15px;
    }
  }

  .v-form {
    margin-top: 20px;

    @include r(700) {
      margin-top: 20px;
    }

    .v-text-field {
      margin-top: 4px;

      @include r(700) {
        margin-top: 5px;
      }
    }
  }

  .register-card-clients {
    margin: 20px 0 5px 0;
    font-size: 16px;

    @include r(700) {
      font-size: 15px;
      margin-top: 5px;
    }
  }

  .register-card-clients-btn-group {
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 15px 0 20px 0;

    .v-btn {
      margin: 0 6px;
      padding: 0 24px;
      background-color: #fff !important;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      color: #000;
      opacity: 0.7;
      border-radius: 3px;
      font-size: 16px;

      @include r(700) {
        padding: 0 13px;
      }
      @include r(420) {
        font-size: 13px;
        margin: 0 4px;
      }
    }
  }

  .v-btn-toggle--selected {
    box-shadow: none;
  }

  .register-card-terms {
    font-size: 16px;
    color: #7b8994;
    margin-top: 14px;

    @include r(1500) {
      font-size: 14px;
      margin-top: 8px;
    }

    a {
      color: #7b8994;
      &:hover {
        text-decoration: none;
      }
    }
  }
</style>
