<template>
  <div
    class="dashboard-content dashboard-settings"
    id="dashboard-content"
  >
    <div class="dashboard-content__title">
      <h2>
        {{$t('practitioner_profile.settings_tab_title')}}
      </h2>
      <p>
        {{$t('practitioner_profile.settings_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">
      <div class="dashboard-settings__container">
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('practitioner_profile.settings_tab_profile_status')}}
          </div>
          <div class="dashboard-settings__inners">
            <div class="dashboard-settings__inner dashboard-settings__inner--sm">
              <div class="dashboard-settings__field">
                <v-form
                  ref="activeForm"
                  v-model="isActiveFormValid"
                  lazy-validation
                >
                  <v-select
                    :items="profileStatuses"
                    v-model="active"
                    :error-messages="activeErrorMess"
                    :rules="activeRules"
                    item-text="text"
                    item-value="value"
                    solo
                    hide-details
                    color="error"
                    light
                    required
                    class="solo-field-white-radius"
                  ></v-select>
                </v-form>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('practitioner_profile.settings_tab_cancellation_policy')}}
          </div>
          <div class="dashboard-settings__inners">
            <!-- <div class="dashboard-settings__inner dashboard-settings__inner--sm">
              <div class="dashboard-settings__field">
                <v-form
                  ref="cancellationForm"
                  v-model="isCancellationFormValid"
                  lazy-validation
                >
                  <v-select
                    :items="cancellationPolicyItems"
                    v-model="cancellation_policy"
                    :error-messages="cancellation_policyErrorMess"
                    :rules="cancellation_policyRules"
                    item-text="text"
                    item-value="value"
                    solo
                    hide-details
                    color="error"
                    light
                    required
                    disabled
                    class="solo-field-white-radius"
                  ></v-select>
                </v-form>
              </div>
            </div> -->
            <!-- <div class="dashboard-settings__inner dashboard-settings__inner--sm"> -->
            <div class="dashboard-settings__inner">
              <span class="dashboard-settings__text">
                {{$t('practitioner_profile.settings_tab_you_cancellation_policy')}}
              </span> <a
              href="#"
              class="dashboard-settings__link"
              @click="onSupport()"
            >
              {{$t('practitioner_profile.settings_tab_learn more')}}
            </a>
            </div>
            <!-- </div> -->
          </div>
        </div>

        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('practitioner_profile.settings_tab_change_password')}}
          </div>
          <v-form
            ref="passwordForm"
            v-model="isPasswordFormValid"
            lazy-validation
          >
            <div class="dashboard-settings__inners">
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">

                  <v-text-field
                    type="password"
                    :error-messages="old_passwordErrorMess"
                    v-model="old_password"
                    :label="$t('practitioner_profile.settings_tab_old_password')"
                    @change="storePassword('old_password')"
                    solo
                    class="solo-field-white-radius"
                  ></v-text-field>
                </div>
              </div>
            </div>

            <div class="dashboard-settings__inners">
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="password"
                    v-model="password"
                    :error-messages="passwordErrorMess"
                    :label="$t('practitioner_profile.settings_tab_new_password')"
                    @change="storePassword('password')"
                    solo
                    class="solo-field-white-radius"
                  ></v-text-field>
                </div>
              </div>
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="password"
                    v-model="password_confirmation"
                    :error-messages="password_confirmationErrorMess"
                    @change="storePassword('password_confirmation')"
                    :label="$t('practitioner_profile.settings_tab_confirm_password')"
                    solo
                    class="solo-field-white-radius"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-form>

        </div>
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('practitioner_profile.settings_tab_update_email')}}
          </div>
          <v-form
            ref="emailForm"
            v-model="isEmailFormValid"
            lazy-validation
          >
            <div class="dashboard-settings__inners">
              <div class="dashboard-settings__inner dashboard-settings__inner--lg">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="email"
                    :value="this.$store.getters.currentUser.email"
                    :disabled="true"
                    solo
                    class="solo-field-white-radius"
                  ></v-text-field>
                </div>
              </div>
              <div class="dashboard-settings__inner dashboard-settings__inner--lg">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="email"
                    v-model="email"
                    :error-messages="emailErrorMess"
                    :label="$t('practitioner_profile.settings_tab_new_address')"
                    @change="storeEmail"
                    solo
                    class="solo-field-white-radius"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('practitioner_profile.settings_tab_bank_detailsd')}}
          </div>
          <v-form
            ref="bankDetailsForm"
            v-model="isBankDetailsFormValid"
            lazy-validation
          >
            <div class="dashboard-settings__inners">
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                  <label for="">
                    {{$t('practitioner_profile.settings_tab_sort_code')}}
                  </label>
                  <v-text-field
                    type="text"
                    v-model="sort_code"
                    :error-messages="sort_codeErrorMess"
                    label="— — — — — — — —"
                    @change="storeBankDetails('sort_code')"
                    solo
                    class="solo-field-white-radius dashboard-settings-field-center"
                  ></v-text-field>
                </div>
              </div>
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                  <label for="">
                    {{$t('practitioner_profile.settings_tab_account_number')}}
                  </label>
                  <v-text-field
                    type="text"
                    v-model="account_number"
                    :error-messages="account_numberErrorMess"
                    label="— — — — — — — —"
                    @change="storeBankDetails('account_number')"
                    solo
                    class="solo-field-white-radius dashboard-settings-field-center"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('auth_forms.phone')}}
          </div>
          <v-form
                  ref="phoneForm"
                  v-model="isPhoneFormValid"
                  lazy-validation
          >
            <div class="dashboard-settings__inners">
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                  <v-text-field
                          name="phone"
                          type="text"
                          :rules="phoneRules"
                          :placeholder="phonePlaceholder"
                          v-model="phone"
                          :error-messages="phoneErrorMess"
                          :validate-on-blur="true"
                          mask="###########"
                          @change="storePhone(phone)"
                          @focus="setPlaceholder('phone','07737129828')"
                          solo
                          class="solo-field-white-radius dashboard-settings-field-center"
                          required
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-form>
        </div>

        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('practitioner_profile.settings_tab_delete_account_block_title')}}
          </div>
            <div class="dashboard-settings__inners">
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                   <span class="profile-search__categories d-block"
                         @click="showDeleteAccountDialog"
                   >
                      {{$t('practitioner_profile.settings_tab_delete_account_button')}}
                   </span>
                </div>
              </div>
            </div>
        </div>
      </div>

      <template>
        <v-layout row justify-center>
          <v-dialog v-model="deleteAccountDialog" persistent max-width="290">
            <v-card>
              <v-card-text class="text-lg-center text-md-center text-sm-center text-xs-center">{{$t('practitioner_profile.settings_tab_delete_account_desc_popup')}}</v-card-text>
              <v-card-text v-if="showDeleteAccProgress">
                <v-progress-linear
                        indeterminate
                        color="error"
                        height="3"
                ></v-progress-linear>
              </v-card-text>
              <v-card-actions>
                <v-btn
                        color="red darken-1"
                        flat
                        @click="deleteAccount"
                        :disabled="disableButtons"
                >
                  {{$t('practitioner_profile.booking_tab_cancel_popup_yes')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" flat
                       @click="deleteAccountDialog = false"
                       :disabled="disableButtons"
                >
                  {{$t('practitioner_profile.booking_tab_cancel_popup_no')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </template>

    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty} from '@/helpers.js';

  /* eslint-disable */

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Practitioner Settings'
    },
    data() {
      return {
        active: null,
        activeErrorMess: [],
        activeRules: [
          v => !empty(v) || this.$i18n.t('practitioner_profile.settings_tab_js_error_status_required'),
        ],
        profileStatuses: [
          {text: this.$i18n.t('practitioner_profile.settings_tab_active'), value: true},
          {text: this.$i18n.t('practitioner_profile.settings_tab_inactive'), value: false},
        ],
        isActiveFormValid: true,
        phoneRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_phone_required'),
          v => /^\d+$/.test(v) || this.$i18n.t('auth_forms.js_error_phone_numbers'),
          v => !(v.length != 11)  || this.$i18n.t('auth_forms.js_error_phone_format')
        ],
        // cancellation_policy: null,
        // cancellation_policyErrorMess: [],
        // cancellation_policyRules: [
        //   v => !empty(v) || "Cancellation policy is required",
        // ],
        // cancellationPolicyItems: [
        //   { text: 'Moderate', value: 'moderate' },
        //   { text: 'No Moderate', value: 'no_moderate' }
        // ],
        // isCancellationFormValid: true,

        password: '',
        passwordErrorMess: [],
        old_password: '',
        old_passwordErrorMess: [],
        password_confirmation: '',
        password_confirmationErrorMess: [],
        isPasswordFormValid: true,

        email: '',
        emailErrorMess: [],
        isEmailFormValid: true,

        sort_code: '',
        sort_codeErrorMess: [],
        account_number: '',
        account_numberErrorMess: [],
        phone: '',
        phoneErrorMess: [],
        phonePlaceholder: '',
        isPhoneFormValid: true,
        isBankDetailsFormValid: true,

        deleteAccountDialog: false,
        showDeleteAccProgress: false,
        disableButtons: false
      }
    },
    created: function () {
      this.getPractitionerProfileSettings()
        .then((practitioner) => {
          this.active = practitioner.data.active;
          this.cancellation_policy = practitioner.data.cancellation_policy;
          this.sort_code = practitioner.data.bank_details.sort_code;
          this.account_number = practitioner.data.bank_details.account_number;
          this.call_out_charge = practitioner.data.call_out_charge;

          this.getUserSettings()
            .then((user) => {
                this.phone =  user.data.phone;
                this.setWatch();
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    methods: {
      showDeleteAccountDialog(){
        this.deleteAccountDialog = true;
      },
      hideDeleteAccountDialog(){
        this.deleteAccountDialog = false;
        this.disableButtons = false;
        this.showDeleteAccProgress = false;
      },
      deleteAccount(){
        this.disableButtons = true;
        this.showDeleteAccProgress = true;

        axios({
          method: 'delete',
          url: `${CONFIG.API_URL}/dashboard/practitioner/account`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
          .then(() => {
            this.hideDeleteAccountDialog();
            let data = {
              user_roles: ['customer'],
            };
            this.$store.commit('SET_CURRENT_USER_DATA', data);
            this.$store.commit('SET_NOTIFICATION', {
              text: this.$i18n.t('practitioner_profile.settings_tab_delete_account_successfully'),
              color: 'error'
            });
            this.$router.push({name: 'customer-dashboard'})
          })
          .catch((error) => {
            this.hideDeleteAccountDialog();
            if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
            console.error(error);
          })
      },
      clearErrorMess (field) {
        this[field] = [];
      },
      setPlaceholder(field, placeholder){
        this[`${field}Placeholder`] = placeholder;
      },
      onSupport() {
        const routeData = this.$router.resolve({name: 'support'});
        window.open(routeData.href, '_blank');
      },
      showNotification() {
        this.$store.commit('CLEAR_NOTIFICATION');
        const that = this;
        setTimeout((that) => {
          that.$store.commit('SET_NOTIFICATION', {
            text: this.$i18n.t('practitioner_profile.settings_tab_notif_settings_updated'),
            color: 'error'
          });
        }, 200, that);
      },
      storeStatus(status) {
        this.activeErrorMess = [];
        if (this.$refs.activeForm.validate()) {
          this.updateProfileSettings({'active': status})
            .then(() => {
              this.showNotification();
            })
            .catch((error) => {
              if (!empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              }
            });
        }
      },
      // storeCancellationPolicy (cancellation_policy) {
      //   this.cancellation_policyErrorMess = [];
      //   if (this.$refs.cancellationForm.validate()) {
      //     this.updateProfileSettings({ 'cancellation_policy': cancellation_policy })
      //       .then(() => {
      //         this.showNotification();
      //       })
      //       .catch((error) => {
      //         if (!empty(error.response.data.errors)) {
      //           this.fieldsErrorFromServer(error.response.data.errors);
      //         }
      //       });
      //   }
      // },
      storePhone(phone) {
          if (this.$refs.phoneForm.validate()) {
            this.updateUserSettings({'phone': phone})
              .then(() => {
               this.updateCurrentUserStore({'phone': phone});
                  this.showNotification();
               })
               .catch((error) => {
                  if (!empty(error.response.data.errors)) {
                    this.fieldsErrorFromServer(error.response.data.errors);
                  }
               });
          }
      },
      storePassword(name) {
        this[`${name}ErrorMess`] = [];
        this.validatePassword(name);
        if (empty(this.passwordErrorMess) && empty(this.old_passwordErrorMess) && empty(this.password_confirmationErrorMess)) {
          this.updateUserSettings({
            'password': this.password,
            'old_password': this.old_password,
            'password_confirmation': this.password_confirmation,
          })
            .then(() => {
              this.password = '';
              this.old_password = '';
              this.password_confirmation = '';
              this.showNotification();
            })
            .catch((error) => {
              if (!empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              }
            });
        }
      },
      validatePassword() {
        if (empty(this.password)) {
          this.passwordErrorMess.push(this.$i18n.t('practitioner_profile.settings_tab_js_error_password_required'))
        }
        if (empty(this.old_password)) {
          this.old_passwordErrorMess.push(this.$i18n.t('practitioner_profile.settings_tab_js_error_password_required'))
        }
        if (empty(this.password_confirmation)) {
          this.password_confirmationErrorMess.push(this.$i18n.t('practitioner_profile.settings_tab_js_error_password_required'))
        }
        if (this.password !== this.password_confirmation) {
          this.password_confirmationErrorMess.push(this.$i18n.t('practitioner_profile.settings_tab_js_error_password_confirmation_required'))
        }
      },
      storeEmail(email) {
        this.emailErrorMess = [];
        this.validateEmail(email);
        if (empty(this.emailErrorMess)) {
          this.updateUserSettings({'email': email})
            .then(() => {
              this.updateCurrentUserStore({'email': email});
              this.showNotification();
            })
            .catch((error) => {
              if (!empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              }
            });
        }
      },
      validateEmail(email) {
        if (empty(email)) {
          this.emailErrorMess.push(this.$i18n.t('practitioner_profile.settings_tab_js_error_email_required'))
        }
        if (!(/.+@.+/.test(email))) {
          this.emailErrorMess.push(this.$i18n.t('practitioner_profile.settings_tab_js_error_email_format'))
        }
      },
      validatePhone(phoneNumber) {
        if (empty(phoneNumber)) {
          this.phoneErrorMess.push('Phone number is required');
        }
      },
      storeBankDetails(name) {
        if (name === 'sort_code') {
          this.sort_codeErrorMess = [];
        } else if (name === 'account_number') {
          this.account_numberErrorMess = [];
        }
        this.validateBankDetails();
        if (empty(this.sort_codeErrorMess) && empty(this.account_numberErrorMess)) {
          this.updateProfileSettings({
            bank_details: {
              sort_code: this.sort_code,
              account_number: this.account_number
            }
          })
            .then(() => {
              this.showNotification();
            })
            .catch((error) => {
              if (!empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              }
            })
        }
      },
      validateBankDetails() {
        if (empty(this.sort_code)) {
          this.sort_codeErrorMess.push(this.$i18n.t('practitioner_profile.settings_tab_js_error_sort_code_required'));
        }
        if (empty(this.account_number)) {
          this.account_numberErrorMess.push(this.$i18n.t('practitioner_profile.settings_tab_js_error_account_number_required'));
        }
      },
      setWatch() {
        this.$watch('active', (status) => this.storeStatus(status));
        // this.$watch('cancellation_policy', (cancellation_policy) => this.storeCancellationPolicy(cancellation_policy));
      },
      getPractitionerProfileSettings() {
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/practitioner/profile`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      getUserSettings() {
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/user/settings`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      updateProfileSettings(data) {
        return axios({
          method: 'put',
          url: `${CONFIG.API_URL}/dashboard/practitioner/profile`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          data: JSON.stringify(data)
        });
      },
      updateUserSettings(data) {
        return axios({
          method: 'put',
          url: `${CONFIG.API_URL}/dashboard/user/settings`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          data: JSON.stringify(data)
        });
      },
      updateCurrentUserStore(data) {
        if (!empty(Object.keys(data)[0])) {
          if (['email'].includes(Object.keys(data)[0])) {
            this[Object.keys(data)[0]] = null;
            this.$store.commit('SET_CURRENT_USER_DATA', data);
          }
        }
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .dashboard-settings {
    &__container {
      @include r(700) {
        background-color: #fff;
        border-radius: 4px;
        padding: 20px;
        text-align: left;
      }
      @include r(550) {
        padding: 20px 14px 16px 14px;
      }
    }

    .v-form {
      margin: 0;
    }
    .v-form .v-text-field {
      margin: 0;
    }

    &__holder {
      display: block;
      margin-bottom: 26px;

      @include r(1700) {
        margin-bottom: 20px;
      }

      @include r(1500) {
        margin-bottom: 18px;
      }
      @include r(550) {
        margin-bottom: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      display: block;
      color: #707070;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 600;

      @include r(1700) {
        font-size: 18px;
      }

      @include r(1500) {
        font-size: 17px;
      }
      @include r(700) {
        padding-left: 30px;
      }
      @include r(550) {
        font-size: 15px;
      }
    }

    &__inners {
      display: flex;
      margin: 10px -15px 0 -15px;
      align-items: center;

      @include r(600) {
        flex-wrap: wrap;
      }
      .v-input__slot {
        @include r(700) {
          border-radius: 4px;
          background-color: #f7f7f7 !important;
        }
      }
    }

    &__inner {
      flex: 0 0 auto;
      padding: 0 15px;
      font-size: 16px;

      &--sm {
        width: 243px;

        @include r(700) {
          flex: 0 0 50%;
          width: 100%;
        }
        @include r(600) {
          flex: 0 0 100%;
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &--md {
        width: 294px;

        @include r(700) {
          flex: 0 0 50%;
          width: 100%;
        }
        @include r(600) {
          flex: 0 0 100%;
        }
      }

      &--lg {
        width: 418px;

        @include r(1200) {
          flex: 0 0 50%;
          width: 100%;
        }
        @include r(600) {
          flex: 0 0 100%;
        }
      }
    }

    &__text {
      color: #a3a3a3;
      font-family: Nunito;
      font-size: 18px;
      font-weight: 400;

      @include r(1700) {
        font-size: 17px;
      }

      @include r(1500) {
        font-size: 16px;
      }
      @include r(550) {
        font-size: 15px;
      }
      @include r(430) {
        display: block;
      }
    }

    &__link {
      color: #a3a3a3;
      font-family: Nunito;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      text-decoration: underline;
      transition: 0.3s;
      margin-left: 6px;

      @include r(1700) {
        font-size: 17px;
      }

      @include r(1500) {
        font-size: 16px;
      }
      @include r(550) {
        font-size: 15px;
      }
      @include r(430) {
        margin: 10px 0 0 0;
      }

      &:hover {
        text-decoration: none;
        transition: 0.3s;
      }
    }

    &__field {
      display: block;

      & > label {
        color: #a3a3a3;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
        display: inline-block;
        margin: 0 0 18px 30px;

        @include r(1700) {
          font-size: 18px;
          margin: 0 0 14px 20px;
        }

        @include r(1500) {
          font-size: 17px;
          margin: 0 0 14px 14px;
        }
        @include r(600) {
          margin: 0 0 4px 0px;
        }
        @include r(550) {
          font-size: 15px;
        }
      }
    }
  }
</style>
