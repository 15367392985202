<template>
  <div>
    <template v-if="!calendarEvent">
      <v-btn
              class="ds-button-tall ml-3 mt-0 mb-2" depressed
              color="primary"
              @click="save"
              :disabled="!isCanSaveEvent"
      >
        <span>Save</span>
      </v-btn>
    </template>
    <template v-else>
      <v-btn
              class="ds-button-tall ml-3 mt-0 mb-2" depressed
              color="primary"
              @click="update"
              :disabled="!isCanSaveEvent"
      >
        <span>Update</span>
      </v-btn>
    </template>
  </div>
</template>
<script>

  import { Day, Schedule, Calendar, CalendarEvent, Functions as fn } from 'dayspan';

  export default {
    name: "customEventPopupButtons",
    props:{
      schedule:
        {
          required: true,
          type: Schedule
        },

      calendarEvent:
        {
          type: CalendarEvent,
          default() {
            return CalendarEvent;
          }
        },

      calendar:
        {
          required: true,
          type: Calendar
        },
      details:{
        required: true,
      },
      day:{
        required: true,
        type: Day
      },
      isCanSaveEvent:
        {
          required: true,
        }

    },
    data(){
      return{

      }
    },
    computed: {
      event() {
        if (this.calendarEvent) {
          return this.calendarEvent.event;
        } else {
          return null;
        }
      }
    },
    methods: {
      save() {
        let ev = this.getEvent('save');
        this.$emit('custom-save', ev);
      },
      update() {
        let ev = this.getEvent('save');
        this.$emit('custom-update', ev);
      },
      getEvent(type, extra = {}) {
        return fn.extend({
          type: type,
          day: this.day,
          schedule: this.schedule,
          calendar: this.calendar,
          calendarEvent: this.calendarEvent,
          event: this.event,
          details: this.details,
          handled: false,
          refresh: true,
          $vm: this,
          $element: this.$el

        }, extra);
      }
    }
  }
</script>

<style scoped>

</style>