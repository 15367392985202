export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const REFRESH_OR_RESET_AUTH_VARIABLES = 'REFRESH_OR_RESET_AUTH_VARIABLES';
export const CHECK_AUTH_AND_SHOW_LOGIN_FORM = 'CHECK_AUTH_AND_SHOW_LOGIN_FORM';

export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';
export const REFRESH_SEARCH_PARAMS = 'REFRESH_SEARCH_PARAMS';
export const RESET_SEARCH_PARAMS = 'RESET_SEARCH_PARAMS';

export const ADD_PRACTITIONER_TO_STATE = 'ADD_PRACTITIONER_TO_STATE';

export const CREATE_BOOKING = 'CREATE_BOOKING';
export const GET_BOOKING = 'GET_BOOKING';

export const GET_CUSTOMER = 'GET_CUSTOMER';

export const REFRESH_CURRENT_USER_DATA = 'REFRESH_CURRENT_USER_DATA';

export const RESET_CURRENT_USER_DATA = 'RESET_CURRENT_USER_DATA';