let environment = process.env.NODE_ENV;

export const APP_ENV = environment;

let API_HOST = 'https://api.freelywellness.com';
switch (environment) {
    case 'development':
        API_HOST = 'http://localhost:8090';
        break;
    case 'staging':
        API_HOST = 'https://apistaging.freelywellness.com';
        break;
    default:
}

export const API_URL = `${API_HOST}/api/v1.0`;

export const DEV_USER_TOKEN = '';

export const GOOGLE_API_KEY = 'AIzaSyB4HxIk3QfRtIlHGf2D4l5R7vq7pVH5tMQ';

/**
 * session time in seconds (15 minutes)
 */
export const SESSION_TIME = 3600;

/**
 * commission for customer
 */
export const CUSTOMER_FEES = 0.1;

/**
 * Stripe key
 * @type {string}
 */
export const STRIPE_PUBLIC_KEY = environment === 'production' ? 'pk_live_qRwCIO4v59YKG3sWfd3eiyYH' : 'pk_test_zI2jKrgNjCEJLiGNOr5IxR3K';
