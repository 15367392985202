import 'babel-polyfill'

import Vue from 'vue'

import 'normalize.css'

import Vuetify from 'vuetify'
import DaySpanVuetify from 'dayspan-vuetify'
import 'vuetify/dist/vuetify.min.css'

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import StarRating from 'vue-star-rating'
Vue.component('star-rating', StarRating)

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

import L from 'leaflet'
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// import component and stylesheet
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

// see docs for available options
const datepickerOptions = {}

// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, datepickerOptions)

import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: `${CONFIG.GOOGLE_API_KEY}`,
})

import * as CONFIG from "@/config.js"

import App from './App.vue'

import router from '@/router/index'
import store from '@/store/index'

Vue.use(Vuetify, {
  iconfont: 'md'
})

Vue.use(DaySpanVuetify, {
  data:{
    supports: {
      title:          true,
      description:    false,
      color:          false,
      location:       false,
      calendar:       false,
      busy:           false,
      icon:           false,
      guests:         false
    },
    features: {
      exclude:        false,
      include:        false,
      cancel:         false,
      move:           false,
      moveDuplicate:  false,
      moveInstance:   false,
      moveAll:        false,
      drag:           true,
      forecast:       false,
      addDay:         true,
      addTime:        true,
      hideOnMove:     true
    },
    prompt: {
      actionRemove:       true,
      actionExclude:      false,
      actionCancel:       false,
      actionUncancel:     false,
      actionMove:         false,
      actionInclude:      false,
      actionSetStart:     false,
      actionSetEnd:       false,
      move:               false,
      toggleAllDay:       false,
      removeExistingTime: false
    },
  },
  methods: {
    getDefaultEventColor: () => '#1976d2',
    setEventDetails(details, data, event, calendarEvent) {
      event.data = Vue.util.extend(data, details, event, calendarEvent);
    },
    getStyleNow() {
      return {
        display: 'none'
      }
    }
  }
});

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

import i18n from './bootstrap/initI18Next';

Vue.config.productionTip = false;

import LightBox from 'vue-image-lightbox';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
Vue.component('light-box', LightBox);

const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
});

app.$mount('#app');
