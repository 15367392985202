<template>
  <div class="dashboard-content dashboard-location" id="dashboard-content">
    <div class="dashboard-content__title">
      <h2>
        {{$t('practitioner_profile.locations_tab_title')}}
      </h2>
      <p>
        {{$t('practitioner_profile.locations_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">
      <div v-if="showProgress">
        <v-progress-linear
          indeterminate
          color="error"
          height="3"
        ></v-progress-linear>
      </div>

      <div
        v-else
        class="dashboard-location__container"
      >
        <div class="v-swith-main">
          <div class="v-swith-main__label">
            {{$t('practitioner_profile.locations_tab_mobile')}}
          </div>
          <toggle-button
            :labels="{checked: 'Yes', unchecked: 'No'}"
            :color="{checked: '#6ACE63', unchecked: '#FF5A5F'}"
            :width="90"
            :height="32"
            v-model="mobile.show"
            @input="chageSwitchMobile"
          />
        </div>

        <transition name="fade">
          <div v-if="mobile.show">
            <div
              v-if="!mobile.isEdit"
              class="dashboard-location__description"
            >
              <div class="dashboard-location__description-lside">
                <div
                  class="dashboard-location__edit"
                  @click="mobile.isEdit = !mobile.isEdit"
                >
                  <span>{{ mobile.address.address }}</span>
                  <span>{{ mobile.address.city }}</span>
                  <span>{{ mobile.address.postcode }}</span>
                </div>
              </div>
              <div class="dashboard-location__description-rside">
                <l-map
                  ref="map"
                  :zoom="zoom"
                  :center="mobile.address.location.center"
                  :options="{ zoomControl: false }"
                  class="map"
                >
                  <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                  <l-tile-layer
                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                  />
                  <l-marker
                    :lat-lng="mobile.address.location.center"
                    :interactive="false"
                  />
                </l-map>
                <span>
                   {{$t('practitioner_profile.locations_tab_locate_on_map')}}
                </span>
              </div>
            </div>

            <template v-if="mobile.isEdit">
              <v-form
                ref="formMobile"
                v-model="formMobileValid"
                lazy-validation
              >
                <vuetify-google-autocomplete
                  id="mobileAddress"
                  :label="$t('practitioner_profile.locations_tab_search_address')"
                  append-icon="search"
                  country="GB"
                  clearable
                  v-on:placechanged="getMobileAddressData"
                  v-on:no-results-found="noAddressFound"
                  types="geocode"
                ></vuetify-google-autocomplete>

                <v-text-field
                  name="mobileAddress"
                  :label="$t('practitioner_profile.locations_tab_house_flat_street')"
                  :rules="addressRules"
                  v-model="mobile.address.address"
                  required
                ></v-text-field>

                <v-text-field
                  name="mobileCity"
                  :label="$t('practitioner_profile.locations_tab_town')"
                  :rules="townRules"
                  v-model="mobile.address.city"
                  required
                  :disabled="true"
                ></v-text-field>

                <v-text-field
                  name="mobilePostcode"
                  :label="$t('practitioner_profile.locations_tab_postcode')"
                  :rules="postcodeRules"
                  v-model="mobile.address.postcode"
                  required
                ></v-text-field>
              </v-form>
            </template>

            <div class="dashboard-location__fields">
              <p class="dashboard-location__title">
                {{$t('practitioner_profile.locations_tab_travel_from_your_home')}}
              </p>

              <v-select
                :items="distancesItems"
                item-text="text"
                item-value="value"
                v-model="mobileDistance"
                solo
              ></v-select>

              <v-form
                ref="formCustomDistance"
                class="custom-distance"
                v-if="mobileDistance == 9999"
                lazy-validation
              >
                <v-text-field
                  :label="$t('practitioner_profile.locations_tab_custom_distance')"
                  suffix="miles"
                  min="1"
                  max="30"
                  step="1"
                  type="number"
                  v-model="customDistance"
                  :rules="distanceRules"
                  required
                ></v-text-field>
              </v-form>
            </div>

            <div class="dashboard-location__holder">
              <div class="dashboard-location__title dashboard-location__title--mt">
                {{$t('practitioner_profile.locations_tab_call_out_charge')}}
              </div>
              <div class="dashboard-location__inner">
                <div class="dashboard-location__currency">
                  £
                </div>
                <div class="dashboard-location__field">
                  <v-form
                    ref="callOutChargeForm"
                    v-model="isCallOutChargeFormValid"
                    lazy-validation
                  >
                    <v-text-field
                      type="number"
                      step=0.01
                      :error-messages="call_out_chargeErrorMess"
                      v-model="call_out_charge"
                      @change="storeCallOutCharge"
                      solo
                      class="solo-field-white-radius"
                    ></v-text-field>
                  </v-form>
                </div>
              </div>
            </div>

            <template v-if="mobile.isEdit">
              <div class="dashboard-location__fields-action">
                <v-btn
                  small
                  color="error lighten-1"
                  :disabled="!mobile.address.city && !mobile.address.address && !mobile.address.postcode"
                  @click="updateMobileLocationAddress()"
                >
                  <template v-if="!mobile.available">
                    {{$t('practitioner_profile.locations_tab_add_location')}}
                  </template>
                  <template v-else>
                    {{$t('practitioner_profile.locations_tab_update_location')}}
                  </template>
                </v-btn>
              </div>
            </template>

          </div>
        </transition>

        <br>
        <br>

        <div class="v-swith-main">
          <div class="v-swith-main__label">
            {{$t('practitioner_profile.locations_tab_salon')}}
          </div>
          <toggle-button
            :labels="{checked: 'Yes', unchecked: 'No'}"
            :color="{checked: '#6ACE63', unchecked: '#FF5A5F'}"
            :width="90"
            :height="32"
            v-model="salon.show"
            @input="chageSwitchSalon"
          />
        </div>

        <transition name="fade">
          <div v-if="salon.show">
            <div
              v-if="!salon.isEdit"
              class="dashboard-location__description"
            >
              <div class="dashboard-location__description-lside">
                <div
                  class="dashboard-location__edit"
                  @click="salon.isEdit = !salon.isEdit"
                >
                  <span><b>{{ salon.name }}</b></span>
                  <span>{{ salon.address.address }}</span>
                  <span>{{ salon.address.city }}</span>
                  <span>{{ salon.address.postcode }}</span>
                </div>
              </div>
              <div class="dashboard-location__description-rside">
                <l-map
                  ref="map"
                  :zoom="zoom"
                  :center="salon.address.location.center"
                  :options="{ zoomControl: false }"
                  class="map"
                >
                  <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                  <l-tile-layer
                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                  />
                  <l-marker
                    :lat-lng="salon.address.location.center"
                    :interactive="false"
                  />
                </l-map>
                <span>
                  {{$t('practitioner_profile.locations_tab_locate_on_map')}}
                </span>
              </div>
            </div>

            <div
              v-else
              class="dashboard-location__fields"
            >
              <v-form
                ref="formSalon"
                v-model="formSalonValid"
                lazy-validation
              >
                <v-text-field
                  name="salonName"
                  :label="$t('practitioner_profile.locations_tab_salon_name')"
                  v-model="salon.name"
                ></v-text-field>

                <vuetify-google-autocomplete
                  id="salonAddress"
                  :label="$t('practitioner_profile.locations_tab_search_address')"
                  append-icon="search"
                  country="GB"
                  clearable
                  v-on:placechanged="getSalonAddressData"
                  v-on:no-results-found="noAddressFound"
                  types="geocode"
                ></vuetify-google-autocomplete>

                <v-text-field
                  name="salonAddress"
                  :label="$t('practitioner_profile.locations_tab_building_number_street')"
                  :rules="addressRules"
                  v-model="salon.address.address"
                  required
                ></v-text-field>

                <v-text-field
                  name="salonTown"
                  :label="$t('practitioner_profile.locations_tab_town')"
                  :rules="townRules"
                  v-model="salon.address.city"
                  required
                  :disabled="true"
                ></v-text-field>

                <v-text-field
                  name="salonPostcode"
                  :label="$t('practitioner_profile.locations_tab_postcode')"
                  :rules="postcodeRules"
                  v-model="salon.address.postcode"
                  required
                ></v-text-field>
              </v-form>
              <div class="dashboard-location__fields-action">
                <v-btn
                  small
                  color="error lighten-1"
                  :disabled="!salon.address.city && !salon.address.address && !salon.address.postcode"
                  @click="updateSalonLocationAddress()"
                >
                  <template v-if="!salon.available">
                    {{$t('practitioner_profile.locations_tab_add_location')}}
                  </template>
                  <template v-else>
                    {{$t('practitioner_profile.locations_tab_update_location')}}
                  </template>
                </v-btn>
              </div>
            </div>

          </div>
        </transition>

      </div>
    </div>

  </div>
</template>

<script>
  import axios from "axios"
  import _ from 'lodash'
  import {empty} from '@/helpers.js';
  import * as CONFIG from "@/config.js"

  import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
  import 'leaflet/dist/leaflet.css';

  /* eslint-disable */

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Practitioner Locations'
    },
    components: {
      LMap,
      LTileLayer,
      LMarker
    },
    data() {
      return {
        showProgress: true,

        mobile: {
          available: false,
          show: false,
          isEdit: false,
          address: {
            address: "",
            city: "",
            postcode: "",
            country_id: "",
            country_name: "",
            location: {
              lat: null,
              lng: null,
              center: {}
            }
          }
        },
        salon: {
          available: false,
          show: false,
          isEdit: false,
          name: "",
          address: {
            address: "",
            city: "",
            postcode: "",
            country_id: "",
            country_name: "",
            location: {
              lat: null,
              lng: null,
              center: {}
            }
          }
        },

        addressRules: [v => !!v || this.$i18n.t('practitioner_profile.locations_tab_js_error_first_address_required')],
        townRules: [v => !!v || this.$i18n.t('practitioner_profile.locations_tab_js_error_first_town_required')],
        postcodeRules: [v => !!v || this.$i18n.t('practitioner_profile.locations_tab_js_error_first_postcode_required')],

        zoom: 13,
        googleLayerOptions: {
          type: 'roadmap'
        },

        formMobileValid: false,
        formSalonValid: false,

        distancesItems: [
          {text: '1 mile', value: 1},
          {text: '2 miles', value: 2},
          {text: '3 miles', value: 3},
          {text: '4 miles', value: 4},
          {text: '5 miles', value: 5},
          {text: '6 miles', value: 6},
          {text: 'Custom', value: 9999}
        ],
        mobileDistance: 1,
        customDistance: 1,
        distanceRules: [
          v => !!v || this.$i18n.t('practitioner_profile.locations_tab_js_error_first_distance_required'),
          v => /^\d+$/.test(v) || 'The distance to travel must be an integer.',
        ],

        countryId: 'UK',
        countryName: 'United Kingdom',

        call_out_charge: '',
        call_out_chargeErrorMess: [],
        isCallOutChargeFormValid: true,

        firstLoadingCustomDistance: true,
        firstLoadingMobileDistance: true,
      };
    },
    mounted() {
      this.getPractitionerLocations()
        .then((response)=>{
          this.initializeAddressForm(response.data);
          this.setWatch();
        })
        .catch((error) => {
          if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
          }
        });

      this.getPractitionerProfileSettings()
        .then((practitioner) => {
          this.call_out_charge = practitioner.data.call_out_charge;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    methods: {
      setWatch() {
        this.$watch('mobileDistance', (value) => this.watchForMobileDistance(value));
        this.$watch('customDistance', () => this.watchForCustomDistance());
      },
      watchForMobileDistance: _.debounce(function (value) {
        if(value !== 9999){
          if(!this.mobile.isEdit){
            this.updatePractitionerLocations();
          }
        }
      }, 500),
      watchForCustomDistance: _.debounce(function () {
        if (this.$refs.formCustomDistance.validate()) {
          let customDistance;
          if (empty(this.customDistance)) {
            customDistance = '';
          } else {
            customDistance = Number(this.customDistance);
          }

          if (customDistance >= 1 && customDistance <= 30) {
            if(!this.mobile.isEdit) {
              this.updatePractitionerLocations();
            }
          } else if (customDistance > 30 || customDistance === 0) {
            this.$store.commit('SET_NOTIFICATION', {
              text: this.$i18n.t('practitioner_profile.locations_tab_notif_enter_mobile_distance'),
              color: 'orange'
            });
          }
        }
      }, 500),
      initializeAddressForm(data) {
        if (data.mobile) {
          this.mobile.available = true;
          this.mobile.show = !data.mobile.disabled;

          this.mobile.address.city = data.mobile.address.city;
          this.mobile.address.address = data.mobile.address.address;
          this.mobile.address.postcode = data.mobile.address.postcode;
          this.mobile.address.country_id = data.mobile.address.country_id;
          this.mobile.address.country_name = data.mobile.address.country_name;
          this.mobile.address.location.lat = data.mobile.address.location.lat;
          this.mobile.address.location.lng = data.mobile.address.location.lng;
          this.mobile.address.location.center = L.latLng(this.mobile.address.location.lat, this.mobile.address.location.lng);

          if (data.mobile.distance_to_travel > 6) {
            this.mobileDistance = 9999;
            this.customDistance = data.mobile.distance_to_travel;
          } else {
            this.mobile.distance_to_travel = data.mobile.distance_to_travel;
            this.mobileDistance = this.mobile.distance_to_travel;
          }
        } else {
          this.mobile.isEdit = true;
        }
        ;

        if (data.salon) {
          this.salon.available = true;
          this.salon.show = !data.salon.disabled;

          this.salon.name = data.salon.name;
          this.salon.address.city = data.salon.address.city;
          this.salon.address.address = data.salon.address.address;
          this.salon.address.postcode = data.salon.address.postcode;
          this.salon.address.country_id = data.salon.address.country_id;
          this.salon.address.country_name = data.salon.address.country_name;
          this.salon.address.location.lat = data.salon.address.location.lat;
          this.salon.address.location.lng = data.salon.address.location.lng;
          this.salon.address.location.center = L.latLng(this.salon.address.location.lat, this.salon.address.location.lng);
        } else {
          this.salon.isEdit = true;
        }
        ;

        this.showProgress = false;
      },
      chageSwitchMobile: _.debounce(function () {
        if (this.mobile.available) {
          this.updatePractitionerLocations();
        }
      }, 500),
      chageSwitchSalon: _.debounce(function () {
        if (this.salon.available) {
          this.updatePractitionerLocations();
        }
      }, 500),
      updateMobileLocationAddress() {
        this.mobile.isEdit = !this.mobile.isEdit;

        if (this.mobile.available) {
          this.updatePractitionerLocations();
        } else {
          this.addPractitionerLocations('mobile');
        }
      },
      updateSalonLocationAddress() {
        this.salon.isEdit = !this.salon.isEdit;

        if (this.salon.available) {
          this.updatePractitionerLocations();
        } else {
          this.addPractitionerLocations('salon');
        }
      },
      getMobileAddressData: function (addressData, placeResultData) {
        if (addressData) {
          if (addressData.locality) {
            this.mobile.address.city = addressData.locality;
          }
          if (addressData.name && (addressData.name != addressData.postal_code)) {
            this.mobile.address.address = addressData.name;
          } else {
            this.mobile.address.address = '';
          }
          if (addressData.postal_code) {
            this.mobile.address.postcode = addressData.postal_code;
          }
          if (addressData.latitude && addressData.longitude) {
            this.mobile.address.location.lat = addressData.latitude;
            this.mobile.address.location.lng = addressData.longitude;
            this.mobile.address.location.center = L.latLng(this.mobile.address.location.lat, this.mobile.address.location.lng);
          }

          const address_components = placeResultData.address_components;

          for (var i = 0; i < address_components.length; i++) {
            if (address_components[i].types[0] === 'postal_town') {
              this.mobile.address.city = address_components[i].long_name;
            }
          }
        } else {
          this.mobile.address.city = null;
          this.mobile.address.address = null;
          this.mobile.address.postcode = null;
        }
      },
      getSalonAddressData: function (addressData, placeResultData) {
        if (addressData) {
          if (addressData.locality) {
            this.salon.address.city = addressData.locality;
          }
          if (addressData.name && (addressData.name != addressData.postal_code)) {
            this.salon.address.address = addressData.name;
          } else {
            this.salon.address.address = '';
          }
          if (addressData.postal_code) {
            this.salon.address.postcode = addressData.postal_code;
          }
          if (addressData.latitude && addressData.longitude) {
            this.salon.address.location.lat = addressData.latitude;
            this.salon.address.location.lng = addressData.longitude;
            this.salon.address.location.center = L.latLng(this.salon.address.location.lat, this.salon.address.location.lng);
          }

          const address_components = placeResultData.address_components;

          for (var i = 0; i < address_components.length; i++) {
            if (address_components[i].types[0] === 'postal_town') {
              this.salon.address.city = address_components[i].long_name;
            }
          }
          ;
        } else {
          this.salon.address.city = null;
          this.salon.address.address = null;
          this.salon.address.postcode = null;
        }
      },
      noAddressFound() {
        this.$store.commit('SET_NOTIFICATION', {
          text: this.$i18n.t('practitioner_profile.locations_tab_notif_address_not_found'),
          color: 'orange'
        });
      },
      getPractitionerLocations() {
        return axios({
          method: "get",
          url: `${CONFIG.API_URL}/dashboard/practitioner/locationtypes`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        });
      },
      addPractitionerLocations(type) {
        let locations_data = {};

        if (type === 'mobile') {
          locations_data["1"] = {
            distance: (this.mobileDistance === 9999) ? this.customDistance : this.mobileDistance,
            home_address: {
              address: this.mobile.address.address,
              city: this.mobile.address.city,
              postcode: this.mobile.address.postcode,
              country_id: this.countryId,
              country_name: this.countryName,
              home_location: {
                lat: this.mobile.address.location.lat,
                lng: this.mobile.address.location.lng
              }
            }
          };
        }
        ;

        if (type === 'salon') {
          locations_data["2"] = [{
            name: this.salon.name ? this.salon.name : '',
            address: this.salon.address.address,
            city: this.salon.address.city,
            postcode: this.salon.address.postcode,
            country_id: this.countryId,
            country_name: this.countryName,
            salon_location: {
              lat: this.salon.address.location.lat,
              lng: this.salon.address.location.lng
            }
          }];
        }
        ;

        axios({
          method: "post",
          url: `${CONFIG.API_URL}/practitioner/locationtypes`,
          data: JSON.stringify(locations_data),
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(response => {
              const salon_id = response.data.salon_id;
              this.addLocationAvailability(type, salon_id)
            },
            error => {
              console.log(error.response.data);
              throw error;
            }
          )
          .catch(error => {
            console.log(error.response.data);

            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            throw error;
          });
      },
      updatePractitionerLocations() {
        let result_data = {};

        if (this.mobile.available) {
          result_data.mobile = {
            disabled: !this.mobile.show,
            distance_to_travel: (this.mobileDistance === 9999) ? this.customDistance : this.mobileDistance,
            address: {
              city: this.mobile.address.city,
              postcode: this.mobile.address.postcode,
              address: this.mobile.address.address,
              country_id: this.countryId,
              country_name: this.countryName,
              location: {
                lat: this.mobile.address.location.lat,
                lng: this.mobile.address.location.lng
              }
            }
          }
        }
        ;

        if (this.salon.available) {
          result_data.salon = {
            disabled: !this.salon.show,
            name: (this.salon.name) ? this.salon.name : '',
            address: {
              city: this.salon.address.city,
              postcode: this.salon.address.postcode,
              address: this.salon.address.address,
              country_id: this.countryId,
              country_name: this.countryName,
              location: {
                lat: this.salon.address.location.lat,
                lng: this.salon.address.location.lng
              }
            }
          }
        }
        ;

        axios({
          method: "put",
          url: `${CONFIG.API_URL}/dashboard/practitioner/locationtypes`,
          data: JSON.stringify(result_data),
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(
            response => {
              this.$store.commit('SET_NOTIFICATION', {
                text: response.data.message,
                color: 'error'
              });
            },
            error => {
              console.log(error.response.data);
              throw error;
            }
          )
          .catch(error => {
            console.log(error.response.data);

            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            throw error;
          });
      },
      addLocationAvailability(type, salon_id) {
        let availability = {};

        if (type === 'mobile') {
          availability['1'] = {
            sunday: {},
            monday: {},
            tuesday: {},
            wednesday: {},
            thursday: {},
            friday: {},
            saturday: {}
          };
        }
        ;

        if (type === 'salon') {
          availability['2'] = {};
          availability['2'][salon_id] = {
            sunday: {},
            monday: {},
            tuesday: {},
            wednesday: {},
            thursday: {},
            friday: {},
            saturday: {}
          };
        }
        ;

        axios({
          method: "post",
          url: `${CONFIG.API_URL}/practitioner/availability`,
          data: JSON.stringify(availability),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${this.$store.getters.userToken || CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(
            response => {
              if (type === 'mobile') {
                this.mobile.available = true;
              }
              if (type === 'salon') {
                this.salon.available = true;
              }

              this.$store.commit('SET_NOTIFICATION', {
                text: response.data.message,
                color: 'error'
              });
            },
            error => {
              console.log(error.response.data);
              throw error;
            }
          )
          .catch(error => {
            console.log(error.response.data);

            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            throw error;
          });
      },

      getPractitionerProfileSettings() {
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/practitioner/profile`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      showNotification() {
        this.$store.commit('CLEAR_NOTIFICATION');
        const that = this;
        setTimeout((that) => {
          that.$store.commit('SET_NOTIFICATION', {
            text: this.$i18n.t('practitioner_profile.locations_tab_notif_settings_updated'),
            color: 'error'
          });
        }, 200, that);
      },
      storeCallOutCharge(call_out_charge) {
        if(!this.mobile.isEdit){
          this.call_out_chargeErrorMess = [];
          this.validateCallOutCharge(call_out_charge);
          if (empty(this.call_out_chargeErrorMess)) {
            this.updateProfileSettings({'call_out_charge': call_out_charge})
              .then(() => {
                this.formatCallOutCharge();
                this.showNotification();
              })
              .catch((error) => {
                console.log(error)
                if (!empty(error.response.data.errors)) {
                  this.fieldsErrorFromServer(error.response.data.errors);
                  this.formatCallOutCharge();
                }
              });
            this.formatCallOutCharge();
          }
        }
      },
      validateCallOutCharge(call_out_charge) {
        if (empty(call_out_charge)) {
          this.call_out_chargeErrorMess.push(this.$i18n.t('practitioner_profile.locations_tab_js_error_first_call_out_charge_required'));
          this.formatCallOutCharge();
        }
      },
      formatCallOutCharge() {
        if (!empty(this.call_out_charge)) {
          this.call_out_charge = (Number(this.call_out_charge)).toFixed(2);
        }
      },
      updateProfileSettings(data) {
        return axios({
          method: 'put',
          url: `${CONFIG.API_URL}/dashboard/practitioner/profile`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          data: JSON.stringify(data)
        });
      },
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .dashboard-location {
    .map {
      width: 100%;
      height: 136px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
    }
    &__container {
      max-width: 570px;

      @include r(1000) {
        margin: 0 auto;
      }

      @include r(700) {
        max-width: 100%;
      }

      .locations-switch {
        .v-input__slot .v-label {
          color: #383838;
          font-family: Nunito;
          font-size: 27px;
          font-weight: 700;

          @include r(1700) {
            font-size: 25px;
          }
          @include r(1500) {
            font-size: 22px;
          }
          @include r(1000) {
            font-size: 20px;
          }
        }
      }
    }

    &__inner {
      display: flex;
      align-items: center;
    }

    &__currency {
      flex: 0 0 auto;
      color: #535353;
      font-family: Nunito;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-right: 10px;
      padding-bottom: 8px;

      @include r(1500) {
        font-size: 21px;
        margin-right: 20px;
      }
    }

    &__field {
      flex: 1 1 auto;
    }

    .v-swith-main {
      &__label {
        min-width: 83px;
        @include r(1500) {
          min-width: 70px;
        }
        @include r(700) {
          min-width: 50px;
        }
      }
    }

    &__fields-action {
      button {
        margin: 0;
        min-width: 203px;
        height: 38px;
        border-radius: 4px;
        opacity: 1;
      }
    }

    &__title {
      display: block;
      color: #535353;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      margin-top: 30px;

      &--mt {
        margin-top: 0;
      }

      @include r(1000) {
        font-size: 18px;
      }

      @include r(700) {
        font-size: 17px;
      }
      @include r(500) {
        font-size: 15px;
      }
    }

    &__description {
      display: flex;
      max-width: 450px;
      margin-top: 60px;

      @include r(1500) {
        margin-top: 40px;
      }
      @include r(1000) {
        max-width: 100%;
      }

      @include r(700) {
        margin-top: 26px;
      }

      &-lside {
        flex: 0 0 215px;
        padding-right: 20px;

        @include r(1000) {
          flex: 0 0 180px;
        }
        @include r(500) {
          flex: 0 0 150px;
        }
      }

      &-rside {
        flex: 1 1 auto;
        position: relative;
        z-index: 1;

        span {
          display: block;
          text-align: right;
          color: #a3a3a3;
          font-family: Nunito;
          font-size: 16px;
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }

    &__edit {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      color: #535353;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
      line-height: 1.1;
      transition: .3s;

      @include r(1500) {
        font-size: 18px;
      }

      @include r(1000) {
        font-size: 17px;
      }

      @include r(500) {
        font-size: 15px;
      }

      &:hover {
        opacity: .7;
        transition: .3s;
      }

      span {
        display: block;
      }

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 4px;
        width: 17px;
        height: 18px;
        background: {
          image: url("../.././images/icons/icon-edit.svg");
          repeat: no-repeat;
          size: contain;
        }
      }
    }
  }
</style>
