<template>
  <div>
    <v-form
      ref="formSearch"
      lazy-validation
    >
      <vuetify-google-autocomplete
        ref="address"
        id="search-address"
        placeholder=""
        :label="$t('services_search.label_enter_your_address')"
        append-icon="search"
        country="GB"
        clearable
        clear-icon="clear"
        v-on:blur="noResultsFound"
        v-on:placechanged="getAddressData"
        v-on:no-results-found="noResultsFound"
        types="(regions)"
        :rules="addressRules"
        v-model="selectedSearchAddress"
        solo
        class="solo-field-white"
      ></vuetify-google-autocomplete>

      <v-select
              :items="serviceTypes"
              v-model="serviceType"
              :rules="serviceTypesRules"
              item-text="text"
              item-value="value"
              solo
              :label="$t('services_search.find_a_service')"
              color="error"
              light
              required
              class="solo-field-white-radius"

      ></v-select>
    </v-form>

    <v-btn
      block
      class="error"
      @click="search"
    >
      {{$t('services_search.search')}}
    </v-btn>
  </div>
</template>

<script>
// import axios from "axios";
// import * as CONFIG from "@/config.js";
import { empty } from '@/helpers.js';

/* eslint-disable */

export default {
  data () {
    return {
      searchTown: '',
      searchAddress: '',
      searchPostcode: '',
      searchLatitude: null,
      searchLongitude: null,

      selectedSearchAddress: '',

      services: [],
      toggleServices: [],
      filteredServices:[],
      loadingServices: true,

      addressRules: [v => !!v || this.$i18n.t('services_search.js_error_address_is_required')],
      serviceRules: [v => !!v || this.$i18n.t('services_search.js_error_service_is_required')],

      serviceType: '',
      serviceTypes:[
        {text: this.$i18n.t('practitioner_search.service_type_beautician'), value: 'beautician'},
        {text: this.$i18n.t('practitioner_search.service_type_hairdresser'), value: 'hairdresser'},
        {text: this.$i18n.t('practitioner_search.service_type_massage_therapist'), value: 'massage_therapist'},
      ],
      serviceTypesRules:[
        v => !!v || 'Practitioner`s type field is required',
      ],

      selectedSubServiceId: '',
      selectedSubServiceName: '',

      selectedLocationType: '',
    };
  },
  mounted () {
    this.initSearchForm();
    console.log('in component');
  },
  methods: {
    setFilteredServices() {
      if (!empty(this.filteredServices)) {
        this.toggleServices = this.filteredServices;
      }
    },
    checkFilteredServices(ev){
      if(!empty(ev.target.value) && !empty(this.filteredServices)){
        this.toggleServices = this.filteredServices;
      }
    },
    customFilter(queryText, itemText){
      if(!empty(itemText)){
        const name = itemText.toLowerCase();
        const searchText = queryText.toLowerCase();
        return name.indexOf(searchText) > -1;
      }else{
        return false;
      }
    },
    checkToggleServices(ev){
      if(!empty(ev.target.value) && (empty(this.toggleServices) || this.toggleServices.length === this.filteredServices.length)){
        this.toggleServices = this.services;
      }else if(empty(ev.target.value) && !empty(this.toggleServices)){
        this.toggleServices = [];
        this.selectedSubServiceId = '';
      }
      this.filteredServices = [];
      for(let i=0; i<this.services.length; i++){
        if(this.customFilter(ev.target.value, this.services[i].name)){
          this.filteredServices.push(this.services[i]);
        }
      }
    },
    clearToggleServices(){
      this.toggleServices = [];
      this.selectedSubServiceId = '';
      this.filteredServices = [];
    },
    initSearchForm(){

      if (Object.keys(this.$route.query).length !== 0) {
        this.selectedSearchAddress = (!empty(this.$route.query.address)) ? this.$route.query.address : '';
        this.searchTown = (!empty(this.$route.query.town)) ? this.$route.query.town : '';
        this.searchLatitude = (!empty(this.$route.query.lat)) ? this.$route.query.lat : '';
        this.searchLongitude = (!empty(this.$route.query.lon)) ? this.$route.query.lon : '';
        this.selectedLocationType = (!empty(this.$route.query.location)) ? this.$route.query.location : '';
      }
    },
    getAddressData: function (addressData, placeResultData) {
      if (!empty(placeResultData)) {
        this.parseAddress(placeResultData);
        this.$store.dispatch('RESET_SEARCH_PARAMS')
          .then(() => {
            this.$store.dispatch('SET_SEARCH_PARAMS', {
              searchPostcode: this.searchPostcode,
              searchCountryName: 'United Kingdom',
              searchCountryId: 'UK',
              searchAddressComponents: placeResultData.address_components
            });
          });
      }
    },
    parseAddress(placeResultData) {
      if (!empty(placeResultData.name)) {
        this.selectedSearchAddress = placeResultData.name;
        this.searchAddress = placeResultData.name;
      }
      if (!empty(placeResultData.address_components)) {
        for (let i = 0; i < placeResultData.address_components.length; i++) {
          if (placeResultData.address_components[i].types.includes('postal_town')) {
            this.searchTown = placeResultData.address_components[i].long_name
          }
          if(placeResultData.address_components[i].types.includes('locality') && empty(this.searchTown)){
            this.searchTown = placeResultData.address_components[i].long_name
          }
          if (placeResultData.address_components[i].types.includes('postal_code')) {
            this.searchPostcode = placeResultData.address_components[i].long_name
          }
        }

        if (placeResultData.geometry.location) {
          this.searchLatitude = placeResultData.geometry.location.lat().toFixed(7);
          this.searchLongitude = placeResultData.geometry.location.lng().toFixed(7);
        }
      }
    },
    noResultsFound () {
      let placesService = new window.google.maps.places.AutocompleteService();
      let placeService = new window.google.maps.places.PlacesService(document.createElement('div'));
      placesService.getPlacePredictions({input: this.selectedSearchAddress, types: ["(regions)"], componentRestrictions: { country: 'UK'} }, (results, requestStatus) => {
        if(requestStatus === google.maps.places.PlacesServiceStatus.OK) {
          placeService.getDetails({placeId: results[0].place_id}, (place) => {
            this.getAddressData({}, place);
            this.selectedSearchAddress = place.name
            this.searchAddress = place.name;
          });
        }
        else{
          this.selectedSearchAddress = ''
        }
      })

    },
    getQueryObject () {
      const query = {};
      if (!empty(this.selectedSearchAddress)) {
        query['address'] = this.selectedSearchAddress;
      }
      if (!empty(this.searchTown)) {
        query['town'] = this.searchTown;
      }
      if (!empty(this.searchLatitude)) {
        query['lat'] = this.searchLatitude;
      }
      if (!empty(this.searchLongitude)) {
        query['lon'] = this.searchLongitude;
      }
      // if (!empty(this.selectedSubServiceId)) {
      //   query['id'] = this.selectedSubServiceId;
      // }
      // if (!empty(this.selectedSubServiceName)) {
      //   query['service'] = this.selectedSubServiceName;
      // }
      if(!empty(this.serviceType)){
        query['type'] = this.serviceType;
      }
      if (!empty(this.selectedLocationType)) {
        query['location'] = this.selectedLocationType;
      }
      return query;
    },
    search () {
      if (this.$refs.formSearch.validate()) {
        const query = this.getQueryObject();
        this.$router.push({ path: '/practitioner-search', query: query });
      }
    }
  }
};
</script>

<style lang="scss">
    .v-form {
        margin-top: 0;
    }
    .v-text-field.v-text-field--solo .v-label {
      left: 0 !important;
      right: inherit !important;
    }
</style>
