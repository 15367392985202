<template>
  <v-progress-linear
          indeterminate
          color="error"
          height="3"
  ></v-progress-linear>
</template>
<script>
  export default {
    name: "logout",
    data(){
      return{
        showWrapper:true
      }
    },
    beforeRouteUpdate(to, from, next) {
      this.logout();
      next();
    },
    created: function () {
      this.logout();
    },
    methods: {
      logout() {
        this.$store.dispatch('LOGOUT_REQUEST')
          .then(() => {
            this.$store.commit('SET_NOTIFICATION', {
              text: 'Logged out successfully',
              color: 'error'
            });
            this.$router.push('/');
          })
          .catch(() => {
            this.$store.commit('SET_NOTIFICATION', {
              text: 'Logged out successfully',
              color: 'error'
            });
            this.$router.push('/');
          });
      }
    }
  }
</script>

<style scoped></style>