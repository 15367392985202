<template>
  <div>
    <div class="skills-experience">
      <p
        class="title text-xs-center text-sm-center text-md-center skills-experience__title skills-experience__title--xl">
        {{$t('practitioner_registration.step_5_title')}}
      </p>
      <p class="skills-experience__title skills-experience__title skills-experience__title--center">
        {{$t('practitioner_registration.step_5_desc')}}
      </p>
      <p class="skills-experience__title skills-experience__title--bold skills-experience__title--center">
        {{$t('practitioner_registration.step_5_enter_qualifications')}}
      </p>
      <div class="skills-experience__fields skills-experience__fields--mwidth">
        <v-text-field
          :placeholder="$t('practitioner_registration.step_5_start_typing')"
          v-model="newQualification"
          @keyup.enter="addQualification"
          v-on:blur="addQualification"
          class="skills-experience__question"
        ></v-text-field>
        <v-btn
          color="error"
          :disabled="newQualification.length <= 0"
          @click="addQualification"
        >
          {{$t('practitioner_registration.step_5_add')}}
        </v-btn>
        <v-list>
          <v-list-tile
            v-for="item in qualificationList"
            :key="item.id"
          >
            <v-list-tile-action>
              <v-icon>star</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              {{ item.name }}
            </v-list-tile-content>
            <button @click="removeQualification(item)">
              <v-icon>clear</v-icon>
            </button>
          </v-list-tile>
        </v-list>
      </div>

      <p
        class="skills-experience__title skills-experience__title--bold skills-experience__title--mt skills-experience__title--center">
        {{$t('practitioner_registration.step_5_professional_experience')}}
      </p>

      <div class="skills-experience__fields">
        <v-form
          ref="formExperience"
          lazy-validation
        >
          <div class="skills-experience__field skills-experience__fields--mwidth">
            <v-menu
              v-model="experienceMenu"
              :close-on-content-click="false"
              full-width
              :nudge-right="40"
            >
              <v-text-field
                slot="activator"
                :value="formattedExperienceDate"
                prepend-icon="event"
                :label="$t('practitioner_registration.step_5_started_working')"
                :rules="experienceRules"
                readonly
              ></v-text-field>
              <v-date-picker
                v-model="experienceStartDate"
                @change="experienceMenu = false"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                type="month"
                landscape
                color="error lighten-1"
              ></v-date-picker>
            </v-menu>
          </div>

          <p class="skills-experience__title skills-experience__title--bold skills-experience__title--mt skills-experience__title--center">
            {{$t('practitioner_registration.step_5_images_showcasing_your_work')}}
          </p>
          <div class="photos__multiple">
            <multiple-upload-component
                    @amount-uploaded-images="setImagesAmount"
            ></multiple-upload-component>
            <div class="checkout__form-field checkout__form-field--payment-hide">
              <v-form
                      ref="imagesAmountForm"
                      lazy-validation
              >
                <v-text-field
                        type="text"
                        v-model="imagesAmount"
                        :rules="imagesAmountRules"
                        solo
                ></v-text-field>
              </v-form>
            </div>
          </div>
          <div class="skills-experience__inline">
            <div class="skills-experience__title skills-experience__title--black">
              {{$t('practitioner_registration.step_5_valid_insurance_to_cover_your_work')}}
            </div>
            <v-radio-group
              label=""
              v-model="insurance"
              :rules="insuranceRules"
              :mandatory="false"
              class="checkbox-string-label"
            >
              <div class="checkout__form-fields checkout__form-fields--checkboxes">
                <div class="checkout__form-field">
                  <v-radio
                    label="Yes"
                    value="yes"
                    off-icon="check_box_outline_blank"
                    on-icon="check_box"
                  ></v-radio>
                </div>
                <div class="checkout__form-field">
                  <v-radio
                    label="No"
                    value="no"
                    off-icon="check_box_outline_blank"
                    on-icon="check_box"
                  ></v-radio>
                </div>
              </div>
            </v-radio-group>
          </div>
          <p
            class="skills-experience__title skills-experience__title--bold skills-experience__title--mt skills-experience__title--center">
            {{$t('practitioner_registration.step_5_certify_information_is_correct')}}
          </p>

          <div class="skills-experience__inline">
            <div class="skills-experience__title skills-experience__title--black">
              {{$t('practitioner_registration.step_5_complete_medical_disclaimer')}}
            </div>
            <div class="checkout__form-fields checkout__form-fields--checkboxes">
              <div class="checkout__form-field">
                <v-checkbox
                  label="Yes"
                  v-model="medical_disclaimer_form"
                  :rules="medicalDisclamerRules"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </v-form>
      </div>
      <div class="text-xs-center step-btn-main">
        <v-btn
          color="error"
          :disabled="beingProcessed"
          @click="finishSignUpStep()"
          class="skills-experience__button"
        >
          {{$t('practitioner_registration.step_5_finish')}}
        </v-btn>
      </div>
    </div>


  </div>
</template>

<script>
  import axios from 'axios';
  import * as CONFIG from '@/config.js';
  import moment from 'moment';
  import MultipleUploadComponent from '@/components/Partial/MultipleUploadComponent';
  import {empty} from '@/helpers.js';

  /* eslint-disable */

  export default {
    components: {
      MultipleUploadComponent
    },
    data() {
      return {
        last_qualification_id: 0,
        newQualification: '',
        qualificationList: [],

        experienceStartDate: null,
        experienceMenu: false,
        experienceRules: [v => !!v || this.$i18n.t('auth_forms.step_5_js_error_date_required')],

        insurance: null,
        insuranceRules: [
          v => !!v || this.$i18n.t('auth_forms.step_5_js_error_insurance_required'),
        ],

        medical_disclaimer_form: false,
        medicalDisclamerRules: [
          v => !!v || this.$i18n.t('auth_forms.step_5_js_error_check_to_continue'),
        ],

        imagesAmount: '',
        imagesAmountRules: [
          v => !!v || this.$i18n.t('auth_forms.step_5_js_error_add_image'),
        ],
        beingProcessed: false
      }
    },
    computed: {
      formattedExperienceDate() {
        return this.experienceStartDate ? moment(this.experienceStartDate).format('MMMM, YYYY') : ''
      }
    },
    methods: {
      addQualification() {
        if (this.newQualification.trim() == '') return;

        let qualification = {
          id: ++this.last_qualification_id,
          name: this.newQualification,
        };
        this.qualificationList.push(qualification);
        this.newQualification = '';
      },
      removeQualification(qualification) {
        this.qualificationList.splice(this.qualificationList.indexOf(qualification), 1)
      },
      setImagesAmount(amount){
        if(!empty(amount) && amount > 0){
          this.imagesAmount = amount;
        }else{
          this.imagesAmount = '';
        }
      },
      finishSignUpStep() {
        let qualifications = [];
        this.qualificationList.forEach(function (item) {
          qualifications.push(item.name);
        });

        const formExperience = this.$refs.formExperience.validate();
        const imagesAmountForm = this.$refs.imagesAmountForm.validate();

        if (formExperience && imagesAmountForm) {
          const skills_experience_data = {
            "qualifications": qualifications,
            "start_working_date": `${this.experienceStartDate}-01`,
            "insurance": (this.insurance == 'yes' ? true : false),
            "medical_disclaimer_form": this.medical_disclaimer_form
          };
          this.beingProcessed = true;
          axios({
            method: "post",
            url: `${CONFIG.API_URL}/practitioner/trustAndSafety`,
            data: JSON.stringify(skills_experience_data),
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': `Bearer ${this.$store.getters.userToken || CONFIG.DEV_USER_TOKEN}`
            }
          })
            .then(
              response => {
                this.beingProcessed = false;
                console.log(response);
                this.changeStep(6);
              }
            )
            .catch(error => {
              this.beingProcessed = false;
              console.log(error.response.data);
              throw error;
            });
        }
      },
      changeStep(step) {
        this.$emit('changeStep', step)
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .skills-experience {
    max-width: 678px;
    margin: 0 auto;

    .v-form {
      margin-top: -20px;
    }

    .qualifications-message {
      margin-top: -15px;
    }
    &__question {
      @include r(700) {
        font-size: 15px;
      }
    }
    &__fields {
      display: block;
      position: relative;

      &--mwidth {
        max-width: 500px;
        margin: 0 auto;
      }

      button {
        position: absolute;
        margin: 0;
        top: 12px;
        right: 10px;
        border-radius: 10px;
        font-size: 13px;
        font-weight: 400;
        height: 28px;
        min-width: 78px;

        @include r(700) {
          right: 0;
          min-width: 60px;
        }

        &:hover {
          position: absolute;
        }
      }
    }

    &__inline {
      display: flex;
      align-items: center;
      justify-content: center;

      .v-input {
        margin-top: 13px !important;
        padding: 0;
        flex: 0 0 auto;
      }
      .checkout__form-fields--checkboxes {
        padding-left: 0;
      }

      .checkout__form-fields {
        margin: 0;
      }
      .v-input__slot {
        margin-bottom: 0 !important;
      }
      .checkout__form-fields .checkout__form-field {
        min-height: auto;
      }
      .v-input__slot .v-label {
        min-width: 100% !important;
      }
      .checkbox-string-label .checkout__form-fields .v-label {
        min-width: 100% !important;
        position: relative;
        font-size: 17px;
        padding-left: 8px;
        position: relative;
        top: 2px;
      }
      .checkout__form-fields--checkboxes .v-input--selection-controls__input {
        height: auto;
      }
    }

    &__title {
      display: block;
      color: #898989;
      font-size: 20px;
      font-weight: 400;

      @include r(1500) {
        font-size: 18px;
      }

      @include r(1000) {
        font-size: 16px;
      }

      &--xl {
        color: #383838;
        font-size: 27px;
        font-weight: 700;
        font-family: Nunito !important;
        margin-bottom: 30px;

        @include r(1500) {
          font-size: 24px;
        }

        @include r(1000) {
          font-size: 22px;
        }
      }
      &--black{
        color: #000
      }
      &--bold {
        display: block;
        color: #535353;
        font-size: 20px;
        font-weight: 700;

        @include r(1500) {
          font-size: 18px;
        }

        @include r(1000) {
          font-size: 16px;
        }
      }

      &--mt {
        margin: 26px 0;
      }

      &--center {
        text-align: center;
      }
    }

    &__button {
      max-width: 470px;
      height: 63px;
    }

    &__switch {
      display: block;

      .v-input__control {
        flex: 0 0 100%;
      }
      .v-input__slot {
        flex: 0 0 100%;
        justify-content: space-between;
      }
    }

  }

  .photos__multiple {
    display: block;
    margin: 30px 0;

    #my-strictly-unique-vue-upload-multiple-image {
      margin: 0;

      & > div {
        width: 100%;
      }
    }

    .position-absolute {
      position: relative;

      &.image-input {
        position: absolute;
      }
    }

    .image-icon-drag {
      display: none;
    }
    .browse-text {
      display: none;
    }

    .image-container {
      width: 100%;
      min-height: 50px;
      height: inherit;
      cursor: pointer;
      background-color: transparent !important;
      border: 1px dashed #D6D6D6 !important;

      .preview-image {
        height: auto;
        max-width: 160px;
        padding: 0;
        margin: 10px auto 0 auto;
        border-radius: 24px;

        @include r(600) {
          border-radius: 10px;
        }
      }

      .image-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        height: auto;
      }

      .show-image {
        top: 0;
        left: 0;
        transform: none;
        position: relative;
        max-width: 160px;
        margin: 0 auto;
        border-radius: 24px;

        @include r(600) {
          border-radius: 10px;
        }
      }

      .image-overlay-details {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        transform: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(#000, .5);
        border-radius: 24px;

        @include r(600) {
          border-radius: 10px;
        }
      }

      .image-delete {
        svg {
          display: none;
        }

        width: 30px;
        height: 30px;
        background: {
          image: url("../../.././src/images/icons/icon-delete-black.svg");
          repeat: no-repeat;
          size: contain;
        }
      }

      .image-edit {
        display: none;
      }

      .show-img {
        max-width: 100%;
        width: 100%;
        max-height: 160px;
        height: 160px;
        border-radius: 24px;

        @include r(600) {
          border-radius: 10px;
        }
      }
      .text-center {
        padding: 10px 0;
      }

      .drag-text {
        color: #707070;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 600;
        padding: 0;
        cursor: pointer;
      }
    }

    .image-list-container {
      display: flex;
      max-width: 100%;
      margin: 20px 0 0 0;

      @include r(480) {
        margin-top: 10px;
      }

      .image-list-item {
        flex: 0 0 calc(20% - 10px);
        margin: 5px !important;
        width: 100%;
        height: 125px;
        overflow: hidden;
        border-radius: 24px;

        @include r(780) {
          height: 100px;
        }

        @include r(600) {
          height: 80px;
          border-radius: 10px;
        }
        @include r(480) {
          height: 60px;
          flex: 0 0 calc(20% - 4px);
          margin: 2px !important;
        }
        @include r(380) {
          height: 56px;
        }
        .add-image-svg {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 30px;
          height: 30px;
          fill: #ff5353;
          margin: -15px 0 0 -15px;

          @include r(600) {
            width: 20px;
            height: 20px;
            margin: -10px 0 0 -10px;
          }
        }

        &.image-highlight {
          border: 2px solid #ff5353;
        }

        .centered {
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          transform: none;
        }

        .show-img {
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
</style>
