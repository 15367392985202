import localStorage from './localstorage';
import cookies from './cookies';

class Repository {

  constructor() {
    this.storage = (localStorage.isAvailableStorage()) ? localStorage : cookies;
  }

  deleteValue(name) {
    this.storage.deleteValue(name);
  }

  saveValue(name, value) {
    this.storage.saveValue(name, value)
  }

  getValue(name) {
    return this.storage.getValue(name)
  }
}

export default new Repository();