<template>
  <div
    class="dashboard-content dashboard-customer"
    id="dashboard-content"
  >
    <div class="dashboard-content__title">
      <h2>{{$t('customer_profile.dashboard_tab_title')}}</h2>
      <BookingCounter></BookingCounter>
    </div>
    <div class="dashboard-content__body">
      <div class="dashboard-customer__title" v-if="lastBooking">
        <p class="p-desk" v-html="$t('customer_profile.dashboard_tab_book_again_block_desk', {practitioner_display_name: lastPractDisplayName, practitioner_first_name: lastPractFirstName})"></p>
        <p class="p-mob" v-html="$t('customer_profile.dashboard_tab_book_again_block_mob', {practitioner_display_name: lastPractDisplayName})"></p>
        <v-btn class="btn error btn-desk" @click="lastBooking && bookAgain(lastBooking.id)">{{$t('customer_profile.dashboard_tab_book_again')}}</v-btn>
        <v-btn class="btn error btn-mob" @click="lastBooking && bookAgain(lastBooking.id)">{{$t('customer_profile.dashboard_tab_book_now')}}</v-btn>
      </div>
      <div class="dashboard-customer__middle">
        <div class="dashboard-customer__book">
        <div class="dashboard-customer__book-items dashboard-customer__book--surprise_block">
          <div class="dashboard-customer__book-item" v-for="(n, index) in numberOfIterations" :key="index">
            <template v-if="surpriseBookings[index]">
              <img
                      v-if="surpriseBookings[index].practitioner_avatar"
                      :src="surpriseBookings[index].practitioner_avatar"
                      :alt="surpriseBookings[index].practitioner_name"
              >
              <v-avatar color="red lighten-1" v-else>
                    <span class="white--text headline"
                    >{{ surpriseBookings[index].avatar_letters }}</span>
              </v-avatar>
            </template>
          </div>
        </div>
        <div
                v-if="numberOfBookings !== 3"
                class="dashboard-customer__book-gift"
        ></div>
      </div>
      </div>
      <div
              v-if="numberOfBookings !== 3 && loadingIsFinished"
              class="dashboard-customer__text"
              v-html="$t('customer_profile.dashboard_tab_book_more_desc', {numberOfBookings: remainingBookings})"
      >
      </div>
      <div
              v-else-if="numberOfBookings == 3 && loadingIsFinished"
              class="dashboard-customer__text"
      >
        {{$t('customer_profile.dashboard_tab_book_more_сongratulations')}}
      </div>
      <v-btn class="error dashboard-customer__search" @click="getRedirect">{{$t('customer_profile.dashboard_tab_search')}}</v-btn>
      <div class="dashboard-cards">
        <div class="dashboard-cards__title">{{$t('customer_profile.dashboard_tab_previous_appointments')}}</div>

        <div class="dashboard-cards__inners">
          <div class="dashboard-cards__inner" v-for="(booking, index) in previousAppointments" :key="index">
            <div class="dashboard-cards__card">
              <div class="dashboard-cards__card-top">
                <div class="dashboard-cards__card-photo">
                  <img
                          v-if="booking.practitioner_avatar"
                          :src="booking.practitioner_avatar"
                          :alt="booking.practitioner_name"
                  >
                  <v-avatar color="red lighten-1" v-else>
                    <span class="white--text headline"
                    >{{ booking.avatar_letters }}</span>
                  </v-avatar>
                </div>
                <div class="dashboard-cards__card-description">
                  <div
                          class="dashboard-cards__card-info"
                          :class="previousClassObj(booking)"
                  >
                    <div class="dashboard-cards__card-name">
                      {{booking.practitioner_name}}
                     </div>
                    <div
                            v-if="!isCanceledBooking(booking)"

                            class="dashboard-cards__card-date"
                    >
                      {{booking.date}}
                    </div>
                    <div
                            v-else
                            class="dashboard-cards__card-date"
                    >
                      Canceled
                    </div>
                  </div>
                  <template v-for="(treatment, index) in booking.treatments">
                    <div class="dashboard-cards__card-type" :key="index">
                      {{treatment.name}}
                    </div>
                  </template>
                  <div class="dashboard-cards__card-location">
                    {{booking.location_type}}
                  </div>
                  <div class="dashboard-cards__card-actions">
                    <v-btn class="btn-lite"
                           @click="bookAgain(booking.id)"
                    >
                      {{$t('customer_profile.dashboard_tab_book_again')}}
                    </v-btn>
                    <v-btn class="error"
                           v-if="booking.review && booking.booking_status === 'completed'"
                           @click="addReviews(booking.id)"
                    >
                      {{$t('customer_profile.dashboard_tab_leave_review')}}
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="dashboard-cards__card-bottom" v-html="$t('customer_profile.dashboard_tab_booking_block_receive_discount', {practitioner_display_name: booking.practitioner_name})">
              </div>
            </div>
          </div>
          <p v-if="previousAppointments.length === 0" class="dashboard-cards--notification">You have no bookings yet</p>
        </div>
      </div>
    </div>
    <router-view name="customer_dashboard_rebook"></router-view>
  </div>
</template>

<script>

  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import moment from 'moment';
  import {empty, getDisplayUserName, getFirstUserName, capitalizeFirstLetter, getAvatarLetters} from '@/helpers.js';
  import _ from 'lodash';


  import BookingCounter from '@/components/CustomerDashboard/Partial/BookingCounter'
  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Customer Dashboard'
    },
    components: {
      BookingCounter
    },
    data(){
      return {
        bookings: [],
        completedBookings:[],
        paidBookings:[],
        surpriseBookings:[],
        previousAppointments:[],
        lastBooking:'',
        lastPractDisplayName:'',
        lastPractFirstName:'',

        numberOfBookings: 0,
        numberOfIterations:2,
        remainingBookings: 3,
        loadingIsFinished: false
      }
    },
    mounted() {
      this.getBookings();
    },
    watch: {
      paidBookings() {
        let numberByModulo = Number(this.paidBookings.length) % 3;

        if (numberByModulo === 0 && this.paidBookings.length !== 0) {
          this.numberOfBookings = 3;
          this.remainingBookings = 0;
          this.numberOfIterations = 3;
        } else {
          this.numberOfBookings = numberByModulo;
          this.remainingBookings = 3 - numberByModulo;
          this.numberOfIterations = 2;
        }

        this.surpriseBookings = _.reverse(_.slice(this.paidBookings, 0,  this.numberOfBookings));
      }
    },
    methods:{
      isCanceledBooking(booking) {
        if (booking.booking_status === 'cancelled by customer' || booking.booking_status === 'cancelled by practitioner') {
          return true;
        } else {
          return false;
        }
      },
      previousClassObj(booking){
        return {
          'dashboard-booking__cancelled' : this.isCanceledBooking(booking)
        }
      },
      getRedirect(){
        this.$router.push({path: '/services-search'});
      },
      addReviews(booking_id){
        this.$router.push({name:'customer-dashboard-reviews', params:{booking_id: booking_id}});
      },
      bookAgain(booking_id) {
        this.$router.push({name: 'customer-dashboard-rebook', params:{booking_id: booking_id, booking_flow: 'dashboard-tab'}})
      },
      getBookings(){
        this.requestBookings()
          .then((response) => {
            this.bookings = response.data;

            this.completedBookings = this.getCompletedBookings();
            this.completedBookings = _.orderBy(this.completedBookings, ['date'], ['desc']);
            this.lastBooking = _.head(this.completedBookings);

            this.paidBookings = this.getPaidBookings();
            this.preparePaidBookings();
            this.paidBookings = _.orderBy(this.paidBookings, ['sort_date'], ['desc']);

            this.previousAppointments = this.getPreviousAppointments();
            this.preparePreviousAppointments();
            this.previousAppointments = _.orderBy(this.previousAppointments, ['sort_date'], ['asc']);

            if(!empty(this.lastBooking)){
              this.lastPractDisplayName = getDisplayUserName(this.lastBooking.practitioner_name);
              this.lastPractFirstName = getFirstUserName(this.lastBooking.practitioner_name);
            }

            this.loadingIsFinished = true;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          });
      },
      getCompletedBookings() {
        return this.bookings.filter((booking) => {
          return booking.booking_status === 'completed';
        })
      },
      getPaidBookings() {
        return this.bookings.filter((booking) => {
          return booking.paid === true && booking.booking_status !== 'cancelled by customer' && booking.booking_status !== 'cancelled by practitioner';
        })
      },
      preparePaidBookings(){
        for(let i=0; i < this.paidBookings.length; i++){
          this.paidBookings[i].sort_date = moment(this.paidBookings[i].created_at, moment.ISO_8601).clone().format("x");
          if(empty(this.paidBookings[i].practitioner_avatar)){
            this.paidBookings[i].avatar_letters = getAvatarLetters(this.paidBookings[i].practitioner_name);
          }
        }
      },
      getPreviousAppointments(){
        return this.bookings.filter((booking) => {
          let date =  moment(booking.date, moment.ISO_8601).clone().format("x");
          let now = moment().clone().format("x");
          return (date < now && booking.booking_status !== 'cancelled by customer' && booking.booking_status !== 'cancelled by practitioner');
        })
      },
      preparePreviousAppointments(){
        for(let i=0; i < this.previousAppointments.length; i++){
          this.previousAppointments[i].practitioner_name = getDisplayUserName(this.previousAppointments[i].practitioner_name);
          this.previousAppointments[i].sort_date = moment(this.previousAppointments[i].date, moment.ISO_8601).clone().format("x");
          this.previousAppointments[i].date = moment(this.previousAppointments[i].date, moment.ISO_8601).clone().format("DD/MM/YYYY");
          this.previousAppointments[i].location_type = capitalizeFirstLetter(this.previousAppointments[i].location_type);
          if(empty(this.previousAppointments[i].practitioner_avatar)){
            this.previousAppointments[i].avatar_letters = getAvatarLetters(this.previousAppointments[i].practitioner_name);
          }
        }
      },
      requestBookings(){
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/user/bookings`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .dashboard-customer {
    .dashboard-content {
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__body {
        @include r(1000) {
          padding-top: 0;
        }
      }
    }

    &__rside {
      display: flex;
      align-items: center;
      padding-right: 46px;

      @include r(1000) {
        display: none;
      }
    }

    &__checked {
      border-radius: 4px;
      border: 1px solid #f25757;
      padding: 12px;
      display: flex;
      align-items: center;
    }

    &__checks {
      display: flex;
      align-items: center;
      margin: 0 -4px;
    }

    &__check {
      display: inline-block;
      margin: 0 4px;
      width: 29px;
      height: 29px;
      background: {
        color: #e0e0e0;
        repeat: no-repeat;
        size: contain;
        position: center;
      }
      border-radius: 50%;

      &.is-check {
        background: {
          image: url("../../../src/images/icons/checked-dashboard.svg");
          color: transparent;
        }
      }
    }

    &__gift {
      margin-left: 8px;
      display: inline-block;
      width: 29px;
      height: 29px;
      background: {
        color: #e0e0e0;
        repeat: no-repeat;
        position: center;
        size: 17px;
        image: url("../../../src/images/icons/gift_black.svg");
      }
      border-radius: 50%;
    }

    &__question {
      cursor: pointer;
      width: 29px;
      height: 29px;
      border: 1px solid #b2b2b2;
      display: block;
      margin-left: 17px;
      border-radius: 50%;
      color: #b2b2b2;
      font-family: Nunito, sans-Serif;
      text-align: center;
      line-height: 29px;
      font-size: 18px;
      font-weight: 600;
    }

    &__title {
      display: flex;
      align-items: center;
      padding: 18px 42px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      background-color: #272727;
      margin: 0 20px;

      @include r(1700) {
        padding: 18px 38px;
      }
      @include r(1500) {
        padding: 18px 30px;
      }
      @include r(1000) {
        padding: 16px 20px;
        margin: 0;
      }
      @include r(500) {
        padding: 15px;
      }

      p {
        flex: 1 1 auto;
        color: #fff;
        font-family: Nunito, sans-Serif;
        font-size: 20px;
        font-weight: 600;
        margin: 0;

        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 15px;
        }

        &.p-mob {
          display: none;
          @include r(700) {
            display: inline-block;
          }
        }
        &.p-desk {
          @include r(700) {
            display: none;
          }
        }

        strong, span {
          font-weight: 700;
        }
        span {
          color: #f25757;
        }
      }

      .btn {
        height: 38px;
        border-radius: 4px;
        background-color: #f25757;
        flex: 0 0 auto;
        font-size: 18px;
        min-width: 139px;
        margin: 0 0 0 20px;
        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 16px;
        }
        @include r(1000) {
          min-width: 110px;
          font-size: 15px;
        }
        @include r(550) {
          font-size: 14px;
        }
        @include r(450) {
          font-size: 13px;
          margin-left: 10px;
        }

        &.btn-mob {
          display: none;
          @include r(700) {
            display: inline-block;
          }
        }
        &.btn-desk {
          @include r(700) {
            display: none;
          }
        }
      }
    }

    &__middle {
      display: flex;
      justify-content: center;
    }

    &__book {
      border-radius: 5px;
      border: 4px solid #f25757;
      padding: 30px;
      display: inline-flex;
      align-items: center;
      margin: 70px auto 0 auto;

      @include r(1700) {
        margin: 50px auto 0 auto;
        padding: 20px 24px;
      }
      @include r(1500) {
        margin: 40px auto 0 auto;
        padding: 18px 20px;
      }
      @include r(1300) {
        margin: 35px auto 0 auto;
        padding: 15px 16px;
      }
      @include r(700) {
        margin: 30px auto 0 auto;
        padding: 15px;
        border-width: 2px;
      }
      @include r(400) {
        padding: 12px;
        border-width: 2px;
        justify-content: space-between;
      }

      &-items {
        display: flex;
        align-items: center;
        margin: 0 -14px;
        @include r(1700) {
          margin: 0 -10px;
        }
        @include r(1500) {
          margin: 0 -8px;
        }
        @include r(700) {
          margin: 0 -5px;
        }
      }

      &--surprise_block{
        .v-avatar{
          height:100% !important;
          width:100% !important;
          margin:0 !important;
          span{
            font-size: 15px!important;
          }
        }
      }

      &-item {
        display: inline-block;
        margin: 0 14px;
        width: 74px;
        height: 74px;
        border: 3px solid #707070;
        background: {
          color: #e0e0e0;
        }
        border-radius: 50%;
        overflow: hidden;

        @include r(1700) {
          width: 60px;
          height: 60px;
          margin: 0 10px;
        }
        @include r(1500) {
          width: 50px;
          height: 50px;
          margin: 0 8px;
        }
        @include r(1300) {
          width: 40px;
          height: 40px;
          margin: 0 8px;
        }
        @include r(700) {
          width: 38px;
          height: 38px;
          margin: 0 5px;
          border-width: 2px;
        }
        @include r(400) {
          width: 34px;
          height: 34px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        &.is-check {
          background: {
            color: transparent;
          }
        }
      }
      &-gift {
        margin-left: 28px;
        display: inline-block;
        width: 74px;
        height: 74px;
        border: 3px solid #707070;
        background: {
          color: transparent;
          repeat: no-repeat;
          position: center;
          size: 30px;
          image: url("../../../src/images/icons/gift_red.svg");
        }
        border-radius: 50%;
        @include r(1700) {
          width: 60px;
          height: 60px;
          margin-left: 20px;
          background-size: 28px;
        }
        @include r(1500) {
          width: 50px;
          height: 50px;
          margin-left: 16px;
          background-size: 24px;
        }
        @include r(1300) {
          width: 40px;
          height: 40px;
          background-size: 22px;
        }
        @include r(700) {
          width: 38px;
          height: 38px;
          background-size: 20px;
          border-width: 2px;
          margin-left: 10px;
        }
        @include r(400) {
          width: 34px;
          height: 34px;
          background-size: 18px;
        }
      }
    }

    &__text {
      display: block;
      margin: 60px 0 0 0;
      text-align: center;
      color: #272727;
      font-family: Nunito, sans-serif;
      font-size: 25px;
      font-weight: 400;
      line-height: 1.2;
      strong {
        font-weight: 700;
      }
      @include r(1700) {
        font-size: 23px;
        margin-top: 50px;
      }
      @include r(1500) {
        font-size: 21px;
        margin-top: 40px;
      }
      @include r(1300) {
        font-size: 16px;
        margin-top: 24px;
      }
      @include r(450) {
        font-size: 15px;
      }
    }

    &__search {
      display: block;
      min-width: 139px;
      margin: 40px auto 0 auto;
      height: 38px;
      border-radius: 4px;
      background-color: #f25757;
      font-size: 18px;
      @include r(1700) {
        font-size: 17px;
        margin-top: 30px;
      }
      @include r(1500) {
        font-size: 16px;
        margin-top: 26px;
      }
      @include r(1300) {
        font-size: 15px;
        margin-top: 20px;
        height: 34px;
        min-width: 120px;
      }
      @include r(450) {
        font-size: 14px;
      }
    }

  }

  .dashboard-cards {
    position: relative;
    padding-top: 40px;
    margin-top: 50px;

    @include r(1700) {
      padding-top: 30px;
      margin-top: 40px;
    }
    @include r(1500) {
      padding-top: 25px;
      margin-top: 35px;
    }
    @include r(1000) {
      padding-top: 0;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      height: 1px;
      left: 22px;
      width: calc(100% - 44px);
      background-color: #f7cecf;

      @include r(1000) {
        display: none;
      }
    }
    &__title {
      display: block;
      color: #383838;
      font-family: Nunito, sans-serif;
      font-size: 27px;
      font-weight: 600;
      padding: 0 22px;

      @include r(1500) {
        font-size: 25px;
      }
      @include r(1400) {
        font-size: 23px;
      }
      @include r(1140) {
        text-align: center;
      }

      @include r(1000) {
        font-size: 20px;
      }

      @include r(500) {
        font-size: 16px;
      }
    }

    &__inners {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 0 -15px;

      @include r(1840) {
        margin: 20px -20px 20px -20px;
      }
      @include r(1320) {
        margin: 20px -16px 20px -16px;
      }
      @include r(1140) {
        justify-content: center;
        margin: 20px 0 0 0;
      }
    }
    &__inner {
      flex: 0 0 50%;
      padding: 48px;
      max-width: 50%;

      @include r(1840) {
        padding: 20px;
      }
      @include r(1320) {
        padding: 16px;
      }
      @include r(1140) {
        flex: 0 0 100%;
        max-width: 600px;
        padding: 10px 0;
      }
    }

    &__card {
      display: block;
      height: 100%;
      border-radius: 5px;
      background-color: #fff;
      padding: 34px;
      @include r(1700) {
        padding: 30px;
      }
      @include r(1500) {
        padding: 24px;
      }
      @include r(1320) {
        padding: 18px;
      }

      &-top {
        display: flex;
        position: relative;
      }

      &-photo {
        width: 176px;
        height: 176px;
        border-radius: 5px;
        display: block;
        overflow: hidden;
        flex: 0 0 auto;

        @include r(1910) {
          width: 150px;
          height: 150px;
        }
        @include r(1600) {
          width: 120px;
          height: 120px;
        }
        @include r(1400) {
          width: 100px;
          height: 100px;
        }
        @include r(1240) {
          width: 80px;
          height: 80px;
        }
        @include r(450) {
          position: absolute;
          top: 0;
          left: 0;
          width: 70px;
          height: 70px;
        }
        @include r(350) {
          width: 60px;
          height: 60px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
        }
      }
      &-description {
        flex: 1 1 auto;
        padding-left: 53px;

        @include r(1840) {
          padding-left: 30px;
        }
        @include r(1400) {
          padding-left: 20px;
        }
        @include r(450) {
          padding-left: 94px;
        }
        @include r(350) {
          padding-left: 84px;
        }
      }

      &-cancelled{
        background-color: rgba(255,82,82, 0.3) !important;
      }

      &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-name {
        display: inline-block;
        color: #272727;
        font-family: Nunito, sans-serif;
        font-size: 20px;
        font-weight: 700;
        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 15px;
        }
      }
      &-date {
        display: inline-block;
        color: #272727;
        font-family: Nunito, sans-serif;
        font-size: 20px;
        font-weight: 600;
        padding-left: 20px;
        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 15px;
        }
      }
      &-type {
        display: block;
        margin-top: 6px;
        color: #272727;
        font-family: Nunito, sans-serif;
        font-size: 20px;
        font-weight: 400;
        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 15px;
        }
      }
      &-location {
        display: block;
        margin-top: 6px;
        color: #272727;
        font-family: Nunito, sans-serif;
        font-size: 20px;
        font-weight: 400;
        @include r(1700) {
          font-size: 17px;
        }
        @include r(1500) {
          font-size: 15px;
        }
      }
      &-actions {
        display: flex;
        align-items: center;
        margin: 38px -5px 0 -5px;

        @include r(1910) {
          margin-top: 12px;
        }
        @include r(450) {
          margin: 20px -5px 0 -99px;
        }
        @include r(350) {
          margin-left: -89px;
        }

        .v-btn {
          margin: 0 5px;
          width: calc(50% - 10px);
          border-radius: 4px;
          font-size: 18px;
          font-weight: 600;
          @include r(1700) {
            font-size: 17px;
          }
          @include r(1600) {
            font-size: 15px;
          }
          @include r(450) {
            font-size: 14px;
          }

          .v-ripple__container {
            border-radius: 2px;
          }
        }

        .btn-lite {
          border: 1px solid #f25757;
          background-color: #fff !important;
        }
      }
      &-bottom {
        display: block;
        margin-top: 30px;

        @include r(1500) {
          margin-top: 20px;
        }

        p {
          display: block;
          margin: 0;
          color: #272727;
          font-family: Nunito, sans-serif;
          font-size: 20px;
          font-weight: 400;
          text-align: center;
          @include r(1700) {
            font-size: 17px;
          }
          @include r(1500) {
            font-size: 15px;
          }

          @include r(1400) {
            font-size: 14px;
          }
          @include r(1300) {
            font-size: 13px;
          }

          strong {
            font-weight: 700;
          }

          span {
            font-weight: 700;
            color: #f25757;
          }
        }
      }
    }
    &--notification{
      width: 100%;
      text-align: center;
    }
    &--practitioner {
      padding-top: 0;
      &::before {
        display: none;
      }
      .dashboard-cards {
        &__title {
          padding: 0;
          display: flex;
          align-items: center;
          @include r(1140) {
            padding: 0;
            justify-content: center;
          }

          span {
            flex: 0 0 auto;
            padding-right: 30px;
            @include r(1140) {
              padding: 0;
            }
          }

          &::after {
            content: '';
            width: 100%;
            flex: 1 1 auto;
            height: 1px;
            background-color: #f7cecf;

            @include r(1140) {
              display: none;
            }
          }
        }
        &__card {
          &-photo {
            padding: 20px;
            @include r(1600) {
              padding: 10px;
            }
            @include r(1240) {
              padding: 4px;
            }
            img {
              display: block;
              object-fit: inherit;
              max-width: 100%;
              width: 100%;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
</style>
