<template>
  <div class="dashboard-content dashboard-reviews" id="dashboard-content">
    <div class="dashboard-content__title">
      <h2>
        {{$t('customer_profile.invite_review_page_title')}}
      </h2>
      <p>
        {{$t('customer_profile.invite_review_page_desc')}}
      </p>
    </div>
    <ReviewsComponent
            @review-data-is-valid="addReview"
            @rating-was-change="ratingWasChange"
            @review-was-change="reviewWasChange"
            :title="title"
            :timekeepingErrorMess="timekeepingErrorMess"
            :friendlinessErrorMess="friendlinessErrorMess"
            :communicationErrorMess="communicationErrorMess"
            :satisfactionErrorMess="satisfactionErrorMess"
            :reviewErrorMess="reviewErrorMess"
    ></ReviewsComponent>
  </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty} from '@/helpers.js';
  import ReviewsComponent from '@/components/Partial/ReviewsComponent';

  export default {
    name: "invited-clients-review",
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Invited Clients Reviews'
    },
    props:['token'],
    components:{
      ReviewsComponent
    },
    data(){
      return {
        title:'',
        timekeepingErrorMess:[],
        friendlinessErrorMess:[],
        communicationErrorMess:[],
        satisfactionErrorMess:[],
        reviewErrorMess:[],
      }
    },
    mounted(){
      this.scrollToHeader();
      this.getPractitioner();
    },
    methods:{
      scrollToHeader(){
        this.$scrollTo('.header.v-toolbar');
      },
      ratingWasChange(data){
        this[`${data.type}ErrorMess`] = [];
      },
      reviewWasChange(){
        this.reviewErrorMess = [];
      },
      addReview(data) {
        this.postReviewRequest(data)
          .then(() => {
            this.$router.push({path: '/customer'});
            this.$store.commit('SET_NOTIFICATION', {
              text: this.$i18n.t('customer_profile.reviews_page_notif_review_successfully_added'),
              color: 'error'
            });
          })
          .catch((error) => {
            if (!empty(error.response) && !empty(error.response.data.errors)) {
              this.fieldsErrorFromServer(error.response.data.errors);
            }else if(!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)){
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
          })
      },
      postReviewRequest(data){
        return axios({
          method: 'post',
          url: `${CONFIG.API_URL}/dashboard/user/inviteReview/${this.token}`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      getPractitioner(){
        this.getPractitionerRequest()
          .then((response) => {
            this.setTitle(response.data);
          })
      },
      getPractitionerRequest(){
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/user/inviteReview/${this.token}`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      setTitle(data){
        this.title = data.practitioner_name;
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      }
    }

  }
</script>

<style scoped>

</style>