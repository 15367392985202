import { SET_SEARCH_PARAMS, REFRESH_SEARCH_PARAMS, RESET_SEARCH_PARAMS } from '../action-types';
import { UPDATE_SEARCH_PARAM } from '../mutation-types';
import repository from '@/repository/repository.js';
import {empty} from '@/helpers.js';

export default {

  state:{
    search:{
      searchAddress: '',
      searchTown: '',
      searchLatitude: '',
      searchLongitude: '',
      searchSubServiceId: '',
      searchSubServiceName: '',
      searchLocationType: '',
      searchDate: '',
      searchPostcode:'',
      searchCountryName:'',
      searchCountryId:'',
      searchAddressComponents: '',
      searchServiceType: ''
    }
  },
  mutations: {
    [UPDATE_SEARCH_PARAM]:(state, data) => {
      state.search[data.name] = data.value;
    }
  },
  actions: {
    [SET_SEARCH_PARAMS]: ({commit}, search) => {
      for(let name in search){
        commit('UPDATE_SEARCH_PARAM', {name:name, value: search[name]});
        if(name === 'searchAddressComponents'){
          repository.saveValue(name, JSON.stringify(search[name]));
        }else{
          repository.saveValue(name, search[name]);
        }
      }
      return Promise.resolve();
    },
    [REFRESH_SEARCH_PARAMS]: ({state, commit}) => {
      for(let name in state.search){
        if(empty(state.search[name])){
          if(name === 'searchAddressComponents'){
            commit('UPDATE_SEARCH_PARAM', {name, value: (!empty(repository.getValue(name))) ? JSON.parse(repository.getValue(name)) : null});
          }else{
            commit('UPDATE_SEARCH_PARAM', {name, value: (!empty(repository.getValue(name))) ? repository.getValue(name) : null});
          }
        }
      }
    },
    [RESET_SEARCH_PARAMS]: ({dispatch}) => {
      dispatch('SET_SEARCH_PARAMS', {
        searchAddress: '',
        searchTown: '',
        searchLatitude: '',
        searchLongitude: '',
        searchSubServiceId: '',
        searchSubServiceName: '',
        searchLocationType: '',
        searchDate: '',
        searchPostcode:'',
        searchCountryName:'',
        searchCountryId:'',
        searchAddressComponents: ''
      })
        .then(()=>{
          return Promise.resolve();
        })
    }
  },
  getters: {
    search(state){
      return state.search;
    },
    isValidSearchParams(state) {
      const verifiableParams = [
        'searchAddress',
        'searchTown',
        'searchLatitude',
        'searchLongitude',
        // 'searchSubServiceId',
        // 'searchSubServiceName',
        'searchLocationType',
        // 'searchPostcode',
        'searchCountryName',
        'searchCountryId',
        // 'searchAddressComponents'
      ];

      for(let i=0; i<verifiableParams.length; i++){
        if(empty(state.search[verifiableParams[i]])){
          return false;
        }
      }
      return true;
    }
  }

}