<template>
  <v-card class="register-card login-form" :class="{'login-form-page': isResetPasswordPage}">
    <v-card-text>
      <div class="profile-booking__close" v-if="!isResetPasswordPage" @click="hide">
        <v-icon>close</v-icon>
      </div>
      <p class="login-form__top">
        {{$t('auth_forms.forgot_password_title')}}
      </p>
      <p>
        {{$t('auth_forms.forgot_password_desc')}}
      </p>
      <div class="login-form__middle">
        <v-form
                ref="requestForm"
                v-model="isRequestFormValid"
                lazy-validation
        >
          <v-text-field
                  name="email"
                  :label="$t('auth_forms.email')"
                  type="email"
                  :rules="emailRules"
                  v-model="email"
                  :error-messages="emailErrorMess"
                  @focus="clearErrorMess('emailErrorMess')"
                  @keyup.enter="onSubmit"
                  required
                  solo
                  class="solo-field-white"
          ></v-text-field>

        </v-form>
        <v-card-actions>
          <v-btn
                  block
                  color="error"
                  @click="onSubmit"
          >
            {{$t('auth_forms.recover_password')}}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card-text>

    <p class="login-form__bottom" v-if="isResetPasswordPage">
      {{$t('auth_forms.remembered_your_password')}}
      <a href="#" @click.prevent="redirectToLoginPage">
        {{$t('auth_forms.login_instead')}}
      </a>
    </p>
    <p class="login-form__bottom" v-if="!isResetPasswordPage">
      {{$t('auth_forms.remembered_your_password')}}
      <a href="#" @click.prevent="showLoginModal">
        {{$t('auth_forms.login_instead')}}
      </a>
    </p>
  </v-card>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty} from '@/helpers.js';


  export default {
    name: "reset-password-request",
    props: ['useInModal'],
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Sign In'
    },
    data() {
      return {
        isRequestFormValid: false,
        email: "",
        emailRules: [
          v => !!v || this.$i18n.t('auth_forms.js_error_email_required'),
          v => /.+@.+/.test(v) || this.$i18n.t('auth_forms.js_error_email_format')
        ],
        emailErrorMess: [],
      }
    },
    mounted() {
      if (this.isResetPasswordPage) {
        this.$scrollTo('.header.v-toolbar');
      }
    },
    computed: {
      isResetPasswordPage() {
        if (!this.useInModal) {
          return true;
        } else {
          return false;
        }
      }
    },
    methods: {
      showLoginModal() {
        this.$emit('showLoginModal');
      },
      redirectToLoginPage() {
        this.$router.push({path: '/login'});
      },
      onSubmit() {
        if (this.$refs.requestForm.validate()) {
          this.resetRequest()
            .then(() => {
              this.$store.commit('SET_NOTIFICATION', {
                text: this.$i18n.t('auth_forms.reset_request_successfully_sent'),
                color: 'error'
              });
              if (this.isResetPasswordPage) {
                this.$router.push({path: '/'})
              } else {
                this.hide();
              }
            })
            .catch((error) => {
              if (!empty(error.response) && !empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              } else if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
                this.$store.commit('SET_NOTIFICATION', {
                  text: error.response.data.message,
                  color: 'orange'
                });
              }
            })
        }
      },
      resetRequest() {
        return axios({
          method: 'post',
          url: `${CONFIG.API_URL}/auth/sendResetPasswordEmail`,
          data: JSON.stringify({email: this.email}),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        });
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      hide() {
        this.$emit("hideResetPasswordModal");
      },
      clearErrorMess(field) {
        this[field] = [];
      },
    }
  }
</script>

<style scoped>

</style>