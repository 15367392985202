<template>

  <div>
    <div class="payment-success-page">
      <div class="payment-success">
        <div class="payment-success__title payment-success__title--mt0">
          {{$t('payment_success.appointment_confirmed')}}
        </div>
        <div class="payment-success__icon"></div>
        <div class="payment-success__title">
          {{$t('payment_success.booking_reference')}} <span>{{booking.id}}</span>
        </div>
        <div class="payment-success__text">
          {{$t('payment_success.confirmation_email_desc')}}
        </div>
        <div class="payment-success__phone">{{practitioner.phone}}</div>
        <div class="payment-success__text">
          {{$t('payment_success.practitioner_contact_number_desc')}}
        </div>
        <div class="payment-success__title payment-success__title--mob payment-success__title--mt">
          {{$t('payment_success.what_you_need_to_know')}}
        </div>
        <div class="payment-success__items">
          <div class="payment-success__item">
            <div class="payment-success__item-title">
              {{$t('payment_success.practitioner')}}
            </div>
            <div class="payment-success__item-value">{{practitioner.name}}</div>
          </div>
          <div class="payment-success__item">
            <div class="payment-success__item-title">
              {{$t('payment_success.contact_number')}}
            </div>
            <div class="payment-success__item-value">{{practitioner.phone}}</div>
          </div>
          <div class="payment-success__item">
            <div class="payment-success__item-title">
              {{$t('payment_success.treatment')}}
            </div>
            <template v-for="(slot, index) in preparedTreatments">
              <div class="payment-success__item-value" :key="index">{{slot.quantity}} x {{slot.name}}</div>
            </template>
          </div>
          <div class="payment-success__item">
            <div class="payment-success__item-title">
              {{$t('payment_success.date')}}
            </div>
            <div class="payment-success__item-value">{{bookingDate}}</div>
          </div>
          <div class="payment-success__item">
            <div class="payment-success__item-title">
              {{$t('payment_success.time')}}
            </div>
            <div class="payment-success__item-value">{{bookingStartTime}}</div>
          </div>
          <div class="payment-success__item">
            <div class="payment-success__item-title">
              {{$t('payment_success.location')}}
            </div>
            <div class="payment-success__item-value">{{booking.location.address}}</div>
            <div class="payment-success__item-value">{{booking.location.city}}</div>
            <div class="payment-success__item-value">{{booking.location.postcode}}</div>
          </div>
        </div>
        <div class="payment-success__action">
          <v-btn
            color="error"
            class="payment-success__button"
            @click="viewMyProfile"
          >
            {{$t('payment_success.view_my_profile')}}
          </v-btn>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import {getTreatmentObject, getHeightOfElement} from '@/helpers.js';
  import moment from 'moment';

  export default {
    props: ['booking_id'],
    name: "PaymentSuccess",
    data() {
      return {
        booking: {
          cancellation_policy: null,
          date: null,
          id: null,
          location_type: null,
          practitioner_name: null,
          practitioner_id: null,
          start_time: null,
          treatments: [],
          location: {
            address: null,
            city: null,
            postcode: null,
          }
        },
        practitioner: {
          name: null,
          phone: null
        },
        sumPrice: 0,
        preparedTreatments: [],
      }
    },
    created: function () {

      this.$store.dispatch('GET_BOOKING', this.booking_id)
        .then((data) => {
          this.booking = data;
          const dataTreatment = getTreatmentObject(this.booking.treatments);
          this.preparedTreatments = dataTreatment['data'];
          this.sumPrice = dataTreatment['sumPrice'];

          this.$store.dispatch('ADD_PRACTITIONER_TO_STATE', {id: this.booking.practitioner_id, lat: null, lon: null})
            .then((data) => {
              this.practitioner = data;
            })
        });
    },
    mounted(){
      const navHeight = getHeightOfElement('nav.header');
      this.$scrollTo('.payment-success__title.payment-success__title--mt0', 800, {offset: Number(navHeight * -1)});
    },
    computed: {
      bookingDate() {
        return moment(this.booking.date, "YYYY-MM-DD").format("dddd Do MMMM YYYY");
      },
      bookingStartTime() {
        return moment(this.booking.start_time, "HH-mm-ss").format("HH.mm a");
      }
    },
    methods: {
      viewMyProfile() {
        this.$router.push({path: '/customer'})
      }
    }
  }
</script>

<style lang="scss">

  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .payment-success-page {
    background-color: #f7f7f7;
    padding: 120px 50px 70px 50px;

    @include r(1700) {
      padding: 60px 24px;
    }

    @include r(1500) {
      padding: 40px 24px;
    }

    @include r(1000) {
      padding: 30px 24px;
    }

    @include r(700) {
      padding: 40px;
    }
    @include r(360) {
      padding: 30px;
    }
  }

  .payment-success {
    display: block;
    margin: 0 auto;
    max-width: 1015px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.34);
    border-radius: 5px;
    background-image: linear-gradient(270deg, #fff 0%, #fdfdfd 100%);
    padding: 30px 168px 112px 168px;

    @include r(1500) {
      max-width: 960px;
      padding: 30px 120px 100px 120px;
    }

    @include r(1400) {
      max-width: 880px;
      padding: 30px 120px 100px 120px;
    }

    @include r(1000) {
      padding: 30px 80px 70px 80px;
    }

    @include r(700) {
      padding: 0;
      box-shadow: none;
      background-image: none;
      border-radius: 0;
    }

    &__title {
      display: block;
      text-align: center;
      color: #383838;
      font-family: Nunito;
      font-size: 27px;
      font-weight: 700;
      line-height: 1.2;
      margin-top: 40px;

      @include r(1700) {
        font-size: 25px;
        margin-top: 30px;
      }

      @include r(1500) {
        font-size: 23px;
        margin-top: 20px;
      }
      @include r(1400) {
        font-size: 20px;
      }

      &--mob {
        @include r(700) {
          font-size: 15px;
        }
      }

      &--mt0 {
        margin-top: 0;
      }

      &--mt {
        margin-top: 60px;

        @include r(1500) {
          margin-top: 40px;
        }

        @include r(700) {
          margin-top: 20px;
        }
      }
    }

    &__icon {
      display: block;
      text-align: center;
      margin: 40px auto 0 auto;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: {
        color: #f25757;
        image: url("../.././images/icons/checke-lg.svg");
        repeat: no-repeat;
        position: center;
      }

      @include r(1700) {
        margin-top: 30px;
        width: 130px;
        height: 130px;
        background-size: 60px;
      }
      @include r(1500) {
        margin-top: 20px;
        width: 120px;
        height: 120px;
        background-size: 50px;
      }
      @include r(700) {
        margin-top: 20px;
        width: 90px;
        height: 90px;
        background-size: 40px;
      }
    }

    &__phone {
      display: block;
      text-align: center;
      color: #383838;
      font-family: Nunito;
      font-size: 30px;
      font-weight: 700;
      position: relative;
      padding-bottom: 80px;
      margin-top: 40px;

      @include r(1700) {
        font-size: 25px;
        margin-top: 30px;
      }

      @include r(1500) {
        font-size: 23px;
        margin-top: 20px;
      }
      @include r(1400) {
        font-size: 20px;
      }

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        margin-left: -22px;
        width: 44px;
        height: 45px;
        background: {
          image: url("../.././images/icons/icon-phone.svg");
          repeat: no-repeat;
          size: contain;
        }

        @include r(1500) {
          width: 34px;
          height: 34px;
          margin-left: -17px;
        }
      }
    }

    &__text {
      display: block;
      color: #535353;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;
      margin-top: 40px;

      @include r(1700) {
        font-size: 18px;
        margin-top: 30px;
      }

      @include r(1500) {
        font-size: 16px;
        margin-top: 20px;
      }

      @include r(1400) {
        font-size: 15px;
      }

      @include r(700) {
        font-size: 13px;
      }

    }

    &__items {
      display: block;
      margin-top: 40px;

      @include r(1700) {
        margin-top: 30px;
      }
      @include r(1500) {
        margin-top: 20px;
      }
    }

    &__item {
      display: block;
      margin-bottom: 20px;

      @include r(1400) {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        display: block;
        color: #383838;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 700;
        margin-bottom: 8px;

        @include r(1700) {
          font-size: 20px;
        }

        @include r(1500) {
          font-size: 18px;
        }

        @include r(1400) {
          font-size: 16px;
        }
        @include r(700) {
          font-size: 15px;
        }

      }
      &-value {
        display: block;
        color: #535353;
        font-family: Nunito;
        font-size: 23px;
        font-weight: 400;

        @include r(1700) {
          font-size: 20px;
        }

        @include r(1500) {
          font-size: 18px;
        }

        @include r(1400) {
          font-size: 16px;
        }
        @include r(700) {
          font-size: 15px;
        }

      }
    }

    &__action {
      display: block;
      margin-top: 50px;
    }
    &__button {
      max-width: 457px;
      width: 100%;
      display: block;
      margin: 0 auto;
      height: 87px;
      font-size: 23px;

      @include r(1700) {
        max-width: 420px;
        height: 80px;
        font-size: 21px;
      }

      @include r(1500) {
        max-width: 360px;
        height: 60px;
        font-size: 19px;
      }
      @include r(1300) {
        max-width: 320px;
        height: 54px;
        font-size: 18px;
      }

      @include r(1100) {
        max-width: 265px;
        height: 50px;
        font-size: 16px;
      }
    }
  }
</style>