var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content dashboard-calendar",attrs:{"id":"dashboard-content"}},[_c('div',{staticClass:"dashboard-content__title"},[_c('h2',[_vm._v("\n      "+_vm._s(_vm.$t('practitioner_profile.calendar_tab_title'))+"\n    ")]),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t('practitioner_profile.calendar_tab_desc'))+"\n    ")])]),_c('div',{staticClass:"dashboard-content__body"},[_c('v-app',{attrs:{"id":"dayspan"}},[_c('ds-calendar-app',{ref:"app",attrs:{"calendar":_vm.calendar,"read-only":_vm.readOnly},on:{"cancel":_vm.closePopup},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var prev = ref.prev;
var prevLabel = ref.prevLabel;
return [_c('v-tooltip',{staticClass:"dashboard-calendar__prev"},[_c('v-btn',{staticClass:"ds-light-forecolor ds-skinny-button",attrs:{"slot":"activator","icon":"","depressed":""},on:{"click":prev},slot:"activator"},[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('span',[_vm._v(_vm._s(prevLabel))])],1)]}},{key:"next",fn:function(ref){
var next = ref.next;
var nextLabel = ref.nextLabel;
return [_c('v-tooltip',{staticClass:"dashboard-calendar__next"},[_c('v-btn',{staticClass:"ds-light-forecolor ds-skinny-button",attrs:{"slot":"activator","icon":"","depressed":""},on:{"click":next},slot:"activator"},[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1),_c('span',[_vm._v(_vm._s(nextLabel))])],1)]}},{key:"view",fn:function(ref){
var currentType = ref.currentType;
var types = ref.types;
return [_c('customViewForSlot',{attrs:{"currentType":currentType,"types":types,"mobileWidth":_vm.mobileWidth},on:{"custom-set-type":_vm.setCurrentViewType}})]}},{key:"eventPopover",fn:function(slotData){return [_c('customCalendarEventPopover',_vm._b({attrs:{"read-only":_vm.eventPopupReadOnly(slotData),"allowEditOnReadOnly":_vm.eventPopupAllowEdit(slotData)},on:{"custom-remove":_vm.eventRemove},scopedSlots:_vm._u([{key:"eventPopoverBodyTop",fn:function(slotData){return [_c('v-list-tile',[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("access_time")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(_vm.eventPopupDate(slotData)))]),_c('v-list-tile-sub-title',[_vm._v(_vm._s(_vm.eventPopupTime(slotData)))])],1)],1)]}}],null,true)},'customCalendarEventPopover',slotData,false))]}},{key:"scheduleTitle",fn:function(ref){
var schedule = ref.schedule;
var calendarEvent = ref.calendarEvent;
var details = ref.details;
return [_c('v-form',{ref:"titleForm",model:{value:(_vm.createEventPopup.isTitleFormValid),callback:function ($$v) {_vm.$set(_vm.createEventPopup, "isTitleFormValid", $$v)},expression:"createEventPopup.isTitleFormValid"}},[_c('v-text-field',{staticClass:"ds-event-title",attrs:{"single-line":"","hide-details":"","solo":"","flat":"","label":"Title","readonly":false,"rules":_vm.createEventPopup.titleRules},on:{"input":_vm.createEventPopupCheckTitle},model:{value:(details.title),callback:function ($$v) {_vm.$set(details, "title", $$v)},expression:"details.title"}})],1)]}},{key:"scheduleActions",fn:function(ref){
var calendarEvent = ref.calendarEvent;
var schedule = ref.schedule;
var calendar = ref.calendar;
var actioned = ref.actioned;
var readOnly = ref.readOnly;
return [(!readOnly && calendarEvent)?_c('v-tooltip',{attrs:{"bottom":""}},[_c('customScheduleActions',_vm._g(_vm._b({attrs:{"slot":"activator","schedule":calendarEvent.schedule,"calendar-event":calendarEvent,"calendar":calendar},on:{"custom-remove":_vm.eventRemove,"finish":actioned},slot:"activator"},'customScheduleActions',{$scopedSlots: _vm.$scopedSlots},false),_vm.$listeners),[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("more_vert")])],1)],1),_c('span',[_vm._v("Remove event")])],1):_vm._e()]}},{key:"schedule",fn:function(slotData){return [_vm._v("\n          "+_vm._s(_vm.createEventPopupSetSlotData(slotData))+"\n\n          "),_c('v-list',[_c('v-list-tile',[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("access_time")])],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(_vm.createEventPopupDate(slotData)))]),_c('v-list-tile-sub-title',[_vm._v(_vm._s(_vm.createEventPopupTime(slotData)))])],1)],1)],1),_c('div',{staticClass:"checkout__form-field checkout__form-field--payment-hide"},[_c('v-text-field',{attrs:{"type":"hidden","error-messages":_vm.createEventPopup.messageErrorMess,"solo":""}})],1),_c('customEventPopupButtons',{attrs:{"schedule":slotData.schedule,"calendarEvent":slotData.calendarEvent,"calendar":slotData.calendar,"details":slotData.details,"day":slotData.day,"isCanSaveEvent":_vm.isCanSaveEvent},on:{"custom-save":_vm.eventSave,"custom-update":_vm.eventUpdate}})]}},{key:"eventTimeTitle",fn:function(ref){
var calendarEvent = ref.calendarEvent;
var details = ref.details;
return [_c('div',[(details.icon)?_c('v-icon',{staticClass:"ds-ev-icon",style:({color: details.forecolor}),attrs:{"size":"14"}},[_vm._v("\n              "+_vm._s(details.icon)+"\n            ")]):_vm._e(),_c('strong',{staticClass:"ds-ev-title"},[_vm._v(_vm._s(details.title))])],1),_c('div',{staticClass:"ds-ev-description"},[_vm._v(_vm._s(_vm.getSelectedTime( calendarEvent )))])]}}])},[_c('template',{slot:"title"},[_c('p')]),_c('template',{slot:"today"},[_c('p')]),_c('template',{slot:"summary"},[_c('h1',{staticClass:"dashboard-calendar__title"},[_vm._v("\n            "+_vm._s(_vm.customSummary)+"\n          ")])]),_c('template',{slot:"scheduleSave"},[_c('div')])],2)],1),_c('customEventPopoverForMobile',{attrs:{"mobilePopover":_vm.mobilePopover},on:{"closeCustomMobilePopup":function($event){_vm.mobilePopover = false},"updateCalendarState":_vm.setCalendarState}}),_c('v-btn',{staticClass:"dashboard-calendar__add-event error",attrs:{"fab":"","dark":""},on:{"click":function($event){_vm.mobilePopover = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }