import Vue from 'vue';
import Vuex from 'vuex';

import notification from './modules/notifications';
import auth from './modules/auth';
import redirects from './modules/redirects';
import search from './modules/search';
import practitioner from './modules/practitioner';
import booking from './modules/booking';
import customer from './modules/customer';
import current_user from './modules/current_user';
import cookies from './modules/cookies';
import searchAvailability from './modules/searchAvailability';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notification,
    auth,
    redirects,
    search,
    practitioner,
    booking,
    customer,
    current_user,
    cookies,
    searchAvailability
  }
});