<template>
  <div>
    <div class="location">
      <p class="location__title title text-xs-center text-sm-center text-md-center">
        {{$t('practitioner_registration.step_3_title')}}
      </p>

      <p class="main-text main-text--bold">
        {{$t('practitioner_registration.step_3_desc')}}
      </p>

      <div class="v-swith-main location__center location__center--mt">
        <div class="v-swith-main__label">
          {{$t('practitioner_registration.step_3_mobile')}}
        </div>
        <toggle-button
          :labels="{checked: 'Yes', unchecked: 'No'}"
          :color="{checked: '#6ACE63', unchecked: '#FF5A5F'}"
          :width="90"
          :height="32"
          v-model="showMobile"
        />
      </div>

      <div v-if="showMobile">
        <p class="main-text main-text--bold">
          {{$t('practitioner_registration.step_3_enter_your_home_address')}}
        </p>
        <p class="main-text main-text--light">
          {{$t('practitioner_registration.step_3_enter_your_home_address_desc')}}
        </p>
        <div class="skills-experience__fields">
          <v-form
            ref="formMobile"
            v-model="formMobileValid"
            lazy-validation
          >
            <vuetify-google-autocomplete
              id="mobile-address"
              ref="mobileAddressRef"
              :label="$t('practitioner_registration.step_3_search_address')"
              append-icon="search"
              country="GB"
              clearable
              v-on:placechanged="getHomeAddressData"
              v-on:no-results-found="noHomeResultsFound"
              types="geocode"
              @blur="checkAddress('mobile')"
              @focusin.native="clearAddress('mobile')"
              @input="toggleMobileAddressFields"
            ></vuetify-google-autocomplete>

            <div v-show="showHomeAddressFields">
              <v-text-field
                      name="homeAddress"
                      :label="$t('practitioner_registration.step_3_house_flat_street')"
                      :rules="addressRules"
                      v-model="homeAddress"
                      required
              ></v-text-field>

              <v-text-field
                      name="homeTown"
                      :label="$t('practitioner_registration.step_3_town')"
                      :rules="townRules"
                      v-model="homeTown"
                      required
                      :disabled="true"
              ></v-text-field>

              <v-text-field
                      name="homePostcode"
                      :label="$t('practitioner_registration.step_3_postcode')"
                      :rules="postcodeRules"
                      v-model="homePostcode"
                      required
              ></v-text-field>
            </div>
          </v-form>
        </div>

        <p class="main-text main-text--bold">
          {{$t('practitioner_registration.step_3_how_far_are_you_willing_to_travel')}}
        </p>

        <p class="main-text main-text--light">
          {{$t('practitioner_registration.step_3_how_far_are_you_willing_to_travel_desc')}}
        </p>

        <v-select
          :items="distances"
          v-model="mobileDistance"
          solo
        ></v-select>

        <v-form
          class="custom-distance"
          v-if="mobileDistance == 'Custom'"
          ref="formDistance"
          lazy-validation
        >
          <v-text-field
            :label="$t('practitioner_registration.step_3_custom_distance')"
            suffix="miles"
            min="1"
            max="30"
            step="1"
            type="number"
            v-model="customDistance"
            :rules="distanceRules"
            required
          ></v-text-field>
        </v-form>
      </div>

      <v-divider v-if="showMobile"></v-divider>

      <div class="v-swith-main location__center location__center--mt">
        <div class="v-swith-main__label">
          {{$t('practitioner_registration.step_3_salon')}}
        </div>
        <toggle-button
          :labels="{checked: 'Yes', unchecked: 'No'}"
          :color="{checked: '#6ACE63', unchecked: '#FF5A5F'}"
          v-model="showSalon"
          :width="90"
          :height="32"
        />
      </div>

      <div v-if="showSalon">
        <p class="main-text main-text--bold">
          {{$t('practitioner_registration.step_3_enter_address_and_add_salon')}}
        </p>
        <div class="skills-experience__fields">
          <v-form
            ref="formSalon"
            v-model="formSalonValid"
            lazy-validation
          >
            <v-text-field
              name="salonName"
              :label="$t('practitioner_registration.step_3_salon_name')"
              v-model="salonName"
            ></v-text-field>

            <vuetify-google-autocomplete
              id="salon-address"
              ref="salonAddressRef"
              :label="$t('practitioner_registration.step_3_search_address')"
              append-icon="search"
              country="GB"
              clearable
              v-on:placechanged="getSalonAddressData"
              v-on:no-results-found="noSalonResultsFound"
              types="geocode"
              @blur="checkAddress('salon')"
              @focusin.native="clearAddress('salon')"
              @input="toggleSalonAddressFields"
            ></vuetify-google-autocomplete>

            <div v-show="showSalonAddressFields">
              <v-text-field
                      name="salonAddress"
                      :label="$t('practitioner_registration.step_3_building_number_street')"
                      :rules="addressRules"
                      v-model="salonAddress"
                      required
              ></v-text-field>

              <v-text-field
                      name="salonTown"
                      :label="$t('practitioner_registration.step_3_town')"
                      :rules="townRules"
                      v-model="salonTown"
                      required
                      :disabled="true"
              ></v-text-field>

              <v-text-field
                      name="salonPostcode"
                      :label="$t('practitioner_registration.step_3_postcode')"
                      :rules="postcodeRules"
                      v-model="salonPostcode"
                      required
              ></v-text-field>
            </div>
          </v-form>
        </div>
        <!-- <p class="text-xs-center text-sm-center text-md-center">
          <v-btn
            class="add-another-salon"
            flat
            small
            @click="addSalon()"
          >Add salon +</v-btn>
        </p> -->

        <!-- <v-list>
          <v-list-tile
            v-for="item in salonList"
            :key="item.id"
          >
            <v-list-tile-action>
              <v-icon>store</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>{{ [item.postcode,item.town , item.address , item.name].filter(Boolean).join(', ') }}</v-list-tile-content>
            <button @click="removeSalon(item)">
              <v-icon>clear</v-icon>
            </button>
          </v-list-tile>
        </v-list> -->
      </div>
      <div class="text-xs-center step-btn-main">
        <v-btn
          color="error"
          :disabled="!showMobile && !showSalon || beingProcessed"
          @click="checkLocationsInformation()"
        >
          {{$t('practitioner_registration.next')}}
        </v-btn>
      </div>
    </div>

    <template>
      <v-layout row justify-center>
        <v-dialog v-model="showNotificationPopup" persistent max-width="290">
          <v-card class="popup-main-container">
            <v-card-text class="popup-main-text">
              {{$t('practitioner_registration.step_3_select_address_from_drop_down')}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  class="error" flat @click="showNotificationPopup = false"> {{$t('practitioner_registration.step_3_ok')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>

  </div>
</template>

<script>
  import axios from "axios";
  import _ from 'lodash'
  import * as CONFIG from "@/config.js";
  import { empty } from '@/helpers.js';

  /* eslint-disable */

  export default {
    data() {
      return {
        formMobileValid: false,
        formSalonValid: false,

        showMobile: false,
        distances: [
          "1 mile",
          "2 miles",
          "3 miles",
          "4 miles",
          "5 miles",
          "6 miles",
          "Custom"
        ],
        mobileDistance: "1 mile",
        customDistance: null,

        homeAddress: "",
        homeTown: "",
        homePostcode: "",
        homeLatitude: null,
        homeLongitude: null,
        showHomeAddressFields: false,

        addressRules: [v => !!v || this.$i18n.t('practitioner_registration.step_3_js_error_address_required')],
        townRules: [v => !!v || this.$i18n.t('practitioner_registration.step_3_js_error_town_required')],
        postcodeRules: [v => !!v || this.$i18n.t('practitioner_registration.step_3_js_error_postcode_required')],

        distanceRules: [v => !!v || this.$i18n.t('practitioner_registration.step_3_js_error_distance_required')],

        showSalon: false,

        salonName: "",
        salonAddress: "",
        salonTown: "",
        salonPostcode: "",
        salonLatitude: null,
        salonLongitude: null,
        showSalonAddressFields: false,

        countryId: 'UK',
        countryName: 'United Kingdom',

        locations_data: {},
        availability: {},
        showNotificationPopup: false,

        beingProcessed: false
      };
    },
    computed: {
      distance: function () {
        let distance = this.mobileDistance;
        if (this.mobileDistance === "Custom") {
          distance = this.customDistance;
        }
        return parseInt(distance);
      }
    },
    watch: {
      customDistance: _.debounce(function () {
        if (this.customDistance < 1 || this.customDistance > 30) {
          this.$store.commit('SET_NOTIFICATION', {
            text: this.$i18n.t('practitioner_registration.step_3_notif_error_distance'),
            color: 'orange'
          });
        }
      }, 500),
    },
    methods: {
      toggleMobileAddressFields(value){
        if(!empty(value)){
          this.showHomeAddressFields = true;
        }else{
          this.showHomeAddressFields = false;
        }
      },
      toggleSalonAddressFields(value){
        if(!empty(value)){
          this.showSalonAddressFields = true;
        }else{
          this.showSalonAddressFields = false;
        }
      },
      clearAddress(type){
        if (type === 'mobile') {
          this.$refs.mobileAddressRef.clear();
          const element = document.getElementById('mobile-address');
          if(!empty(element)){
            element.value = '';
          }
          delete this.locations_data["1"];
          this.homeAddress = '';
          this.homeTown = '';
          this.homePostcode = '';
          this.homeLatitude = null;
          this.homeLongitude = null;
        } else {
          this.$refs.salonAddressRef.clear();
          const element = document.getElementById('salon-address');
          if(!empty(element)){
            element.value = '';
          }
          delete this.locations_data["2"];
          this.salonAddress = '';
          this.salonTown = '';
          this.salonPostcode = '';
          this.salonLatitude = null;
          this.salonLongitude = null;
        }
      },
      checkAddress: _.debounce(function (type) {
        if (type === 'mobile') {
          const element = document.getElementById('mobile-address');
          let address;
          if(!empty(element)){
             address = element.value;
          }
          // if (!empty(address) && !this.validateMobile()) {
          //   this.showNotificationPopup = true;
          // }
        } else {
          const element = document.getElementById('salon-address');
          let address;
          if(!empty(element)){
            address = element.value;
          }
          // if (!empty(address) && !this.validateSalon()) {
          //   this.showNotificationPopup = true;
          // }
        }
      },1000),
      getHomeAddressData: function (addressData, placeResultData) {
        if (addressData) {
          if (addressData.name && (addressData.name != addressData.postal_code)) {
            this.homeAddress = addressData.name;
          } 
          if ((addressData.name === addressData.postal_code) && !this.homeAddress) {
            this.homeAddress = '';
          }
          if (addressData.locality) {
            this.homeTown = addressData.locality;
          }
          if (addressData.postal_code) {
            this.homePostcode = addressData.postal_code;
          }
          if (addressData.latitude) {
            this.homeLatitude = addressData.latitude;
          }
          if (addressData.longitude) {
            this.homeLongitude = addressData.longitude;
          }

          const address_components = placeResultData.address_components;
          for (var i = 0; i < address_components.length; i++) {
            if (address_components[i].types[0] === 'postal_town') {
              this.homeTown = address_components[i].long_name;
            }
          }

          this.$refs.formMobile.validate();
        }
      },
      noHomeResultsFound() {
        console.log('not found');
      },
      getSalonAddressData: function (addressData, placeResultData) {
        if (addressData) {
          if (addressData.name && (addressData.name != addressData.postal_code)) {
            this.salonAddress = addressData.name;
          } 
          if ((addressData.name === addressData.postal_code) && !this.salonAddress) {
            this.salonAddress = '';
          }
          if (addressData.locality) {
            this.salonTown = addressData.locality;
          }
          if (addressData.postal_code) {
            this.salonPostcode = addressData.postal_code;
          }
          if (addressData.latitude) {
            this.salonLatitude = addressData.latitude;
          }
          if (addressData.longitude) {
            this.salonLongitude = addressData.longitude;
          }

          const address_components = placeResultData.address_components;
          for (var i = 0; i < address_components.length; i++) {
            if (address_components[i].types[0] === 'postal_town') {
              this.salonTown = address_components[i].long_name;
            }
          }

          this.$refs.formSalon.validate();
        }
      },
      noSalonResultsFound() {
        console.log('not found');
      },
      changeStep(step) {
        this.$emit("changeStep", step);
      },
      updateLocations(locations) {
        this.$emit("updateLocations", locations);
      },
      updateAvailability(availability) {
        this.$emit("updateAvailability", availability);
      },
      validateMobile() {
        if (this.$refs.formMobile.validate()) {
          if (this.distance) {
            this.locations_data["1"] = {
              distance: this.distance,
              home_address: {
                city: this.homeTown,
                postcode: this.homePostcode,
                address: this.homeAddress,
                country_id: this.countryId,
                country_name: this.countryName,
                home_location: {
                  lat: this.homeLatitude,
                  lng: this.homeLongitude
                }
              }
            };
            return true;
          } else {
            if (!this.$refs.formDistance.validate()) {
              return false;
            }
          }
        } else {
          if (!this.homeTown && !this.homePostcode) {
            this.showNotificationPopup = true;
          }

          return false;
        }
      },
      validateSalon() {
        if (this.$refs.formSalon.validate()) {
          this.locations_data["2"] = [{
            name: this.salonName ? this.salonName : '',
            address: this.salonAddress,
            city: this.salonTown,
            postcode: this.salonPostcode,
            country_id: this.countryId,
            country_name: this.countryName,
            salon_location: {
              lat: this.salonLatitude,
              lng: this.salonLongitude
            }
          }];
          return true;
        } else {
          if (!this.salonTown && !this.salonPostcode) {
            this.showNotificationPopup = true;
          }

          return false;
        }

        /*
        const salonList = this.salonList;
        if (salonList.length > 0) {
          let salon_list = [];
          for (var i = 0; i < salonList.length; i++) {
            salon_list.push({
              name: salonList[i].name,
              address: salonList[i].address,
              city: salonList[i].town,
              postcode: salonList[i].postcode,
              country_id: this.countryId,
              country_name: this.countryName,
              salon_location: {
                lat: salonList[i].latitude,
                lng: salonList[i].longitude
              }
            });
          };
          this.locations_data["2"] = salon_list;
          return true;
        } else {
          if (
            salonList.length === 0 &&
            this.salonAddress &&
            this.salonTown &&
            this.salonPostcode
          ) {
            this.$refs.formSalon.reset();
            this.$refs.formSalon.validate();
          }
          this.$refs.formSalon.validate();
          return false;
        }
        */
      },
      checkLocationsInformation() {
        this.locations_data = {};

        if (this.showMobile && this.showSalon) {
          if (this.validateMobile() && this.validateSalon()) {
            this.sendLocationsInformation(this.locations_data);
          }
        } else if (this.showMobile && !this.showSalon) {
          if (this.validateMobile()) {
            this.sendLocationsInformation(this.locations_data);
          }
        } else if (!this.showMobile && this.showSalon) {
          if (this.validateSalon()) {
            this.sendLocationsInformation(this.locations_data);
          }
        }
      },
      // addSalon () {
      //   if (this.$refs.formSalon.validate()) {
      //     let salon_name = this.salonName ? this.salonName : '';
      //     let salon = {
      //       id: ++this.last_salon_id,
      //       name: salon_name,
      //       address: this.salonAddress,
      //       town: this.salonTown,
      //       postcode: this.salonPostcode,
      //       latitude: this.salonLatitude,
      //       longitude: this.salonLongitude
      //     };
      //     this.salonList.push(salon);
      //     this.$refs.formSalon.reset();
      //   }
      // },
      // removeSalon (salon) {
      //   this.salonList.splice(this.salonList.indexOf(salon), 1);
      // },
      sendLocationsInformation(locations_data) {
        this.beingProcessed = true;
        axios({
          method: "post",
          url: `${CONFIG.API_URL}/practitioner/locationtypes`,
          data: JSON.stringify(locations_data),
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(response => {
              this.updateLocations({
                mobile: this.showMobile,
                salon: this.showSalon,
                data: this.locations_data
              });

              if (this.showSalon === true) {
                this.getUserSalons()
                  .then(response => {
                    this.beingProcessed = false;
                      // this.setAvailability(response);
                      this.changeStep(4);
                    })
                  .catch(error => {
                    this.beingProcessed = false;
                    console.log(error.response.data);
                    throw error;
                  })
              } else {
                this.beingProcessed = false;
                // this.setAvailability();
                this.changeStep(4);
              }
            })
          .catch(error => {
            this.beingProcessed = false;
            console.log(error.response.data);
            throw error;
          });
      },
      getUserSalons() {
        return axios({
          method: "get",
          url: `${CONFIG.API_URL}/practitioner/salons`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        })
      },
      // setAvailability(response = {}) {
      //   let salonsAvailability = [];
      //   let mobileAvailability = {};
      //
      //   if (this.showSalon === true && !empty(response)) {
      //     let salons = this.getSalonsData(response.data.salons);
      //     for (let index in salons) {
      //       salonsAvailability.push({
      //         'data': {
      //           'id': salons[index].id,
      //           'name': (!empty(salons[index].name)) ? salons[index].name : salons[index].address
      //         },
      //         'schedule': {
      //           "Sunday": {
      //             "show": false,
      //             "available": {
      //               "from": "09:00",
      //               "to": "18:00"
      //             },
      //             "break": []
      //           },
      //           "Monday": {
      //             "show": false,
      //             "available": {
      //               "from": "09:00",
      //               "to": "18:00"
      //             },
      //             "break": []
      //           },
      //           "Tuesday": {
      //             "show": false,
      //             "available": {
      //               "from": "09:00",
      //               "to": "18:00"
      //             },
      //             "break": []
      //           },
      //           "Wednesday": {
      //             "show": false,
      //             "available": {
      //               "from": "09:00",
      //               "to": "18:00"
      //             },
      //             "break": []
      //           },
      //           "Thursday": {
      //             "show": false,
      //             "available": {
      //               "from": "09:00",
      //               "to": "18:00"
      //             },
      //             "break": []
      //           },
      //           "Friday": {
      //             "show": false,
      //             "available": {
      //               "from": "09:00",
      //               "to": "18:00"
      //             },
      //             "break": []
      //           },
      //           "Saturday": {
      //             "show": false,
      //             "available": {
      //               "from": "09:00",
      //               "to": "18:00"
      //             },
      //             "break": []
      //           }
      //         }
      //       });
      //     }
      //   }
      //
      //   if (this.showMobile === true) {
      //     mobileAvailability = {
      //       'data': {
      //         'id': '',
      //         'name': ''
      //       },
      //       'schedule': {
      //         "Sunday": {
      //           "show": false,
      //           "available": {
      //             "from": "09:00",
      //             "to": "18:00"
      //           },
      //           "break": []
      //         },
      //         "Monday": {
      //           "show": false,
      //           "available": {
      //             "from": "09:00",
      //             "to": "18:00"
      //           },
      //           "break": []
      //         },
      //         "Tuesday": {
      //           "show": false,
      //           "available": {
      //             "from": "09:00",
      //             "to": "18:00"
      //           },
      //           "break": []
      //         },
      //         "Wednesday": {
      //           "show": false,
      //           "available": {
      //             "from": "09:00",
      //             "to": "18:00"
      //           },
      //           "break": []
      //         },
      //         "Thursday": {
      //           "show": false,
      //           "available": {
      //             "from": "09:00",
      //             "to": "18:00"
      //           },
      //           "break": []
      //         },
      //         "Friday": {
      //           "show": false,
      //           "available": {
      //             "from": "09:00",
      //             "to": "18:00"
      //           },
      //           "break": []
      //         },
      //         "Saturday": {
      //           "show": false,
      //           "available": {
      //             "from": "09:00",
      //             "to": "18:00"
      //           },
      //           "break": []
      //         }
      //       }
      //     };
      //   }
      //
      //   this.availability['mobile'] = mobileAvailability;
      //   this.availability['salon'] = salonsAvailability;
      //   this.updateAvailability(this.availability);
      // },
      // getSalonsData(salons) {
      //   let data = [];
      //   if (!empty(salons)) {
      //     for (let index in salons) {
      //       data[index] = {
      //         "id": salons[index].id,
      //         "name": salons[index].name,
      //         "postcode": salons[index].postcode,
      //         "address": salons[index].address
      //       }
      //     }
      //   }
      //   return data;
      // }
    }
  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .custom-distance {
    margin-top: -25px;
  }

  .location {
    max-width: 500px;
    margin: 0 auto;

    .main-text {

      &--bold {
        font-weight: 600;
      }


    }


    .v-swith-main{
      &__label {
        min-width: 83px;
        @include r(1500) {
          min-width: 70px;
        }
        @include r(700) {
          min-width: 50px;
        }
      }
    }

    &__center {
      justify-content: center;
      margin-top: 10px;

      &--mt {
        margin-top: 30px;
      }
    }

    &__title {
      font-weight: 700 !important;
      font-family: Nunito !important;
    }

    &__fields {
      max-width: 400px;
      margin: 0 auto;

      @include r(700) {
        max-width: 100%;
      }
    }
    .locations-switch {
      display: flex;
      justify-content: center;

      .v-label {
        font-size: 24px !important;
        min-width: 60px;
        font-weight: 700 !important;
        font-family: Nunito !important;

        @include r(700) {
          min-width: 50px;
        }

        @include r(1700) {
          font-size: 20px !important;
        }

        @include r(1500) {
          font-size: 18px !important;
        }

        @include r(700) {
          font-size: 15px !important;
        }
      }
    }

    .v-input--selection-controls {
      margin-top: 28px;
    }
    .v-text-field.v-text-field--enclosed {
      margin-top: 28px;
    }
    .add-another-salon {
      font-size: 16px;
      margin-top: 30px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      padding: 0;
      height: auto;

      &::before {
        display: none;
      }

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
</style>