<template>
  <v-dialog v-model="mobilePopover" persistent>
    <v-card>
      <v-spacer></v-spacer>
      <v-btn icon @click="close" :style="styleButton" class="popup-close-calendar">
        <v-icon>close</v-icon>
      </v-btn>

      <v-card-text>
        <v-text-field single-line hide-details solo flat
                      class="ds-event-title"
                      label="Title"
                      :readonly="false"
                      v-model="title"
                      @focus="changedTitle"
        ></v-text-field>

        <v-list-tile>
          <v-list-tile-avatar>
            <v-icon>access_time</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{eventPopupDate}}</v-list-tile-title>
            <v-list-tile-sub-title>
              {{eventPopupTime}}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>

        <!--<v-switch v-model="fullDay"></v-switch>-->

        <div class="v-dialog__toggle">
          <toggle-button
            :labels="{checked: 'Yes', unchecked: 'No'}"
            :color="{checked: '#6ACE63', unchecked: '#FF5A5F'}"
            :width="55"
            :height="25"
            v-model="fullDay"
          />
          <span class="v-dialog__toggle-title">Full day</span>
        </div>

        <template v-if="!fullDay">
          <div class="filter__inner filter__inner--datepicker">
            <v-date-picker
              v-model="selectedDate"
              full-width
              no-title
              color="error lighten-1"
              :min="currentDate"
              class="elevation-0"
              @input="changeDate"
            ></v-date-picker>
          </div>


          <div class="filter__inner filter-time">
            <div class="availability__time-inners availability-hours">
              <div class="availability__time-inner">
                <div class="availability__time-title" @click="initTime('sdfsd')">From</div>
                <!-- from -->
                <VueCtkDateTimePicker
                  v-model="startTime"
                  formatted="HH:mm"
                  format="HH:mm"
                  :minute-interval="15"
                  only-time
                  label=""
                  @input="changedTime('start')"
                ></VueCtkDateTimePicker>
              </div>
              <div class="availability__time-inner">
                <div class="availability__time-title">To</div>
                <!-- to -->
                <VueCtkDateTimePicker
                  v-model="endTime"
                  formatted="HH:mm"
                  format="HH:mm"
                  :minute-interval="15"
                  only-time
                  label=""
                  @input="changedTime('end')"
                ></VueCtkDateTimePicker>
              </div>
            </div>
          </div>
        </template>

        <div class="checkout__form-field checkout__form-field--payment-hide">
          <v-text-field
            v-if="startErrorMess.length > 0"
            type="hidden"
            :error-messages="startErrorMess"
            solo
          ></v-text-field>
          <v-text-field
            v-if="endErrorMess.length > 0"
            type="hidden"
            :error-messages="endErrorMess"
            solo
          ></v-text-field>
          <v-text-field
            v-if="messageErrorMess.length > 0"
            type="hidden"
            :error-messages="messageErrorMess"
            solo
          ></v-text-field>
        </div>

        <div class="v-dialog__action">
          <v-btn
            class="ds-button-tall ml-3 mt-0 mb-2" depressed
            color="primary"
            @click="save"
            :disabled="!canSaveEvent"
          >
            <span>Save</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment';
  import axios from 'axios';
  import * as CONFIG from '@/config.js'
  import {empty, isEmptyObj} from '@/helpers.js';

  export default {
    name: "customEventPopoverForMobile",
    props: ['mobilePopover'],
    data() {
      return {
        isTitleFormValid: true,
        title: '',
        messageErrorMess: [],
        startErrorMess: [],
        endErrorMess: [],
        fullDay: true,

        currentDate: '',

        selectedDate: '',
        startTime: '',
        endTime: ''
      }
    },
    mounted() {
      this.initPopup();
    },
    watch: {
      'fullDay': function () {
        this.messageErrorMess = [];
        this.startErrorMess = [];
        this.endErrorMess = [];
      },
      'mobilePopover': function (value) {
        if (value) {
          this.initPopup();
        }
      }
    },
    computed: {
      details() {
        return this.$dayspan.defaults.calendarEvent.event.data;
      },
      canSaveEvent() {
        return empty(this.messageErrorMess);
      },
      eventPopupDate() {
        if (this.fullDay === true) {
          return moment(this.currentDate, "YYYY-MM-DD").format("dddd Do MMM");
        } else {
          return moment(this.selectedDate, "YYYY-MM-DD").format("dddd Do MMM");
        }
      },
      eventPopupTime() {
        if (this.fullDay === true) {
          return 'Full day';
        } else if (!empty(this.startTime) && !empty(this.endTime)) {
          return `${moment(this.startTime, 'HH:mm').format('LT')} - ${moment(this.endTime, 'HH:mm').format('LT')}`
        } else {
          return '';
        }
      }
    },
    methods: {
      initPopup() {
        this.fullDay = true;
        this.messageErrorMess = [];
        this.startErrorMess = [];
        this.endErrorMess = [];
        this.currentDate = moment().clone().format("YYYY-MM-DD");
        this.selectedDate = moment().clone().format("YYYY-MM-DD");
        this.startTime = this.initTime('start');
        this.endTime = this.initTime('end');
      },
      initTime(type) {
        const minutes = moment().clone().minute();
        const remOfDivision = minutes % 15;

        if (remOfDivision === 0) {
          if (type === 'start') {
            return moment().clone().format("HH:mm");
          } else {
            return moment().clone().add(1, 'h').format("HH:mm");
          }
        } else {
          if (type === 'start') {
            return moment().clone().minute(minutes - remOfDivision).format("HH:mm");
          } else {
            return moment().clone().add(1, 'h').minute(minutes - remOfDivision).format("HH:mm");
          }
        }
      },
      save() {
        let data = {};
        data['date'] = this.selectedDate;
        data['title'] = this.title;

        if (this.isValidTitle() && this.fullDay === true) {
          data['full_day_off'] = true;
          this.saveEvent(data)
            .then(() => {
              this.$emit('updateCalendarState');
              this.$emit('closeCustomMobilePopup');
            })
            .catch((error) => {
              if (error.response && !empty(error.response.data)) {
                this.addError(error.response.data)
              }
            });
        } else if (this.isValidTitle() && this.fullDay === false && this.isValidDate()) {
          data['full_day_off'] = false;
          data['start_time'] = moment(this.startTime, 'HH:mm').format('HH:mm:ss');
          data['end_time'] = moment(this.endTime, 'HH:mm').format('HH:mm:ss');

          this.saveEvent(data)
            .then(() => {
              this.$emit('updateCalendarState');
              this.$emit('closeCustomMobilePopup');
            })
            .catch((error) => {
              if (error.response && !empty(error.response.data)) {
                this.addError(error.response.data)
              }
            });
        }
      },
      saveEvent(data) {
        return axios({
          method: 'post',
          url: `${CONFIG.API_URL}/dashboard/practitioner/timeoff`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      addError(errors) {
        if (!isEmptyObj(errors.errors)) {
          for (let field in errors.errors) {
            for (let i = 0; i < errors.errors[field].length; i++) {
              this.messageErrorMess.push(errors.errors[field][i]);
            }
          }
        } else if (!empty(errors.message)) {
          this.messageErrorMess.push(errors.message);
        }
      },
      isValidTitle() {
        if (!empty(this.title)) {
          return true;
        } else {
          this.messageErrorMess.push('Title field is required');
          return false;
        }
      },
      isValidDate() {

        if (!empty(this.startTime) && !empty(this.endTime)) {
          return true;
        } else {
          if (empty(this.startTime)) {
            this.startErrorMess.push('Start time is required');
          }
          if (empty(this.endTime)) {
            this.endErrorMess.push('End time is required');
          }

          return false;
        }
      },
      changedTitle() {
        this.messageErrorMess = [];
      },
      changedTime(type) {
        this.messageErrorMess = [];
        if (type === 'start') {
          this.startErrorMess = [];
        } else {
          this.endErrorMess = [];
        }
      },
      changeDate() {
        this.messageErrorMess = [];
        this.startErrorMess = [];
        this.endErrorMess = [];
      },
      close() {
        this.$emit('closeCustomMobilePopup');
      },
      styleHeader() {
        return {
          backgroundColor: this.details.color,
          color: this.details.forecolor
        };
      },
      styleButton() {
        return {
          color: this.details.forecolor
        };
      },
    }
  }
</script>

<style scoped lang="scss">

  .v-dialog {
    &__action {
      display: flex;
      justify-content: flex-end;
    }
    &__toggle {
      display: flex;
      align-items: center;
      margin: 10px 0 10px 30px;

      &-title {
        font-size: 16px;
        display: inline-block;
        margin-left: 20px;
      }
    }
    .v-list__tile__sub-title {
      display: none;
    }
    .v-list__tile__avatar {
      min-width: 46px;
    }
  }

</style>