<template>
  <v-menu>
    <v-btn flat slot="activator">
      {{ currentType.label }}
      <v-icon>arrow_drop_down</v-icon>
    </v-btn>
    <v-list>
      <v-list-tile v-for="type in filteredTypes"
                   :key="type.id"
                   @click="setCurrentType(type)">
        <v-list-tile-content>
          <v-list-tile-title>{{ type.label }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: "customViewForSlot",
    props:[ 'currentType', 'types', 'mobileWidth'],
    data(){
      return{
        displayedTypes:['D', 'W', 'M'],
        displayedMobileTypes:['D', 'X', 'M'],
      }
    },
    computed: {
      filteredTypes() {
        if (!this.mobileWidth) {
          return this.types.filter(type => {
            return this.displayedTypes.includes(type.id);
          });
        } else {
          return this.types.filter(type => {
            return this.displayedMobileTypes.includes(type.id);
          });
        }
      }
    },
    methods:{
      setCurrentType(type){
        this.$emit('custom-set-type', type)
      }
    }
  }
</script>

<style scoped>

</style>