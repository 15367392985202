<template>
  <div class="dashboard-content" id="dashboard-content">
    <div class="dashboard-customer">
      <div class="dashboard-content__title">
        <h2>
          {{$t('customer_profile.settings_tab_title')}}
        </h2>
        <BookingCounter></BookingCounter>
      </div>
      <p>
      {{$t('customer_profile.settings_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">
      <div class="dashboard-settings__container">
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('customer_profile.settings_tab_profile_status')}}
          </div>
          <div class="dashboard-settings__inners">
            <div class="dashboard-settings__inner dashboard-settings__inner--sm">
              <div class="dashboard-settings__field">
                <v-form
                  ref="activeForm"
                  v-model="isActiveFormValid"
                  lazy-validation
                >
                  <v-select
                    :items="profileStatuses"
                    v-model="active"
                    :error-messages="activeErrorMess"
                    :rules="activeRules"
                    item-text="text"
                    item-value="value"
                    solo
                    hide-details
                    color="error"
                    light
                    required
                  ></v-select>
                </v-form>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('customer_profile.settings_tab_change_password')}}
          </div>

          <v-form
            ref="passwordForm"
            v-model="isPasswordFormValid"
            lazy-validation
          >
            <div class="dashboard-settings__inners">
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="password"
                    :error-messages="old_passwordErrorMess"
                    v-model="old_password"
                    @change="storePassword('old_password')"
                    :label="$t('customer_profile.settings_tab_enter_old_password')"
                    solo
                  ></v-text-field>
                </div>
              </div>
            </div>

            <div class="dashboard-settings__inners">
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="password"
                    v-model="password"
                    :error-messages="passwordErrorMess"
                    @change="storePassword('password')"
                    :label="$t('customer_profile.settings_tab_enter_new_password')"
                    solo
                  ></v-text-field>
                </div>
              </div>
              <div class="dashboard-settings__inner dashboard-settings__inner--md">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="password"
                    v-model="password_confirmation"
                    :error-messages="password_confirmationErrorMess"
                    @change="storePassword('password_confirmation')"
                    :label="$t('customer_profile.settings_tab_confirm_new_password')"
                    solo
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('customer_profile.settings_tab_update_email_address')}}
          </div>

          <v-form
            ref="emailForm"
            v-model="isEmailFormValid"
            lazy-validation
          >
            <div class="dashboard-settings__inners">

              <div class="dashboard-settings__inner dashboard-settings__inner--lg">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="email"
                    :value="this.$store.getters.currentUser.email"
                    :label="$t('customer_profile.settings_tab_enter_old_address')"
                    :disabled="true"
                    solo
                  ></v-text-field>
                </div>
              </div>

              <div class="dashboard-settings__inner dashboard-settings__inner--lg">
                <div class="dashboard-settings__field">
                  <v-text-field
                    type="email"
                    v-model="email"
                    :error-messages="emailErrorMess"
                    :label="$t('customer_profile.settings_tab_enter_new_address')"
                    @change="storeEmail"
                    solo
                  ></v-text-field>
                </div>
              </div>

            </div>
          </v-form>
        </div>

        <div class="dashboard-settings__holder">
          <div class="dashboard-settings__title">
            {{$t('customer_profile.settings_tab_delete_account_block_title')}}
          </div>
          <div class="dashboard-settings__inners">
            <div class="dashboard-settings__inner dashboard-settings__inner--md">
              <div class="dashboard-settings__field">
                   <span class="profile-search__categories d-block"
                         @click="showDeleteAccountDialog"
                   >
                      {{$t('customer_profile.settings_tab_delete_account_button')}}
                   </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template>
      <v-layout row justify-center>
        <v-dialog v-model="deleteAccountDialog" persistent max-width="290">
          <v-card>
            <v-card-text class="text-lg-center text-md-center text-sm-center text-xs-center">{{$t('customer_profile.settings_tab_delete_account_desc_popup')}}</v-card-text>
            <v-card-text v-if="showDeleteAccProgress">
              <v-progress-linear
                      indeterminate
                      color="error"
                      height="3"
              ></v-progress-linear>
            </v-card-text>
            <v-card-actions>
              <v-btn
                      color="red darken-1"
                      flat
                      @click="deleteAccount"
                      :disabled="disableButtons"
              >
                {{$t('practitioner_profile.booking_tab_cancel_popup_yes')}}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" flat
                     @click="deleteAccountDialog = false"
                     :disabled="disableButtons"
              >
                {{$t('practitioner_profile.booking_tab_cancel_popup_no')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>

  </div>
</template>

<script>
  import axios from "axios";
  import * as CONFIG from "@/config.js";
  import {empty} from '@/helpers.js';
  import BookingCounter from '@/components/CustomerDashboard/Partial/BookingCounter'

  /* eslint-disable */

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Customer Settings'
    },
    components:{
      BookingCounter
    },
    data: function () {
      return {
        active: null,
        activeErrorMess: [],
        activeRules: [
          v => !empty(v) || this.$i18n.t('customer_profile.settings_tab_js_error_status_required'),
        ],
        profileStatuses: [
          {text: this.$i18n.t('customer_profile.settings_tab_active'), value: true},
          {text: this.$i18n.t('customer_profile.settings_tab_inactive'), value: false},
        ],
        isActiveFormValid: true,

        password: '',
        passwordErrorMess: [],
        old_password: '',
        old_passwordErrorMess: [],
        password_confirmation: '',
        password_confirmationErrorMess: [],
        isPasswordFormValid: true,

        email: '',
        emailErrorMess: [],

        isEmailFormValid: true,

        deleteAccountDialog: false,
        showDeleteAccProgress: false,
        disableButtons: false

      }
    },
    created: function () {
      this.getUserProfileSettings()
        .then((response) => {
          this.active = response.data.active;
          this.setWatch();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    methods: {
      showDeleteAccountDialog(){
        this.deleteAccountDialog = true;
      },
      hideDeleteAccountDialog(){
        this.deleteAccountDialog = false;
        this.disableButtons = false;
        this.showDeleteAccProgress = false;
      },
      deleteAccount(){
        this.disableButtons = true;
        this.showDeleteAccProgress = true;

        axios({
          method: 'delete',
          url: `${CONFIG.API_URL}/user`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
          .then(() => {
            this.hideDeleteAccountDialog();
            this.$store.commit('RESET_AUTH_VARIABLES');
            this.$store.dispatch('RESET_CURRENT_USER_DATA');
            this.$store.commit('SET_NOTIFICATION', {
              text: this.$i18n.t('customer_profile.settings_tab_delete_account_successfully'),
              color: 'error'
            });
            this.$router.push({path: '/'})
          })
          .catch((error) => {
            this.hideDeleteAccountDialog();
            if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.message)) {
              this.$store.commit('SET_NOTIFICATION', {
                text: error.response.data.message,
                color: 'orange'
              });
            }
            console.error(error);
          })
      },
      showNotification() {
        this.$store.commit('CLEAR_NOTIFICATION');
        const that = this;
        setTimeout((that) => {
          that.$store.commit('SET_NOTIFICATION', {
            text: this.$i18n.t('customer_profile.settings_tab_successfully_updated'),
            color: 'error'
          });
        }, 200, that);
      },
      storeStatus(status) {
        this.activeErrorMess = [];
        if (this.$refs.activeForm.validate()) {
          this.updateUserProfileSettings({'active': status})
            .then(() => {
              this.showNotification();
            })
            .catch((error) => {
              if (!empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              }
            })
        }
      },
      storePassword(name) {
        this[`${name}ErrorMess`] = [];
        this.validatePassword(name);
        if (empty(this.passwordErrorMess) && empty(this.old_passwordErrorMess) && empty(this.password_confirmationErrorMess)) {
          this.updateUserProfileSettings({
            'password': this.password,
            'old_password': this.old_password,
            'password_confirmation': this.password_confirmation,
          })
            .then(() => {
              this.password = '';
              this.old_password = '';
              this.password_confirmation = '';
              this.showNotification();
            })
            .catch((error) => {
              if (!empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              }
            });
        }
      },
      validatePassword() {
        if (empty(this.password)) {
          this.passwordErrorMess.push(this.$i18n.t('auth_forms.js_error_password_required'))
        }
        if (empty(this.old_password)) {
          this.old_passwordErrorMess.push(this.$i18n.t('auth_forms.js_error_password_required'))
        }
        if (empty(this.password_confirmation)) {
          this.password_confirmationErrorMess.push(this.$i18n.t('auth_forms.js_error_password_required'))
        }
        if (this.password !== this.password_confirmation) {
          this.password_confirmationErrorMess.push(this.$i18n.t('auth_forms.js_error_password_confirmation_required'))
        }
      },
      storeEmail(email) {
        this.emailErrorMess = [];
        this.validateEmail(email);
        if (empty(this.emailErrorMess)) {
          this.updateUserProfileSettings({'email': email})
            .then(() => {
              this.updateCurrentUserStore({'email': email});
              this.showNotification();
            })
            .catch((error) => {
              if (!empty(error.response.data.errors)) {
                this.fieldsErrorFromServer(error.response.data.errors);
              }
            });
        }
      },
      validateEmail(email) {
        if (empty(email)) {
          this.emailErrorMess.push(this.$i18n.t('auth_forms.js_error_email_required'))
        }
        if (!(/.+@.+/.test(email))) {
          this.emailErrorMess.push(this.$i18n.t('auth_forms.js_error_email_format'))
        }
      },
      setWatch() {
        this.$watch('active', (status) => this.storeStatus(status));
      },
      getUserProfileSettings() {
        return axios({
          method: 'get',
          url: `${CONFIG.API_URL}/dashboard/user/settings`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      },
      fieldsErrorFromServer(errors) {
        if (!empty(errors)) {
          for (let field in errors) {
            for (let i = 0; i < errors[field].length; i++) {
              this[`${field}ErrorMess`].push(errors[field][i]);
            }
          }
        }
      },
      updateUserProfileSettings(data) {
        return axios({
          method: 'put',
          url: `${CONFIG.API_URL}/dashboard/user/settings`,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          data: JSON.stringify(data)
        });
      },
      updateCurrentUserStore(data) {
        if (!empty(Object.keys(data)[0])) {
          if (['email'].includes(Object.keys(data)[0])) {
            this[Object.keys(data)[0]] = null;
            this.$store.commit('SET_CURRENT_USER_DATA', data);
          }
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .dashboard-settings {
    &__container {
      max-width: 570px;
    }

    &__holder {
      display: block;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      display: block;
      color: #707070;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 600;
    }

    &__inners {
      display: flex;
      margin: 20px -15px 0 -15px;
      align-items: center;
    }

    &__inner {
      flex: 0 0 auto;
      padding: 0 15px;

      &--sm {
        width: 243px;
      }

      &--md {
        width: 294px;
      }

      &--lg {
        width: 418px;
      }
    }

    &__link {
      color: #a3a3a3;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 400;
      border-bottom: 1px solid transparent;
      transition: 0.3s;
      text-decoration: none;

      &:hover {
        border-bottom-color: #a3a3a3;
        transition: 0.3s;
      }
    }

    &__field {
      display: block;

      input {
        width: 100%;
        height: 55px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background-color: #fff;
        text-align: center;
        padding: 0 12px;
        color: #707070;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
      }

      label {
        color: #a3a3a3;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
        display: inline-block;
        margin: 0 0 20px 40px;
      }

      select {
        width: 100%;
        height: 55px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background-color: #fff;
        text-align: center;
        padding: 0 12px;
        color: #707070;
        font-family: Nunito;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

</style>
