<template>

  <div class="how-it-works">
    <div class="how-it-works__wrapper">
      <div class="how-it-works__content">
        <div class="how-it-works__title">
          <strong>
            {{$t('how_it_works.how_it_works_page_title')}}
          </strong>
          <span>
            {{$t('how_it_works.how_it_works_page_desc')}}
          </span>
          <v-btn
            @click="searchService()"
            class="error"
          >
            {{$t('how_it_works.get_started')}}
          </v-btn>
        </div>
        <div class="how-it-works__holder">
          <div class="how-it-works__navigation">
            <div class="how-it-works__buttons">
              <div
                @click="displayTab('customer')"
                v-bind:class="{ 'is-active': isActiveCustomer }"
                class="how-it-works__button"
              ><span>
                {{$t('how_it_works.customer_tab_button')}}
              </span>
              </div>
              <div
                @click="displayTab('practitioner')"
                v-bind:class="{ 'is-active': isActivePractitioner }"
                class="how-it-works__button"
              ><span>
                {{$t('how_it_works.practitioner_tab_button')}}
              </span>
              </div>
              <div
                @click="displayTab('faq')"
                v-bind:class="{ 'is-active': isActiveFaq }"
                class="how-it-works__button"
              >
                <span>
                  {{$t('how_it_works.faqs_tab_button')}}
                </span>
              </div>
            </div>
          </div>
          <div class="how-it-works__bodies">
            <div
              v-if="tabName === 'customer'"
              class="how-it-works__body"
            >
              <div class="how-it-works__inners">
                <div class="how-it-works__inner">
                  <div class="how-it-works__inner-description">
                    <div class="how-it-works__inner-title">
                      <strong>{{$t('how_it_works.customer_tab_section_1_title')}}</strong>
                    </div>
                    <div class="how-it-works__inner-items">
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>{{$t('how_it_works.customer_tab_section_1_paragraph_1_title')}}</strong> {{$t('how_it_works.customer_tab_section_1_paragraph_1_desc')}}
                        </p>
                      </div>
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>{{$t('how_it_works.customer_tab_section_1_paragraph_2_title')}}</strong> {{$t('how_it_works.customer_tab_section_1_paragraph_2_desc')}}
                        </p>
                      </div>
                    </div>
                    <div class="how-it-works__questions">
                      <v-expansion-panel>
                        <v-expansion-panel-content
                          v-for="item in content"
                          :key="item.id"
                        >
                          <div slot="header">
                            <div class="how-it-works__questions-header">{{ item.header }}</div>
                          </div>
                          <v-card>
                            <v-card-text>
                              <div class="how-it-works__questions-description">{{ item.description }}</div>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </div>
                  </div>
                  <div class="how-it-works__inner-image">
                    <img
                      src="../.././src/images/pages/hotitworks/hit-customer-1.svg"
                      alt=""
                    >
                  </div>
                </div>
                <div class="how-it-works__inner how-it-works__inner--right">
                  <div class="how-it-works__inner-description">
                    <div class="how-it-works__inner-title">
                      {{$t('how_it_works.customer_tab_section_2_title')}}
                    </div>
                    <div class="how-it-works__inner-items">
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>{{$t('how_it_works.customer_tab_section_2_paragraph_1_title')}}</strong>{{$t('how_it_works.customer_tab_section_2_paragraph_1_desc')}}</p>
                      </div>
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>{{$t('how_it_works.customer_tab_section_2_paragraph_2_title')}}</strong>{{$t('how_it_works.customer_tab_section_2_paragraph_2_desc')}}</p>
                      </div>
                    </div>
                    <div class="how-it-works__questions">
                      <v-expansion-panel>
                        <v-expansion-panel-content
                          v-for="item in content2"
                          :key="item.id"
                        >
                          <div slot="header">
                            <div class="how-it-works__questions-header">{{ item.header }}</div>
                          </div>
                          <v-card>
                            <v-card-text>
                              <div class="how-it-works__questions-description">{{ item.description }}</div>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </div>
                  </div>
                  <div class="how-it-works__inner-image">
                    <img
                      src="../.././src/images/pages/hotitworks/hit-customer-2.svg"
                      alt=""
                    >
                  </div>
                </div>
                <div class="how-it-works__inner">
                  <div class="how-it-works__inner-description">
                    <div class="how-it-works__inner-title">
                      {{$t('how_it_works.customer_tab_section_3_title')}}
                    </div>
                    <div class="how-it-works__inner-items">
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>{{$t('how_it_works.customer_tab_section_3_paragraph_1_title')}}</strong>
                          {{$t('how_it_works.customer_tab_section_3_paragraph_1_desc')}}
                        </p>
                      </div>
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>{{$t('how_it_works.customer_tab_section_3_paragraph_2_title')}}</strong>
                          {{$t('how_it_works.customer_tab_section_3_paragraph_2_desc')}}
                        </p>
                      </div>
                    </div>
                    <div class="how-it-works__questions">
                      <v-expansion-panel>
                        <v-expansion-panel-content
                          v-for="item in content3"
                          :key="item.id"
                        >
                          <div slot="header">
                            <div class="how-it-works__questions-header">{{ item.header }}</div>
                          </div>
                          <v-card>
                            <v-card-text>
                              <div class="how-it-works__questions-description">{{ item.description }}</div>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </div>
                  </div>
                  <div class="how-it-works__inner-image">
                    <img
                      src="../.././src/images/pages/hotitworks/hit-customer-3.svg"
                      alt=""
                    >
                  </div>
                </div>
                <div class="how-it-works__inner how-it-works__inner--right">
                  <div class="how-it-works__inner-description">
                    <div class="how-it-works__inner-title">
                      {{$t('how_it_works.customer_tab_section_4_title')}}
                    </div>
                    <div class="how-it-works__inner-items">
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>
                            {{$t('how_it_works.customer_tab_section_4_paragraph_1_title')}}
                          </strong>
                          {{$t('how_it_works.customer_tab_section_4_paragraph_1_desc')}}
                        </p>
                      </div>
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>
                            {{$t('how_it_works.customer_tab_section_4_paragraph_2_title')}}
                          </strong>
                          {{$t('how_it_works.customer_tab_section_4_paragraph_2_desc')}}
                        </p>
                      </div>
                    </div>
                    <div class="how-it-works__questions">
                      <v-expansion-panel>
                        <v-expansion-panel-content
                          v-for="item in content4"
                          :key="item.id"
                        >
                          <div slot="header">
                            <div class="how-it-works__questions-header">{{ item.header }}</div>
                          </div>
                          <v-card>
                            <v-card-text>
                              <div class="how-it-works__questions-description">{{ item.description }}</div>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </div>
                  </div>
                  <div class="how-it-works__inner-image">
                    <img
                      src="../.././src/images/pages/hotitworks/hit-customer-4.svg"
                      alt=""
                    >
                  </div>
                </div>
              </div>
              <div class="how-it-works__action">
                <v-btn
                  @click="searchService()"
                  class="error"
                >
                  {{ $t('how_it_works.customer_tab_get_started') }}
                </v-btn>
              </div>
            </div>
            <div
              v-if="tabName === 'practitioner'"
              class="how-it-works__body"
            >
              <div class="how-it-works__inners">
                <div class="how-it-works__inner">
                  <div class="how-it-works__inner-description">
                    <div class="how-it-works__inner-title">
                      {{$t('how_it_works.practitioner_tab_section_1_title')}}
                    </div>
                    <div class="how-it-works__inner-items">
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>
                            {{$t('how_it_works.practitioner_tab_section_1_paragraph_1_title')}}
                          </strong>
                          {{$t('how_it_works.practitioner_tab_section_1_paragraph_1_desc')}}
                        </p>
                      </div>
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>
                            {{$t('how_it_works.practitioner_tab_section_1_paragraph_2_title')}}
                          </strong>
                          {{$t('how_it_works.practitioner_tab_section_1_paragraph_2_desc')}}
                        </p>
                      </div>
                    </div>
                    <div class="how-it-works__questions">
                      <v-expansion-panel>
                        <v-expansion-panel-content>
                          <div slot="header">
                            <div class="how-it-works__questions-header">
                              {{$t('how_it_works.practitioner_tab_section_1_paragraph_3_title')}}
                            </div>
                          </div>
                          <v-card>
                            <v-card-text>
                              <div class="how-it-works__questions-description">
                                {{$t('how_it_works.practitioner_tab_section_1_paragraph_3_desc')}}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </div>
                  </div>
                  <div class="how-it-works__inner-image">
                    <img
                      src="../.././src/images/pages/hotitworks/hit-pract-1.svg"
                      alt=""
                    >
                  </div>
                </div>
                <div class="how-it-works__inner how-it-works__inner--right">
                  <div class="how-it-works__inner-description">
                    <div class="how-it-works__inner-title">
                      {{$t('how_it_works.practitioner_tab_section_2_title')}}
                    </div>
                    <div class="how-it-works__inner-items">
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>
                            {{$t('how_it_works.practitioner_tab_section_2_paragraph_1_title')}}
                          </strong>
                          {{$t('how_it_works.practitioner_tab_section_2_paragraph_1_desc')}}
                        </p>
                      </div>
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>
                            {{$t('how_it_works.practitioner_tab_section_2_paragraph_2_title')}}
                          </strong>
                          {{$t('how_it_works.practitioner_tab_section_2_paragraph_2_desc')}}
                        </p>
                      </div>
                    </div>
                    <div class="how-it-works__questions">
                      <v-expansion-panel>
                        <v-expansion-panel-content>
                          <div slot="header">
                            <div class="how-it-works__questions-header">
                              {{$t('how_it_works.practitioner_tab_section_2_paragraph_3_title')}}
                            </div>
                          </div>
                          <v-card>
                            <v-card-text>
                              <div class="how-it-works__questions-description">
                                {{$t('how_it_works.practitioner_tab_section_2_paragraph_3_desc')}}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </div>
                  </div>
                  <div class="how-it-works__inner-image">
                    <img
                      src="../.././src/images/pages/hotitworks/hit-pract-2.svg"
                      alt=""
                    >
                  </div>
                </div>
                <div class="how-it-works__inner">
                  <div class="how-it-works__inner-description">
                    <div class="how-it-works__inner-title">
                      {{$t('how_it_works.practitioner_tab_section_3_title')}}
                    </div>
                    <div class="how-it-works__inner-items">
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>
                            {{$t('how_it_works.practitioner_tab_section_3_paragraph_1_title')}}
                          </strong>
                          {{$t('how_it_works.practitioner_tab_section_3_paragraph_1_desc')}}
                        </p>
                      </div>
                      <div class="how-it-works__inner-item">
                        <p>
                          <strong>
                            {{$t('how_it_works.practitioner_tab_section_3_paragraph_2_title')}}
                          </strong>
                          {{$t('how_it_works.practitioner_tab_section_3_paragraph_2_desc')}}
                        </p>
                      </div>
                    </div>
                    <div class="how-it-works__questions">
                      <v-expansion-panel>
                        <v-expansion-panel-content>
                          <div slot="header">
                            <div class="how-it-works__questions-header">
                              {{$t('how_it_works.practitioner_tab_section_3_paragraph_3_title')}}
                            </div>
                          </div>
                          <v-card>
                            <v-card-text>
                              <div class="how-it-works__questions-description">
                                {{$t('how_it_works.practitioner_tab_section_3_paragraph_3_desc')}}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </div>
                  </div>
                  <div class="how-it-works__inner-image">
                    <img
                      src="../.././src/images/pages/hotitworks/hit-pract-3.svg"
                      alt=""
                    >
                  </div>
                </div>
              </div>
              <div class="how-it-works__action">
                <v-btn
                  @click="searchService()"
                  class="error"
                >
                  {{$t('how_it_works.practitioner_tab_get_started')}}
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="tabName === 'faq'"
              class="how-it-works__body is-faq"
            >
              <div class="how-it-works__faq">
                <div class="how-it-works__faq-navigation">
                  <div class="how-it-works__faq-buttons">
                    <div
                      @click="displayFaqTab('customer')"
                      v-bind:class="{ 'is-active': isActiveCustomerFaq }"
                      class="how-it-works__faq-button"
                    >
                      {{$t('how_it_works.faqs_tab_customer_sub_tab_title')}}
                    </div>
                    <div
                      @click="displayFaqTab('practitioner')"
                      v-bind:class="{ 'is-active': isActivePractitionerFaq }"
                      class="how-it-works__faq-button"
                    >
                      {{$t('how_it_works.faqs_tab_practitioner_sub_tab_title')}}
                    </div>
                  </div>
                </div>
                <div
                  v-if="customerRole === 'customer'"
                  class="how-it-works__faq-bodies"
                >
                  <div class="how-it-works__faq-body">
                    <v-expansion-panel>
                      <v-expansion-panel-content
                        v-for="item in customerFaqs"
                        :key="item.id"
                      >
                        <div slot="header">
                          <div class="support__faq-header">{{ item.header }}</div>
                          <div class="support__faq-subheader">{{ item.subheader }}</div>
                        </div>
                        <v-card>
                          <v-card-text>
                            <div class="support__faq-description">{{ item.description }}</div>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </div>
                </div>
                <div
                  v-if="customerRole === 'practitioner'"
                  class="how-it-works__faq-bodies"
                >
                  <div class="how-it-works__faq-body">
                    <v-expansion-panel>
                      <v-expansion-panel-content
                        v-for="item in practitionerFaqs"
                        :key="item.id"
                      >
                        <div slot="header">
                          <div class="support__faq-header">{{ item.header }}</div>
                          <div class="support__faq-subheader">{{ item.subheader }}</div>
                        </div>
                        <v-card>
                          <v-card-text>
                            <div class="support__faq-description">{{ item.description }}</div>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </div>
                </div>
              </div>
              <div class="how-it-works__action">
                <v-btn @click="onFullFAQ()" class="error">
                  {{$t('how_it_works.faqs_tab_view_full_faqs')}}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable */

export default {
  name: "HowItWorks",
  metaInfo: {
    title: 'Freely',
    titleTemplate: '%s | How It Works'
  },
  data () {
    return {
      tabName: 'customer',
      customerRole: 'customer',

      isActiveCustomer: true,
      isActivePractitioner: false,
      isActiveFaq: false,

      isActiveCustomerFaq: true,
      isActivePractitionerFaq: false,
      content: [
        {
          id: 1,
          header: this.$i18n.t('how_it_works.customer_tab_section_1_paragraph_3_title'),
          description: this.$i18n.t('how_it_works.customer_tab_section_1_paragraph_3_desc')
        }
      ],
      content2: [
        {
          id: 2,
          header: this.$i18n.t('how_it_works.customer_tab_section_2_paragraph_3_title'),
          description: this.$i18n.t('how_it_works.customer_tab_section_2_paragraph_3_desc')
        }
      ],
      content3: [
        {
          id: 3,
          header: this.$i18n.t('how_it_works.customer_tab_section_3_paragraph_3_title'),
          description: this.$i18n.t('how_it_works.customer_tab_section_3_paragraph_3_desc')
        }
      ],
      content4: [
        {
          id: 4,
          header: this.$i18n.t('how_it_works.customer_tab_section_4_paragraph_3_title'),
          description: this.$i18n.t('how_it_works.customer_tab_section_4_paragraph_3_desc')
        }
      ],
      customerFaqs: [
        {
          id: 1,
          header: this.$i18n.t('how_it_works.faqs_tab_customer_header_1'),
          subheader: this.$i18n.t('how_it_works.faqs_tab_customer_subheader_1'),
          description: this.$i18n.t('how_it_works.faqs_tab_customer_desc_1'),
        },
        {
          id: 2,
          header: this.$i18n.t('how_it_works.faqs_tab_customer_header_2'),
          subheader: this.$i18n.t('how_it_works.faqs_tab_customer_subheader_2'),
          description: this.$i18n.t('how_it_works.faqs_tab_customer_desc_2'),
        },
        {
          id: 3,
          header: this.$i18n.t('how_it_works.faqs_tab_customer_header_3'),
          subheader: this.$i18n.t('how_it_works.faqs_tab_customer_subheader_3'),
          description: this.$i18n.t('how_it_works.faqs_tab_customer_desc_3'),
        },
        {
          id: 4,
          header: this.$i18n.t('how_it_works.faqs_tab_customer_header_4'),
          subheader: this.$i18n.t('how_it_works.faqs_tab_customer_subheader_4'),
          description: this.$i18n.t('how_it_works.faqs_tab_customer_desc_4'),
        }
      ],
      practitionerFaqs:[
        {
          id: 1,
          header: this.$i18n.t('how_it_works.faqs_tab_practitioner_header_1'),
          subheader: this.$i18n.t('how_it_works.faqs_tab_practitioner_subheader_1'),
          description: this.$i18n.t('how_it_works.faqs_tab_practitioner_desc_1'),
        },
        {
          id: 2,
          header: this.$i18n.t('how_it_works.faqs_tab_practitioner_header_2'),
          subheader: this.$i18n.t('how_it_works.faqs_tab_practitioner_subheader_2'),
          description: this.$i18n.t('how_it_works.faqs_tab_practitioner_desc_2'),
        },
        {
          id: 3,
          header: this.$i18n.t('how_it_works.faqs_tab_practitioner_header_3'),
          subheader: this.$i18n.t('how_it_works.faqs_tab_practitioner_subheader_3'),
          description: this.$i18n.t('how_it_works.faqs_tab_practitioner_desc_3'),
        },
        {
          id: 4,
          header: this.$i18n.t('how_it_works.faqs_tab_practitioner_header_4'),
          subheader: this.$i18n.t('how_it_works.faqs_tab_practitioner_subheader_4'),
          description: this.$i18n.t('how_it_works.faqs_tab_practitioner_desc_4'),
        }
      ]
    };
  },
  created () {
    if (this.$router.currentRoute.name == 'how-it-works') {
      this.displayTab('customer');
    }

    if (this.$router.currentRoute.name == 'how-it-works-customer') {
      this.displayTab('customer');
    }
    if (this.$router.currentRoute.name == 'how-it-works-practitioner') {
      this.displayTab('practitioner');
    }
    if (this.$router.currentRoute.name == 'how-it-works-faq') {
      this.displayTab('faq');
    }
  },
  methods: {
    onFullFAQ () {
      this.$router.push({ name: 'support' });
    },
    searchService () {
      const currentUserRoles = this.$store.getters.currentUser.user_roles;

      if (currentUserRoles.length == 0 && this.$router.currentRoute.name == 'how-it-works-practitioner') {
        this.$router.push({ name: 'practitioner-registration' });
      } else {
        this.$router.push({ name: 'services-search' });
      }
    },
    displayTab (tabName) {
      this.isActiveCustomer = false;
      this.isActivePractitioner = false;
      this.isActiveFaq = false;
      if (tabName == 'customer') {
        this.isActiveCustomer = true;
        this.$router.push({ name: 'how-it-works-customer' });
      }
      if (tabName == 'practitioner') {
        this.isActivePractitioner = true;
        this.$router.push({ name: 'how-it-works-practitioner' });
      }
      if (tabName == 'faq') {
        this.isActiveFaq = true;
        this.$router.push({ name: 'how-it-works-faq' });
      }
      this.tabName = tabName;
    },
    displayFaqTab (customerRole) {
      this.isActiveCustomerFaq = false;
      this.isActivePractitionerFaq = false;

      this.customerRole = customerRole;
      if (customerRole == 'customer') {
        this.isActiveCustomerFaq = true;
      }
      if (customerRole == 'practitioner') {
        this.isActivePractitionerFaq = true;
      }
    }
  },
};
</script>

<style lang="scss">
@mixin r($width) {
  @media only screen and (max-width: "#{$width}px") {
    @content;
  }
}

.how-it-works {
  &__wrapper {
    max-width: 1580px;
    margin: 0 auto;
    padding: 0 24px;

    @include r(1700) {
      max-width: 1440px;
    }

    @include r(1500) {
      max-width: 1240px;
    }

    @include r(1400) {
      max-width: 1140px;
    }
  }

  &__content {
    padding: 150px 0 220px 0;

    @include r(1700) {
      padding: 120px 0 200px 0;
    }
    @include r(1500) {
      padding: 70px 0 160px 0;
    }
    @include r(1000) {
      padding: 50px 0 100px 0;
    }

    @include r(700) {
      padding: 50px 0 70px 0;
    }
  }

  &__title {
    display: block;
    position: relative;
    padding: 0 290px 70px 290px;
    border-bottom: 1px solid #b2b2b2;
    text-align: center;

    @include r(1500) {
      padding: 0 220px 60px 220px;
    }
    @include r(900) {
      padding: 0 0 50px 0;
    }
    @include r(700) {
      padding: 0;
      border-bottom: 0;
    }

    &::before {
      position: absolute;
      content: "";
      width: 264px;
      height: 191px;
      background: {
        image: url("../.././src/images/pages/hotitworks/hiw-1.svg");
        repeat: no-repeat;
        size: contain;
      }
      bottom: 0;
      left: 0;

      @include r(1500) {
        width: 214px;
        height: 156px;
      }
      @include r(900) {
        display: none;
      }
    }

    &::after {
      position: absolute;
      content: "";
      width: 201px;
      height: 238px;
      background: {
        image: url("../.././src/images/pages/hotitworks/hiw-2.svg");
        repeat: no-repeat;
        size: contain;
      }
      bottom: 0;
      right: 0;

      @include r(1500) {
        width: 160px;
        height: 189px;
      }
      @include r(900) {
        display: none;
      }
    }

    strong {
      display: block;
      color: #272727;
      font-size: 55px;
      font-weight: 700;
      line-height: 1.2;

      @include r(1700) {
        font-size: 45px;
      }
      @include r(1500) {
        font-size: 40px;
      }
      @include r(1300) {
        font-size: 37px;
      }
      @include r(700) {
        font-size: 32px;
      }
      @include r(550) {
        font-size: 28px;
      }
    }

    span {
      display: block;
      margin-top: 40px;
      color: #47434d;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.2;

      @include r(1700) {
        font-size: 23px;
        margin-top: 30px;
      }
      @include r(1500) {
        font-size: 21px;
        margin-top: 20px;
      }
      @include r(1400) {
        font-size: 19px;
      }
      @include r(850) {
        font-size: 18px;
      }
      @include r(550) {
        font-size: 17px;
      }
    }
    button {
      display: block;
      max-width: 350px;
      width: 100%;
      height: 75px;
      border-radius: 10px;
      color: #fff;
      font-family: Nunito;
      font-size: 23px;
      font-weight: 700;
      margin: 50px auto 0 auto;

      @include r(1700) {
        height: 70px;
        font-size: 21px;
        margin-top: 42px;
      }

      @include r(1500) {
        max-width: 320px;
        height: 60px;
        font-size: 18px;
        margin-top: 36px;
      }

      @include r(900) {
        max-width: 300px;
        height: 55px;
        font-size: 16px;
        border-radius: 4px;
      }
      @include r(700) {
        display: none;
      }

      @include r(450) {
        max-width: 265px;
        height: 50px;
        font-size: 15px;
      }
    }
  }

  &__holder {
    display: block;
    margin-top: 75px;

    @include r(1700) {
      margin-top: 65px;
    }
    @include r(1500) {
      margin-top: 55px;
    }
    @include r(700) {
      margin-top: 37px;
    }
  }

  &__navigation {
    display: block;
    max-width: 1140px;
    margin: 0 auto;

    @include r(1700) {
      max-width: 1040px;
    }

    @include r(1500) {
      max-width: 940px;
    }

    @include r(1400) {
      max-width: 860px;
    }

    @include r(1000) {
      padding: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin: 0 -20px;

    @include r(1000) {
      margin: 0 -20px;
    }
    @include r(650) {
      align-items: stretch;
    }
  }

  &__button {
    flex: 0 0 calc(33.3333333% - 40px);
    margin: 0 20px;
    height: 74px;
    border-radius: 10px;
    border: 4px solid #b2b2b2;
    color: #47434d;
    font-size: 20px;
    font-weight: 700;
    line-height: 68px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    @include r(1000) {
      flex: 0 0 calc(33.3333333% - 20px);
      margin: 0 10px;
    }

    @include r(1700) {
      height: 70px;
      line-height: 62px;
    }

    @include r(1500) {
      height: 60px;
      font-size: 18px;
      line-height: 52px;
    }

    @include r(900) {
      height: auto;
      padding: 16px 7px;
      font-size: 16px;
      line-height: 1;
      border-width: 1px;
    }
    @include r(650) {
      flex: 0 0 calc(33.3333333% - 10px);
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
    }

    &:hover,
    &.is-active {
      background-color: #b2b2b2;
      color: #fff;
      transition: 0.3s;
    }
  }

  &__bodies {
    display: block;
    margin: 70px auto 0 auto;
    max-width: 1140px;

    @include r(1700) {
      margin-top: 50px;
      max-width: 1040px;
    }
    @include r(1500) {
      margin-top: 30px;
      max-width: 940px;
    }
    @include r(1400) {
      max-width: 860px;
    }
    @include r(1000) {
      padding: 0;
    }
    @include r(700) {
      position: relative;
      padding-top: 40px;
      margin-top: 15px;

      &::after {
        top: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: #b2b2b2;
        position: absolute;
        content: "";
      }
    }
  }

  &__body {
    .how-it-works {
      &__inners {
        display: block;
      }

      &__inner {
        display: flex;
        align-items: center;
        margin-top: 70px;

        @include r(1700) {
          padding: 30px 0;
          margin-top: 0;
        }
        @include r(1500) {
          padding: 25px 0;
        }
        @include r(750) {
          flex-wrap: wrap;
        }

        &--right {
          .how-it-works {
            &__inner {
              &-description {
                order: 2;
              }
              &-image {
                order: 1;
                padding: 0 140px 0 0;

                @include r(1000) {
                  padding: 0 100px 0 0;
                }
                @include r(750) {
                  order: 1;
                  flex: 0 0 100%;
                  padding: 0 0 30px 0;
                  margin: 0 auto;
                }
              }
            }
          }
        }

        &-description {
          flex: 0 0 50%;
          @include r(750) {
            order: 2;
            flex: 0 0 100%;
          }
        }

        &-title {
          display: block;
          color: #47434d;
          font-size: 40px;
          font-weight: 700;
          line-height: 1.2;

          @include r(1700) {
            font-size: 32px;
          }
          @include r(1500) {
            font-size: 28px;
          }
          @include r(1400) {
            font-size: 24px;
          }
          @include r(900) {
            font-size: 22px;
          }
          @include r(650) {
            text-align: center;
          }
          @include r(550) {
            font-size: 19px;
          }
        }

        &-items {
          display: block;
          margin-top: 65px;

          @include r(1700) {
            margin-top: 50px;
          }
          @include r(1500) {
            margin-top: 30px;
          }
          @include r(700) {
            margin-top: 20px;
          }
        }

        &-item {
          display: block;
          margin-top: 40px;
          position: relative;

          @include r(1700) {
            margin-top: 30px;
          }
          @include r(1500) {
            margin-top: 20px;
          }

          &::before {
            position: absolute;
            content: "";
            left: -65px;
            top: 7px;
            width: 25px;
            height: 25px;
            background-color: #b2b2b2;
            border-radius: 50%;

            @include r(1700) {
              width: 24px;
              height: 24px;
              left: -45px;
              top: 3px;
            }
            @include r(1700) {
              width: 23px;
              height: 23px;
            }
            @include r(1500) {
              width: 21px;
              height: 21px;
              left: -40px;
            }
            @include r(1000) {
              display: none;
            }
          }

          p {
            display: block;
            margin: 0;
            color: #47434d;
            font-size: 25px;
            font-weight: 300;

            @include r(1700) {
              font-size: 23px;
            }
            @include r(1500) {
              font-size: 19px;
            }
            @include r(1400) {
              font-size: 18px;
            }
            @include r(850) {
              font-size: 17px;
            }

            strong {
              font-weight: 600;
            }
          }
        }

        &-image {
          flex: 0 0 50%;
          padding-left: 150px;

          @include r(1000) {
            padding-left: 100px;
          }

          @include r(750) {
            order: 1;
            flex: 0 0 100%;
            padding: 0 0 30px 0;
            max-width: 300px;
            margin: 0 auto;
          }
          @include r(650) {
            max-width: 200px;
          }
          @include r(450) {
            max-width: 120px;
          }

          img {
            max-width: 100%;

            @include r(1500) {
              max-width: 90%;
            }
            @include r(1400) {
              max-width: 85%;
            }
            @include r(1000) {
              max-width: 95%;
            }
            @include r(750) {
              max-width: 100%;
            }
          }
        }
      }
    }

    /*&.is-faq {*/
    /*.how-it-works {*/
    /*&__faq {*/

    /*}*/
    /*}*/
    /*}*/
  }

  &__questions {
    display: block;
    margin-top: 30px;

    &-header {
      display: block;
      color: #47434d;
      font-size: 25px;
      font-weight: 700;
      line-height: 1.2;

      @include r(1700) {
        font-size: 23px;
      }
      @include r(1500) {
        font-size: 21px;
      }
      @include r(1400) {
        font-size: 18px;
      }
      @include r(850) {
        font-size: 17px;
      }
    }

    &-description {
      line-height: 1.4;
      color: #47434d;
      font-size: 20px;
      font-weight: 400;

      @include r(1700) {
        font-size: 19px;
      }
      @include r(1500) {
        font-size: 17px;
      }
      @include r(550) {
        font-size: 15px;
      }
    }

    .v-expansion-panel {
      border: 0;
      box-shadow: none;
    }

    .v-expansion-panel__container {
      background-color: transparent !important;
      border-bottom: 1px solid #b2b2b2 !important;
      border-top: 1px solid #b2b2b2 !important;
    }
    .v-expansion-panel__header {
      padding: 20px 0;
      @include r(1700) {
        padding: 18px 0;
      }
      @include r(1500) {
        padding: 15px 0;
      }
    }
    .v-expansion-panel__header__icon {
      .v-icon {
        font-size: 30px;
      }
    }
    .v-expansion-panel__body {
      .theme--light.v-sheet {
        background-color: transparent !important;
      }

      .v-card__text {
        padding: 0 0 30px 0;
      }
    }
  }

  &__action {
    display: block;
    text-align: center;
    margin-top: 70px;

    @include r(1700) {
      margin-top: 60px;
    }
    @include r(1500) {
      margin-top: 50px;
    }
    @include r(1400) {
      margin-top: 40px;
    }
    @include r(1000) {
      margin-top: 30px;
    }

    button {
      display: inline-block;
      max-width: 450px;
      width: 100%;
      height: 75px;
      border-radius: 10px;
      color: #fff;
      font-family: Nunito;
      font-size: 23px;
      font-weight: 700;
      margin: 0;

      @include r(1700) {
        max-width: 420px;
        height: 70px;
        font-size: 21px;
      }

      @include r(1500) {
        max-width: 320px;
        height: 60px;
        font-size: 18px;
      }

      @include r(900) {
        max-width: 300px;
        height: 55px;
        font-size: 16px;
        border-radius: 4px;
      }

      @include r(450) {
        max-width: 265px;
        height: 50px;
        font-size: 15px;
      }
    }
  }

  &__faq {
    display: block;
    margin-top: 70px;

    @include r(1700) {
      margin-top: 60px;
    }

    @include r(1500) {
      margin-top: 50px;
    }
    @include r(1400) {
      margin-top: 40px;
    }
    @include r(1000) {
      margin-top: 36px;
    }
    @include r(700) {
      margin-top: 10px;
    }

    &-navigation {
      display: block;
    }

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      @include r(500) {
        margin: 0 -7px;
      }
    }

    &-button {
      display: inline-block;
      color: #b2b2b2;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 700;
      padding: 0 28px 8px 28px;
      border-bottom: 2px solid #b2b2b2;
      cursor: pointer;
      margin: 0 15px;
      text-align: center;
      transition-property: color;
      transition-duration: 0.3s;

      @include r(1700) {
        font-size: 19px;
        padding: 0 26px 7px 26px;
      }
      @include r(1500) {
        font-size: 18px;
        padding: 0 24px 6px 24px;
      }
      @include r(1900) {
        font-size: 17px;
        padding: 0 16px 6px 16px;
        border-width: 1px;
      }
      @include r(500) {
        font-size: 15px;
        margin: 0 7px;
        padding: 0 10px 6px 10px;
      }
      @include r(365) {
        font-size: 14px;
      }

      &:hover,
      &.is-active {
        color: #47434d;
        transition-property: color;
        transition-duration: 0.3s;
      }
    }
    &-bodies {
      display: block;
      margin-top: 50px;
      @include r(1700) {
        margin-top: 40px;
      }
      @include r(1500) {
        margin-top: 30px;
      }
    }

    &-body {
      display: block;

      .v-expansion-panel {
        border: 0;
        box-shadow: none;
      }

      .v-expansion-panel__container {
        background-color: transparent !important;
        border-bottom: 0 !important;
        border-top: 1px solid #b2b2b2 !important;
      }
      .v-expansion-panel__header {
        padding: 26px 0;
      }
      .v-expansion-panel__header__icon {
        .v-icon {
          font-size: 30px;
        }
      }
      .v-expansion-panel__body {
        .theme--light.v-sheet {
          background-color: transparent !important;
        }

        .v-card__text {
          padding: 0 0 30px 0;
        }
      }
    }
  }
}
</style>
