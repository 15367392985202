<template>
  <div class="dashboard-content dashboard-booking" id="dashboard-content">

    <v-dialog v-model="cancelBookingIsVisible">
      <v-card class="booking-cancel">
        <div class="booking-cancel__warning">!</div>
        <div class="booking-cancel__text">
          {{$t('customer_profile.booking_tab_cancel_booking_desc')}}
        </div>
        <div class="booking-cancel__actions">
          <v-btn @click="cancelBookingIsVisible = false; ">
            {{$t('customer_profile.booking_tab_return')}}
          </v-btn>
          <v-btn
            color="error"
            @click="cancelBooking()"
          >
            {{$t('customer_profile.booking_tab_cancel_booking')}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <div class="dashboard-customer">
      <div class="dashboard-content__title">
        <h2>
          {{$t('customer_profile.booking_tab_title')}}
        </h2>
        <BookingCounter></BookingCounter>
      </div>
      <p>
      {{$t('customer_profile.booking_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">
      <div v-if="showProgress">
        <v-progress-linear
          indeterminate
          color="error"
          height="3"
        ></v-progress-linear>
      </div>

      <div v-if="!showProgress">
        <div class="dashboard-booking__title">
          {{$t('customer_profile.booking_tab_upcoming_appointments')}}
        </div>
        <div class="dashboard-booking__desk">
          <v-data-table
            :headers="headers"
            :items="upcomingAppointments"
            :expand="expand"
            item-key="id"
            hide-actions
            class="dashboard-booking__table"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <tr
                @click="props.expanded = !props.expanded"
                :class="upcomingClassObj(props.item)"
                class="dashboard-booking__table-header"
              >
                <td class="dashboard-booking__table-td dashboard-booking__table-number">{{ props.item.id }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-name">{{ props.item.practitioner_name | formatName }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-treatments">{{ props.item.treatments |
                  formatTreatments }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-location">{{ props.item.location_type |
                  capitalizeFirstLetter }}
                </td>
                <td
                        v-if="!isCanceledBooking(props.item)"
                        class="dashboard-booking__table-td dashboard-booking__table-time-date"
                >
                  {{ props.item.date | formatDate }}
                </td>
                <td
                        v-else
                        class="dashboard-booking__table-td dashboard-booking__table-time-date"
                >
                  <template v-if="bookingCancelBy(props.item) === 'customer'">
                    {{$t('customer_profile.booking_tab_customer_cancelled')}}
                  </template>
                  <template v-else-if="bookingCancelBy(props.item) === 'practitioner'">
                    {{$t('customer_profile.booking_tab_practitioner_cancelled')}}
                  </template>
                </td>
              </tr>
            </template>
            <template
              slot="expand"
              slot-scope="props"
            >
              <div>
                <table class="dashboard-booking__table">
                  <tr>
                    <td class="dashboard-booking__table-td dashboard-booking__table-number"></td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-name">
                      <div class="dashboard-booking__info">
                        <div>{{ props.item.practitioner_phone }}</div>
                        <!--<div>{{ props.item.practitioner_email }}</div>-->
                      </div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-treatments">
                      <div class="dashboard-booking__prices">
                        <div
                          class="dashboard-booking__prices-item"
                          v-for="treat in getTreatmentObjectCalculated(props.item.treatments)['data']"
                          :key="treat.id"
                        >
                          <span>{{ treat.quantity }} x {{ treat.name }}</span>
                          <span>£{{ treat.total_price }}</span>
                        </div>

                        <div
                          class="dashboard-booking__prices-item"
                          v-if="props.item.location_type === 'mobile'"
                        >
                          <span>
                            {{$t('customer_profile.booking_tab_call_out_charge')}}
                          </span>
                          <span>£{{priceFormat(props.item.call_out_charge)}}</span>
                        </div>

                        <div
                                class="dashboard-booking__prices-item"
                                v-if="props.item.discount != 0"
                        >
                          <span>
                            {{$t('customer_profile.booking_tab_discount')}}
                          </span>
                          <span>£{{props.item.discount_amount}}</span>
                        </div>

                        <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total">
                          <span>
                            {{$t('customer_profile.booking_tab_sub_total')}}
                          </span>
                          <span>£{{ props.item.total_amount }}</span>
                        </div>

                        <div
                                v-if="props.item.amount_refunded"
                                class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                        >
                          <span>
                            {{$t('customer_profile.booking_tab_refund_total')}}
                          </span>
                          <span>£{{ props.item.amount_refunded }}</span>
                        </div>

                      </div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-location">
                      <div class="dashboard-booking__address">{{ props.item.location.address }}</div>
                      <div class="dashboard-booking__address">{{ props.item.location.city }}</div>
                      <div class="dashboard-booking__address">{{ props.item.location.postcode }}</div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-time-date">
                      {{$t('customer_profile.booking_tab_salon_location')}}
                      <l-map
                        ref="map"
                        :zoom="zoom"
                        :center="formatMapCenter(props.item.location.home_location)"
                        :options="{ zoomControl: false }"
                        class="map"
                      >
                        <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                        <l-tile-layer
                          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                        />
                        <l-marker
                          :lat-lng="formatMapCenter(props.item.location.home_location)"
                          :interactive="false"
                        />
                      </l-map>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="dashboard-booking__actions">
                <!-- <button class="dashboard-booking__actions-view">
                  <template v-if="true">
                    View Notes
                  </template>
                  <template v-else>
                    Hide Notes
                  </template>
                </button> -->
                <button
                        v-if="props.item.booking_status === 'confirmed'"
                        class="dashboard-booking__actions-cancel error"
                        @click="cancelBookingIsVisible = true; canceledBooking = props.item;"
                >
                  {{$t('customer_profile.booking_tab_cancel_booking')}}
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
        <div class="dashboard-booking__mob">
          <div class="dashboard-booking__mob-table">

            <div
              class="dashboard-booking__mob-item"
              v-for="item in upcomingAppointments"
              :key="item.id"
            >
              <div
                      class="dashboard-booking__mob-header"
                      :class="upcomingClassObj(item)"
              >
                <div class="dashboard-booking__mob-header-lside">
                  <strong>{{item.practitioner_name | formatName}}</strong>
                  <span>{{item.id}}</span>
                </div>
                <div
                        v-if="!isCanceledBooking(item)"
                        class="dashboard-booking__mob-header-rside"
                >
                  <strong>{{item.date | formatDate}}</strong>
                </div>
                <div
                        v-else
                        class="dashboard-booking__mob-header-rside"
                >
                  <template v-if="bookingCancelBy(item) === 'customer'">
                    <strong>
                      {{$t('customer_profile.booking_tab_customer_cancelled')}}
                    </strong>
                  </template>
                  <template v-else-if="bookingCancelBy(item) === 'practitioner'">
                    <strong>
                      {{$t('customer_profile.booking_tab_practitioner_cancelled')}}
                    </strong>
                  </template>
                </div>
              </div>
              <div class="dashboard-booking__mob-body">
                <div class="dashboard-booking__mob-body-inner">
                  <div class="dashboard-booking__prices">
                    <div
                      class="dashboard-booking__prices-item"
                      v-for="treat in getTreatmentObjectCalculated(item.treatments)['data']"
                      :key="treat.id"
                    >
                      <span>{{ treat.quantity }} x {{ treat.name }}</span>
                      <span>£{{ treat.total_price }}</span>
                    </div>

                    <div
                      class="dashboard-booking__prices-item"
                      v-if="item.location_type === 'mobile'"
                    >
                      <span>
                        {{$t('customer_profile.booking_tab_call_out_charge')}}
                      </span>
                      <span>£{{priceFormat(item.call_out_charge)}}</span>
                    </div>

                    <div
                            class="dashboard-booking__prices-item"
                            v-if="item.discount != 0"
                    >
                      <span>
                        {{$t('customer_profile.booking_tab_discount')}}
                      </span>
                      <span>£{{item.discount_amount}}</span>
                    </div>

                    <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total">
                      <span>
                        {{$t('customer_profile.booking_tab_sub_total')}}
                      </span>
                      <span>£{{ item.total_amount }}</span>
                    </div>

                    <div
                            v-if="item.amount_refunded"
                            class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                    >
                      <span>
                         {{$t('customer_profile.booking_tab_refund_total')}}
                      </span>
                      <span>£{{ item.amount_refunded }}</span>
                    </div>

                  </div>
                </div>
                <div class="dashboard-booking__mob-body-inner">
                  <span class="dashboard-booking__mob-title">
                    {{$t('customer_profile.booking_tab_location')}}
                  </span>

                  <div class="dashboard-booking__address-holder">
                    <div class="dashboard-booking__address">{{ item.location.address }},</div>
                    <div class="dashboard-booking__address">{{ item.location.city }},</div>
                    <div class="dashboard-booking__address">{{ item.location.postcode }}</div>
                  </div>
                  <template
                    v-if="item.location_type === 'salon'"
                  >
                    {{$t('customer_profile.booking_tab_salon_location')}}
                    <l-map
                      ref="map"
                      :zoom="zoom"
                      :center="formatMapCenter(item.location.home_location)"
                      :options="{ zoomControl: false }"
                      class="map"
                    >
                      <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                      <l-tile-layer
                        url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                      />

                      <l-marker
                        :lat-lng="formatMapCenter(item.location.home_location)"
                        :interactive="false"
                      />
                    </l-map>
                  </template>
                </div>
                <div class="dashboard-booking__mob-body-inner">
                  <div class="dashboard-booking__actions">
                    <button
                            v-if="item.booking_status === 'confirmed'"
                            class="dashboard-booking__actions-cancel error"
                            @click="cancelBookingIsVisible = true; canceledBooking = props.item;"
                    >
                      {{$t('customer_profile.booking_tab_cancel_booking')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div v-if="!showProgress">
        <div class="dashboard-booking__title dashboard-booking__title--mt">
          {{$t('customer_profile.booking_tab_previous_appointments')}}
        </div>
        <div class="dashboard-booking__desk">
          <v-data-table
            :headers="headers"
            :items="previousAppointments"
            :expand="expand"
            item-key="id"
            hide-actions
            class="dashboard-booking__table"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <tr
                @click="props.expanded = !props.expanded"
                :class="previousClassObj(props.item)"
                class="dashboard-booking__table-header"
              >
                <td class="dashboard-booking__table-td dashboard-booking__table-number">{{ props.item.id }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-name">{{ props.item.practitioner_name | formatName }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-treatments">{{ props.item.treatments |
                  formatTreatments }}
                </td>
                <td class="dashboard-booking__table-td dashboard-booking__table-location">{{ props.item.location_type |
                  capitalizeFirstLetter }}
                </td>
                <td
                        v-if="!isCanceledBooking(props.item)"
                        class="dashboard-booking__table-td dashboard-booking__table-time-date"
                >
                  {{ props.item.date | formatDate }}
                </td>
                <td
                        v-else
                        class="dashboard-booking__table-td dashboard-booking__table-time-date"
                >
                  <template v-if="bookingCancelBy(props.item) === 'customer'">
                    {{$t('customer_profile.booking_tab_customer_cancelled')}}
                  </template>
                  <template v-else-if="bookingCancelBy(props.item) === 'practitioner'">
                    {{$t('customer_profile.booking_tab_practitioner_cancelled')}}
                  </template>
                </td>
              </tr>
            </template>
            <template
              slot="expand"
              slot-scope="props"
            >
              <div>
                <table class="dashboard-booking__table">
                  <tr>
                    <td class="dashboard-booking__table-td dashboard-booking__table-number"></td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-name">
                      <div class="dashboard-booking__info">
                        <div>{{ props.item.practitioner_phone }}</div>
                        <!--<div>{{ props.item.practitioner_email }}</div>-->
                      </div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-treatments">
                      <div class="dashboard-booking__prices">
                        <div
                          class="dashboard-booking__prices-item"
                          v-for="treat in getTreatmentObjectCalculated(props.item.treatments)['data']"
                          :key="treat.id"
                        >
                          <span>{{ treat.quantity }} x {{ treat.name }}</span>
                          <span>£{{ treat.total_price }}</span>
                        </div>

                        <div
                          class="dashboard-booking__prices-item"
                          v-if="props.item.location_type === 'mobile'"
                        >
                          <span>
                            {{$t('customer_profile.booking_tab_call_out_charge')}}
                          </span>
                          <span>£{{priceFormat(props.item.call_out_charge)}}</span>
                        </div>

                        <div
                                class="dashboard-booking__prices-item"
                                v-if="props.item.discount != 0"
                        >
                          <span>
                            {{$t('customer_profile.booking_tab_discount')}}
                          </span>
                          <span>£{{props.item.discount_amount}}</span>
                        </div>

                        <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total">
                          <span>
                            {{$t('customer_profile.booking_tab_sub_total')}}
                          </span>
                          <span>£{{ props.item.total_amount }}</span>
                        </div>

                        <div
                                v-if="props.item.amount_refunded"
                                class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                        >
                          <span>
                            {{$t('customer_profile.booking_tab_refund_total')}}
                          </span>
                          <span>£{{ props.item.amount_refunded }}</span>
                        </div>

                      </div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-location">
                      <div class="dashboard-booking__address">{{ props.item.location.address }}</div>
                      <div class="dashboard-booking__address">{{ props.item.location.city }}</div>
                      <div class="dashboard-booking__address">{{ props.item.location.postcode }}</div>
                    </td>
                    <td class="dashboard-booking__table-td dashboard-booking__table-time-date">
                      <l-map
                        ref="map"
                        :zoom="zoom"
                        :center="formatMapCenter(props.item.location.home_location)"
                        :options="{ zoomControl: false }"
                        class="map"
                      >
                        <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                        <l-tile-layer
                          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                        />
                        <l-marker
                          :lat-lng="formatMapCenter(props.item.location.home_location)"
                          :interactive="false"
                        />
                      </l-map>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="dashboard-booking__actions">
                <!--<button class="dashboard-booking__actions-view">-->
                <!--<template v-if="true">-->
                <!--View Notes-->
                <!--</template>-->
                <!--<template v-else>-->
                <!--Hide Notes-->
                <!--</template>-->
                <!--</button>-->

                <v-btn
                        class="dashboard-booking__actions-cancel error"
                        @click="bookAgain(props.item.id)"
                >
                  {{$t('customer_profile.dashboard_tab_book_again')}}
                </v-btn>

                <button
                        v-if="props.item.review === true && props.item.booking_status === 'completed'"
                        @click="addReviews(props.item)"
                        class="dashboard-booking__actions-cancel error"
                >
                  {{$t('customer_profile.booking_tab_leave_review')}}
                </button>
              </div>
            </template>
          </v-data-table>
        </div>
        <div class="dashboard-booking__mob">
          <div class="dashboard-booking__mob-table">

            <div
              class="dashboard-booking__mob-item"
              v-for="item in previousAppointments"
              :key="item.id"
            >
              <div
                      class="dashboard-booking__mob-header"
                      :class="previousClassObj(item)"
              >
                <div class="dashboard-booking__mob-header-lside">
                  <strong>{{item.practitioner_name | formatName}}</strong>
                  <span>{{item.id}}</span>
                </div>
                <div
                        v-if="!isCanceledBooking(item)"
                        class="dashboard-booking__mob-header-rside"
                >
                  <strong>{{item.date | formatDate}}</strong>
                </div>
                <div
                        v-else
                        class="dashboard-booking__mob-header-rside"
                >
                  <template v-if="bookingCancelBy(item) === 'customer'">
                    <strong>
                      {{$t('customer_profile.booking_tab_customer_cancelled')}}
                    </strong>
                  </template>
                  <template v-else-if="bookingCancelBy(item) === 'practitioner'">
                    <strong>
                      {{$t('customer_profile.booking_tab_practitioner_cancelled')}}
                    </strong>
                  </template>
                </div>
              </div>
              <div class="dashboard-booking__mob-body">
                <div class="dashboard-booking__mob-body-inner">
                  <div class="dashboard-booking__prices">
                    <div
                      class="dashboard-booking__prices-item"
                      v-for="treat in getTreatmentObjectCalculated(item.treatments)['data']"
                      :key="treat.id"
                    >
                      <span>{{ treat.quantity }} x {{ treat.name }}</span>
                      <span>£{{ treat.total_price }}</span>
                    </div>

                    <div
                      class="dashboard-booking__prices-item"
                      v-if="item.location_type === 'mobile'"
                    >
                      <span>
                        {{$t('customer_profile.booking_tab_call_out_charge')}}
                      </span>
                      <span>£{{priceFormat(item.call_out_charge)}}</span>
                    </div>

                    <div
                            class="dashboard-booking__prices-item"
                            v-if="item.discount != 0"
                    >
                      <span>
                         {{$t('customer_profile.booking_tab_discount')}}
                      </span>
                      <span>£{{item.discount_amount}}</span>
                    </div>

                    <div class="dashboard-booking__prices-item dashboard-booking__prices-item--total">
                      <span>
                        {{$t('customer_profile.booking_tab_sub_total')}}
                      </span>
                      <span>£{{ item.total_amount }}</span>
                    </div>

                    <div
                            v-if="item.amount_refunded"
                            class="dashboard-booking__prices-item dashboard-booking__prices-item--total"
                    >
                      <span>
                         {{$t('customer_profile.booking_tab_refund_total')}}
                      </span>
                      <span>£{{ item.amount_refunded }}</span>
                    </div>

                  </div>
                </div>
                <div class="dashboard-booking__mob-body-inner">
                  <span class="dashboard-booking__mob-title">
                    {{$t('customer_profile.booking_tab_location')}}
                  </span>

                  <div class="dashboard-booking__address-holder">
                    <div class="dashboard-booking__address">{{ item.location.address }},</div>
                    <div class="dashboard-booking__address">{{ item.location.city }},</div>
                    <div class="dashboard-booking__address">{{ item.location.postcode }}</div>
                  </div>
                    <template
                      v-if="item.location_type === 'salon'"
                    >
                      <l-map
                        ref="map"
                        :zoom="zoom"
                        :center="formatMapCenter(item.location.home_location)"
                        :options="{ zoomControl: false }"
                        class="map"
                      >
                        <!-- <v-tilelayer-googlemutant :options="googleLayerOptions"></v-tilelayer-googlemutant> -->
                        <l-tile-layer
                          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                        />

                        <l-marker
                          :lat-lng="formatMapCenter(item.location.home_location)"
                          :interactive="false"
                        />
                      </l-map>
                    </template>
                </div>
                <div class="dashboard-booking__mob-body-inner">
                  <div class="dashboard-booking__actions">
                    <!--<button-->
                      <!--class="dashboard-booking__actions-cancel error"-->
                      <!--@click="cancelBookingIsVisible = true; canceledBooking = props.item;"-->
                    <!--&gt;-->
                      <!--{{$t('customer_profile.booking_tab_cancel_booking')}}-->
                    <!--</button>-->

                    <v-btn
                            class="dashboard-booking__actions-cancel error"
                            @click="bookAgain(item.id)"
                    >
                      {{$t('customer_profile.dashboard_tab_book_again')}}
                    </v-btn>

                    <button
                            v-if="item.review === true && item.booking_status === 'completed'"
                            @click="addReviews(item)"
                            class="dashboard-booking__actions-cancel error"
                    >
                      {{$t('customer_profile.booking_tab_leave_review')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <router-view name="booking_tab_customer_rebook"></router-view>
  </div>
</template>

<script>
  import axios from "axios"
  import * as CONFIG from "@/config.js"
  import {getTreatmentObject, formatTreatments, capitalizeFirstLetter, getDisplayUserName} from '@/helpers.js';
  import moment from 'moment';

  import {LMap, LTileLayer, LMarker} from 'vue2-leaflet'
  import 'leaflet/dist/leaflet.css'

  import BookingCounter from '@/components/CustomerDashboard/Partial/BookingCounter'

  /* eslint-disable */

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Customer Bookings'
    },
    components: {
      LMap,
      LTileLayer,
      LMarker,
      BookingCounter
    },
    data() {
      return {
        cancelBookingIsVisible: false,
        canceledBooking: null,

        showProgress: true,

        zoom: 13,
        googleLayerOptions: {
          type: 'roadmap'
        },

        expand: false,
        headers: [
          {text: this.$i18n.t('customer_profile.booking_tab_ref'), align: 'left', sortable: false, value: 'id'},
          {text: this.$i18n.t('customer_profile.booking_tab_name'), sortable: false, value: 'practitioner_name'},
          {text: this.$i18n.t('customer_profile.booking_tab_treatments'), sortable: false, value: 'treatment'},
          {text: this.$i18n.t('customer_profile.booking_tab_location'), sortable: false, value: 'location_type'},
          {text: this.$i18n.t('customer_profile.booking_tab_time_date'), value: 'date'}
        ],
        upcomingAppointments: [],
        previousAppointments: [],
      }
    },
    mounted() {
      this.getCustomerBookings().then(
        response => {
          this.initializeBookingsTables(response.data);
          this.showProgress = false;
        },
        error => {
          console.log(error.response.data);

          this.$store.commit('SET_NOTIFICATION', {
            text: error.response.data.message,
            color: 'orange'
          });

          this.showProgress = false;
          throw error;
        }
      )
        .catch(error => {
          console.log(error.response);
          this.showProgress = false;
          throw error;
        });
    },
    filters: {
      formatTreatments: function (value) {
        return formatTreatments(value);
      },
      capitalizeFirstLetter: function (value) {
        return capitalizeFirstLetter(value);
      },
      formatDate: function (dateValue) {
        const  date = moment(dateValue, moment.ISO_8601).format('DD/MM/YYYY');
        const time = moment(dateValue, moment.ISO_8601).format("HH:mm a");
        return `${date} ${time}`;
      },
      formatName: function (name) {
        return getDisplayUserName(name);
      }
    },
    methods: {
      bookAgain(booking_id) {
        this.$router.push({name: 'booking-tab-customer-rebook', params:{booking_id: booking_id, booking_flow: 'booking-tab'}})
      },
      bookingCancelBy(booking){
        if (booking.booking_status === 'cancelled by customer') {
          return 'customer';
        } else if (booking.booking_status === 'cancelled by practitioner') {
          return 'practitioner';
        }else{
          return false;
        }
      },
      isCanceledBooking(booking) {
        if (booking.booking_status === 'cancelled by customer' || booking.booking_status === 'cancelled by practitioner') {
          return true;
        } else {
          return false;
        }
      },
      upcomingClassObj(booking){
        return {
          [`booking-${booking.id}`] : true,
          'dashboard-booking__cancelled' : this.isCanceledBooking(booking)
        }
      },
      previousClassObj(booking){
        return {
          [`booking-${booking.id}`] : true,
          'dashboard-booking__cancelled' : this.isCanceledBooking(booking)
        }
      },
      priceFormat(value){
        return Number(value).toFixed(2);
      },
      addReviews(boking){
        this.$router.push({name:'customer-dashboard-reviews', params:{booking_id: boking.id}});
      },
      getSubTotal(treatmentsPrice, callOutCharge) {
        return (Number(treatmentsPrice) + Number(callOutCharge)).toFixed(2);
      },
      getTreatmentObjectCalculated(data) {
        return getTreatmentObject(data);
      },
      formatMapCenter(coords) {
        return L.latLng(coords.lat, coords.lng);
      },
      initializeBookingsTables(data) {
        console.log('customers', JSON.parse(JSON.stringify(data)));

        this.upcomingAppointments = data.filter((booking) => {
           return (Date.parse(booking.date) > Date.now() && booking.booking_status !== 'cancelled by customer' && booking.booking_status !== 'cancelled by practitioner');
        });

        this.previousAppointments = data.filter((booking) => {
          return (Date.parse(booking.date) < Date.now() || booking.booking_status === 'cancelled by customer' || booking.booking_status === 'cancelled by practitioner');
        });
      },
      cancelBooking() {
        axios({
          method: "put",
          url: `${CONFIG.API_URL}/booking/${this.canceledBooking.id}/cancelByCustomer`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(
            response => {
              let bookingIndex = this.upcomingAppointments.indexOf(this.canceledBooking);
              if(bookingIndex !== -1){
                this.previousAppointments.push(this.canceledBooking);
                this.upcomingAppointments.splice(this.upcomingAppointments.indexOf(this.canceledBooking), 1);
              }
              this.cancelBookingIsVisible = false;
              this.canceledBooking.booking_status = 'cancelled by customer';

              this.$store.commit('SET_NOTIFICATION', {
                text: response.data.message,
                color: 'error'
              });
            },
            error => {
              console.log(error.response.data);
              throw error;
            }
          )
          .catch(error => {
            console.log(error.response.data);
            throw error;
          });
      },
      getCustomerBookings() {
        return axios({
          method: "get",
          url: `${CONFIG.API_URL}/dashboard/user/bookings`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        })
      }
    },

  };
</script>

<style lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }

  .booking-cancel {
    max-width: 400px;
    padding: 24px 30px;
    text-align: center;
    &__warning {
      display: block;
      width: 42px;
      height: 42px;
      background-color: #f25757;
      margin: 0 auto;
      border-radius: 50% !important;
      text-align: center;
      line-height: 42px;
      color: #fff;
      font-family: Nunito;
      font-size: 31px;
      font-weight: 700;
    }
    &__text {
      display: block;
      color: #383838;
      font-family: Nunito;
      font-size: 15px;
      font-weight: 400;
      margin-top: 20px;
    }
    &__actions {
      display: flex;
      margin-top: 20px;
      justify-content: center;
    }
  }
</style>
