<template>
  <v-progress-linear
          indeterminate
          color="error"
          height="3"
          v-if="showProgress"
  ></v-progress-linear>
</template>

<script>

  import {empty} from '@/helpers.js';
  import * as CONFIG from "@/config.js";
  import axios from 'axios';

/* eslint-disable */

export default {
  props: ['booking'],
  name: "create-booking",
  data () {
    return {
      showWrapper: true,
      showProgress: true
    }
  },
  mounted: function () {
    this.checkDiscountForBooking();
  },
  methods: {
    createBooking (booking) {
      this.showProgress = true;
      this.$store.dispatch('CREATE_BOOKING', booking)
        .then((response) => {
          this.showProgress = false;
          booking['booking_id'] = response.data.booking_id;
          this.$store.commit('SET_BOOKING_DATA_TO_STORE', booking);
          this.$router.replace({ path: `/payment/${response.data.booking_id}` });
        })
        .catch((error) => {
          this.showProgress = false;
          this.$store.commit('SET_NOTIFICATION', {
            text: error.response.data.message,
            color: 'orange'
          });
          this.$router.back();
        });
    },
    checkDiscountForBooking(){
      if (empty(this.booking.discount)){
        this.getDiscount()
          .then((response)=>{
            const data = response.data;
            if (data.loyalty === true) {
              this.booking.discount = data.discount;
              this.booking.discount_amount = (Number(this.booking.total_amount) * (Number(data.discount)/100)).toFixed(2);
              this.booking.discount_mark = data.mark;
              this.booking.total_amount = (Number(this.booking.total_amount) - Number(this.booking.discount_amount)).toFixed(2);

              this.createBooking(this.booking);
            }else{
              this.createBooking(this.booking);
            }
          })
          .catch((error) => {
            this.showProgress = false;
            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });
            this.$router.back();
          })
      }else{
        this.createBooking(this.booking);
      }
    },
    getDiscount() {
      const currentUser = this.$store.getters.currentUser;
      return axios.get(`${CONFIG.API_URL}/booking/loyalty-scheme/${currentUser.user_id}/${this.booking.practitioner_id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest"
        }
      });
    },
  }
}
</script>

<style scoped>
</style>