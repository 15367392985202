import {
  TOGGLE_NEED_AUTHORIZATION,
  SET_TARGET_PAGE,
  SET_TARGET_PARAMS,
  TOGGLE_MODAL_AUTHORIZATION,
  TOGGLE_MODAL_CUSTOMER_REG
} from '../mutation-types';

import {
  CHECK_AUTH_AND_SHOW_LOGIN_FORM
} from '../action-types';

export default {
  state: {
    targetPage: '',
    needAuthorization: false,
    modalAuthorization: false,
    modalCustomerReg: false,
    params: {}
  },
  mutations: {
    [TOGGLE_NEED_AUTHORIZATION]: (state, needAuthorization = false) => {
      state.needAuthorization = needAuthorization;
    },
    [SET_TARGET_PAGE]: (state, targetPage = '') => {
      state.targetPage = targetPage;
    },
    [SET_TARGET_PARAMS]: (state, params = {}) => {
      state.params = params;
    },
    [TOGGLE_MODAL_AUTHORIZATION]: (state, modalAuthorization) => {
      state.modalAuthorization = modalAuthorization;
    },
    [TOGGLE_MODAL_CUSTOMER_REG]: (state, modalCustomerReg) => {
      state.modalCustomerReg = modalCustomerReg
    }
  },
  actions: {
    [CHECK_AUTH_AND_SHOW_LOGIN_FORM]: ({state, commit}) => {

        if(!state.isUserLoggedIn){
          commit('TOGGLE_MODAL_AUTHORIZATION', true);
        }else{
          commit('TOGGLE_MODAL_AUTHORIZATION', false);
        }
      // eslint-disable-next-line
      console.log(state);
    }
  },
  getters: {
    targetPage(state) {
      return state.targetPage;
    },
    params(state) {
      return state.params;
    },
    needAuthorization(state) {
      return state.needAuthorization;
    },
    modalAuthorization(state){
      return state.modalAuthorization;
    },
    modalCustomerReg(state){
      return state.modalCustomerReg;
    }
  }
}