<template>

  <div class="dashboard-content dashboard-services" id="dashboard-content">
    <div class="dashboard-content__title">
      <h2>
        {{$t('practitioner_profile.services_tab_title')}}
      </h2>
      <p>
        {{$t('practitioner_profile.services_tab_desc')}}
      </p>
    </div>
    <div class="dashboard-content__body">
      <div v-if="showProgress">
        <v-progress-linear
          indeterminate
          color="error"
          height="3"
        ></v-progress-linear>
      </div>

      <div
        v-for="item in services"
        :key="item.id"
        class="dashboard-service"
      >
        <PractitionerDashboardServicesSearchItem :item="item"></PractitionerDashboardServicesSearchItem>
      </div>
    </div>
  </div>

</template>

<script>
  import axios from "axios"
  import _ from 'lodash'
  import * as CONFIG from "@/config.js"

  import PractitionerDashboardServicesSearchItem
    from '@/components/PractitionerDashboard/PractitionerDashboardServicesSearchItem';

  /* eslint-disable */

  export default {
    metaInfo: {
      title: 'Freely',
      titleTemplate: '%s | Practitioner Services'
    },
    components: {
      PractitionerDashboardServicesSearchItem,
    },
    data: function () {
      return {
        services: [],
        showProgress: true
      }
    },
    mounted() {
      this.getPractitionerServices();
    },
    methods: {
      compareServicesName(a, b) {
        const a_number = parseFloat(a.name);
        const b_number = parseFloat(b.name);

        if (!isNaN(a_number) && !isNaN(b_number)) {
          return a_number - b_number;
        } else {
          return a.name.localeCompare(b.name)
        }
      },
      prepareServices(response, practitioner_services) {
        let practitioner_sub_services = {};
        for (let i = 0; i < practitioner_services.length; i++) {
          let sub_service = practitioner_services[i];
          practitioner_sub_services[sub_service.sub_service_id] = {
            price: sub_service.price,
            duration: sub_service.duration,
            gid: sub_service.sub_service_id
          };
        }

        for (let id in response.data) {
          for (let sub_id in response.data[id].sub_services) {
            const sub_service_id = response.data[id].sub_services[sub_id].id;

            if (practitioner_sub_services[sub_service_id] && sub_service_id === practitioner_sub_services[sub_service_id].gid) {
              response.data[id].sub_services[sub_id]['price'] = practitioner_sub_services[sub_service_id].price;
              response.data[id].sub_services[sub_id]['rawPrice'] = practitioner_sub_services[sub_service_id].price;
              response.data[id].sub_services[sub_id]['order'] = 1;
              response.data[id].sub_services[sub_id]['available'] = true;
            } else {
              response.data[id].sub_services[sub_id]['price'] = '';
              response.data[id].sub_services[sub_id]['rawPrice'] = '';
              response.data[id].sub_services[sub_id]['order'] = 2;
              response.data[id].sub_services[sub_id]['available'] = false;
            }
            response.data[id].sub_services[sub_id]['show'] = false;
          }

          response.data[id].sub_services =  response.data[id].sub_services.sort(this.compareServicesName);
        }
        return response;
      },
      getPractitionerServices() {
        axios({
          method: "get",
          url: `${CONFIG.API_URL}/dashboard/practitioner/services`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.getters.userToken ||
            CONFIG.DEV_USER_TOKEN}`
          }
        })
          .then(
            response => {
              this.getAllServices(response.data);
            },
            error => {
              console.log(error.response.data);
              throw error;
            }
          )
          .catch(error => {
            this.$store.commit('SET_NOTIFICATION', {
              text: error.response.data.message,
              color: 'orange'
            });

            this.getAllServices([]);
            throw error;
          });
      },

      getAllServices(practitioner_services) {
        axios({
          method: "get",
          url: `${CONFIG.API_URL}/servicesWithSubServices`,
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
          }
        })
          .then(
            response => {
              response = this.prepareServices(response, practitioner_services);
              this.services = response.data.sort(this.compareServicesName);
              this.showProgress = false;
            },
            error => {
              console.log(error.response.data);
              throw error;
            }
          )
          .catch(error => {
            console.log(error.response);
            throw error;
          });
      },
    }
  };
</script>

<style scoped lang="scss">
  @mixin r($width) {
    @media only screen and (max-width: "#{$width}px") {
      @content;
    }
  }

  .dashboard-content {
    display: block;
    flex: 1 1 auto;
    width: 100%;
    padding: 50px 120px 120px 100px;

    @include r(1700) {
      padding: 50px 100px 100px 80px;
    }

    @include r(1500) {
      padding: 40px 60px 60px 60px;
    }

    @include r(1300) {
      padding: 30px 40px 60px 40px;
    }
    @include r(1200) {
      padding: 28px 28px 40px 28px !important;
    }

    @include r(900) {
      padding: 28px 16px 20px 16px !important;
    }

    &__title {
      padding-bottom: 24px;

      @include r(1000) {
        padding-bottom: 0;
      }

      h2 {
        display: block;
        color: #383838;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.1;

        @include r(1700) {
          font-size: 34px;
        }

        @include r(1500) {
          font-size: 32px;
        }

        @include r(1400) {
          font-size: 30px;
        }

        @include r(1300) {
          font-size: 28px;
        }

        @include r(1000) {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 0 0;
        color: #535353;
        font-size: 15px;
        font-weight: 400;

        @include r(1000) {
          margin-top: 0;
        }
      }
    }

    &__body {
      padding: 40px 22px 0 22px;
      position: relative;

      @include r(1200) {
        padding: 40px 0 0 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 1px;
        left: 22px;
        width: calc(100% - 44px);
        background-color: #f7cecf;

        @include r(1200) {
          width: 100%;
          left: 0;
        }
        @include r(1000) {
          display: none;
        }
      }
    }
  }
</style>
